define('client/helpers/possession-string', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Handlebars.makeBoundHelper(function(str, options) {
    var ends_in_s, last_char;
    last_char = str.charAt(str.length - 1);
    ends_in_s = last_char === 's' || last_char === 'S';
    if (ends_in_s) {
      return str + '\'';
    } else {
      return str + '\'s';
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});