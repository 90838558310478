define('client/components/thinkspace/casespace/ownerable/bar/peer_review', ['exports', 'ember', 'totem/ns', 'totem/ds/associations', 'thinkspace-casespace/components/ownerable/bar/base'], function (exports, ember, ns, ta, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    casespace_peer_review: ember['default'].inject.service(),
    ownerables: ember['default'].computed('model', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model;
          model = _this.get('model');
          if (!ember['default'].isPresent(model)) {
            return resolve([]);
          }
          if (model.is_team_ownerable()) {
            return _this.get('casespace_peer_review').get_peer_review_teams(model).then(function(teams) {
              return resolve(teams);
            });
          } else {
            return _this.get('casespace_peer_review').get_peer_review_users(model).then(function(users) {
              return resolve(users);
            });
          }
        };
      })(this));
      return ta['default'].PromiseArray.create({
        promise: promise
      });
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});