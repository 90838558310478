define('client/components/thinkspace/resource/pane/file', ['exports', 'ember', 'totem/ajax', 'thinkspace-base/components/base'], function (exports, ember, ajax, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    file_url: ember['default'].computed(function() {
      return this.get('model.url');
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});