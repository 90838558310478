define('client/initializers/totem/application/errors', ['exports', 'ember', 'totem/error', 'totem-messages/messages'], function (exports, ember, totem_error, totem_messages) {

  'use strict';

  var ___DefaultExportObject___, initializer;

  initializer = {
    name: 'totem-application-errors',
    after: ['totem', 'totem-messages'],
    initialize: function(container, app) {
      ember['default'].RSVP.on('error', function(reason) {
        var route;
        if (reason == null) {
          reason = null;
        }
        console.info('2.......rsvp-error', reason);
        if (reason && reason.is_totem_error) {
          if (!reason.is_handled) {
            route = container.lookup('route:application');
            return route.send('error', reason);
          }
        }
      });
      return ember['default'].onerror = function(reason) {
        var message;
        if (reason == null) {
          reason = null;
        }
        console.info('3.......on-error', reason);
        message = reason && reason.message;
        if (message) {
          return console.error(message);
        }
      };
    }
  };

  ___DefaultExportObject___ = initializer;

  exports['default'] = ___DefaultExportObject___;;

});