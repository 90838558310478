define('client/helpers/time-duration', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Handlebars.makeBoundHelper(function(duration_in_seconds, options) {
    var hours, minutes, seconds;
    minutes = Math.floor(duration_in_seconds / 60);
    if (minutes > 59) {
      hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      minutes = minutes < 10 ? "0" + minutes : void 0;
    }
    seconds = duration_in_seconds % 60;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    if (hours) {
      return hours + ":" + minutes + ":" + seconds;
    } else {
      return minutes + ":" + seconds;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});