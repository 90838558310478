define('client/services/casespace_gradebook', ['exports', 'ember', 'ember-data', 'totem/ns', 'totem/ajax', 'totem/scope', 'totem-messages/messages'], function (exports, ember, ds, ns, ajax, totem_scope, totem_messages) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Object.extend({
    map: null,
    clear: function() {
      return this.set('map', null);
    },
    toString: function() {
      return 'GradebookMap';
    },
    new_map: function() {
      return ember['default'].Map.create();
    },
    get_map: function() {
      return this.get('map');
    },
    get_or_init_map: function() {
      var map;
      if (!(map = this.get_map())) {
        this.set('map', this.new_map());
        map = this.get_map();
      }
      return map;
    },
    get_or_init_space_map: function(space) {
      return this.get_or_init_record_map(space);
    },
    get_or_init_assignment_map: function(assignment) {
      return this.get_or_init_record_map(assignment);
    },
    get_or_init_phase_map: function(assignment, phase) {
      var assignment_map, phase_map;
      assignment_map = this.get_or_init_assignment_map(assignment);
      phase_map = assignment_map.get(phase);
      if (!phase_map) {
        assignment_map.set(phase, this.new_map());
      }
      return assignment_map.get(phase);
    },
    get_or_init_record_map: function(record) {
      var map, record_map;
      map = this.get_or_init_map();
      record_map = map.get(record);
      if (!record_map) {
        map.set(record, this.new_map());
      }
      return map.get(record);
    },
    get_gradebook_users: function(space, assignment) {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var gradebook_users, query, space_map;
          space_map = _this.get_or_init_space_map(space);
          gradebook_users = space_map.get('gradebook_users');
          if (ember['default'].isPresent(gradebook_users)) {
            return resolve(gradebook_users);
          }
          totem_scope['default'].ownerable_to_current_user();
          query = totem_scope['default'].get_view_query(assignment, {
            sub_action: 'gradebook_users'
          });
          totem_scope['default'].add_authable_to_query(query);
          return assignment.store.find(ns['default'].to_p('assignment'), query).then(function() {
            return space.get(ns['default'].to_p('users')).then(function(users) {
              gradebook_users = users.sortBy('sort_name');
              space_map.set('gradebook_users', gradebook_users);
              return resolve(gradebook_users);
            });
          });
        };
      })(this));
      return ds['default'].PromiseArray.create({
        promise: promise
      });
    },
    get_gradebook_phase_teams: function(assignment, team_phase) {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var phase_map, query, teams;
          phase_map = _this.get_or_init_phase_map(assignment, team_phase);
          teams = phase_map.get('teams');
          if (teams) {
            return resolve(teams);
          } else {
            query = {};
            query.verb = 'POST';
            query.action = 'view';
            query.data = totem_scope['default'].get_view_query(assignment, {
              sub_action: 'gradebook_teams'
            });
            query.model = assignment;
            query.id = assignment.get('id');
            totem_scope['default'].add_authable_to_query(query.data);
            return ajax['default'].object(query).then(function(payload) {
              teams = ajax['default'].normalize_and_push_payload('team', payload);
              if (ember['default'].isPresent(teams)) {
                teams = teams.uniq();
              }
              phase_map = _this.get_or_init_phase_map(assignment, team_phase);
              phase_map.set('teams', teams.sortBy('title'));
              return resolve(_this.get_or_init_phase_map(assignment, team_phase).get('teams'));
            });
          }
        };
      })(this));
      return ds['default'].PromiseArray.create({
        promise: promise
      });
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});