define('client/components/thinkspace/artifact/bucket/file/image/file', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    image_url: null,
    is_loading: false,
    missing_msg: 'Image is not available.',
    show_file_change: ember['default'].observer('show_file', function() {
      return this.load_and_show_image();
    }),
    c_loader: ns['default'].to_p('common', 'loader'),
    didInsertElement: function() {
      return this.load_and_show_image();
    },
    load_and_show_image: function() {
      var model;
      if (!this.get('show_file')) {
        return;
      }
      model = this.get('model');
      if (ember['default'].isBlank(model)) {
        return;
      }
      this.set('is_loading', true);
      return this.tc.image.url({
        model: model
      }).then((function(_this) {
        return function(url) {
          _this.set('is_loading', false);
          if (ember['default'].isBlank(url)) {
            return;
          }
          _this.set('image_url', url);
          return _this.totem_messages.api_success({
            source: _this,
            action: 'image_url',
            i18n_path: ns['default'].to_o('artifact', 'file', 'image_loaded')
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.set('is_loading', false);
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});