define('client/components/thinkspace/builder/header', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    builder: ember['default'].inject.service(),
    c_step: ns['default'].to_p('builder', 'header', 'step'),
    space: null,
    tagName: '',
    model: ember['default'].computed.reads('builder.model'),
    steps: ember['default'].computed.reads('builder.steps'),
    title: ember['default'].computed('model.title', function() {
      var model, title;
      model = this.get('model');
      title = model.get('title');
      if (ember['default'].isPresent(title)) {
        return title;
      } else {
        return 'New Case';
      }
    }),
    init: function() {
      var builder;
      this._super();
      builder = this.get('builder');
      return builder.get_space().then((function(_this) {
        return function(space) {
          return _this.set('space', space);
        };
      })(this));
    },
    actions: {
      exit: function() {
        return this.get('builder').transition_to_assignment();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});