define('client/components/thinkspace/casespace/ownerable/bar/gradebook/phase/overview', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    phase_manager: ember['default'].inject.service(),
    tagName: '',
    c_phase_score: ns['default'].to_p('casespace', 'ownerable', 'bar', 'gradebook', 'phase', 'overview', 'score'),
    init: function() {
      var register_to;
      this._super();
      register_to = this.get('register_to');
      register_to.register_child(this);
      return this.callback_set_addon_ownerable();
    },
    callback_set_addon_ownerable: function() {
      var map, phase, phase_states;
      phase = this.get('model');
      map = this.get('phase_manager.map');
      if (ember['default'].isPresent(map)) {
        phase_states = map.get_ownerable_phase_states(phase);
        return this.set('phase_states', phase_states);
      } else {
        return this.set('phase_states', new Array);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});