define('client/components/thinkspace/common/dropdown', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    classNames: ['thinkspace-dropdown'],
    dropdown_link: 'thinkspace/common/dropdown_link',
    default_anchor_class: 'thinkspace-dropdown_anchor',
    default_list_class: 'f-dropdown thinkspace-dropdown_list',
    default_list_item_class: 'thinkspace-dropdown_list-item',
    default_link_class: 'thinkspace-dropdown_link',
    anchor_classes: ember['default'].computed('anchor_class', function() {
      return this.concat_class('anchor');
    }),
    list_classes: ember['default'].computed('list_class', function() {
      return this.concat_class('list');
    }),
    list_item_classes: ember['default'].computed('list_item_class', function() {
      return this.concat_class('list_item');
    }),
    link_classes: ember['default'].computed('link_class', function() {
      return this.concat_class('link');
    }),
    drop_id: ember['default'].computed(function() {
      return "ts-drop_" + (this.get('elementId'));
    }),
    concat_class: function(tag) {
      var custom_class, default_class;
      default_class = this.get("default_" + tag + "_class");
      custom_class = this.get(tag + "_class");
      if (custom_class) {
        return default_class.concat(' ', custom_class);
      } else {
        return default_class;
      }
    },
    didInsertElement: function() {
      $(document).foundation('dropdown');
      return this.init_text();
    },
    init_text: function() {
      var default_text;
      default_text = this.get('default_text');
      if (ember['default'].isPresent(default_text)) {
        return this.set('text', default_text);
      }
    },
    keyDown: function(event) {
      var key_code;
      key_code = event.keyCode;
      switch (key_code) {
        case 38:
          this.select_previous();
          event.stopPropagation();
          return event.preventDefault();
        case 40:
          this.select_next();
          event.stopPropagation();
          return event.preventDefault();
      }
    },
    select_next: function() {
      return this.select_from_offset(1);
    },
    select_previous: function() {
      return this.select_from_offset(-1);
    },
    select_from_offset: function(offset) {
      var collection, find_at, index, object, selected;
      collection = this.get('collection');
      selected = this.get('selected');
      index = collection.indexOf(selected);
      find_at = index + offset;
      if (index === -1) {
        return;
      }
      if (collection.length > find_at && find_at >= 0) {
        object = collection.objectAt(find_at);
        this.set('selected', object);
        return this.set_selected_text();
      }
    },
    set_selected_text: function() {
      var display_property, selected, text;
      selected = this.get('selected');
      display_property = this.get('display_property');
      if (!(ember['default'].isPresent(selected) && ember['default'].isPresent(display_property))) {
        return;
      }
      if (selected[display_property] != null) {
        text = selected[display_property];
      } else {
        text = selected.get(display_property);
      }
      return this.set('text', text);
    },
    send_selection_event: function() {
      var action_receiver, select_action, selected;
      selected = this.get('selected');
      select_action = this.get('select_action');
      action_receiver = this.get('action_receiver');
      if (!(ember['default'].isPresent(select_action) && ember['default'].isPresent(action_receiver))) {
        return;
      }
      return action_receiver.send(select_action, selected);
    },
    auto_width: false,
    callback_auto_width: function() {
      var $drop, drop_selector, klass;
      drop_selector = '#' + this.get('drop_id');
      $drop = this.$(drop_selector);
      klass = '';
      $drop.children('li').each((function(_this) {
        return function(i, child) {
          var $child, characters, text;
          $child = $(child);
          text = $child.text();
          characters = text.length;
          switch (false) {
            case !(characters < 5):
            case !(characters >= 0):
              return klass = 'thinkspace-dropdown_small';
            case !(characters < 10):
            case !(characters >= 5):
              return klass = 'thinkspace-dropdown_medium';
            default:
              return klass = 'thinkspace-dropdown_large';
          }
        };
      })(this));
      return $drop.addClass(klass);
    },
    actions: {
      click_callbacks: function() {
        if (this.get('auto_width')) {
          return this.callback_auto_width();
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});