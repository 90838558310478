define('client/transforms/string_id', ['exports', 'ember-data'], function (exports, ds) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ds['default'].Transform.extend({
    deserialize: function(serialized) {
      return "" + serialized;
    },
    serialize: function(deserialized) {
      return deserialized;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});