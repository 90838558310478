define('client/components/thinkspace/readiness_assurance/response/trat/chats', ['exports', 'ember', 'totem/ns', 'thinkspace-readiness-assurance/base/ra_component'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    chat_managers: ember['default'].computed('chat_ids.@each', function() {
      var chat_ids, cm, i, len, managers, qid, qm;
      managers = [];
      chat_ids = this.get('chat_ids') || [];
      for (i = 0, len = chat_ids.length; i < len; i++) {
        qid = chat_ids[i];
        cm = this.rm.chat_manager_map.get(qid);
        qm = this.rm.question_manager_map.get(qid);
        managers.push({
          cm: cm,
          qm: qm
        });
      }
      return managers;
    }),
    actions: {
      close: function(qid) {
        return this.sendAction('close', qid);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});