define('client/models/thinkspace/input_element/element', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].polymorphic('helper_embedable'), ta['default'].has_many('responses', {
    reads: {
      filter: true,
      notify: true
    }
  })), {
    name: ta['default'].attr('string'),
    element_type: ta['default'].attr('string'),
    helper_embedable_type: ta['default'].attr('string'),
    helper_embedable_id: ta['default'].attr('number'),
    response: ember['default'].computed.reads('responses.firstObject')
  });

  exports['default'] = ___DefaultExportObject___;;

});