define('client/components/thinkspace/weather_forecaster/assessment/help_tip', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    actions: {
      close: function() {
        return this.sendAction('close');
      }
    },
    help_tip_presentation: ember['default'].computed(function() {
      var tip;
      tip = this.get('help_tip.html' || '');
      return tip && tip.htmlSafe();
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});