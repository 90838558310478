define('client/components/thinkspace/lab/builder/lab/content', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    init: function() {
      var admin, chart;
      this._super();
      admin = this.get('admin');
      chart = this.get('model');
      admin.clear();
      admin.set_admin_component(this);
      admin.set_store(chart.store);
      admin.set_chart(chart);
      return admin.set('t', this.t);
    },
    admin: ember['default'].inject.service(ns['default'].to_p('lab', 'admin')),
    actions: {
      exit: function() {
        return this.send('clear_and_exit');
      },
      clear_and_exit: function() {
        var admin;
        admin = this.get('admin');
        admin.set_action_overlay_off();
        return admin.clear();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});