define('client/models/thinkspace/markup/discussion', ['exports', 'ember', 'totem/ds/associations', 'totem/ns'], function (exports, ember, ta, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].polymorphic('authable'), ta['default'].polymorphic('ownerable'), ta['default'].polymorphic('creatorable'), ta['default'].polymorphic('discussionable'), ta['default'].has_many('comments')), {
    user_id: ta['default'].attr('number'),
    authable_id: ta['default'].attr('number'),
    authable_type: ta['default'].attr('string'),
    ownerable_id: ta['default'].attr('number'),
    ownerable_type: ta['default'].attr('string'),
    creatorable_id: ta['default'].attr('number'),
    creatorable_type: ta['default'].attr('string'),
    discussionable_id: ta['default'].attr('number'),
    discussionable_type: ta['default'].attr('string'),
    value: ta['default'].attr(),
    updated_at: ta['default'].attr('date'),
    created_at: ta['default'].attr('date'),
    updateable: ta['default'].attr('boolean'),
    page_height: 1500.0,
    sort_by: ember['default'].computed('value', function() {
      var page, page_height, y_pos;
      y_pos = this.get('value.position.y');
      page = this.get('value.position.page');
      page_height = this.get('page_height');
      return parseFloat(y_pos) + (parseFloat(page) * parseFloat(page_height));
    }),
    get_commenterables: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get(ns['default'].to_p('comments')).then(function(comments) {
            var commenterable_promises;
            commenterable_promises = comments.getEach('commenterable');
            return ember['default'].RSVP.Promise.all(commenterable_promises).then(function(commenterables) {
              return resolve(commenterables);
            });
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});