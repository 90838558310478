define('client/templates/components/thinkspace/common/user/show/profile', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ts-grid_row");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","small-12 ts-grid_columns ts-grid_columns-thick ts-forms_section-container");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-forms_section-heading");
        var el4 = dom.createElement("h1");
        var el5 = dom.createTextNode("Profile Picture  ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-user-profile");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-user-profile_image-col");
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"class","ts-user-profile_image");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-user-profile_text-col");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-forms_field-container");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-forms_field-label-container");
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","ts-forms_field-label");
        var el8 = dom.createTextNode("Upload your profile picture");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","ts-forms_field-description");
        var el8 = dom.createTextNode("Must be at least 400px x 400px");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ts-grid_row");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","small-12 medium-12 large-6 ts-grid_columns ts-grid_columns-thick");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-forms_section-container");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-forms_section-heading");
        var el5 = dom.createElement("h1");
        var el6 = dom.createTextNode("Profile Information");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-forms_field-container");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-forms_field-label");
        var el6 = dom.createTextNode("First Name");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-forms_field-container");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-forms_field-label");
        var el6 = dom.createTextNode("Last Name");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-forms_field-container");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-forms_field-label-container");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-forms_field-label");
        var el7 = dom.createTextNode("School Email");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","tsi tsi-right tsi-nano tsi-lock");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-forms_field-description");
        var el7 = dom.createTextNode("Your school email is set by your instructor.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-forms_field-container");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-forms_field-label-container");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-forms_field-label");
        var el7 = dom.createTextNode("Discipline");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-forms_field-description");
        var el7 = dom.createTextNode("Select the discipline that is the closest to yours.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-forms_field-container");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-forms_field-label-container");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-forms_field-label");
        var el7 = dom.createTextNode("Are you an instructor or planning to be one?");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-forms_field-description");
        var el7 = dom.createTextNode("This will give you access to the ThinkSpace sandbox to help you learn about ThinkSpace tools and components to enhance your class.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-forms_radio-btn");
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","ts-radio");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("Yes, I am an instructor or future instructor.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-forms_field-container");
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","ts-radio");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("No, I am a student.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","btn btn-primary btn-small");
        var el5 = dom.createTextNode("Update Information");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","small-12 medium-12 large-6 ts-grid_columns ts-grid_columns-thick");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-forms_section-container");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-forms_section-heading");
        var el5 = dom.createElement("h1");
        var el6 = dom.createTextNode("Change Password");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("To change your password, click on the button below.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","btn btn-primary btn-small");
        var el5 = dom.createTextNode("Update Password");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-forms_field-container");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-forms_section-heading");
        var el5 = dom.createElement("h1");
        var el6 = dom.createTextNode("E-mail Settings");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"class","ts-checkbox");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-checkbox_label");
        var el6 = dom.createTextNode("Yes, keep me updated with the latest ThinkSpace news");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-radio_description");
        var el6 = dom.createTextNode("ThinkSpace newsletters send you the latest updates on features and development.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, inline = hooks.inline, element = hooks.element;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [1, 0, 1]);
        var element1 = dom.childAt(element0, [0, 0]);
        var element2 = dom.childAt(fragment, [2]);
        var element3 = dom.childAt(element2, [0, 0]);
        var element4 = dom.childAt(element3, [5]);
        var element5 = dom.childAt(element4, [1, 0]);
        var element6 = dom.childAt(element4, [2, 0]);
        var element7 = dom.childAt(element3, [6]);
        var element8 = dom.childAt(element2, [1]);
        var element9 = dom.childAt(element8, [0, 2]);
        var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
        var morph1 = dom.createMorphAt(dom.childAt(element0, [1, 0]),1,1);
        var morph2 = dom.createMorphAt(dom.childAt(element3, [1]),1,1);
        var morph3 = dom.createMorphAt(dom.childAt(element3, [2]),1,1);
        var morph4 = dom.createMorphAt(dom.childAt(element3, [3]),1,1);
        var morph5 = dom.createMorphAt(dom.childAt(element3, [4]),1,1);
        var morph6 = dom.createMorphAt(element5,0,0);
        var morph7 = dom.createMorphAt(element6,0,0);
        var morph8 = dom.createMorphAt(dom.childAt(element8, [1, 1]),0,0);
        dom.insertBoundary(fragment, 0);
        inline(env, morph0, context, "partial", [get(env, context, "p_header")], {});
        element(env, element1, context, "bind-attr", [], {"src": get(env, context, "avatar_url")});
        inline(env, morph1, context, "component", [get(env, context, "c_file_upload")], {"form_action": get(env, context, "upload_avatar_form_action"), "btn_text": get(env, context, "upload_avatar_btn_text"), "loading_text": get(env, context, "upload_avatar_loading_text"), "api_params": get(env, context, "upload_avatar_params"), "close_on_success": false, "verb": "POST", "multiple": false, "file_input_accept": "image/*", "select_text": get(env, context, "upload_avatar_select_text"), "model_path": get(env, context, "upload_avatar_model_path")});
        inline(env, morph2, context, "component", [get(env, context, "c_validated_input")], {"value": get(env, context, "first_name"), "errors": get(env, context, "errors.first_name")});
        inline(env, morph3, context, "component", [get(env, context, "c_validated_input")], {"value": get(env, context, "last_name"), "errors": get(env, context, "errors.last_name")});
        inline(env, morph4, context, "component", [get(env, context, "c_validated_input")], {"value": get(env, context, "email"), "disabled": true});
        inline(env, morph5, context, "component", [get(env, context, "c_dropdown")], {"collection": get(env, context, "all_disciplines"), "display_property": "title", "default_text": "Select a Discipline", "text": get(env, context, "discipline.title"), "select_action": "select_discipline", "action_receiver": get(env, context, "this"), "anchor_classes": "btn btn-small"});
        element(env, element5, context, "action", ["set_role", "instructor"], {"on": "click"});
        inline(env, morph6, context, "component", [get(env, context, "c_radio")], {"checked": get(env, context, "is_instructor"), "disable_click": true});
        element(env, element6, context, "action", ["set_role", "student"], {"on": "click"});
        inline(env, morph7, context, "component", [get(env, context, "c_radio")], {"checked": get(env, context, "is_student"), "disable_click": true});
        element(env, element7, context, "action", ["update_information"], {});
        element(env, element9, context, "action", ["update_password"], {});
        inline(env, morph8, context, "component", [get(env, context, "c_checkbox")], {"checked": get(env, context, "email_optin")});
        return fragment;
      }
    };
  }()));

});