define('client/components/thinkspace/casespace/ownerable/bar/gradebook', ['exports', 'ember', 'totem/ns', 'totem/ds/associations', 'thinkspace-casespace/components/ownerable/bar/base'], function (exports, ember, ns, ta, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    casespace_gradebook: ember['default'].inject.service(),
    is_team_collaboration: false,
    is_gradebook: true,
    is_scoring: false,
    is_viewing_total_score: false,
    is_viewing_phase_state: false,
    phase_state: null,
    children: null,
    ownerables: ember['default'].computed('model', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var assignment, casespace, gradebook, is_team_collaboration, phase, space;
          is_team_collaboration = _this.get_is_team_collaboration();
          casespace = _this.get('casespace');
          gradebook = _this.get('casespace_gradebook');
          if (is_team_collaboration) {
            phase = _this.get('model');
            assignment = casespace.get_current_assignment();
            return gradebook.get_gradebook_phase_teams(assignment, phase).then(function(teams) {
              return resolve(teams);
            });
          } else {
            space = casespace.get_current_space();
            assignment = casespace.get_current_assignment();
            return gradebook.get_gradebook_users(space, assignment).then(function(users) {
              return resolve(users);
            });
          }
        };
      })(this));
      return ta['default'].PromiseArray.create({
        promise: promise
      });
    }),
    c_phase_score: ns['default'].to_p('casespace', 'ownerable', 'bar', 'gradebook', 'phase', 'score'),
    c_phase_state: ns['default'].to_p('casespace', 'ownerable', 'bar', 'gradebook', 'phase', 'state'),
    c_phase_overview: ns['default'].to_p('casespace', 'ownerable', 'bar', 'gradebook', 'phase', 'overview'),
    current_phase_observer: ember['default'].observer('casespace.current_phase', function() {
      return ember['default'].run.schedule('afterRender', (function(_this) {
        return function() {
          return _this.callback_set_addon_ownerable();
        };
      })(this));
    }),
    init: function() {
      this._super();
      return this.set('children', new Array);
    },
    callback_set_addon_ownerable: function() {
      var children, phase, phase_state;
      phase = this.get('current_phase');
      phase_state = phase.get('phase_state');
      this.set('phase_state', phase_state);
      this.notify_phase_score_change();
      children = this.get('children');
      return children.forEach((function(_this) {
        return function(child) {
          return child.callback_set_addon_ownerable();
        };
      })(this));
    },
    register_child: function(component) {
      var children;
      children = this.get('children');
      return children.pushObject(component);
    },
    phase_score_change: null,
    notify_phase_score_change: function() {
      return this.notifyPropertyChange('phase_score_change');
    },
    total_score: ember['default'].computed('phase_score_change', 'current_phase', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var current_assignment, map;
          map = _this.get('phase_manager.map');
          current_assignment = _this.get('current_assignment');
          if (!current_assignment) {
            return resolve(0);
          }
          return current_assignment.get(ns['default'].to_p('phases')).then(function(phases) {
            var total;
            if (!phases) {
              return resolve(0);
            }
            total = 0;
            phases.forEach(function(phase) {
              var phase_states;
              phase_states = map.get_ownerable_phase_states(phase);
              return phase_states.forEach(function(phase_state) {
                return total += phase_state.get('score') || 0;
              });
            });
            return resolve(total);
          });
        };
      })(this));
      return ta['default'].PromiseObject.create({
        promise: promise
      });
    }),
    save_phase_score: function(score) {
      var phase, phase_state;
      phase = this.get('current_phase');
      phase_state = phase.get('phase_state');
      return phase_state.get(ns['default'].to_p('phase_score')).then((function(_this) {
        return function(phase_score) {
          if (!phase_score) {
            phase_score = phase.store.createRecord(ns['default'].to_p('phase_score'));
            phase_score.set(ns['default'].to_p('phase_state'), phase_state);
          }
          score = Number(score);
          phase_score.set('score', score);
          if (phase_score.get('isDirty')) {
            return phase_score.save().then(function(record) {
              _this.totem_messages.api_success({
                source: _this,
                model: record,
                action: 'save',
                i18n_path: ns['default'].to_o('phase_score', 'save')
              });
              return _this.notify_phase_score_change();
            }, function(error) {
              return _this.totem_messages.api_failure(error, {
                source: _this,
                model: phase_score
              });
            });
          }
        };
      })(this));
    },
    save_phase_state: function(state) {
      var phase, phase_state;
      phase = this.get('current_phase');
      phase_state = phase.get('phase_state');
      if (phase_state.get('current_state') !== state) {
        phase_state.set('new_state', state);
        return phase_state.save().then((function(_this) {
          return function(record) {
            return _this.totem_messages.api_success({
              source: _this,
              model: record,
              action: 'save',
              i18n_path: ns['default'].to_o('phase_state', 'save')
            });
          };
        })(this), (function(_this) {
          return function(error) {
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: phase_state
            });
          };
        })(this));
      }
    },
    set_is_scoring: function() {
      return this.set('is_scoring', true);
    },
    reset_is_scoring: function() {
      return this.set('is_scoring', false);
    },
    toggle_is_scoring: function() {
      return this.toggleProperty('is_scoring');
    },
    actions: {
      toggle_scorecard: function() {
        return this.toggle_is_scoring();
      },
      phase_score: function(score) {
        return this.save_phase_score(score);
      },
      phase_state: function(state) {
        return this.save_phase_state(state);
      },
      toggle_is_viewing_phase_state: function() {
        return this.toggleProperty('is_viewing_phase_state');
      },
      toggle_is_viewing_total_score: function() {
        return this.toggleProperty('is_viewing_total_score');
      },
      delete_ownerable_data: function() {
        var confirm, ownerable, phase, query;
        ownerable = this.get('addon_ownerable');
        phase = this.get('current_phase');
        confirm = window.confirm("Are you sure you want to clear " + (ownerable.get('first_name')) + "'s data for " + (phase.get('title')) + "?  This will remove all of their responses for this phase.  This process is NOT REVERSIBLE.  This will refresh your browser.");
        if (confirm) {
          query = {
            id: phase.get('id'),
            ownerable_id: ownerable.get('id'),
            ownerable_type: this.totem_scope.standard_record_path(ownerable),
            action: 'delete_ownerable_data',
            verb: 'PUT'
          };
          this.totem_messages.show_loading_outlet({
            message: 'Removing learner data...'
          });
          return this.tc.query(ns['default'].to_p('phase'), query, {
            single: true
          }).then((function(_this) {
            return function() {
              return _this.set_addon_ownerable(ownerable).then(function() {
                _this.totem_messages.hide_loading_outlet();
                return location.reload();
              });
            };
          })(this));
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});