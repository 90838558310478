define('client/components/thinkspace/casespace/case_manager/assignment/peer_assessment/assessment', ['exports', 'ember', 'totem/ajax', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ajax, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    teams: null,
    team_sets: null,
    assignment: null,
    has_sent: false,
    c_assignment_header: ns['default'].to_p('assignment', 'header'),
    c_team: ns['default'].to_p('case_manager', 'assignment', 'peer_assessment', 'team'),
    init: function() {
      this._super();
      return this.set_assessment().then((function(_this) {
        return function() {
          return _this.set_teams().then(function() {
            return _this.set_team_sets().then(function() {
              return _this.set('all_data_loaded', true);
            });
          });
        };
      })(this));
    },
    set_assessment: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          query = {
            model: ns['default'].to_p('tbl:assessment'),
            verb: 'get',
            action: 'fetch',
            data: {
              assignment_id: _this.get('assignment.id')
            }
          };
          return ajax['default'].object(query).then(function(payload) {
            var assessment;
            assessment = ajax['default'].normalize_and_push_payload('tbl:assessment', payload, {
              single: true
            });
            console.log("[tbl-pa-cm] Assessment set to : ", assessment);
            _this.set('model', assessment);
            return resolve();
          });
        };
      })(this));
    },
    set_teams: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          query = {
            model: ns['default'].to_p('tbl:assessment'),
            verb: 'get',
            action: 'teams',
            id: _this.get('model.id')
          };
          return ajax['default'].object(query).then(function(payload) {
            var teams;
            teams = ajax['default'].normalize_and_push_payload('team', payload);
            _this.set('teams', teams);
            return resolve();
          });
        };
      })(this));
    },
    set_team_sets: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          query = {
            model: ns['default'].to_p('tbl:assessment'),
            verb: 'get',
            action: 'team_sets',
            id: _this.get('model.id')
          };
          return ajax['default'].object(query).then(function(payload) {
            var team_sets;
            team_sets = ajax['default'].normalize_and_push_payload('tbl:team_set', payload);
            console.log("[tbl-pa-cm] Team sets set to: ", team_sets);
            _this.set('team_sets', team_sets);
            return resolve();
          });
        };
      })(this));
    },
    get_approve_modal: function() {
      return $('.ts-tblpa_modal');
    },
    show_approve_modal: function() {
      return this.get_approve_modal().foundation('reveal', 'open');
    },
    close_approve_modal: function() {
      return this.get_approve_modal().foundation('reveal', 'close');
    },
    get_notify_all_modal: function() {
      return $('.ts-tblpa_modal-notify');
    },
    show_notify_all_modal: function() {
      return this.get_notify_all_modal().foundation('reveal', 'open');
    },
    close_notify_all_modal: function() {
      return this.get_notify_all_modal().foundation('reveal', 'close');
    },
    actions: {
      show_approve_modal: function() {
        return this.show_approve_modal();
      },
      close_approve_modal: function() {
        return this.close_approve_modal();
      },
      show_notify_all_modal: function() {
        return this.show_notify_all_modal();
      },
      close_notify_all_modal: function() {
        return this.close_notify_all_modal();
      },
      approve: function() {
        var assessment, query;
        assessment = this.get('model');
        query = {
          id: assessment.get('id'),
          action: 'approve',
          verb: 'PUT'
        };
        this.totem_messages.show_loading_outlet();
        return this.tc.query(ns['default'].to_p('tbl:assessment'), query, {
          single: true
        }).then((function(_this) {
          return function() {
            _this.totem_messages.hide_loading_outlet();
            _this.close_approve_modal();
            return _this.set('has_sent', true);
          };
        })(this));
      },
      approve_notify_all: function() {
        var id, model, query;
        model = this.get('model');
        id = this.get('model.id');
        query = {
          id: id,
          action: 'notify_all',
          verb: 'POST'
        };
        this.totem_messages.show_loading_outlet();
        return this.tc.query(ns['default'].to_p('tbl:assessment'), query, {
          single: true
        }).then((function(_this) {
          return function() {
            _this.totem_messages.api_success({
              source: _this,
              model: model,
              action: 'notify_all',
              i18n_path: ns['default'].to_o('tbl:assessment', 'notify_all')
            });
            _this.totem_messages.hide_loading_outlet();
            return _this.close_notify_all_modal();
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});