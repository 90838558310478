define('client/components/thinkspace/readiness_assurance/admin/messages/send', ['exports', 'ember', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    show_select: true,
    init: function() {
      this._super();
      return this.rad = this.am.rad({
        name: 'MSG',
        width_selector: '.ts-ra_admin-message-content'
      });
    },
    willInsertElement: function() {
      return this.am.get_trat_team_users().then((function(_this) {
        return function(team_users) {
          _this.team_users = team_users;
          _this.rad.set_team_users(team_users);
          _this.rad.show_all_on();
          return _this.set_ready_on();
        };
      })(this));
    },
    actions: {
      done: function() {
        return this.sendAction('done', this.config);
      },
      toggle_select: function() {
        this.toggleProperty('show_select');
      },
      validate: function() {
        return this.validate();
      },
      send_message: function() {
        var message;
        this.selected_send_on();
        this.validate();
        if (ember['default'].isPresent(this.rad.errors)) {
          return;
        }
        message = this.rad.get_data();
        return this.am.send_message_to_users({
          message: message
        });
      }
    },
    validate: function() {
      this.rad.clear_errors();
      if (ember['default'].isBlank(this.rad.get_message())) {
        this.rad.error("You must enter a message.");
      }
      if (ember['default'].isBlank(this.rad.get_users())) {
        return this.rad.error("You have not selected any users.");
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});