define('client/components/thinkspace/readiness_assurance/admin/shared/radio/buttons', ['exports', 'ember', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    tagName: 'div',
    actions: {
      select: function(id) {
        return this.sendAction('select', id);
      }
    },
    willInsertElement: function() {
      return this.process_button_columns();
    },
    button_columns: null,
    process_button_columns: function() {
      var button, button_columns, buttons, col_array, i, len, ncols, per_col;
      ncols = this.columns;
      if (!(ncols && ncols > 1)) {
        return;
      }
      buttons = this.buttons || [];
      if (ember['default'].isBlank(buttons)) {
        return;
      }
      per_col = Math.ceil(buttons.length / ncols);
      if (per_col < 1) {
        return;
      }
      button_columns = [];
      col_array = [];
      for (i = 0, len = buttons.length; i < len; i++) {
        button = buttons[i];
        if (col_array.length < per_col) {
          col_array.push(button);
        } else {
          button_columns.push(col_array);
          col_array = [];
          col_array.push(button);
        }
      }
      if (ember['default'].isPresent(col_array)) {
        button_columns.push(col_array);
      }
      return this.set('button_columns', button_columns);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});