define('client/models/thinkspace/peer_assessment/review_set', ['exports', 'ember', 'totem/util', 'totem/ds/associations', 'totem/ns'], function (exports, ember, util, ta, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].polymorphic('ownerable'), ta['default'].has_many('tbl:reviews', {
    reads: {}
  })), {
    ownerable_id: ta['default'].attr('number'),
    ownerable_string: ta['default'].attr('string'),
    state: ta['default'].attr('string'),
    is_sent: ember['default'].computed.equal('state', 'sent'),
    is_approved: ember['default'].computed.equal('state', 'approved'),
    is_ignored: ember['default'].computed.equal('state', 'ignored'),
    is_submitted: ember['default'].computed.equal('state', 'submitted'),
    is_not_approved: ember['default'].computed.not('is_approved'),
    is_not_sent: ember['default'].computed.not('is_sent'),
    is_not_ignored: ember['default'].computed.not('is_ignored'),
    is_approvable: ember['default'].computed.and('is_not_approved', 'is_not_sent'),
    is_read_only: ember['default'].computed('is_ignored', 'is_approved', 'is_submitted', 'is_sent', function() {
      return this.get('is_approved') || this.get('is_submitted') || this.get('is_ignored') || this.get('is_sent');
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});