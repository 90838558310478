define('client/components/thinkspace/simulation/simulations/radiation', ['exports', 'ember', 'ember-data', 'totem/ns', 'totem-application/mixins/default_property_settings', 'thinkspace-base/components/base'], function (exports, ember, ds, ns, default_prop_mixin, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend(default_prop_mixin['default'], {
    c_list_btn: ember['default'].computed(function() {
      return ns['default'].to_p('simulation', 'simulations', 'radiation', 'list-btn');
    }),
    radiation_game: null,
    plot_chart: null,
    plot_data: [],
    default_plot_data: [],
    update_counter: 0,
    simulation_paused: false,
    simulation_concluded: false,
    simulation_loaded: false,
    cur_surface: 'sand',
    cur_time_of_day: 'day',
    height_partitions: 20,
    balloon_height_data: [],
    load_update_counter: 0,
    load_text_container: null,
    cur_height: null,
    sim_ground_level: 350,
    sim_fps: 60,
    balloon_animate_counter: 0,
    initial_plot_state: null,
    snow_day_obj: null,
    grass_day_obj: null,
    plowed_day_obj: null,
    sand_day_obj: null,
    snow_night_obj: null,
    plowed_night_obj: null,
    sand_night_obj: null,
    grass_night_obj: null,
    night_overlay_obj: null,
    balloon_obj: null,
    balloon_label_obj: null,
    snow_day_graph: null,
    snow_night_graph: null,
    grass_day_graph: null,
    grass_night_graph: null,
    sand_day_graph: null,
    sand_night_graph: null,
    plowed_day_graph: null,
    plowed_night_graph: null,
    balloon_start_x: 250,
    balloon_start_y: 305,
    image_preloader: new createjs.LoadQueue(false),
    all_data: [
      {
        pressure: 1000,
        height: 0,
        sand_day: 285,
        plowed_day: 283,
        grass_day: 281,
        snow_day: 273,
        sand_night: 278.4,
        plowed_night: 276.4,
        grass_night: 274.4,
        snow_night: 268
      }, {
        pressure: 990,
        height: 80.97,
        sand_day: 284.2,
        plowed_day: 282.2,
        grass_day: 280.2,
        snow_day: 273.2,
        sand_night: 278.5,
        plowed_night: 276.5,
        grass_night: 274.5,
        snow_night: 270
      }, {
        pressure: 980,
        height: 162.85,
        sand_day: 283.4,
        plowed_day: 281.4,
        grass_day: 279.4,
        snow_day: 273.4,
        sand_night: 278.7,
        plowed_night: 276.7,
        grass_night: 274.7,
        snow_night: 271.8
      }, {
        pressure: 970,
        height: 245.69,
        sand_day: 282.5,
        plowed_day: 280.5,
        grass_day: 278.6,
        snow_day: 273.7,
        sand_night: 278.8,
        plowed_night: 276.8,
        grass_night: 274.9,
        snow_night: 273.2
      }, {
        pressure: 960,
        height: 329.49,
        sand_day: 281.7,
        plowed_day: 279.7,
        grass_day: 277.7,
        snow_day: 274.6,
        sand_night: 279.5,
        plowed_night: 277.5,
        grass_night: 275.5,
        snow_night: 274.6
      }, {
        pressure: 950,
        height: 414.25,
        sand_day: 280.9,
        plowed_day: 278.9,
        grass_day: 276.9,
        snow_day: 275.9,
        sand_night: 280.1,
        plowed_night: 278.1,
        grass_night: 276.1,
        snow_night: 275.9
      }, {
        pressure: 940,
        height: 500,
        sand_day: 280,
        plowed_day: 278,
        grass_day: 276.8,
        snow_day: 276.8,
        sand_night: 280,
        plowed_night: 278,
        grass_night: 276.8,
        snow_night: 276.8
      }, {
        pressure: 930,
        height: 586.76,
        sand_day: 279.2,
        plowed_day: 277.2,
        grass_day: 277.2,
        snow_day: 277.2,
        sand_night: 279.2,
        plowed_night: 277.2,
        grass_night: 277.2,
        snow_night: 277.2
      }, {
        pressure: 920,
        height: 674.49,
        sand_day: 278.3,
        plowed_day: 277,
        grass_day: 277,
        snow_day: 277,
        sand_night: 278.3,
        plowed_night: 277,
        grass_night: 277,
        snow_night: 277
      }, {
        pressure: 910,
        height: 763.12,
        sand_day: 277.4,
        plowed_day: 276.8,
        grass_day: 276.8,
        snow_day: 276.8,
        sand_night: 277.4,
        plowed_night: 276.8,
        grass_night: 276.8,
        snow_night: 276.8
      }, {
        pressure: 900,
        height: 852.64,
        sand_day: 276.5,
        plowed_day: 276.5,
        grass_day: 276.5,
        snow_day: 276.5,
        sand_night: 276.5,
        plowed_night: 276.5,
        grass_night: 276.5,
        snow_night: 276.5
      }, {
        pressure: 890,
        height: 942.95,
        sand_day: 275.5,
        plowed_day: 275.5,
        grass_day: 275.5,
        snow_day: 275.5,
        sand_night: 275.2,
        plowed_night: 276.2,
        grass_night: 275.2,
        snow_night: 275.5
      }, {
        pressure: 880,
        height: 1034,
        sand_day: 274.8,
        plowed_day: 274.8,
        grass_day: 274.8,
        snow_day: 274.8,
        sand_night: 274.8,
        plowed_night: 274.8,
        grass_night: 274.8,
        snow_night: 274.8
      }, {
        pressure: 870,
        height: 1125.85,
        sand_day: 274,
        plowed_day: 274,
        grass_day: 274,
        snow_day: 274,
        sand_night: 274,
        plowed_night: 274,
        grass_night: 274,
        snow_night: 274
      }, {
        pressure: 860,
        height: 1218.44,
        sand_day: 273,
        plowed_day: 273,
        grass_day: 273,
        snow_day: 273,
        sand_night: 273,
        plowed_night: 273,
        grass_night: 273,
        snow_night: 273
      }, {
        pressure: 850,
        height: 1311.82,
        sand_day: 272.2,
        plowed_day: 272.2,
        grass_day: 272.2,
        snow_day: 272.2,
        sand_night: 272.2,
        plowed_night: 272.2,
        grass_night: 272.2,
        snow_night: 272.2
      }, {
        pressure: 840,
        height: 1406,
        sand_day: 271.3,
        plowed_day: 271.3,
        grass_day: 271.3,
        snow_day: 271.3,
        sand_night: 271.3,
        plowed_night: 271.3,
        grass_night: 271.3,
        snow_night: 271.3
      }
    ],
    default_property_settings: {
      update_counter: null,
      simulation_paused: false,
      simulation_concluded: false,
      default_plot_data: {
        type: 'array'
      }
    },
    balloon_text_update: ember['default'].observer('cur_height', function() {
      var balloon_label, cur_height;
      cur_height = this.get('cur_height');
      balloon_label = this.get('balloon_label_obj');
      return balloon_label.text = cur_height.rounded_height + 'm';
    }),
    transition_obs: ember['default'].observer('cur_surface', 'cur_time_of_day', function() {
      var cur_background, cur_surface, cur_time_of_day, old_background;
      cur_surface = this.get('cur_surface');
      cur_time_of_day = this.get('cur_time_of_day');
      old_background = this.get('cur_background');
      cur_background = this.get(cur_surface + "_" + cur_time_of_day + "_obj");
      this.transition_background(old_background, cur_background);
      return this.set('cur_background', cur_background);
    }),
    willDestroyElement: function() {
      this.get('radiation_game').removeAllEventListeners();
      createjs.Ticker.removeAllEventListeners();
      return createjs.Tween.removeAllTweens();
    },
    didInsertElement: function() {
      var canvas;
      this.set('radiation_game', new createjs.Stage('radiation-sim-canvas'));
      createjs.Ticker.setFPS(this.get('sim_fps'));
      createjs.Ticker.addEventListener('tick', createjs.Tween);
      canvas = document.getElementById('radiation-sim-canvas');
      canvas.width = 700;
      canvas.height = 400;
      return this.initialize_load_screen();
    },
    initialize_graph: function() {
      var all_data, grass_day_graph, grass_night_graph, plot_chart, plot_data, plowed_day_graph, plowed_night_graph, sand_day_graph, sand_night_graph, snow_day_graph, snow_night_graph;
      plot_data = this.get('default_plot_data').slice();
      all_data = this.get('all_data');
      plot_chart = new AmCharts.AmXYChart();
      all_data.forEach((function(_this) {
        return function(data_point) {
          var new_point;
          new_point = {
            'height': data_point.height
          };
          return plot_data.pushObject(new_point);
        };
      })(this));
      plot_data.reverse();
      this.set('initial_plot_state', plot_data);
      plot_chart.dataProvider = plot_data;
      plot_chart.valueAxes = [
        {
          'id': 'x-axis',
          'titleFontSize': 14,
          'titleBold': false,
          'position': 'bottom',
          'title': 'Temperature (°F)',
          'maximum': 60,
          'minimum': 0,
          'autoGridCount': false,
          'gridCount': 10
        }, {
          'id': 'y-axis',
          'titleFontSize': 14,
          'titleBold': false,
          'title': 'Altitude (m)',
          'autoGridCount': false,
          'maximum': 1500,
          'minimum': 0,
          'gridCount': 15
        }
      ];
      plot_chart.fontFamily = 'omnes-pro';
      plot_chart.creditsPosition = 'top-left';
      plot_chart.legend = {
        'useGraphSettings': true,
        'position': 'right'
      };
      plot_chart.mouseWheelZoomEnabled = true;
      snow_day_graph = new AmCharts.AmGraph();
      snow_night_graph = new AmCharts.AmGraph();
      plowed_day_graph = new AmCharts.AmGraph();
      plowed_night_graph = new AmCharts.AmGraph();
      sand_day_graph = new AmCharts.AmGraph();
      sand_night_graph = new AmCharts.AmGraph();
      grass_day_graph = new AmCharts.AmGraph();
      grass_night_graph = new AmCharts.AmGraph();
      sand_day_graph.type = 'line';
      sand_day_graph.xField = 'sand_day';
      sand_day_graph.yField = 'height';
      sand_day_graph.bullet = 'round';
      sand_day_graph.title = 'Sand (Day)';
      sand_day_graph.balloonText = "[[sand_day]] °F at [[height]]m";
      sand_day_graph.lineColor = "#FF00FF";
      sand_night_graph.type = 'line';
      sand_night_graph.xField = 'sand_night';
      sand_night_graph.yField = 'height';
      sand_night_graph.bullet = 'square';
      sand_night_graph.title = 'Sand (Night)';
      sand_night_graph.balloonText = "[[sand_night]] °F at [[height]]m";
      sand_night_graph.lineColor = "#FF0000";
      plowed_day_graph.type = 'line';
      plowed_day_graph.xField = 'plowed_day';
      plowed_day_graph.yField = 'height';
      plowed_day_graph.bullet = 'round';
      plowed_day_graph.title = 'Plowed (Day)';
      plowed_day_graph.balloonText = "[[plowed_day]] °F at [[height]]m";
      plowed_day_graph.lineColor = "#FFBE82";
      plowed_night_graph.type = 'line';
      plowed_night_graph.xField = 'plowed_night';
      plowed_night_graph.yField = 'height';
      plowed_night_graph.bullet = 'square';
      plowed_night_graph.title = 'Plowed (Night)';
      plowed_night_graph.balloonText = "[[plowed_night]] °F at [[height]]m";
      plowed_night_graph.lineColor = "#A05000";
      grass_day_graph.type = 'line';
      grass_day_graph.xField = 'grass_day';
      grass_day_graph.yField = 'height';
      grass_day_graph.bullet = 'round';
      grass_day_graph.title = 'Grass (Day)';
      grass_day_graph.balloonText = "[[grass_day]] °F at [[height]]m";
      grass_day_graph.lineColor = "#00FF00";
      grass_night_graph.type = 'line';
      grass_night_graph.xField = 'grass_night';
      grass_night_graph.yField = 'height';
      grass_night_graph.bullet = 'square';
      grass_night_graph.title = 'Grass (Night)';
      grass_night_graph.balloonText = "[[grass_night]] °F at [[height]]m";
      grass_night_graph.lineColor = "#019646";
      snow_day_graph.type = 'line';
      snow_day_graph.xField = 'snow_day';
      snow_day_graph.yField = 'height';
      snow_day_graph.bullet = 'round';
      snow_day_graph.title = 'Snow (Day)';
      snow_day_graph.balloonText = "[[snow_day]] °F at [[height]]m";
      snow_day_graph.lineColor = "#00FFFF";
      snow_night_graph.type = 'line';
      snow_night_graph.xField = 'snow_night';
      snow_night_graph.yField = 'height';
      snow_night_graph.bullet = 'square';
      snow_night_graph.title = 'Snow (Night)';
      snow_night_graph.balloonText = "[[snow_night]] °F at [[height]]m";
      snow_night_graph.lineColor = "#0000FF";
      this.set('sand_day_graph', sand_day_graph);
      this.set('sand_night_graph', sand_night_graph);
      this.set('plowed_day_graph', plowed_day_graph);
      this.set('plowed_night_graph', plowed_night_graph);
      this.set('grass_day_graph', grass_day_graph);
      this.set('grass_night_graph', grass_night_graph);
      this.set('snow_day_graph', snow_day_graph);
      this.set('snow_night_graph', snow_night_graph);
      plot_chart.addGraph(sand_day_graph);
      plot_chart.addGraph(sand_night_graph);
      plot_chart.addGraph(plowed_day_graph);
      plot_chart.addGraph(plowed_night_graph);
      plot_chart.addGraph(grass_day_graph);
      plot_chart.addGraph(grass_night_graph);
      plot_chart.addGraph(snow_day_graph);
      plot_chart.addGraph(snow_night_graph);
      this.set('plot_data', plot_data);
      this.set('plot_chart', plot_chart);
      return plot_chart.write('chart-div');
    },
    initialize_simulation: function() {
      var all_data, balloon, balloon_container, balloon_height_data, balloon_start_x, balloon_start_y, balloon_text, canvas_height, cur_height, grass_background, grass_night_background, image_preloader, night_background, plot_data, plowed_background, plowed_night_background, radiation_game, range_size, range_value, sand_background, sand_night_background, sim_ground_level, snow_background, snow_night_background, start_background, starting_surface, starting_time_of_day;
      radiation_game = this.get('radiation_game');
      plot_data = this.get('plot_data');
      all_data = this.get('all_data');
      balloon_height_data = this.get('balloon_height_data');
      balloon_start_y = this.get('balloon_start_y');
      balloon_start_x = this.get('balloon_start_x');
      cur_height = this.get('cur_height');
      image_preloader = this.get('image_preloader');
      sand_background = new createjs.Bitmap(image_preloader.getResult('sand_day'));
      grass_background = new createjs.Bitmap(image_preloader.getResult('grass_day'));
      plowed_background = new createjs.Bitmap(image_preloader.getResult('plowed_day'));
      snow_background = new createjs.Bitmap(image_preloader.getResult('snow_day'));
      sand_night_background = new createjs.Bitmap(image_preloader.getResult('sand_night'));
      grass_night_background = new createjs.Bitmap(image_preloader.getResult('grass_night'));
      plowed_night_background = new createjs.Bitmap(image_preloader.getResult('plowed_night'));
      snow_night_background = new createjs.Bitmap(image_preloader.getResult('snow_night'));
      night_background = new createjs.Shape();
      sand_background.alpha = 0;
      night_background.alpha = 0;
      grass_background.alpha = 0;
      plowed_background.alpha = 0;
      snow_background.alpha = 0;
      grass_night_background.alpha = 0;
      plowed_night_background.alpha = 0;
      snow_night_background.alpha = 0;
      sand_night_background.alpha = 0;
      balloon_container = new createjs.Container();
      balloon_text = new createjs.Text('Height', '11px omnes-pro', '#FFFFFF');
      balloon_text.textAlign = 'center';
      balloon_text.y = -15;
      balloon = new createjs.Bitmap(image_preloader.getResult('balloon'));
      balloon.regX = balloon.getBounds().width / 2;
      balloon.regY = balloon.getBounds().height / 2;
      balloon_container.addChild(balloon, balloon_text);
      balloon_container.on("pressmove", (function(event) {
        return this.translate_balloon(event);
      }).bind(this));
      balloon_container.on("pressup", (function(event) {
        return this.balloon_release(event);
      }).bind(this));
      balloon_container.x = balloon_start_x;
      balloon_container.y = balloon_start_y;
      this.set('balloon_obj', balloon_container);
      this.set('balloon_label_obj', balloon_text);
      this.set('sand_day_obj', sand_background);
      this.set('grass_day_obj', grass_background);
      this.set('plowed_day_obj', plowed_background);
      this.set('snow_day_obj', snow_background);
      this.set('sand_night_obj', sand_night_background);
      this.set('grass_night_obj', grass_night_background);
      this.set('plowed_night_obj', plowed_night_background);
      this.set('snow_night_obj', snow_night_background);
      radiation_game.addChild(sand_background);
      radiation_game.addChild(grass_background);
      radiation_game.addChild(plowed_background);
      radiation_game.addChild(snow_background);
      radiation_game.addChild(sand_night_background);
      radiation_game.addChild(plowed_night_background);
      radiation_game.addChild(snow_night_background);
      radiation_game.addChild(grass_night_background);
      radiation_game.addChild(balloon_container);
      canvas_height = radiation_game.getBounds().height;
      sim_ground_level = this.get('sim_ground_level');
      range_value = canvas_height - (canvas_height - sim_ground_level + balloon_container.getBounds().height / 2);
      range_size = range_value / this.get('height_partitions');
      all_data.forEach((function(_this) {
        return function(data_point) {
          var new_data_point;
          new_data_point = {};
          new_data_point['height'] = data_point['height'];
          if (data_point['height'] - Math.floor(data_point['height']) < 0.5) {
            new_data_point['rounded_height'] = Math.floor(data_point['height']);
          } else {
            new_data_point['rounded_height'] = Math.ceil(data_point['height']);
          }
          new_data_point['range_max'] = range_value;
          range_value -= range_size;
          new_data_point['range_min'] = range_value;
          return balloon_height_data.pushObject(new_data_point);
        };
      })(this));
      balloon_height_data.find((function(element) {
        if (balloon_start_y >= element['range_min'] && balloon_start_y < element['range_max']) {
          return cur_height = element;
        }
      }));
      this.set('cur_height', cur_height);
      starting_surface = this.get('cur_surface');
      starting_time_of_day = this.get('cur_time_of_day');
      start_background = this.get(starting_surface + "_" + starting_time_of_day + "_obj");
      start_background.alpha = 1;
      this.set('cur_background', start_background);
      return createjs.Tween.get(radiation_game).to({
        alpha: 1
      }, 1000);
    },
    initialize_load_screen: function() {
      var dark_text, game_tick_listener, load_listener, load_text_container, radiation_game;
      radiation_game = this.get('radiation_game');
      radiation_game.setBounds(0, 0, 700, 400);
      load_text_container = new createjs.Container();
      dark_text = new createjs.Text('Arriving shortly!', '24px omnes-pro', '#63b4d6');
      dark_text.textAlign = 'center';
      load_text_container.addChild(dark_text);
      dark_text.x = load_text_container.getBounds().width / 2;
      load_text_container.regY = load_text_container.getBounds().height / 2;
      load_text_container.regX = load_text_container.getBounds().width / 2;
      load_text_container.y = radiation_game.getBounds().height / 2;
      load_text_container.x = radiation_game.getBounds().width / 2;
      radiation_game.addChild(load_text_container);
      createjs.Tween.get(load_text_container, {
        loop: true
      }).to({
        alpha: 0
      }, 1000).to({
        alpha: 1
      }, 1000);
      this.set('load_text_container', load_text_container);
      game_tick_listener = createjs.Ticker.addEventListener("tick", radiation_game);
      this.set('game_tick_listener', game_tick_listener);
      load_listener = createjs.Ticker.addEventListener("tick", (function(event) {
        return this.timed_load(event);
      }).bind(this));
      this.set('load_listener', load_listener);
      return this.initialize_graph();
    },
    timed_load: function(event) {
      var image_queue, load_listener, load_update_counter;
      load_update_counter = this.get('load_update_counter');
      if (load_update_counter === this.get('sim_fps')) {
        image_queue = this.get('image_preloader');
        load_listener = this.get('load_listener');
        image_queue.on("complete", (function(event) {
          return this.handle_load(event);
        }).bind(this));
        image_queue.loadFile(new createjs.LoadItem().set({
          id: 'sand_day',
          src: '/assets/images/simulations/radiation/sand-day.png',
          crossOrigin: true
        }));
        image_queue.loadFile(new createjs.LoadItem().set({
          id: 'sand_night',
          src: '/assets/images/simulations/radiation/sand-night.png',
          crossOrigin: true
        }));
        image_queue.loadFile(new createjs.LoadItem().set({
          id: 'plowed_day',
          src: '/assets/images/simulations/radiation/plow-day.png',
          crossOrigin: true
        }));
        image_queue.loadFile(new createjs.LoadItem().set({
          id: 'plowed_night',
          src: '/assets/images/simulations/radiation/plow-night.png',
          crossOrigin: true
        }));
        image_queue.loadFile(new createjs.LoadItem().set({
          id: 'grass_day',
          src: '/assets/images/simulations/radiation/grass-day.png',
          crossOrigin: true
        }));
        image_queue.loadFile(new createjs.LoadItem().set({
          id: 'grass_night',
          src: '/assets/images/simulations/radiation/grass-night.png',
          crossOrigin: true
        }));
        image_queue.loadFile(new createjs.LoadItem().set({
          id: 'snow_day',
          src: '/assets/images/simulations/radiation/snow-day.png',
          crossOrigin: true
        }));
        image_queue.loadFile(new createjs.LoadItem().set({
          id: 'snow_night',
          src: '/assets/images/simulations/radiation/snow-night.png',
          crossOrigin: true
        }));
        image_queue.loadFile(new createjs.LoadItem().set({
          id: 'balloon',
          src: '/assets/images/simulations/radiation/balloon.png',
          crossOrigin: true
        }));
        image_queue.load();
        createjs.Ticker.removeEventListener("tick", load_listener);
      }
      return this.set('load_update_counter', load_update_counter + 1);
    },
    handle_load: function(event) {
      var image_preloader, load_text_container, radiation_game;
      load_text_container = this.get('load_text_container');
      radiation_game = this.get('radiation_game');
      image_preloader = this.get('image_preloader');
      image_preloader.removeAllEventListeners();
      createjs.Tween.removeTweens(load_text_container);
      createjs.Ticker.removeEventListener("tick", this.get('load_listener'));
      radiation_game.removeChild(load_text_container);
      radiation_game.alpha = 0;
      this.initialize_simulation();
      return this.set('simulation_loaded', true);
    },
    balloon_release: function(event) {
      var balloon_obj, balloon_tick_listener;
      balloon_obj = this.get('balloon_obj');
      if (this.get('cur_height').height !== 0) {
        balloon_tick_listener = balloon_obj.addEventListener('tick', (function(event) {
          return this.animate_balloon(event);
        }).bind(this));
        return this.set('balloon_tick_listener', balloon_tick_listener);
      }
    },
    animate_balloon: function(event) {
      var balloon_animate_counter;
      balloon_animate_counter = this.get('balloon_animate_counter');
      if (balloon_animate_counter === this.get('sim_fps')) {
        createjs.Tween.get(event.target, {
          loop: true
        }).to({
          y: event.target.y + 5
        }, 1000).to({
          y: event.target.y
        }, 1000).to({
          y: event.target.y - 5
        }, 1000).to({
          y: event.target.y
        }, 1000);
        this.set('balloon_animate_counter', balloon_animate_counter + 1);
      }
      if (balloon_animate_counter < this.get('sim_fps')) {
        balloon_animate_counter += 1;
        return this.set('balloon_animate_counter', balloon_animate_counter);
      }
    },
    translate_balloon: function(event) {
      var balloon, balloon_height_data, balloon_label, cur_height, radiation_game, sim_ground_level;
      radiation_game = this.get('radiation_game');
      balloon = this.get('balloon_obj');
      cur_height = this.get('cur_height');
      balloon_height_data = this.get('balloon_height_data');
      balloon_label = this.get('balloon_label_obj');
      sim_ground_level = this.get('sim_ground_level');
      if (this.get('balloon_tick_listener')) {
        this.set('balloon_animate_counter', 0);
        balloon.removeEventListener('tick', this.get('balloon_tick_listener'));
        createjs.Tween.removeTweens(balloon);
      }
      if (event.currentTarget.y > sim_ground_level) {
        return event.currentTarget.y = sim_ground_level;
      } else {
        event.currentTarget.y = event.stageY;
        if (balloon.y < cur_height['range_min'] || balloon.y >= cur_height['range_max']) {
          balloon_height_data.find((function(element) {
            if (balloon.y >= element.range_min && balloon.y < element.range_max) {
              return cur_height = element;
            }
          }));
        }
        return this.set('cur_height', cur_height);
      }
    },
    transition_background: function(old_background, background) {
      if (old_background === background) {
        return false;
      } else {
        return createjs.Tween.get(old_background).to({
          alpha: 0
        }, 250).call(function() {
          return createjs.Tween.get(background).to({
            alpha: 1
          }, 250);
        });
      }
    },
    calculate_fahrenheit: function(kelvin_temp) {
      var fahrenheit;
      fahrenheit = (kelvin_temp * 1.8) - 459.67;
      fahrenheit = parseFloat(fahrenheit.toFixed(2));
      return fahrenheit;
    },
    actions: {
      reset_simulation: function() {
        this.initialize_simulation();
        return this.initialize_graph();
      },
      select_time_of_day: function(time_of_day) {
        if (time_of_day != null) {
          return this.set('cur_time_of_day', time_of_day);
        }
      },
      select_background: function(background) {
        if (background != null) {
          return this.set('cur_surface', background);
        }
      },
      clear_graph: function() {
        if (this.get('simulation_loaded')) {
          this.set('plot_data', []);
          return this.initialize_graph();
        }
      },
      plot_point: function() {
        var all_data, cur_height, cur_surface, cur_time_of_day, plot_chart, plot_data, relevant_point;
        if (this.get('simulation_loaded')) {
          plot_chart = this.get('plot_chart');
          cur_time_of_day = this.get('cur_time_of_day');
          cur_surface = this.get('cur_surface');
          cur_height = this.get('cur_height');
          plot_data = this.get('plot_data');
          all_data = this.get('all_data');
          relevant_point = all_data.find((function(element) {
            if (cur_height.height === element.height) {
              return element;
            }
          }));
          switch (cur_time_of_day) {
            case 'day':
              switch (cur_surface) {
                case 'snow':
                  return plot_data.forEach((function(_this) {
                    return function(plot_point) {
                      var graph;
                      if (plot_point.height === relevant_point.height) {
                        graph = _this.get('snow_day_graph');
                        plot_chart.removeGraph(graph);
                        plot_chart.addGraph(graph);
                        plot_point['snow_day'] = _this.calculate_fahrenheit(relevant_point.snow_day);
                        return plot_chart.validateData();
                      }
                    };
                  })(this));
                case 'plowed':
                  return plot_data.forEach((function(_this) {
                    return function(plot_point) {
                      var graph;
                      if (plot_point.height === relevant_point.height) {
                        graph = _this.get('plowed_day_graph');
                        plot_chart.removeGraph(graph);
                        plot_chart.addGraph(graph);
                        plot_point['plowed_day'] = _this.calculate_fahrenheit(relevant_point.plowed_day);
                        return plot_chart.validateData();
                      }
                    };
                  })(this));
                case 'sand':
                  return plot_data.forEach((function(_this) {
                    return function(plot_point) {
                      var graph;
                      if (plot_point.height === relevant_point.height) {
                        graph = _this.get('sand_day_graph');
                        plot_chart.removeGraph(graph);
                        plot_chart.addGraph(graph);
                        plot_point['sand_day'] = _this.calculate_fahrenheit(relevant_point.sand_day);
                        return plot_chart.validateData();
                      }
                    };
                  })(this));
                case 'grass':
                  return plot_data.forEach((function(_this) {
                    return function(plot_point) {
                      var graph;
                      if (plot_point.height === relevant_point.height) {
                        graph = _this.get('grass_day_graph');
                        plot_chart.removeGraph(graph);
                        plot_chart.addGraph(graph);
                        plot_point['grass_day'] = _this.calculate_fahrenheit(relevant_point.grass_day);
                        return plot_chart.validateData();
                      }
                    };
                  })(this));
              }
              break;
            case 'night':
              switch (cur_surface) {
                case 'snow':
                  return plot_data.forEach((function(_this) {
                    return function(plot_point) {
                      var graph;
                      if (plot_point.height === relevant_point.height) {
                        graph = _this.get('snow_night_graph');
                        plot_chart.removeGraph(graph);
                        plot_chart.addGraph(graph);
                        plot_point['snow_night'] = _this.calculate_fahrenheit(relevant_point.snow_night);
                        return plot_chart.validateData();
                      }
                    };
                  })(this));
                case 'plowed':
                  return plot_data.forEach((function(_this) {
                    return function(plot_point) {
                      var graph;
                      if (plot_point.height === relevant_point.height) {
                        graph = _this.get('plowed_night_graph');
                        plot_chart.removeGraph(graph);
                        plot_chart.addGraph(graph);
                        plot_point['plowed_night'] = _this.calculate_fahrenheit(relevant_point.plowed_night);
                        return plot_chart.validateData();
                      }
                    };
                  })(this));
                case 'sand':
                  return plot_data.forEach((function(_this) {
                    return function(plot_point) {
                      var graph;
                      if (plot_point.height === relevant_point.height) {
                        graph = _this.get('sand_night_graph');
                        plot_chart.removeGraph(graph);
                        plot_chart.addGraph(graph);
                        plot_point['sand_night'] = _this.calculate_fahrenheit(relevant_point.sand_night);
                        return plot_chart.validateData();
                      }
                    };
                  })(this));
                case 'grass':
                  return plot_data.forEach((function(_this) {
                    return function(plot_point) {
                      var graph;
                      if (plot_point.height === relevant_point.height) {
                        graph = _this.get('grass_night_graph');
                        plot_chart.removeGraph(graph);
                        plot_chart.addGraph(graph);
                        plot_point['grass_night'] = _this.calculate_fahrenheit(relevant_point.grass_night);
                        return plot_chart.validateData();
                      }
                    };
                  })(this));
              }
          }
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});