define('client/components/thinkspace/casespace/case_manager/assignment/clone', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'thinkspace-base/components/base'], function (exports, ember, ns, ajax, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    wizard_manager: ember['default'].inject.service(),
    case_manager: ember['default'].inject.service(),
    current_space: ember['default'].computed.reads('case_manager.current_space'),
    clone_spaces: ember['default'].computed(function() {
      var cm, space;
      cm = this.get('case_manager');
      space = this.get('current_space');
      return cm.get_store_spaces().without(space).sortBy('title');
    }),
    actions: {
      exit: function() {
        return this.get('wizard_manager').exit();
      },
      clone: function(space_id) {
        return this.clone_assignment(space_id);
      }
    },
    clone_assignment: function(space_id) {
      var assignment, query;
      assignment = this.get('model');
      this.totem_messages.show_loading_outlet({
        message: "Cloning " + (assignment.get('title')) + "..."
      });
      query = {
        model: assignment,
        id: assignment.get('id'),
        action: 'clone',
        verb: 'post',
        data: {
          space_id: space_id
        }
      };
      return ajax['default'].object(query).then((function(_this) {
        return function(payload) {
          var new_assignment;
          new_assignment = ajax['default'].normalize_and_push_payload('assignment', payload, {
            single: true
          });
          assignment.store.pushPayload(payload);
          _this.totem_messages.hide_loading_outlet();
          _this.totem_messages.api_success({
            source: _this,
            model: new_assignment,
            action: 'clone',
            i18n_path: ns['default'].to_o('assignment', 'clone')
          });
          return _this.send('exit');
        };
      })(this), (function(_this) {
        return function(error) {
          _this.totem_messages.hide_loading_outlet();
          return _this.totem_messages.api_failure(error, {
            source: _this,
            model: assignment,
            action: 'clone'
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});