define('client/models/thinkspace/observation_list/group', ['exports', 'ember', 'totem/ds/associations', 'totem/mixins/data'], function (exports, ember, ta, data_mixin) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend({
    title: ta['default'].attr('string'),
    groupable_type: ta['default'].attr('string'),
    groupable_id: ta['default'].attr('number')
  });

  exports['default'] = ___DefaultExportObject___;;

});