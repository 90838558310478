define('client/models/thinkspace/indented_list/list', ['exports', 'ember', 'totem/ds/associations', 'client/models/thinkspace/common/componentable'], function (exports, ember, ta, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(ta['default'].add(ta['default'].polymorphic('authable'), ta['default'].has_many('indented:responses', {
    reads: {
      filter: true,
      notify: true
    }
  }), ta['default'].has_many('indented:expert_responses')), {
    title: ta['default'].attr('string'),
    authable_id: ta['default'].attr('number'),
    authable_type: ta['default'].attr('string'),
    expert: ta['default'].attr('boolean'),
    settings: ta['default'].attr(),
    layout: ember['default'].computed.reads('settings.layout')
  });

  exports['default'] = ___DefaultExportObject___;;

});