define('client/components/thinkspace/common/user/password/new', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    layoutName: 'thinkspace/common/user/password/new',
    c_checkbox: ns['default'].to_p('common', 'shared', 'checkbox'),
    c_validated_input: ns['default'].to_p('common', 'shared', 'validated_input'),
    c_loader: ns['default'].to_p('common', 'shared', 'loader'),
    authenticating: false,
    actions: {
      submit: function() {
        var model;
        model = this.get('model');
        this.set('authenticating', true);
        return model.save().then((function(_this) {
          return function(response) {
            _this.set('authenticating', false);
            return _this.sendAction('goto_users_password_confirmation');
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});