define('client/thinkspace/team/teams/edit/controller', ['exports', 'ember', 'totem/ns', 'thinkspace-team/controllers/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    model_validation_message: null,
    actions: {
      save: function() {
        var team;
        team = this.get('model');
        if (team.get('isDirty')) {
          return team.save().then((function(_this) {
            return function(team) {
              _this.totem_messages.api_success({
                source: _this,
                model: team,
                action: 'save',
                i18n_path: ns['default'].to_o('team', 'save')
              });
              return _this.transition_to_index();
            };
          })(this), (function(_this) {
            return function(error) {
              _this.totem_messages.api_failure(error, {
                source: _this,
                model: team,
                action: 'save',
                without_key: false
              });
              return _this.set('model_validation_message', team.get('validation_message'));
            };
          })(this));
        } else {
          return this.transition_to_index();
        }
      },
      cancel: function() {
        var team;
        team = this.get('model');
        team.rollback();
        return this.transition_to_index();
      },
      clear_model_errors: function() {
        return this.set('model_validation_message', null);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});