define('client/thinkspace/casespace/case_manager/spaces/clone/controller', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace-case-manager/controllers/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    c_space_clone: ns['default'].to_p('case_manager', 'space', 'clone')
  });

  exports['default'] = ___DefaultExportObject___;;

});