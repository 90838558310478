define('client/components/thinkspace/resource/manage/tag/new', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    t_manage_tag_form: ns['default'].to_t('resource', 'manage', 'tag', 'form'),
    placeholder: 'New tag title',
    title: null,
    actions: {
      cancel: function() {
        return this.sendAction('cancel');
      },
      save: function() {
        var tag, taggable, title;
        taggable = this.get('taggable');
        title = this.get('title');
        tag = taggable.store.createRecord(ns['default'].to_p('tag'), {
          title: title,
          taggable_type: this.totem_scope.record_type_key(taggable),
          taggable_id: taggable.get('id')
        });
        return tag.save().then((function(_this) {
          return function(link) {
            _this.totem_messages.api_success({
              source: _this,
              model: tag,
              i18n_path: ns['default'].to_o('tag', 'save')
            });
            return _this.send('cancel');
          };
        })(this), (function(_this) {
          return function(error) {
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: tag
            });
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});