define('client/components/thinkspace/builder/toolbar', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    builder: ember['default'].inject.service(),
    classNames: ['ts-builder_toolbar'],
    c_builder_toolbar: ember['default'].computed.reads('builder.c_builder_toolbar')
  });

  exports['default'] = ___DefaultExportObject___;;

});