define('client/models/thinkspace/casespace/case_manager_template', ['exports', 'ember', 'ember-data', 'totem/ds/associations'], function (exports, ember, ds, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend({
    title: ta['default'].attr('string'),
    description: ta['default'].attr('string'),
    templateable_type: ta['default'].attr('string'),
    templateable_id: ta['default'].attr('number')
  });

  exports['default'] = ___DefaultExportObject___;;

});