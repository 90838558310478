define('client/components/thinkspace/observation_list/observation_note/new', ['exports', 'ember', 'thinkspace-base/components/base'], function (exports, ember, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    note_content: null,
    actions: {
      done: function() {
        return this.sendAction('done', this.get('note_content'));
      },
      cancel: function() {
        return this.sendAction('cancel');
      }
    },
    didInsertElement: function() {
      return this.$('textarea').focus();
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});