define('client/components/thinkspace/lab/result/vet_med', ['exports', 'ember', 'totem/ns', 'thinkspace-lab/vet_med_lab_result', 'thinkspace-base/components/base'], function (exports, ember, ns, lab_result, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    init: function() {
      this._super();
      return this.set('lab_result', lab_result['default'].create({
        lab: this.get('lab'),
        result: this.get('model')
      }));
    },
    c_observation: ember['default'].computed(function() {
      return ns['default'].to_p('lab:observation', this.get('category.value.component'));
    }),
    c_html_observation: ember['default'].computed(function() {
      return ns['default'].to_p('lab:observation', 'html');
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});