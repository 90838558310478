define('client/components/thinkspace/lab/builder/lab/result/show', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    admin: ember['default'].inject.service(ns['default'].to_p('lab', 'admin')),
    result_columns: ember['default'].computed(function() {
      return this.get('admin').result_type_columns(this.get('model'), this.get('model.admin_type'));
    }),
    title_column: ember['default'].computed.reads('result_columns.firstObject'),
    html_column: ember['default'].computed(function() {
      return this.get('result_columns').findBy('source', 'result');
    }),
    html_column_span: ember['default'].computed(function() {
      return this.get('admin.selected_category_headings.length') - 1;
    }),
    action_dropdown_collection: ember['default'].computed(function() {
      return [
        {
          display: this.t('builder.lab.admin.form.buttons.edit'),
          action: 'edit'
        }, {
          display: this.t('builder.lab.admin.form.buttons.clone'),
          action: 'clone'
        }, {
          display: this.t('builder.lab.admin.form.buttons.delete'),
          action: 'delete'
        }
      ];
    }),
    actions: {
      edit: function() {
        var admin, result;
        admin = this.get('admin');
        result = this.get('model');
        admin.set_action_overlay_model(result);
        return admin.set_action_overlay('c_result_edit');
      },
      clone: function() {
        var admin, clone, result, title;
        admin = this.get('admin');
        result = this.get('model');
        title = 'clone:' + result.get('title');
        clone = result.store.createRecord(ns['default'].to_p('lab:result'));
        return admin.save_result(clone, {
          values: result,
          properties: {
            title: title
          }
        }).then((function(_this) {
          return function() {
            return ember['default'].run.schedule('afterRender', function() {
              return admin.on_drop_result_reorder();
            });
          };
        })(this));
      },
      "delete": function() {
        var admin, result;
        admin = this.get('admin');
        result = this.get('model');
        admin.set_action_overlay_model(result);
        return admin.set_action_overlay('c_result_delete');
      },
      edit_value: function(component) {
        var admin;
        admin = this.get('admin');
        return admin.set_result_value_edit_component(component);
      },
      save_value: function() {
        var admin, result;
        admin = this.get('admin');
        result = this.get('model');
        return admin.save_result(result).then((function(_this) {
          return function() {
            return admin.clear_overlay_values();
          };
        })(this));
      },
      cancel_value: function() {
        var admin;
        admin = this.get('admin');
        return admin.clear_overlay_values();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});