define('client/components/thinkspace/markup/sidepocket/discussions', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'totem/scope', 'totem/ds/associations', 'thinkspace-base/components/base'], function (exports, ember, ns, ajax, scope, ta, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    manager: ember['default'].inject.service(ns['default'].to_p('markup', 'manager')),
    casespace: ember['default'].inject.service(),
    thinkspace: ember['default'].inject.service(),
    is_library_open: ember['default'].computed.reads('manager.is_library_open'),
    is_pdf: ember['default'].computed.reads('manager.is_pdf'),
    is_pdf_loading: ember['default'].computed.reads('manager.is_pdf_loading'),
    current_phase: ember['default'].computed.reads('casespace.current_phase'),
    other_discussions: ember['default'].computed('totem_scope.ownerable_record', 'casespace.current_phase', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var discussions, manager, model;
          model = _this.get('model');
          manager = _this.get('manager');
          discussions = ember['default'].makeArray();
          if (ember['default'].isPresent(model)) {
            discussions = _this.container.lookup('store:main').filter(ns['default'].to_p('markup', 'discussion'), function(discussion) {
              return manager.discussion_has_authable(discussion, model) && manager.discussion_has_ownerable(discussion) && manager.discussion_discussionable_is_in_store(discussion) && !manager.discussion_has_discussionable(discussion, model);
            });
          }
          return resolve(discussions);
        };
      })(this));
      return ta['default'].PromiseArray.create({
        promise: promise
      });
    }),
    phase_discussions: ember['default'].computed('totem_scope.ownerable_record', 'casespace.current_phase', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var discussions, manager, model;
          model = _this.get('model');
          manager = _this.get('manager');
          discussions = ember['default'].makeArray();
          if (ember['default'].isPresent(model)) {
            discussions = _this.container.lookup('store:main').filter(ns['default'].to_p('markup', 'discussion'), function(discussion) {
              return manager.discussion_has_authable(discussion, model) && manager.discussion_has_ownerable(discussion) && manager.discussion_discussionable_is_in_store(discussion) && manager.discussion_has_discussionable(discussion, model);
            });
          }
          return resolve(discussions);
        };
      })(this));
      return ta['default'].PromiseArray.create({
        promise: promise
      });
    }),
    discussions_sort_by: ['sort_by'],
    sorted_phase_discussions: ember['default'].computed.sort('phase_discussions', 'discussions_sort_by'),
    sorted_other_discussions: ember['default'].computed.sort('other_discussions', 'discussions_sort_by'),
    has_no_phase_discussions: ember['default'].computed.empty('phase_discussions'),
    has_no_other_discussions: ember['default'].computed.empty('other_discussions'),
    has_no_discussions: ember['default'].computed.and('has_no_phase_discussions', 'has_no_other_discussions'),
    fetch_comments_observer: ember['default'].observer('totem_scope.ownerable_record', 'casespace.current_phase', function() {
      this.reset_all_data_loaded();
      if (ember['default'].isPresent(this.get('casespace.current_phase'))) {
        return this.fetch_discussions().then((function(_this) {
          return function() {
            if (!_this.is_destroyed()) {
              return _this.set_all_data_loaded();
            }
          };
        })(this));
      }
    }),
    c_library: ns['default'].to_p('markup', 'sidepocket', 'library'),
    c_loader: ns['default'].to_p('common', 'loader'),
    c_markup_discussion_sidepocket: ns['default'].to_p('markup', 'sidepocket', 'discussion'),
    init: function() {
      this._super();
      return this.fetch_discussions().then((function(_this) {
        return function(discussions) {
          _this.set_all_data_loaded();
          return ember['default'].run.schedule('afterRender', function() {
            return _this.didInsertElement();
          });
        };
      })(this));
    },
    didInsertElement: function() {
      return this.get('manager').open_comments();
    },
    willDestroyElement: function() {
      return this.get('manager').close_comments();
    },
    click: function(e) {
      var library_comment, manager, target_class;
      manager = this.get('manager');
      library_comment = manager.get_selected_library_comment();
      target_class = manager.get_library_target_class();
      if (e.target.classList.contains(target_class) && library_comment) {
        this.add_comment_to_phase(false, {
          library_comment: library_comment
        });
        return manager.reset_selected_library_comment();
      }
    },
    is_destroyed: function() {
      return this.get('isDestroyed');
    },
    fetch_discussions: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model, options;
          model = _this.get('current_phase');
          options = {
            ownerable: _this.totem_scope.get_ownerable_record(),
            authable: model,
            discussionable: model
          };
          return _this.get('manager').get_discussions(options).then(function(discussions) {
            return resolve(discussions);
          });
        };
      })(this));
    },
    set_anonymized_commenterables: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get('discussions').then(function(discussions) {
            var promises;
            promises = [];
            discussions.forEach(function(discussion) {
              return promises.pushObject(discussion.get_commenterables());
            });
            return ember['default'].RSVP.Promise.all(promises).then(function(commenterables) {
              var map;
              commenterables = _this.get('taf').flatten(commenterables).uniq();
              map = ember['default'].Map.create();
              commenterables.forEach(function(commenterable, index) {
                return map.set(commenterable, index);
              });
              _this.set('anonymized_commenterables', map);
              return resolve(map);
            });
          });
        };
      })(this));
    },
    add_comment_to_phase: function(edit, comment_options) {
      var manager, options, phase;
      if (edit == null) {
        edit = true;
      }
      if (comment_options == null) {
        comment_options = {};
      }
      manager = this.get('manager');
      phase = this.get('model');
      options = {
        authable: phase,
        discussionable: phase,
        ownerable: this.totem_scope.get_ownerable_record(),
        creatorable: this.totem_scope.get_current_user()
      };
      if (comment_options.library_comment) {
        options.save = true;
      }
      return manager.add_discussion(options).then((function(_this) {
        return function(discussion) {
          comment_options.commenterable = _this.totem_scope.get_current_user();
          if (edit) {
            return manager.add_comment_to_discussion_and_edit(discussion, comment_options);
          } else {
            return manager.add_comment_to_discussion(discussion, comment_options);
          }
        };
      })(this));
    },
    actions: {
      add_comment_to_library: function(comment) {
        return this.get('manager').add_comment_to_library(comment);
      },
      select_library_comment: function(comment) {
        return this.add_comment({
          library_comment: comment
        });
      },
      open_library: function() {
        return this.get('manager').open_library();
      },
      close_library: function() {
        return this.get('manager').close_library();
      },
      add_comment_to_phase: function() {
        return this.add_comment_to_phase();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});