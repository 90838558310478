define('client/components/thinkspace/dock', ['exports', 'ember', 'thinkspace-dock/map', 'thinkspace-base/components/base'], function (exports, ember, dock_map, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    route_change: ember['default'].observer('current_route', function() {
      return this.set_dock_addons();
    }),
    addons: [],
    init: function() {
      this._super();
      return this.set_dock_addons();
    },
    set_dock_addons: function() {
      var required_addons, route, route_addons;
      route = this.get('current_route');
      required_addons = dock_map['default'].get_required_addons(route) || [];
      route_addons = dock_map['default'].get_route_addons(route) || [];
      return this.set('addons', this.group_addons(required_addons, route_addons));
    },
    group_addons: function(required_addons, route_addons) {
      var addon, all_addons, first, i, last, len, middle;
      all_addons = required_addons.concat(route_addons);
      first = [];
      middle = [];
      last = [];
      for (i = 0, len = all_addons.length; i < len; i++) {
        addon = all_addons[i];
        if (addon.group != null) {
          if (addon.group === 'first') {
            first.pushObject(addon);
          } else if (addon.group === 'middle') {
            middle.pushObject(addon);
          } else if (addon.group === 'last') {
            last.pushObject(addon);
          }
        } else {
          middle.pushObject(addon);
        }
      }
      return first.concat(middle.concat(last));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});