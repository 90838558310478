define('client/_config/thinkspace/readiness_assurance/query_params', ['exports'], function (exports) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = {
    'ra:assessment': {
      ownerable: true,
      authable: true
    },
    'ra:response': {
      ownerable: true,
      authable: true
    },
    'ra:chat': {
      ownerable: true,
      authable: true
    },
    'ra:status': {
      ownerable: true,
      authable: true
    },
    'ra:server_event': {
      ownerable: true,
      authable: true
    }
  };

  exports['default'] = ___DefaultExportObject___;;

});