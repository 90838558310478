define('client/components/thinkspace/indented_list/list', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tvo: ember['default'].inject.service(),
    c_loader: ns['default'].to_p('common', 'loader'),
    c_list_responses: ns['default'].to_p('indented_list', 'list', 'responses'),
    c_list_expert_responses: ns['default'].to_p('indented_list', 'list', 'expert_responses'),
    init: function() {
      this._super();
      this.get('tvo.helper').define_ready(this);
      return this.get('tvo.hash').set_value('indented_list_attributes', this.get('attributes'));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});