define('client/components/thinkspace/readiness_assurance/admin/shared/time_after', ['exports', 'ember', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    after_buttons: null,
    selected_minutes: null,
    init: function() {
      this._super();
      this.set_after_buttons();
      ember['default'].defineProperty(this, 'show_formatted', ember['default'].computed("rad." + this.time, function() {
        return ember['default'].isPresent(this.formatted) && ember['default'].isPresent(this.get("rad." + this.time));
      }));
      ember['default'].defineProperty(this, 'formatted_time', ember['default'].computed.reads("rad." + this.time + "_formatted"));
      return this.validate = this.rad.validate;
    },
    set_after_buttons: function() {
      var b, buttons, end, hash, i, j, k, label, len, n, range, ref, ref1, running, start;
      buttons = [];
      range = this.range || {
        start: 1,
        end: 5,
        by: 1
      };
      ref = ember['default'].makeArray(range);
      for (j = 0, len = ref.length; j < len; j++) {
        hash = ref[j];
        start = hash.start;
        end = hash.end;
        b = hash.by;
        if (start && end && b) {
          n = Math.floor((end - start) / b) + 1;
          running = start;
          for (i = k = 1, ref1 = n; 1 <= ref1 ? k <= ref1 : k >= ref1; i = 1 <= ref1 ? ++k : --k) {
            if (i !== 1) {
              running += b;
            }
            label = this.am.pluralize(running, 'minute');
            buttons.push({
              id: running,
              label: running + " " + label
            });
          }
        }
      }
      return this.set('after_buttons', buttons);
    },
    actions: {
      select: function(minutes) {
        var after_at;
        this.set('selected_minutes', minutes);
        after_at = new Date();
        this.am.adjust_by_minutes(after_at, minutes);
        this.rad.set(this.time, after_at);
        this.rad.set(this.time + "_formatted", this.am.format_time_only(after_at));
        if (this.validate) {
          return this.sendAction('validate');
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});