define('client/components/thinkspace/indented_list/list/layout/diagnostic_path', ['exports', 'ember', 'totem/ns', 'client/components/thinkspace/indented_list/list/layout/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    validate_indented_list: function(status) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var action, messages, rm, section, tvo;
          tvo = _this.get('tvo');
          rm = _this.get_response_manager();
          messages = [];
          status.set_is_valid(true);
          section = _this.get_indented_list_source_section();
          if (!section) {
            return resolve();
          }
          _this.validate_value_items_exist(status);
          if (!status.get_is_valid()) {
            return resolve();
          }
          action = 'itemables';
          if (!tvo.section.has_action(section, action)) {
            return resolve();
          }
          return tvo.section.call_action(section, action).then(function(itemables) {
            var first, type;
            _this.validate_itemables_exist(status, itemables);
            if (!status.get_is_valid()) {
              return resolve();
            }
            first = itemables.get('firstObject');
            type = _this.totem_scope.get_record_path(first);
            itemables.forEach(function(itemable) {
              var id, item;
              id = parseInt(itemable.get('id'));
              item = rm.is_itemable_used_by_another_item({
                itemable_type: type,
                itemable_id: id
              });
              if (ember['default'].isBlank(item)) {
                return _this.add_itemable_unused_message(status, itemable, messages);
              }
            });
            _this.set_status_messages(status, messages);
            return resolve();
          }, function(error) {
            return reject(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return reject(error);
        };
      })(this));
    },
    set_status_messages: function(status, messages) {
      var count, errors, hdr_msg, i, len, msg, status_messages;
      if (status.get_is_valid()) {
        return;
      }
      count = status.get_invalid_count();
      hdr_msg = "Must use ALL observations - " + count + " ";
      hdr_msg += count <= 1 ? 'observation was not used.' : 'observations were not used.';
      errors = [];
      errors.push("<span style='font-weight: 500'>" + hdr_msg + "</span>");
      errors.push('<ol>');
      for (i = 0, len = messages.length; i < len; i++) {
        msg = messages[i];
        errors.push("<li>" + msg + "</li>");
      }
      errors.push('</ol>');
      status_messages = [];
      status_messages.push(errors.join('').htmlSafe());
      return status.set_status_messages(status_messages);
    },
    add_itemable_unused_message: function(status, itemable, messages) {
      status.increment_invalid_count();
      status.set_is_valid(false);
      return messages.push(itemable.get('value'));
    },
    validate_value_items_exist: function(status) {
      var items;
      items = this.get_response_manager_items();
      if (ember['default'].isPresent(items)) {
        return;
      }
      status.set_is_valid(false);
      return status.set_status_messages(["The diagnostic path is blank."]);
    },
    validate_itemables_exist: function(status, itemables) {
      if (ember['default'].isPresent(itemables)) {
        return;
      }
      status.set_is_valid(false);
      return status.set_status_messages(["No observations present."]);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});