define('client/models/thinkspace/common/key', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend({
    ttz: ember['default'].inject.service(),
    source: ta['default'].attr('string'),
    expires_at: ta['default'].attr('date'),
    friendly_expires_at: ember['default'].computed('expires_at', function() {
      var date;
      date = this.get('expires_at');
      if (!ember['default'].isPresent(date)) {
        return 'N/A';
      }
      return this.get('ttz').format(date, {
        format: 'MMMM Do YYYY, h:mm z',
        zone: this.get('ttz').get_client_zone_iana()
      });
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});