define('client/components/thinkspace/readiness_assurance/admin/trat/summary/teams/team_qm', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    chat_messages: ember['default'].computed.reads('cm.messages'),
    init: function() {
      this._super();
      this.qid = this.qm.qid;
      this.rm = this.qm.rm;
      return this.cm = this.rm.chat_manager_map.get(this.qid);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});