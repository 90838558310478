define('client/components/thinkspace/casespace/case_manager/team/edit', ['exports', 'ember', 'totem/ds/associations', 'totem/ns', 'totem/ajax', 'totem/util', 'client/components/thinkspace/casespace/case_manager/team/form'], function (exports, ember, ta, ns, ajax, util, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    color: ember['default'].computed.reads('model.color'),
    title: ember['default'].computed.reads('model.title'),
    css_style: ember['default'].computed('color', function() {
      var color, css;
      color = this.get('color');
      if (!ember['default'].isPresent(color)) {
        return '';
      }
      css = '';
      css += "background-color: #" + color + ";";
      return new ember['default'].Handlebars.SafeString(css);
    }),
    get_model: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return resolve(_this.get('model'));
        };
      })(this));
    },
    reset: function() {
      this.set('color', this.get('model.color'));
      return this.set('title', this.get('model.title'));
    },
    transition_from_save: function(team) {
      return this.get('team_manager').transition_to_team_set_show(team);
    },
    actions: {
      destroy: function() {
        var team;
        team = this.get('model');
        return team.get(ns['default'].to_p('team_set')).then((function(_this) {
          return function(team_set) {
            _this.totem_messages.show_loading_outlet({
              message: 'Deleting team...'
            });
            return team.destroyRecord().then(function(team) {
              team_set.decrement_team_count();
              _this.get('team_manager').update_unassigned_users();
              _this.get('team_manager').transition_to_team_set_show(team);
              return _this.totem_messages.hide_loading_outlet();
            });
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});