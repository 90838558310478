define('client/components/thinkspace/lab/builder/lab/chart/admin', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    init: function() {
      var admin, chart;
      this._super();
      admin = this.get('admin');
      chart = this.get('model');
      admin.clear();
      admin.set_admin_component(this);
      admin.set_store(chart.store);
      admin.set_chart(chart);
      return admin.set('t', this.t);
    },
    admin: ember['default'].inject.service(ns['default'].to_p('lab', 'admin')),
    is_active: false,
    actions: {
      select: function() {
        return this.set('is_active', true);
      },
      exit: function() {
        return this.send('clear_and_exit');
      },
      clear_and_exit: function() {
        var admin;
        admin = this.get('admin');
        admin.set_action_overlay_off();
        admin.clear();
        return this.set('is_active', false);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});