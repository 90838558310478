define('client/models/thinkspace/diagnostic_path/path_item', ['exports', 'ember', 'totem/ds/associations', 'totem/mixins/data'], function (exports, ember, ta, data_mixin) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(data_mixin['default'], ta['default'].add(ta['default'].belongs_to('path', {
    reads: {}
  }), ta['default'].polymorphic('path_itemable'), ta['default'].has_many('path_items', {
    inverse: ta['default'].to_p('path_item:parent'),
    reads: [
      {
        name: 'path_items',
        sort: 'position:asc'
      }, {
        name: 'unscoped_path_items',
        sort: 'position:asc'
      }
    ]
  }), ta['default'].belongs_to('path_item:parent', {
    type: ta['default'].to_p('path_item'),
    inverse: ta['default'].to_p('path_items')
  })), {
    position: ta['default'].attr('number'),
    description: ta['default'].attr('string'),
    path_id: ta['default'].attr('number'),
    parent_id: ta['default'].attr('number'),
    path_itemable_id: ta['default'].attr('number'),
    path_itemable_type: ta['default'].attr('string'),
    ownerable_id: ta['default'].attr('number'),
    ownerable_type: ta['default'].attr('string'),
    category: ta['default'].attr(),
    totem_data_config: {
      ability: true
    },
    instructions: "Double-click to edit the observation",
    has_children: ember['default'].computed.gt(ta['default'].to_prop('path_items', 'length'), 0),
    mechanism_icon: ember['default'].computed(function() {
      return '<i class="fa fa-circle-o mechanism" title="Mechanism"></i>'.htmlSafe();
    }),
    is_mechanism: ember['default'].computed('path_itemable_id', 'category', function() {
      return !ember['default'].isPresent(this.get('path_itemable_id')) && !ember['default'].isPresent(this.get('category'));
    }),
    has_path_itemable: ember['default'].computed.notEmpty('path_itemable_id'),
    itemable: ember['default'].computed('path_itemable.value', 'description', 'position', function() {
      return ta['default'].PromiseObject.create({
        promise: this.get('path_itemable').then((function(_this) {
          return function(itemable) {
            var value;
            if (itemable) {
              value = _this.get_path_itemable_value(itemable);
            } else {
              value = _this.get_path_item_value();
            }
            return {
              value: (value || '').htmlSafe()
            };
          };
        })(this))
      });
    }),
    category_icon: ember['default'].computed('path_itemable.category_icon', 'category', function() {
      var category, has_path_itemable;
      has_path_itemable = this.get('has_path_itemable');
      if (has_path_itemable) {
        return this.get('path_itemable.category_icon');
      } else {
        category = this.get('category');
        if (ember['default'].isPresent(category)) {
          return this.get('category_json_icon');
        } else {
          return this.get('mechanism_icon');
        }
      }
    }),
    category_id: ember['default'].computed(function() {
      return (this.get('category.name') || '').toLowerCase();
    }),
    category_json_icon: ember['default'].computed(function() {
      var icon;
      switch (this.get('category_id')) {
        case 'd':
          console.log(this);
          icon = '<i class="fa fa-flask data" title="Data"></i>';
          break;
        case 'h':
          icon = '<i class="im im-book history" title="History"></i>';
          break;
        case 'm':
          icon = '<i class="fa fa-circle-o mechanism" title="Mechanism"></i>';
          break;
        default:
          icon = '<i class="fa fa-square unknown" title="Unknown"></i>';
      }
      return icon.htmlSafe();
    }),
    get_path_itemable_value: function(itemable) {
      return itemable.get('value');
    },
    get_path_item_value: function() {
      return this.get('description') || this.get('instructions');
    },
    didLoad: function() {
      var parent_id;
      parent_id = this.get('parent_id');
      return this.get(ta['default'].to_p('path')).then((function(_this) {
        return function(path) {
          var path_items;
          path_items = path.get(ta['default'].to_p('path_items'));
          return path_items.then(function(path_items) {
            if (!path_items.contains(_this)) {
              path_items.pushObject(_this);
            }
            if (ember['default'].isPresent(parent_id)) {
              return _this.store.find(ta['default'].to_p('path_item'), parent_id).then(function(parent) {
                return parent.get(ta['default'].to_p('path_items')).then(function(path_items) {
                  if (!path_items.contains(_this)) {
                    return path_items.pushObject(_this);
                  }
                });
              });
            }
          });
        };
      })(this));
    },
    didUpdate: function() {
      return this.didLoad();
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});