define('client/components/thinkspace/readiness_assurance/admin/messages/view', ['exports', 'ember', 'totem/ns', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    admin_message_rooms: ember['default'].computed(function() {
      return this.am.ra.get_admin_room();
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});