define('client/components/thinkspace/builder/steps/parts/phases/new', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    model: null,
    phase_templates: null,
    c_loader: ns['default'].to_p('common', 'loader'),
    init: function() {
      this._super();
      return this.get_templates().then((function(_this) {
        return function() {
          return _this.set_all_data_loaded();
        };
      })(this));
    },
    get_templates: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          query = {
            action: 'templates',
            verb: 'GET'
          };
          return _this.tc.query(ns['default'].to_p('phase'), query, {
            payload_type: ns['default'].to_p('builder:template')
          }).then(function(templates) {
            _this.set('phase_templates', templates);
            return resolve();
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    clone_ajax: function(template) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          query = {
            action: 'clone',
            verb: 'POST',
            assignment_id: _this.get('model.id'),
            template_id: template.get('id')
          };
          return _this.tc.query(ns['default'].to_p('phase'), query, {
            single: true
          }).then(function() {
            return resolve();
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    actions: {
      clone: function(template) {
        this.totem_messages.show_loading_outlet();
        return this.clone_ajax(template).then((function(_this) {
          return function() {
            _this.sendAction('cancel');
            return _this.totem_messages.hide_loading_outlet();
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});