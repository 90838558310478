define('client/components/thinkspace/casespace/phase/select_phase_state', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    phase_manager: ember['default'].inject.service(),
    phase_states: ember['default'].computed('model', function() {
      return this.get('phase_manager.map').get_ownerable_phase_states(this.get('model'));
    }),
    actions: {
      select_phase_state: function(phase_state) {
        return this.sendAction('select_phase_state', phase_state);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});