define('client/templates/thinkspace/team/teams/destroy', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ts-grid_row");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","small-12 ts-grid_columns");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","form");
        var el4 = dom.createElement("fieldset");
        var el5 = dom.createElement("legend");
        var el6 = dom.createTextNode("Delete Team");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-grid_row");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","small-12 ts-grid_columns");
        var el7 = dom.createElement("h6");
        var el8 = dom.createTextNode("Are you sure you want to delete this team?");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","team_team-delete-name");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-grid_row");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","small-2 ts-grid_columns");
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","btn btn-primary btn-small");
        var el8 = dom.createTextNode("Delete");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","small-2 ts-grid_columns");
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","btn btn-primary btn-small");
        var el8 = dom.createTextNode("Cancel");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","small-8 ts-grid_columns");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, inline = hooks.inline, content = hooks.content, get = hooks.get, element = hooks.element;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [1, 0, 0, 0]);
        var element1 = dom.childAt(element0, [2]);
        var element2 = dom.childAt(element1, [0, 0]);
        var element3 = dom.childAt(element1, [1, 0]);
        var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
        var morph1 = dom.createMorphAt(dom.childAt(element0, [1, 0, 0, 1]),0,0);
        dom.insertBoundary(fragment, 0);
        inline(env, morph0, context, "partial", ["thinkspace/team/shared/validation_errors"], {});
        content(env, morph1, context, "title");
        element(env, element2, context, "action", ["destroy", get(env, context, "team")], {});
        element(env, element3, context, "action", ["cancel"], {});
        return fragment;
      }
    };
  }()));

});