define('client/thinkspace/builder/controller', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Controller.extend({
    c_builder_header: ns['default'].to_p('builder', 'header'),
    c_builder_toolbar: ns['default'].to_p('builder', 'toolbar'),
    c_messages: ns['default'].to_p('dock', 'messages', 'messages')
  });

  exports['default'] = ___DefaultExportObject___;;

});