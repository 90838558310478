define('client/thinkspace/casespace/case_manager/team_sets/new/route', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace-case-manager/routes/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    thinkspace: ember['default'].inject.service(),
    team_manager: ember['default'].inject.service(),
    titleToken: function(model) {
      return 'New Team Set for ' + model.get('title');
    },
    activate: function() {
      return this.get('thinkspace').disable_wizard_mode();
    },
    model: function(params) {
      return this.modelFor(ns['default'].to_p('case_manager', 'team_sets'));
    },
    actions: {
      transition_to_team_set_show: function(space, team_set) {
        return this.transitionTo(ns['default'].to_r('case_manager', 'team_sets', 'show'), space, team_set);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});