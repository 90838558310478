define('client/components/thinkspace/casespace/case_manager/assignment/peer_assessment/state', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    model_state: null,
    is_approved: ember['default'].computed.equal('model_state', 'approved'),
    is_neutral: ember['default'].computed.equal('model_state', 'neutral'),
    is_sent: ember['default'].computed.equal('model_state', 'sent'),
    is_submitted: ember['default'].computed.equal('model_state', 'submitted'),
    is_ignored: ember['default'].computed.equal('model_state', 'ignored'),
    state_text: ember['default'].computed('model_state', function() {
      var model_state, state_text;
      model_state = this.get('model_state');
      if (ember['default'].isPresent(model_state)) {
        if (model_state === 'neutral') {
          state_text = 'In-Progress';
        } else {
          state_text = model_state.charAt(0).toUpperCase() + model_state.slice(1);
        }
        return state_text;
      }
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});