define('client/_config/thinkspace/readiness_assurance/routes', ['exports'], function (exports) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = {
    '/casespace': {
      'thinkspace/casespace/readiness_assurance': {
        path: '/cases/:assignment_id/readiness_assurance',
        resource: true
      }
    },
    '/casespace/cases/:assignment_id/readiness_assurance': {
      dashboard: {
        path: '/dashboard'
      }
    }
  };

  exports['default'] = ___DefaultExportObject___;;

});