define('client/components/thinkspace/peer_assessment/builder/overview/settings', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    builder: ember['default'].inject.service(),
    assessments: null,
    init: function() {
      this._super();
      return this.set_assessments().then((function(_this) {
        return function() {
          return _this.set_all_data_loaded();
        };
      })(this));
    },
    set_assessments: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get('builder').get_assignment().then(function(assignment) {
            var query;
            query = {
              id: assignment.get('id'),
              action: 'phase_componentables',
              componentable_type: ns['default'].to_p('tbl:assessment')
            };
            return _this.tc.query(ns['default'].to_p('assignment'), query, {
              payload_type: ns['default'].to_p('tbl:assessment')
            }).then(function(assessments) {
              _this.set('assessments', assessments);
              return resolve();
            }, function(error) {
              return _this.error(error);
            });
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    set_assessment_on_model: function(assessment) {
      var model;
      model = this.get('model');
      model.set('assessment_id', assessment.id);
      return model.save().then((function(_this) {
        return function(model) {
          return console.log("Saved model: ", model);
        };
      })(this));
    },
    actions: {
      select: function(assessment) {
        return this.set_assessment_on_model(assessment);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});