define('client/components/thinkspace/common/space/show', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    c_space_header: ns['default'].to_p('space', 'header'),
    c_loader: ns['default'].to_p('common', 'shared', 'loader')
  });

  exports['default'] = ___DefaultExportObject___;;

});