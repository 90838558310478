define('client/users/show/profile/route', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin', 'thinkspace-base/base/route', 'totem/ns'], function (exports, ember, auth_mixin, base, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    model: function() {
      return this.modelFor('users.show');
    },
    setupController: function(controller, model) {
      return controller.set('model', model);
    },
    renderTemplate: function() {
      return this.render(ns['default'].to_p('users', 'show', 'profile'));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});