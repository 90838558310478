define('client/components/thinkspace/casespace/gradebook/assignment/roster/assignment/scores', ['exports', 'ember', 'ember-data', 'totem/ns', 'thinkspace-casespace-gradebook/calc', 'thinkspace-casespace-gradebook/common_helpers', 'totem/mixins/group_by', 'thinkspace-base/components/base'], function (exports, ember, ds, ns, calc, gch, group_by_mixin, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend(group_by_mixin['default'], {
    c_dropdown_split_button: ns['default'].to_p('common', 'dropdown_split_button'),
    c_score: ns['default'].to_p('gradebook', 'assignment', 'roster', 'shared', 'score'),
    t_sort_links: ns['default'].to_t('gradebook', 'assignment', 'roster', 'shared', 'sort_links'),
    t_table_options: ns['default'].to_t('gradebook', 'assignment', 'roster', 'shared', 'table_options'),
    t_header: ns['default'].to_t('gradebook', 'assignment', 'roster', 'assignment', 'header'),
    t_group: ns['default'].to_t('gradebook', 'assignment', 'roster', 'assignment', 'group'),
    t_group_sort_by: ns['default'].to_t('gradebook', 'assignment', 'roster', 'assignment', 'group_sort_by'),
    t_supplement: ns['default'].to_t('gradebook', 'assignment', 'roster', 'assignment', 'supplement'),
    decimals: ember['default'].computed.reads('roster.content.decimals'),
    supplement: ember['default'].computed.reads('roster.content.supplement'),
    sort_links: ember['default'].computed('sort_order', function() {
      return gch['default'].get_sort_links(this.get_sort_def(), this.get_sort_order(), this.get_sort_link_for());
    }),
    dropdown_collection: ember['default'].computed('sort_order', function() {
      var collection, sort_links;
      collection = [];
      sort_links = gch['default'].get_sort_links(this.get_sort_def(), this.get_sort_order(), this.get_sort_link_for());
      sort_links.forEach((function(_this) {
        return function(sort_link) {
          return collection.pushObject({
            display: sort_link.text,
            action: sort_link.key
          });
        };
      })(this));
      return collection;
    }),
    selected_sort_order: ember['default'].computed('sort_order', function() {
      var selected, sort_links;
      sort_links = gch['default'].get_sort_links(this.get_sort_def(), this.get_sort_order(), this.get_sort_link_for());
      selected = sort_links.findBy('active', true);
      return selected;
    }),
    roster: ember['default'].computed('sort_order', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          _this.set('show_scores', false);
          return ember['default'].run.next(function() {
            return _this.get_roster().then(function(roster) {
              _this.totem_messages.hide_loading_outlet();
              _this.set('row_number', 0);
              resolve(roster);
              return _this.set('show_scores', true);
            });
          });
        };
      })(this));
      return ds['default'].PromiseObject.create({
        promise: promise
      });
    }),
    server_roster: null,
    show_scores: true,
    sort_order: null,
    row_number: null,
    edit_visible: false,
    selected_decimals: 2,
    sort_def: {
      by_user: {
        sort: ['user', 'phase', 'team'],
        heading: {
          column_1: 'Student'
        },
        title: 'Last Name ( A - Z )'
      },
      by_score_user: {
        sort: ['total', 'user', 'phase', 'team'],
        heading: {
          column_1: 'Student'
        },
        heading_sort_by: 'Total',
        title: 'Total Score'
      }
    },
    get_sort_def: function() {
      return this.get('sort_def');
    },
    get_sort_order: function() {
      return this.get('sort_order') || 'by_user';
    },
    get_sort_link_for: function() {
      return 'all';
    },
    get_number_decimals: function() {
      return this.get('selected_decimals');
    },
    rerender_view: function() {
      return this.notifyPropertyChange('sort_order');
    },
    set_decimals_from_offset: function(offset) {
      var decimals;
      decimals = this.get('selected_decimals' || 2);
      this.set('selected_decimals', decimals + offset);
      return this.rerender_view();
    },
    actions: {
      by_user: function() {
        return this.set('sort_order', 'by_user');
      },
      by_score_user: function() {
        return this.set('sort_order', 'by_score_user');
      },
      toggle_edit: function() {
        this.toggleProperty('edit_visible');
        return this.rerender_view();
      },
      increase_decimals: function() {
        return this.set_decimals_from_offset(1);
      },
      decrease_decimals: function() {
        return this.set_decimals_from_offset(-1);
      },
      save_score: function(values, score) {
        return gch['default'].update_roster_score(this.get_server_roster_scores(), values, score).then((function(_this) {
          return function() {
            return _this.rerender_view();
          };
        })(this));
      },
      save_state: function(values, state) {
        return gch['default'].update_roster_state(this.get_server_roster_scores(), values, state).then((function(_this) {
          return function() {
            return _this.rerender_view();
          };
        })(this));
      },
      select_sort_link: function(sort_link) {
        if (sort_link.action) {
          this.send(sort_link.action);
        }
        return true;
      }
    },
    get_roster: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (_this.get_server_roster()) {
            return _this.get_roster_values().then(function(roster) {
              return resolve(roster);
            });
          } else {
            return gch['default'].get_assignment_roster_from_server(_this.get('model')).then(function(roster) {
              var scores;
              scores = [];
              roster.scores.forEach(function(phase_scores) {
                return phase_scores.forEach(function(hash) {
                  hash.score = Number(hash.score);
                  hash.total = Number(hash.total);
                  if (hash.team_ownerable) {
                    hash.multiple_scores = hash.team_count > 1;
                  } else {
                    hash.team_ownerable = false;
                    hash.team_id = 0;
                    hash.team_label = ' ';
                    hash.multiple_scores = false;
                  }
                  return scores.push(ember['default'].Object.create(hash));
                });
              });
              roster.original_scores = roster.scores;
              roster.scores = scores;
              _this.set_server_roster(roster);
              return _this.get_roster_values().then(function(roster_values) {
                return resolve(roster_values);
              });
            });
          }
        };
      })(this));
    },
    get_roster_values: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var assignment, options, roster, server_roster, sort_def, sort_order;
          assignment = _this.get('model');
          sort_order = _this.get_sort_order();
          sort_def = _this.get("sort_def." + sort_order);
          server_roster = _this.get_server_roster();
          roster = {};
          roster.phases = _this.get_server_roster_phases();
          roster.supplement = _this.get_roster_supplement(sort_def);
          roster.decimals = _this.get_number_decimals();
          roster.heading = sort_def.heading;
          roster.heading_sort_by = sort_def.heading_sort_by;
          _this.set_user_totals();
          options = ember['default'].merge({}, sort_def);
          options.add_props = ['score', 'total', 'phase_position', 'state_id', 'multiple_scores', 'team_ownerable'];
          options.find = {
            total: 'total'
          };
          options.sort_by = {
            phase: 'phase_position'
          };
          roster.group_values = _this.group_values(_this.get_server_roster_scores(), options);
          return resolve(roster);
        };
      })(this));
    },
    set_user_totals: function() {
      var scores, user_ids;
      scores = this.get_server_roster_scores();
      user_ids = scores.mapBy('user_id').uniq();
      return user_ids.forEach((function(_this) {
        return function(id) {
          var hashes, total;
          total = calc['default'].total(scores.filterBy('user_id', id).mapBy('score'));
          hashes = scores.filterBy('user_id', id);
          return hashes.forEach(function(hash) {
            return hash.total = total;
          });
        };
      })(this));
    },
    get_server_roster: function() {
      return this.get('server_roster');
    },
    set_server_roster: function(roster) {
      return this.set('server_roster', roster);
    },
    get_server_roster_scores: function() {
      return this.get('server_roster.scores');
    },
    get_server_roster_phases: function() {
      return (this.get('server_roster.phases') || []).sortBy('position');
    },
    get_roster_supplement: function(sort_def) {
      var phases, scores, sup, total_scores;
      sup = {};
      sup.base = [];
      sup.decimals = this.get_number_decimals();
      phases = this.get_server_roster_phases();
      scores = this.get_server_roster_scores();
      phases.forEach((function(_this) {
        return function(phase) {
          var phase_hashes, phase_id, phase_scores, phase_sup, user_count;
          phase_id = phase.id;
          phase_hashes = scores.filterBy('phase_id', phase_id);
          phase_scores = phase_hashes.mapBy('score');
          phase_sup = calc['default'].values(phase_scores);
          phase_sup.title = phase.title;
          user_count = calc['default'].count_uniq_key_values(phase_hashes, 'user_id');
          if (user_count !== phase_sup.count) {
            sup.has_user_count = true;
            phase_sup.user_count = user_count;
          }
          return sup.base.push(phase_sup);
        };
      })(this));
      total_scores = calc['default'].values(scores.mapBy('score'));
      total_scores.title = 'Totals';
      total_scores.count = '';
      sup.base.push(total_scores);
      return sup;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});