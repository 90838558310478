define('client/thinkspace/casespace/phases/route', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin', 'thinkspace-base/base/route'], function (exports, ember, auth_mixin, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(auth_mixin['default'], {
    model: function(params) {
      return this.store.find(this.ns.to_p('assignment'), params.assignment_id).then((function(_this) {
        return function(assignment) {
          return _this.totem_messages.api_success({
            source: _this,
            model: assignment
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.totem_messages.api_failure(error, {
            source: _this,
            model: _this.ns.to_p('assignment')
          });
        };
      })(this));
    },
    afterModel: function() {
      this.totem_messages.show_loading_outlet({
        message: 'Loading...'
      });
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return ember['default'].run.later(_this, (function() {
            return resolve();
          }), 50);
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});