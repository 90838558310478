define('client/components/thinkspace/peer_assessment/assessment/team', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    selected_team_member: null,
    manager: null,
    reviewables: ember['default'].computed.reads('manager.reviewables'),
    reviewable: ember['default'].computed.reads('manager.reviewable'),
    c_team_member: ns['default'].to_p('tbl:assessment', 'team', 'member'),
    actions: {
      back: function() {
        var manager;
        manager = this.get('manager');
        return manager.set_reviewable_from_offset(-1);
      },
      next: function() {
        var manager;
        manager = this.get('manager');
        return manager.set_reviewable_from_offset(1);
      },
      confirmation: function() {
        var manager;
        manager = this.get('manager');
        return manager.set_confirmation();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});