define('client/_config/thinkspace/reporter/ns', ['exports'], function (exports) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = {
    namespaces: {
      reporter: 'thinkspace/reporter'
    },
    type_to_namespace: {
      'reporter:file': 'reporter',
      'reporter:report': 'reporter'
    }
  };

  exports['default'] = ___DefaultExportObject___;;

});