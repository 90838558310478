define('client/components/thinkspace/common/space/header', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    r_spaces_show: ns['default'].to_r('spaces', 'show'),
    r_spaces_edit: ns['default'].to_r('case_manager', 'spaces', 'edit'),
    r_spaces_roster: ns['default'].to_r('case_manager', 'spaces', 'roster'),
    r_spaces_grades: ns['default'].to_r('case_manager', 'spaces', 'grades'),
    r_team_sets: ns['default'].to_r('case_manager', 'team_sets'),
    r_spaces_scores: ns['default'].to_r('spaces', 'scores')
  });

  exports['default'] = ___DefaultExportObject___;;

});