define('client/thinkspace/builder/cases/new/route', ['exports', 'ember', 'totem/ns', 'simple-auth/mixins/authenticated-route-mixin', 'thinkspace-base/base/route'], function (exports, ember, ns, auth_mixin, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(auth_mixin['default'], {
    builder: ember['default'].inject.service(),
    titleToken: 'New',
    model: function(params) {
      var space_id;
      space_id = params.space_id;
      return this.tc.find_record(ns['default'].to_p('space'), space_id);
    },
    afterModel: function(model) {
      var assignment;
      assignment = this.store.createRecord(ns['default'].to_p('assignment'), {
        'thinkspace/common/space': model
      });
      return assignment.save().then((function(_this) {
        return function(assignment) {
          return _this.transitionTo(ns['default'].to_r('builder', 'cases', 'details'), assignment);
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});