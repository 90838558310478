define('client/components/thinkspace/casespace/case_manager/assignment/wizards/casespace/steps/confirmation', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace-case-manager/components/wizards/steps/base', 'totem/i18n'], function (exports, ember, ns, base, i18n) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    step: 'confirmation',
    page_title: ember['default'].computed.reads('model.title'),
    button_text: ember['default'].computed('is_editing', function() {
      var is_editing;
      is_editing = this.get('is_editing');
      if (is_editing) {
        return i18n['default'].message({
          path: 'builder.casespace.buttons.save'
        });
      } else {
        return i18n['default'].message({
          path: 'builder.casespace.buttons.create'
        });
      }
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});