define('client/thinkspace/casespace/case_manager/assignments/new/route', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace-case-manager/routes/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    titleToken: 'New',
    setupController: function(controller, model) {
      controller.reset_query_params();
      return this._super(controller, model);
    },
    model: function(params) {
      return this.store.find(ns['default'].to_p('space'), params.space_id).then((function(_this) {
        return function(space) {
          _this.get('wizard_manager').set_space(space);
          return _this.store.createRecord(ns['default'].to_p('assignment'), {
            space: space
          });
        };
      })(this));
    },
    deactivate: function() {
      var model;
      model = this.get('controller.model');
      if (model.get('isNew')) {
        return model.deleteRecord();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});