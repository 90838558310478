define('client/models/thinkspace/resource/tag', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].polymorphic('taggable'), ta['default'].has_many('files', {
    reads: {
      sort: 'title:asc'
    }
  }), ta['default'].has_many('links', {
    reads: {
      sort: 'title:asc'
    }
  })), {
    title: ta['default'].attr('string'),
    taggable_type: ta['default'].attr('string'),
    taggable_id: ta['default'].attr('string'),
    has_files: ember['default'].computed.notEmpty(ta['default'].to_p('files')),
    has_links: ember['default'].computed.notEmpty(ta['default'].to_p('links')),
    has_resources: ember['default'].computed.or('has_files', 'has_links'),
    resources: ember['default'].computed('files', 'links', function() {
      return this.get('files').concat(this.get('links'));
    }),
    didCreate: function() {
      return this.didLoad();
    },
    didLoad: function() {
      this.get(ta['default'].to_p('files')).then((function(_this) {
        return function(files) {
          return files.forEach(function(file) {
            return file.get(ta['default'].to_p('tags')).then(function(tags) {
              if (!tags.contains(_this)) {
                return tags.pushObject(_this);
              }
            });
          });
        };
      })(this));
      this.get(ta['default'].to_p('links')).then((function(_this) {
        return function(links) {
          return links.forEach(function(link) {
            return link.get(ta['default'].to_p('tags')).then(function(tags) {
              if (!tags.contains(_this)) {
                return tags.pushObject(_this);
              }
            });
          });
        };
      })(this));
      return this.get('taggable').then((function(_this) {
        return function(taggable) {
          return taggable.get(ta['default'].to_p('tags')).then(function(tags) {
            if (!tags.contains(_this)) {
              return tags.pushObject(_this);
            }
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});