define('client/components/thinkspace/builder/steps/parts/templates/grid', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    model: null,
    selected_template: null,
    tagName: '',
    is_selected: ember['default'].computed('model', 'selected_template', function() {
      return ember['default'].isEqual(this.get('model'), this.get('selected_template'));
    }),
    actions: {
      select: function() {
        return this.sendAction('select', this.get('model'));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});