define('client/models/thinkspace/casespace/phase_template', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].has_many('phases', {
    reads: {}
  })), {
    title: ta['default'].attr('string'),
    description: ta['default'].attr('string'),
    template: ta['default'].attr('string'),
    value: ta['default'].attr(),
    image_preview_src: ember['default'].computed('value', function() {
      return this.get('value.images.preview');
    }),
    image_thumbnail_src: ember['default'].computed('value', function() {
      return this.get('value.images.thumbnail');
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});