define('client/thinkspace/markup/libraries/edit/controller', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Controller.extend({
    c_library_edit: ns['default'].to_p('library', 'edit')
  });

  exports['default'] = ___DefaultExportObject___;;

});