define('client/components/thinkspace/resource/manage/files', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'thinkspace-base/components/base'], function (exports, ember, ns, ajax, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    c_manage_file: ns['default'].to_p('resource', 'manage', 'file'),
    files_url: ember['default'].computed(function() {
      return ajax['default'].adapter_model_url({
        model: ns['default'].to_p('file')
      });
    }),
    model_type: ember['default'].computed(function() {
      return this.totem_scope.record_type_key(this.get('model'));
    }),
    is_drag_hovered: false,
    is_uploading: false,
    actions: {
      close: function() {
        return this.sendAction('close');
      }
    },
    didInsertElement: function() {
      var $input, selector_completed_state, selector_drop_zone, selector_expansion, selector_upload_input, selector_upload_list, selector_upload_progress_bar, selector_uploaded_item, template_completed_icon, template_progress_bar, template_uploaded_item, url;
      selector_expansion = '.thinkspace-resource_expansion';
      selector_drop_zone = '.thinkspace-resource_drop-zone';
      selector_upload_input = '.thinkspace-resource_upload-input';
      selector_upload_list = '.thinkspace-resource_upload-list';
      selector_uploaded_item = '.thinkspace-resource_uploaded-item';
      selector_completed_state = '.is-completed';
      selector_upload_progress_bar = '.thinkspace-resource_upload-progress-bar';
      template_uploaded_item = '<li class="thinkspace-resource_uploaded-item">#PROGRESS_BAR#</li>';
      template_progress_bar = '<div class="thinkspace-resource_upload-progress-bar-wrapper"><div class="thinkspace-resource_upload-progress-bar">Uploading #FILE_NAME#</div></div>';
      template_completed_icon = '<i class="fa fa-check"></i>';
      this.$(selector_expansion).on('dragover', (function(_this) {
        return function() {
          if (!_this.get('is_drag_hovered')) {
            return _this.set('is_drag_hovered', true);
          }
        };
      })(this));
      this.$(selector_drop_zone).on('dragleave drop', (function(_this) {
        return function() {
          if (_this.get('is_drag_hovered')) {
            return _this.set('is_drag_hovered', false);
          }
        };
      })(this));
      $input = this.$().find(selector_upload_input).first();
      url = ajax['default'].adapter_model_url({
        model: ns['default'].to_p('file')
      });
      return $input.fileupload({
        url: url,
        dataType: 'json',
        dropZone: $(selector_drop_zone),
        done: (function(_this) {
          return function(e, data) {
            var file, i, key, len, model, ref;
            model = _this.get('model');
            key = ns['default'].to_p('files');
            model.store.pushPayload(ns['default'].to_p('file'), data.result);
            ref = data.result[key];
            for (i = 0, len = ref.length; i < len; i++) {
              file = ref[i];
              model.store.find(ns['default'].to_p('file'), file.id).then(function(file) {
                return model.get(key).then(function(files) {
                  if (!files.contains(file)) {
                    return files.pushObject(file);
                  }
                });
              });
            }
            if (_this.get('is_uploading')) {
              return _this.set('is_uploading', false);
            }
          };
        })(this),
        drop: (function(_this) {
          return function(e, data) {
            if (!_this.get('is_uploading')) {
              return _this.set('is_uploading', true);
            }
          };
        })(this),
        add: (function(_this) {
          return function(e, data) {
            var $upload_list;
            if (!_this.get('is_uploading')) {
              _this.set('is_uploading', true);
            }
            $upload_list = _this.$(selector_upload_list);
            data.files.forEach(function(file) {
              var $uploaded_item, progress_bar, uploaded_item;
              progress_bar = template_progress_bar.replace('#FILE_NAME#', file.name);
              uploaded_item = template_uploaded_item.replace('#PROGRESS_BAR#', progress_bar);
              $uploaded_item = $(uploaded_item);
              $upload_list.prepend($uploaded_item);
              return data.uploaded_item = $uploaded_item;
            });
            return data.submit();
          };
        })(this),
        progress: (function(_this) {
          return function(e, data) {
            var $active_row, $progress_bar, progress;
            progress = parseInt(data.loaded / data.total * 100, 10);
            $active_row = data.uploaded_item;
            $progress_bar = $active_row.find(selector_upload_progress_bar);
            if (progress === 100) {
              $active_row.remove();
            }
            return $progress_bar.css('width', progress + '%');
          };
        })(this)
      });
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});