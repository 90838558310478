define('client/components/thinkspace/reporter/reports', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    casespace: ember['default'].inject.service(),
    is_report_requested: false,
    current_assignment: ember['default'].computed.reads('casespace.current_assignment'),
    c_loader: ns['default'].to_p('common', 'loader'),
    c_report_tr: ns['default'].to_p('reporter:report', 'tr'),
    init: function() {
      this._super();
      return this.get_reports().then((function(_this) {
        return function() {
          return _this.set_all_data_loaded();
        };
      })(this));
    },
    get_reports: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.tc.find_all(ns['default'].to_p('reporter:report')).then(function(reports) {
            _this.set('reports', reports);
            return resolve();
          });
        };
      })(this));
    },
    set_is_report_requested: function() {
      return this.set('is_report_requested', true);
    },
    actions: {
      request_report: function() {
        var assignment, query;
        assignment = this.get('current_assignment');
        query = {
          type: 'ownerable_data',
          verb: 'post',
          action: 'generate'
        };
        this.totem_scope.add_authable_to_query(query, assignment);
        this.totem_messages.show_loading_outlet({
          message: 'Creating report...'
        });
        return this.tc.query(ns['default'].to_p('reporter:report'), query).then((function(_this) {
          return function() {
            _this.set_is_report_requested();
            return _this.totem_messages.hide_loading_outlet();
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});