define('client/models/thinkspace/builder/template', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].polymorphic('templateable')), {
    title: ta['default'].attr('string'),
    description: ta['default'].attr('string'),
    templateable_type: ta['default'].attr('string'),
    templateable_id: ta['default'].attr('number'),
    value: ta['default'].attr(),
    images_thumbnail_src: ember['default'].computed.reads('value.images.thumbnail')
  });

  exports['default'] = ___DefaultExportObject___;;

});