define('client/components/thinkspace/common/space/space', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    ttz: ember['default'].inject.service(),
    has_actions: ember['default'].computed.and('can.update', 'metadata.can_clone'),
    dropdown_collection: ember['default'].computed(function() {
      return [
        {
          display: 'Clone Space',
          route: this.get('r_spaces_clone'),
          model: this.get('model')
        }
      ];
    }),
    c_dropdown_split_button: ns['default'].to_p('common', 'dropdown_split_button'),
    r_spaces_show: ns['default'].to_r('spaces', 'show'),
    r_spaces_clone: ns['default'].to_r('case_manager', 'spaces', 'clone'),
    next_due_at: ember['default'].computed.reads('metadata.next_due_at'),
    next_due_at_date: ember['default'].computed('next_due_at', function() {
      return this.get('ttz').format(this.get('next_due_at'), {
        format: 'MMM Do, YYYY h:mm a'
      });
    }),
    totem_data_config: {
      ability: {
        ajax_source: true
      },
      metadata: {
        ajax_source: true
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});