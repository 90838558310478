define('client/components/thinkspace/common/user/terms', ['exports', 'ember', 'totem/ns', 'totem/config', 'thinkspace-base/components/base', 'totem/cache', 'totem/scope'], function (exports, ember, ns, config, base_component, tc, totem_scope) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    thinkspace: ember['default'].inject.service(),
    tagName: '',
    tos: null,
    tos_link: ember['default'].computed.reads('tos.link'),
    update_user_terms_date: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          query = {
            action: 'update_tos',
            model: totem_scope['default'].get_current_user(),
            verb: 'PUT',
            id: totem_scope['default'].get_current_user_id()
          };
          return tc['default'].query(ns['default'].to_p('user'), query, {
            single: true
          }).then(function() {
            return resolve();
          });
        };
      })(this));
    },
    transition_to_target: function() {
      var target, thinkspace;
      thinkspace = this.get('thinkspace');
      target = thinkspace.get_current_transition();
      if (thinkspace.transition_is_for(target, 'terms')) {
        return this.transitionToRoute(ns['default'].to_p('spaces'));
      } else {
        return target.retry();
      }
    },
    actions: {
      deny: function() {
        return this.transitionToRoute('/users/sign_in');
      },
      accept: function() {
        return this.update_user_terms_date().then((function(_this) {
          return function() {
            return _this.transition_to_target();
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});