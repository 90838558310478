define('client/models/thinkspace/readiness_assurance/assessment', ['exports', 'ember', 'totem/ds/associations', 'client/models/thinkspace/common/componentable'], function (exports, ember, ta, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(ta['default'].add(ta['default'].has_many('ra:responses', {
    reads: {
      filter: true
    }
  }), ta['default'].polymorphic('authable')), {
    title: ta['default'].attr('string'),
    question_settings: ta['default'].attr(),
    authable_id: ta['default'].attr('number'),
    authable_type: ta['default'].attr('string'),
    settings: ta['default'].attr(),
    answers: ta['default'].attr(),
    questions: ember['default'].computed.reads('question_settings'),
    get_question_ids: function() {
      return this.get('questions').mapBy('id');
    },
    get_question_by_id: function(id) {
      return this.get('questions').findBy('id', id);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});