define('client/components/thinkspace/common/user/sign_in', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'totem/mixins/validations', 'thinkspace-base/components/base', 'thinkspace-common/mixins/sign_up_terms'], function (exports, ember, ns, ajax, val_mixin, base_component, sign_up_terms) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend(val_mixin['default'], sign_up_terms['default'], {
    tagName: '',
    user_email: null,
    password: null,
    invitable: null,
    refer: null,
    email: ember['default'].computed.reads('user_email'),
    c_checkbox: ns['default'].to_p('common', 'shared', 'checkbox'),
    c_validated_input: ns['default'].to_p('common', 'shared', 'validated_input'),
    c_loader: ns['default'].to_p('common', 'shared', 'loader'),
    authenticator: 'authenticator:totem',
    credentials_error_message: 'Email or password incorrect',
    invitation_present: ember['default'].computed.notEmpty('invitable'),
    referred_from_signup: ember['default'].computed.equal('refer', 'signup'),
    has_errors: ember['default'].computed.reads('totem_messages.error_present'),
    error_messages: ember['default'].computed.reads('totem_messages.message_queue'),
    didInsertElement: function() {
      this.set_focus_first_input();
      return this.get_latest_terms().then((function(_this) {
        return function(tos) {
          if (ember['default'].isPresent(tos)) {
            return _this.set('tos', tos);
          }
        };
      })(this));
    },
    set_focus_first_input: function() {
      return $('form input').first().select();
    },
    set_user_credentials_error: function() {
      var credentials_error_message, errors;
      errors = this.get('errors');
      credentials_error_message = this.get('credentials_error_message');
      if (!errors.email.contains(credentials_error_message)) {
        return errors.email.pushObject(credentials_error_message);
      }
    },
    reset_user_credentials_error: ember['default'].observer('email', function() {
      var credentials_error_message, errors;
      errors = this.get('errors');
      credentials_error_message = this.get('credentials_error_message');
      if (errors.email.contains(credentials_error_message)) {
        return errors.email.removeObject(credentials_error_message);
      }
    }),
    actions: {
      submit: function() {
        var data;
        this.set('authenticating', true);
        data = {
          identification: this.get('email'),
          password: this.get('password')
        };
        this.set('password', null);
        return this.get('session').authenticate(this.get('authenticator'), data).then((function(_this) {
          return function() {
            _this.set('authenticating', false);
            return _this.totem_messages.info("Sign in successful!");
          };
        })(this), (function(_this) {
          return function(error) {
            var message;
            _this.set('authenticating', false);
            _this.set_user_credentials_error();
            message = error.responseText || 'Invalid credentials.';
            return _this.totem_messages.error(message);
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});