define('client/components/thinkspace/observation_list/observation/show', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: 'li',
    classNames: ['obs-list_list-item', 'gu-draggable'],
    classNameBindings: ['draggable_class'],
    attributeBindings: ['model_id', 'model_type', 'model_value_path'],
    model_id: ember['default'].computed(function() {
      return this.get('model.id');
    }),
    model_type: ember['default'].computed(function() {
      return this.totem_scope.get_record_path(this.get('model'));
    }),
    model_value_path: 'value',
    show_dropdown: ember['default'].computed.or('is_overflown', 'can_update', 'model.has_notes'),
    dropdown_collection: ember['default'].computed('is_expanded', 'can_update', 'is_overflown', 'are_notes_visible', function() {
      var can_update, collection, notes_text, overflown_text;
      overflown_text = this.get('is_expanded') ? 'Collapse' : 'Expand';
      notes_text = this.get('are_notes_visible') ? 'Hide Note(s)' : 'View Note(s)';
      can_update = this.get('can_update');
      collection = [];
      if (this.get('is_overflown')) {
        collection.push({
          display: overflown_text,
          action: 'toggle_expand'
        });
      }
      if (can_update) {
        collection.push({
          display: 'Edit',
          action: 'toggle_edit_observation'
        });
      }
      if (can_update) {
        collection.push({
          display: 'Remove',
          action: 'destroy_observation_start'
        });
      }
      if (can_update || this.get('model.has_notes')) {
        collection.push({
          display: notes_text,
          action: 'toggle_notes_visible'
        });
      }
      return collection;
    }),
    init: function() {
      var timer;
      this._super();
      timer = setInterval(((function(_this) {
        return function() {
          return _this.update_time(_this);
        };
      })(this)), 60000);
      return this.set('timer', timer);
    },
    didInsertElement: function() {
      return this.set_overflown();
    },
    willDestroyElement: function() {
      var timer;
      timer = this.get('timer');
      clearInterval(timer);
      return this.set('timer', null);
    },
    c_observation_edit: ns['default'].to_p('observation', 'edit'),
    c_observation_note_show: ns['default'].to_p('observation_note', 'show'),
    c_observation_note_new: ns['default'].to_p('observation_note', 'new'),
    c_dropdown_split_button: ns['default'].to_p('common', 'dropdown_split_button'),
    is_expanded: false,
    is_overflown: false,
    is_editing_obs: false,
    is_destroying_obs: false,
    are_notes_visible: false,
    is_creating_note: false,
    are_notes_visible_on: function() {
      return this.set('are_notes_visible', true);
    },
    are_notes_visible_off: function() {
      return this.set('are_notes_visible', false);
    },
    create_note_on: function() {
      return this.set('is_creating_note', true);
    },
    create_note_off: function() {
      return this.set('is_creating_note', false);
    },
    edit_obs_on: function() {
      return this.set('is_editing_obs', true);
    },
    edit_obs_off: function() {
      return this.set('is_editing_obs', false);
    },
    destroy_obs_on: function() {
      return this.set('is_destroying_obs', true);
    },
    destroy_obs_off: function() {
      return this.set('is_destroying_obs', false);
    },
    not_view_only: ember['default'].computed.not('totem_scope.is_view_only'),
    can_update: ember['default'].computed.and('not_view_only', 'model.can.update'),
    can_destroy: ember['default'].computed.and('not_view_only', 'model.can.destroy'),
    has_sortable: ember['default'].computed.not('not_view_only'),
    actions: {
      toggle_expand: function() {
        return this.toggleProperty('is_expanded');
      },
      toggle_edit_observation: function() {
        if (this.toggleProperty('is_editing_obs')) {
          return this.send('edit_observation');
        } else {
          return this.send('update_observation_cancel');
        }
      },
      edit_observation: function() {
        this.disable_observation_list();
        return this.edit_obs_on();
      },
      update_observation: function() {
        this.sendAction('update', this.get('model'));
        return this.edit_obs_off();
      },
      update_observation_cancel: function() {
        this.enable_observation_list();
        this.edit_obs_off();
        return this.get('model').rollback();
      },
      destroy_observation_start: function() {
        return this.destroy_obs_on();
      },
      destroy_observation_cancel: function() {
        return this.destroy_obs_off();
      },
      destroy_observation: function() {
        this.sendAction('remove', this.get('model'));
        return this.destroy_obs_off();
      },
      toggle_notes_visible: function() {
        if (this.toggleProperty('are_notes_visible')) {
          if (this.get('model.has_no_notes')) {
            return this.send('create_note_start');
          }
        } else {
          if (this.get('is_creating_note')) {
            return this.send('create_note_cancel');
          }
        }
      },
      create_note_start: function() {
        this.disable_observation_list();
        return this.create_note_on();
      },
      create_note: function(value) {
        var note, observation;
        observation = this.get('model');
        note = observation.store.createRecord(ns['default'].to_p('observation_note'), {
          value: value
        });
        note.set(ns['default'].to_p('observation'), observation);
        note.save().then((function(_this) {
          return function(note) {
            return _this.totem_messages.api_success({
              source: _this,
              model: note,
              action: 'save',
              i18n_path: ns['default'].to_o('observation_note', 'save')
            });
          };
        })(this), (function(_this) {
          return function(error) {
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: note
            });
          };
        })(this));
        this.create_note_off();
        return this.enable_observation_list();
      },
      create_note_cancel: function() {
        this.create_note_off();
        if (!this.get('model.has_notes')) {
          this.are_notes_visible_off();
        }
        return this.enable_observation_list();
      },
      edit_note_start: function() {
        this.edit_obs_off();
        return this.disable_observation_list();
      },
      update_note_cancel: function(note) {
        if (note.get('isDirty')) {
          note.rollback();
        }
        return this.enable_observation_list();
      },
      update_note: function(note) {
        note.save().then((function(_this) {
          return function(note) {
            return _this.totem_messages.api_success({
              source: _this,
              model: note,
              action: 'save',
              i18n_path: ns['default'].to_o('observation_note', 'save')
            });
          };
        })(this), (function(_this) {
          return function(error) {
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: note
            });
          };
        })(this));
        return this.enable_observation_list();
      },
      destroy_note: function(note) {
        var observation;
        observation = this.get('model');
        note.deleteRecord();
        return note.save().then((function(_this) {
          return function(note) {
            _this.totem_messages.api_success({
              source: _this,
              model: note,
              action: 'delete',
              i18n_path: ns['default'].to_o('observation_note', 'destroy')
            });
            return observation.get(ns['default'].to_p('observation_notes')).then(function(notes) {
              if (notes.get('length') === 0) {
                return _this.are_notes_visible_off();
              }
            });
          };
        })(this), (function(_this) {
          return function(error) {
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: note
            });
          };
        })(this));
      }
    },
    enable_observation_list: function() {
      return this.$().addClass(this.draggable_class);
    },
    disable_observation_list: function() {
      return this.$().removeClass(this.draggable_class);
    },
    check_overflow: ember['default'].observer('model.value', function() {
      return this.set_overflown();
    }),
    update_time: function(comp) {
      var $obs_item_date, created_at, from_now, model;
      model = comp.get('model');
      if (model) {
        created_at = model.get('created_at');
      }
      if (!created_at) {
        return;
      }
      from_now = moment(created_at).fromNow();
      $obs_item_date = this.$().find('.obs-item-date');
      return $obs_item_date.text(from_now);
    },
    overflown_selector: '.obs-list_list-item-value',
    set_overflown: function() {
      var $value, element, selector;
      selector = this.get('overflown_selector');
      $value = this.$(selector);
      if (ember['default'].isBlank($value)) {
        return;
      }
      element = $value[0];
      if (!element) {
        return;
      }
      return this.set('is_overflown', element.scrollWidth > element.clientWidth);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});