define('client/services/case_manager', ['exports', 'ember', 'totem/ns', 'totem/ajax'], function (exports, ember, ns, ajax) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Object.extend({
    reset_all: function() {
      this.reset_models();
      return this.get_case_manager_map().clear();
    },
    reset_models: function() {
      this.set_current_space(null);
      this.set_current_assignment(null);
      return this.set_current_phase(null);
    },
    current_space: null,
    current_assignment: null,
    current_phase: null,
    current_model: ember['default'].computed('current_space', 'current_assignment', 'current_phase', function() {
      return this.get('current_phase') || this.get('current_assignment') || this.get('current_space') || null;
    }),
    get_current_space: function() {
      return this.get('current_space');
    },
    get_current_assignment: function() {
      return this.get('current_assignment');
    },
    get_current_phase: function() {
      return this.get('current_phase');
    },
    set_current_space: function(space) {
      return this.set('current_space', space);
    },
    set_current_assignment: function(assignment) {
      return this.set('current_assignment', assignment);
    },
    set_current_phase: function(phase) {
      return this.set('current_phase', phase);
    },
    set_current_models: function(current_models) {
      if (current_models == null) {
        current_models = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var assignment, phase, space;
          switch (false) {
            case !(phase = current_models.phase):
              return phase.get(ns['default'].to_p('assignment')).then(function(assignment) {
                return assignment.get(ns['default'].to_p('space')).then(function(space) {
                  if (_this.get_current_space() !== space) {
                    _this.set_current_space(space);
                  }
                  if (_this.get_current_assignment() !== assignment) {
                    _this.set_current_assignment(assignment);
                  }
                  _this.set_current_phase(phase);
                  return resolve();
                }, function(error) {
                  return reject(error);
                });
              }, function(error) {
                return reject(error);
              });
            case !(assignment = current_models.assignment):
              return assignment.get(ns['default'].to_p('space')).then(function(space) {
                _this.set_current_phase(null);
                if (_this.get_current_space() !== space) {
                  _this.set_current_space(space);
                }
                _this.set_current_assignment(assignment);
                return resolve();
              }, function(error) {
                return reject(error);
              });
            case !(space = current_models.space):
              _this.set_current_assignment(null);
              _this.set_current_phase(null);
              if (_this.get_current_space() !== space) {
                _this.set_current_space(space);
              }
              return resolve();
            default:
              _this.reset_all();
              return resolve();
          }
        };
      })(this));
    },
    case_manager_map: ember['default'].Map.create(),
    get_case_manager_map: function() {
      return this.get('case_manager_map');
    },
    get_or_init_case_manager_map: function(key) {
      var map;
      map = this.get_case_manager_map().get(key);
      if (ember['default'].isPresent(map)) {
        return map;
      }
      this.get_case_manager_map().set(key, ember['default'].Map.create());
      return this.get_case_manager_map().get(key);
    },
    get_or_init_assignment_map: function() {
      return this.get_or_init_case_manager_map(this.get_current_assignment());
    },
    set_assignment_loaded: function() {
      return this.get_or_init_assignment_map().set('loaded', true);
    },
    has_assignment_loaded: function() {
      return this.get_or_init_assignment_map().get('loaded');
    },
    get_store: function() {
      return this.get('current_model.store');
    },
    get_team_categories: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var categories, map, store;
          map = _this.get_case_manager_map();
          if (map.has('team_categories')) {
            categories = map.get('team_categories');
          }
          if (ember['default'].isPresent(categories)) {
            return resolve(categories);
          }
          store = _this.get_store();
          return store.find(ns['default'].to_p('team_category')).then(function(categories) {
            map.set('team_categories', categories);
            return resolve(categories);
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    },
    get_store_spaces: function() {
      var spaces, store;
      store = this.get_store();
      spaces = store.all(ns['default'].to_p('space'));
      return spaces.filter(function(space) {
        return !space.get('isNew');
      });
    },
    get_updatable_store_spaces: function() {
      return this.get_store_spaces.filter(function(space) {
        return space.can_update;
      });
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});