define('client/components/thinkspace/markup/dock', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace/components/dock_base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    casespace: ember['default'].inject.service(),
    addon_display_name: 'Comments',
    can_access_addon: true,
    is_addon_visible: false,
    open_addon: function() {
      return this.get('casespace').set_active_sidepocket_component(ns['default'].to_p('markup', 'sidepocket', 'discussions'));
    },
    close_addon: function() {
      return this.get('casespace').reset_active_sidepocket_component();
    },
    actions: {
      toggle_addon_visible: function() {
        var casespace, is_addon_visible;
        is_addon_visible = this.toggleProperty('is_addon_visible');
        casespace = this.get('casespace');
        if (is_addon_visible) {
          return this.open_addon();
        } else {
          return this.close_addon();
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});