define('client/components/thinkspace/markup/sidepocket/library/comment', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    manager: ember['default'].inject.service(ns['default'].to_p('markup', 'manager')),
    model: null,
    library: null,
    tagName: 'li',
    classNames: ['ts-markup-library_comment'],
    classNameBindings: ['is_collapsed:is-collapsed', 'is_selected:is-selected'],
    is_collapsed: true,
    is_tagging: false,
    is_selected: ember['default'].computed('manager.selected_library_comment', 'model', function() {
      return this.get('manager.selected_library_comment') === this.get('model');
    }),
    library_tags: ember['default'].computed.reads('library.model.all_tags'),
    click: function(e) {
      e.preventDefault();
      e.stopPropagation();
      return this.sendAction('select', this.get('model'));
    },
    didInsertElement: function() {
      this.set_is_overflowing();
      return this.set_tags();
    },
    set_is_overflowing: function() {
      return ember['default'].run.schedule('afterRender', (function(_this) {
        return function() {
          var $value;
          $value = _this.$('.ts-markup-library_comment-text');
          return _this.set('is_overflowing', $value[0].scrollWidth > $value.innerWidth());
        };
      })(this));
    },
    set_tags: function() {
      var all_tags, tags;
      tags = ember['default'].makeArray();
      all_tags = ember['default'].makeArray(this.get('model.all_tags'));
      all_tags.forEach((function(_this) {
        return function(tag) {
          return tags.pushObject(tag);
        };
      })(this));
      return this.set('tags', tags);
    },
    update_tags: function() {
      var model, tags;
      model = this.get('model');
      tags = this.get('tags');
      model.set('all_tags', tags);
      return model.save();
    },
    actions: {
      toggle_expand: function() {
        return this.toggleProperty('is_collapsed');
      },
      toggle_tagging: function() {
        if (this.get('is_tagging')) {
          this.update_tags();
        }
        return this.toggleProperty('is_tagging');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});