define('client/users/show/route', ['exports', 'ember', 'totem/ns', 'thinkspace-base/base/route'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    model: function(params) {
      return this.store.find(ns['default'].to_p('user'), params.user_id);
    },
    renderTemplate: function() {
      return this.render(ns['default'].to_p('users', 'show'));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});