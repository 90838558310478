define('client/components/thinkspace/peer_assessment/assessment/item/quantitative', ['exports', 'ember', 'thinkspace-base/components/base'], function (exports, ember, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    manager: null,
    model: null,
    comment: null,
    items: null,
    slider: {
      classes: {
        fill: 'ts-rangeslider_fill',
        handle: 'ts-rangeslider_handle',
        range: 'ts-rangeslider'
      },
      colors: {
        fill: {
          'green': '#6dc05e',
          'yellow': '#fcb725',
          'red': '#fb6b6b'
        }
      },
      templates: {
        ruler: "<div class='ts-rangeslider_ruler' />",
        ticks: "<div class='ts-rangeslider_ticks' />",
        background: "<div class='ts-rangeslider_background' />"
      },
      step: 1
    },
    review: ember['default'].computed.reads('manager.review'),
    assessment: ember['default'].computed.reads('manager.model'),
    points_different: ember['default'].computed.reads('assessment.points_different'),
    is_read_only: ember['default'].computed.or('manager.is_read_only', 'manager.is_review_read_only'),
    model_index: ember['default'].computed('model', 'items', function() {
      var items, model;
      items = this.get('items');
      model = this.get('model');
      if (!(ember['default'].isPresent(items) && ember['default'].isPresent(model))) {
        return 0;
      }
      return items.indexOf(model) + 1;
    }),
    points_min: ember['default'].computed('assessment.points_min', 'model', function() {
      var value;
      value = this.get_model_property('settings.points.min');
      if (ember['default'].isPresent(value)) {
        return value;
      }
      return value || this.get('assessment.points_min');
    }),
    points_max: ember['default'].computed('assessment.points_max', 'model', function() {
      var value;
      value = this.get_model_property('settings.points.max');
      if (ember['default'].isPresent(value)) {
        return value;
      }
      return value || this.get('assessment.points_max');
    }),
    points_descriptive_enabled: ember['default'].computed('assessment.points_descriptive_enabled', 'model', function() {
      return (this.get('points_descriptive_low') && this.get('points_descriptive_high')) || this.get('assessment.points_descriptive_enabled');
    }),
    points_descriptive_low: ember['default'].computed('assessment.points_descriptive_low', 'model', function() {
      return this.get_model_property('settings.labels.scale.min') || this.get('assessment.points_descriptive_low');
    }),
    points_descriptive_high: ember['default'].computed('assessment.points_descriptive_high', 'model', function() {
      return this.get_model_property('settings.labels.scale.max') || this.get('assessment.points_descriptive_high');
    }),
    can_comment: ember['default'].computed('model', function() {
      return this.get_model_property('settings.comments.enabled');
    }),
    review_change: ember['default'].observer('review', function() {
      return this.initialize_review();
    }),
    fill_color_change: ember['default'].observer('fill_color', function() {
      var color, fill_class, fill_class_hex, fill_color;
      fill_color = this.get('fill_color');
      fill_class = this.get_slider_class('fill');
      fill_class_hex = this.get('fill_class_hex');
      color = this.get_slider_color(fill_color);
      return this.$("." + fill_class).css('background-color', color);
    }),
    init: function() {
      var assessment_id, model_id;
      this._super();
      model_id = this.get('model.id');
      assessment_id = this.get('assessment.id');
      return ember['default'].defineProperty(this, 'slider_value', ember['default'].computed('review', "review.value.quantitative." + model_id + ".value", function() {
        var review, value;
        review = this.get('review');
        if (!ember['default'].isPresent(review)) {
          return;
        }
        value = review.get_quantitative_value_for_id(model_id);
        if (ember['default'].isPresent(value)) {
          return value;
        } else {
          return 0;
        }
      }));
    },
    didInsertElement: function() {
      var range_class;
      this.$('input[type="range"]').rangeslider({
        polyfill: false,
        rangeClass: this.get_slider_class('range'),
        fillClass: this.get_slider_class('fill'),
        handleClass: this.get_slider_class('handle'),
        onInit: (function(_this) {
          return function() {
            return _this.slider_set_ruler();
          };
        })(this),
        onSlide: (function(_this) {
          return function(position, value) {
            _this.slider_set_color(value);
            return _this.slider_set_value(value);
          };
        })(this),
        onSlideEnd: (function(_this) {
          return function() {
            return _this.manager_save_review();
          };
        })(this)
      });
      if (this.get('points_descriptive_enabled')) {
        range_class = "." + (this.get_slider_class('range'));
        this.$(range_class).addClass('is-descriptive');
      }
      return this.initialize_review();
    },
    willDestroyElement: function() {
      return this.$('input[type="range"]').rangeslider('destroy');
    },
    initialize_review: function() {
      var $slider, comment, model_id, value;
      value = this.get('slider_value');
      model_id = this.get('model.id');
      comment = this.get('review').get_quantitative_comment_for_id(model_id);
      $slider = this.$('input[type="range"]');
      $slider.val(value).change();
      this.set('comment', comment);
      return ember['default'].run.schedule('afterRender', (function(_this) {
        return function() {
          return $slider.rangeslider('update');
        };
      })(this));
    },
    get_model_property: function(path) {
      var model;
      model = this.get('model');
      model = ember['default'].Object.create(model);
      return model.get(path);
    },
    manager_save_review: function() {
      return this.get('manager').save_review();
    },
    get_slider: function(prop) {
      var slider;
      slider = this.get('slider');
      return slider[prop];
    },
    get_slider_class: function(prop) {
      var classes;
      classes = this.get_slider('classes');
      return classes[prop];
    },
    get_slider_template: function(prop) {
      var templates;
      templates = this.get_slider('templates');
      return templates[prop];
    },
    get_slider_color: function(prop) {
      var colors;
      colors = this.get_slider('colors');
      return colors.fill[prop];
    },
    slider_set_ruler: function() {
      var $background, $ruler, $ticks, i, points_max, points_min, range_class, ruler, template, ticks;
      range_class = this.get_slider_class('range');
      points_min = this.get('points_min');
      points_max = this.get('points_max');
      ruler = '';
      ticks = '';
      i = points_min;
      while (i <= points_max) {
        ruler += i + ' ';
        ticks += '| ';
        i = i + this.get_slider('step');
      }
      template = this.get_slider_template('ruler');
      $ruler = $(template);
      template = this.get_slider_template('ticks');
      $ticks = $(template);
      template = this.get_slider_template('background');
      $background = $(template);
      $ruler.html(ruler);
      $ticks.html(ticks);
      this.$("." + range_class).prepend($background);
      return this.$("." + range_class).prepend($ticks);
    },
    slider_set_value: function(value) {
      return this.get('manager').set_quantitative_value(this.get('model.id'), value);
    },
    slider_set_color: function(value) {
      var percentage, points_max;
      points_max = this.get('points_max');
      percentage = value / points_max;
      switch (false) {
        case !(percentage >= 0.67):
          return this.set('fill_color', 'green');
        case !(percentage >= 0.33 && percentage < 0.67):
          return this.set('fill_color', 'yellow');
        case !(percentage >= 0 && percentage < 0.33):
          return this.set('fill_color', 'red');
      }
    },
    actions: {
      save_comment: function() {
        var manager;
        manager = this.get('manager');
        return manager.set_quantitative_comment(this.get('model.id'), this.get('comment'));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});