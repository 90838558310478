define('client/components/thinkspace/readiness_assurance/assessment/irat/show', ['exports', 'ember', 'totem/ns', 'thinkspace-readiness-assurance/response_manager', 'thinkspace-readiness-assurance/base/ra_component'], function (exports, ember, ns, response_manager, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    willInsertElement: function() {
      var assessment, readonly, rm;
      readonly = this.get('viewonly');
      assessment = this.get('model');
      rm = response_manager['default'].create({
        container: this.container
      });
      rm.init_manager({
        assessment: assessment,
        readonly: readonly,
        irat: true
      });
      return this.set('rm', rm);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});