define('client/models/thinkspace/observation_list/observation_note', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].belongs_to('observation', {
    reads: {}
  })), {
    value: ta['default'].attr('string'),
    didCreate: function() {
      return this.didLoad();
    },
    didLoad: function() {
      this.get(ta['default'].to_p('observation')).then((function(_this) {
        return function(observation) {
          return observation.get(ta['default'].to_p('observation_notes')).then(function(notes) {
            if (!notes.contains(_this)) {
              return notes.pushObject(_this);
            }
          });
        };
      })(this));
      return this._super();
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});