define('client/components/thinkspace/readiness_assurance/response/trat/chat', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    willInsertElement: function() {
      return this.cm.init_values();
    },
    actions: {
      send: function() {
        return this.cm.add_message();
      },
      close: function() {
        this.qm.set_chat_displayed_off();
        return this.sendAction('close', this.cm.qid);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});