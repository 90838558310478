define('client/_config/thinkspace/reporter/routes', ['exports'], function (exports) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = {
    'thinkspace/reporter/reports': {
      path: '/reports',
      resource: true
    },
    '/reports': {
      show: {
        path: '/:token'
      }
    }
  };

  exports['default'] = ___DefaultExportObject___;;

});