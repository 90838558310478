define('client/components/thinkspace/readiness_assurance/admin/trat/teams/users/show', ['exports', 'ember', 'totem/ns', 'thinkspace-readiness-assurance/mixins/user_list'], function (exports, ember, ns, user_list_mixin) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Component.extend(user_list_mixin['default'], {
    header: ember['default'].computed.reads('team.title'),
    room: ember['default'].computed.reads('team.room'),
    users: ember['default'].computed.reads('team_users'),
    collapsed: true,
    show_users: ember['default'].observer('show_all', function() {
      return this.set('collapsed', !this.get('show_all'));
    }),
    willInsertElement: function() {
      return this.setup();
    },
    setup: function() {
      this.ps.join({
        room: this.get_room(),
        room_type: this.get_room_type(),
        room_observer: true,
        source: this,
        callback: 'handle_room_users'
      });
      this.set_all_sorted_users();
      return this.refresh_users();
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});