define('client/components/thinkspace/builder/steps/templates', ['exports', 'ember', 'totem/ns', 'thinkspace-builder/components/wizard/steps/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    selected_template: null,
    tagName: '',
    c_template_grid: ns['default'].to_p('builder', 'steps', 'parts', 'templates', 'grid'),
    c_template_detailed: ns['default'].to_p('builder', 'steps', 'parts', 'templates', 'detailed'),
    init: function() {
      this._super();
      return this.set_templates().then((function(_this) {
        return function() {
          return _this.set_all_data_loaded();
        };
      })(this));
    },
    set_templates: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.tc.query(ns['default'].to_p('builder:template'), {
            templateable_type: 'thinkspace/casespace/assignment'
          }).then(function(templates) {
            _this.set('templates', templates);
            return resolve();
          });
        };
      })(this));
    },
    set_selected_template: function(template) {
      return this.set('selected_template', template);
    },
    reset_selected_template: function() {
      return this.set('selected_template', null);
    },
    callbacks_next_step: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var builder, model, template;
          template = _this.get('selected_template');
          builder = _this.get('builder');
          model = _this.get('model');
          model.set('template_id', template.get('id'));
          builder.set_is_saving();
          return model.save().then(function() {
            builder.reset_is_saving();
            model.set('template_id', null);
            _this.get('builder').transition_to_next_step();
            return resolve();
          }, function(error) {
            return _this.get('builder').encountered_save_error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return console.error('Error caught in templates step.');
        };
      })(this));
    },
    actions: {
      select: function(template) {
        return this.set_selected_template(template);
      },
      back_to_grid: function() {
        return this.reset_selected_template();
      },
      use_selected_template: function() {
        return this.send('next');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});