define('client/components/thinkspace/casespace/phase/show', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    totem_data_config: {
      metadata: {
        module_only: true
      }
    },
    tvo: ember['default'].inject.service(),
    phase_manager: ember['default'].inject.service(),
    has_responses: ember['default'].computed.bool('tvo.status.elements'),
    valid_elements_count: ember['default'].computed.reads('tvo.status.elements.count.valid'),
    invalid_elements_count: ember['default'].computed.reads('tvo.status.elements.count.invalid'),
    actions: {
      submit: function() {
        var assignment;
        assignment = this.get('phase_manager').get_assignment();
        this.totem_data.metadata.unload(assignment);
        return this.sendAction('submit');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});