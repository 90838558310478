define('client/components/thinkspace/lab/builder/lab/result/form/abnormality', ['exports', 'ember', 'totem/ns', 'client/components/thinkspace/lab/builder/lab/result/form/base', 'totem/mixins/validations'], function (exports, ember, ns, base, val_mixin) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(val_mixin['default'], {
    no_input: ember['default'].computed.equal('model.value.observations.abnormality.input_type', 'none'),
    max_attempts: null,
    correct_values: null,
    error_messages: null,
    new_count: 0,
    init_values: function() {
      var correct, max, ref;
      ref = this.get_model_value_path(), correct = ref[0], max = ref[1];
      this.set('correct_values', this.get_unbound_correct_values(correct));
      return this.set('max_attempts', (max || 0) + 0);
    },
    get_display_value: function() {
      var correct, max;
      correct = this.get('correct_values').map(function(correct) {
        return correct.value;
      });
      max = this.get('max_attempts');
      if (ember['default'].isBlank(correct)) {
        correct = ['click to add'];
      }
      return "(" + (correct.join(',')) + ") (" + max + ")";
    },
    actions: {
      add_label: function() {
        var class_input, correct, new_count, new_id;
        correct = this.get('correct_values');
        new_count = this.incrementProperty('new_count');
        new_id = "new_" + new_count;
        class_input = "lab_abnormality_" + new_id;
        correct.pushObject({
          value: '',
          "class": class_input
        });
        this.set('correct_values', correct);
        return ember['default'].run.schedule('afterRender', (function(_this) {
          return function() {
            return $("." + class_input).focus();
          };
        })(this));
      },
      delete_label: function(value) {
        return this.set('correct_values', this.get('correct_values').without(value));
      }
    },
    form_valid: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.validate_correct_values().then(function(correct_values) {
            return _this.validate_max_attempts().then(function(max_attempts) {
              if (ember['default'].isBlank(correct_values)) {
                max_attempts = 0;
              }
              _this.set_model_value_path([correct_values, max_attempts]);
              return resolve();
            }, function(error_messages) {
              _this.set_error_messages(error_messages);
              return reject();
            });
          }, function(error_messages) {
            _this.set_error_messages(error_messages);
            return reject();
          });
        };
      })(this));
    },
    validate_correct_values: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var correct;
          correct = ember['default'].makeArray(_this.get('correct_values'));
          correct = correct.map(function(hash) {
            return (hash.value || '').trim();
          });
          correct = correct.filter(function(element) {
            return ember['default'].isPresent(element);
          });
          correct = correct.uniq();
          return resolve(correct);
        };
      })(this));
    },
    validate_max_attempts: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var max;
          max = _this.get('max_attempts') || 0;
          if (!('' + max).match(/^\d+$/)) {
            return reject(_this.t('builder.lab.admin.form.abnormality.errors.max_attempts'));
          }
          return resolve(parseInt(max));
        };
      })(this));
    },
    set_error_messages: function(messages) {
      return this.set('error_messages', ember['default'].makeArray(messages));
    },
    get_unbound_correct_values: function(correct) {
      return (correct || []).map(function(value) {
        return {
          value: '' + (value || '')
        };
      });
    },
    rollback: function() {
      this.set('error_messages', null);
      return this.init_values();
    },
    validations: {
      max_attempts: {
        presence: true,
        numericality: {
          onlyInteger: true,
          greaterThanOrEqualTo: 0
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});