define('client/components/thinkspace/lab/builder/lab/preview', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    selected_category: null,
    is_viewing_keyboard_shortcuts: false,
    c_select_category: ns['default'].to_p('lab:chart', 'select_category'),
    willInsertElement: function() {
      var chart;
      chart = this.get('model');
      return chart.get(ns['default'].to_p('lab:categories')).then((function(_this) {
        return function(categories) {
          var first_category;
          first_category = categories.sortBy('position').get('firstObject');
          if (ember['default'].isPresent(first_category)) {
            return _this.set('selected_category', first_category);
          }
        };
      })(this));
    },
    actions: {
      select: function(category) {
        return this.set('selected_category', category);
      },
      toggle_keyboard_shortcuts: function() {
        return this.toggleProperty('is_viewing_keyboard_shortcuts');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});