define('client/components/thinkspace/builder/phases/edit/layout', ['exports', 'ember', 'totem/ns', 'thinkspace-builder/components/wizard/steps/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    tvo: ember['default'].inject.service()
  });

  exports['default'] = ___DefaultExportObject___;;

});