define('client/thinkspace/team/viewers/team/view', ['exports', 'ember', 'ember-data', 'totem/ns'], function (exports, ember, ds, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].View.extend({
    tagName: 'ul',
    classNames: ['team_viewers-team-list'],
    classNameBindings: ['is_selected_team:team_viewers-selected-team'],
    is_selected_team: ember['default'].computed('controller.selected_team', function() {
      return this.get('review_team') === this.get('controller.selected_team');
    }),
    has_team_viewers: ember['default'].computed.gt('teams_sorted.length', 0),
    has_user_viewers: ember['default'].computed.gt('users_sorted.length', 0),
    viewers_visible: true,
    team_viewers_visible: ember['default'].observer('controller.team_viewers_visible', function() {
      return this.set('viewers_visible', this.get('controller.team_viewers_visible'));
    }),
    teams_sorted: ember['default'].computed('team_viewerables', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get('team_viewerables').then(function(teams) {
            return resolve(teams.sortBy('title'));
          });
        };
      })(this));
      return ds['default'].PromiseArray.create({
        promise: promise
      });
    }),
    team_viewerables: ember['default'].computed('team_viewers.@each', function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var team_path;
          team_path = ns['default'].to_p('team');
          return _this.get('team_viewers').then(function(team_viewers) {
            var team_promises, team_team_viewers;
            team_team_viewers = team_viewers.filter(function(team_viewer) {
              return _this.polymorphic_type_to_path(team_viewer.get('viewerable_type')) === team_path;
            });
            team_promises = team_team_viewers.getEach('viewerable');
            return ember['default'].RSVP.Promise.all(team_promises).then(function(teams) {
              return resolve(teams);
            });
          });
        };
      })(this));
    }),
    users_sorted: ember['default'].computed('user_viewerables', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get('user_viewerables').then(function(users) {
            return resolve(users.sortBy('sort_name'));
          });
        };
      })(this));
      return ds['default'].PromiseArray.create({
        promise: promise
      });
    }),
    user_viewerables: ember['default'].computed('team_viewers.@each', function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var user_path;
          user_path = common.to_p('user');
          return _this.get('team_viewers').then(function(team_viewers) {
            var user_promises, user_team_viewers;
            user_team_viewers = team_viewers.filter(function(team_viewer) {
              return _this.polymorphic_type_to_path(team_viewer.get('viewerable_type')) === user_path;
            });
            user_promises = user_team_viewers.getEach('viewerable');
            return ember['default'].RSVP.Promise.all(user_promises).then(function(users) {
              return resolve(users);
            });
          });
        };
      })(this));
    }),
    team_viewers: ember['default'].computed(function() {
      var team_id;
      team_id = parseInt(this.get('review_team.id'));
      return this.get('controller.store').filter(ns['default'].to_p('team_viewer'), (function(_this) {
        return function(team_viewer) {
          return team_viewer.get('team_id') === team_id;
        };
      })(this));
    }),
    polymorphic_type_to_path: function(type) {
      return this.get('controller').polymorphic_type_to_path(type);
    },
    actions: {
      toggle_viewers_visible: function() {
        this.toggleProperty('viewers_visible');
        return false;
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});