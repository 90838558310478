define('client/initializers/totem/application/helpers/each-number', ['exports', 'ember', 'totem/util'], function (exports, ember, util) {

  'use strict';

  var ___DefaultExportObject___, initializer;

  initializer = {
    name: 'totem-application-helpers-each-number',
    after: ['totem'],
    initialize: function(container, app) {
      return ember['default'].Handlebars.registerHelper('each-number', function(options) {
        var hash, length, pad, period, row;
        row = ember['default'].get(options, 'data.view.contentIndex');
        row = ((row != null) && row + 1) || 0;
        hash = options.hash || {};
        length = hash.length;
        period = hash.period;
        if (period === false) {
          period = '';
        } else {
          period = '.';
        }
        if (length) {
          pad = hash.pad;
          pad = ((pad != null) && pad) || '0';
          return util['default'].rjust(row, length, "" + pad) + period;
        } else {
          return row + period;
        }
      });
    }
  };

  ___DefaultExportObject___ = initializer;

  exports['default'] = ___DefaultExportObject___;;

});