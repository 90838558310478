define('client/components/thinkspace/indented_list/list/source/mechanism', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    classNames: ['indented-list_new-mechanism-container'],
    get_container: function() {
      return this.$();
    },
    get_item_values: function() {
      return this.get('item_values');
    },
    get_response_manager: function() {
      return this.get('response_manager');
    },
    willInsertElement: function() {
      return this.get_response_manager().register_source_container(this, this.get_container(), {
        item_values: this.get_item_values()
      });
    },
    item_values: {
      description: 'New Mechanism',
      class_names: 'mechanism',
      icon: 'mechanism'
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});