define('client/thinkspace/markup/libraries/edit/route', ['exports', 'ember', 'totem/ns', 'simple-auth/mixins/authenticated-route-mixin', 'thinkspace-base/base/route'], function (exports, ember, ns, auth_mixin, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(auth_mixin['default'], {
    titleToken: 'Edit',
    model: function(params) {
      return this.store.find(ns['default'].to_p('library'), params.library_id);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});