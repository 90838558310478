define('client/thinkspace/team/teams/new/controller', ['exports', 'ember', 'totem/ns', 'thinkspace-team/controllers/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    model_validation_message: null,
    actions: {
      save: function() {
        var category_id, team;
        team = this.get('model');
        category_id = team.get('category_id');
        if (!category_id) {
          this.totem_error["throw"](this, "Team " + (team.get('id')) + " category is blank.");
        }
        return this.store.find(ns['default'].to_p('category'), category_id).then((function(_this) {
          return function(category) {
            team.set(ns['default'].to_p('category'), category);
            return team.save().then(function(team) {
              _this.totem_messages.api_success({
                source: _this,
                model: team,
                action: 'save',
                i18n_path: ns['default'].to_o('team', 'save')
              });
              return _this.transition_to_index();
            }, function(error) {
              _this.totem_messages.api_failure(error, {
                source: _this,
                model: team,
                action: 'save',
                without_key: false
              });
              return _this.set('model_validation_message', team.get('validation_message'));
            });
          };
        })(this), (function(_this) {
          return function(error) {
            _this.totem_messages.api_failure(error, {
              source: _this,
              model: team,
              action: 'save'
            });
            return _this.totem_error["throw"](_this, "Error getting team category [id: " + category_id + "].");
          };
        })(this));
      },
      cancel: function() {
        var team;
        team = this.get('model');
        team.unloadRecord();
        return this.transition_to_index();
      },
      clear_model_errors: function() {
        return this.set('model_validation_message', null);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});