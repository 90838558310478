define('client/components/thinkspace/casespace/case_manager/assignment/wizards/assessment/steps/settings', ['exports', 'ember', 'totem/ns', 'totem/util', 'thinkspace-casespace-case-manager/components/wizards/steps/base', 'totem/mixins/validations'], function (exports, ember, ns, util, base, val_mixin) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(val_mixin['default'], {
    step: 'settings',
    qualitative: null,
    quantitative: null,
    options: null,
    methodology: null,
    type: ember['default'].computed.reads('options.type'),
    page_title: ember['default'].computed.reads('model.title'),
    has_individual_comments: false,
    is_michaelsen: ember['default'].computed.equal('methodology', 'michaelsen'),
    is_custom: ember['default'].computed.equal('methodology', 'custom'),
    has_methodology: ember['default'].computed.or('is_michaelsen', 'is_custom'),
    methodology_types: {
      michaelsen: ['balance'],
      fink: [],
      custom: ['categories', 'balance', 'free']
    },
    c_quantitative: ns['default'].to_p('case_manager', 'assignment', 'wizards', 'assessment', 'steps', 'settings', 'quantitative'),
    c_qualitative: ns['default'].to_p('case_manager', 'assignment', 'wizards', 'assessment', 'steps', 'settings', 'qualitative'),
    methodologies: {
      michaelsen: {
        options: {
          points: {
            min: 0,
            max: 15,
            per_member: 10,
            different: false,
            descriptive: {
              enabled: false,
              values: []
            }
          },
          type: 'balance'
        },
        qualitative: [
          {
            id: 1,
            label: 'Indicate specifically how this person contributes to group success.',
            type: 'textarea',
            feedback_type: 'positive'
          }, {
            id: 2,
            label: 'Make constructive suggestions about how this person could better contribute to group success.',
            type: 'textarea',
            feedback_type: 'constructive'
          }
        ],
        quantitative: [
          {
            id: 1,
            label: 'Score',
            type: 'range'
          }
        ],
        properties: {
          has_individual_comments: true
        }
      },
      custom: {
        options: {},
        qualitative: [],
        quantitative: []
      }
    },
    init: function() {
      this._super();
      return this.init_defaults();
    },
    init_defaults: function() {
      this.set('qualitative', []);
      this.set('quantitative', []);
      return this.set('options', {});
    },
    get_store: function() {
      return this.container.lookup('store:main');
    },
    set_value: function(path, value, options) {
      if (options == null) {
        options = {};
      }
      if (options.number) {
        value = parseInt(value);
      }
      return util['default'].set_path_value(this, path, value);
    },
    set_options_type: function() {
      var options;
      options = this.get('options');
      return options.type = this.get('type');
    },
    get_assessment_value: function() {
      var options, qualitative, quantitative, type, value;
      options = this.get('options');
      qualitative = this.get('qualitative');
      quantitative = this.get('quantitative');
      type = this.get('type');
      this.set_options_type();
      switch (type) {
        case 'balance':
          this.set_balance_quantitative();
          break;
        case 'free':
          this.set_free_quantitative();
      }
      return value = {
        options: options,
        qualitative: qualitative,
        quantitative: quantitative
      };
    },
    get_quantitative_slider: function() {
      return {
        id: 1,
        label: 'Score',
        type: 'range'
      };
    },
    set_balance_quantitative: function() {
      var item, quantitative;
      item = this.get_quantitative_slider();
      quantitative = this.get('quantitative');
      quantitative.clear();
      return quantitative.pushObject(item);
    },
    set_free_quantitative: function() {
      return this.set_balance_quantitative();
    },
    get_next_id: function(items) {
      var id;
      if (ember['default'].isPresent(items)) {
        id = parseInt(items.mapBy('id').sort().pop());
        id += 1;
      } else {
        id = 1;
      }
      return id;
    },
    get_types_for_methodology: function(type) {
      var methodology;
      methodology = this.get('methodology');
      return this.get("methodology_types." + methodology);
    },
    is_valid_type_for_methodology: function(type) {
      var types;
      types = this.get_types_for_methodology(type);
      if (!ember['default'].isPresent(types)) {
        return false;
      }
      return types.contains(type);
    },
    set_type_for_methodology: function(type) {
      var first_type, types;
      types = this.get_types_for_methodology(type);
      first_type = types.get('firstObject');
      if (this.is_valid_type_for_methodology(type)) {
        return this.set('type', type);
      } else {
        return this.set('type', first_type);
      }
    },
    actions: {
      complete: function() {
        var assessment, wizard_manager;
        wizard_manager = this.get('wizard_manager');
        assessment = this.get_store().createRecord(ns['default'].to_p('tbl:assessment'), {
          value: this.get_assessment_value()
        });
        console.log("[tbl-wizard-settings] Assessment generated as: ", assessment);
        wizard_manager.send_action('set_assessment', assessment);
        return wizard_manager.send_action('complete_step', this.get('step'));
      },
      set_methodology: function(methodology) {
        var defaults, options, property, qualitative, quantitative;
        defaults = this.get("methodologies." + methodology);
        options = ember['default'].get(defaults, 'options');
        qualitative = ember['default'].get(defaults, 'qualitative');
        quantitative = ember['default'].get(defaults, 'quantitative');
        this.set('methodology', methodology);
        this.set('options', options);
        this.set('qualitative', qualitative);
        this.set('quantitative', quantitative);
        this.set_type_for_methodology(this.get('type'));
        if (ember['default'].isPresent(defaults.properties)) {
          for (property in defaults.properties) {
            this.set(property, defaults.properties[property]);
          }
        }
        return console.log("Methodolgy set: ", this);
      },
      add_qualitative_item: function(feedback_type) {
        var item, items;
        if (feedback_type == null) {
          feedback_type = 'positive';
        }
        items = this.get('qualitative');
        item = {
          id: this.get_next_id(items),
          label: '',
          feedback_type: feedback_type,
          type: 'textarea'
        };
        return items.pushObject(item);
      },
      remove_qualitative_item: function(item) {
        var items;
        items = this.get('qualitative');
        if (items.contains(item)) {
          return items.removeObject(item);
        }
      },
      add_quantitative_item: function() {
        var item, items;
        items = this.get('quantitative');
        item = {
          id: this.get_next_id(items),
          label: '',
          type: 'range'
        };
        return items.pushObject(item);
      },
      toggle_has_individual_comments: function() {
        return this.toggleProperty('has_individual_comments');
      },
      set_points_different: function(value) {
        return this.set_value('options.points.different', value);
      },
      set_points_descriptive_enabled: function(value) {
        return this.set_value('options.points.descriptive.enabled', value);
      },
      set_points_descriptive_values: function(values) {
        return this.set_value('options.points.descriptive.values', values);
      },
      set_points_per_member: function(value) {
        return this.set_value('options.points.per_member', value, {
          number: true
        });
      },
      set_points_max: function(value) {
        return this.set_value('options.points.max', value, {
          number: true
        });
      },
      set_points_min: function(value) {
        return this.set_value('options.points.min', value, {
          number: true
        });
      },
      set_type: function(type) {
        return this.set_type_for_methodology(type);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});