define('client/components/thinkspace/lab/builder/lab/result/form', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    admin: ember['default'].inject.service(ns['default'].to_p('lab', 'admin')),
    result_columns: ember['default'].computed(function() {
      var admin, result, type;
      admin = this.get('admin');
      result = this.get('model');
      type = result.get('admin_type');
      return admin.result_type_columns(result, type);
    }),
    show_form_errors: false,
    actions: {
      cancel: function() {
        return this.sendAction('cancel');
      },
      save: function() {
        var admin, form_comps, promises;
        admin = this.get('admin');
        form_comps = admin.get_result_form_components();
        promises = form_comps.map(function(comp) {
          return comp.form_valid();
        });
        return ember['default'].RSVP.all(promises).then((function(_this) {
          return function() {
            return _this.sendAction('save');
          };
        })(this), (function(_this) {
          return function() {
            return _this.set('show_form_errors', true);
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});