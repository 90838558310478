define('client/components/thinkspace/casespace/peer_review/dock', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace/components/dock_base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    casespace_peer_review: ember['default'].inject.service(),
    addon_name: 'peer_review',
    addon_display_name: 'Peer Review',
    c_phase: ns['default'].to_p('peer_review', 'phase'),
    can_peer_review_users: ember['default'].computed.bool('current_phase.can.peer_review_users'),
    can_peer_review_teams: ember['default'].computed.bool('current_phase.can.peer_review_teams'),
    can_peer_review: ember['default'].computed.or('can_peer_review_users', 'can_peer_review_teams'),
    can_access_addon: ember['default'].computed.and('has_phase_view', 'can_peer_review'),
    exit_addon: function() {
      return this.get('casespace_peer_review').clear();
    },
    actions: {
      exit: function() {
        var map, ownerable, phase;
        phase = this.get('current_phase');
        if (phase.is_team_ownerable()) {
          map = this.get('casespace_peer_review');
          ownerable = map.get_team_ownerable();
          this.exit_addon_common();
          this.set_ownerable(ownerable);
          return this.generate_phase_view();
        } else {
          return this._super();
        }
      }
    },
    valid_addon_ownerable: function(addon_ownerable) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (_this.get('can_peer_review')) {
            return resolve(true);
          }
          return _this.validate_ownerable(addon_ownerable).then(function(valid) {
            if (!valid) {
              _this.exit_addon_common();
            }
            return resolve(valid);
          });
        };
      })(this));
    },
    validate_ownerable: function(ownerable) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var map, phase;
          map = _this.get('casespace_peer_review');
          phase = _this.get('current_phase');
          if (_this.get('can_peer_review')) {
            if (phase.is_team_ownerable()) {
              return map.get_peer_review_teams(phase).then(function(teams) {
                return resolve(teams.contains(ownerable));
              });
            } else {
              return map.get_peer_review_users(phase).then(function(users) {
                return resolve(users.contains(ownerable));
              });
            }
          } else {
            return resolve(false);
          }
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});