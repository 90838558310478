define('client/thinkspace/casespace/case_manager/assignments/peer_assessment/assessments/controller', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Controller.extend({
    c_assessment: ns['default'].to_p('case_manager', 'assignment', 'peer_assessment', 'assessment')
  });

  exports['default'] = ___DefaultExportObject___;;

});