define('client/components/thinkspace/casespace/case_manager/assignment/peer_assessment/review/comment', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    model: null,
    review: null,
    assessment: null,
    comment: null,
    is_editing: false,
    value: ember['default'].computed.reads('comment.value'),
    label: ember['default'].computed.reads('model.label'),
    has_value: ember['default'].computed.notEmpty('value'),
    is_not_sent: ember['default'].computed.reads('review.is_not_sent'),
    init: function() {
      var comment, model, review;
      this._super();
      review = this.get('review');
      model = this.get('model');
      comment = review.get_qualitative_comment_for_id(model.id);
      return this.set('comment', comment);
    },
    actions: {
      edit: function() {
        return this.toggleProperty('is_editing');
      },
      save: function() {
        var review;
        review = this.get('review');
        review.set_qualitative_value(this.get('model.id'), this.get('model.feedback_type'), this.get('value'));
        this.totem_messages.show_loading_outlet();
        return review.save().then((function(_this) {
          return function() {
            _this.totem_messages.api_success({
              source: _this,
              model: review,
              action: 'update',
              i18n_path: ns['default'].to_o('tbl:review', 'save')
            });
            _this.totem_messages.hide_loading_outlet();
            return _this.set('is_editing', false);
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});