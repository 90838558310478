define('client/components/thinkspace/casespace/builder/phase/header/content', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    title: ember['default'].computed.reads('model.title'),
    actions: {
      save: function() {
        var model, title;
        model = this.get('model');
        title = this.get('title');
        model.set('title', title);
        return model.save().then((function(_this) {
          return function() {
            return _this.sendAction('cancel');
          };
        })(this));
      },
      cancel: function() {
        return this.sendAction('cancel');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});