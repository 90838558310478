define('client/components/thinkspace/builder/steps/details', ['exports', 'ember', 'totem/ns', 'thinkspace-builder/components/wizard/steps/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    title: ember['default'].computed.reads('builder.model.title'),
    instructions: ember['default'].computed.reads('builder.model.instructions'),
    callbacks_next_step: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var builder, instructions, model, title;
          title = _this.get('title');
          model = _this.get('model');
          instructions = _this.get('instructions');
          builder = _this.get('builder');
          model.set('title', title);
          builder.set_is_saving();
          return model.save().then(function() {
            builder.reset_is_saving();
            _this.get('builder').transition_to_next_step();
            return resolve();
          }, function(error) {
            return _this.get('builder').encountered_save_error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return console.error('Error caught in details step.');
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});