define('client/components/thinkspace/artifact/bucket/file', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'thinkspace-base/components/base'], function (exports, ember, ns, ajax, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    ttz: ember['default'].inject.service(),
    attachment_updated_at: ember['default'].computed('model.attachment_updated_at', function() {
      return this.get('ttz').format(this.get('model.attachment_updated_at'), {
        format: 'MMM Do, h:mm a'
      });
    }),
    file_url: ember['default'].computed(function() {
      return this.get('model.url');
    }),
    can_update: ember['default'].computed.bool('model.updateable'),
    show_file: false,
    c_confirmation_modal: ns['default'].to_p('common', 'shared', 'confirmation', 'modal'),
    c_file_component: ember['default'].computed(function() {
      switch (false) {
        case !this.get('model.is_pdf'):
          return ns['default'].to_p('artifact', 'bucket', 'file', 'pdf', 'wrapper');
        case !this.get('model.is_image'):
          return ns['default'].to_p('artifact', 'bucket', 'file', 'image', 'wrapper');
        default:
          return null;
      }
    }),
    actions: {
      show: function() {
        return this.set('show_file', true);
      },
      hide: function() {
        return this.set('show_file', false);
      },
      destroy: function() {
        var file;
        file = this.get('model');
        file.deleteRecord();
        return file.save().then((function(_this) {
          return function() {
            if (file.get('is_image')) {
              _this.tc.image.revoke_phase_url();
            }
            return _this.totem_messages.api_success({
              source: _this,
              model: file,
              action: 'delete',
              i18n_path: ns['default'].to_o('artifact:file', 'destroy')
            });
          };
        })(this), (function(_this) {
          return function(error) {
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: file,
              action: 'delete'
            });
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});