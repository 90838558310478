define('client/components/thinkspace/peer_assessment/builder/assessment/qual/settings', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    manager: ember['default'].inject.service(ns['default'].to_p('peer_assessment', 'builder', 'manager')),
    feedback_types: [
      {
        id: 'positive',
        label: 'Positive'
      }, {
        id: 'constructive',
        label: 'Constructive'
      }
    ],
    types: [
      {
        id: 'textarea',
        label: 'Large Text'
      }, {
        id: 'text',
        label: 'Small Text'
      }
    ],
    label: ember['default'].computed.reads('model.label'),
    type: ember['default'].computed.reads('model.type'),
    type_label: ember['default'].computed('type', function() {
      var type, types;
      types = this.get('types');
      type = this.get('type');
      type = types.findBy('id', type);
      return type.label;
    }),
    feedback_type: ember['default'].computed.reads('model.feedback_type'),
    feedback_type_label: ember['default'].computed('feedback_type', function() {
      var type, types;
      types = this.get('feedback_types');
      type = this.get('feedback_type');
      type = types.findBy('id', type);
      return type.label;
    }),
    c_checkbox: ns['default'].to_p('common', 'shared', 'checkbox'),
    c_dropdown: ns['default'].to_p('common', 'dropdown'),
    update_model: function() {
      var feedback_type, label, model, type;
      model = this.get('model');
      label = this.get('label');
      type = this.get('type');
      feedback_type = this.get('feedback_type');
      model.set_value('label', label);
      model.set_value('type', type);
      model.set_value('feedback_type', feedback_type);
      return console.info("[pa:builder:qual:settings] Model post update is: ", model);
    },
    actions: {
      back: function() {
        return this.sendAction('back');
      },
      save: function() {
        this.update_model();
        this.get('manager').save_model();
        return this.sendAction('back');
      },
      select_feedback_type: function(type) {
        return this.set('feedback_type', type.id);
      },
      select_type: function(type) {
        return this.set('type', type.id);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});