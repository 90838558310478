define('client/templates/components/thinkspace/diagnostic_path/path_item/confirm_destroy', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","diag-path_list-item-value-confirm-destroy");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","diag-path_list-item-value-confirm-destroy-message");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","im im-warning warning-icon");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","text");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","diag-path_list-item-value-edit-actions");
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","btn btn-small sortable-exclude");
        var el4 = dom.createTextNode("Cancel");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","btn btn-small btn-primary sortable-exclude");
        var el4 = dom.createTextNode("OK");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, content = hooks.content, element = hooks.element;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [0]);
        var element3 = dom.childAt(element1, [1]);
        var morph0 = dom.createMorphAt(dom.childAt(element0, [0, 1]),0,0);
        content(env, morph0, context, "message");
        element(env, element2, context, "action", ["cancel"], {"on": "click"});
        element(env, element3, context, "action", ["ok"], {"on": "click"});
        return fragment;
      }
    };
  }()));

});