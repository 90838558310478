define('client/controllers/application', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Controller.extend({
    totem_error_template_message: null,
    totem_outlet_messages: null,
    has_messages: ember['default'].computed.gt('totem_outlet_messages.length', 0),
    totem_main_outlet_class_names: null,
    totem_message_outlet_class_names: null,
    totem_message_outlet: function(options) {
      var controller, message_prop, message_type, messages, template_name, view;
      if (options == null) {
        options = {};
      }
      template_name = options.template_name || 'totem_message_outlet/messages';
      controller = options.outlet_controller;
      view = options.outlet_view;
      messages = options.outlet_messages;
      message_type = options.message_type;
      message_prop = 'totem_outlet_messages';
      if (messages) {
        messages = this.get_formatted_outlet_messages(messages);
      } else {
        messages = this.get_totem_messages_for_type(message_type);
      }
      if (controller) {
        this.set(message_prop, null);
        controller.set(message_prop, messages);
      } else {
        this.set(message_prop, messages);
      }
      return this.send('show_totem_message_outlet', template_name, {
        view: view,
        controller: controller,
        outlet_class_names: options.outlet_class_names,
        main_outlet_class_names: options.main_outlet_class_names,
        fade_main_outlet: options.fade_main_outlet
      });
    },
    get_totem_messages_for_type: function(type) {
      if (!type) {
        return [];
      }
      return this.totem_messages.type_messages(type);
    },
    get_formatted_outlet_messages: function(messages) {
      var formatted_messages, i, len, message;
      if (!messages) {
        return [];
      }
      messages = ember['default'].makeArray(messages).compact();
      formatted_messages = [];
      for (i = 0, len = messages.length; i < len; i++) {
        message = messages[i];
        if (typeof message === 'string') {
          formatted_messages.push({
            message: message
          });
        } else {
          formatted_messages.push(message);
        }
      }
      return formatted_messages;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});