define('client/services/wizard_manager', ['exports', 'ember', 'totem/ns', 'totem/ajax'], function (exports, ember, ns, ajax) {

  'use strict';

  var ___DefaultExportObject___,
    slice = [].slice;

  ___DefaultExportObject___ = ember['default'].Object.extend({
    case_manager: ember['default'].inject.service(),
    thinkspace: ember['default'].inject.service(),
    wizard: null,
    controller: null,
    route: null,
    space: null,
    page_title: 'New Case',
    reset_all: function() {
      return this.set('transition_to_step', null);
    },
    send_action: function() {
      var action, args, ref;
      action = arguments[0], args = 2 <= arguments.length ? slice.call(arguments, 1) : [];
      return (ref = this.get('wizard')).send.apply(ref, [action].concat(slice.call(args)));
    },
    set_controller: function(controller) {
      return this.set('controller', controller);
    },
    set_route: function(route) {
      return this.set('route', route);
    },
    set_page_title: function(title) {
      return this.set('page_title', title);
    },
    set_space: function(space) {
      return this.set('space', space);
    },
    get_current_assignment: function() {
      return this.get('case_manager').get_current_assignment();
    },
    set_query_param: function(param, value, options) {
      var controller, current_step, fn, wizard;
      if (options == null) {
        options = {};
      }
      fn = "check_" + param;
      controller = this.get('controller');
      wizard = this.get('wizard');
      current_step = controller.get('step');
      if (!ember['default'].isPresent(controller)) {
        console.error("[wizard] Cannot set a query param without a controller set.");
      }
      if (typeof wizard[fn] !== 'function') {
        return ember['default'].RSVP.resolve();
      }
      return wizard[fn](value, options).then((function(_this) {
        return function(new_value) {
          if (new_value == null) {
            new_value = null;
          }
          if (ember['default'].isPresent(new_value)) {
            value = new_value;
          }
          return controller.set(param, value);
        };
      })(this));
    },
    back: function(step) {
      this.get('wizard').send('back', step);
      return this.scroll_to_top();
    },
    complete_step: function(step) {
      this.get('wizard').send('complete_step', step);
      return this.scroll_to_top();
    },
    go_to_step: function(step) {
      this.get('wizard').send('go_to_step', step);
      return this.scroll_to_top();
    },
    exit: function() {
      this.get('route').send('exit');
      return this.scroll_to_top();
    },
    scroll_to_top: function() {
      return this.get('thinkspace').scroll_to_top();
    },
    transition_to_step: null,
    transition_to_assignment_edit: function(assignment, options) {
      if (options == null) {
        options = {};
      }
      this.set('transition_to_step', options.queryParams && options.queryParams.step);
      return this.get('route').transitionTo(ns['default'].to_r('case_manager', 'assignments', 'edit'), assignment, options);
    },
    transition_to_assignment_show: function(assignment) {
      if (!ember['default'].isPresent(assignment)) {
        assignment = this.get_current_assignment();
      }
      return this.get('route').transitionTo(ns['default'].to_r('casespace', 'assignments', 'show'), assignment);
    },
    transition_to_space: function(space) {
      var route;
      if (space == null) {
        space = null;
      }
      route = this.get('route');
      if (ember['default'].isPresent(space)) {
        if (space.get('isDeleted')) {
          return route.transitionTo(ns['default'].to_r('spaces'));
        } else {
          return route.transitionTo(ns['default'].to_r('spaces', 'show'), space);
        }
      } else {
        return route.transitionTo(ns['default'].to_r('spaces', 'index'));
      }
    },
    transition_to_selector: function() {
      return this.get('route').transitionTo(ns['default'].to_r('case_manager', 'assignments', 'new'), {
        queryParams: {
          space_id: this.get('space.id'),
          bundle_type: 'selector'
        }
      });
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});