define('client/components/thinkspace/readiness_assurance/admin/shared/timer/show', ['exports', 'ember', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    init: function() {
      this._super();
      return this.reset_timer();
    },
    time_change: ember['default'].observer('time_at', function() {
      return this.set_have_time_for_reminder();
    }),
    willInsertElement: function() {
      return this.set_have_time_for_reminder();
    },
    reset_timer: function() {
      this.set('add_reminder', false);
      this.set('have_time_for_reminder', true);
      this.set('interval', null);
      this.set('reminders', null);
      this.start_at = null;
      return this.end_at = null;
    },
    set_have_time_for_reminder: function() {
      var min;
      if (ember['default'].isBlank(this.time_at)) {
        return false;
      }
      min = this.am.minutes_from_now(this.time_at);
      return this.set('have_time_for_reminder', min > 0);
    },
    actions: {
      select_reminder: function() {
        return this.set('add_reminder', true);
      },
      select_no_reminder: function() {
        this.reset_timer();
        return this.set_timer();
      },
      select_interval: function(int) {
        this.set('interval', int);
        return this.set_timer();
      },
      select_reminders: function(num) {
        this.set('reminders', num);
        return this.set_dates();
      },
      clear_interval: function() {
        return this.set('interval', null);
      },
      clear_reminder: function() {
        this.reset_timer();
        this.set('have_time_for_reminder', false);
        return this.set_timer();
      }
    },
    set_dates: function() {
      var mins;
      if (ember['default'].isBlank(this.interval) || ember['default'].isBlank(this.reminders)) {
        return;
      }
      mins = (this.interval * this.reminders) * -1;
      this.end_at = this.am.clone_date(this.time_at);
      this.start_at = this.am.clone_date(this.time_at);
      this.am.adjust_by_minutes(this.start_at, mins);
      return this.set_timer();
    },
    set_timer: function() {
      var interval, options, settings, type, unit;
      interval = this.get('interval');
      options = null;
      if (this.add_reminder && ember['default'].isPresent(interval)) {
        type = this.rad.get('timer_type');
        unit = this.rad.get('timer_unit');
        settings = {
          type: type,
          unit: unit,
          interval: interval
        };
        options = {
          settings: settings,
          start_at: this.start_at,
          end_at: this.end_at
        };
      }
      return this.rad.set_timer(options);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});