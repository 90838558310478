define('client/components/thinkspace/resource/manage/tag', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    classNames: ['thinkspace-resource_tag'],
    c_manage_tag_edit: ns['default'].to_p('resource', 'manage', 'tag', 'edit'),
    edit_visible: false,
    actions: {
      edit: function() {
        return this.set('edit_visible', true);
      },
      cancel: function() {
        return this.set('edit_visible', false);
      },
      destroy: function() {
        var tag;
        tag = this.get('model');
        tag.deleteRecord();
        return tag.save().then((function(_this) {
          return function() {
            return _this.totem_messages.api_success({
              source: _this,
              model: tag,
              action: 'delete',
              i18n_path: ns['default'].to_o('tag', 'destroy')
            });
          };
        })(this), (function(_this) {
          return function(error) {
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: tag,
              action: 'delete'
            });
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});