define('client/thinkspace/casespace/assignments/reports/index/controller', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Controller.extend({
    c_reporter_reports: ns['default'].to_p('reporter', 'reports')
  });

  exports['default'] = ___DefaultExportObject___;;

});