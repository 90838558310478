define('client/thinkspace/team/viewers/team_user/view', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].View.extend({
    tagName: 'li',
    classNames: ['team_viewers-team-list-user'],
    actions: {
      remove_user: function() {
        return this.get('controller').delete_team_viewer(this.get('review_team'), this.get('viewer_user'));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});