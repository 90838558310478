define('client/components/thinkspace/common/dropdown_link', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: 'li',
    select_action: ember['default'].computed(function() {
      if (this.get('select_action_master')) {
        return this.get('select_action_master');
      }
      if (this.has_key(this.get('link'), 'action')) {
        return this.get('link.action');
      }
      return null;
    }),
    select_route: ember['default'].computed(function() {
      if (this.get('select_route_master')) {
        return this.get('select_route_master');
      }
      if (this.has_key(this.get('link'), 'route')) {
        return this.get('link.route');
      }
      return null;
    }),
    display: ember['default'].computed(function() {
      var display_property, link, value;
      display_property = this.get('display_property_master');
      link = this.get('link');
      if (ember['default'].isPresent(display_property)) {
        if (typeof link['get'] === 'function') {
          value = link.get(display_property);
          if (!ember['default'].isPresent(value)) {
            value = link[display_property];
          }
        } else {
          value = link[display_property];
        }
        return value;
      }
      if (this.has_key(this.get('link'), 'display')) {
        return this.get('link.display');
      }
      return this.get('link');
    }),
    route_param_key: ember['default'].computed(function() {
      if (this.get('route_param_key_master')) {
        return this.get('link').get(this.get('route_param_key_master'));
      }
      if (this.has_key(this.get('link'), 'route_param_key')) {
        return this.get("link.route_param_key");
      }
      return this.get('link');
    }),
    route_param: ember['default'].computed('route_param_key', function() {
      return this.get("link." + (this.get('route_param_key')));
    }),
    has_key: function(obj, key) {
      return key in obj;
    },
    didInsertElement: function() {
      return $(document).foundation();
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});