define('client/components/thinkspace/simulation/simulations/budget', ['exports', 'ember', 'ember-data', 'totem/ns', 'totem-application/mixins/default_property_settings', 'thinkspace-base/components/base'], function (exports, ember, ds, ns, default_prop_mixin, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend(default_prop_mixin['default'], {
    budget_game: null,
    update_counter: 0,
    bubble_update: 0,
    next_bubble: 240,
    hrs_elapsed: 0,
    no_of_hrs: 24,
    sounds_complete: 0,
    sound_count: 1,
    volume_bar: null,
    muted: false,
    images_loaded: false,
    sounds_loaded: false,
    simulation_started: false,
    simulation_loaded: false,
    simulation_paused: false,
    simulation_concluded: false,
    sim_fps: 60,
    water_level: 20,
    rate_of_inflow: 0,
    rate_of_outflow: 1,
    inflow: 0,
    max_inflow: 10,
    min_inflow: 0,
    listeners: [],
    game_tick_listener: null,
    graph_update_listener: null,
    load_listener: null,
    load_update_counter: 0,
    fill_class: 'ts-rangeslider_fill',
    handle_class: 'ts-rangeslider_handle',
    range_class: 'ts-rangeslider',
    slider_background_template: "<div class='ts-rangeslider_background' />",
    water_start_x: 99,
    water_start_y: 396,
    water_full_y: 396,
    water_empty_y: 636,
    water_scale_x: 0.995,
    water_size_coefficient: null,
    slider_step_size: 1.0,
    image_preloader: new createjs.LoadQueue(false),
    level_chart: null,
    rate_chart: null,
    level_chart_data: [],
    rate_chart_data: [],
    default_level_chart_data: [
      {
        "time": 0,
        "full": 20
      }
    ],
    default_rate_chart_data: [
      {
        "time": 0,
        "inflow": 0,
        "outflow": 1
      }
    ],
    inflow_controllable: ember['default'].computed('hrs_elapsed', function() {
      return this.get('hrs_elapsed') < 7 && this.get('hrs_elapsed') > 18;
    }),
    default_property_settings: {
      level_chart: null,
      rate_chart: null,
      update_counter: 0,
      bubble_update: 0,
      next_bubble: 240,
      hrs_elapsed: 0,
      simulation_concluded: false,
      simulation_started: false,
      water_level: 20,
      rate_of_inflow: 0,
      rate_of_outflow: 1,
      base_inflow: 0,
      load_listener: null,
      level_chart_data: {
        type: 'array'
      },
      rate_chart_data: {
        type: 'array'
      },
      listeners: {
        type: 'array'
      }
    },
    muted_obs: ember['default'].observer('muted', function() {
      if (this.get('muted')) {
        return createjs.Sound.volume = 0;
      } else {
        return createjs.Sound.volume = this.get('volume_bar').scaleY;
      }
    }),
    simulation_auto_pause: ember['default'].observer('simulation_paused', function() {
      var budget_game, graph_update_listener;
      graph_update_listener = this.get('graph_update_listener');
      budget_game = this.get('budget_game');
      if (this.get('simulation_paused')) {
        return createjs.Ticker.removeEventListener("tick", graph_update_listener);
      } else {
        return createjs.Ticker.addEventListener("tick", graph_update_listener);
      }
    }),
    hrs_elapsed_obs: ember['default'].observer('hrs_elapsed', function() {
      var hrs_elapsed;
      hrs_elapsed = this.get('hrs_elapsed');
      if (hrs_elapsed === 6) {
        this.set('simulation_paused', true);
      }
      if (hrs_elapsed === 24) {
        this.set('simulation_concluded', true);
        this.set('simulation_paused', true);
        return createjs.Ticker.removeEventListener("tick", this.get('graph_update_listener'));
      }
    }),
    inflow_obs: ember['default'].observer('inflow', 'hrs_elapsed', function() {
      var hrs_elapsed, inflow;
      inflow = parseInt(this.get('inflow'));
      hrs_elapsed = this.get('hrs_elapsed');
      if (hrs_elapsed < 6 || hrs_elapsed >= 18) {
        return this.set('rate_of_inflow', 0);
      } else {
        return this.set('rate_of_inflow', inflow);
      }
    }),
    net_flow: ember['default'].computed('rate_of_inflow', 'rate_of_outflow', function() {
      return this.get('rate_of_outflow') - this.get('rate_of_inflow');
    }),
    slider_obs: ember['default'].observer('inflow', function() {
      return this.$('input[id="inflow_slider"]').val(this.get('inflow')).change();
    }),
    willDestroyElement: function() {
      var budget_game, game_tick_listener, image_preloader, listeners;
      game_tick_listener = this.get('game_tick_listener');
      budget_game = this.get('budget_game');
      image_preloader = this.get('image_preloader');
      image_preloader.removeAllEventListeners();
      budget_game.removeAllEventListeners();
      this.reset_properties_to_default();
      listeners = this.get('listeners');
      listeners.forEach((function(_this) {
        return function(listener) {
          return createjs.Ticker.removeEventListener("tick", listener);
        };
      })(this));
      createjs.Ticker.removeAllEventListeners();
      return createjs.Tween.removeAllTweens();
    },
    handle_load: function(event) {
      this.get('image_preloader').removeAllEventListeners();
      createjs.Ticker.removeEventListener("tick", this.get('load_listener'));
      return this.set('images_loaded', true);
    },
    sim_final_load: ember['default'].observer('sounds_loaded', 'images_loaded', function() {
      var budget_game, load_text_container;
      if (this.get('sounds_loaded') && this.get('images_loaded')) {
        load_text_container = this.get('load_text_container');
        budget_game = this.get('budget_game');
        createjs.Tween.removeTweens(load_text_container);
        budget_game.removeChild(load_text_container);
        budget_game.alpha = 0;
        this.initialize_simulation();
        return this.set('simulation_loaded', true);
      }
    }),
    timed_load: function(event) {
      var image_queue, load_listener, load_update_counter;
      load_update_counter = this.get('load_update_counter');
      if (load_update_counter === this.get('sim_fps')) {
        image_queue = this.get('image_preloader');
        load_listener = this.get('load_listener');
        createjs.Sound.alternateExtensions = ['mp3'];
        createjs.Sound.registerPlugins([createjs.HTMLAudioPlugin]);
        createjs.Sound.on('fileload', (function(event) {
          return this.handle_sound_load(event);
        }).bind(this));
        if (createjs.Sound.loadComplete('https://s3.amazonaws.com/thinkspace-prod/sounds/stream.mp3')) {
          this.handle_sound_load('https://s3.amazonaws.com/thinkspace-prod/sounds/stream.mp3');
        }
        createjs.Sound.registerSound('https://s3.amazonaws.com/thinkspace-prod/sounds/stream.mp3', 'water');
        image_queue.on("complete", (function(event) {
          return this.handle_load(event);
        }).bind(this));
        image_queue.loadFile(new createjs.LoadItem().set({
          id: 'background',
          src: '/assets/images/simulations/budget/fluidity-background.png',
          crossOrigin: true
        }));
        image_queue.loadFile(new createjs.LoadItem().set({
          id: 'vat_outline',
          src: '/assets/images/simulations/budget/fluidity-vat-outline_2.png',
          crossOrigin: true
        }));
        image_queue.loadFile(new createjs.LoadItem().set({
          id: 'vat_base',
          src: '/assets/images/simulations/budget/fluidity-vat-base.png',
          crossOrigin: true
        }));
        image_queue.loadFile(new createjs.LoadItem().set({
          id: 'vat_background',
          src: '/assets/images/simulations/budget/fluidity-vat-background.png',
          crossOrigin: true
        }));
        image_queue.loadFile(new createjs.LoadItem().set({
          id: 'water_background',
          src: '/assets/images/simulations/budget/fluidity-water-3.png',
          crossOrigin: true
        }));
        image_queue.loadFile(new createjs.LoadItem().set({
          id: 'water_bubbles_light',
          src: '/assets/images/simulations/budget/fluidity-bubbles-1.png',
          crossOrigin: true
        }));
        image_queue.loadFile(new createjs.LoadItem().set({
          id: 'water_bubbles_dark',
          src: '/assets/images/simulations/budget/fluidity-bubbles-2.png',
          crossOrigin: true
        }));
        image_queue.loadFile(new createjs.LoadItem().set({
          id: 'vat_ticks',
          src: '/assets/images/simulations/budget/fluidity_measuring-scale.png',
          crossOrigin: true
        }));
        image_queue.loadFile(new createjs.LoadItem().set({
          id: 'speaker',
          src: '/assets/images/simulations/speaker.png',
          crossOrigin: true
        }));
        image_queue.loadFile(new createjs.LoadItem().set({
          id: 'speaker_mute',
          src: '/assets/images/simulations/speaker-mute.png',
          crossOrigin: true
        }));
        image_queue.load();
        createjs.Ticker.removeEventListener("tick", load_listener);
      }
      return this.set('load_update_counter', load_update_counter + 1);
    },
    handle_sound_load: function(event) {
      var sounds_complete;
      sounds_complete = this.get('sounds_complete') + 1;
      if (sounds_complete === this.get('sound_count')) {
        createjs.Sound.removeAllEventListeners();
        this.set('sounds_loaded', true);
      }
      return this.set('sounds_complete', sounds_complete);
    },
    initialize_load_screen: function() {
      var budget_game, dark_text, game_tick_listener, load_listener, load_text_container;
      budget_game = this.get('budget_game');
      budget_game.setBounds(0, 0, 700, 400);
      load_text_container = new createjs.Container();
      dark_text = new createjs.Text('Arriving shortly!', '24px omnes-pro', '#63b4d6');
      dark_text.textAlign = 'center';
      load_text_container.addChild(dark_text);
      dark_text.x = load_text_container.getBounds().width / 2;
      load_text_container.regY = load_text_container.getBounds().height / 2;
      load_text_container.regX = load_text_container.getBounds().width / 2;
      load_text_container.y = budget_game.getBounds().height / 2;
      load_text_container.x = budget_game.getBounds().width / 2;
      budget_game.addChild(load_text_container);
      createjs.Tween.get(load_text_container, {
        loop: true
      }).to({
        alpha: 0
      }, 1000).to({
        alpha: 1
      }, 1000);
      this.set('load_text_container', load_text_container);
      game_tick_listener = createjs.Ticker.addEventListener("tick", budget_game);
      this.set('game_tick_listener', game_tick_listener);
      load_listener = createjs.Ticker.addEventListener("tick", (function(event) {
        return this.timed_load(event);
      }).bind(this));
      this.set('load_listener', load_listener);
      this.initialize_graph();
      return this.$('input[id="inflow_slider"]').rangeslider({
        polyfill: false,
        rangeClass: this.get('range_class'),
        fillClass: this.get('fill_class'),
        handleClass: this.get('handle_class'),
        onInit: (function(_this) {
          return function() {
            return _this.init_template();
          };
        })(this),
        onSlideEnd: (function(_this) {
          return function(position, value) {
            return _this.set('inflow', value);
          };
        })(this),
        onSlide: (function(_this) {
          return function(position, value) {
            return _this.set('inflow', value);
          };
        })(this)
      });
    },
    init_template: function() {
      var $background, range_class, template;
      range_class = this.get('range_class');
      template = this.get('slider_background_template');
      $background = $(template);
      return this.$("." + range_class).prepend($background);
    },
    didInsertElement: function() {
      var canvas;
      this.set('budget_game', new createjs.Stage('budget-sim-canvas'));
      createjs.Ticker.setFPS(this.get('sim_fps'));
      createjs.Ticker.addEventListener('tick', createjs.Tween);
      canvas = document.getElementById('budget-sim-canvas');
      canvas.width = 700;
      canvas.height = 400;
      return this.initialize_load_screen();
    },
    graph_update: function(event) {
      var budget_game, hrs_elapsed, level_chart, level_chart_data, rate_chart, rate_chart_data, rate_of_inflow, rate_of_outflow, update_counter, water_level;
      level_chart_data = this.get('level_chart_data');
      rate_chart_data = this.get('rate_chart_data');
      rate_chart = this.get('rate_chart');
      level_chart = this.get('level_chart');
      budget_game = this.get('budget_game');
      rate_of_inflow = this.get('rate_of_inflow');
      rate_of_outflow = this.get('rate_of_outflow');
      water_level = this.get('water_level');
      update_counter = this.get('update_counter');
      if (update_counter === this.get('sim_fps')) {
        hrs_elapsed = this.get('hrs_elapsed') + 1;
        rate_chart_data.find((function(element) {
          if (element.time === hrs_elapsed) {
            element['inflow'] = rate_of_inflow;
            return rate_chart.validateData();
          }
        }));
        level_chart_data.find((function(element) {
          if (element.time === hrs_elapsed) {
            element['full'] = this.calculate_water_level();
            return level_chart.validateData();
          }
        }).bind(this));
        water_level = this.get('water_level');
        this.add_translate_tween(budget_game.water, this.get('net_flow'));
        createjs.Sound.play('water');
        update_counter = 0;
      }
      update_counter += 1;
      return this.set('update_counter', update_counter);
    },
    animate_bubbles: function(event) {
      var bubble, bubble_selector, bubble_update, budget_game, next_bubble, water_bubbles_light, water_obj;
      water_bubbles_light = this.get('water_bubbles_light');
      budget_game = this.get('budget_game');
      water_obj = budget_game.water;
      bubble_update = this.get('bubble_update');
      next_bubble = this.get('next_bubble');
      if (bubble_update === next_bubble) {
        bubble_selector = Math.floor((Math.random() * 2) + 1);
        if (bubble_selector === 1) {
          bubble = this.get('water_bubbles_light');
        } else {
          bubble = this.get('water_bubbles_dark');
        }
        bubble.y = water_obj.y;
        bubble.x = Math.floor((Math.random() * 200) + 200);
        bubble.alpha = .8;
        createjs.Tween.get(bubble).to({
          y: water_obj.y - (water_obj.getBounds().height - bubble.getBounds().height - Math.floor((Math.random() * 10) + 15))
        }, 2000).to({
          alpha: 0
        }, 250);
        bubble_update = 0;
        this.set('next_bubble', Math.floor((Math.random() * 180) + 180));
      }
      bubble_update += 1;
      return this.set('bubble_update', bubble_update);
    },
    increment_hrs: function() {
      return this.set('hrs_elapsed', this.get('hrs_elapsed') + 1);
    },
    calculate_water_level: function() {
      var net_flow, rate_of_inflow, rate_of_outflow, water_level;
      water_level = this.get('water_level');
      rate_of_inflow = this.get('rate_of_inflow');
      rate_of_outflow = this.get('rate_of_outflow');
      net_flow = this.get('net_flow');
      water_level = water_level - net_flow;
      if (water_level < 0) {
        water_level = 0;
      } else if (water_level > 100) {
        water_level = 100;
      }
      this.set('water_level', water_level);
      return water_level;
    },
    initialize_simulation: function(is_reset) {
      var background, bubble_animation_listener, budget_game, image_preloader, listeners, vat_background, vat_base, vat_container, vat_outline, vat_ticks, water_background, water_background_height, water_bubbles_dark, water_bubbles_light, water_empty_y, water_full_y, water_scale_x, water_size_coefficient, water_start_x, water_start_y;
      budget_game = this.get('budget_game');
      water_start_x = this.get('water_start_x');
      water_start_y = this.get('water_start_y');
      water_scale_x = this.get('water_scale_x');
      listeners = this.get('listeners');
      image_preloader = this.get('image_preloader');
      water_full_y = this.get('water_full_y');
      water_empty_y = this.get('water_empty_y');
      if (is_reset === false) {
        bubble_animation_listener = createjs.Ticker.addEventListener("tick", (function(event) {
          return this.animate_bubbles(event);
        }).bind(this));
        listeners.pushObject(bubble_animation_listener);
      }
      vat_container = new createjs.Container();
      background = new createjs.Bitmap(image_preloader.getResult('background'));
      vat_base = new createjs.Bitmap(image_preloader.getResult('vat_base'));
      vat_background = new createjs.Bitmap(image_preloader.getResult('vat_background'));
      vat_outline = new createjs.Bitmap(image_preloader.getResult('vat_outline'));
      vat_ticks = new createjs.Bitmap(image_preloader.getResult('vat_ticks'));
      water_background = new createjs.Bitmap(image_preloader.getResult('water_background'));
      water_bubbles_light = new createjs.Bitmap(image_preloader.getResult('water_bubbles_light'));
      water_bubbles_dark = new createjs.Bitmap(image_preloader.getResult('water_bubbles_dark'));
      water_bubbles_light.alpha = 0;
      water_bubbles_dark.alpha = 0;
      vat_container.addChild(vat_background);
      vat_container.addChild(vat_outline);
      vat_container.regX = vat_container.getBounds().width / 2;
      vat_container.regY = vat_container.getBounds().height / 2;
      water_background_height = water_background.getBounds().height;
      water_background.regY = water_background_height;
      water_background.scaleX = water_scale_x;
      water_background.x = water_start_x;
      water_size_coefficient = (water_empty_y - water_full_y) / 100;
      water_background.y = water_start_y + (80 * water_size_coefficient);
      this.set('water_size_coefficient', water_size_coefficient);
      vat_container.x = 350;
      vat_container.y = 220;
      vat_container.cache(0, 0, vat_container.getBounds().width, vat_container.getBounds().height);
      background.cache(0, 0, background.getBounds().width, background.getBounds().height);
      vat_ticks.regX = vat_ticks.getBounds().width;
      vat_ticks.x = 500;
      vat_ticks.y = 88;
      vat_base.x = 88;
      vat_base.y = 347;
      this.set('water_bubbles_light', water_bubbles_light);
      this.set('water_bubbles_dark', water_bubbles_dark);
      budget_game.water = water_background;
      budget_game.addChild(background);
      budget_game.addChild(vat_container);
      budget_game.addChild(water_background);
      budget_game.addChild(water_bubbles_light);
      budget_game.addChild(water_bubbles_dark);
      budget_game.addChild(vat_base);
      budget_game.addChild(vat_ticks);
      this.initialize_sound(budget_game, false);
      return createjs.Tween.get(budget_game).to({
        alpha: 1
      }, 1000);
    },
    initialize_sound: function(game, horizontal) {
      var background_bar, background_graphic, foreground_bar, foreground_graphic, sound_bar_container, speaker_image, speaker_image_container, speaker_mute_image;
      game.enableMouseOver(10);
      sound_bar_container = new createjs.Container();
      speaker_image_container = new createjs.Container();
      speaker_image = new createjs.Bitmap(this.get('image_preloader').getResult('speaker'));
      speaker_mute_image = new createjs.Bitmap(this.get('image_preloader').getResult('speaker_mute'));
      speaker_mute_image.alpha = 0;
      speaker_mute_image.x = -6;
      background_graphic = new createjs.Graphics().beginFill("#000000").drawRect(0, 0, 10, 100);
      foreground_graphic = new createjs.Graphics().beginFill("#FFFFFF").drawRect(0, 0, 10, 100);
      background_bar = new createjs.Shape(background_graphic);
      foreground_bar = new createjs.Shape(foreground_graphic);
      background_bar.alpha = .7;
      background_bar.regX = 10 / 2;
      foreground_bar.regX = 10 / 2;
      background_bar.regY = 100;
      foreground_bar.regY = 100;
      background_bar.x = 13;
      foreground_bar.x = 13;
      background_bar.y = speaker_image.y - 10;
      foreground_bar.y = speaker_image.y - 10;
      if (horizontal != null) {
        if (horizontal) {
          background_bar.rotation = 90;
          foreground_bar.rotation = 90;
        }
      }
      speaker_image_container.addChild(speaker_image);
      speaker_image_container.addChild(speaker_mute_image);
      sound_bar_container.addChild(speaker_image_container);
      sound_bar_container.addChild(background_bar);
      sound_bar_container.addChild(foreground_bar);
      game.addChild(sound_bar_container);
      sound_bar_container.x = game.getBounds().width - (sound_bar_container.getBounds().width + 15);
      sound_bar_container.y = game.getBounds().height - (sound_bar_container.getBounds().height + 15);
      speaker_image_container.addEventListener('click', (function(event) {
        this.toggleProperty('muted');
        if (this.get('muted')) {
          speaker_image.alpha = 0;
          return speaker_mute_image.alpha = 1;
        } else {
          speaker_image.alpha = 1;
          return speaker_mute_image.alpha = 0;
        }
      }).bind(this));
      sound_bar_container.addEventListener('rollover', (function(event) {
        return createjs.Tween.get(sound_bar_container).to({
          alpha: 0.9
        }, 250);
      }));
      sound_bar_container.addEventListener('rollout', (function(event) {
        return createjs.Tween.get(sound_bar_container).to({
          alpha: 0.3
        }, 250);
      }));
      foreground_bar.addEventListener('pressmove', (function(event) {
        var foreground_bottom, foreground_top;
        foreground_top = sound_bar_container.y;
        foreground_bottom = sound_bar_container.y - 100;
        foreground_bar.scaleY = (100 - (event.stageY - foreground_bottom)) / 100;
        if (foreground_bar.scaleY > 1) {
          foreground_bar.scaleY = 1;
        }
        if (this.get('muted') === false) {
          return createjs.Sound.volume = foreground_bar.scaleY;
        }
      }).bind(this));
      background_bar.addEventListener('pressmove', (function(event) {
        var foreground_bottom, foreground_top;
        foreground_top = sound_bar_container.y;
        foreground_bottom = sound_bar_container.y - 100;
        foreground_bar.scaleY = (100 - (event.stageY - foreground_bottom)) / 100;
        if (foreground_bar.scaleY > 1) {
          foreground_bar.scaleY = 1;
        }
        if (this.get('muted') === false) {
          return createjs.Sound.volume = foreground_bar.scaleY;
        }
      }).bind(this));
      foreground_bar.addEventListener('mousedown', (function(event) {
        var foreground_bottom, foreground_top;
        foreground_top = sound_bar_container.y;
        foreground_bottom = sound_bar_container.y - 100;
        foreground_bar.scaleY = (100 - (event.stageY - foreground_bottom)) / 100;
        if (foreground_bar.scaleY > 1) {
          foreground_bar.scaleY = 1;
        }
        if (this.get('muted') === false) {
          return createjs.Sound.volume = foreground_bar.scaleY;
        }
      }).bind(this));
      background_bar.addEventListener('mousedown', (function(event) {
        var foreground_bottom, foreground_top;
        foreground_top = sound_bar_container.y;
        foreground_bottom = sound_bar_container.y - 100;
        foreground_bar.scaleY = (100 - (event.stageY - foreground_bottom)) / 100;
        if (foreground_bar.scaleY > 1) {
          foreground_bar.scaleY = 1;
        }
        if (this.get('muted') === false) {
          return createjs.Sound.volume = foreground_bar.scaleY;
        }
      }).bind(this));
      sound_bar_container.alpha = .3;
      return this.set('volume_bar', foreground_bar);
    },
    initialize_graph: function() {
      var default_level_chart_data, default_rate_chart_data, drain_graph, i, level_chart, level_chart_data, level_graph, no_of_hrs, rate_chart, rate_chart_data, rate_graph, results;
      no_of_hrs = this.get('no_of_hrs');
      default_rate_chart_data = this.get('default_rate_chart_data').slice();
      default_level_chart_data = this.get('default_level_chart_data').slice();
      this.set('rate_chart_data', default_rate_chart_data);
      this.set('level_chart_data', default_level_chart_data);
      rate_chart_data = this.get('rate_chart_data');
      level_chart_data = this.get('level_chart_data');
      (function() {
        results = [];
        for (var i = 1; 1 <= no_of_hrs ? i <= no_of_hrs : i >= no_of_hrs; 1 <= no_of_hrs ? i++ : i--){ results.push(i); }
        return results;
      }).apply(this).forEach((function(_this) {
        return function(number) {
          var new_level_point, new_rate_point;
          new_rate_point = {
            'time': number,
            'outflow': 1
          };
          new_level_point = {
            'time': number
          };
          rate_chart_data.pushObject(new_rate_point);
          return level_chart_data.pushObject(new_level_point);
        };
      })(this));
      level_chart = new AmCharts.AmSerialChart();
      rate_chart = new AmCharts.AmSerialChart();
      level_chart.dataProvider = level_chart_data;
      level_chart.categoryField = 'time';
      level_chart.categoryAxis = {
        'title': 'Time Elapsed (hrs)',
        'titleFontSize': 14,
        'titleBold': false,
        'autoGridCount': false,
        'gridCount': 12,
        'tickPosition': true,
        'startOnAxis': true
      };
      level_chart.valueAxes = [
        {
          'id': 'y-axis',
          'titleFontSize': 14,
          'titleBold': false,
          'title': 'Water Level (L)',
          'maximum': 100,
          'minimum': 0,
          'autoGridCount': false,
          'gridCount': 10
        }
      ];
      level_chart.fontFamily = 'omnes-pro';
      level_chart.creditsPosition = 'top-left';
      rate_chart.dataProvider = rate_chart_data;
      rate_chart.categoryField = 'time';
      rate_chart.categoryAxis = {
        'title': 'Time Elapsed (hrs)',
        'titleFontSize': 14,
        'titleBold': false,
        'tickPosition': true,
        'startOnAxis': true,
        'autoGridCount': false,
        'gridCount': 12
      };
      rate_chart.valueAxes = [
        {
          'id': 'y-axis',
          'titleFontSize': 14,
          'titleBold': false,
          'title': 'Flow Rate (L/hr)',
          'maximum': 10,
          'minimum': 0,
          'autoGridCount': false,
          'gridCount': 10
        }
      ];
      rate_chart.fontFamily = 'omnes-pro';
      rate_chart.creditsPosition = 'top-left';
      level_graph = new AmCharts.AmGraph();
      rate_graph = new AmCharts.AmGraph();
      drain_graph = new AmCharts.AmGraph();
      level_graph.type = 'line';
      level_graph.valueField = 'full';
      level_graph.bullet = 'round';
      level_graph.balloonText = "[[category]] hrs, [[value]]L";
      level_graph.bulletSize = 5;
      level_graph.lineColor = "#63b4d6";
      rate_graph.type = 'line';
      rate_graph.valueField = 'inflow';
      rate_graph.bullet = 'round';
      rate_graph.bulletSize = 5;
      rate_graph.title = 'Inflow';
      rate_graph.balloonText = "[[value]]L/hr";
      rate_graph.lineColor = "#63b4d6";
      drain_graph.type = 'line';
      drain_graph.valueField = 'outflow';
      drain_graph.title = 'Outflow';
      drain_graph.lineColor = '#dc0303';
      level_chart.addGraph(level_graph);
      rate_chart.addGraph(rate_graph);
      rate_chart.addGraph(drain_graph);
      this.set('level_chart', level_chart);
      this.set('rate_chart', rate_chart);
      level_chart.write('chart-column1');
      return rate_chart.write('chart-column2');
    },
    add_translate_tween: function(budget_game_object, net_flow) {
      var new_y, water_empty_y, water_size_coefficient, water_start_y;
      water_start_y = this.get('water_start_y');
      water_empty_y = this.get('water_empty_y');
      water_size_coefficient = this.get('water_size_coefficient');
      new_y = budget_game_object.y + (net_flow * water_size_coefficient);
      if (new_y < water_start_y) {
        createjs.Tween.get(budget_game_object).to({
          y: water_start_y
        }, 250);
      } else if (new_y > water_empty_y) {
        createjs.Tween.get(budget_game_object).to({
          y: water_empty_y
        }, 250);
      } else {
        createjs.Tween.get(budget_game_object).to({
          y: new_y
        }, 500);
      }
      return this.increment_hrs();
    },
    actions: {
      step_slider: function(bool) {
        var inflow, max_inflow, min_inflow, slider_step_size;
        slider_step_size = this.get('slider_step_size');
        inflow = this.get('inflow');
        if (bool) {
          max_inflow = this.get('max_inflow');
          if (inflow < max_inflow) {
            return this.set('inflow', inflow + slider_step_size);
          }
        } else {
          min_inflow = this.get('min_inflow');
          if (inflow > min_inflow) {
            return this.set('inflow', inflow - slider_step_size);
          }
        }
      },
      pause_simulation: function() {
        if (this.get('simulation_concluded') === false) {
          return this.set('simulation_paused', true);
        }
      },
      resume_simulation: function() {
        if (this.get('simulation_concluded') === false) {
          return this.set('simulation_paused', false);
        }
      },
      hour_forward: function() {
        if (this.get('simulation_loaded')) {
          if (this.get('simulation_concluded') === false) {
            if (this.get('simulation_paused')) {
              this.set('update_counter', this.get('sim_fps'));
              return this.graph_update();
            }
          }
        }
      },
      start_simulation: function() {
        var graph_update_listener, listeners, simulation_loaded;
        simulation_loaded = this.get('simulation_loaded');
        listeners = this.get('listeners');
        if (simulation_loaded) {
          graph_update_listener = createjs.Ticker.addEventListener("tick", (function(event) {
            return this.graph_update(event);
          }).bind(this));
          this.set('graph_update_listener', graph_update_listener);
          listeners.pushObject(graph_update_listener);
          return this.set('simulation_started', true);
        }
      },
      reset_simulation: function() {
        var first_level_point, first_rate_point, level_chart, level_chart_data, rate_chart, rate_chart_data, simulation_loaded;
        first_rate_point = this.get('default_rate_chart');
        first_level_point = this.get('default_level_chart');
        simulation_loaded = this.get('simulation_loaded');
        if (simulation_loaded) {
          this.reset_properties_to_default();
          rate_chart_data = this.get('rate_chart_data');
          level_chart_data = this.get('level_chart_data');
          rate_chart = this.get('rate_chart');
          level_chart = this.get('level_chart');
          rate_chart_data.pushObject(first_rate_point);
          level_chart_data.pushObject(first_level_point);
          this.initialize_simulation(true);
          return this.initialize_graph();
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});