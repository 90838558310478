define('client/users/show/profile/controller', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Controller.extend({
    c_user_show_profile: ns['default'].to_p('user', 'show', 'profile'),
    actions: {
      update_password: function() {
        return this.transitionToRoute('users/password.new');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});