define('client/components/thinkspace/readiness_assurance/admin/shared/message', ['exports', 'ember', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    message_title: ember['default'].computed(function() {
      return this.title || 'Message';
    }),
    actions: {
      clear: function() {
        return this.rad.clear_message();
      },
      "default": function() {
        return this.rad.add_default_message();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});