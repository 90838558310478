define('client/_config/thinkspace/readiness_assurance/ns', ['exports'], function (exports) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = {
    namespaces: {
      readiness_assurance: 'thinkspace/readiness_assurance'
    },
    type_to_namespace: {
      'ra': 'readiness_assurance',
      'ra:assessment': 'readiness_assurance',
      'ra:response': 'readiness_assurance',
      'ra:chat': 'readiness_assurance',
      'ra:status': 'readiness_assurance',
      'ra:server_event': 'readiness_assurance',
      'ra:admin': 'readiness_assurance'
    }
  };

  exports['default'] = ___DefaultExportObject___;;

});