define('client/thinkspace/casespace/case_manager/spaces/new/route', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace-case-manager/routes/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    model: function(params) {
      return this.store.createRecord(ns['default'].to_p('space'));
    },
    deactivate: function() {
      var model;
      model = this.get('controller.model');
      if (model.get('isNew')) {
        return model.unloadRecord();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});