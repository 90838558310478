define('client/helpers/delimit-string', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Handlebars.makeBoundHelper(function(str, item, collection, delimeter, options) {
    var index, size;
    index = collection.indexOf(item);
    size = collection.get('length');
    if (index === size - 1) {
      return str;
    } else {
      return str + delimeter;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});