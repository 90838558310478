define('client/components/thinkspace/casespace/case_manager/assignment/wizards/assessment/steps/logistics/team_set', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    model: null,
    selected: null,
    is_selected: ember['default'].computed('model', 'selected', function() {
      return ember['default'].isEqual(this.get('model'), this.get('selected'));
    }),
    set_team_set: 'set_team_set',
    actions: {
      set_team_set: function() {
        return this.sendAction('set_team_set', this.get('model'));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});