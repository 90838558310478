define('client/components/thinkspace/casespace/case_manager/assignment/wizards/casespace/steps/phases/componentable', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    c_componentable: ember['default'].computed.reads('model.edit_component'),
    is_current: ember['default'].computed('current_componentable', function() {
      return this.get('model') === this.get('current_componentable');
    }),
    actions: {
      select: function(componentable) {
        return this.sendAction('select', componentable);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});