define('client/components/thinkspace/markup/sidepocket/discussion', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    markup: ember['default'].inject.service(ns['default'].to_p('markup', 'manager')),
    model: null,
    comments: null,
    is_anonymous: false,
    is_scrolled_to: false,
    is_highlighted: false,
    is_for_phase: false,
    classNames: ['ts-markup_sidepocket-discussion'],
    classNameBindings: ['is_scrolled_to:is-scrolled-to', 'is_highlighted:is-highlighted'],
    comments_sort_by: ['position'],
    sorted_comments: ember['default'].computed.sort('comments', 'comments_sort_by'),
    root_comments: ember['default'].computed('comments.@each.parent_id', function() {
      return this.get('comments').filter(function(comment) {
        return ember['default'].isEmpty(comment.get('parent_id'));
      });
    }),
    sorted_root_comments: ember['default'].computed.sort('root_comments', 'comments_sort_by'),
    discussion_number: ember['default'].computed('discussions.length', 'model', function() {
      var discussions, model;
      discussions = this.get('discussions');
      model = this.get('model');
      return this.get('markup').get_discussion_number(discussions, model);
    }),
    c_markup_discussion_comment: ns['default'].to_p('markup', 'sidepocket', 'discussion', 'comment'),
    c_confirmation_modal: ns['default'].to_p('common', 'shared', 'confirmation', 'modal'),
    init: function() {
      this._super();
      this.get('markup').add_discussion_component(this);
      return this.set_comments().then((function(_this) {
        return function() {
          return _this.set_all_data_loaded();
        };
      })(this));
    },
    willDestroyElement: function() {
      return this.get('markup').remove_discussion_component(this);
    },
    click: function(e) {
      var library_comment, markup, model, options;
      markup = this.get('markup');
      library_comment = markup.get_selected_library_comment();
      if (!library_comment) {
        return;
      }
      model = this.get('model');
      options = {
        library_comment: library_comment,
        commenterable: this.totem_scope.get_current_user()
      };
      markup.reset_selected_library_comment();
      return markup.add_comment_to_discussion(model, options);
    },
    set_comments: function() {
      var model;
      model = this.get('model');
      return model.get(ns['default'].to_p('markup', 'comments')).then((function(_this) {
        return function(comments) {
          return _this.set('comments', comments);
        };
      })(this));
    },
    set_is_scrolled_to: function() {
      this.set('is_scrolled_to', true);
      return ember['default'].run.later((function(_this) {
        return function() {
          if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) {
            return _this.reset_is_scrolled_to();
          }
        };
      })(this), 500);
    },
    reset_is_scrolled_to: function() {
      return this.set('is_scrolled_to', false);
    },
    set_is_highlighted: function() {
      return this.set('is_highlighted', true);
    },
    reset_is_highlighted: function() {
      return this.set('is_highlighted', false);
    },
    get_is_highlighted: function() {
      return this.get('is_highlighted');
    },
    save_record: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model;
          model = _this.get('model');
          if (model.get('isDirty')) {
            return model.save().then(function() {
              return resolve(model);
            });
          } else {
            return resolve(model);
          }
        };
      })(this));
    },
    destroy_record: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model;
          model = _this.get('model');
          return model.destroyRecord().then(function() {
            return resolve(model);
          });
        };
      })(this));
    },
    delete_record: function() {
      var model;
      model = this.get('model');
      return model.deleteRecord();
    },
    actions: {
      add_comment: function() {
        var markup, model, options;
        model = this.get('model');
        markup = this.get('markup');
        options = {
          commenterable: markup.get_current_commenterable(),
          comment: 'New comment',
          position: this.get('comments.length')
        };
        return markup.add_comment_to_discussion_and_edit(model, options);
      },
      remove: function() {
        var model;
        model = this.get('model');
        return model.get(ns['default'].to_p('comments')).then((function(_this) {
          return function(comments) {
            return model.destroyRecord().then(function() {
              return comments.forEach(function(comment) {
                return _this.get_store().unloadRecord(comment);
              });
            });
          };
        })(this));
      },
      highlight: function() {
        var model;
        model = this.get('model');
        return this.get('markup').highlight_discussion(model);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});