define('client/templates/components/thinkspace/casespace/case_manager/assignment/wizards/assessment/steps/settings/quantitative/balance', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ts-forms_field-container");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","ts-forms_field-label-container");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-forms_field-label");
        var el4 = dom.createTextNode("Total average points per team member");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-forms_field-label-description");
        var el4 = dom.createTextNode("If you enter 10 points and you have 4 members on your team you will have 40 points to distribute.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ts-forms_field-label");
        var el2 = dom.createTextNode("Choose the range for your scale");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ts_forms_inline-fields-container");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","ts-forms_field-container");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-forms_field-label");
        var el4 = dom.createTextNode("Minimum");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","ts-forms_field-container");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-forms_field-label");
        var el4 = dom.createTextNode("Maximum");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ts-forms_field-container");
        var el2 = dom.createElement("label");
        dom.setAttribute(el2,"class","ts-checkbox");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Team members must all have different points");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-radio_description");
        var el4 = dom.createTextNode("If a student assigns the same amount of points to more than one team member, they will receive an error message.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, inline = hooks.inline, element = hooks.element;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [2]);
        var element1 = dom.childAt(fragment, [4, 0]);
        var morph0 = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
        var morph1 = dom.createMorphAt(dom.childAt(element0, [0]),1,1);
        var morph2 = dom.createMorphAt(dom.childAt(element0, [1]),1,1);
        var morph3 = dom.createMorphAt(fragment,3,3,contextualElement);
        var morph4 = dom.createMorphAt(element1,0,0);
        inline(env, morph0, context, "input", [], {"value": get(env, context, "points_per_member"), "type": "number", "key-up": "set_points_per_member", "class": "ts-forms_input-tiny"});
        inline(env, morph1, context, "input", [], {"value": get(env, context, "points_min"), "type": "number", "key-up": "set_points_min", "class": "ts-forms_input-small"});
        inline(env, morph2, context, "input", [], {"value": get(env, context, "points_max"), "type": "number", "key-up": "set_points_max", "class": "ts-forms_input-small"});
        inline(env, morph3, context, "partial", [get(env, context, "t_points_descriptive")], {});
        element(env, element1, context, "action", ["toggle_points_different"], {"on": "click"});
        inline(env, morph4, context, "component", [get(env, context, "c_checkbox")], {"checked": get(env, context, "points_different"), "disable_click": true});
        return fragment;
      }
    };
  }()));

});