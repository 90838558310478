define('client/templates/components/thinkspace/peer_assessment/builder/assessment/settings', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","ts-forms_description-warning");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","tsi tsi-small tsi-phase-warning");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("Evaluation is ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(", cannot modify settings.");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","btn btn-small btn-default");
          var el2 = dom.createTextNode("Back");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, content = hooks.content, element = hooks.element;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element7 = dom.childAt(fragment, [1]);
          var morph0 = dom.createMorphAt(dom.childAt(fragment, [0]),2,2);
          content(env, morph0, context, "model.state");
          element(env, element7, context, "action", ["cancel"], {"on": "click"});
          return fragment;
        }
      };
    }());
    var child1 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","btn btn-small btn-primary");
          var el2 = dom.createTextNode("Update Settings");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","btn btn-small btn-default");
          var el2 = dom.createTextNode("Cancel");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, element = hooks.element;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element5 = dom.childAt(fragment, [0]);
          var element6 = dom.childAt(fragment, [1]);
          element(env, element5, context, "action", ["save"], {"on": "click"});
          element(env, element6, context, "action", ["cancel"], {"on": "click"});
          return fragment;
        }
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","ts-forms_field-subcontainer");
            var el2 = dom.createElement("label");
            dom.setAttribute(el2,"class","ts-forms_field-label");
            var el3 = dom.createTextNode("Points per member");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","ts-radio_item-container");
            var el2 = dom.createElement("label");
            dom.setAttribute(el2,"class","ts-checkbox");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","ts-checkbox_label");
            var el4 = dom.createTextNode("Differentiate points?");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","ts-radio_description");
            var el4 = dom.createTextNode("If this is enabled, it will require that the student allocates a different score for each member.  For example, you could not allocate 10, 10, 10 to your peers with this enabled.");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline, element = hooks.element;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element0 = dom.childAt(fragment, [1, 0]);
            var morph0 = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
            var morph1 = dom.createMorphAt(element0,0,0);
            inline(env, morph0, context, "component", [get(env, context, "c_validated_input")], {"value": get(env, context, "points_per_member"), "errors": get(env, context, "errors.points_per_member"), "class": "ts-forms_input-tiny"});
            element(env, element0, context, "action", ["toggle_points_different"], {"on": "click"});
            inline(env, morph1, context, "component", [get(env, context, "c_checkbox")], {"checked": get(env, context, "points_different"), "disable_click": true});
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","ts-forms_section-container");
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Evaluation is currently inactive. To activate it click on button. ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"class","btn btn-small btn-primary");
          var el3 = dom.createTextNode("Activate Evaluation");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","ts-forms_section-container");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","small-12 medium-6 large-6 ts-grid_columns");
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","ts-forms_field-label");
          var el5 = dom.createTextNode("What type of peer evaluation would you like this to be?");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","small-12 medium-6 large-6 ts-grid_columns ts-radio_item-container");
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"class","ts-radio");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("img");
          dom.setAttribute(el5,"src","/assets/images/ts-icons/builder/ts-builder_component-type_categories.svg");
          dom.setAttribute(el5,"class","ts-radio_image");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","ts-checkbox_label ts-radio_image-label");
          var el6 = dom.createTextNode("Categories");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","ts-radio_description ts-radio_image-description");
          var el6 = dom.createTextNode("Add any number of scales without points restrictions.");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","small-12 medium-6 large-6 ts-grid_columns ts-radio_item-container");
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"class","ts-radio");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("img");
          dom.setAttribute(el5,"src","/assets/images/ts-icons/builder/ts-builder_component-type_balanced.svg");
          dom.setAttribute(el5,"class","ts-radio_image");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","ts-checkbox_label ts-radio_image-label");
          var el6 = dom.createTextNode("Balance Points");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","ts-radio_image-description");
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","ts-radio_description");
          var el7 = dom.createTextNode("Classically used for the Michaelsen method.  Points are pooled based on a per-member value.  Students cannot give the same score for everyone.");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, element = hooks.element, get = hooks.get, inline = hooks.inline, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element1 = dom.childAt(fragment, [0, 1]);
          var element2 = dom.childAt(fragment, [1, 1]);
          var element3 = dom.childAt(element2, [0, 0]);
          var element4 = dom.childAt(element2, [1, 0]);
          var morph0 = dom.createMorphAt(element3,0,0);
          var morph1 = dom.createMorphAt(element4,0,0);
          var morph2 = dom.createMorphAt(dom.childAt(element4, [3]),1,1);
          element(env, element1, context, "action", ["activate"], {"on": "click"});
          element(env, element3, context, "action", ["set_is_categories"], {"on": "click"});
          inline(env, morph0, context, "component", [get(env, context, "c_radio")], {"checked": get(env, context, "is_categories"), "disable_click": true});
          element(env, element4, context, "action", ["set_is_balance"], {"on": "click"});
          inline(env, morph1, context, "component", [get(env, context, "c_radio")], {"checked": get(env, context, "is_balance"), "disable_click": true});
          block(env, morph2, context, "if", [get(env, context, "is_balance")], {}, child0, null);
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ts-grid_row");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","ts-grid_columns small-12");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-forms_inline-heading");
        var el4 = dom.createElement("h4");
        var el5 = dom.createTextNode("Evaluation Settings");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-forms_heading-actions");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var morph0 = dom.createMorphAt(dom.childAt(fragment, [0, 0, 0, 1]),0,0);
        var morph1 = dom.createMorphAt(fragment,1,1,contextualElement);
        dom.insertBoundary(fragment, null);
        block(env, morph0, context, "if", [get(env, context, "is_read_only")], {}, child0, child1);
        block(env, morph1, context, "unless", [get(env, context, "is_read_only")], {}, child2, null);
        return fragment;
      }
    };
  }()));

});