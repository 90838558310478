define('client/components/thinkspace/casespace/case_manager/assignment/wizards/casespace/steps/phases/phase', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'thinkspace-base/components/base'], function (exports, ember, ns, ajax, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: 'tr',
    dropdown_collection_sort: ['display:asc'],
    dropdown_sorted_collection: ember['default'].computed.sort('dropdown_collection', 'dropdown_collection_sort'),
    dropdown_collection: ember['default'].computed('model', function() {
      return [
        {
          display: 'Clone Phase',
          event: 'event_clone'
        }, {
          display: 'Delete Phase',
          event: 'event_destroy'
        }
      ];
    }),
    is_destroying: false,
    c_dropdown: ns['default'].to_p('common', 'dropdown'),
    c_phase_destroy: ns['default'].to_p('case_manager', 'assignment', 'wizards', 'casespace', 'steps', 'phases', 'destroy'),
    r_phase_edit: ns['default'].to_r('case_manager', 'phases', 'edit'),
    r_builder_phases_edit: ns['default'].to_r('builder', 'phases', 'edit'),
    event_clone: function() {
      var phase;
      phase = this.get('model');
      this.totem_messages.show_loading_outlet({
        message: "Cloning " + (phase.get('title')) + "..."
      });
      return phase.get(ns['default'].to_p('assignment')).then((function(_this) {
        return function(assignment) {
          var query;
          query = {
            model: phase,
            action: 'clone',
            verb: 'post',
            data: {
              phase_id: phase.get('id'),
              assignment_id: assignment.get('id')
            }
          };
          return ajax['default'].object(query).then(function(payload) {
            phase = ajax['default'].normalize_and_push_payload('phase', payload, {
              single: true
            });
            _this.totem_messages.hide_loading_outlet();
            return _this.totem_messages.api_success({
              source: _this,
              model: phase,
              action: 'clone',
              i18n_path: ns['default'].to_o('phase', 'clone')
            });
          }, function(error) {
            _this.totem_messages.hide_loading_outlet();
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: phase,
              action: 'clone'
            });
          });
        };
      })(this), (function(_this) {
        return function(error) {
          _this.totem_messages.hide_loading_outlet();
          return _this.totem_messages.api_failure(error, {
            source: _this,
            model: phase,
            action: 'clone'
          });
        };
      })(this));
    },
    event_destroy: function() {
      return this.set('is_destroying', true);
    },
    reset_is_destroying: function() {
      return this.set('is_destroying', false);
    },
    actions: {
      select: function(selected) {
        var event;
        event = selected.event;
        if (ember['default'].isPresent(event) && (this[event] != null)) {
          return this[event]();
        }
      },
      cancel_destroy: function() {
        return this.reset_is_destroying();
      },
      destroy: function() {
        var phase;
        phase = this.get('model');
        phase.deleteRecord();
        return phase.save().then((function(_this) {
          return function() {
            _this.reset_is_destroying();
            return _this.totem_messages.api_success({
              source: _this,
              model: phase,
              action: 'destroy',
              i18n_path: ns['default'].to_o('phase', 'destroy')
            });
          };
        })(this), (function(_this) {
          return function(error) {
            _this.reset_is_destroying();
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: phase,
              action: 'destroy'
            });
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});