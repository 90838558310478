define('client/models/thinkspace/team/team_set', ['exports', 'ember', 'totem/ns', 'totem/ds/associations'], function (exports, ember, ns, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].belongs_to('space', {
    reads: {}
  }), ta['default'].has_many('teams', {
    reads: {}
  })), {
    title: ta['default'].attr('string'),
    description: ta['default'].attr('string'),
    "default": ta['default'].attr('boolean'),
    space_id: ta['default'].attr('number'),
    state: ta['default'].attr('string'),
    settings: ta['default'].attr(),
    metadata: ta['default'].attr(),
    is_locked: ember['default'].computed.equal('state', 'locked'),
    unlocked_states: ['neutral'],
    locked_states: ['locked'],
    increment_team_count: function() {
      var total_teams;
      total_teams = this.get('metadata.total_teams');
      return this.set('metadata.total_teams', total_teams + 1);
    },
    decrement_team_count: function() {
      var total_teams;
      total_teams = this.get('metadata.total_teams');
      return this.set('metadata.total_teams', total_teams - 1);
    },
    set_unassigned_users_filter: function(filter) {
      return this.set('metadata.unassigned_users', filter);
    },
    unlocked_teams: ember['default'].computed('teams', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get('teams').then(function(teams) {
            var records;
            records = teams.filter(function(team) {
              return _this.get('unlocked_states').contains(team.get('state'));
            });
            return resolve(records);
          });
        };
      })(this));
      return ta['default'].PromiseArray.create({
        promise: promise
      });
    }),
    locked_teams: ember['default'].computed('teams', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get('teams').then(function(teams) {
            var records;
            records = teams.filter(function(team) {
              return _this.get('locked_states').contains(team.get('state'));
            });
            return resolve(records);
          });
        };
      })(this));
      return ta['default'].PromiseArray.create({
        promise: promise
      });
    }),
    unassigned_users_count: ember['default'].computed('metadata.unassigned_users', function() {
      var count;
      count = this.get('metadata.unassigned_users');
      if (count.then != null) {
        return count.get('length');
      } else {
        return count;
      }
    }),
    total_teams_count: ember['default'].computed('metadata.total_teams', function() {
      var count;
      count = this.get('metadata.total_teams');
      if (count.then != null) {
        return count.get('length');
      } else {
        return count;
      }
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});