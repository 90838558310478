define('client/components/thinkspace/readiness_assurance/admin/trat/summary/teams', ['exports', 'ember', 'totem/ns', 'thinkspace-readiness-assurance/mixins/data_rows', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, ns, m_data_rows, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(m_data_rows['default'], {
    columns_per_row: 1,
    qms_rows: null,
    willInsertElement: function() {
      return this.setup();
    },
    setup: function() {
      return this.am.get_trat_assessment().then((function(_this) {
        return function(assessment) {
          return _this.am.get_trat_response_managers().then(function(rms) {
            var array, i, j, len, len1, qid, qids, qm, qms, rm;
            qids = assessment.get_question_ids();
            qms = [];
            for (i = 0, len = qids.length; i < len; i++) {
              qid = qids[i];
              array = [];
              for (j = 0, len1 = rms.length; j < len1; j++) {
                rm = rms[j];
                qm = rm.question_manager_map.get(qid);
                array.push(qm);
              }
              qms.push(array);
            }
            _this.set('qms_rows', _this.get_data_rows(qms));
            return _this.set_ready_on();
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});