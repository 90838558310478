define('client/components/thinkspace/resource/manage/tags', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    c_manage_tag: ns['default'].to_p('resource', 'manage', 'tag'),
    c_manage_tag_new: ns['default'].to_p('resource', 'manage', 'tag', 'new'),
    create_visible: false,
    actions: {
      close: function() {
        return this.sendAction('close');
      },
      create: function() {
        return this.set('create_visible', true);
      },
      cancel: function() {
        return this.set('create_visible', false);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});