define('client/routes/root_url', ['exports', 'ember', 'simple-auth/configuration'], function (exports, ember, auth_config) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Route.extend({
    redirect: function() {
      var sign_in_url;
      sign_in_url = auth_config['default'].authenticationRoute;
      if (sign_in_url) {
        return this.transitionTo(sign_in_url);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});