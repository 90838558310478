define('client/models/thinkspace/reporter/file', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].belongs_to('reporter:report', {
    reads: {
      name: 'report'
    }
  })), {
    url: ta['default'].attr('string'),
    title: ta['default'].attr('string'),
    content_type: ta['default'].attr('string'),
    size: ta['default'].attr('number'),
    created_at: ta['default'].attr('date')
  });

  exports['default'] = ___DefaultExportObject___;;

});