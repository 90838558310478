define('client/models/thinkspace/team/team_category', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend({
    title: ta['default'].attr('string'),
    category: ta['default'].attr('string'),
    is_peer_review: ember['default'].computed.equal('category', 'peer_review'),
    is_collaboration: ember['default'].computed.equal('category', 'collaboration')
  });

  exports['default'] = ___DefaultExportObject___;;

});