define('client/components/thinkspace/casespace/case_manager/team/member/unassigned', ['exports', 'ember', 'ember-data', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ds, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    selected_members: null,
    is_selected: ember['default'].computed('selected_members.length', function() {
      return this.get('selected_members').contains(this.get('model'));
    }),
    c_user_avatar: ns['default'].to_p('common', 'user', 'avatar'),
    actions: {
      select: function() {
        return this.sendAction('select_member', this.get('model'));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});