define('client/components/thinkspace/input_element/elements/carry_forward/table', ['exports', 'ember', 'totem/ns', 'client/components/thinkspace/input_element/elements/carry_forward/standard'], function (exports, ember, ns, base) {

	'use strict';

	var ___DefaultExportObject___;

	___DefaultExportObject___ = base['default'].extend();

	exports['default'] = ___DefaultExportObject___;;

});