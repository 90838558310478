define('client/components/thinkspace/observation_list/list/edit', ['exports', 'ember', 'totem/ns', 'totem/mixins/validations', 'thinkspace-base/components/base'], function (exports, ember, ns, val_mixin, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend(val_mixin['default'], {
    categories: ember['default'].computed.reads('model.category_values'),
    c_category_radio: ns['default'].to_p('observation_list', 'list', 'category_radio'),
    input_value: null,
    actions: {
      select: function() {
        return this.sendAction('select', this.get('model'));
      },
      exit: function() {
        return this.sendAction('select', null);
      },
      save: function() {
        var list;
        list = this.get('model');
        list.set('category.name', this.get('input_value'));
        return list.save().then((function(_this) {
          return function(list) {
            _this.totem_messages.api_success({
              source: _this,
              model: list,
              action: 'update',
              i18n_path: ns['default'].to_o('list', 'save')
            });
            return _this.send('exit');
          };
        })(this), (function(_this) {
          return function(error) {
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: list,
              action: 'update'
            });
          };
        })(this));
      },
      cancel: function() {
        var bucket;
        bucket = this.get('model');
        if (bucket.get('isDirty')) {
          bucket.rollback();
        }
        return this.send('exit');
      },
      check: function(id) {
        return this.set('input_value', id);
      },
      uncheck: function() {
        return this.set('input_value', null);
      }
    },
    didInsertElement: function() {
      return this.set('input_value', this.get('model.category_id'));
    },
    validations: {
      input_value: {
        presence: {
          message: 'You must select one of the above categories'
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});