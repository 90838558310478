define('client/components/thinkspace/casespace/case_manager/space/edit', ['exports', 'ember', 'totem/ns', 'totem/mixins/validations', 'thinkspace-base/components/base'], function (exports, ember, ns, val_mixin, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend(val_mixin['default'], {
    wizard_manager: ember['default'].inject.service(),
    c_validated_input: ns['default'].to_p('common', 'shared', 'validated_input'),
    t_space_form: ns['default'].to_t('case_manager', 'space', 'form'),
    title: ember['default'].computed.reads('model.title'),
    title_region_title: ember['default'].computed.reads('model.title'),
    action_name: 'Edit',
    actions: {
      save: function() {
        var space;
        if (!this.get('is_valid')) {
          return;
        }
        space = this.get('model');
        space.set('title', this.get('title'));
        return space.save().then((function(_this) {
          return function() {
            _this.totem_messages.api_success({
              source: _this,
              model: space,
              action: 'save',
              i18n_path: ns['default'].to_o('space', 'save')
            });
            return _this.transition_to_space();
          };
        })(this), (function(_this) {
          return function(error) {
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: space,
              action: 'save'
            });
          };
        })(this));
      },
      cancel: function() {
        var space;
        space = this.get('model');
        if (space.get('isDirty')) {
          space.rollback();
        }
        return this.transition_to_space();
      }
    },
    transition_to_space: function() {
      return this.get('wizard_manager').transition_to_space(this.get('model'));
    },
    validations: {
      title: {
        presence: true,
        modelErrors: true
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});