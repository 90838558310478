define('client/components/thinkspace/observation_list/list', ['exports', 'ember', 'totem/ns', 'thinkspace-observation-list/mixins/dragula', 'thinkspace-observation-list/mixins/sort_order', 'thinkspace-base/components/base'], function (exports, ember, ns, m_dragula, m_sort_order, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend(m_dragula['default'], m_sort_order['default'], {
    classNames: ['ts-componentable'],
    tvo: ember['default'].inject.service(),
    thinkspace: ember['default'].inject.service(),
    container_class: 'obs-list_list',
    init: function() {
      this._super();
      return this.get('tvo.helper').register(this, {
        actions: {
          'select-text': 'create_observation',
          'obs-list-values': 'get_observation_list_values',
          'itemables': 'get_observation_list_observations'
        }
      });
    },
    didInsertElement: function() {
      this._super();
      this.init_dragula();
      return this.get('thinkspace').set_component_column_as_sticky(this);
    },
    t_title_region: ns['default'].to_t('observation_list', 'list', 'title_region'),
    c_observation_show: ns['default'].to_p('observation_list', 'observation', 'show'),
    c_observation_new: ns['default'].to_p('observation_list', 'observation', 'new'),
    ownerable_observations: ember['default'].computed(function() {
      return this.get('tvo.helper').ownerable_view_association_promise_array(this, {
        association: 'observations',
        ready: true
      });
    }),
    is_view_only: ember['default'].computed.reads('viewonly'),
    is_creating_observation: false,
    has_path: ember['default'].computed.bool('attributes.has_path'),
    actions: {
      create_observation_start: function() {
        return this.set('is_creating_observation', true);
      },
      create_observation_cancel: function() {
        return this.set('is_creating_observation', false);
      },
      create_observation: function(value) {
        return this.create_observation(value);
      },
      update_observation: function(observation) {
        return this.update_observation(observation);
      },
      destroy_observation: function(observation) {
        return this.remove_observation(observation);
      }
    },
    get_observation_list_values: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return resolve(_this.get('model.observations').mapBy('value'));
        };
      })(this), (function(_this) {
        return function(error) {
          return reject(error);
        };
      })(this));
    },
    get_observation_list_observations: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return resolve(_this.get('model.observations'));
        };
      })(this), (function(_this) {
        return function(error) {
          return reject(error);
        };
      })(this));
    },
    call_section_action_component: function(section, action, observation) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var tvo;
          tvo = _this.get('tvo');
          if (!tvo.section.has_action(section, action)) {
            return resolve();
          }
          return tvo.section.call_action(section, action, observation).then(function() {
            return resolve();
          });
        };
      })(this));
    },
    create_observation: function(value) {
      var category, list, max, observation, position;
      list = this.get('model');
      max = list.get('max_observation_position');
      position = parseInt(max);
      position = (isNaN(position) && 1) || position + 1;
      category = position % 2 === 0 ? 'Data' : 'History';
      observation = list.store.createRecord(ns['default'].to_p('observation'), {
        position: position,
        value: value,
        category: category,
        created_at: new Date()
      });
      this.totem_scope.set_record_ownerable_attributes(observation);
      observation.set(ns['default'].to_p('list'), list);
      observation.save().then((function(_this) {
        return function(observation) {
          return _this.totem_messages.api_success({
            source: _this,
            model: observation,
            action: 'save',
            i18n_path: ns['default'].to_o('observation', 'save')
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.totem_messages.api_failure(error, {
            source: _this,
            model: observation
          });
        };
      })(this));
      return this.send('create_observation_cancel');
    },
    update_observation: function(observation) {
      return observation.save().then((function(_this) {
        return function(observation) {
          return _this.totem_messages.api_success({
            source: _this,
            model: observation,
            action: 'save',
            i18n_path: ns['default'].to_o('observation', 'save')
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.totem_messages.api_failure(error, {
            source: _this,
            model: observation
          });
        };
      })(this));
    },
    remove_observation: function(observation) {
      return this.call_section_action_component('remove_itemable', 'remove', observation).then((function(_this) {
        return function() {
          var list;
          list = _this.get('model');
          _this.unload_observation_notes(observation);
          observation.deleteRecord();
          return observation.save().then(function(observation) {
            return _this.totem_messages.api_success({
              source: _this,
              model: observation,
              action: 'delete',
              i18n_path: ns['default'].to_o('observation', 'destroy')
            });
          }, function(error) {
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: observation
            });
          });
        };
      })(this));
    },
    unload_observation_notes: function(observation) {
      return observation.get(ns['default'].to_p('observation_notes')).then((function(_this) {
        return function(notes) {
          return notes.forEach(function(note) {
            return note.unloadRecord();
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});