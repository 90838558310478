define('client/views/session_timeout_warning', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].View.extend({
    classNames: ['totem_message_outlet-view'],
    mouseDown: function(event) {
      var controller;
      controller = this.get('controller');
      if (event.target && ember['default'].$(event.target).hasClass('sign-out')) {
        return controller.sign_out_user();
      } else {
        controller.hide_session_timeout_warning();
        return controller.reset_session_timer({
          stay_alive: true
        });
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});