define('client/components/thinkspace/builder/shared/phases/errors', ['exports', 'ember', 'thinkspace-base/components/base', 'thinkspace-casespace/mixins/assignments/validity'], function (exports, ember, base, valid) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(valid['default'], {
    model: null,
    hide_success: false
  });

  exports['default'] = ___DefaultExportObject___;;

});