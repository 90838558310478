define('client/models/thinkspace/weather_forecaster/assessment_item', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].belongs_to('wf:item'), ta['default'].has_many('wf:assessments'), ta['default'].has_many('wf:forecasts')), {
    title: ta['default'].attr('string'),
    description: ta['default'].attr('string'),
    presentation: ta['default'].attr('string'),
    item_header: ta['default'].attr('string'),
    help_tip: ta['default'].attr()
  });

  exports['default'] = ___DefaultExportObject___;;

});