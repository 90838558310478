define('client/components/thinkspace/casespace', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace/components/casespace'], function (exports, ember, ns, base_component) {

	'use strict';

	var ___DefaultExportObject___;

	___DefaultExportObject___ = base_component['default'].extend();

	exports['default'] = ___DefaultExportObject___;;

});