define('client/mixins/thinkspace/intercom/controller', ['exports', 'ember', 'totem/config'], function (exports, ember, config) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    intercom_boot_obs: (function() {
      var user;
      if ($crisp) {
        $crisp.push(["do", "chat:hide"]);
      }
      user = this.get('session.user');
      if (ember['default'].isBlank($crisp) || ember['default'].isBlank(user)) {
        return;
      }
      $crisp.push(["set", "user:email", user.get('email')]);
      return $crisp.push(["set", "user:nickname", user.get('full_name')]);
    }).observes('session.user').on('init')
  });

  exports['default'] = ___DefaultExportObject___;;

});