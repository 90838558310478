define('client/thinkspace/team/assign/controller', ['exports', 'ember', 'totem/ns', 'thinkspace-team/controllers/base', 'client/thinkspace/team/assign/team/view', 'client/thinkspace/team/assign/resource/view', 'client/thinkspace/team/assign/resource_team/view'], function (exports, ember, ns, base, team_view, resource_view, resource_team_view) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    assign_team_view: team_view['default'],
    assign_resource_view: resource_view['default'],
    assign_resource_team_view: resource_team_view['default'],
    resources: ember['default'].computed.reads('parentController.resources'),
    resource_teams_visible: true,
    team_users_visible: false,
    selected_resource: null,
    filtered_teams: ember['default'].computed.reads('all_teams_filtered_by_category'),
    actions: {
      select_resource: function(resource) {
        if (!resource) {
          return;
        }
        if (resource.get('team_ownerable')) {
          this.set_team_filter_category(this.get('collaboration_category'));
        } else {
          this.set_team_filter_category(this.get('peer_review_category'));
        }
        return this.set('selected_resource', resource);
      },
      toggle_resource_teams_visible: function() {
        this.toggleProperty('resource_teams_visible');
        return false;
      },
      toggle_team_users_visible: function() {
        this.toggleProperty('team_users_visible');
        return false;
      }
    },
    get_resource_teams: function(resource) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var team_promises, teamables;
          teamables = _this.get_resource_team_teamables(resource);
          team_promises = teamables.getEach(ns['default'].to_p('team'));
          return ember['default'].RSVP.Promise.all(team_promises).then(function(teams) {
            return resolve(teams.sortBy('title'));
          });
        };
      })(this));
    },
    get_resource_team_teamables: function(resource) {
      return this.store.all(ns['default'].to_p('team_teamable')).filter((function(_this) {
        return function(team_teamable) {
          return _this.record_is_polymorphic(resource, team_teamable, 'teamable');
        };
      })(this));
    },
    create_team_teamable: function(team, resource) {
      var team_teamable;
      if (!team) {
        this.totem_error["throw"](this, "create team teamable team is blank.");
      }
      if (!resource) {
        this.totem_error["throw"](this, "create team teamable resource is blank.");
      }
      if (!ember['default'].isBlank(this.get_team_teamables(team, resource))) {
        return;
      }
      team_teamable = this.store.createRecord(ns['default'].to_p('team_teamable'), {
        team_id: parseInt(team.get('id')),
        teamable_type: this.totem_scope.get_record_path(resource),
        teamable_id: parseInt(resource.get('id'))
      });
      team_teamable.set(ns['default'].to_p('team'), team);
      return team_teamable.save().then((function(_this) {
        return function() {
          return _this.totem_messages.api_success({
            source: _this,
            model: team_teamable,
            action: 'create'
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.totem_messages.api_failure(error, {
            source: _this,
            model: team_teamable,
            action: 'create'
          });
        };
      })(this));
    },
    delete_team_teamable: function(team, resource) {
      if (!team) {
        this.totem_error["throw"](this, "remove team teamable team is blank.");
      }
      if (!resource) {
        this.totem_error["throw"](this, "remove team teamable resource is blank.");
      }
      return this.get_team_teamables(team, resource).forEach((function(_this) {
        return function(team_teamable) {
          team_teamable.deleteRecord();
          return team_teamable.save().then(function() {
            _this.totem_messages.api_success({
              source: _this,
              model: team_teamable,
              action: 'delete'
            });
            return _this.remove_from_association(team, team_teamable);
          }, function(error) {
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: team_teamable,
              action: 'delete'
            });
          });
        };
      })(this));
    },
    get_team_teamables: function(team, resource) {
      var team_id;
      team_id = parseInt(team.get('id'));
      return this.store.all(ns['default'].to_p('team_teamable')).filter((function(_this) {
        return function(team_teamable) {
          return team_teamable.get('team_id') === team_id && _this.record_is_polymorphic(resource, team_teamable, 'teamable');
        };
      })(this));
    },
    remove_from_association: function(team, team_teamable) {
      return team.get(ns['default'].to_p('team_teamables')).then((function(_this) {
        return function(team_teamables) {
          return team_teamables.removeObject(team_teamable);
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});