define('client/components/thinkspace/indented_list/response/items', ['exports', 'ember', 'totem/ns', 'thinkspace-indented-list/response_manager', 'thinkspace-base/components/base'], function (exports, ember, ns, response_manager, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    indent_px: 20,
    c_response_item_show: ns['default'].to_p('indented_list', 'response', 'item', 'show'),
    c_list_layout: ember['default'].computed(function() {
      var layout;
      layout = this.get('list.layout');
      return ns['default'].to_p('indented_list', 'list', 'layout', layout);
    }),
    willInsertElement: function() {
      return this.totem_data.ability.refresh().then((function(_this) {
        return function() {
          var readonly, rm;
          readonly = _this.get('list.expert') || _this.get('viewonly');
          rm = response_manager['default'].create();
          rm.init_manager({
            model: _this.get('model'),
            indent_px: _this.get('indent_px'),
            readonly: readonly
          });
          return _this.set('response_manager', rm);
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});