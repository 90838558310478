define('client/models/thinkspace/indented_list/expert_response', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].belongs_to('indented:list', {
    reads: {}
  })), {
    value: ta['default'].attr()
  });

  exports['default'] = ___DefaultExportObject___;;

});