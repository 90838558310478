define('client/initializers/totem/config/query_params', ['exports', 'totem/config/query_params'], function (exports, qp) {

  'use strict';

  var ___DefaultExportObject___, initializer;

  initializer = {
    name: 'totem-config-query-params',
    initialize: function(container, app) {
      return qp['default'].process(container);
    }
  };

  ___DefaultExportObject___ = initializer;

  exports['default'] = ___DefaultExportObject___;;

});