define('client/components/thinkspace/reporter/report/tr', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: 'tr',
    model: null,
    c_loader: ns['default'].to_p('common', 'loader'),
    r_assignments_reports_show: ns['default'].to_r('assignments', 'reports', 'show'),
    actions: {
      "delete": function() {
        this.totem_messages.show_loading_outlet({
          message: 'Deleting report...'
        });
        return this.get('model').destroyRecord().then((function(_this) {
          return function() {
            return _this.totem_messages.hide_loading_outlet();
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});