define('client/models/thinkspace/markup/library', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].has_many('library_comments', {
    reads: {
      name: 'comments'
    }
  })), {
    user_id: ta['default'].attr('number'),
    all_tags: ta['default'].attr()
  });

  exports['default'] = ___DefaultExportObject___;;

});