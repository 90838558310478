define('client/components/thinkspace/common/space_user', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: 'tr',
    roles: ['read', 'update', 'owner'],
    actions: {
      destroy: function() {
        return this.get('model').destroyRecord();
      },
      save: function() {
        return this.get('model').save();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});