define('client/thinkspace/casespace/case_manager/phases/edit/controller', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace-case-manager/controllers/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    c_phase_edit: ns['default'].to_p('case_manager', 'assignment', 'wizards', 'casespace', 'steps', 'phases', 'edit')
  });

  exports['default'] = ___DefaultExportObject___;;

});