define('client/components/thinkspace/casespace/case_manager/assignment/peer_assessment/user', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'thinkspace-base/components/base'], function (exports, ember, ns, ajax, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    assessment: null,
    review_sets: null,
    color: null,
    notification: null,
    is_expanded: false,
    is_notifying: false,
    review_set: ember['default'].computed('review_sets', 'model', function() {
      var review_sets, user_id;
      review_sets = this.get('review_sets');
      user_id = parseInt(this.get('model.id'));
      if (!(ember['default'].isPresent(review_sets) && ember['default'].isPresent(user_id))) {
        return;
      }
      return review_sets.findBy('ownerable_id', user_id);
    }),
    review_set_state: ember['default'].computed('review_set.state', function() {
      var review_set;
      review_set = this.get('review_set');
      if (!ember['default'].isPresent(review_set)) {
        return 'Has not submitted any assessments';
      }
      return review_set.get('state');
    }),
    is_approvable: ember['default'].computed('review_set.state', 'review_set', function() {
      var review_set;
      review_set = this.get('review_set');
      if (!ember['default'].isPresent(review_set)) {
        return false;
      }
      return review_set.get('is_approvable');
    }),
    css_style: ember['default'].computed('color', function() {
      var color, css;
      color = this.get('color');
      if (!ember['default'].isPresent(color)) {
        return '';
      }
      css = '';
      css += "border-bottom-color: #" + color + ";";
      css += "border-top-color: #" + color + ";";
      return new ember['default'].Handlebars.SafeString(css);
    }),
    dropdown_collection: ember['default'].computed('model', function() {
      var collection, review_set;
      review_set = this.get('review_set');
      collection = [];
      if (!(ember['default'].isEmpty(review_set) || this.get('review_set.is_sent'))) {
        collection.pushObject({
          display: 'Approve',
          action: 'approve'
        });
      }
      return collection;
    }),
    c_review_set: ns['default'].to_p('case_manager', 'assignment', 'peer_assessment', 'review_set'),
    c_state: ns['default'].to_p('casespace', 'case_manager', 'assignment', 'peer_assessment', 'state'),
    c_dropdown_split_button: ns['default'].to_p('common', 'dropdown_split_button'),
    willInsertElement: function() {
      var $modal;
      this.get('review_set');
      $modal = this.$('.ts-tblpa_user-modal');
      return $modal.attr('id', this.get_modal_id());
    },
    state_change: function(state) {
      var query, review_set;
      review_set = this.get('review_set');
      query = {
        id: review_set.get('id'),
        action: state,
        verb: 'PUT'
      };
      return this.tc.query(ns['default'].to_p('tbl:review_set'), query, {
        single: true
      });
    },
    get_modal_id: function() {
      return this.elementId + "-ts-tblpa_user-modal";
    },
    get_modal: function() {
      return $("#" + (this.get_modal_id()));
    },
    show_modal: function() {
      return this.get_modal().foundation('reveal', 'open');
    },
    close_modal: function() {
      return this.get_modal().foundation('reveal', 'close');
    },
    set_is_notifying: function() {
      return this.set('is_notifying', true);
    },
    reset_is_notifying: function() {
      return this.set('is_notifying', false);
    },
    actions: {
      toggle: function() {
        return this.toggleProperty('is_expanded');
      },
      approve: function() {
        return this.state_change('approve');
      },
      unapprove: function() {
        return this.state_change('unapprove');
      },
      ignore: function() {
        return this.state_change('ignore');
      },
      approve_all: function() {
        return this.state_change('approve_all');
      },
      unapprove_all: function() {
        return this.state_change('unapprove_all');
      },
      notify: function() {
        return this.show_modal();
      },
      send_notification: function() {
        var assessment_id, model, notification, query, user_id;
        notification = this.get('notification');
        model = this.get('model');
        user_id = this.get('model.id');
        assessment_id = this.get('assessment.id');
        if (!(user_id && assessment_id)) {
          return;
        }
        query = {
          id: assessment_id,
          user_id: user_id,
          action: 'notify',
          notification: notification,
          verb: 'POST'
        };
        this.totem_messages.show_loading_outlet();
        return this.tc.query(ns['default'].to_p('tbl:assessment'), query, {
          single: true
        }).then((function(_this) {
          return function() {
            _this.totem_messages.api_success({
              source: _this,
              model: model,
              action: 'notify',
              i18n_path: ns['default'].to_o('tbl:assessment', 'notify')
            });
            _this.totem_messages.hide_loading_outlet();
            _this.set('notification', null);
            return _this.close_modal();
          };
        })(this));
      },
      close_notification_modal: function() {
        return this.close_modal();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});