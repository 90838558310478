define('client/models/thinkspace/casespace/phase_component', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].belongs_to('component', {
    reads: {}
  }), ta['default'].belongs_to('phase', {
    reads: {}
  }), ta['default'].polymorphic('componentable')), {
    section: ta['default'].attr('string'),
    componentable_id: ta['default'].attr('number'),
    componentable_type: ta['default'].attr('string')
  });

  exports['default'] = ___DefaultExportObject___;;

});