define('client/models/thinkspace/common/agreement', ['exports', 'ember', 'totem/ds/associations', 'totem/ns'], function (exports, ember, ta, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend({
    doc_type: ta['default'].attr('string'),
    link: ta['default'].attr('string')
  });

  exports['default'] = ___DefaultExportObject___;;

});