define('client/components/thinkspace/weather_forecaster/response/radio', ['exports', 'ember', 'totem/ns', 'totem/mixins/validations', 'thinkspace-base/components/base'], function (exports, ember, ns, val_mixin, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Component.extend(val_mixin['default'], {
    classNameBindings: ['no_errors::thinkspace-weather-forecaster_error'],
    no_errors: ember['default'].computed.or('is_valid', 'is_view_only'),
    c_radio_item: ns['default'].to_p('wf:response', 'radio', 'item'),
    choices: ember['default'].computed(function() {
      return this.get('metadata.choices');
    }),
    input_value: null,
    didInsertElement: function() {
      return this.set('input_value', this.get('model.value.input'));
    },
    actions: {
      check: function(id) {
        if (this.get('is_view_only')) {
          return;
        }
        this.set('input_value', id);
        return this.sendAction('save', id);
      },
      uncheck: function() {
        if (this.get('is_view_only')) {
          return;
        }
        this.set('input_value', null);
        return this.sendAction('save', null);
      }
    },
    validations: {
      input_value: {
        presence: {
          message: 'You must select one of the above options'
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});