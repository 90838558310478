define('client/components/thinkspace/markup/library/edit/manager/tag', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    model: null,
    tag_count: null,
    all_selected: null,
    library_tags: ember['default'].computed.reads('model.all_tags'),
    is_selected: false,
    c_checkbox: ns['default'].to_p('common', 'shared', 'checkbox'),
    all_selected_obs: ember['default'].observer('all_selected', function() {
      if (this.get('all_selected')) {
        return this.set('is_selected', false);
      }
    }),
    actions: {
      toggle_selected: function() {
        var tag_name;
        tag_name = this.get('model.name');
        this.toggleProperty('is_selected');
        return this.sendAction('click_action', tag_name);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});