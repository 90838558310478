define('client/components/thinkspace/markup/library/edit/manager/tags', ['exports', 'ember', 'totem/ns', 'totem/ds/associations', 'thinkspace-base/components/base'], function (exports, ember, ns, ta, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    c_clickable_tag: ns['default'].to_p('markup', 'library', 'edit', 'manager', 'tag'),
    c_validated_input: ns['default'].to_p('common', 'shared', 'validated_input'),
    c_checkbox: ns['default'].to_p('common', 'shared', 'checkbox'),
    model: null,
    all_tags: ember['default'].computed.reads('model.all_tags'),
    selected_tags: null,
    select_tag_action: null,
    add_tag_action: null,
    category_name: '',
    is_adding_category: false,
    select_all: null,
    input_class: null,
    init: function() {
      this._super();
      return this.sendAction('register_tags_component', this);
    },
    tag_count: null,
    all_tag_count: ember['default'].computed('sorted_tags', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get('sorted_tags').then(function(sorted_tags) {
            var count;
            count = 0;
            sorted_tags.forEach(function(tag) {
              return count += tag.count;
            });
            return resolve({
              count: count
            });
          });
        };
      })(this));
      return ta['default'].PromiseObject.create({
        promise: promise
      });
    }),
    sorted_tags: ember['default'].computed('model', 'model.all_tags.length', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var all_tags, model;
          model = _this.get('model');
          if (!ember['default'].isPresent(model)) {
            return resolve([]);
          }
          all_tags = model.get('all_tags');
          if (ember['default'].isEmpty(all_tags)) {
            return resolve([]);
          }
          return model.get('comments').then(function(comments) {
            var arr, temp;
            arr = [];
            temp = [];
            all_tags.forEach(function(tag) {
              var obj;
              obj = {};
              obj.name = tag;
              return arr.pushObject(obj);
            });
            comments.forEach(function(comment) {
              var comment_tags;
              comment_tags = comment.get('all_tags');
              return comment_tags.forEach(function(comment_tag) {
                var obj;
                obj = arr.findBy('name', comment_tag);
                if (ember['default'].isPresent(obj)) {
                  if (ember['default'].isPresent(obj.count)) {
                    return obj.count += 1;
                  } else {
                    return obj.count = 1;
                  }
                } else {
                  return console.error("[Markup:Tags] Tag " + comment_tag + " not found in library.");
                }
              });
            });
            arr.forEach(function(obj) {
              if (!ember['default'].isPresent(obj.count)) {
                return obj.count = 0;
              }
            });
            arr = arr.sortBy('count');
            arr.reverse();
            return resolve(arr);
          });
        };
      })(this));
      return ta['default'].PromiseArray.create({
        promise: promise
      });
    }),
    tags_did_change: function() {
      return this.propertyDidChange('sorted_tags');
    },
    keyPress: function(e) {
      if (ember['default'].isEqual(e.keyCode, 13)) {
        this.send('confirm_category_add');
        return false;
      }
    },
    actions: {
      confirm_category_add: function() {
        var category_name;
        category_name = this.get('category_name');
        if (category_name !== '') {
          this.sendAction('add_tag_action', category_name);
          return this.set('is_adding_category', false);
        }
      },
      add_category: function() {
        return this.set('is_adding_category', true);
      },
      cancel_category_add: function() {
        return this.set('is_adding_category', false);
      },
      all_selected: function() {
        return this.sendAction('select_all');
      },
      select_tag_action: function(tag) {
        return this.sendAction('select_tag_action', tag);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});