define('client/components/thinkspace/lab/observation/vet_med/correctable', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    is_correct: null,
    input_value: null,
    correction_visible: false,
    prompt_correct: ember['default'].computed(function() {
      return this.get_lab_observation().get_category_value('correctable_prompt');
    }),
    correct_value: ember['default'].computed(function() {
      return this.get_lab_observation().get_result_value('validate.correct');
    }),
    max_attempts: ember['default'].computed(function() {
      return this.get_lab_observation().get_result_value('max_attempts');
    }),
    show_help: ember['default'].computed('number_of_attempts', function() {
      return this.get('number_of_attempts') >= this.get('max_attempts');
    }),
    help_observer: ember['default'].observer('number_of_attempts', function() {
      var show_help;
      show_help = this.get('show_help');
      if (show_help) {
        return this.show_help_modal();
      }
    }),
    init: function() {
      var value;
      this._super();
      this.get_lab_observation().register_component(this);
      value = this.get_value();
      this.set_input_value(value);
      if (value) {
        this.set('correction_visible', true);
      }
      return this.set('number_of_attempts', 0);
    },
    get_help_modal: function() {
      return $('.ts-lab_modal');
    },
    show_help_modal: function() {
      return this.get_help_modal().foundation('reveal', 'open');
    },
    get_lab_observation: function() {
      return this.get('lab_observation');
    },
    get_value: function() {
      return this.get_lab_observation().get_value();
    },
    set_value: function(value) {
      return this.get_lab_observation().set_value(value);
    },
    set_lab_observation_value: function() {
      return this.set_value(this.get_input_value());
    },
    get_result: function() {
      return this.get_lab_observation().get_result();
    },
    get_input_value: function() {
      return this.get('input_value');
    },
    set_input_value: function(value) {
      return this.set('input_value', value);
    },
    save_result: function() {
      var correct_value, input_value;
      correct_value = parseFloat(this.get('correct_value'));
      input_value = parseFloat(this.get_input_value());
      if (ember['default'].isEqual(correct_value, input_value)) {
        this.set('is_correct', true);
        return this.get_lab_observation().save_result(this.get_result());
      } else {
        return this.incrementProperty('number_of_attempts');
      }
    },
    focus_next_result: function() {
      return this.get_lab_observation().set_focus_on_next_result();
    },
    focus_self: function() {
      return ember['default'].run.next((function(_this) {
        return function() {
          if (_this.get('correction_visible')) {
            return _this.$('input').focus();
          } else {
            return _this.focus_next_result();
          }
        };
      })(this));
    },
    enable_correction_input: function() {
      this.set('correction_visible', true);
      return this.focus_self();
    },
    keyPress: function(e) {
      var $target, tag_name;
      $target = $(e.target);
      tag_name = $target.prop('tagName').toLowerCase();
      switch (tag_name) {
        case 'input':
          this.set_lab_observation_value();
          if (ember['default'].isEqual(e.keyCode, 13)) {
            return this.save_result();
          }
          break;
        case 'a':
          return this.enable_correction_input();
      }
    },
    actions: {
      show: function() {
        return this.enable_correction_input();
      },
      set_input_value: function() {
        return this.set_lab_observation_value();
      },
      save: function() {
        return this.save_result();
      },
      cancel: function() {
        this.set('input_value', null);
        return this.set('correction_visible', false);
      },
      close_help_modal: function() {
        return this.get_help_modal().foundation('reveal', 'close');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});