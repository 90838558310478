define('client/models/thinkspace/common/configurable', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

	'use strict';

	var ___DefaultExportObject___;

	___DefaultExportObject___ = ta['default'].Model.extend();

	exports['default'] = ___DefaultExportObject___;;

});