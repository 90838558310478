define('client/models/thinkspace/weather_forecaster/station', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].has_many('wf:assessments')), {
    location: ta['default'].attr('string')
  });

  exports['default'] = ___DefaultExportObject___;;

});