define('client/users/password/confirmation/controller', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Controller.extend({
    c_user_password_confirmation: 'thinkspace/common/user/password/confirmation'
  });

  exports['default'] = ___DefaultExportObject___;;

});