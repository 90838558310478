define('client/components/thinkspace/casespace/phase/header/mock_progress', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    r_phases_show: ns['default'].to_r('phases', 'show'),
    is_current_phase: ember['default'].computed(function() {
      return this.get('current_phase') === this.get('model');
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});