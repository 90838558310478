define('client/services/thinkspace/peer_assessment/builder/manager', ['exports', 'ember', 'totem/ns', 'totem-messages/messages'], function (exports, ember, ns, totem_messages) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Service.extend({
    model: null,
    set_model: function(model) {
      console.info("[pa:builder] Model set to: ", model);
      return this.set('model', model);
    },
    save_model: function() {
      var model;
      model = this.get('model');
      return model.save().then((function(_this) {
        return function() {
          return totem_messages['default'].api_success({
            source: _this,
            model: model,
            action: 'update',
            i18n_path: ns['default'].to_o('tbl:assessment', 'save')
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    get_new_quant_item: function(label, type, settings) {
      var item;
      if (settings == null) {
        settings = {};
      }
      item = {
        id: this.get_next_quant_id(),
        label: label,
        type: type,
        settings: this.get_new_quant_item_settings()
      };
      return item;
    },
    get_new_quant_item_settings: function() {
      var settings;
      return settings = {
        points: {
          min: this.get_default_quant_points_min(),
          max: this.get_default_quant_points_max()
        },
        comments: {
          enabled: true
        },
        labels: {
          scale: {
            min: null,
            max: null
          }
        }
      };
    },
    get_default_quant_points_min: function() {
      return 1;
    },
    get_default_quant_points_max: function() {
      return 10;
    },
    get_new_qual_item: function(label, type, settings) {
      var item;
      if (settings == null) {
        settings = {};
      }
      return item = {
        id: this.get_next_qual_id(),
        label: label,
        feedback_type: 'positive',
        type: type
      };
    },
    add_quant_item: function() {
      var item;
      item = this.get_new_quant_item('New label', 'range');
      return this.add_item('quant', item);
    },
    add_qual_item: function(type) {
      var item;
      if (type == null) {
        type = 'textarea';
      }
      item = this.get_new_qual_item('New postitive qualitative question.', type);
      return this.add_item('qual', item);
    },
    delete_quant_item: function(item) {
      return this.delete_item('quant', item);
    },
    delete_qual_item: function(item) {
      return this.delete_item('qual', item);
    },
    reorder_quant_item: function(item, offset) {
      return this.reorder_item('quant', item, offset);
    },
    reorder_qual_item: function(item, offset) {
      return this.reorder_item('qual', item, offset);
    },
    duplicate_quant_item: function(item) {
      return this.duplicate_item('quant', this.get_next_quant_id(), item);
    },
    duplicate_qual_item: function(item) {
      return this.duplicate_item('qual', this.get_next_qual_id(), item);
    },
    get_next_quant_id: function() {
      return this.get_next_id('quant');
    },
    get_next_qual_id: function() {
      return this.get_next_id('qual');
    },
    get_items_for_type: function(type) {
      var model;
      model = this.get('model');
      return model.get(type + "_items");
    },
    add_item: function(type, item) {
      var items;
      items = this.get_items_for_type(type);
      items.pushObject(item);
      return this.save_model();
    },
    delete_item: function(type, item) {
      var items;
      items = this.get_items_for_type(type);
      items.removeObject(item);
      return this.save_model();
    },
    reorder_item: function(type, item, offset) {
      var add_at, index, items, length;
      items = this.get_items_for_type(type);
      index = items.indexOf(item);
      if (!(index > -1)) {
        return;
      }
      if (offset === 1) {
        add_at = index + 1;
      } else {
        add_at = index - 1;
      }
      if (add_at < 0) {
        return;
      }
      length = items.get('length');
      if (add_at > length - 1) {
        return;
      }
      items.removeAt(index);
      items.insertAt(add_at, item);
      return this.save_model();
    },
    duplicate_item: function(type, id, item) {
      var add_at, index, items, new_item;
      items = this.get_items_for_type(type);
      index = items.indexOf(item);
      if (!(index > -1)) {
        return;
      }
      add_at = index + 1;
      if (add_at < 0) {
        return;
      }
      new_item = ember['default'].merge({}, item);
      new_item.id = id;
      items.insertAt(add_at, new_item);
      return this.save_model();
    },
    get_next_id: function(type) {
      var id, items;
      items = this.get_items_for_type(type).sortBy('id');
      id = items.get('lastObject.id');
      if (ember['default'].isPresent(id)) {
        return id = id + 1;
      } else {
        return id = 1;
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});