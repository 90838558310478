define('client/components/thinkspace/builder/steps/parts/templates/detailed', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    model: null,
    selected_phase: null,
    c_phase: ns['default'].to_p('builder', 'steps', 'parts', 'templates', 'phase'),
    c_loader: ns['default'].to_p('common', 'loader'),
    init: function() {
      var model;
      this._super();
      model = this.get('model');
      return model.get('templateable').then((function(_this) {
        return function(assignment) {
          return _this.tc.query(ns['default'].to_p('assignment'), {
            id: assignment.get('id'),
            action: 'load'
          }, {
            single: true
          }).then(function(assignment) {
            var phases;
            phases = assignment.get(ns['default'].to_p('phases'));
            if (!ember['default'].isPresent(phases)) {
              console.error("[builder] Templateable does not have a `phases` getter.");
            }
            return phases.then(function(phases) {
              phases = phases.sortBy('position');
              _this.set('assignment', assignment);
              _this.set('phases', phases);
              _this.set('selected_phase', phases.get('firstObject'));
              return _this.set_all_data_loaded();
            });
          });
        };
      })(this));
    },
    actions: {
      back: function() {
        return this.sendAction('back');
      },
      use: function() {
        return this.sendAction('use');
      },
      select_phase: function(phase) {
        return this.set('selected_phase', phase);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});