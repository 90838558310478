define('client/components/thinkspace/readiness_assurance/admin/shared/users/user', ['exports', 'ember', 'totem/ns', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    show_teams: ember['default'].observer('show_all', function() {
      return this.set('show_team', this.get('show_all'));
    }),
    selected: ember['default'].computed('selected_users.[]', function() {
      return this.selected_users.contains(this.user);
    }),
    show_team: false,
    actions: {
      select: function() {
        return this.sendAction('select', this.user);
      },
      toggle_team: function() {
        this.toggleProperty('show_team');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});