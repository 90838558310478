define('client/thinkspace/casespace/case_manager/assignments/phase_order/route', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace-case-manager/routes/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    titleToken: function(model) {
      return 'Phase Order ' + model.get('title');
    },
    model: function(params) {
      return this.get_assignment_from_params(params);
    },
    afterModel: function(model) {
      return this.set_current_models({
        assignment: model
      }).then((function(_this) {
        return function() {
          return _this.load_assignment();
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});