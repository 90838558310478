define('client/initializers/thinkspace/casespace/dock_addons', ['exports', 'ember', 'totem/ns', 'thinkspace-dock/map'], function (exports, ember, ns, dock_map) {

  'use strict';

  var ___DefaultExportObject___, initializer;

  initializer = {
    name: 'thinkspace-casespace-dock-addons',
    after: ['totem'],
    initialize: function(container, app) {
      var case_manager, comments, gradebook, peer_review, resources;
      peer_review = {
        path: ns['default'].to_p('peer_review', 'dock'),
        group: 'first'
      };
      gradebook = {
        path: ns['default'].to_p('gradebook', 'dock'),
        group: 'first'
      };
      case_manager = {
        path: ns['default'].to_p('case_manager', 'dock'),
        group: 'first'
      };
      resources = {
        path: ns['default'].to_p('resource', 'dock'),
        group: 'middle'
      };
      comments = {
        path: ns['default'].to_p('markup', 'dock'),
        group: 'middle'
      };
      dock_map['default'].show(ns['default'].to_r('assignments', 'show.index'), [resources]);
      dock_map['default'].show(ns['default'].to_r('phases', 'show.index'), [resources]);
      dock_map['default'].show(ns['default'].to_r('phases', 'show.index'), [gradebook]);
      dock_map['default'].show(ns['default'].to_r('assignments', 'show.index'), [peer_review]);
      dock_map['default'].show(ns['default'].to_r('phases', 'show.index'), [peer_review]);
      return dock_map['default'].show(ns['default'].to_r('phases', 'show.index'), [comments]);
    }
  };

  ___DefaultExportObject___ = initializer;

  exports['default'] = ___DefaultExportObject___;;

});