define('client/components/thinkspace/markup/sidepocket/library', ['exports', 'ember', 'totem/ns', 'totem/ds/associations', 'thinkspace-base/components/base'], function (exports, ember, ns, ta, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    manager: ember['default'].inject.service(ns['default'].to_p('markup', 'manager')),
    model: null,
    wrapped_library_tags: ember['default'].computed('model', 'model.all_tags', function() {
      var tags, wrapped;
      tags = ember['default'].makeArray(this.get('model.all_tags'));
      wrapped = new Array;
      tags.forEach((function(_this) {
        return function(tag) {
          return wrapped.push({
            display: tag
          });
        };
      })(this));
      wrapped = wrapped.sortBy('display');
      wrapped.insertAt(0, {
        display: 'All',
        reset: true
      });
      return wrapped;
    }),
    filtered_library_comments: ember['default'].computed('model', 'model.comments', 'manager.selected_library_tags.@each', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var manager, model, tags;
          model = _this.get('model');
          if (!model) {
            return resolve(new Array);
          }
          manager = _this.get('manager');
          tags = manager.get_selected_library_tags();
          return model.get(ns['default'].to_p('library_comments')).then(function(comments) {
            var filtered;
            if (ember['default'].isEmpty(tags)) {
              return resolve(comments);
            }
            filtered = comments.filter(function(comment) {
              var comment_tags, has_match;
              comment_tags = comment.get('all_tags');
              has_match = false;
              comment_tags.forEach(function(comment_tag) {
                if (tags.contains(comment_tag)) {
                  return has_match = true;
                }
              });
              return has_match;
            });
            return resolve(filtered);
          });
        };
      })(this));
      return ta['default'].PromiseArray.create({
        promise: promise
      });
    }),
    selected_library_tags: ember['default'].computed.reads('manager.selected_library_tags'),
    has_filtered_library_comments: ember['default'].computed.notEmpty('filtered_library_comments'),
    has_selected_library_tags: ember['default'].computed.notEmpty('manager.selected_library_tags'),
    c_library_comment: ns['default'].to_p('markup', 'sidepocket', 'library', 'comment'),
    c_dropdown: ns['default'].to_p('common', 'dropdown'),
    c_loader: ns['default'].to_p('common', 'loader'),
    r_libraries_edit: ns['default'].to_r('markup', 'libraries', 'edit'),
    init: function() {
      this._super();
      return this.get('manager').get_library_for_current_user().then((function(_this) {
        return function(library) {
          return library.get(ns['default'].to_p('library_comments')).then(function() {
            _this.set('model', library);
            return _this.set_all_data_loaded();
          });
        };
      })(this));
    },
    actions: {
      select: function(comment) {
        var manager;
        manager = this.get('manager');
        if (comment === manager.get_selected_library_comment()) {
          return manager.reset_selected_library_comment();
        } else {
          return manager.set_selected_library_comment(comment);
        }
      },
      select_tag: function(tag) {
        if (!tag) {
          return;
        }
        if (tag.reset) {
          return this.get('manager').reset_selected_library_tags();
        } else {
          tag = tag.display;
          return this.get('manager').add_selected_library_tag(tag);
        }
      },
      deselect_tag: function(tag) {
        if (!tag) {
          return;
        }
        return this.get('manager').remove_selected_library_tag(tag);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});