define('client/components/thinkspace/indented_list/response/item/show', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    classNames: ['indented-list_item-container'],
    classNameBindings: ['zoom_level_class', 'item_visible::indented-list_hide', 'is_selected:indented-list_selected-item', 'overflow_visible:overflow-visible', 'overflow_visible:is-expanded', 'edit_visible:is-editing', 'confirm_remove_visible:is-deleting', 'is_mechanism:is-mechanism'],
    attributeBindings: ['tabindex'],
    c_response_item_edit: ns['default'].to_p('indented_list', 'response', 'item', 'edit'),
    c_response_item_confirm_remove: ns['default'].to_p('indented_list', 'response', 'item', 'confirm_remove'),
    c_dropdown_split_button: ns['default'].to_p('common', 'dropdown_split_button'),
    is_mechanism: ember['default'].computed.equal('item.icon', 'mechanism'),
    zoom_level_class: ember['default'].computed('response_manager.zoom_level', function() {
      return "zoom-" + (this.get('response_manager.zoom_level'));
    }),
    show_dropdown: ember['default'].computed.or('has_overflow', 'has_children', 'response_manager.updateable'),
    dropdown_collection: ember['default'].computed('has_children', 'has_overflow', 'children_visible', 'overflow_visible', function() {
      var collection, overflow_text, pack_text;
      overflow_text = this.get('overflow_visible') ? 'Collapse' : 'Expand';
      pack_text = this.get('children_visible') ? 'Pack with children' : 'Unpack children';
      collection = [];
      if (this.get('has_overflow')) {
        collection.push({
          display: overflow_text,
          action: 'toggle_overflow'
        });
      }
      if (this.get('has_children')) {
        collection.push({
          display: pack_text,
          action: 'toggle_children'
        });
      }
      if (this.get('response_manager.updateable')) {
        collection.push({
          display: 'Edit',
          action: 'edit'
        });
        collection.push({
          display: 'Duplicate before',
          action: 'duplicate_before'
        });
        collection.push({
          display: 'Duplicate after',
          action: 'duplicate_after'
        });
        collection.push({
          display: 'Add path item above',
          action: 'insert_before'
        });
        collection.push({
          display: 'Add path item below',
          action: 'insert_after'
        });
        collection.push({
          display: 'Remove',
          action: 'remove'
        });
      }
      return collection;
    }),
    item_visible: true,
    children_visible: true,
    has_children: false,
    number_children: null,
    is_selected: false,
    show_value: null,
    edit_visible: false,
    confirm_remove_visible: false,
    tabindex: 1,
    get_element: function() {
      return this.$();
    },
    get_item: function() {
      return this.get('item');
    },
    get_response_manager: function() {
      return this.get('response_manager');
    },
    turn_on_edit: function() {
      return this.set('edit_visible', true);
    },
    turn_off_edit: function() {
      return this.set('edit_visible', false);
    },
    turn_on_remove: function() {
      return this.set('confirm_remove_visible', true);
    },
    turn_off_remove: function() {
      return this.set('confirm_remove_visible', false);
    },
    click: function(event) {
      if (this.get('edit_visible')) {
        return;
      }
      this.get_response_manager().select_item(this.get_item());
      return this.highlight_itemable();
    },
    focusOut: function(event) {
      this.set('is_selected', false);
      return this.unhighlight_itemable();
    },
    focusIn: function(event) {
      if (this.get('edit_visible')) {
        return;
      }
      this.get_response_manager().select_item(this.get_item(), {
        skip_focus: true
      });
      return this.highlight_itemable();
    },
    keyDown: function(event) {
      if (this.get('edit_visible')) {
        return;
      }
      switch (event.keyCode) {
        case 37:
          this.get_response_manager().move_left(this.get_item());
          return this.stop_event(event);
        case 38:
          this.get_response_manager().move_up(this.get_item());
          return this.stop_event(event);
        case 39:
          this.get_response_manager().move_right(this.get_item());
          return this.stop_event(event);
        case 40:
          this.get_response_manager().move_down(this.get_item());
          return this.stop_event(event);
        case 46:
          this.send('remove');
          return this.stop_event(event);
      }
    },
    keyPress: function(event) {
      var char_code, value;
      if (this.get('edit_visible')) {
        return;
      }
      char_code = event.which || event.charCode || event.keyCode;
      if (!char_code) {
        return;
      }
      value = String.fromCharCode(char_code).toLowerCase();
      switch (value) {
        case 'q':
          this.insert_before();
          return this.stop_event(event);
        case 'w':
          this.insert_after();
          return this.stop_event(event);
        case 'a':
          this.duplicate_before();
          return this.stop_event(event);
        case 's':
          this.duplicate_after();
          return this.stop_event(event);
        case 'z':
          this.send('toggle_children');
          return this.stop_event(event);
      }
    },
    stop_event: function(event) {
      event.preventDefault();
      return event.stopPropagation();
    },
    duplicate_before: function() {
      return this.get_response_manager().duplicate_before(this.get_item());
    },
    duplicate_after: function() {
      return this.get_response_manager().duplicate_after(this.get_item());
    },
    insert_before: function() {
      return this.get_response_manager().insert_before(this.get_item());
    },
    insert_after: function() {
      return this.get_response_manager().insert_after(this.get_item());
    },
    actions: {
      duplicate_before: function() {
        return this.duplicate_before();
      },
      duplicate_after: function() {
        return this.duplicate_after();
      },
      insert_before: function() {
        return this.insert_before();
      },
      insert_after: function() {
        return this.insert_after();
      },
      remove: function() {
        if (this.get_readonly()) {
          return;
        }
        if (this.get_response_manager().show_confirm_remove()) {
          this.remove_draggable_class();
          this.turn_off_edit();
          return this.turn_on_remove();
        } else {
          return this.send('remove_ok');
        }
      },
      remove_cancel: function() {
        this.add_draggable_class();
        return this.turn_off_remove();
      },
      remove_ok: function() {
        this.unhighlight_itemable();
        return this.get_response_manager().remove_item(this.get_item());
      },
      edit: function() {
        if (this.get_readonly()) {
          return;
        }
        this.remove_draggable_class();
        this.turn_off_remove();
        return this.turn_on_edit();
      },
      edit_cancel: function() {
        this.add_draggable_class();
        return this.turn_off_edit();
      },
      edit_done: function(value) {
        var item, rm;
        item = this.get_item();
        rm = this.get_response_manager();
        if (ember['default'].isPresent(value)) {
          item.description = value.trimRight();
          rm.clear_item_itemable(item).then((function(_this) {
            return function() {
              _this.set_item_show_value();
              _this.set_has_overflow();
              return rm.save_response();
            };
          })(this));
        }
        return this.send('edit_cancel');
      },
      toggle_overflow: function() {
        this.toggleProperty('overflow_visible');
      },
      toggle_children: function() {
        var items;
        if (!this.get('has_children')) {
          return;
        }
        items = this.get_item_children();
        this.get_response_manager().set_children_items_visibility(items, this.toggleProperty('children_visible'));
        return this.set_number_of_children();
      }
    },
    set_number_of_children: function() {
      if (this.get('children_visible')) {
        return;
      }
      return this.set('number_children', this.get_item_children(false).length);
    },
    willInsertElement: function() {
      this.set_item_show_value();
      this.set_itemable_is_used();
      this.set_itemable_icon();
      return this.get_response_manager().register_component(this);
    },
    didInsertElement: function() {
      var item;
      this.add_draggable_class();
      this.set_has_children();
      this.add_item_classes();
      this.set_indent();
      this.set('guid', ember['default'].guidFor(this));
      item = this.get_item();
      if (!ember['default'].isPresent(item.test_id)) {
        return ember['default'].set(item, 'test_id', this.get_response_manager().value_items.indexOf(item));
      }
    },
    set_item_show_value: function() {
      var item, rm;
      rm = this.get_response_manager();
      item = this.get_item();
      return rm.get_item_value(item).then((function(_this) {
        return function(value) {
          return _this.set('show_value', value);
        };
      })(this));
    },
    set_has_children: function() {
      return this.set('has_children', this.get_response_manager().has_item_children(this.get_item()));
    },
    get_item_children: function(visible) {
      if (visible == null) {
        visible = true;
      }
      return this.get_response_manager().get_item_children(this.get_item(), visible);
    },
    add_item_classes: function() {
      return this.get_response_manager().add_element_item_classes(this.get_element(), this.get_item());
    },
    set_itemable_is_used: function() {
      return this.get_response_manager().set_itemable_is_used(this.get_item());
    },
    add_draggable_class: function() {
      return this.get_response_manager().add_draggable_class(this.get_element());
    },
    remove_draggable_class: function() {
      return this.get_response_manager().remove_draggable_class(this.get_element());
    },
    get_readonly: function() {
      return this.get_response_manager().readonly;
    },
    itemable_icon: null,
    set_itemable_icon: function() {
      var icon, item;
      item = this.get_item();
      icon = this.get_response_manager().get_item_itemable_icon(item);
      return this.set('itemable_icon', icon);
    },
    set_indent: function() {
      var $el, item, left;
      item = this.get_item();
      left = this.get_response_manager().calc_item_indent(item);
      $el = this.get_element();
      $el.css('margin-left', left);
      this.set_has_overflow();
      return this.set_indent_letter();
    },
    background_color: function(x) {
      switch (x) {
        case 0:
          return 'lightgray';
        case 1:
          return 'lightblue';
        case 2:
          return 'wheat';
        case 3:
          return 'lightgreen';
        case 4:
          return 'lightsalmon';
        case 5:
          return 'lightcoral';
        case 6:
          return 'lightpink';
        default:
          return this.get_random_rgb(x);
      }
    },
    get_random_rgb: function(x) {
      var b, g, max, min, r;
      min = 0;
      max = 255;
      r = Math.floor(Math.random() * (max - min + 1) + min);
      g = Math.floor(Math.random() * (max - 100 + 1) + 100);
      b = Math.floor(Math.random() * (max - min + 1) + min);
      return "rgb(" + r + "," + g + "," + b + ")";
    },
    itemable_highlight_class: 'indented-list_highlight-itemable',
    unhighlight_itemable: function() {
      return this.get_itemable_element().removeClass(this.get('itemable_highlight_class'));
    },
    highlight_itemable: function() {
      return this.get_itemable_element().addClass(this.get('itemable_highlight_class'));
    },
    get_itemable_element: function() {
      var id, item, path, type;
      item = this.get_item();
      id = item.itemable_id;
      type = item.itemable_type;
      if (!(type && id)) {
        return $(null);
      }
      path = this.totem_scope.rails_polymorphic_type_to_path(type);
      return $("[model_id='" + id + "'][model_type='" + path + "']");
    },
    overflow_visible: false,
    has_overflow: false,
    set_overflow_visible_on: function() {
      return this.set('overflow_visible', true);
    },
    set_overflow_visible_off: function() {
      return this.set('overflow_visible', false);
    },
    set_has_overflow: function() {
      return ember['default'].run.schedule('afterRender', this, (function(_this) {
        return function() {
          var $el, element;
          $el = _this.$().find('.overflow');
          element = $el[0];
          if (ember['default'].isBlank(element)) {
            return;
          }
          _this.set('has_overflow', element.scrollWidth > element.clientWidth);
          return _this.propertyDidChange('dropdown_collection');
        };
      })(this));
    },
    set_indent_letter: function(indent) {
      var char, pos_x;
      pos_x = parseInt(this.get_item().pos_x);
      char = String.fromCharCode(97 + pos_x);
      if (pos_x > 25) {
        char = 'ZZ';
      }
      return this.set('indent_letter', char.capitalize());
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});