define('client/components/thinkspace/casespace/assignments', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    c_assignment: ns['default'].to_p('assignment'),
    c_loader: ns['default'].to_p('common', 'loader'),
    r_assignments_new: ns['default'].to_r('builder', 'cases', 'new'),
    init: function() {
      var model;
      this._super();
      model = this.get('model');
      return model.get(ns['default'].to_p('assignments')).then((function(_this) {
        return function(assignments) {
          return _this.set_all_data_loaded();
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});