define('client/views/date_picker/base', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].View.extend({
    classNames: ['date-picker'],
    is_open: false,
    hide_input: false,
    hide_button: false,
    time_picker: false,
    save_on_close: true,
    current_time: null,
    current_date: null,
    time_picker_header: '',
    date_picker_header: '',
    placeholder: '',
    didInsertElement: function() {
      var content, controller, date, date_type, property, selected_time, time_picker, view;
      view = this;
      time_picker = this.get('time_picker');
      controller = this.get('controller');
      content = this.get('content');
      property = this.get('property');
      date = content.get(property);
      date_type = typeof date;
      if (date_type === 'string') {
        date = new Date(date);
      }
      if (date) {
        selected_time = moment(date).format('h:mm A');
      }
      if (!selected_time) {
        selected_time = '12:00 PM';
      }
      if (date) {
        this.set('current_date', date.toDateString());
      }
      if (selected_time) {
        this.set('current_time', selected_time);
      }
      if (time_picker) {
        this.set_and_initialize_time_picker(selected_time);
        return this.set_and_initialize_date_picker(date);
      } else {
        return this.set_and_initialize_date_picker(date);
      }
    },
    set_and_initialize_time_picker: function(time) {
      var $input, save_on_close, time_picker, view;
      $input = this.$('.date-pick_time-picker-input');
      view = this;
      save_on_close = this.get('save_on_close');
      $input.pickatime({
        onSet: function() {
          var time_string;
          time_string = this.get();
          view.set('current_time', time_string);
          view.set_content_property();
          if (save_on_close) {
            return view.save_content();
          }
        }
      });
      time_picker = $input.pickatime('picker');
      return time_picker.set('select', time);
    },
    set_and_initialize_date_picker: function(date) {
      var $input, content, date_picker, save_on_close, view;
      $input = this.$('.datepicker');
      content = this.get('content');
      view = this;
      save_on_close = this.get('save_on_close');
      $input.pickadate({
        onClose: function() {
          var date_string;
          date_string = this.get();
          if (ember['default'].isEmpty(date_string)) {
            return;
          }
          view.set('current_date', date_string);
          view.set_content_property();
          if (save_on_close) {
            return view.save_content();
          }
        }
      });
      date_picker = $input.pickadate('picker');
      return date_picker.set('select', date);
    },
    get_date: function() {
      var current_date, current_time, date, date_string;
      current_time = this.get('current_time');
      current_date = this.get('current_date');
      date_string = current_date + " " + current_time;
      return date = new Date(date_string);
    },
    set_content_property: function() {
      var content, date, property;
      content = this.get('content');
      property = this.get('property');
      if (!content && property) {
        return;
      }
      date = this.get_date();
      return content.set(property, date);
    },
    save_content: function() {
      var content;
      content = this.get('content');
      return content.save();
    },
    actions: {
      toggle_date: function() {
        var $date_picker, date_picker;
        $date_picker = this.$('.datepicker').pickadate();
        date_picker = $date_picker.pickadate('picker');
        return date_picker.open();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});