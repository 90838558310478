define('client/components/thinkspace/casespace/case_manager/team_set/index', ['exports', 'ember', 'ember-data', 'totem/ns', 'totem/ajax', 'thinkspace-base/components/base'], function (exports, ember, ds, ns, ajax, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    team_sets: null,
    current_team_sets: ember['default'].computed.reads('team_manager.current_team_sets'),
    c_space_header: ns['default'].to_p('space', 'header'),
    c_file_upload: ns['default'].to_p('common', 'file-upload'),
    c_file_upload_modal: ns['default'].to_p('case_manager', 'team_sets', 'import_modal'),
    r_team_sets_show: ns['default'].to_r('case_manager', 'team_sets', 'show'),
    r_team_sets_new: ns['default'].to_r('case_manager', 'team_sets', 'new'),
    team_manager: ember['default'].inject.service(),
    import_form_action: ember['default'].computed('model', function() {
      return "/api/thinkspace/common/spaces/" + (this.get('model.id')) + "/import_teams";
    }),
    import_model_path: 'thinkspace/common/space',
    import_params: ember['default'].computed('model', function() {
      return {
        id: this.get('model.id')
      };
    }),
    import_btn_text: 'Import Team Roster',
    import_loading_text: 'Importing team roster..'
  });

  exports['default'] = ___DefaultExportObject___;;

});