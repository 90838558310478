define('client/components/thinkspace/common/dropdown_split_button', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    classNames: ['thinkspace-dropdown'],
    first_button: null,
    buttons: null,
    dropdown_text: '',
    list_width_class: null,
    icon_classes: 'tsi icon-small tsi-down-caret-inverse',
    drop_id: ember['default'].computed(function() {
      return "ts-drop_" + (this.get('elementId'));
    }),
    collection_observer: ember['default'].observer('collection', function() {
      return ember['default'].run.next((function(_this) {
        return function() {
          if (!(_this.get('isDestroying') || _this.get('isDestroyed'))) {
            return _this.map_buttons();
          }
        };
      })(this));
    }),
    init: function() {
      this._super();
      return this.map_buttons();
    },
    didInsertElement: function() {
      return $(document).foundation('dropdown');
    },
    map_buttons: function() {
      var collection;
      collection = (this.get('collection') || []).concat([]);
      collection.map((function(_this) {
        return function(params) {
          if (params.action) {
            return _this.set(params.action, params.action);
          }
        };
      })(this));
      this.set_list_width_class(collection);
      if (this.get('show_button') !== false) {
        this.set('first_button', collection.shift());
      }
      this.set('dropdown_text', this.get('text') || '');
      return this.set('buttons', collection);
    },
    set_list_width_class: function(collection) {
      var klass, lengths, max;
      lengths = collection.map(function(hash) {
        return (hash.display && hash.display.length) || 0;
      });
      max = lengths.sort().pop() || 0;
      switch (false) {
        case !(max < 15):
          klass = 'thinkspace-dropdown_split-button-small';
          break;
        case !(max < 25):
          klass = 'thinkspace-dropdown_split-button-medium';
          break;
        default:
          klass = 'thinkspace-dropdown_split-button-large';
      }
      return this.set('list_width_class', klass);
    },
    actions: {
      _select: function(params) {
        return this.sendAction(params.action, params);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});