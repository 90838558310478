define('client/components/thinkspace/casespace/case_manager/assignment/wizards/casespace/steps/phases/edit', ['exports', 'ember', 'totem/ns', 'totem/ds/associations', 'totem/ajax', 'totem/mixins/validations', 'thinkspace-base/components/base'], function (exports, ember, ns, ta, ajax, val_mixin, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend(val_mixin['default'], {
    init: function() {
      this._super();
      return this.set_team_sets().then((function(_this) {
        return function() {
          return _this.set_team_categories();
        };
      })(this));
    },
    submit_text: ember['default'].computed.reads('model.submit_text'),
    submit_visible: ember['default'].computed.reads('model.submit_visible'),
    max_score: ember['default'].computed.reads('model.max_score'),
    title: ember['default'].computed.reads('model.title'),
    configuration_validate: ember['default'].computed.reads('model.configuration_validate'),
    description: ember['default'].computed.reads('model.description'),
    auto_score: ember['default'].computed.reads('model.has_auto_score'),
    complete_phase: ember['default'].computed.reads('model.has_complete_phase'),
    unlock_phase: ember['default'].computed.reads('model.has_unlock_phase'),
    team_based: ember['default'].computed.or('model.team_category_id', 'model.team_set_id'),
    c_validated_input: ns['default'].to_p('common', 'shared', 'validated_input'),
    c_checkbox: ns['default'].to_p('common', 'shared', 'checkbox'),
    c_componentable: ns['default'].to_p('case_manager', 'assignment', 'wizards', 'casespace', 'steps', 'phases', 'componentable'),
    c_loader: ns['default'].to_p('common', 'shared', 'loader'),
    wizard_manager: ember['default'].inject.service(),
    case_manager: ember['default'].inject.service(),
    thinkspace: ember['default'].inject.service(),
    t_phase_form: ns['default'].to_t('case_manager', 'assignment', 'wizards', 'casespace', 'steps', 'phases', 'form'),
    has_team_categories: false,
    team_categories: null,
    team_category_id: null,
    team_category_selected: null,
    has_team_sets: false,
    team_sets: null,
    team_set_id: ember['default'].computed.reads('model.team_set_id'),
    team_set_selected: null,
    show_phase: true,
    current_componentable: null,
    componentables_loaded: false,
    has_componentables: false,
    set_team_sets: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var space;
          space = _this.get('case_manager.current_space');
          return space.get_team_sets().then(function(team_sets) {
            var team_set_id;
            _this.set('team_sets', team_sets);
            team_set_id = _this.get('model.team_set_id');
            if (ember['default'].isPresent(team_set_id)) {
              team_set_id = "" + team_set_id;
              _this.set('team_set_id', team_set_id);
            }
            _this.set('has_team_sets', true);
            return resolve();
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return console.error("[steps:phases:edit] Error in team sets find for phase edit.", error);
        };
      })(this));
    },
    set_team_categories: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get('case_manager').get_team_categories().then(function(categories) {
            var team_category_id;
            categories = categories.sortBy('title');
            categories = categories.filter(function(category) {
              return !ember['default'].isEqual(category.get('category'), 'assessment');
            });
            _this.set('team_categories', categories);
            team_category_id = _this.get('model.team_category_id');
            if (ember['default'].isPresent(team_category_id)) {
              team_category_id = "" + team_category_id;
              _this.set('team_category_id', team_category_id);
            }
            _this.set('has_team_categories', true);
            return resolve();
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return console.error("[steps:phases:edit] Error in set_team_categories", error);
        };
      })(this));
    },
    get_data: function() {
      var data;
      return data = {
        max_score: this.get('max_score'),
        submit_text: this.get('submit_text'),
        submit_visible: this.get('submit_visible'),
        title: this.get('title'),
        configuration_validate: this.get('configuration_validate'),
        team_based: this.get('team_based'),
        description: this.get('description'),
        team_category_id: this.get('team_category_selected.id'),
        team_set_id: this.get('team_set_selected.id'),
        auto_score: this.get('auto_score'),
        complete_phase: this.get('complete_phase'),
        unlock_phase: this.get('unlock_phase')
      };
    },
    actions: {
      save: function() {
        var phase, query, store;
        phase = this.get('model');
        store = this.totem_scope.get_store();
        query = {
          model: phase,
          id: phase.get('id'),
          action: '',
          verb: 'put',
          data: this.get_data()
        };
        return ajax['default'].object(query).then((function(_this) {
          return function(payload) {
            store.pushPayload(payload);
            return _this.send('exit');
          };
        })(this));
      },
      cancel: function() {
        var phase;
        phase = this.get('model');
        if (phase.get('isDirty')) {
          phase.rollback();
        }
        return this.get('componentables').then((function(_this) {
          return function(componentables) {
            componentables.forEach(function(componentable) {
              if (typeof componentable.admin_exit === 'function') {
                return componentable.admin_exit();
              } else {
                return componentable.unloadRecord();
              }
            });
            return _this.send('exit');
          };
        })(this));
      },
      exit: function() {
        var assignment, wizard_manager;
        wizard_manager = this.get('wizard_manager');
        assignment = wizard_manager.get_current_assignment();
        return wizard_manager.transition_to_assignment_edit(assignment, {
          queryParams: {
            step: 'phases'
          }
        });
      },
      select_componentable: function(componentable) {
        this.set('current_componentable', componentable);
        this.set('show_phase', ember['default'].isBlank(componentable));
        return this.get('thinkspace').scroll_to_top();
      },
      toggle_submit_visible: function() {
        return this.toggleProperty('submit_visible');
      },
      toggle_configuration_validate: function() {
        return this.toggleProperty('configuration_validate');
      },
      toggle_unlock_phase: function() {
        return this.toggleProperty('unlock_phase');
      },
      toggle_complete_phase: function() {
        return this.toggleProperty('complete_phase');
      },
      toggle_auto_score: function() {
        return this.toggleProperty('auto_score');
      },
      toggle_team_based: function() {
        return this.toggleProperty('team_based');
      }
    },
    componentables: ember['default'].computed(function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var case_manager, componentables, key, phase, phase_map, query;
          phase = _this.get('model');
          case_manager = _this.get('case_manager');
          phase_map = case_manager.get_or_init_case_manager_map(phase);
          key = 'componentables';
          componentables = phase_map.get(key);
          if (ember['default'].isPresent(componentables)) {
            _this.set_componentables_loaded(componentables);
            return resolve(componentables);
          }
          query = {
            model: phase,
            id: phase.get('id'),
            action: 'componentables',
            verb: 'get'
          };
          return ajax['default'].object(query).then(function(payload) {
            var records;
            records = ajax['default'].extract_included_records(payload);
            phase_map.set(key, records);
            _this.set_componentables_loaded(records);
            return resolve(records);
          });
        };
      })(this));
      return ta['default'].PromiseArray.create({
        promise: promise
      });
    }),
    set_componentables_loaded: function(records) {
      this.set('componentables_loaded', true);
      if (!ember['default'].isPresent(records)) {
        return;
      }
      return records.forEach((function(_this) {
        return function(record) {
          if (ember['default'].isPresent(record.get('edit_component'))) {
            return _this.set('has_componentables', true);
          }
        };
      })(this));
    },
    validations: {
      title: {
        presence: true,
        modelErrors: true
      },
      max_score: {
        presence: true,
        numericality: {
          onlyInteger: true,
          greaterThanOrEqualTo: 1,
          lessThanOrEqualTo: 1000
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});