define('client/components/thinkspace/casespace/case_manager/assignment/peer_assessment/review/qualitative', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    model: null,
    review: null,
    assessment: null,
    item: null,
    is_editing: false,
    label: ember['default'].computed.reads('model.label'),
    has_value: ember['default'].computed.notEmpty('value'),
    is_not_sent: ember['default'].computed.reads('review.is_not_sent'),
    init: function() {
      var model, review, value;
      this._super();
      review = this.get('review');
      model = this.get('model');
      value = review.get_quantitative_value_for_id(model.id);
      return this.set('value', value);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});