define('client/helpers/phase-progress-icon-from-state', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Handlebars.makeBoundHelper(function(current_state, title, options) {
    var tag_title;
    if (!options) {
      options = title;
      title = null;
    }
    tag_title = (title && ("title='" + title + "'")) || '';
    if (current_state) {
      return ("<div class='tsi tsi-small tsi-phase-" + current_state + "' " + tag_title + "></div>").htmlSafe();
    } else {
      return ("<div class='tsi tsi-small tsi-phase-unlocked " + tag_title + "></div>").htmlSafe();
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});