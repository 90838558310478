define('client/helpers/lab-result-range', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Handlebars.makeBoundHelper(function(result, prop, options) {
    var lower, range, sep, upper;
    if (!(result && prop)) {
      return '';
    }
    range = result.get("values.columns." + prop) || {};
    lower = range.lower || '';
    upper = range.upper || '';
    sep = range.sep || ' - ';
    switch (false) {
      case !(lower && upper):
        return lower + sep + upper;
      case !lower:
        return lower;
      case !upper:
        return upper;
      default:
        return '';
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});