define('client/components/thinkspace/casespace/gradebook/assignment/roster/shared/state', ['exports', 'ember', 'thinkspace-base/components/base'], function (exports, ember, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    title: ember['default'].computed(function() {
      return (this.get('team_ownerable') && this.get('group_values.team_label')) || this.get('group_values.user_label') || this.get('group_values.label');
    }),
    current_state: ember['default'].computed.reads('group_values.state'),
    team_ownerable: ember['default'].computed.reads('group_values.team_ownerable'),
    can_edit: ember['default'].computed.and('is_edit', 'group_values.state_id'),
    edit_state_visible: false,
    new_state: null,
    domain_phase_states: [
      {
        state: 'unlocked',
        title: 'Unlock'
      }, {
        state: 'locked',
        title: 'Lock'
      }, {
        state: 'completed',
        title: 'Complete'
      }
    ],
    actions: {
      change: function(phase_state) {
        this.set('edit_state_visible', false);
        return this.sendAction('save_state', this.get('group_values'), phase_state);
      },
      cancel: function() {
        return this.set('edit_state_visible', false);
      },
      toggle_edit: function() {
        this.toggleProperty('edit_state_visible');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});