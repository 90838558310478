define('client/helpers/is-current', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Handlebars.makeBoundHelper(function(each_obj, current_obj, options) {
    var hash, string;
    hash = options.hash || {};
    if (each_obj && each_obj === current_obj) {
      string = hash.if_true || '';
    } else {
      string = hash.if_false || '';
    }
    return string.htmlSafe();
  });

  exports['default'] = ___DefaultExportObject___;;

});