define('client/components/thinkspace/html/html_select_text', ['exports', 'ember', 'totem/ns', 'thinkspace-html/components/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    mouseUp: function(event) {
      var action, i, len, results, section, sections, sel_obj, tvo, value;
      if (this.totem_scope.get('is_view_only')) {
        return;
      }
      sel_obj = window.getSelection();
      value = sel_obj.toString();
      if (!value) {
        return;
      }
      sel_obj.removeAllRanges();
      tvo = this.get('tvo');
      action = 'select-text';
      sections = tvo.attribute_value_array(this.get("attributes." + action));
      if (ember['default'].isBlank(sections)) {
        return;
      }
      results = [];
      for (i = 0, len = sections.length; i < len; i++) {
        section = sections[i];
        if (tvo.section.has_action(section, action)) {
          results.push(tvo.section.send_action(section, action, value));
        } else {
          results.push(void 0);
        }
      }
      return results;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});