define('client/helpers/chosen-select', ['exports', 'ember', 'client/views/select/select'], function (exports, ember, view) {

	'use strict';

	var ___DefaultExportObject___;

	___DefaultExportObject___ = ember['default'].Handlebars.makeViewHelper(view['default']);

	exports['default'] = ___DefaultExportObject___;;

});