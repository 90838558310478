define('client/components/thinkspace/readiness_assurance/admin/shared/phase_states', ['exports', 'ember', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    state_buttons: [
      {
        id: 'lock',
        label: 'Locked'
      }, {
        id: 'unlock',
        label: 'Unlocked'
      }, {
        id: 'complete',
        label: 'Completed'
      }
    ],
    init: function() {
      this._super();
      return this.validate = this.rad.validate;
    },
    actions: {
      select: function(state) {
        this.set('selected_state', state);
        this.rad.set_phase_state(state);
        if (this.validate) {
          return this.sendAction('validate');
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});