define('client/models/thinkspace/common/space', ['exports', 'ember', 'totem/ds/associations', 'totem/ajax'], function (exports, ember, ta, ajax) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].has_many('users'), ta['default'].has_many('users', {
    type: ta['default'].to_p('owner'),
    reads: {
      name: 'owners'
    }
  }), ta['default'].has_many('space_types', {
    reads: {}
  }), ta['default'].has_many('assignments', {
    reads: [
      {
        sort: 'title'
      }, {
        name: 'assignments_due_at_asc',
        sort: ['due_at:asc', 'title:asc']
      }
    ]
  }), ta['default'].has_many('team_sets', {
    reads: {}
  })), {
    title: ta['default'].attr('string'),
    immediate_assignment: ember['default'].computed.reads('assignments_due_at_asc.firstObject'),
    active_assignments: ember['default'].computed.filterBy('assignments_due_at_asc', 'active', true),
    inactive_assignments: ember['default'].computed.filterBy('assignments_due_at_asc', 'active', false),
    active_assignments_by_past_due: ember['default'].computed('active_assignments.@each.active', function() {
      var assignments, results;
      assignments = this.get('active_assignments');
      results = {
        past_due: [],
        not_past_due: []
      };
      assignments.forEach((function(_this) {
        return function(assignment) {
          if (assignment.get('is_past_due')) {
            return results.past_due.pushObject(assignment);
          } else {
            return results.not_past_due.pushObject(assignment);
          }
        };
      })(this));
      return results;
    }),
    valid_roles: ['read', 'update', 'owner'],
    get_team_sets: function(options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          query = {
            model: ta['default'].to_p('space'),
            verb: 'get',
            action: 'team_sets',
            id: _this.get('id')
          };
          return ajax['default'].object(query).then(function(payload) {
            var team_sets;
            team_sets = ajax['default'].normalize_and_push_payload('team_set', payload);
            team_sets = team_sets.filter(function(team_set) {
              if (!options.include_locked) {
                return team_set.get('unlocked_states').contains(team_set.get('state'));
              }
            });
            return resolve(team_sets);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return console.error("[space model] Error in get_team_sets.", error);
        };
      })(this));
    },
    unlocked_team_sets: ember['default'].computed('team_sets', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get('team_sets').then(function(team_sets) {
            var records;
            records = team_sets.filter(function(team_set) {
              return team_set.get('unlocked_states').contains(team_set.get('state'));
            });
            return resolve(records);
          });
        };
      })(this));
      return ta['default'].PromiseArray.create({
        promise: promise
      });
    }),
    locked_team_sets: ember['default'].computed('team_sets', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get('team_sets').then(function(team_sets) {
            var records;
            records = team_sets.filter(function(team_set) {
              return team_set.get('locked_states').contains(team_set.get('state'));
            });
            return resolve(records);
          });
        };
      })(this));
      return ta['default'].PromiseArray.create({
        promise: promise
      });
    }),
    add_ability: function(abilities) {
      var update;
      update = abilities.update || false;
      abilities.update = update;
      return abilities.destroy = update;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});