define('client/components/thinkspace/indented_list/list/layout/base', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tvo: ember['default'].inject.service(),
    c_list_test_only: ns['default'].to_p('indented_list', 'list', 'layout', 'shared', 'test_only'),
    c_list_header: ns['default'].to_p('indented_list', 'list', 'layout', 'shared', 'header'),
    c_list_all_visible: ns['default'].to_p('indented_list', 'list', 'layout', 'shared', 'all_visible'),
    c_list_new_top: ns['default'].to_p('indented_list', 'list', 'layout', 'shared', 'new_top'),
    c_list_new_bottom: ns['default'].to_p('indented_list', 'list', 'layout', 'shared', 'new_bottom'),
    c_list_source_observation: ns['default'].to_p('indented_list', 'list', 'source', 'observation'),
    c_list_source_mechanism: ns['default'].to_p('indented_list', 'list', 'source', 'mechanism'),
    c_response_item_show: ns['default'].to_p('indented_list', 'response', 'item', 'show'),
    init: function() {
      this._super();
      this.register_validation();
      return this.register_remove_itemable();
    },
    register_validation: function() {
      return this.get('tvo.status').register_validation('indented_list', this, 'validate_indented_list');
    },
    register_remove_itemable: function() {
      var section;
      section = this.get_indented_list_source_section();
      if (ember['default'].isBlank(section)) {
        return;
      }
      return this.get('tvo.helper').register(this, {
        section: 'remove_itemable',
        actions: {
          remove: 'remove_itemable_in_items'
        }
      });
    },
    remove_itemable_in_items: function(itemable) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get_response_manager().remove_items_with_itemable(itemable).then(function() {
            return resolve();
          });
        };
      })(this));
    },
    get_response_manager: function() {
      return this.get('response_manager');
    },
    get_list_container: function() {
      return this.$('.indented-list_list-container');
    },
    willInsertElement: function() {
      var $list_container, indent;
      $list_container = this.get_list_container();
      this.get_response_manager().register_list_container(this, $list_container);
      this.add_background_grid();
      indent = this.get_response_manager().indent_px + 400;
      return $list_container.css('min-height', indent + "px");
    },
    add_background_grid: function() {
      var $list_container, grid, px, rm, xcolor, ycolor;
      rm = this.get_response_manager();
      px = rm.get('indent_px');
      ycolor = 'lightgrey';
      xcolor = 'white';
      grid = "linear-gradient(to right, " + ycolor + " 1px, transparent 1px), linear-gradient(to bottom, " + xcolor + " 1px, transparent 1px)";
      $list_container = this.get_list_container();
      $list_container.css('background-size', px + "px " + px + "px");
      return $list_container.css('background-image', grid);
    },
    get_indented_list_attributes: function() {
      return this.get('tvo.hash.indented_list_attributes') || {};
    },
    get_indented_list_source_section: function() {
      return this.get_indented_list_attributes().source;
    },
    get_response_manager_items: function() {
      return this.get_response_manager().value_items;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});