define('client/components/thinkspace/readiness_assurance/admin/shared/menu', ['exports', 'ember', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    select: 'select',
    done: 'done',
    actions: {
      clear: function() {
        return this.sendAction('clear');
      },
      done: function(config) {
        return this.sendAction('done', config);
      },
      select: function(config) {
        return this.sendAction('select', config);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});