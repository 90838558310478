define('client/thinkspace/team/users/controller', ['exports', 'ember', 'totem/ns', 'thinkspace-team/controllers/base', 'client/thinkspace/team/users/team/view', 'client/thinkspace/team/users/team_user/view', 'client/thinkspace/team/users/user/view', 'client/thinkspace/team/users/user_team/view'], function (exports, ember, ns, base, team_view, team_user_view, user_view, user_team_view) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    users_team_view: team_view['default'],
    users_team_user_view: team_user_view['default'],
    users_user_view: user_view['default'],
    users_user_team_view: user_team_view['default'],
    filter_users: null,
    user_teams_visible: true,
    resource_users: ember['default'].computed(function() {
      return this.get('all_team_users');
    }),
    selected_team: null,
    filtered_teams: ember['default'].computed.reads('all_teams_filtered_by_category'),
    actions: {
      filter_by_collaboration_teams: function() {
        return this.set_team_filter_category(this.get('collaboration_category'));
      },
      filter_by_peer_review_teams: function() {
        return this.set_team_filter_category(this.get('peer_review_category'));
      },
      filter_teams_off: function() {
        return this.set_team_filter_category();
      },
      filter_by_unassigned_users: function() {
        return this.set_filter_users('unassigned');
      },
      filter_by_assigned_users: function() {
        return this.set_filter_users('assigned');
      },
      filter_users_off: function() {
        return this.set_filter_users();
      },
      toggle_user_teams_visible: function() {
        this.toggleProperty('user_teams_visible');
        return false;
      },
      select_team: function(team) {
        return this.set('selected_team', team);
      }
    },
    set_filter_users: function(filter_by) {
      if (filter_by == null) {
        filter_by = null;
      }
      return this.set('filter_users', filter_by);
    },
    set_team_filter_category: function(category) {
      this.set_filter_users();
      return this._super(category);
    },
    create_team_user: function(team, user) {
      var team_user;
      if (!team) {
        this.totem_error["throw"](this, "remove team user team is blank.");
      }
      if (!user) {
        this.totem_error["throw"](this, "remove team user user is blank.");
      }
      if (!ember['default'].isBlank(this.get_team_users(team, user))) {
        return;
      }
      team_user = this.store.createRecord(ns['default'].to_p('team_user'), {
        team_id: parseInt(team.get('id')),
        user_id: parseInt(user.get('id'))
      });
      team_user.set(common.to_p('user'), user);
      team_user.set(ns['default'].to_p('team'), team);
      return team_user.save().then((function(_this) {
        return function() {
          return _this.totem_messages.api_success({
            source: _this,
            model: team_user,
            action: 'create',
            i18n_path: ns['default'].to_o('team_user', 'save')
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.totem_messages.api_failure(error, {
            source: _this,
            model: team_user,
            action: 'create'
          });
        };
      })(this));
    },
    delete_team_user: function(team, user) {
      if (!team) {
        this.totem_error["throw"](this, "remove team user team is blank.");
      }
      if (!user) {
        this.totem_error["throw"](this, "remove team user user is blank.");
      }
      return this.get_team_users(team, user).forEach((function(_this) {
        return function(team_user) {
          team_user.deleteRecord();
          return team_user.save().then(function() {
            return _this.totem_messages.api_success({
              source: _this,
              model: team_user,
              action: 'delete',
              i18n_path: ns['default'].to_o('team_user', 'destroy')
            });
          }, function(error) {
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: team_user,
              action: 'delete'
            });
          });
        };
      })(this));
    },
    get_team_users: function(team, user) {
      var team_id, user_id;
      team_id = parseInt(team.get('id'));
      user_id = parseInt(user.get('id'));
      return this.store.all(ns['default'].to_p('team_user')).filter((function(_this) {
        return function(team_user) {
          return team_user.get('team_id') === team_id && team_user.get('user_id') === user_id;
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});