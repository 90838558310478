define('client/services/thinkspace/lab/admin', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'totem-messages/messages'], function (exports, ember, ns, ajax, totem_messages) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Service.extend({
    test_ui_only: false,
    test_ui_on: function() {
      return this.set('test_ui_only', true);
    },
    test_ui_off: function() {
      return this.set('test_ui_only', false);
    },
    c_chart_show: ns['default'].to_p('lab', 'builder', 'lab', 'chart', 'show'),
    c_category_select: ns['default'].to_p('lab', 'builder', 'lab', 'chart', 'select_category'),
    c_category_show: ns['default'].to_p('lab', 'builder', 'lab', 'category', 'show'),
    c_category_new: ns['default'].to_p('lab', 'builder', 'lab', 'category', 'new'),
    c_category_edit: ns['default'].to_p('lab', 'builder', 'lab', 'category', 'edit'),
    c_category_form: ns['default'].to_p('lab', 'builder', 'lab', 'category', 'form'),
    c_category_delete: ns['default'].to_p('lab', 'builder', 'lab', 'category', 'destroy'),
    c_result_show: ns['default'].to_p('lab', 'builder', 'lab', 'result', 'show'),
    c_result_edit: ns['default'].to_p('lab', 'builder', 'lab', 'result', 'edit'),
    c_result_new: ns['default'].to_p('lab', 'builder', 'lab', 'result', 'new'),
    c_result_form: ns['default'].to_p('lab', 'builder', 'lab', 'result', 'form'),
    c_result_delete: ns['default'].to_p('lab', 'builder', 'lab', 'result', 'destroy'),
    c_form_analysis: ns['default'].to_p('lab', 'builder', 'lab', 'result', 'form', 'analysis'),
    c_form_abnormality: ns['default'].to_p('lab', 'builder', 'lab', 'result', 'form', 'abnormality'),
    c_form_input: ns['default'].to_p('lab', 'builder', 'lab', 'result', 'form', 'input'),
    c_form_range: ns['default'].to_p('lab', 'builder', 'lab', 'result', 'form', 'range'),
    c_form_html: ns['default'].to_p('lab', 'builder', 'lab', 'result', 'form', 'html'),
    c_form_correctable: ns['default'].to_p('lab', 'builder', 'lab', 'result', 'form', 'correctable'),
    c_validated_input: ns['default'].to_p('common', 'shared', 'validated_input'),
    c_dropdown_split_button: ns['default'].to_p('common', 'dropdown_split_button'),
    load_chart: function() {
      var promise;
      return promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var chart, query;
          chart = _this.get_chart();
          query = {
            model: chart,
            id: chart.get('id'),
            verb: 'get',
            action: 'load'
          };
          return ajax['default'].object(query).then(function(payload) {
            chart.store.pushPayload(payload);
            return resolve();
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    },
    reorder_chart_categories: function(changes) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var chart, query;
          chart = _this.get_chart();
          query = {
            model: chart,
            id: chart.get('id'),
            action: 'category_positions',
            verb: 'post',
            data: {
              category_positions: changes
            }
          };
          if (_this.get('test_ui_only')) {
            return resolve();
          }
          totem_messages['default'].show_loading_outlet();
          return ajax['default'].object(query).then(function() {
            totem_messages['default'].hide_loading_outlet();
            return resolve();
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    },
    save_category: function(category, options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var values;
          values = options.values;
          if (ember['default'].isPresent(values)) {
            _this.clone_category_values(category, values, options);
          }
          return category.get(ns['default'].to_p('lab:chart')).then(function(chart) {
            if (ember['default'].isBlank(chart)) {
              category.set(ns['default'].to_p('lab:chart'), _this.get_chart());
            }
            if (_this.get('test_ui_only')) {
              return resolve();
            }
            totem_messages['default'].show_loading_outlet();
            return category.save().then(function(category) {
              totem_messages['default'].hide_loading_outlet();
              return resolve(category);
            }, function(error) {
              return totem_messages['default'].api_failure(error, {
                source: _this,
                model: category,
                action: 'save'
              });
            });
          });
        };
      })(this));
    },
    delete_category: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var category;
          category = _this.get_selected_category();
          if (ember['default'].isBlank(category)) {
            return resolve();
          }
          if (_this.get('test_ui_only')) {
            category.unloadRecord();
            return resolve();
          }
          category.deleteRecord();
          totem_messages['default'].show_loading_outlet();
          return category.save().then(function() {
            totem_messages['default'].hide_loading_outlet();
            return resolve(category);
          }, function(error) {
            return totem_messages['default'].api_failure(error, {
              source: _this,
              model: category,
              action: 'delete'
            });
          });
        };
      })(this));
    },
    reorder_category_results: function(changes) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var category, query;
          category = _this.get_selected_category();
          query = {
            model: category,
            id: category.get('id'),
            action: 'result_positions',
            verb: 'post',
            data: {
              result_positions: changes
            }
          };
          if (_this.get('test_ui_only')) {
            return resolve();
          }
          return ajax['default'].object(query).then(function() {
            return resolve();
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    },
    clone_category_values: function(category, values, options) {
      var options_props, props;
      if (options == null) {
        options = {};
      }
      props = {
        title: values.get('title'),
        position: values.get('position'),
        value: values.get('value')
      };
      options_props = options.properties;
      if (options_props) {
        props = ember['default'].merge(props, options_props);
      }
      category.setProperties(props);
      return category;
    },
    save_result: function(result, options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var values;
          values = options.values;
          if (ember['default'].isPresent(values)) {
            _this.clone_result_values(result, values, options);
          }
          return result.get(ns['default'].to_p('lab:category')).then(function(category) {
            if (ember['default'].isBlank(category)) {
              category = _this.get_selected_category();
              if (ember['default'].isBlank(category)) {
                return reject('Selected category is blank.');
              }
              result.set(ns['default'].to_p('lab:category'), category);
            }
            if (_this.get('test_ui_only')) {
              return resolve();
            }
            totem_messages['default'].show_loading_outlet();
            return result.save().then(function(result) {
              totem_messages['default'].hide_loading_outlet();
              return resolve(result);
            }, function(error) {
              return totem_messages['default'].api_failure(error, {
                source: _this,
                model: result,
                action: 'save'
              });
            });
          });
        };
      })(this));
    },
    delete_result: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var result;
          result = _this.get_action_overlay_model();
          if (ember['default'].isBlank(result)) {
            return resolve();
          }
          if (_this.get('test_ui_only')) {
            result.unloadRecord();
            return resolve();
          }
          result.deleteRecord();
          totem_messages['default'].show_loading_outlet();
          return result.save().then(function() {
            totem_messages['default'].hide_loading_outlet();
            return resolve(result);
          }, function(error) {
            return totem_messages['default'].api_failure(error, {
              source: _this,
              model: result,
              action: 'delete'
            });
          });
        };
      })(this));
    },
    clone_result_values: function(result, values, options) {
      var options_props, props;
      if (options == null) {
        options = {};
      }
      props = {
        title: values.get('title'),
        position: values.get('position'),
        metadata: values.get('metadata'),
        value: values.get('value')
      };
      options_props = options.properties;
      if (options_props) {
        props = ember['default'].merge(props, options_props);
      }
      result.setProperties(props);
      return result;
    },
    category_sortable_selector: '.ts-lab_admin-sortable-categories',
    result_sortable_selector: '.ts-lab_admin-sortable-results',
    get_category_sortable_selector: function() {
      return this.get('category_sortable_selector');
    },
    get_result_sortable_selector: function() {
      return this.get('result_sortable_selector');
    },
    on_drop_category_reorder: function(options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var chart;
          chart = _this.get_chart();
          return _this.get_chart_categories().then(function(categories) {
            var changes, component, notify_prop, selector;
            selector = _this.get_category_sortable_selector();
            changes = _this.find_and_set_reorder_changes(selector, categories, 'li');
            if (ember['default'].isBlank(changes)) {
              return resolve();
            }
            component = options.component;
            notify_prop = options.notify;
            if (component && notify_prop) {
              component.notifyPropertyChange(notify_prop);
            }
            return _this.reorder_chart_categories(changes).then(function() {
              return resolve();
            });
          });
        };
      })(this));
    },
    on_drop_result_reorder: function(options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get_category_results().then(function(results) {
            var changes, component, notify_prop, selector;
            selector = _this.get_result_sortable_selector() + ':visible';
            changes = _this.find_and_set_reorder_changes(selector, results, 'tr');
            if (ember['default'].isBlank(changes)) {
              return resolve();
            }
            component = options.component;
            notify_prop = options.notify;
            if (component && notify_prop) {
              component.notifyPropertyChange(notify_prop);
            }
            return _this.reorder_category_results(changes).then(function() {
              return resolve();
            });
          });
        };
      })(this));
    },
    find_and_set_reorder_changes: function(selector, records, tag) {
      var $items, changes;
      $items = $(selector).find(tag);
      changes = [];
      $items.each((function(_this) {
        return function(index, child) {
          var $child, id, position, rec_pos, record;
          rec_pos = index + 1;
          $child = $(child);
          id = $child.attr('model_id');
          record = records.findBy('id', id);
          if (record) {
            position = record.get('position');
            if (position !== rec_pos) {
              record.set('position', rec_pos);
              return changes.push({
                id: id,
                position: rec_pos
              });
            }
          }
        };
      })(this));
      return changes;
    },
    store: null,
    chart: null,
    admin_component: null,
    selected_category: null,
    selected_category_columns: null,
    selected_category_headings: null,
    get_store: function() {
      return this.get('store');
    },
    set_store: function(store) {
      return this.set('store', store);
    },
    get_chart: function() {
      return this.get('chart');
    },
    set_chart: function(chart) {
      return this.set('chart', chart);
    },
    get_admin_component: function() {
      return this.get('admin_component');
    },
    set_admin_component: function(component) {
      return this.set('admin_component', component);
    },
    reset_selected_category: function() {
      return this.set_selected_category(this.get_selected_category());
    },
    get_selected_category: function() {
      return this.get('selected_category');
    },
    set_selected_category: function(category) {
      var columns, headings;
      if (ember['default'].isBlank(category)) {
        return;
      }
      columns = this.category_columns(category);
      headings = columns.map(function(column) {
        return column.heading;
      });
      return this.setProperties({
        selected_category: category,
        selected_category_columns: columns,
        selected_category_headings: headings
      });
    },
    get_selected_category_columns: function() {
      return this.get('selected_category_columns') || [];
    },
    exit: function() {
      return this.get_admin_component().send('exit');
    },
    clear: function() {
      this.set_action_overlay_off();
      return this.setProperties({
        selected_category: null,
        selected_category_columns: null,
        selected_category_headings: null
      });
    },
    has_action_overlay: false,
    c_action_overlay: null,
    action_overlay_model: null,
    result_form_components: [],
    new_result_type: null,
    result_value_edit_component: null,
    result_value_edit_model: null,
    set_action_overlay_off: function() {
      this.set('has_action_overlay', false);
      return ember['default'].run.schedule('afterRender', (function(_this) {
        return function() {
          return _this.clear_overlay_values();
        };
      })(this));
    },
    set_action_overlay: function(component_name) {
      var name;
      name = this.get(component_name);
      this.set('c_action_overlay', name);
      return this.set('has_action_overlay', true);
    },
    get_action_overlay_model: function() {
      return this.get('action_overlay_model');
    },
    set_action_overlay_model: function(record) {
      return this.set('action_overlay_model', record);
    },
    is_result_value_edit_component: function(component) {
      var result;
      result = component.get('model');
      return component === this.get('result_value_edit_component') && this.guid_for(result) === this.get('result_value_edit_model');
    },
    set_result_value_edit_component: function(component) {
      var prev_component;
      prev_component = this.get('result_value_edit_component');
      if (ember['default'].isPresent(prev_component)) {
        prev_component.rollback();
      }
      return this.setProperties({
        result_value_edit_component: component,
        result_value_edit_model: this.guid_for(component.get('model'))
      });
    },
    get_result_form_components: function() {
      return this.get('result_form_components');
    },
    add_result_form_component: function(component) {
      return this.get_result_form_components().push(component);
    },
    guid_for: function(object) {
      return ember['default'].guidFor(object);
    },
    set_edit_result_has_errors: function(errors) {
      if (errors == null) {
        errors = true;
      }
      return this.set('edit_result_has_errors', errors);
    },
    clear_overlay_values: function() {
      return this.setProperties({
        new_result_type: null,
        result_value_edit_component: null,
        result_value_edit_model: null,
        result_form_components: [],
        action_overlay_model: null,
        c_action_overlay: null
      });
    },
    set_chart_selected_category: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get_chart_categories().then(function(categories) {
            var selected_category;
            selected_category = categories.sortBy('position').get('firstObject');
            if (selected_category) {
              _this.set_selected_category(selected_category);
            }
            return resolve();
          });
        };
      })(this));
    },
    get_chart_categories: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var chart;
          chart = _this.get_chart();
          if (ember['default'].isBlank(chart)) {
            return reject();
          }
          return chart.get(ns['default'].to_p('lab:categories')).then(function(categories) {
            return resolve(categories);
          }, function(error) {
            return reject();
          });
        };
      })(this));
    },
    get_category_results: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var category;
          category = _this.get_selected_category();
          if (ember['default'].isBlank(category)) {
            return reject();
          }
          return category.get(ns['default'].to_p('lab:results')).then(function(results) {
            return resolve(results);
          }, function(error) {
            return reject();
          });
        };
      })(this));
    },
    mock_new_category_record: ember['default'].Object.extend({
      columns: ember['default'].computed.reads('value.columns'),
      description_heading: ember['default'].computed(function() {
        return this.get('value.description') || 'Description';
      }),
      toString: function() {
        return 'mock_lab_category';
      }
    }),
    category_columns: function(category) {
      var cat_columns, columns, has_description;
      columns = [];
      cat_columns = category.get('columns') || [];
      has_description = false;
      cat_columns.map((function(_this) {
        return function(cat_column) {
          var column;
          column = ember['default'].merge({}, cat_column);
          if (column.source === 'description') {
            has_description = true;
          }
          return columns.push(column);
        };
      })(this));
      if (!has_description) {
        columns.push({
          heading: category.get('description_heading'),
          source: 'description'
        });
      }
      return columns;
    },
    get_mock_new_category_record: function() {
      return this.get('mock_new_category_record').create(this.category_new_defaults());
    },
    category_new_defaults: function() {
      return {
        title: '',
        position: 0,
        value: {
          component: 'vet_med',
          description_heading: this.i18n_category_heading('description'),
          correctable_prompt: this.t('builder.lab.category.correctable_prompt'),
          columns: [
            {
              heading: this.i18n_category_heading('result_name'),
              source: 'title'
            }, {
              heading: this.i18n_category_heading('result'),
              source: 'result'
            }, {
              heading: this.i18n_category_heading('units'),
              source: 'units'
            }, {
              heading: this.i18n_category_heading('range'),
              source: 'ratings',
              range: true
            }, {
              heading: this.i18n_category_heading('analysis'),
              observation: 'analysis'
            }, {
              heading: this.i18n_category_heading('abnormality'),
              observation: 'abnormality'
            }
          ]
        }
      };
    },
    category_clone: function(category) {
      var chart, clone;
      chart = this.get('chart');
      clone = chart.store.createRecord(ns['default'].to_p('lab:category'));
      this.set_clone_category_values(clone, category);
      clone.set(ns['default'].to_p('lab:chart'), chart);
      return chart.get(ns['default'].to_p('lab:categories')).then((function(_this) {
        return function(categories) {
          categories.pushObject(clone);
          _this.get('admin').set_selected_category(clone);
          return _this.send('clear_action_overlay');
        };
      })(this));
    },
    i18n_category_heading: function(key) {
      return this.t("builder.lab.category.heading." + key);
    },
    result_title_column: function() {
      return this.get_selected_category_columns().findBy('source', 'title');
    },
    result_type_columns: function(result, type) {
      var columns;
      columns = [];
      switch (type) {
        case 'result':
          this.add_result_columns(columns);
          break;
        case 'adjusted_result':
          this.add_adjusted_result_columns(columns);
          break;
        case 'html_result':
          this.add_html_result_columns(columns);
      }
      return this.result_columns(columns, result);
    },
    result_columns: function(columns, result) {
      if (!columns) {
        return [];
      }
      return columns.map((function(_this) {
        return function(col) {
          var column;
          column = ember['default'].merge({}, col);
          return _this.set_component_values(column, result);
        };
      })(this));
    },
    set_component_values: function(column, result) {
      if (column.observation) {
        column.fieldset = true;
        switch (column.observation) {
          case 'analysis':
            column.component = this.get('c_form_analysis');
            column.value_path = ['value.observations.analysis.selections', 'value.observations.analysis.normal', 'metadata.analysis.validate.correct'];
            break;
          case 'abnormality':
            column.component = this.get('c_form_abnormality');
            column.value_path = ['metadata.abnormality.validate.correct', 'metadata.abnormality.max_attempts'];
        }
      } else {
        if (ember['default'].isPresent(column.correctable)) {
          column.fieldset = true;
          column.value_path = ['metadata.analysis.validate.correct', 'metadata.analysis.max_attempts'];
        } else {
          column.fieldset = false;
        }
        switch (column.source) {
          case 'title':
            column.component = this.get('c_form_input');
            column.value_path = 'title';
            break;
          case 'description':
            column.component = this.get('c_form_input');
            column.value_path = 'value.description';
            break;
          case 'result':
            column.component = this.get('c_form_input');
            column.value_path = 'value.columns.result';
            if (result.get('admin_is_html')) {
              column.component = this.get('c_form_html');
            }
            break;
          case 'units':
            column.component = this.get('c_form_input');
            column.value_path = 'value.columns.units';
            break;
          case 'ratings':
            if (column.range) {
              column.component = this.get('c_form_range');
              column.value_path = ['value.columns.ratings.lower', 'value.columns.ratings.upper'];
              column.fieldset = true;
            } else {
              column.component = this.get('c_form_input');
              column.value_path = 'value.columns.ratings';
            }
        }
      }
      return column;
    },
    result_sources: ['description', 'result', 'units', 'ratings'],
    result_observations: ['analysis', 'abnormality'],
    add_html_result_columns: function(columns) {
      this.add_result_title_column(columns);
      return columns.push({
        heading: this.i18n_result_form_heading('html_result'),
        source: 'result'
      });
    },
    add_adjusted_result_columns: function(columns) {
      this.add_common_result_columns(columns, this.get('result_sources'), []);
      return columns.push({
        heading: this.i18n_result_form_heading('adjusted_result'),
        component: this.get('c_form_correctable'),
        correctable: true
      });
    },
    add_result_columns: function(columns) {
      return this.add_common_result_columns(columns, this.get('result_sources'), this.get('result_observations'));
    },
    add_result_title_column: function(columns) {
      var title_column;
      title_column = this.result_title_column();
      if (ember['default'].isPresent(title_column)) {
        return columns.push(title_column);
      }
    },
    add_common_result_columns: function(columns, sources, observations) {
      var common_columns;
      this.add_result_title_column(columns);
      common_columns = this.get_selected_category_columns().filter((function(_this) {
        return function(column) {
          return sources.contains(column.source) || observations.contains(column.observation);
        };
      })(this));
      return common_columns.forEach((function(_this) {
        return function(column) {
          var result_column;
          result_column = {
            heading: column.heading
          };
          if (column.observation) {
            result_column.observation = column.observation;
          } else {
            result_column.source = column.source;
            if (column.range) {
              result_column.range = column.range;
            }
          }
          return columns.push(result_column);
        };
      })(this));
    },
    i18n_result_form_heading: function(key) {
      return this.t("builder.lab.admin.form.result_heading." + key);
    },
    mock_new_result_record: ember['default'].Object.extend({
      admin_type: ember['default'].computed.reads('value.type'),
      toString: function() {
        return 'mock_lab_result';
      }
    }),
    get_mock_new_result_record: function(type) {
      var props;
      props = this.result_type_defaults()[type] || {};
      return this.get('mock_new_result_record').create(props);
    },
    result_type_defaults: function() {
      return {
        adjusted_result: {
          admin_is_adjusted: true,
          title: '',
          position: 0,
          metadata: {
            abnormality: {
              no_value: true
            },
            analysis: {
              lock_on_max_attempts: false,
              max_attempts: 3,
              validate: {
                correct: '',
                correct_method: 'standard_adjusted'
              }
            }
          },
          value: {
            type: 'adjusted_result',
            columns: {
              ratings: {
                lower: '',
                upper: ''
              },
              result: '',
              units: ''
            },
            description: '',
            observations: {
              abnormality: {
                input_type: 'none'
              },
              analysis: {
                input_type: 'correctable'
              }
            }
          }
        },
        html_result: {
          admin_is_html: true,
          title: '',
          position: 0,
          metadata: {},
          value: {
            type: 'html_result',
            columns: {
              result: ''
            },
            description: '',
            observations: {}
          }
        },
        result: {
          title: '',
          position: 0,
          metadata: {
            abnormality: {
              max_attempts: 3,
              validate: {
                correct: []
              }
            },
            analysis: {
              validate: {
                correct: 'normal'
              }
            }
          },
          value: {
            type: 'result',
            columns: {
              ratings: {
                lower: '',
                upper: ''
              },
              result: '',
              units: ''
            },
            description: '',
            observations: {
              abnormality: {
                input_type: 'input'
              },
              analysis: {
                input_type: 'select',
                normal: 'normal',
                selections: [
                  {
                    id: 'normal',
                    label: 'Normal'
                  }, {
                    id: 'high',
                    label: 'High'
                  }, {
                    id: 'low',
                    label: 'Low'
                  }
                ]
              }
            }
          }
        }
      };
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});