define('client/mixins/thinkspace/intercom/toolbar', ['exports', 'ember', 'totem/ns', 'totem/config'], function (exports, ember, ns, config) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    is_support_visible: false,
    support_button_pressed: false,
    intercom_show_obs: ember['default'].observer('is_support_visible', function() {
      if (this.get('isDestroying') || this.get('isDestroyed')) {
        return;
      }
      if ($crisp) {
        if ($crisp.is("chat:visible")) {
          $crisp.push(["do", "chat:hide"]);
          return $crisp.push(["do", "chat:close"]);
        } else {
          $crisp.push(["do", "chat:show"]);
          return $crisp.push(["do", "chat:open"]);
        }
      }
    }),
    intercom_chat_closed: function() {
      if ($crisp) {
        return $crisp.push(["do", "chat:hide"]);
      }
    },
    bind_intercom_events: function() {
      if ($crisp) {
        return $crisp.push(["on", "chat:closed", this.intercom_chat_closed]);
      }
    },
    init: function() {
      this._super();
      return this.bind_intercom_events();
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});