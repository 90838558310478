define('client/components/thinkspace/casespace/phase/header', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    phase_manager: ember['default'].inject.service(),
    r_phases_show: ns['default'].to_r('phases', 'show'),
    r_phases_edit: ns['default'].to_r('builder', 'phases', 'edit'),
    r_spaces_show: ns['default'].to_r('spaces', 'show'),
    r_assignments_show: ns['default'].to_r('assignments', 'show'),
    c_select_phase_state: ns['default'].to_p('phase', 'select_phase_state'),
    c_progress: ns['default'].to_p('phase', 'header', 'progress'),
    c_mock_progress: ns['default'].to_p('phase', 'header', 'mock_progress'),
    t_collaboration_teams: ns['default'].to_t('phase', 'header', 'collaboration_teams'),
    actions: {
      select_phase_state: function(phase_state) {
        var phase_manager;
        if (this.get('select_send_action')) {
          return this.sendAction('select_send_action', phase_state);
        } else {
          phase_manager = this.get('phase_manager');
          return this.get('phase_manager').set_ownerable_from_phase_state(phase_state).then((function(_this) {
            return function() {
              return phase_manager.generate_view_with_ownerable();
            };
          })(this));
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});