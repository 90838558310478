define('client/components/thinkspace/peer_assessment/assessment/team/member', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: 'li',
    classNameBindings: ['is_selected:is-selected'],
    manager: null,
    reviewable: null,
    review: null,
    is_selected: ember['default'].computed('model', 'reviewable', function() {
      return ember['default'].isEqual(this.get('model'), this.get('reviewable'));
    }),
    is_balance: ember['default'].computed.reads('manager.is_balance'),
    points_expended: ember['default'].computed('review', 'manager.points_remaining', function() {
      var review;
      if (!this.get('is_balance')) {
        return;
      }
      review = this.get('review');
      return review.get_expended_points();
    }),
    c_user_avatar: ns['default'].to_p('user', 'avatar'),
    init: function() {
      var model, review;
      this._super();
      model = this.get('model');
      review = this.get('manager').get_review_for_reviewable(model);
      return this.set('review', review);
    },
    click: function() {
      var manager;
      manager = this.get('manager');
      return manager.set_reviewable(this.get('model'));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});