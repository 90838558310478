define('client/components/thinkspace/weather_forecaster/assessment', ['exports', 'ember', 'ember-data', 'totem/ns', 'totem/ajax', 'thinkspace-base/components/base'], function (exports, ember, ds, ns, ajax, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    c_assessment_show: ns['default'].to_p('wf:assessment', 'show'),
    c_attempts: ns['default'].to_p('wf:assessment', 'attempts'),
    c_top_forecasts: ns['default'].to_p('wf:assessment', 'top_forecasts'),
    c_help_tip: ns['default'].to_p('wf:assessment', 'help_tip'),
    show_help: false,
    show_attempts: true,
    show_top_forecasts: false,
    current_forecast: null,
    selected_forecast: null,
    view_forecast: ember['default'].computed('current_forecast', 'selected_forecast', function() {
      return this.get('selected_forecast') || this.get('current_forecast');
    }),
    help_tip: null,
    actions: {
      show_help: function(help) {
        this.set('help_tip', help);
        return this.set('show_help', true);
      },
      hide_help: function() {
        return this.set('show_help', false);
      },
      select_forecast: function(forecast) {
        if (forecast === this.get('current_forecast')) {
          return this.set('selected_forecast', null);
        } else {
          return this.set('selected_forecast', forecast);
        }
      },
      select_attempts: function() {
        this.set('show_top_forecasts', false);
        return this.set('show_attempts', true);
      },
      select_top_forecasts: function() {
        this.set('show_attempts', false);
        return this.set('show_top_forecasts', true);
      }
    },
    didInsertElement: function() {
      this.totem_messages.show_loading_outlet();
      return this.get_assessment_current_forecast().then((function(_this) {
        return function(forecast) {
          return _this.get_assessment_forecast_attempts().then(function() {
            _this.set('current_forecast', forecast);
            return _this.totem_messages.hide_loading_outlet();
          });
        };
      })(this), (function(_this) {
        return function(error) {
          var assessment;
          assessment = _this.get('model');
          return _this.totem_messages.api_failure(error, {
            source: _this,
            model: assessment
          });
        };
      })(this));
    },
    get_assessment_current_forecast: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var assessment, query;
          assessment = _this.get('model');
          query = _this.totem_scope.get_view_query(assessment, {
            action: 'current_forecast'
          });
          query.model = assessment;
          return ajax['default'].object(query).then(function(payload) {
            var forecast, type;
            type = ns['default'].to_p('wf:forecast');
            forecast = payload[type];
            assessment.store.pushPayload(payload);
            return assessment.store.find(type, forecast.id).then(function(forecast) {
              return resolve(forecast);
            });
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return reject(error);
        };
      })(this));
    },
    get_assessment_forecast_attempts: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var assessment, query;
          assessment = _this.get('model');
          query = _this.totem_scope.get_view_query(assessment, {
            sub_action: 'forecast_attempts'
          });
          return assessment.store.find(ns['default'].to_p('wf:assessment'), query).then(function() {
            return resolve();
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return reject(error);
        };
      })(this));
    },
    top_forecasts: ember['default'].computed('current_forecast', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var assessment, query;
          assessment = _this.get('model');
          query = _this.totem_scope.get_view_query(assessment);
          query.auth.sub_action = 'top_forecasts';
          query.data = {};
          query.data.auth = query.auth;
          query.model = assessment;
          delete query.auth;
          return ajax['default'].object(query).then(function(payload) {
            var i, len, user, users;
            users = payload.top_forecasts || [];
            for (i = 0, len = users.length; i < len; i++) {
              user = users[i];
              user.score = parseInt(user.score);
            }
            resolve(users);
            return _this.set('have_top_forecasts', true);
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
      return ds['default'].PromiseArray.create({
        promise: promise
      });
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});