define('client/_config/thinkspace/readiness_assurance/locales/en', ['exports'], function (exports) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = {
    readiness_assurance: {
      assessment: 'Assessment',
      response: 'Response'
    }
  };

  exports['default'] = ___DefaultExportObject___;;

});