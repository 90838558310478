define('client/components/thinkspace/common/invitation/new', ['exports', 'ember', 'totem/ns', 'totem/config', 'totem/mixins/validations', 'totem/ajax', 'thinkspace-base/components/base'], function (exports, ember, ns, config, val_mixin, ajax, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend(val_mixin['default'], {
    tagName: '',
    friendly_role: ember['default'].computed('friendly_roles', function() {
      return this.get('friendly_roles.firstObject');
    }),
    friendly_roles: ember['default'].computed(function() {
      var friendly, role, roles, roles_map;
      roles_map = config['default'].roles_map;
      if (!ember['default'].isPresent(roles_map)) {
        console.error("Could not find roles map in config, cannot process space_user.");
      }
      roles = [];
      for (role in roles_map) {
        friendly = roles_map[role];
        roles.pushObject(friendly);
      }
      return roles;
    }),
    c_validated_input: ns['default'].to_p('common', 'shared', 'validated_input'),
    is_sending: false,
    show_errors: false,
    email: '',
    email_input_class: 'ts-invitation-new_input-email',
    email_input_selector: ember['default'].computed(function() {
      return $("." + (this.get('email_input_class'))).children('input');
    }),
    reveal_errors: ember['default'].observer('email', function() {
      if (!this.get('show_errors')) {
        return this.set('show_errors', true);
      }
    }),
    focus_email_input: function() {
      return this.get('email_input_selector').focus();
    },
    select_email_input: function() {
      return this.get('email_input_selector').select();
    },
    get_role: function(friendly_role) {
      var friendly, name, role, roles_map;
      roles_map = config['default'].roles_map;
      if (!ember['default'].isPresent(roles_map)) {
        console.error("Could not find roles map in config, cannot process space_user.");
      }
      name = null;
      for (role in roles_map) {
        friendly = roles_map[role];
        if (ember['default'].isEqual(friendly.toLowerCase(), friendly_role.toLowerCase())) {
          name = role;
        }
      }
      if (!ember['default'].isPresent(name)) {
        console.error("Could not find role for [" + friendly_role + "] in config.roles_map [" + roles_map + "].");
      }
      return name;
    },
    didInsertElement: function() {
      return ember['default'].run.schedule('afterRender', (function(_this) {
        return function() {
          return _this.focus_email_input();
        };
      })(this));
    },
    actions: {
      send: function() {
        var email, invitable, options, role;
        if (!ember['default'].isEmpty(this.get('errors.email'))) {
          this.select_email_input();
          return;
        }
        invitable = this.get('invitable');
        email = this.get('email');
        role = this.get_role(this.get('friendly_role'));
        options = {
          verb: 'PUT',
          action: 'invite',
          model: invitable,
          id: invitable.get('id'),
          data: {
            email: email,
            role: role
          }
        };
        this.set('is_sending', true);
        return ajax['default'].object(options).then((function(_this) {
          return function(payload) {
            var space_user, user;
            user = ajax['default'].normalize_and_push_payload('user', payload).get('firstObject');
            space_user = ajax['default'].normalize_and_push_payload('space_user', payload).get('firstObject');
            space_user.set('user', user);
            _this.totem_messages.api_success({
              source: _this,
              model: user,
              action: 'create',
              i18n_path: ns['default'].to_o('invitation', 'save')
            });
            _this.set('email', '');
            _this.set('show_errors', false);
            _this.set('is_sending', false);
            return ember['default'].run.schedule('afterRender', function() {
              return _this.focus_email_input();
            });
          };
        })(this), (function(_this) {
          return function(error) {
            _this.set('is_sending', false);
            _this.totem_messages.api_falure({
              source: _this,
              model: user,
              action: 'create'
            });
            return ember['default'].run.schedule('afterRender', function() {
              return _this.select_email_input();
            });
          };
        })(this));
      },
      cancel: function() {
        return this.sendAction('cancel');
      }
    },
    validations: {
      email: {
        format: {
          "with": /\S+@\S+/,
          message: "Must be a valid email"
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});