define('client/components/thinkspace/common/user/profile_space', ['exports', 'ember', 'thinkspace-casespace/components/casespace'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    is_user_profile: true
  });

  exports['default'] = ___DefaultExportObject___;;

});