define('client/thinkspace/common/invitations/show/route', ['exports', 'ember', 'simple-auth/configuration', 'thinkspace-base/base/route'], function (exports, ember, auth_config, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    model: function(params) {
      return params;
    },
    actions: {
      sign_in_transition: function() {
        var sign_in_url;
        sign_in_url = auth_config['default'].authenticationRoute;
        if (sign_in_url) {
          return this.transitionTo(sign_in_url);
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});