define('client/thinkspace/common/spaces/view', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].View.extend({
    templateName: ns['default'].to_p('spaces')
  });

  exports['default'] = ___DefaultExportObject___;;

});