define('client/services/casespace', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Object.extend({
    thinkspace: ember['default'].inject.service(),
    phase_settings: null,
    reset_all: function() {
      this.reset_models();
      return this.reset_active_addon();
    },
    reset_models: function() {
      this.set_current_space(null);
      this.set_current_assignment(null);
      return this.set_current_phase(null);
    },
    reset_active_addon: function() {
      this.set_active_addon_ownerable(null);
      return this.set_active_addon(null);
    },
    get_phase_settings: function() {
      return this.get('phase_settings');
    },
    set_phase_settings: function(phase_settings) {
      return this.set('phase_settings', phase_settings);
    },
    get_phases_show_controller: function() {
      return this.container.lookup('controller:thinkspace/casespace/phases/show');
    },
    set_phase_settings_query_params: function(phase_settings) {
      var controller;
      controller = this.get_phases_show_controller();
      return controller.set_phase_settings(phase_settings);
    },
    current_space: null,
    current_assignment: null,
    current_phase: null,
    current_model: ember['default'].computed('current_space', 'current_assignment', 'current_phase', function() {
      return this.get('current_phase') || this.get('current_assignment') || this.get('current_space') || null;
    }),
    get_current_space: function() {
      return this.get('current_space');
    },
    get_current_assignment: function() {
      return this.get('current_assignment');
    },
    get_current_phase: function() {
      return this.get('current_phase');
    },
    set_current_space: function(space) {
      return this.set('current_space', space);
    },
    set_current_assignment: function(assignment) {
      return this.set('current_assignment', assignment);
    },
    set_current_phase: function(phase) {
      return this.set('current_phase', phase);
    },
    set_current_models: function(current_models) {
      if (current_models == null) {
        current_models = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var assignment, phase, space;
          switch (false) {
            case !(phase = current_models.phase):
              return phase.get(ns['default'].to_p('assignment')).then(function(assignment) {
                return assignment.get(ns['default'].to_p('space')).then(function(space) {
                  if (_this.get_current_space() !== space) {
                    _this.set_current_space(space);
                  }
                  if (_this.get_current_assignment() !== assignment) {
                    _this.set_current_assignment(assignment);
                  }
                  _this.set_current_phase(phase);
                  return resolve();
                }, function(error) {
                  return reject(error);
                });
              }, function(error) {
                return reject(error);
              });
            case !(assignment = current_models.assignment):
              return assignment.get(ns['default'].to_p('space')).then(function(space) {
                _this.set_current_phase(null);
                if (_this.get_current_space() !== space) {
                  _this.set_current_space(space);
                }
                _this.set_current_assignment(assignment);
                return resolve();
              }, function(error) {
                return reject(error);
              });
            case !(space = current_models.space):
              _this.set_current_assignment(null);
              _this.set_current_phase(null);
              if (_this.get_current_space() !== space) {
                _this.set_current_space(space);
              }
              return resolve();
            default:
              _this.reset_all();
              return resolve();
          }
        };
      })(this));
    },
    active_addon: null,
    active_addon_ownerable: null,
    has_sticky_addon: ember['default'].computed('active_addon', function() {
      var addon, name;
      addon = this.get_active_addon();
      if (!addon) {
        return false;
      }
      name = addon.get('addon_name');
      return name === 'gradebook' || name === 'peer_review';
    }),
    has_active_addon: function() {
      return this.get('active_addon') != null;
    },
    get_active_addon: function() {
      return this.get('active_addon');
    },
    set_active_addon: function(addon) {
      var active_addon;
      if (addon == null) {
        addon = null;
      }
      active_addon = this.get('active_addon');
      this.debug_active_addon(addon, active_addon);
      if (active_addon && typeof active_addon.exit_addon === 'function') {
        active_addon.exit_addon();
      }
      return this.set('active_addon', addon);
    },
    get_active_addon_ownerable: function() {
      return this.get('active_addon_ownerable');
    },
    set_active_addon_ownerable: function(ownerable) {
      return this.set('active_addon_ownerable', ownerable);
    },
    debug_active_addon: function(addon, active_addon) {
      var ownerable;
      if (active_addon) {
        console.info('exit...active addon:', active_addon.toString());
      }
      if (addon) {
        console.info('set....active addon:', addon.toString());
      }
      if (!addon) {
        console.info('clear..active addon');
      }
      ownerable = this.get_active_addon_ownerable();
      if (ownerable) {
        return console.info('current active addon ownerable:', ownerable.toString());
      }
    },
    dock_is_visible: ember['default'].computed.or('current_assignment', 'current_phase'),
    c_sidepocket_component: false,
    sidepocket_is_expanded: false,
    sidepocket_width: 1,
    sidepocket_width_class: ember['default'].computed('sidepocket_is_expanded', 'sidepocket_width', function() {
      if (!this.get('sidepocket_is_expanded')) {
        return null;
      }
      return "sidepocket_width-" + (this.get('sidepocket_width'));
    }),
    hide_sidepocket: function() {
      this.set('sidepocket_is_expanded', false);
      return this.reset_sidepocket_width();
    },
    show_sidepocket: function() {
      return this.set('sidepocket_is_expanded', true);
    },
    toggle_sidepocket: function() {
      return this.toggleProperty('sidepocket_is_expanded');
    },
    set_sidepocket_width: function(width) {
      return this.set('sidepocket_width', width);
    },
    reset_sidepocket_width: function() {
      return this.set('sidepocket_width', 1);
    },
    set_active_sidepocket_component: function(path) {
      this.set('c_sidepocket_component', path);
      return this.show_sidepocket();
    },
    reset_active_sidepocket_component: function() {
      this.hide_sidepocket();
      return ember['default'].run.next((function(_this) {
        return function() {
          return _this.set('c_sidepocket_component', null);
        };
      })(this));
    },
    transition_to_current_assignment: function() {
      var assignment, router, thinkspace, transition;
      assignment = this.get('current_assignment');
      if (!ember['default'].isPresent(assignment)) {
        return;
      }
      thinkspace = this.get('thinkspace');
      transition = thinkspace.get_current_transition();
      if (!ember['default'].isPresent(transition)) {
        return;
      }
      router = transition.router;
      return router.transitionTo(ns['default'].to_r('assignments', 'show'), assignment);
    },
    transition_to_phase: function(phase) {
      if (!ember['default'].isPresent(phase)) {
        return;
      }
      return this.set_current_models({
        phase: phase
      }).then((function(_this) {
        return function() {
          var assignment, router, thinkspace, transition;
          assignment = _this.get_current_assignment();
          if (!ember['default'].isPresent(assignment)) {
            return;
          }
          thinkspace = _this.get('thinkspace');
          transition = thinkspace.get_current_transition();
          console.warn('transition:', transition);
          if (!ember['default'].isPresent(transition)) {
            return;
          }
          router = transition.router;
          return router.transitionTo(ns['default'].to_r('phases', 'show'), assignment, phase);
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});