define('client/_config/thinkspace/intercom/ns', ['exports'], function (exports) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = {
    namespaces: {
      intercom: 'thinkspace/intercom'
    }
  };

  exports['default'] = ___DefaultExportObject___;;

});