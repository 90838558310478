define('client/templates/components/thinkspace/casespace/case_manager/assignment/wizards/casespace/steps/phases/_form', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","ts-forms_field-subcontainer");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","ts-forms_field-container");
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","ts-forms_field-label-wrapper");
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","ts-forms_field-label");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("p");
          dom.setAttribute(el4,"class","ts-forms_field-description");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, inline = hooks.inline, get = hooks.get;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element5 = dom.childAt(fragment, [0, 0]);
          var element6 = dom.childAt(element5, [0]);
          var morph0 = dom.createMorphAt(dom.childAt(element6, [0]),0,0);
          var morph1 = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
          var morph2 = dom.createMorphAt(element5,1,1);
          inline(env, morph0, context, "t", ["builder.casespace.phases.edit.instruct.submit_text"], {});
          inline(env, morph1, context, "t", ["builder.casespace.phases.edit.describe.submit_text"], {});
          inline(env, morph2, context, "component", [get(env, context, "c_validated_input")], {"value": get(env, context, "submit_text"), "errors": get(env, context, "errors.submit_text")});
          return fragment;
        }
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","ts-forms_field-container");
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","ts-forms_field-label-wrapper");
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","ts-forms_field-label");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("p");
            dom.setAttribute(el3,"class","ts-forms_field-description");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, inline = hooks.inline, get = hooks.get;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element2 = dom.childAt(fragment, [0]);
            var element3 = dom.childAt(element2, [0]);
            var morph0 = dom.createMorphAt(dom.childAt(element3, [0]),0,0);
            var morph1 = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
            var morph2 = dom.createMorphAt(element2,1,1);
            inline(env, morph0, context, "t", ["builder.casespace.phases.edit.instruct.team_category"], {});
            inline(env, morph1, context, "t", ["builder.casespace.phases.edit.describe.team_category"], {});
            inline(env, morph2, context, "view", ["select"], {"selection": get(env, context, "team_category_selected"), "value": get(env, context, "team_category_id"), "content": get(env, context, "team_categories"), "optionValuePath": "content.id", "optionLabelPath": "content.title", "class": "btn btn-small", "prompt": "Not team based"});
            return fragment;
          }
        };
      }());
      var child1 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","ts-forms_field-container");
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","ts-forms_field-label");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, inline = hooks.inline, get = hooks.get;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element1 = dom.childAt(fragment, [0]);
            var morph0 = dom.createMorphAt(dom.childAt(element1, [0]),0,0);
            var morph1 = dom.createMorphAt(element1,1,1);
            inline(env, morph0, context, "t", ["builder.casespace.phases.edit.instruct.team_set"], {});
            inline(env, morph1, context, "view", ["select"], {"selection": get(env, context, "team_set_selected"), "value": get(env, context, "team_set_id"), "content": get(env, context, "team_sets"), "optionValuePath": "content.id", "optionLabelPath": "content.title", "class": "btn btn-small", "prompt": "No team set."});
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","ts-forms_field-subcontainer");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element4 = dom.childAt(fragment, [0]);
          var morph0 = dom.createMorphAt(element4,0,0);
          var morph1 = dom.createMorphAt(element4,1,1);
          block(env, morph0, context, "if", [get(env, context, "has_team_categories")], {}, child0, null);
          block(env, morph1, context, "if", [get(env, context, "has_team_sets")], {}, child1, null);
          return fragment;
        }
      };
    }());
    var child2 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, null);
          dom.insertBoundary(fragment, 0);
          inline(env, morph0, context, "component", [get(env, context, "c_loader")], {"i18n_path": "builder.casespace.loaders.componentables"});
          return fragment;
        }
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("h6");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
            inline(env, morph0, context, "t", ["builder.casespace.phases.edit.headings.no_componentables"], {});
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, null);
          dom.insertBoundary(fragment, 0);
          block(env, morph0, context, "unless", [get(env, context, "has_componentables")], {}, child0, null);
          return fragment;
        }
      };
    }());
    var child4 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","ts-wizard_row");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","ts-grid_columns small-12");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(dom.childAt(fragment, [0, 0]),0,0);
          inline(env, morph0, context, "component", [get(env, context, "c_componentable")], {"model": get(env, context, "componentable"), "select": "select_componentable", "current_componentable": get(env, context, "current_componentable"), "show_link": get(env, context, "show_phase")});
          return fragment;
        }
      };
    }());
    var child5 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","btn btn-primary btn-small left");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, element = hooks.element, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element0 = dom.childAt(fragment, [0]);
          var morph0 = dom.createMorphAt(element0,0,0);
          element(env, element0, context, "action", ["save"], {"on": "click"});
          inline(env, morph0, context, "t", ["builder.casespace.phases.edit.buttons.save"], {});
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ts-wizard");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","ts-wizard_content");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-forms_heading");
        var el4 = dom.createElement("h1");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-wizard_row");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-grid_columns small-12 ts-forms_form-container");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-forms_section-container");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-forms_section-heading");
        var el7 = dom.createElement("h5");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-forms_field-container");
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","ts-forms_field-label");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-forms_field-container");
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","ts-forms_field-label");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("hr");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-forms_section-container");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-forms_section-heading");
        var el7 = dom.createElement("h5");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-forms_field-container");
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","ts-checkbox");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("p");
        dom.setAttribute(el8,"class","ts-forms_field-description");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-forms_field-container");
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","ts-checkbox");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("p");
        dom.setAttribute(el8,"class","ts-forms_field-description");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("hr");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-forms_section-container");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-forms_section-heading");
        var el7 = dom.createElement("h5");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-forms_field-container");
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","ts-checkbox");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("p");
        dom.setAttribute(el8,"class","ts-forms_field-description");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-forms_field-container");
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","ts-checkbox");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("p");
        dom.setAttribute(el8,"class","ts-forms_field-description");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-forms_field-container");
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","ts-checkbox");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("p");
        dom.setAttribute(el8,"class","ts-forms_field-description");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("hr");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-forms_section-container");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-forms_section-heading");
        var el7 = dom.createElement("h5");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-forms_field-container");
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","ts-checkbox");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("hr");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-forms_section-container");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-forms_section-heading");
        var el7 = dom.createElement("h5");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-wizard_row ts-forms_action-btns-container");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-grid_columns small-12");
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","btn btn-default btn-small right");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, inline = hooks.inline, element = hooks.element, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element7 = dom.childAt(fragment, [0, 0]);
        var element8 = dom.childAt(element7, [0]);
        var element9 = dom.childAt(element7, [1, 0]);
        var element10 = dom.childAt(element9, [0]);
        var element11 = dom.childAt(element10, [0]);
        var element12 = dom.childAt(element10, [1]);
        var element13 = dom.childAt(element10, [2]);
        var element14 = dom.childAt(element9, [2]);
        var element15 = dom.childAt(element14, [0]);
        var element16 = dom.childAt(element14, [1]);
        var element17 = dom.childAt(element16, [0]);
        var element18 = dom.childAt(element14, [2, 0]);
        var element19 = dom.childAt(element9, [4]);
        var element20 = dom.childAt(element19, [0]);
        var element21 = dom.childAt(element19, [1, 0]);
        var element22 = dom.childAt(element19, [2, 0]);
        var element23 = dom.childAt(element19, [3, 0]);
        var element24 = dom.childAt(element9, [6]);
        var element25 = dom.childAt(element24, [0]);
        var element26 = dom.childAt(element24, [1]);
        var element27 = dom.childAt(element26, [0]);
        var element28 = dom.childAt(element9, [8]);
        var element29 = dom.childAt(element9, [10]);
        var element30 = dom.childAt(element29, [0]);
        var morph0 = dom.createMorphAt(dom.childAt(element8, [0]),0,0);
        var morph1 = dom.createMorphAt(dom.childAt(element8, [1]),0,0);
        var morph2 = dom.createMorphAt(dom.childAt(element11, [0]),0,0);
        var morph3 = dom.createMorphAt(dom.childAt(element11, [1]),0,0);
        var morph4 = dom.createMorphAt(dom.childAt(element12, [0]),0,0);
        var morph5 = dom.createMorphAt(element12,1,1);
        var morph6 = dom.createMorphAt(dom.childAt(element13, [0]),0,0);
        var morph7 = dom.createMorphAt(element13,1,1);
        var morph8 = dom.createMorphAt(dom.childAt(element15, [0]),0,0);
        var morph9 = dom.createMorphAt(dom.childAt(element15, [1]),0,0);
        var morph10 = dom.createMorphAt(element17,0,0);
        var morph11 = dom.createMorphAt(element17,1,1);
        var morph12 = dom.createMorphAt(dom.childAt(element17, [2]),0,0);
        var morph13 = dom.createMorphAt(element16,1,1);
        var morph14 = dom.createMorphAt(element18,0,0);
        var morph15 = dom.createMorphAt(element18,1,1);
        var morph16 = dom.createMorphAt(dom.childAt(element18, [2]),0,0);
        var morph17 = dom.createMorphAt(dom.childAt(element20, [0]),0,0);
        var morph18 = dom.createMorphAt(dom.childAt(element20, [1]),0,0);
        var morph19 = dom.createMorphAt(element21,0,0);
        var morph20 = dom.createMorphAt(element21,1,1);
        var morph21 = dom.createMorphAt(dom.childAt(element21, [3]),0,0);
        var morph22 = dom.createMorphAt(element22,0,0);
        var morph23 = dom.createMorphAt(element22,1,1);
        var morph24 = dom.createMorphAt(dom.childAt(element22, [2]),0,0);
        var morph25 = dom.createMorphAt(element23,0,0);
        var morph26 = dom.createMorphAt(element23,1,1);
        var morph27 = dom.createMorphAt(dom.childAt(element23, [2]),0,0);
        var morph28 = dom.createMorphAt(dom.childAt(element25, [0]),0,0);
        var morph29 = dom.createMorphAt(dom.childAt(element25, [1]),0,0);
        var morph30 = dom.createMorphAt(element27,0,0);
        var morph31 = dom.createMorphAt(element27,1,1);
        var morph32 = dom.createMorphAt(element26,1,1);
        var morph33 = dom.createMorphAt(dom.childAt(element28, [0, 0]),0,0);
        var morph34 = dom.createMorphAt(element28,1,1);
        var morph35 = dom.createMorphAt(element28,2,2);
        var morph36 = dom.createMorphAt(element28,3,3);
        var morph37 = dom.createMorphAt(element30,0,0);
        var morph38 = dom.createMorphAt(element29,1,1);
        inline(env, morph0, context, "t", ["builder.casespace.phases.edit.headings.main_title", get(env, context, "model.title")], {});
        inline(env, morph1, context, "t", ["builder.casespace.phases.edit.describe.edit"], {});
        inline(env, morph2, context, "t", ["builder.casespace.phases.edit.headings.details"], {});
        inline(env, morph3, context, "t", ["builder.casespace.phases.edit.describe.details"], {});
        inline(env, morph4, context, "t", ["builder.casespace.phases.edit.headings.title"], {});
        inline(env, morph5, context, "component", [get(env, context, "c_validated_input")], {"value": get(env, context, "title"), "errors": get(env, context, "errors.title")});
        inline(env, morph6, context, "t", ["builder.casespace.phases.edit.headings.max_score"], {});
        inline(env, morph7, context, "component", [get(env, context, "c_validated_input")], {"value": get(env, context, "max_score"), "errors": get(env, context, "errors.max_score"), "class": "case_manager_phase-max-score"});
        inline(env, morph8, context, "t", ["builder.casespace.phases.edit.headings.submission"], {});
        inline(env, morph9, context, "t", ["builder.casespace.phases.edit.describe.submission"], {});
        element(env, element17, context, "action", ["toggle_submit_visible"], {"on": "click"});
        inline(env, morph10, context, "component", [get(env, context, "c_checkbox")], {"checked": get(env, context, "submit_visible"), "class": "case_manager_phase-submit-button-checkbox", "disable_click": true});
        inline(env, morph11, context, "t", ["builder.casespace.phases.edit.instruct.submit_visible"], {});
        inline(env, morph12, context, "t", ["builder.casespace.phases.edit.describe.submit_visible"], {});
        block(env, morph13, context, "if", [get(env, context, "submit_visible")], {}, child0, null);
        element(env, element18, context, "action", ["toggle_configuration_validate"], {"on": "click"});
        inline(env, morph14, context, "component", [get(env, context, "c_checkbox")], {"checked": get(env, context, "configuration_validate"), "class": "case_manager_phase-validate-checkbox", "disable_click": true});
        inline(env, morph15, context, "t", ["builder.casespace.phases.edit.instruct.configuration_validate"], {});
        inline(env, morph16, context, "t", ["builder.casespace.phases.edit.describe.configuration_validate"], {});
        inline(env, morph17, context, "t", ["builder.casespace.phases.edit.headings.submit_events"], {});
        inline(env, morph18, context, "t", ["builder.casespace.phases.edit.describe.submit_events"], {});
        element(env, element21, context, "action", ["toggle_complete_phase"], {"on": "click"});
        inline(env, morph19, context, "component", [get(env, context, "c_checkbox")], {"checked": get(env, context, "complete_phase"), "class": "case_manager_phase-validate-checkbox", "disable_click": true});
        inline(env, morph20, context, "t", ["builder.casespace.phases.edit.instruct.complete_phase"], {});
        inline(env, morph21, context, "t", ["builder.casespace.phases.edit.describe.complete_phase"], {});
        element(env, element22, context, "action", ["toggle_unlock_phase"], {"on": "click"});
        inline(env, morph22, context, "component", [get(env, context, "c_checkbox")], {"checked": get(env, context, "unlock_phase"), "class": "case_manager_phase-validate-checkbox", "disable_click": true});
        inline(env, morph23, context, "t", ["builder.casespace.phases.edit.instruct.unlock_phase"], {});
        inline(env, morph24, context, "t", ["builder.casespace.phases.edit.describe.unlock_phase"], {});
        element(env, element23, context, "action", ["toggle_auto_score"], {"on": "click"});
        inline(env, morph25, context, "component", [get(env, context, "c_checkbox")], {"checked": get(env, context, "auto_score"), "class": "case_manager_phase-validate-checkbox", "disable_click": true});
        inline(env, morph26, context, "t", ["builder.casespace.phases.edit.instruct.auto_score"], {});
        inline(env, morph27, context, "t", ["builder.casespace.phases.edit.describe.auto_score"], {});
        inline(env, morph28, context, "t", ["builder.casespace.phases.edit.headings.team_based"], {});
        inline(env, morph29, context, "t", ["builder.casespace.phases.edit.describe.team_based"], {});
        element(env, element27, context, "action", ["toggle_team_based"], {"on": "click"});
        inline(env, morph30, context, "component", [get(env, context, "c_checkbox")], {"checked": get(env, context, "team_based"), "class": "case_manager_phase-validate-checkbox", "disable_click": true});
        inline(env, morph31, context, "t", ["builder.casespace.phases.edit.instruct.team_based"], {});
        block(env, morph32, context, "if", [get(env, context, "team_based")], {}, child1, null);
        inline(env, morph33, context, "t", ["builder.casespace.phases.edit.headings.components"], {});
        block(env, morph34, context, "unless", [get(env, context, "componentables_loaded")], {}, child2, null);
        block(env, morph35, context, "if", [get(env, context, "componentables_loaded")], {}, child3, null);
        block(env, morph36, context, "each", [get(env, context, "componentables")], {"keyword": "componentable"}, child4, null);
        element(env, element30, context, "action", ["cancel"], {"on": "click"});
        inline(env, morph37, context, "t", ["builder.casespace.phases.edit.buttons.cancel"], {});
        block(env, morph38, context, "if", [get(env, context, "is_valid")], {}, child5, null);
        return fragment;
      }
    };
  }()));

});