define('client/components/thinkspace/readiness_assurance/admin/timers/active', ['exports', 'ember', 'thinkspace-readiness-assurance/base/admin/component', 'totem/timer'], function (exports, ember, base, totem_timer) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    timers: null,
    willInsertElement: function() {
      return this.get_timer_list();
    },
    add_refresh_timer: function() {
      return this.refresh_timer = totem_timer['default'].start({
        source: this,
        method: 'get_timer_list',
        interval: 15000
      });
    },
    get_timer_list: function() {
      var cevent, pubsub, sevent, socket;
      pubsub = this.am.pubsub;
      socket = pubsub.get_socket();
      cevent = pubsub.client_event('timer_list');
      sevent = pubsub.server_event('timer_list');
      pubsub.on(socket, sevent, this, 'handle_timer_list');
      return socket.emit(cevent);
    },
    handle_timer_list: function(data) {
      console.info('handle_timer_list:', data);
      return this.set_timers(data).then((function(_this) {
        return function() {
          return _this.add_refresh_timer();
        };
      })(this));
    },
    set_timers: function(data) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var promises, value, values;
          values = data.value || [];
          values = values.sortBy('timer.settings.end_at');
          promises = (function() {
            var i, len, results;
            results = [];
            for (i = 0, len = values.length; i < len; i++) {
              value = values[i];
              results.push(this.format_timer_value(value));
            }
            return results;
          }).call(_this);
          return ember['default'].RSVP.all(promises).then(function(timers) {
            _this.set('timers', timers);
            _this.set_ready_on();
            return resolve();
          });
        };
      })(this));
    },
    format_timer_value: function(value) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var end_at, end_date, from_class, from_now, from_units, id, interval, rooms, settings, title, type, unit;
          settings = value.timer || {};
          rooms = value.rooms;
          id = settings.id;
          title = settings.title || 'no-title';
          type = settings.type;
          interval = settings.interval;
          unit = settings.unit;
          end_date = new Date(settings.end_at);
          end_at = _this.am.format_time(end_date);
          from_now = _this.am.minutes_from_now(end_date);
          if (from_now > 0) {
            from_units = _this.am.pluralize(from_now, unit);
            from_now = from_now + " " + from_units;
            from_class = '';
          } else {
            from_class = 'ts-ra_admin-timer-about-to-fire';
            from_now = "under 1 " + unit;
          }
          return resolve({
            settings: settings,
            rooms: rooms,
            title: title,
            end_at: end_at,
            type: type,
            from_now: from_now,
            from_class: from_class
          });
        };
      })(this));
    },
    actions: {
      cancel: function(timer) {
        console.warn('cancel:', timer);
        timer.settings.rooms = timer.rooms;
        return this.am.send_timer_cancel({
          timer: timer.settings
        }).then((function(_this) {
          return function() {
            return _this.get_timer_list();
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});