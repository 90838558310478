define('client/components/thinkspace/simulation/simulations/mountain', ['exports', 'ember', 'ember-data', 'totem/ns', 'totem-application/mixins/default_property_settings', 'thinkspace-base/components/base'], function (exports, ember, ds, ns, default_prop_mixin, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend(default_prop_mixin['default'], {
    c_result: ns['default'].to_p('simulation', 'simulations', 'mountain', 'result'),
    simulation_loaded: false,
    dynamic_graphing: false,
    mountain_game: null,
    leaf_obj: null,
    update_counter: 0,
    sim_fps: 60,
    trial_num: 0,
    wind_speed: 0,
    parcel_temp: 0,
    cur_altitude: 0,
    cur_temp: 0,
    cur_humidity: 0,
    cur_pressure: 0,
    cur_dew_point: 0,
    start_temp: 25,
    start_humidity: 10.0,
    start_pressure: 1000,
    sun_scale_coefficient: 0.2,
    sun_ring_toggle: false,
    fill_class: 'ts-rangeslider_fill',
    handle_class: 'ts-rangeslider_handle',
    range_class: 'ts-rangeslider',
    x_step_size: 2,
    y_step_size: 2,
    leaf_start_x: 50,
    sim_ground_level: 350,
    sim_peak: 65,
    canvas_midpoint: 340,
    start_temp_max: 30.0,
    start_temp_min: -20.0,
    start_humidity_max: 42.5,
    start_humidity_min: 3.0,
    sim_altitude_max: 2.5,
    sim_altitude_min: 0,
    slider_step_size: 0.1,
    volume_bar: null,
    sound_count: 2,
    sounds_complete: 0,
    muted: false,
    factor: 10,
    image_preloader: new createjs.LoadQueue(false),
    game_tick_listener: null,
    sim_update_listener: null,
    load_listener: null,
    load_text_container: null,
    load_update_counter: 0,
    sim_running: false,
    cloud_base: 'Clear',
    night_overlay_obj: null,
    graph_altitude_max: 5,
    graph_altitude_min: 0,
    graph_temp_max: 30,
    graph_temp_min: -20,
    graph_humidity_max: 50,
    graph_humidity_min: 0.1,
    humidity_output: 0.1,
    temp_output: 0.0,
    next_cloud_y: 350,
    cloud_height: 45,
    base_cloud_array: [],
    leaf_cloud_array: [],
    cloud_array: [],
    cloud_base_y: null,
    first_cloud: null,
    rain_array: [],
    rain_update_counter: 0,
    rain_update_target: 5,
    slider_background_template: "<div class='ts-rangeslider_background' />",
    default_altitude_chart_data: [
      {
        'temp': 0,
        'altitude': 0
      }
    ],
    default_humidity_chart_data: [
      {
        'start_temp': 25,
        'start_humidity': 10,
        'customBullet': '/assets/images/simulations/mountain/rect_leaf.png'
      }, {
        'temp': -20,
        'phase_line_vapor_humidity': 1.222945649168197
      }, {
        'temp': -19.5,
        'phase_line_vapor_humidity': 1.277495908928843
      }, {
        'temp': -19,
        'phase_line_vapor_humidity': 1.3342078551627543
      }, {
        'temp': -18.5,
        'phase_line_vapor_humidity': 1.3931558368273866
      }, {
        'temp': -18,
        'phase_line_vapor_humidity': 1.4544163427835477
      }, {
        'temp': -17.5,
        'phase_line_vapor_humidity': 1.51806805004944
      }, {
        'temp': -17,
        'phase_line_vapor_humidity': 1.5841918727839308
      }, {
        'temp': -16.5,
        'phase_line_vapor_humidity': 1.652871012002036
      }, {
        'temp': -16,
        'phase_line_vapor_humidity': 1.7241910060256844
      }, {
        'temp': -15.5,
        'phase_line_vapor_humidity': 1.79823978167263
      }, {
        'temp': -15,
        'phase_line_vapor_humidity': 1.8751077061862296
      }, {
        'temp': -14.5,
        'phase_line_vapor_humidity': 1.9548876399086634
      }, {
        'temp': -14,
        'phase_line_vapor_humidity': 2.037674989700097
      }, {
        'temp': -13.5,
        'phase_line_vapor_humidity': 2.123567763106011
      }, {
        'temp': -13,
        'phase_line_vapor_humidity': 2.212666623274969
      }, {
        'temp': -12.5,
        'phase_line_vapor_humidity': 2.305074944628757
      }, {
        'temp': -12,
        'phase_line_vapor_humidity': 2.400898869286813
      }, {
        'temp': -11.5,
        'phase_line_vapor_humidity': 2.500247364246667
      }, {
        'temp': -11,
        'phase_line_vapor_humidity': 2.603232279321993
      }, {
        'temp': -10.5,
        'phase_line_vapor_humidity': 2.709968405839659
      }, {
        'temp': -10,
        'phase_line_vapor_humidity': 2.8205735360971143
      }, {
        'temp': -9.5,
        'phase_line_vapor_humidity': 2.935168523581191
      }, {
        'temp': -9,
        'phase_line_vapor_humidity': 3.05387734394933
      }, {
        'temp': -8.5,
        'phase_line_vapor_humidity': 3.1768271567740776
      }, {
        'temp': -8,
        'phase_line_vapor_humidity': 3.3041483680514574
      }, {
        'temp': -7.5,
        'phase_line_vapor_humidity': 3.4359746934737885
      }, {
        'temp': -7,
        'phase_line_vapor_humidity': 3.572443222467293
      }, {
        'temp': -6.5,
        'phase_line_vapor_humidity': 3.713694482994712
      }, {
        'temp': -6,
        'phase_line_vapor_humidity': 3.859872507122967
      }, {
        'temp': -5.5,
        'phase_line_vapor_humidity': 4.01112489735568
      }, {
        'temp': -5,
        'phase_line_vapor_humidity': 4.16760289373051
      }, {
        'temp': -4.5,
        'phase_line_vapor_humidity': 4.3294614416805395
      }, {
        'temp': -4,
        'phase_line_vapor_humidity': 4.496859260659482
      }, {
        'temp': -3.5,
        'phase_line_vapor_humidity': 4.669958913529648
      }, {
        'temp': -3,
        'phase_line_vapor_humidity': 4.848926876712026
      }, {
        'temp': -2.5,
        'phase_line_vapor_humidity': 5.033933611097255
      }, {
        'temp': -2,
        'phase_line_vapor_humidity': 5.2251536337162285
      }, {
        'temp': -1.5,
        'phase_line_vapor_humidity': 5.42276559016919
      }, {
        'temp': -1,
        'phase_line_vapor_humidity': 5.626952327811398
      }, {
        'temp': -0.5,
        'phase_line_vapor_humidity': 5.837900969694071
      }, {
        'temp': 0,
        'phase_line_vapor_humidity': 6.055802989258314
      }, {
        'temp': 0.5,
        'phase_line_vapor_humidity': 6.280854285780446
      }, {
        'temp': 1,
        'phase_line_vapor_humidity': 6.513255260566105
      }, {
        'temp': 1.5,
        'phase_line_vapor_humidity': 6.753210893891136
      }, {
        'temp': 2,
        'phase_line_vapor_humidity': 7.000930822686538
      }, {
        'temp': 2.5,
        'phase_line_vapor_humidity': 7.256629418964895
      }, {
        'temp': 3,
        'phase_line_vapor_humidity': 7.520525868985428
      }, {
        'temp': 3.5,
        'phase_line_vapor_humidity': 7.792844253154714
      }, {
        'temp': 4,
        'phase_line_vapor_humidity': 8.073813626659913
      }, {
        'temp': 4.5,
        'phase_line_vapor_humidity': 8.363668100831166
      }, {
        'temp': 5,
        'phase_line_vapor_humidity': 8.662646925229787
      }, {
        'temp': 5.5,
        'phase_line_vapor_humidity': 8.970994570458526
      }, {
        'temp': 6,
        'phase_line_vapor_humidity': 9.288960811690254
      }, {
        'temp': 6.5,
        'phase_line_vapor_humidity': 9.61680081291103
      }, {
        'temp': 7,
        'phase_line_vapor_humidity': 9.954775211873493
      }, {
        'temp': 7.5,
        'phase_line_vapor_humidity': 10.303150205756438
      }, {
        'temp': 8,
        'phase_line_vapor_humidity': 10.662197637526125
      }, {
        'temp': 8.5,
        'phase_line_vapor_humidity': 11.032195082994555
      }, {
        'temp': 9,
        'phase_line_vapor_humidity': 11.413425938570565
      }, {
        'temp': 9.5,
        'phase_line_vapor_humidity': 11.806179509698287
      }, {
        'temp': 10,
        'phase_line_vapor_humidity': 12.210751099978435
      }, {
        'temp': 10.5,
        'phase_line_vapor_humidity': 12.627442100966872
      }, {
        'temp': 11,
        'phase_line_vapor_humidity': 13.056560082645785
      }, {
        'temp': 11.5,
        'phase_line_vapor_humidity': 13.498418884561259
      }, {
        'temp': 12,
        'phase_line_vapor_humidity': 13.953338707622153
      }, {
        'temp': 12.5,
        'phase_line_vapor_humidity': 14.421646206554753
      }, {
        'temp': 13,
        'phase_line_vapor_humidity': 14.903674583006461
      }, {
        'temp': 13.5,
        'phase_line_vapor_humidity': 15.399763679293798
      }, {
        'temp': 14,
        'phase_line_vapor_humidity': 15.910260072787176
      }, {
        'temp': 14.5,
        'phase_line_vapor_humidity': 16.43551717092745
      }, {
        'temp': 15,
        'phase_line_vapor_humidity': 16.97589530686672
      }, {
        'temp': 15.5,
        'phase_line_vapor_humidity': 17.531761835727696
      }, {
        'temp': 16,
        'phase_line_vapor_humidity': 18.103491231474482
      }, {
        'temp': 16.5,
        'phase_line_vapor_humidity': 18.691465184387702
      }, {
        'temp': 17,
        'phase_line_vapor_humidity': 19.29607269913806
      }, {
        'temp': 17.5,
        'phase_line_vapor_humidity': 19.917710193449725
      }, {
        'temp': 18,
        'phase_line_vapor_humidity': 20.556781597347648
      }, {
        'temp': 18.5,
        'phase_line_vapor_humidity': 21.213698452980985
      }, {
        'temp': 19,
        'phase_line_vapor_humidity': 21.888880015014692
      }, {
        'temp': 19.5,
        'phase_line_vapor_humidity': 22.582753351582785
      }, {
        'temp': 20,
        'phase_line_vapor_humidity': 23.295753445794162
      }, {
        'temp': 20.5,
        'phase_line_vapor_humidity': 24.02832329778478
      }, {
        'temp': 21,
        'phase_line_vapor_humidity': 24.780914027306498
      }, {
        'temp': 21.5,
        'phase_line_vapor_humidity': 25.553984976846152
      }, {
        'temp': 22,
        'phase_line_vapor_humidity': 26.348003815265415
      }, {
        'temp': 22.5,
        'phase_line_vapor_humidity': 27.163446641953918
      }, {
        'temp': 23,
        'phase_line_vapor_humidity': 28.000798091486978
      }, {
        'temp': 23.5,
        'phase_line_vapor_humidity': 28.86055143877933
      }, {
        'temp': 24,
        'phase_line_vapor_humidity': 29.743208704726253
      }, {
        'temp': 24.5,
        'phase_line_vapor_humidity': 30.64928076232403
      }, {
        'temp': 25,
        'phase_line_vapor_humidity': 31.579287443259478
      }, {
        'temp': 25.5,
        'phase_line_vapor_humidity': 32.53375764496122
      }, {
        'temp': 26,
        'phase_line_vapor_humidity': 33.51322943810272
      }, {
        'temp': 26.5,
        'phase_line_vapor_humidity': 34.518250174548065
      }, {
        'temp': 27,
        'phase_line_vapor_humidity': 35.54937659573159
      }, {
        'temp': 27.5,
        'phase_line_vapor_humidity': 36.607174941461906
      }, {
        'temp': 28,
        'phase_line_vapor_humidity': 37.69222105914062
      }, {
        'temp': 28.5,
        'phase_line_vapor_humidity': 38.80510051338682
      }, {
        'temp': 29,
        'phase_line_vapor_humidity': 39.946408696057574
      }, {
        'temp': 29.5,
        'phase_line_vapor_humidity': 41.11675093665394
      }, {
        'temp': 30,
        'phase_line_vapor_humidity': 42.316742613104566
      }
    ],
    humidity_chart_data: null,
    altitude_chart_data: null,
    chart_step_data: [],
    trial_results: [],
    cur_trial_data: {},
    humidity_chart: null,
    altitude_chart: null,
    sim_started: false,
    sim_paused: false,
    sounds_loaded: false,
    images_loaded: false,
    valid_graph_colors: ['#82c2ee', '#94cb8a', '#fb6b6b', '#fcb725', '#285cc9'],
    default_property_settings: {
      update_counter: 0,
      rain_update_counter: 0,
      wind_speed: 0,
      cur_altitude: 0,
      cur_temp: 0,
      cur_humidity: 0,
      cur_dew_point: 0,
      start_temp: 25,
      start_humidity: 10.0,
      start_pressure: 1000,
      start_temp_max: 30.0,
      start_temp_min: -20.0,
      start_humidity_max: 42.5,
      start_humidity_min: 0,
      factor: 10,
      sim_running: false,
      sim_started: false,
      cloud_base: 'Clear',
      first_cloud: null,
      next_cloud_y: 350,
      cur_trial_data: {},
      base_cloud_array: {
        type: 'array'
      },
      cloud_array: {
        type: 'array'
      }
    },
    display_altitude: ember['default'].computed('cur_altitude', function() {
      if (this.get('cur_altitude').toFixed(1) < 0) {
        return 0.00;
      } else {
        return this.get('cur_altitude').toFixed(1);
      }
    }),
    display_dew_point: ember['default'].computed('cur_dew_point', function() {
      return this.get('cur_dew_point').toFixed(1);
    }),
    display_humidity: ember['default'].computed('cur_humidity', 'start_humidity', function() {
      if (this.get('sim_started')) {
        return this.get('cur_humidity').toFixed(1);
      } else {
        return this.get('start_humidity').toFixed(1);
      }
    }),
    display_pressure: ember['default'].computed('cur_pressure', function() {
      return this.get('cur_pressure').toFixed(1);
    }),
    display_temp: ember['default'].computed('cur_temp', 'start_temp', function() {
      if (this.get('sim_started')) {
        return this.get('cur_temp').toFixed(1);
      } else {
        return this.get('start_temp').toFixed(1);
      }
    }),
    display_cloud_base: ember['default'].computed('cloud_base', function() {
      var cloud_base;
      cloud_base = this.get('cloud_base');
      if (cloud_base === 'Clear') {
        return cloud_base;
      } else {
        return cloud_base.toFixed(1) + ' km';
      }
    }),
    need_reset: ember['default'].computed('sim_running', 'sim_started', function() {
      var sim_running, sim_started;
      sim_running = this.get('sim_running');
      sim_started = this.get('sim_started');
      if (sim_running) {
        this.set('sim_started', true);
        return false;
      } else {
        if (sim_started) {
          return true;
        }
      }
    }),
    sim_final_load: ember['default'].observer('sounds_loaded', 'images_loaded', function() {
      var load_text_container, mountain_game;
      if (this.get('sounds_loaded') && this.get('images_loaded')) {
        load_text_container = this.get('load_text_container');
        mountain_game = this.get('mountain_game');
        createjs.Tween.removeTweens(load_text_container);
        mountain_game.removeChild(load_text_container);
        mountain_game.alpha = 0;
        this.initialize_simulation();
        return this.set('simulation_loaded', true);
      }
    }),
    next_graph_color: ember['default'].computed('trial_num', function() {
      var index, trial_num, valid_graph_colors;
      trial_num = this.get('trial_num');
      valid_graph_colors = this.get('valid_graph_colors');
      index = trial_num % valid_graph_colors.length;
      return valid_graph_colors[index];
    }),
    animation_obs: ember['default'].observer('sim_running', function() {
      var leaf_obj;
      leaf_obj = this.get('leaf_obj');
      if (this.get('sim_running')) {
        return leaf_obj.play();
      } else {
        return leaf_obj.stop();
      }
    }),
    temp_slider_obs: ember['default'].observer('start_temp', function() {
      return this.$('input[id="temp_slider"]').val(this.get('start_temp')).change();
    }),
    humidity_slider_obs: ember['default'].observer('start_humidity', function() {
      return this.$('input[id="humidity_slider"]').val(this.get('start_humidity')).change();
    }),
    alt_per_step: ember['default'].computed('x_step_size', 'canvas_midpoint', 'leaf_start_x', function() {
      var canvas_midpoint, leaf_start_x, sim_altitude_max, sim_altitude_min, x_step_size, y_steps;
      canvas_midpoint = this.get('canvas_midpoint');
      leaf_start_x = this.get('leaf_start_x');
      x_step_size = this.get('x_step_size');
      sim_altitude_max = this.get('sim_altitude_max');
      sim_altitude_min = this.get('sim_altitude_min');
      y_steps = (canvas_midpoint - leaf_start_x) / x_step_size;
      return (sim_altitude_max - sim_altitude_min) / y_steps;
    }),
    cur_alt_obs: ember['default'].observer('cur_altitude', function() {
      var cur_altitude;
      cur_altitude = this.get('cur_altitude');
      if (this.get('sim_running') && cur_altitude <= 0) {
        this.collect_trial_completion_data();
        this.get('humidity_chart').validateData();
        this.get('altitude_chart').validateData();
        return this.set('sim_running', false);
      }
    }),
    start_dew_point: ember['default'].computed('start_humidity', function() {
      var start_dew_point, start_humidity;
      start_humidity = this.get('start_humidity');
      start_dew_point = 2354 / (9.4041 - Math.log(start_humidity) / Math.log(10)) - 273;
      return start_dew_point = start_dew_point.toFixed(1);
    }),
    temp_min: ember['default'].computed('start_humidity', function() {
      var min_temp, start_humidity_mb, start_humidity_mmhg;
      start_humidity_mb = parseFloat(this.get('start_humidity'));
      start_humidity_mmhg = start_humidity_mb / 1.33322368;
      return min_temp = (1730.63 / (8.07131 - (Math.log(start_humidity_mmhg) / Math.log(10)))) - 233.426;
    }),
    humidity_max: ember['default'].computed('start_temp', function() {
      var pressure_mb, pressure_mmhg, start_temp;
      start_temp = parseFloat(this.get("start_temp"));
      pressure_mmhg = Math.pow(10, 8.07131 - (1730.63 / (233.426 + start_temp)));
      return pressure_mb = pressure_mmhg * 1.33322368;
    }),
    trial_valid: ember['default'].computed('temp_min', 'humidity_max', function() {
      var humidity_max, start_humidity, start_temp, temp_min;
      temp_min = this.get('temp_min');
      humidity_max = this.get('humidity_max');
      start_humidity = this.get('start_humidity');
      start_temp = this.get('start_temp');
      if (start_temp < temp_min || start_humidity > humidity_max) {
        return false;
      } else {
        return true;
      }
    }),
    user_indicator_update: ember['default'].observer('start_temp', 'start_humidity', function() {
      var humidity_chart, humidity_chart_data, start_humidity, start_temp;
      humidity_chart = this.get('humidity_chart');
      humidity_chart_data = this.get('humidity_chart_data');
      start_temp = this.get('start_temp');
      start_humidity = this.get('start_humidity');
      return humidity_chart_data.find((function(element) {
        if (element.start_temp != null) {
          element.start_temp = start_temp;
          element.start_humidity = start_humidity;
          return humidity_chart.validateData();
        }
      }));
    }),
    muted_obs: ember['default'].observer('muted', function() {
      if (this.get('muted')) {
        return createjs.Sound.volume = 0;
      } else {
        return createjs.Sound.volume = this.get('volume_bar').scaleY;
      }
    }),
    init_template: function() {
      var $background, range_class, template;
      range_class = this.get('range_class');
      template = this.get('slider_background_template');
      $background = $(template);
      return this.$("." + range_class).prepend($background);
    },
    collect_trial_start_data: function() {
      var cur_trial_data, start_dew_point, start_humidity, start_temp, trial_num;
      cur_trial_data = this.get('cur_trial_data');
      start_temp = this.get('start_temp');
      start_humidity = this.get('start_humidity');
      start_dew_point = this.get('start_dew_point');
      trial_num = this.get('trial_num');
      cur_trial_data["" + trial_num]['start_temp'] = start_temp;
      cur_trial_data["" + trial_num]['start_humidity'] = start_humidity;
      cur_trial_data["" + trial_num]['start_dew_point'] = start_dew_point;
      return this.set('cur_trial_data', cur_trial_data);
    },
    collect_trial_completion_data: function() {
      var cloud_base, cur_trial_data, final_dew_point, final_humidity, final_temp, trial_num, trial_results;
      cur_trial_data = this.get('cur_trial_data');
      trial_results = this.get('trial_results');
      final_temp = this.get('cur_temp');
      final_humidity = this.get('cur_humidity');
      final_dew_point = this.get('cur_dew_point');
      trial_num = this.get('trial_num');
      cloud_base = this.get('cloud_base');
      cur_trial_data["" + trial_num]['final_temp'] = final_temp;
      cur_trial_data["" + trial_num]['final_humidity'] = final_humidity;
      cur_trial_data["" + trial_num]['final_dew_point'] = final_dew_point;
      cur_trial_data["" + trial_num]['cloud_base'] = cloud_base;
      return trial_results.pushObject(cur_trial_data);
    },
    willDestroyElement: function() {
      this.get('mountain_game').removeAllEventListeners();
      return createjs.Ticker.removeAllEventListeners();
    },
    didInsertElement: function() {
      var canvas;
      this.set('mountain_game', new createjs.Stage('mountain-sim-canvas'));
      canvas = document.getElementById('mountain-sim-canvas');
      canvas.width = 700;
      canvas.height = 400;
      createjs.Ticker.setFPS(this.get('sim_fps'));
      createjs.Ticker.addEventListener('tick', createjs.Tween);
      return this.initialize_load_screen();
    },
    initialize_load_screen: function() {
      var dark_text, game_tick_listener, load_listener, load_text_container, mountain_game;
      mountain_game = this.get('mountain_game');
      mountain_game.setBounds(0, 0, 700, 400);
      load_text_container = new createjs.Container();
      dark_text = new createjs.Text('Arriving shortly!', '24px omnes-pro', '#63b4d6');
      dark_text.textAlign = 'center';
      load_text_container.addChild(dark_text);
      dark_text.x = load_text_container.getBounds().width / 2;
      load_text_container.regY = load_text_container.getBounds().height / 2;
      load_text_container.regX = load_text_container.getBounds().width / 2;
      load_text_container.y = mountain_game.getBounds().height / 2;
      load_text_container.x = mountain_game.getBounds().width / 2;
      mountain_game.addChild(load_text_container);
      createjs.Tween.get(load_text_container, {
        loop: true
      }).to({
        alpha: 0
      }, 1000).to({
        alpha: 1
      }, 1000);
      this.set('load_text_container', load_text_container);
      game_tick_listener = createjs.Ticker.addEventListener("tick", mountain_game);
      this.set('game_tick_listener', game_tick_listener);
      load_listener = createjs.Ticker.addEventListener("tick", (function(event) {
        return this.timed_load(event);
      }).bind(this));
      this.set('load_listener', load_listener);
      this.initialize_graph();
      this.$('input[id="temp_slider"]').rangeslider({
        polyfill: false,
        rangeClass: this.get('range_class'),
        fillClass: this.get('fill_class'),
        handleClass: this.get('handle_class'),
        onInit: (function(_this) {
          return function() {
            return _this.init_template();
          };
        })(this),
        onSlideEnd: (function(_this) {
          return function(position, value) {
            return _this.set('start_temp', value);
          };
        })(this),
        onSlide: (function(_this) {
          return function(position, value) {
            return _this.set('temp_output', value.toFixed(1) + ' °C');
          };
        })(this)
      });
      return this.$('input[id="humidity_slider"]').rangeslider({
        polyfill: false,
        rangeClass: this.get('range_class'),
        fillClass: this.get('fill_class'),
        handleClass: this.get('handle_class'),
        onInit: (function(_this) {
          return function() {
            return _this.init_template();
          };
        })(this),
        onSlideEnd: (function(_this) {
          return function(position, value) {
            return _this.set('start_humidity', value);
          };
        })(this),
        onSlide: (function(_this) {
          return function(position, value) {
            return _this.set('humidity_output', value.toFixed(1) + ' mb');
          };
        })(this)
      });
    },
    update_temp: function(value) {
      var image_preloader, mountain_game, new_ring, next_ring_value, ring, scale, start_temp, start_temp_max, start_temp_min, sun_ring_toggle, sun_rings, sun_scale_coefficient;
      start_temp = value;
      start_temp_max = this.get('start_temp_max');
      start_temp_min = this.get('start_temp_min');
      sun_scale_coefficient = this.get('sun_scale_coefficient');
      sun_rings = this.get('sun_rings');
      mountain_game = this.get('mountain_game');
      sun_ring_toggle = this.get('sun_ring_toggle');
      scale = 1 + ((start_temp - start_temp_min) / (start_temp_max - start_temp_min));
      sun_rings.forEach((function(_this) {
        return function(ring_obj) {
          if (scale < 1 + ring_obj.coefficient) {
            createjs.Tween.get(ring_obj.ring).to({
              alpha: 0
            }, 250);
            mountain_game.removeChild(ring_obj.ring);
            return sun_rings.removeObject(ring_obj);
          }
        };
      })(this));
      next_ring_value = 1 + sun_scale_coefficient * (1 + sun_rings.get('length'));
      if (scale > next_ring_value) {
        image_preloader = this.get('image_preloader');
        mountain_game = this.get('mountain_game');
        new_ring = {};
        if (sun_ring_toggle) {
          ring = new createjs.Bitmap(image_preloader.getResult('sun_inner_ring'));
        } else {
          ring = new createjs.Bitmap(image_preloader.getResult('sun_outer_ring'));
        }
        new_ring['ring'] = ring;
        new_ring['coefficient'] = next_ring_value - 1;
        ring.alpha = 0;
        ring.x = 560;
        ring.y = 50;
        ring.regX = ring.getBounds().width / 2;
        ring.regY = ring.getBounds().height / 2;
        mountain_game.addChild(ring);
        createjs.Tween.get(ring).to({
          alpha: 1
        }, 500);
        sun_rings.pushObject(new_ring);
        this.toggleProperty('sun_ring_toggle');
      }
      return sun_rings.forEach((function(_this) {
        return function(ring_obj) {
          return ring_obj.ring.scaleX = ring_obj.ring.scaleY = scale - ring_obj.coefficient - 0.1;
        };
      })(this));
    },
    timed_load: function(event) {
      var image_queue, load_listener, load_update_counter;
      load_update_counter = this.get('load_update_counter');
      if (load_update_counter === this.get('sim_fps')) {
        image_queue = this.get('image_preloader');
        load_listener = this.get('load_listener');
        createjs.Sound.alternateExtensions = ['mp3'];
        createjs.Sound.registerPlugins([createjs.HTMLAudioPlugin]);
        createjs.Sound.on('fileload', (function(event) {
          return this.handle_sound_load(event);
        }).bind(this));
        if (createjs.Sound.loadComplete('https://s3.amazonaws.com/thinkspace-prod/sounds/thunder.mp3')) {
          this.handle_sound_load('https://s3.amazonaws.com/thinkspace-prod/sounds/thunder.mp3');
        }
        if (createjs.Sound.loadComplete('https://s3.amazonaws.com/thinkspace-prod/sounds/wind.mp3')) {
          this.handle_sound_load('https://s3.amazonaws.com/thinkspace-prod/sounds/wind.mp3');
        }
        createjs.Sound.registerSound('https://s3.amazonaws.com/thinkspace-prod/sounds/thunder.mp3', 'thunder');
        createjs.Sound.registerSound('https://s3.amazonaws.com/thinkspace-prod/sounds/wind.mp3', 'wind');
        image_queue.on('complete', (function(event) {
          return this.handle_load(event);
        }).bind(this));
        image_queue.loadFile(new createjs.LoadItem().set({
          id: 'background',
          crossOrigin: true,
          src: '/assets/images/simulations/mountain/mountain.png'
        }));
        image_queue.loadFile(new createjs.LoadItem().set({
          id: 'leaf_sheet',
          crossOrigin: true,
          src: '/assets/images/simulations/mountain/leaf-spritesheet.png'
        }));
        image_queue.loadFile(new createjs.LoadItem().set({
          id: 'raindrop',
          crossOrigin: true,
          src: '/assets/images/simulations/mountain/raindrop.png'
        }));
        image_queue.loadFile(new createjs.LoadItem().set({
          id: 'cloud_2',
          crossOrigin: true,
          src: '/assets/images/simulations/mountain/borderless_cloud3.png'
        }));
        image_queue.loadFile(new createjs.LoadItem().set({
          id: 'mountain_base',
          crossOrigin: true,
          src: '/assets/images/simulations/mountain/mountain_base.png'
        }));
        image_queue.loadFile(new createjs.LoadItem().set({
          id: 'speaker',
          crossOrigin: true,
          src: '/assets/images/simulations/speaker.png'
        }));
        image_queue.loadFile(new createjs.LoadItem().set({
          id: 'speaker_mute',
          crossOrigin: true,
          src: '/assets/images/simulations/speaker-mute.png'
        }));
        image_queue.load();
        createjs.Ticker.removeEventListener("tick", load_listener);
      }
      return this.set('load_update_counter', load_update_counter + 1);
    },
    handle_sound_load: function(event) {
      var sounds_complete;
      sounds_complete = this.get('sounds_complete') + 1;
      if (sounds_complete === this.get('sound_count')) {
        createjs.Sound.removeAllEventListeners();
        this.set('sounds_loaded', true);
      }
      return this.set('sounds_complete', sounds_complete);
    },
    handle_load: function(event) {
      this.get('image_preloader').removeAllEventListeners();
      return this.set('images_loaded', true);
    },
    simulation_update: function(event) {
      var alt_per_step, canvas_midpoint, cur_altitude, cur_dew_point, cur_humidity, cur_pressure, cur_temp, factor, ground_level, h, leaf, mountain_game, old_altitude, old_dew_point, old_humidity, old_pressure, old_temp, start_altitude, start_humidity, start_pressure, start_temp, step_data_obj, trial_num, update_counter, x_step_size, y_step_size;
      mountain_game = this.get('mountain_game');
      leaf = mountain_game.leaf;
      ground_level = this.get('sim_ground_level');
      canvas_midpoint = this.get('canvas_midpoint');
      x_step_size = this.get('x_step_size');
      y_step_size = this.get('y_step_size');
      alt_per_step = this.get('alt_per_step');
      trial_num = this.get('trial_num');
      update_counter = this.get('update_counter');
      if (leaf.y <= ground_level) {
        if (this.get('sim_running')) {
          update_counter += 1;
          if (leaf.x < canvas_midpoint) {
            leaf.x += x_step_size;
            leaf.y -= y_step_size;
          } else {
            leaf.x += x_step_size;
            leaf.y += y_step_size;
          }
          leaf.rotation += Math.floor(Math.random() * 5) + 1;
          this.set('update_counter', update_counter);
          if (update_counter === 1) {
            old_altitude = this.get('cur_altitude');
            old_temp = this.get('cur_temp');
            old_humidity = this.get('cur_humidity');
            old_pressure = this.get('cur_pressure');
            old_dew_point = this.get('cur_dew_point');
            factor = this.get('factor');
            start_temp = parseInt(this.get('start_temp'));
            start_pressure = this.get('start_pressure');
            start_altitude = this.get('sim_altitude_min');
            start_humidity = parseInt(this.get('start_humidity'));
            if (old_temp === 0) {
              old_temp = start_temp;
            }
            if (old_humidity === 0) {
              old_humidity = start_humidity;
            }
            if (old_pressure === 0) {
              old_pressure = start_pressure;
            }
            if (leaf.x > canvas_midpoint) {
              alt_per_step *= -1;
            }
            cur_altitude = old_altitude + update_counter * alt_per_step;
            cur_pressure = start_pressure - 125 * (cur_altitude - start_altitude);
            cur_humidity = old_humidity * cur_pressure / old_pressure;
            cur_dew_point = 2354 / (9.4041 - Math.log(old_humidity) / Math.log(10)) - 273;
            cur_temp = old_temp - factor * (cur_altitude - old_altitude);
            h = Math.pow(10, 9.4041 - 2354 / (cur_temp + 273));
            if (cur_humidity > h) {
              if (leaf.y <= this.get('next_cloud_y')) {
                this.animate_clouds(cur_altitude, leaf);
              }
              cur_humidity = h;
              factor = 6.0;
            }
            if (cur_temp > old_temp) {
              factor = 10.0;
            }
            this.set('cur_altitude', cur_altitude);
            this.set('cur_pressure', cur_pressure);
            this.set('cur_humidity', cur_humidity);
            this.set('cur_dew_point', cur_dew_point);
            this.set('cur_temp', cur_temp);
            this.set('factor', factor);
            step_data_obj = {};
            step_data_obj['temp'] = cur_temp;
            step_data_obj['humidity'] = cur_humidity;
            step_data_obj['altitude'] = cur_altitude;
            if (this.get('cloud_base') !== 'Clear' && old_altitude < cur_altitude) {
              this.create_rain(leaf);
            }
            this.add_graph_coords(step_data_obj, trial_num);
            return this.set('update_counter', 0);
          }
        }
      }
    },
    create_rain: function(leaf_obj) {
      var base_cloud_array, calls_before_drop, canvas_midpoint, cloud, cur_distance, cur_x_distance, cur_y_distance, first_cloud, image_preloader, last_cloud, mountain_game, rain_array, rain_update_counter, rain_update_target, raindrop, rand, rand_range, sim_peak, total_distance, total_x_distance, total_y_distance, traveled;
      rain_update_counter = this.get('rain_update_counter');
      rain_update_target = this.get('rain_update_target');
      image_preloader = this.get('image_preloader');
      base_cloud_array = this.get('base_cloud_array');
      rain_array = this.get('rain_array');
      cloud = base_cloud_array.get('firstObject');
      mountain_game = this.get('mountain_game');
      sim_peak = this.get('sim_peak');
      canvas_midpoint = this.get('canvas_midpoint');
      if (rain_update_counter >= rain_update_target) {
        first_cloud = base_cloud_array.get('firstObject');
        last_cloud = base_cloud_array.get('lastObject');
        rand_range = (last_cloud.x + (last_cloud.getBounds().width / 2)) - (first_cloud.x - (first_cloud.getBounds().width / 2));
        rand = Math.floor(Math.random() * rand_range) - rand_range / 2;
        total_y_distance = first_cloud.y - sim_peak;
        total_x_distance = canvas_midpoint - first_cloud.x;
        cur_y_distance = first_cloud.y - leaf_obj.y;
        cur_x_distance = leaf_obj.x - first_cloud.x;
        total_distance = Math.floor(Math.sqrt(Math.pow(total_x_distance, 2) + Math.pow(total_y_distance, 2)));
        cur_distance = Math.floor(Math.sqrt(Math.pow(cur_x_distance, 2) + Math.pow(cur_y_distance, 2)));
        traveled = cur_distance / total_distance;
        calls_before_drop = (1 - traveled) * 20;
        this.set('rain_update_target', calls_before_drop);
        raindrop = new createjs.Bitmap(image_preloader.getResult('raindrop'));
        raindrop.x = first_cloud.x + rand;
        if (raindrop.x > leaf_obj.x) {
          raindrop.x = leaf_obj.x;
        }
        raindrop.y = first_cloud.y;
        mountain_game.addChild(raindrop);
        mountain_game.setChildIndex(raindrop, 1);
        rain_array.pushObject(raindrop);
        createjs.Tween.get(raindrop, {
          loop: true
        }).to({
          y: raindrop.y + 125
        }, 750).call(function() {
          raindrop.x = cloud.x - rand;
          return raindrop.y = cloud.y;
        });
        rain_update_counter = 0;
      }
      rain_update_counter += 1;
      return this.set('rain_update_counter', rain_update_counter);
    },
    initialize_simulation: function() {
      var image_preloader, leaf, leaf_start_x, leaf_start_y, mountain_background, mountain_base, mountain_game, night_background, sim_ground_level, sim_peak, sim_update_listener, sprite_data, sprite_sheet;
      mountain_game = this.get('mountain_game');
      leaf_start_x = this.get('leaf_start_x');
      leaf_start_y = this.get('sim_ground_level');
      image_preloader = this.get('image_preloader');
      sim_ground_level = this.get('sim_ground_level');
      sim_peak = this.get('sim_peak');
      createjs.Ticker.addEventListener("tick", mountain_game);
      sim_update_listener = createjs.Ticker.addEventListener("tick", (function(event) {
        return this.simulation_update(event);
      }).bind(this));
      this.set('sim_update_listener', sim_update_listener);
      mountain_background = new createjs.Bitmap(image_preloader.getResult('background'));
      mountain_base = new createjs.Bitmap(image_preloader.getResult('mountain_base'));
      mountain_game.addChild(mountain_background);
      mountain_game.addChild(mountain_base);
      sprite_data = {
        images: [image_preloader.getResult('leaf_sheet')],
        frames: {
          width: 30,
          height: 30
        },
        animations: {
          roll: {
            frames: [0, 1, 2, 3]
          },
          inverted_roll: {
            frames: [3, 2, 1, 0]
          }
        },
        framerate: 4
      };
      sprite_sheet = new createjs.SpriteSheet(sprite_data);
      leaf = new createjs.Sprite(sprite_sheet);
      leaf.x = leaf_start_x;
      leaf.y = leaf_start_y;
      leaf.regX = leaf.getBounds().width / 2;
      leaf.regY = leaf.getBounds().height / 2;
      leaf.addEventListener('animationend', (function(event) {
        return this.animation_manager(event);
      }).bind(this));
      mountain_game.snapToPixelEnabled = true;
      leaf.snapToPixel = true;
      mountain_game.leaf = leaf;
      this.set('leaf_obj', leaf);
      night_background = new createjs.Shape();
      night_background.graphics.beginFill("Black").drawRect(0, 0, mountain_game.getBounds().width, mountain_game.getBounds().height);
      night_background.alpha = 0;
      this.set('night_overlay_obj', night_background);
      mountain_game.addChild(leaf);
      mountain_game.addChild(night_background);
      this.initialize_sound(mountain_game, false);
      return createjs.Tween.get(mountain_game).to({
        alpha: 1
      }, 1000);
    },
    initialize_sound: function(game, horizontal) {
      var background_bar, background_graphic, foreground_bar, foreground_graphic, sound_bar_container, speaker_image, speaker_image_container, speaker_mute_image;
      game.enableMouseOver(10);
      sound_bar_container = new createjs.Container();
      speaker_image_container = new createjs.Container();
      speaker_image = new createjs.Bitmap(this.get('image_preloader').getResult('speaker'));
      speaker_mute_image = new createjs.Bitmap(this.get('image_preloader').getResult('speaker_mute'));
      speaker_mute_image.x = -6;
      speaker_mute_image.alpha = 0;
      background_graphic = new createjs.Graphics().beginFill("#000000").drawRect(0, 0, 10, 100);
      foreground_graphic = new createjs.Graphics().beginFill("#FFFFFF").drawRect(0, 0, 10, 100);
      background_bar = new createjs.Shape(background_graphic);
      foreground_bar = new createjs.Shape(foreground_graphic);
      background_bar.alpha = .7;
      background_bar.regX = 10 / 2;
      foreground_bar.regX = 10 / 2;
      background_bar.regY = 100;
      foreground_bar.regY = 100;
      background_bar.x = 13;
      foreground_bar.x = 13;
      background_bar.y = speaker_image.y - 10;
      foreground_bar.y = speaker_image.y - 10;
      if (horizontal != null) {
        if (horizontal) {
          background_bar.rotation = 90;
          foreground_bar.rotation = 90;
        }
      }
      speaker_image_container.addChild(speaker_image);
      speaker_image_container.addChild(speaker_mute_image);
      sound_bar_container.addChild(speaker_image_container);
      sound_bar_container.addChild(background_bar);
      sound_bar_container.addChild(foreground_bar);
      game.addChild(sound_bar_container);
      sound_bar_container.x = game.getBounds().width - (sound_bar_container.getBounds().width + 15);
      sound_bar_container.y = game.getBounds().height - (sound_bar_container.getBounds().height + 15);
      speaker_image_container.addEventListener('click', (function(event) {
        this.toggleProperty('muted');
        if (this.get('muted')) {
          speaker_mute_image.alpha = 1;
          return speaker_image.alpha = 0;
        } else {
          speaker_mute_image.alpha = 0;
          return speaker_image.alpha = 1;
        }
      }).bind(this));
      sound_bar_container.addEventListener('rollover', (function(event) {
        return createjs.Tween.get(sound_bar_container).to({
          alpha: 0.9
        }, 250);
      }));
      sound_bar_container.addEventListener('rollout', (function(event) {
        return createjs.Tween.get(sound_bar_container).to({
          alpha: 0.3
        }, 250);
      }));
      foreground_bar.addEventListener('pressmove', (function(event) {
        var foreground_bottom, foreground_top;
        foreground_top = sound_bar_container.y;
        foreground_bottom = sound_bar_container.y - 100;
        foreground_bar.scaleY = (100 - (event.stageY - foreground_bottom)) / 100;
        if (foreground_bar.scaleY > 1) {
          foreground_bar.scaleY = 1;
        }
        if (this.get('muted') === false) {
          return createjs.Sound.volume = foreground_bar.scaleY;
        }
      }).bind(this));
      background_bar.addEventListener('pressmove', (function(event) {
        var foreground_bottom, foreground_top;
        foreground_top = sound_bar_container.y;
        foreground_bottom = sound_bar_container.y - 100;
        foreground_bar.scaleY = (100 - (event.stageY - foreground_bottom)) / 100;
        if (foreground_bar.scaleY > 1) {
          foreground_bar.scaleY = 1;
        }
        if (this.get('muted') === false) {
          return createjs.Sound.volume = foreground_bar.scaleY;
        }
      }).bind(this));
      foreground_bar.addEventListener('mousedown', (function(event) {
        var foreground_bottom, foreground_top;
        foreground_top = sound_bar_container.y;
        foreground_bottom = sound_bar_container.y - 100;
        foreground_bar.scaleY = (100 - (event.stageY - foreground_bottom)) / 100;
        if (foreground_bar.scaleY > 1) {
          foreground_bar.scaleY = 1;
        }
        if (this.get('muted') === false) {
          return createjs.Sound.volume = foreground_bar.scaleY;
        }
      }).bind(this));
      background_bar.addEventListener('mousedown', (function(event) {
        var foreground_bottom, foreground_top;
        foreground_top = sound_bar_container.y;
        foreground_bottom = sound_bar_container.y - 100;
        foreground_bar.scaleY = (100 - (event.stageY - foreground_bottom)) / 100;
        if (foreground_bar.scaleY > 1) {
          foreground_bar.scaleY = 1;
        }
        if (this.get('muted') === false) {
          return createjs.Sound.volume = foreground_bar.scaleY;
        }
      }).bind(this));
      sound_bar_container.alpha = .3;
      return this.set('volume_bar', foreground_bar);
    },
    animation_manager: function(event) {
      var animation, leaf_obj;
      leaf_obj = this.get('leaf_obj');
      animation = Math.floor((Math.random() * 2) + 1);
      if (animation === 1) {
        return leaf_obj.play('roll');
      } else {
        return leaf_obj.play('inverted_roll');
      }
    },
    animate_clouds: function(cur_altitude, leaf_obj) {
      var base_cloud_array, cloud, cloud_array, cloud_base, cloud_base_y, cloud_height, cloud_width, first_cloud, image_preloader, leaf_cloud, leaf_cloud_array, left_cloud, mountain_game, next_cloud_y, right_cloud, sim_peak;
      cloud_height = this.get('cloud_height');
      mountain_game = this.get('mountain_game');
      cloud_base = this.get('cloud_base');
      base_cloud_array = this.get('base_cloud_array');
      leaf_cloud_array = this.get('leaf_cloud_array');
      cloud_array = this.get('cloud_array');
      image_preloader = this.get('image_preloader');
      sim_peak = this.get('sim_peak');
      cloud_base_y = this.get('cloud_base_y');
      next_cloud_y = this.get('next_cloud_y');
      if (leaf_obj.y <= next_cloud_y || cloud_base === 'Clear') {
        base_cloud_array.forEach((function(_this) {
          return function(base_cloud) {
            var new_cloud;
            if (base_cloud_array.indexOf(base_cloud) >= base_cloud_array.get('length') - 5) {
              new_cloud = new createjs.Bitmap(image_preloader.getResult('cloud_2'));
              new_cloud.regX = new_cloud.getBounds().width / 2;
              new_cloud.regY = new_cloud.getBounds().height / 2;
              new_cloud.alpha = 0;
              if (base_cloud_array.indexOf(base_cloud) >= base_cloud_array.length - 1) {
                new_cloud.x = base_cloud.x + Math.floor((Math.random() * 45) - 22);
                new_cloud.y = base_cloud.y - base_cloud.getBounds().height / 2;
              } else {
                if (Math.floor(Math.random() * 2) === 0) {
                  new_cloud.x = base_cloud.x + Math.floor((Math.random() * 45) - 22) + (10 * base_cloud_array.length);
                  new_cloud.y = next_cloud_y + Math.floor((Math.random() * 45) - 22);
                } else {
                  new_cloud.x = base_cloud.x - Math.floor((Math.random() * 45) - 22) + (10 * base_cloud_array.length);
                  new_cloud.y = cloud_base_y - Math.floor(Math.random() * (cloud_base_y - leaf_obj.y));
                }
              }
              new_cloud.rotation = Math.floor(Math.random() * 360);
              cloud_array.pushObject(new_cloud);
              mountain_game.addChild(new_cloud);
              mountain_game.setChildIndex(new_cloud, 4);
              return createjs.Tween.get(new_cloud).to({
                alpha: 1
              }, 500);
            }
          };
        })(this));
        leaf_cloud_array.forEach((function(_this) {
          return function(base_cloud) {
            var new_cloud;
            if (leaf_cloud_array.indexOf(base_cloud) >= leaf_cloud_array.get('length') - 5) {
              new_cloud = new createjs.Bitmap(image_preloader.getResult('cloud_2'));
              new_cloud.regX = new_cloud.getBounds().width / 2;
              new_cloud.regY = new_cloud.getBounds().height / 2;
              new_cloud.alpha = 0;
              if (leaf_cloud_array.indexOf(base_cloud) >= leaf_cloud_array.length - 1) {
                new_cloud.x = base_cloud.x + Math.floor((Math.random() * 45) - 22);
                new_cloud.y = base_cloud.y - base_cloud.getBounds().height / 2;
              } else {
                if (Math.floor(Math.random() * 2) === 0) {
                  new_cloud.x = base_cloud.x + Math.floor((Math.random() * 45) - 22) + (10 * leaf_cloud_array.length);
                  new_cloud.y = next_cloud_y + Math.floor((Math.random() * 45) - 22);
                } else {
                  new_cloud.x = base_cloud.x - Math.floor((Math.random() * 45) - 22) + (10 * leaf_cloud_array.length);
                  new_cloud.y = cloud_base_y - Math.floor(Math.random() * (cloud_base_y - leaf_obj.y));
                }
              }
              new_cloud.rotation = Math.floor(Math.random() * 360);
              cloud_array.pushObject(new_cloud);
              mountain_game.addChild(new_cloud);
              mountain_game.setChildIndex(new_cloud, 4);
              return createjs.Tween.get(new_cloud).to({
                alpha: 1
              }, 500);
            }
          };
        })(this));
        cloud = new createjs.Bitmap(image_preloader.getResult("cloud_2"));
        cloud.x = -100;
        cloud.alpha = 0;
        cloud.regX = cloud.getBounds().width / 2;
        cloud.regY = cloud.getBounds().height / 2;
        if (Math.floor((Math.random() * 2) + 1) === 2) {
          cloud.rotation = 180;
          mountain_game.setChildIndex(cloud, 2);
        } else {
          mountain_game.setChildIndex(cloud, 3);
        }
        mountain_game.addChild(cloud);
        if (cloud_base === 'Clear') {
          this.set('first_cloud', cloud);
          this.set('cloud_base_y', leaf_obj.y);
          this.set('cloud_base', cur_altitude);
          this.play_thunder();
          this.transition_storm();
        }
        first_cloud = this.get('first_cloud');
        cloud_base_y = this.get('cloud_base_y');
        cloud_width = first_cloud.getBounds().width;
        cloud_height = first_cloud.getBounds().height;
        if (base_cloud_array.length !== 0) {
          left_cloud = base_cloud_array.get('firstObject');
          right_cloud = base_cloud_array.get('lastObject');
        }
        if (cloud === first_cloud) {
          cloud.x = leaf_obj.x;
          cloud.y = leaf_obj.y;
        } else if (left_cloud.x - cloud_width > 0 && right_cloud.x + cloud_width / 2 < this.calc_x(cloud_base_y)) {
          if (Math.floor(Math.random() * 2) === 0) {
            cloud.x = right_cloud.x + cloud_width - (Math.floor(Math.random() * 10) + 10);
            cloud.y = cloud_base_y + (Math.floor(Math.random() * 15) - 8);
          } else {
            cloud.x = left_cloud.x - cloud_width + (Math.floor(Math.random() * 10) + 10);
            cloud.y = cloud_base_y + (Math.floor(Math.random() * 15) - 8);
          }
        } else if (left_cloud.x - cloud_width > 0) {
          cloud.x = left_cloud.x - cloud_width + (Math.floor(Math.random() * 10) + 10);
          cloud.y = cloud_base_y + (Math.floor(Math.random() * 15) - 8);
        } else if (right_cloud.x + cloud_width < this.calc_x(cloud_base_y)) {
          cloud.x = right_cloud.x + cloud_width - (Math.floor(Math.random() * 10) + 10);
          cloud.y = cloud_base_y + (Math.floor(Math.random() * 15) - 8);
        } else {
          cloud.x = leaf_obj.x + (Math.floor(Math.random() * 15) - 10);
          cloud.y = right_cloud.y - (cloud_height - (Math.floor(Math.random() * 15) - 10));
        }
        if (cloud === first_cloud) {
          base_cloud_array.pushObject(cloud);
        } else {
          if (cloud.x < left_cloud.x) {
            base_cloud_array.splice(0, 0, cloud);
          } else if (cloud.x > right_cloud.x) {
            base_cloud_array.pushObject(cloud);
          }
        }
        leaf_cloud = new createjs.Bitmap(image_preloader.getResult('cloud_2'));
        leaf_cloud.regX = leaf_cloud.getBounds().width / 2;
        leaf_cloud.regY = leaf_cloud.getBounds().height / 2;
        leaf_cloud.alpha = 0;
        leaf_cloud.x = leaf_obj.x + (Math.floor(Math.random() * 15) - 8);
        leaf_cloud.y = leaf_obj.y;
        leaf_cloud_array.pushObject(leaf_cloud);
        mountain_game.addChild(leaf_cloud);
        mountain_game.setChildIndex(leaf_cloud, 2);
        cloud.rotation = Math.floor(Math.random() * 180);
        this.set('base_cloud_array', base_cloud_array);
        this.set('leaf_cloud_array', leaf_cloud_array);
        createjs.Tween.get(leaf_cloud).to({
          alpha: 1
        }, 500);
        createjs.Tween.get(cloud).to({
          alpha: 1
        }, 500);
        return this.set('next_cloud_y', leaf_obj.y - cloud_height + 30);
      }
    },
    play_wind: function() {
      return createjs.Sound.play('wind');
    },
    play_thunder: function() {
      return createjs.Sound.play('thunder');
    },
    transition_storm: function() {
      var mountain_game, night_overlay;
      night_overlay = this.get('night_overlay_obj');
      mountain_game = this.get('mountain_game');
      return createjs.Tween.get(night_overlay).to({
        alpha: 0
      }, 500);
    },
    initialize_graph: function() {
      var altitude_chart, altitude_chart_data, altitude_default_graph, altitude_graph_0, cur_trial_data, default_altitude_chart_data, default_humidity_chart_data, humidity_chart, humidity_chart_data, humidity_graph_0, next_graph_color, obj, phase_line, trial_num, user_indicator;
      trial_num = this.get('trial_num');
      default_humidity_chart_data = this.get('default_humidity_chart_data').slice();
      default_altitude_chart_data = this.get('default_altitude_chart_data').slice();
      this.set('trial_results', ember['default'].makeArray());
      this.set('humidity_chart_data', default_humidity_chart_data);
      this.set('altitude_chart_data', default_altitude_chart_data);
      humidity_chart_data = this.get('humidity_chart_data');
      altitude_chart_data = this.get('altitude_chart_data');
      humidity_chart = new AmCharts.AmXYChart();
      altitude_chart = new AmCharts.AmXYChart();
      humidity_chart.dataProvider = humidity_chart_data;
      humidity_chart.valueAxes = [
        {
          'id': 'y-axis',
          'titleFontSize': 14,
          'titleBold': false,
          'title': 'Vapor pressure (mb)',
          'maximum': 50,
          'minimum': 0,
          'autoGridCount': false,
          'gridCount': 5
        }, {
          'id': 'x-axis',
          'titleFontSize': 14,
          'titleBold': false,
          'title': 'Temperature (°C)',
          'position': 'bottom',
          'maximum': 30,
          'minimum': -20,
          'autoGridCount': false,
          'gridCount': 5
        }
      ];
      humidity_chart.fontFamily = 'omnes-pro';
      humidity_chart.creditsPosition = 'top-left';
      altitude_chart.dataProvider = altitude_chart_data;
      altitude_chart.valueAxes = [
        {
          'id': 'y-axis',
          'titleFontSize': 14,
          'titleBold': false,
          'title': 'Altitude (km)',
          'maximum': 5,
          'minimum': 0,
          'autoGridCount': false,
          'gridCount': 5
        }, {
          'id': 'x-axis',
          'titleFontSize': 14,
          'titleBold': false,
          'title': 'Temperature (°C)',
          'position': 'bottom',
          'maximum': 30,
          'minimum': -20,
          'autoGridCount': false,
          'gridCount': 5
        }
      ];
      altitude_chart.fontFamily = 'omnes-pro';
      altitude_chart.creditsPosition = 'top-left';
      next_graph_color = this.get('next_graph_color');
      phase_line = new AmCharts.AmGraph();
      phase_line.xField = 'temp';
      phase_line.yField = 'phase_line_vapor_humidity';
      phase_line.title = 'Dew Point';
      phase_line.type = 'smoothedLine';
      phase_line.lineColor = '#833da1';
      user_indicator = new AmCharts.AmGraph();
      user_indicator.xField = 'start_temp';
      user_indicator.yField = 'start_humidity';
      user_indicator.customBullet = '/assets/images/simulations/mountain/rect_leaf.png';
      user_indicator.customBulletField = 'customBullet';
      user_indicator.bulletSize = 20;
      altitude_default_graph = new AmCharts.AmGraph();
      altitude_default_graph.xField = 'temp';
      altitude_default_graph.yField = 'altitude';
      altitude_graph_0 = new AmCharts.AmGraph();
      altitude_graph_0.xField = 'dynamic_temp_0';
      altitude_graph_0.yField = 'dynamic_altitude_0';
      altitude_graph_0.title = 'Altitude';
      altitude_graph_0.lineColor = next_graph_color;
      humidity_graph_0 = new AmCharts.AmGraph();
      humidity_graph_0.xField = 'dynamic_temp_0';
      humidity_graph_0.yField = 'dynamic_humidity_0';
      humidity_graph_0.title = 'Dynamic Graph';
      humidity_graph_0.lineColor = next_graph_color;
      this.set('humidity_graph_0', humidity_graph_0);
      this.set('altitude_graph_0', altitude_graph_0);
      humidity_chart.addGraph(phase_line);
      humidity_chart.addGraph(user_indicator);
      humidity_chart.addGraph(humidity_graph_0);
      altitude_chart.addGraph(altitude_graph_0);
      altitude_chart.addGraph(altitude_default_graph);
      this.set('humidity_chart', humidity_chart);
      this.set('altitude_chart', altitude_chart);
      humidity_chart.write('chart-column1');
      altitude_chart.write('chart-column2');
      humidity_chart.validateData();
      altitude_chart.validateData();
      cur_trial_data = this.get('cur_trial_data');
      trial_num = this.get('trial_num');
      cur_trial_data = (
        obj = {},
        obj["" + trial_num] = {},
        obj
      );
      cur_trial_data["" + trial_num]["color"] = next_graph_color;
      return this.set('cur_trial_data', cur_trial_data);
    },
    calc_x: function(y) {
      return (470 - y) / 1.02;
    },
    add_first_point: function() {
      var altitude_chart_data, humidity_chart_data, new_altitude_data_point, new_humidity_data_point, obj, obj1, start_altitude, start_humidity, start_temp, trial_num;
      humidity_chart_data = this.get('humidity_chart_data');
      altitude_chart_data = this.get('altitude_chart_data');
      start_temp = this.get('start_temp');
      start_humidity = this.get('start_humidity');
      start_altitude = this.get('start_altitude');
      trial_num = this.get('trial_num');
      new_altitude_data_point = (
        obj = {},
        obj["dynamic_temp_" + trial_num] = start_temp,
        obj["dynamic_altitude_" + trial_num] = start_altitude,
        obj
      );
      new_humidity_data_point = (
        obj1 = {},
        obj1["dynamic_temp_" + trial_num] = start_temp,
        obj1["dynamic_humidity_" + trial_num] = start_humidity,
        obj1
      );
      humidity_chart_data.pushObject(new_humidity_data_point);
      return altitude_chart_data.pushObject(new_altitude_data_point);
    },
    add_dynamic_graphs: function(trial_num) {
      var altitude_chart, cur_trial_data, humidity_chart, next_graph_color, obj, temp_altitude, temp_humidity;
      humidity_chart = this.get('humidity_chart');
      altitude_chart = this.get('altitude_chart');
      trial_num = this.get('trial_num');
      cur_trial_data = {};
      next_graph_color = this.get('next_graph_color');
      temp_altitude = this.get("altitude_graph_" + trial_num);
      temp_humidity = this.get("humidity_graph_" + trial_num);
      if (ember['default'].isNone(temp_humidity) && ember['default'].isNone(temp_altitude)) {
        ember['default'].defineProperty(this, "humidity_graph_" + trial_num, void 0, new AmCharts.AmGraph());
        ember['default'].defineProperty(this, "altitude_graph_" + trial_num, void 0, new AmCharts.AmGraph());
        temp_altitude = this.get("altitude_graph_" + trial_num);
        temp_humidity = this.get("humidity_graph_" + trial_num);
        humidity_chart.addGraph(temp_humidity);
        altitude_chart.addGraph(temp_altitude);
      }
      temp_humidity.xField = "dynamic_temp_" + trial_num;
      temp_humidity.yField = "dynamic_humidity_" + trial_num;
      temp_humidity.title = "Vapor Humidity Trial #" + trial_num;
      temp_humidity.lineColor = next_graph_color;
      temp_altitude.xField = "dynamic_temp_" + trial_num;
      temp_altitude.yField = "dynamic_altitude_" + trial_num;
      temp_altitude.title = "Altitude Trial #" + trial_num;
      temp_altitude.lineColor = next_graph_color;
      cur_trial_data = (
        obj = {},
        obj["" + trial_num] = {},
        obj
      );
      cur_trial_data["" + trial_num]["color"] = next_graph_color;
      return this.set('cur_trial_data', cur_trial_data);
    },
    add_graph_coords: function(step_data_obj, trial_num) {
      var altitude_chart, altitude_chart_data, altitude_max, altitude_min, chart_step_data, humidity_chart, humidity_chart_data, humidity_max, humidity_min, new_altitude_data_point, new_humidity_data_point, obj, temp_max, temp_min;
      humidity_chart_data = this.get('humidity_chart_data');
      humidity_chart = this.get('humidity_chart');
      altitude_chart_data = this.get('altitude_chart_data');
      altitude_chart = this.get('altitude_chart');
      humidity_max = this.get('graph_humidity_max');
      humidity_min = this.get('graph_humidity_min');
      temp_max = this.get('graph_temp_max');
      temp_min = this.get('graph_temp_min');
      altitude_max = this.get('graph_altitude_max');
      altitude_min = this.get('graph_altitude_min');
      chart_step_data = this.get('chart_step_data');
      chart_step_data.pushObject((
        obj = {},
        obj["" + trial_num] = step_data_obj,
        obj
      ));
      if (step_data_obj.temp >= temp_min && step_data_obj.temp <= temp_max) {
        if (step_data_obj.humidity >= humidity_min && step_data_obj.humidity <= humidity_max) {
          new_humidity_data_point = {};
          new_humidity_data_point["dynamic_temp_" + trial_num] = step_data_obj.temp;
          new_humidity_data_point["dynamic_humidity_" + trial_num] = step_data_obj.humidity;
          humidity_chart_data.pushObject(new_humidity_data_point);
          if (this.get('dynamic_graphing')) {
            humidity_chart.validateData();
          }
        }
        if (step_data_obj.altitude >= altitude_min && step_data_obj.altitude <= altitude_max) {
          new_altitude_data_point = {};
          new_altitude_data_point["dynamic_temp_" + trial_num] = step_data_obj.temp;
          new_altitude_data_point["dynamic_altitude_" + trial_num] = step_data_obj.altitude;
          altitude_chart_data.pushObject(new_altitude_data_point);
          if (this.get('dynamic_graphing')) {
            return altitude_chart.validateData();
          }
        }
      }
    },
    actions: {
      pause_simulation: function() {
        var game_tick_listener, sim_update_listener;
        if (this.get('sim_running')) {
          game_tick_listener = this.get('game_tick_listener');
          sim_update_listener = this.get('sim_update_listener');
          createjs.Ticker.removeEventListener('tick', game_tick_listener);
          createjs.Ticker.removeEventListener('tick', sim_update_listener);
          return this.set('sim_paused', true);
        }
      },
      resume_simulation: function() {
        var game_tick_listener, sim_update_listener;
        if (this.get('sim_running')) {
          game_tick_listener = this.get('game_tick_listener');
          sim_update_listener = this.get('sim_update_listener');
          createjs.Ticker.addEventListener('tick', game_tick_listener);
          createjs.Ticker.addEventListener('tick', sim_update_listener);
          return this.set('sim_paused', false);
        }
      },
      reset_simulation: function() {
        var base_cloud_array, cloud_array, leaf_cloud_array, leaf_obj, leaf_start_x, leaf_start_y, mountain_game, night_overlay, rain_array, trial_num, trial_results;
        if (this.get('need_reset')) {
          leaf_obj = this.get('leaf_obj');
          leaf_start_x = this.get('leaf_start_x');
          leaf_start_y = this.get('sim_ground_level');
          trial_num = this.get('trial_num') + 1;
          trial_results = this.get('trial_results');
          mountain_game = this.get('mountain_game');
          base_cloud_array = this.get('base_cloud_array');
          leaf_cloud_array = this.get('leaf_cloud_array');
          cloud_array = this.get('cloud_array');
          rain_array = this.get('rain_array');
          night_overlay = this.get('night_overlay_obj');
          this.set('trial_num', trial_num);
          this.set('sim_started', false);
          createjs.Tween.get(night_overlay).to({
            alpha: 0
          }, 500);
          base_cloud_array.forEach((function(_this) {
            return function(cloud) {
              return createjs.Tween.get(cloud).to({
                alpha: 0
              }, 500).call(function() {
                mountain_game.removeChild(cloud);
                return base_cloud_array.removeObject(cloud);
              });
            };
          })(this));
          cloud_array.forEach((function(_this) {
            return function(cloud) {
              return createjs.Tween.get(cloud).to({
                alpha: 0
              }, 500).call(function() {
                mountain_game.removeChild(cloud);
                return cloud_array.removeObject(cloud);
              });
            };
          })(this));
          leaf_cloud_array.forEach((function(_this) {
            return function(cloud) {
              return createjs.Tween.get(cloud).to({
                alpha: 0
              }, 500).call(function() {
                mountain_game.removeChild(cloud);
                return leaf_cloud_array.removeObject(cloud);
              });
            };
          })(this));
          rain_array.forEach((function(_this) {
            return function(raindrop) {
              return createjs.Tween.get(raindrop).to({
                alpha: 0
              }, 500).call(function() {
                mountain_game.removeChild(raindrop);
                return rain_array.removeObject(raindrop);
              });
            };
          })(this));
          createjs.Tween.get(leaf_obj).to({
            alpha: 0
          }, 500).call(function() {
            leaf_obj.x = leaf_start_x;
            leaf_obj.y = leaf_start_y;
            return createjs.Tween.get(leaf_obj).to({
              alpha: 1
            }, 500);
          });
          this.reset_properties_to_default();
          if (trial_num > 0) {
            this.set('start_temp', trial_results.get("lastObject." + (trial_num - 1) + ".start_temp"));
            this.set('start_humidity', trial_results.get("lastObject." + (trial_num - 1) + ".start_humidity"));
          }
          return this.add_dynamic_graphs(this.get('trial_num'));
        }
      },
      step_humidity_slider: function(bool) {
        var slider_step_size, start_humidity, start_humidity_max, start_humidity_min;
        if (this.get('sim_running') === false) {
          slider_step_size = this.get('slider_step_size');
          start_humidity = this.get('start_humidity');
          if (bool) {
            start_humidity_max = this.get('start_humidity_max');
            if (start_humidity < start_humidity_max) {
              return this.set('start_humidity', start_humidity + slider_step_size);
            }
          } else {
            start_humidity_min = this.get('start_humidity_min');
            if (start_humidity > start_humidity_min) {
              return this.set('start_humidity', start_humidity - slider_step_size);
            }
          }
        }
      },
      step_temp_slider: function(bool) {
        var slider_step_size, start_temp, start_temp_max, start_temp_min;
        if (this.get('sim_running') === false) {
          slider_step_size = this.get('slider_step_size');
          start_temp = this.get('start_temp');
          if (bool) {
            start_temp_max = this.get('start_temp_max');
            if (start_temp < start_temp_max) {
              return this.set('start_temp', start_temp + slider_step_size);
            }
          } else {
            start_temp_min = this.get('start_temp_min');
            if (start_temp > start_temp_min) {
              return this.set('start_temp', start_temp - slider_step_size);
            }
          }
        }
      },
      clear_graphs: function() {
        var altitude_chart, humidity_chart, new_altitude_array, new_humidity_array;
        if (this.get('simulation_loaded')) {
          humidity_chart = this.get("humidity_chart");
          altitude_chart = this.get("altitude_chart");
          new_humidity_array = this.get('default_humidity_chart_data').slice();
          new_altitude_array = this.get('default_altitude_chart_data').slice();
          this.set('humidity_chart_data', null);
          this.set('altitude_chart_data', null);
          this.set('humidity_chart_data', new_humidity_array);
          this.set('altitude_chart_data', new_altitude_array);
          this.set('trial_num', -1);
          this.set('trial_results', null);
          this.set('trial_results', []);
          humidity_chart.dataProvider = this.get('humidity_chart_data');
          altitude_chart.dataProvider = this.get('altitude_chart_data');
          humidity_chart.validateNow();
          altitude_chart.validateNow();
          humidity_chart.validateData();
          altitude_chart.validateData();
          return this.send('reset_simulation');
        }
      },
      start_simulation: function() {
        var humidity_chart, humidity_chart_data, start_humidity, start_temp;
        if (this.get('simulation_loaded')) {
          humidity_chart_data = this.get('humidity_chart_data');
          humidity_chart = this.get('humidity_chart');
          start_temp = this.get('start_temp');
          start_humidity = this.get('start_humidity');
          humidity_chart_data.find((function(element) {
            if (element.start_temp != null) {
              element.dynamic_temp = start_temp;
              element.dynamic_humidity = start_humidity;
              return humidity_chart.validateData();
            }
          }));
          this.collect_trial_start_data();
          this.play_wind();
          this.set('sim_running', true);
          return this.get('leaf_obj').play('roll');
        }
      },
      toggle_graph_visible: function(visible, trial_num) {
        var altitude_chart, altitude_graph, humidity_chart, humidity_graph;
        altitude_chart = this.get('altitude_chart');
        humidity_chart = this.get('humidity_chart');
        altitude_graph = this.get("altitude_graph_" + trial_num);
        humidity_graph = this.get("humidity_graph_" + trial_num);
        if (visible) {
          altitude_chart.addGraph(altitude_graph);
          humidity_chart.addGraph(humidity_graph);
        } else {
          altitude_chart.removeGraph(altitude_graph);
          humidity_chart.removeGraph(humidity_graph);
        }
        altitude_chart.validateData();
        return humidity_chart.validateData();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});