define('client/views/select/validated_select', ['exports', 'ember', 'totem-application/mixins/validated_field'], function (exports, ember, validations_mixin) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].View.extend(validations_mixin['default'], {
    templateName: 'select/validated_select'
  });

  exports['default'] = ___DefaultExportObject___;;

});