define('client/helpers/radio-button', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___, radio_button;

  radio_button = ember['default'].View.extend({
    tagName: 'input',
    type: 'radio',
    attributeBindings: ['type', 'htmlChecked:checked', 'value', 'name'],
    htmlChecked: (function() {
      return this.get('value') === this.get('checked');
    }).property('value', 'checked'),
    change: function() {
      return this.set('checked', this.get('value'));
    }
  });

  ___DefaultExportObject___ = ember['default'].Handlebars.makeViewHelper(radio_button);

  exports['default'] = ___DefaultExportObject___;;

});