define('client/users/password/new/controller', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Controller.extend({
    c_user_password_new: 'thinkspace/common/user/password/new',
    actions: {
      goto_users_password_confirmation: function() {
        return this.transitionToRoute('users/password.confirmation');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});