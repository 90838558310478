define('client/components/thinkspace/casespace/phase/submit', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    tvo: ember['default'].inject.service(),
    phase_manager: ember['default'].inject.service(),
    is_view_only: ember['default'].computed.bool('phase_manager.is_view_only'),
    is_edit: ember['default'].computed.bool('tvo.status.is_edit'),
    has_responses: ember['default'].computed.bool('tvo.status.elements'),
    valid_elements_count: ember['default'].computed.reads('tvo.status.elements.count.valid'),
    invalid_elements_count: ember['default'].computed.reads('tvo.status.elements.count.invalid'),
    submit_messages_title: ember['default'].computed.reads('tvo.hash.phase_submit_messages_title'),
    submit_messages: ember['default'].computed.reads('tvo.hash.phase_submit_messages'),
    actions: {
      submit: function() {
        return this.sendAction('submit');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});