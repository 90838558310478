define('client/components/thinkspace/common/time_picker', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    ttz: ember['default'].inject.service(),
    attributeBindings: ['tabindex'],
    tabindex: 1,
    show_icon: true,
    friendly_value: ember['default'].computed('value', function() {
      var value;
      value = this.get('value');
      if (!ember['default'].isPresent(value)) {
        return null;
      }
      return this.get('ttz').format(value, {
        format: 'h:mm a'
      });
    }),
    value_observer: ember['default'].observer('value', function() {
      return this.set_time();
    }),
    focusIn: function(e) {
      var picker;
      picker = this.get_time_picker();
      picker.open();
      return e.stopPropagation();
    },
    click: function(e) {
      var picker;
      picker = this.get_time_picker();
      picker.open();
      e.stopPropagation();
      return e.preventDefault();
    },
    didInsertElement: function() {
      var $input, options;
      $input = this.get_picker_input();
      options = this.get('time_options') || {};
      options.onClose = ((function(_this) {
        return function() {
          return _this.select_time();
        };
      })(this));
      $input.pickatime(options);
      return this.set_time();
    },
    set_time: function() {
      var value, value_type;
      value = this.get('value');
      if (!ember['default'].isPresent(value)) {
        return;
      }
      value_type = typeof value;
      if (value_type === 'string') {
        value = new Date(value);
      }
      return this.get_time_picker().set('select', value);
    },
    select_time: function() {
      var time;
      time = this.get_time_picker_time();
      return this.sendAction('select', time);
    },
    get_picker_input: function() {
      return this.$('.ts-picker_input');
    },
    get_time_picker: function() {
      return this.get_picker_input().pickatime('picker');
    },
    get_time_picker_time: function() {
      return this.get_time_picker().get('select');
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});