define('client/components/thinkspace/observation_list/list/category_radio', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    category_id: ember['default'].computed.reads('category.id'),
    category_label: ember['default'].computed.reads('category.label'),
    is_checked: ember['default'].computed('input_value', function() {
      return this.get('input_value') === this.get('category_id');
    }),
    actions: {
      toggle_radio: function() {
        if (this.get('is_checked')) {
          return this.sendAction('uncheck');
        } else {
          return this.sendAction('check', this.get('category_id'));
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});