define('client/components/thinkspace/input_element/elements/standard_input', ['exports', 'ember', 'totem/ns', 'totem/mixins/validations', 'thinkspace-base/components/base'], function (exports, ember, ns, val_mixin, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend(val_mixin['default'], {
    classNames: ['thinkspace-ie'],
    classNameBindings: ['input_class_name'],
    tvo: ember['default'].inject.service(),
    init: function() {
      this._super();
      return this.get('tvo.status').register_validation('inputs', this, 'validate_input_saved');
    },
    input_class_name: ember['default'].computed('is_valid', 'totem_scope.is_view_only', function() {
      if (this.get('is_valid') || this.totem_scope.get('is_view_only')) {
        return null;
      }
      return 'thinkspace-ie_error';
    }),
    input_value: null,
    focusOut: function() {
      return this.set_status().then((function(_this) {
        return function() {
          return _this.save_response();
        };
      })(this), (function(_this) {
        return function() {};
      })(this));
    },
    save_response: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model;
          model = _this.get('model');
          model.set('value', _this.get('input_value'));
          _this.totem_scope.set_record_ownerable_attributes(model);
          return model.save().then(function() {
            _this.totem_messages.api_success({
              source: _this,
              model: model,
              action: 'save',
              i18n_path: ns['default'].to_o('response', 'save')
            });
            return resolve();
          }, function(error) {
            _this.totem_messages.api_failure(error, {
              source: _this,
              model: model
            });
            return reject(error);
          });
        };
      })(this));
    },
    set_status: function() {
      return this.get('status').validate(this);
    },
    didInsertElement: function() {
      this.set('input_value', this.get('model.value'));
      return this.set_status();
    },
    validate_input_saved: function(status) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model;
          status.set_is_valid(true);
          if (!_this.get('is_valid')) {
            return resolve();
          }
          model = _this.get('model');
          if (!(model.get('isDirty') && !model.get('isNew'))) {
            return resolve();
          }
          return _this.save_response().then(function() {
            return resolve();
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});