define('client/components/thinkspace/markup/library/edit/manager', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'totem/ds/associations', 'thinkspace-base/components/base'], function (exports, ember, ns, ajax, ta, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    library: null,
    close_action: null,
    library_empty: false,
    selected_tags: [],
    model: null,
    sort_text: [
      {
        display: 'Most Used'
      }, {
        display: 'Recently Used'
      }, {
        display: 'Recently Created'
      }, {
        display: 'A - Z'
      }
    ],
    selected_sort: 'Most Used',
    is_adding_category: false,
    is_adding_library_comment: false,
    all_selected: true,
    add_comment_class: 'keypress-comment-input',
    add_category_class: 'keypress-category-input',
    tags_component: null,
    new_comment_text: '',
    c_validated_input: ns['default'].to_p('common', 'shared', 'validated_input'),
    t_library_tagger: ns['default'].to_t('markup', 'library', 'edit', 'manager', 'tagger'),
    c_clickable_tag: ns['default'].to_p('markup', 'library', 'edit', 'manager', 'tag'),
    c_checkbox: ns['default'].to_p('common', 'shared', 'checkbox'),
    c_common_dropdown: ns['default'].to_p('common', 'dropdown'),
    c_library_tags: ns['default'].to_p('markup', 'library', 'edit', 'manager', 'tags'),
    c_library_comments: ns['default'].to_p('markup', 'library', 'edit', 'manager', 'comments'),
    library_all_tags: ember['default'].observer('model.all_tags', function() {
      var model;
      return model = this.get('model');
    }),
    sorted_comments: ember['default'].computed('selected_sort', 'model.comments.length', function() {
      var model, promise, selected_sort;
      selected_sort = this.get('selected_sort');
      model = this.get('model');
      if (ember['default'].isPresent(model)) {
        promise = new ember['default'].RSVP.Promise((function(_this) {
          return function(resolve, reject) {
            return model.get('comments').then(function(library_comments) {
              var sorted_arr;
              if (selected_sort === 'Most Used') {
                sorted_arr = library_comments.sortBy('uses');
                sorted_arr.reverse();
              } else if (selected_sort === 'Recently Used') {
                sorted_arr = library_comments.sortBy('last_used');
                sorted_arr.reverse();
              } else if (selected_sort === 'Recently Created') {
                sorted_arr = library_comments.sortBy('created_at');
                sorted_arr.reverse();
              } else if (selected_sort === 'A - Z') {
                sorted_arr = library_comments.sortBy('comment');
              }
              return resolve(sorted_arr);
            });
          };
        })(this));
        return ta['default'].PromiseArray.create({
          promise: promise
        });
      }
    }),
    sorted_and_filtered_comments: ember['default'].computed('sorted_comments.length', 'selected_tags.length', function() {
      var all_selected, model, promise, selected_tags, sorted_comments;
      sorted_comments = this.get('sorted_comments');
      model = this.get('model');
      selected_tags = this.get('selected_tags');
      all_selected = this.get('all_selected');
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var comments;
          comments = sorted_comments.filter(function(comment) {
            var has_tag, tags;
            tags = comment.get('all_tags');
            has_tag = false;
            if (all_selected) {
              has_tag = true;
            } else {
              selected_tags.forEach(function(tag) {
                if (tags.contains(tag)) {
                  return has_tag = true;
                }
              });
            }
            return has_tag;
          });
          return resolve(comments);
        };
      })(this));
      return ta['default'].PromiseArray.create({
        promise: promise
      });
    }),
    comments_obs: ember['default'].observer('model.comments.length', 'selected_tags.length', function() {
      var promise;
      if (ember['default'].isPresent(this.get('model'))) {
        return promise = new ember['default'].RSVP.Promise((function(_this) {
          return function(resolve, reject) {
            var all_selected, model, selected_tags;
            model = _this.get('model');
            selected_tags = _this.get('selected_tags');
            all_selected = _this.get('all_selected');
            if (ember['default'].isPresent(model)) {
              return model.get('comments').then(function(comments) {
                if (ember['default'].isEmpty(comments)) {
                  _this.set('library_empty', true);
                  return resolve();
                }
                if (ember['default'].isEmpty(selected_tags) && !all_selected) {
                  _this.set('library_empty', false);
                  return resolve();
                }
                comments = comments.filter(function(comment) {
                  var has_tag, tags;
                  tags = comment.get('all_tags');
                  has_tag = false;
                  if (all_selected) {
                    has_tag = true;
                  } else {
                    selected_tags.forEach(function(tag) {
                      if (tags.contains(tag)) {
                        return has_tag = true;
                      }
                    });
                  }
                  return has_tag;
                });
                _this.set('library_comments', comments);
                return resolve();
              }, function(error) {
                return reject(error);
              });
            } else {
              return reject();
            }
          };
        })(this));
      }
    }),
    all_tags_obs: ember['default'].observer('selected_tags.length', function() {
      var all_selected, selected_tags;
      selected_tags = this.get('selected_tags');
      all_selected = this.get('all_selected');
      if (ember['default'].isEmpty(selected_tags)) {
        return this.set('all_selected', true);
      } else if (all_selected) {
        return this.set('all_selected', false);
      }
    }),
    tags_did_change: function() {
      var component;
      component = this.get('tags_component');
      if (!ember['default'].isPresent(component)) {
        return;
      }
      return component.tags_did_change();
    },
    get_tag_query: function(model, action, all_tags, comment) {
      var query;
      return query = {
        model: model,
        id: model.get('id'),
        verb: 'put',
        action: action,
        data: {
          all_tags: all_tags,
          comment_id: comment.get('id')
        }
      };
    },
    keyPress: function(e) {
      if (ember['default'].isEqual(e.keyCode, 13)) {
        return this.send('add_library_comment');
      }
    },
    actions: {
      exit: function() {
        return window.history.back();
      },
      register_tags_component: function(component) {
        return this.set('tags_component', component);
      },
      toggle_add_comment: function() {
        return this.toggleProperty('is_adding_library_comment');
      },
      add_library_comment: function() {
        var comment_text, current_user, library_comment, library_comments, model;
        comment_text = this.get('new_comment_text');
        model = this.get('model');
        current_user = this.totem_scope.get_current_user_id();
        library_comments = this.get('library_comments');
        if (comment_text !== '' && (model != null)) {
          library_comment = this.totem_scope.get_store().createRecord(ns['default'].to_p('markup', 'library_comment'));
          library_comment.set('comment', comment_text);
          library_comment.set('library', model);
          library_comment.set('user_id', current_user);
          library_comment.set('uses', 0);
          library_comment.set('last_used', null);
          library_comment.save().then((function(_this) {
            return function(saved_comment) {
              _this.totem_messages.api_success({
                source: _this,
                model: library_comment,
                action: 'save',
                i18n_path: ns['default'].to_o('comment', 'save')
              });
              return _this.set('new_comment_text', '');
            };
          })(this));
        }
        return this.set('is_adding_library_comment', false);
      },
      select_tag: function(tag) {
        var selected_tags;
        selected_tags = this.get('selected_tags');
        if (selected_tags.contains(tag)) {
          return selected_tags.removeObject(tag);
        } else {
          return selected_tags.pushObject(tag);
        }
      },
      toggle_tag_selection: function(tag) {
        return this.send('select_tag', tag);
      },
      all_selected: function() {
        this.set('all_selected', true);
        return this.set('selected_tags', ember['default'].makeArray());
      },
      remove_comment_tag: function(comment, tag) {
        var all_tags, model, query;
        all_tags = comment.get('all_tags');
        model = this.get('model');
        if (all_tags.contains(tag)) {
          all_tags.removeObject(tag);
        }
        query = this.get_tag_query(model, 'remove_comment_tag', all_tags, comment);
        return ajax['default'].object(query).then((function(_this) {
          return function() {
            return _this.tags_did_change();
          };
        })(this));
      },
      add_comment_tag: function(comment, tag) {
        var all_tags, model, query;
        all_tags = comment.get('all_tags');
        model = this.get('model');
        if (!all_tags.contains(tag)) {
          all_tags.pushObject(tag);
          query = this.get_tag_query(model, 'add_comment_tag', all_tags, comment);
          return ajax['default'].object(query).then((function(_this) {
            return function() {
              return _this.tags_did_change();
            };
          })(this));
        }
      },
      select_sort: function(sort_type) {
        var key, results, value;
        results = [];
        for (key in sort_type) {
          value = sort_type[key];
          results.push(this.set('selected_sort', value));
        }
        return results;
      },
      close_library: function() {
        return this.sendAction('close_action');
      },
      confirm_category_add: function(category_name) {
        var model, query;
        model = this.get('model');
        if (category_name !== '') {
          query = {
            model: ns['default'].to_p('markup', 'library'),
            id: model.get('id'),
            verb: 'put',
            action: 'add_tag',
            data: {
              tag_name: category_name
            }
          };
          return ajax['default'].object(query).then((function(_this) {
            return function(payload) {
              return ajax['default'].normalize_and_push_payload('library', payload, {
                single: true
              });
            };
          })(this));
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});