define('client/components/thinkspace/diagnostic_path/path', ['exports', 'ember', 'totem/ns', 'thinkspace-diagnostic-path/path_manager', 'thinkspace-base/components/base'], function (exports, ember, ns, path_manager, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    classNames: ['ts-componentable', 'diag-path_'],
    all_collapsed: false,
    edit_visible: false,
    is_view_only: ember['default'].computed.reads('totem_scope.is_view_only'),
    ownerable_children: ember['default'].computed(function() {
      return this.get('tvo.helper').ownerable_view_association_promise_array(this, {
        association: 'children',
        ready: true
      });
    }),
    scoped_children: ember['default'].computed('ownerable_children.@each', function() {
      var records;
      records = this.get('ownerable_children');
      return records.filter((function(_this) {
        return function(record) {
          var id, o_id, o_type, type;
          type = record.get('ownerable_type');
          type = _this.totem_scope.standard_record_path(type);
          id = record.get('ownerable_id');
          o_type = _this.totem_scope.get_ownerable_type();
          o_id = _this.totem_scope.get_ownerable_id();
          return ember['default'].isEqual(type, o_type) && ember['default'].isEqual(id, o_id);
        };
      })(this));
    }),
    c_path_edit: ns['default'].to_p('diagnostic_path', 'path', 'edit'),
    c_path_item: ns['default'].to_p('diagnostic_path', 'path_item', 'show'),
    c_path_mechanism: ns['default'].to_p('diagnostic_path', 'path_item', 'mechanism'),
    tvo: ember['default'].inject.service(),
    ownerable_children_observer: ember['default'].observer('ownerable_children.isFulfilled', function() {
      var children, path;
      path = this.get('model');
      return children = path.get(ns['default'].to_p('path_items')).then((function(_this) {
        return function(path_items) {
          var length;
          length = path_items.get('length');
          if (length === 0) {
            return _this.add_default_mechanism();
          }
        };
      })(this));
    }),
    init: function() {
      this._super();
      this.get('tvo.helper').define_ready(this);
      return this.get('tvo.status').register_validation('diagnostic_path', this, 'validate_diagnostic_path');
    },
    didInsertElement: function() {
      this._super();
      if (this.get('is_view_only')) {
        return;
      }
      this.$('.diag-path_list').sortable({
        group: 'path-obs-list',
        clone: true,
        consume: true,
        exclude: '.sortable-exclude',
        component: this
      });
      return this.$('.diag-path_mechanism-list').sortable({
        group: 'path-obs-list',
        clone: true,
        consume: true,
        exclude: '.sortable-exclude',
        component: this,
        drop: false
      });
    },
    willDestroyElement: function() {
      this.$('.diag-path_list').sortable('destroy');
      return this.$('.diag-path_mechanism-list').sortable('destroy');
    },
    add_default_mechanism: function() {
      var event;
      event = this.get_root_mock_event(this.get_root_last_path_item());
      return this.dragend_new_mechanism(event);
    },
    get_root_list: function() {
      return this.$('.diag-path_list').first();
    },
    get_root_last_path_item: function() {
      return this.get_root_list().children('.diag-path_list-item').last();
    },
    get_root_mock_event: function(prev_item) {
      var event;
      return event = {
        dropped_container: {
          el: this.get_root_list(),
          prevItem: prev_item
        }
      };
    },
    dragend_process: function(event, type, id) {
      var path;
      path = this.get('model');
      this.set_is_saving();
      return path_manager['default'].dragend_new_diagnostic_path_item(path, event, type, id).then((function(_this) {
        return function() {
          return _this.reset_is_saving();
        };
      })(this));
    },
    dragend_move_diagnostic_path_items: function(event) {
      var path;
      path = this.get('model');
      this.set_is_saving();
      return path_manager['default'].dragend_move_diagnostic_path_items(path, event).then((function(_this) {
        return function() {
          return _this.reset_is_saving();
        };
      })(this));
    },
    dragend_new_mechanism: function(event) {
      var path;
      path = this.get('model');
      this.set_is_saving();
      return path_manager['default'].dragend_new_mechanism_path_item(path, event, 'New mechanism').then((function(_this) {
        return function() {
          return _this.reset_is_saving();
        };
      })(this));
    },
    set_is_saving: function() {
      this.set('is_saving', true);
      return this.totem_messages.show_loading_outlet({
        message: 'Updating path...'
      });
    },
    reset_is_saving: function() {
      this.set('is_saving', false);
      return this.totem_messages.hide_loading_outlet();
    },
    validate_diagnostic_path: function(status) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var action, messages, section, tvo;
          tvo = _this.get('tvo');
          section = _this.get('attributes.source');
          action = 'itemables';
          messages = [];
          status.set_is_valid(true);
          if (tvo.section.has_action(section, action)) {
            return tvo.section.call_action(section, action).then(function(itemables) {
              itemables.forEach(function(item) {
                if (item.get_is_used() !== true) {
                  status.increment_invalid_count();
                  return status.set_is_valid(false);
                }
              });
              if (!status.get_is_valid()) {
                messages.push("Use all observations.");
              }
              status.set_status_messages(messages.uniq());
              return resolve();
            }, function(error) {
              return reject(error);
            });
          }
        };
      })(this), (function(_this) {
        return function(error) {
          return reject(error);
        };
      })(this));
    },
    actions: {
      add_mechanism_bottom: function() {
        return this.add_default_mechanism();
      },
      toggle_collapse_all: function() {
        this.toggleProperty('all_collapsed');
      },
      edit: function() {
        return this.set('edit_visible', true);
      },
      save: function() {
        var path;
        path = this.get('model');
        this.set('edit_visible', false);
        if (!path.get('isDirty')) {
          return;
        }
        return path.save().then((function(_this) {
          return function(path) {
            return _this.totem_messages.api_success({
              source: _this,
              model: path,
              action: 'save',
              i18n_path: ns['default'].to_o('path', 'save')
            });
          };
        })(this), (function(_this) {
          return function(error) {
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: path,
              action: 'save'
            });
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});