define('client/components/thinkspace/lab/builder/lab/result/form/html', ['exports', 'ember', 'totem/ns', 'client/components/thinkspace/lab/builder/lab/result/form/base', 'totem/mixins/ckeditor'], function (exports, ember, ns, base, ckeditor_mixin) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(ckeditor_mixin['default'], {
    html: null,
    init: function() {
      this._super();
      if (this.get('is_edit')) {
        return this.edit_html();
      }
    },
    init_values: function() {
      return this.set('html', this.get_unbound_html());
    },
    get_display_value: function() {
      return this.get_html().htmlSafe();
    },
    ckeditor_tag: null,
    editor_loaded: false,
    get_html: function() {
      return this.get_model_value_path() || '';
    },
    set_html: function(html) {
      return this.set_model_value_path(html);
    },
    get_unbound_html: function() {
      return '' + this.get_html();
    },
    form_valid: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var editor, html;
          editor = _this.get('editor_loaded');
          if (!editor) {
            return reject();
          }
          html = _this.ckeditor_value();
          _this.set_html(html);
          return resolve();
        };
      })(this));
    },
    edit_html_observer: ember['default'].observer('is_edit', function() {
      if (!this.get('is_edit')) {
        return;
      }
      return this.edit_html();
    }),
    edit_html: function() {
      return ember['default'].run.schedule('afterRender', (function(_this) {
        return function() {
          var edit_area, options;
          edit_area = $('textarea.lab-admin_edit-html-result');
          if (ember['default'].isBlank(edit_area)) {
            return;
          }
          _this.set('ckeditor_tag', edit_area);
          options = {
            height: 200,
            width: 450,
            resize_enabled: false
          };
          return _this.ckeditor_load().then(function() {
            return edit_area.ckeditor((function() {
              return _this.set('editor_loaded', true);
            }), options);
          }, function(error) {
            return _this.totem_error["throw"](_this, error);
          });
        };
      })(this));
    },
    rollback: function() {}
  });

  exports['default'] = ___DefaultExportObject___;;

});