define('client/models/thinkspace/resource/link', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].polymorphic('resourceable'), ta['default'].has_many('tags', {
    reads: {}
  })), {
    url: ta['default'].attr('string'),
    title: ta['default'].attr('string'),
    resourceable_type: ta['default'].attr('string'),
    resourceable_id: ta['default'].attr('string'),
    new_tags: ta['default'].attr(),
    tag: ember['default'].computed.reads(ta['default'].to_prop('tags', 'firstObject')),
    didCreate: function() {
      return this.didLoad();
    },
    didLoad: function() {
      return this.get('resourceable').then((function(_this) {
        return function(resource) {
          return resource.get(ta['default'].to_p('links')).then(function(links) {
            if (!links.contains(_this)) {
              return links.pushObject(_this);
            }
          });
        };
      })(this));
    },
    set_new_tags: function(tag) {
      var tag_ids;
      tag_ids = (tag && tag.get('id')) || [];
      return this.set('new_tags', ember['default'].makeArray(tag_ids));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});