define('client/validators/local/password_strength', ['exports', 'ember', 'ember-validations/validators/base'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    call: function() {
      var min, msg, password, score;
      password = this.get(this.property);
      if (!password) {
        return;
      }
      score = window.zxcvbn(password).score;
      min = this.options.minimum || 3;
      msg = this.options.message || 'Must use a "Good" or "Strong" password.  Try using a combination of numbers, letters, and symbols.';
      if (score < min) {
        return this.errors.pushObject(msg);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});