define('client/components/thinkspace/casespace/ownerable/bar', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    casespace: ember['default'].inject.service(),
    has_bar: false,
    model: ember['default'].computed.reads('casespace.current_phase'),
    is_gradebook: ember['default'].computed.equal('bar_type', 'gradebook'),
    c_bar_type: ember['default'].computed('bar_type', function() {
      var type;
      type = this.get('bar_type');
      if (!ember['default'].isPresent(type)) {
        return null;
      }
      return ns['default'].to_p('casespace', 'ownerable', 'bar', type);
    }),
    addon_observer: ember['default'].observer('casespace.active_addon', function() {
      var addon, name;
      addon = this.get('casespace.active_addon');
      if (ember['default'].isPresent(addon)) {
        name = addon.get('addon_name');
        switch (name) {
          case 'gradebook':
            return this.set_bar_type(name);
          case 'peer_review':
            return this.set_bar_type(name);
        }
      } else {
        return this.reset_bar_type();
      }
    }),
    set_bar_type: function(type) {
      this.set('has_bar', true);
      return this.set('bar_type', type);
    },
    reset_bar_type: function(type) {
      this.set('has_bar', false);
      return ember['default'].run.schedule('afterRender', (function(_this) {
        return function() {
          return _this.set('bar_type', null);
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});