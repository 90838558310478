define('client/components/thinkspace/readiness_assurance/shared/timer/show', ['exports', 'ember', 'totem/ns', 'thinkspace-readiness-assurance/base/ra_component'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    message: null,
    willInsertElement: function() {
      return this.setup();
    },
    setup: function() {
      var model, previous;
      model = this.get('model');
      this.room_options = this.ra.join_timer_room(model, {
        source: this,
        callback: 'handle_timer',
        after_authorize_callback: 'start_timers_callback'
      });
      previous = this.ra.get_timer_message(this.room_options.room);
      if (ember['default'].isPresent(previous)) {
        return this.set('message', previous);
      }
    },
    handle_timer: function(data) {
      var message;
      if (data == null) {
        data = {};
      }
      console.info('handle_timer:', data);
      message = this.get_message(data);
      this.ra.set_timer_message(this.room_options.room, message);
      return this.set('message', message);
    },
    get_message: function(data) {
      var data_msg, message, prefix;
      data_msg = data.message;
      prefix = data.n === (data.of - 1) ? 'in less than' : 'in about';
      message = prefix + (" " + data.units + " " + data.label);
      if (ember['default'].isPresent(data_msg)) {
        message = data_msg + " (" + message + ").";
      }
      return message;
    },
    start_timers_callback: function(options) {
      return this.ra.start_timers(options);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});