define('client/models/thinkspace/diagnostic_path/path', ['exports', 'ember', 'totem/ds/associations', 'client/models/thinkspace/common/componentable'], function (exports, ember, ta, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(ta['default'].add(ta['default'].has_many('path_items'), ta['default'].store_filter('path_items', {
    on: 'path_id',
    filter_on: 'parent_id',
    is_blank: true,
    reads: {
      name: 'children',
      filter: true,
      sort: 'position:asc'
    }
  })), {
    title: ta['default'].attr('string'),
    has_path_items: ta['default'].attr('boolean'),
    abilities: ta['default'].attr()
  });

  exports['default'] = ___DefaultExportObject___;;

});