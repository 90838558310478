define('client/components/thinkspace/observation_list/observation_note/edit', ['exports', 'ember', 'thinkspace-base/components/base'], function (exports, ember, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    note_text: null,
    actions: {
      update: function() {
        this.set('model.value', this.get('note_text'));
        return this.sendAction('update');
      },
      cancel: function() {
        return this.sendAction('cancel');
      }
    },
    didInsertElement: function() {
      var $textarea;
      this.set('note_text', this.get('model.value'));
      $textarea = this.$('textarea');
      return $textarea.focus();
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});