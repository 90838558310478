define('client/models/thinkspace/weather_forecaster/item', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].has_many('wf:assessment_items')), {
    name: ta['default'].attr('string'),
    title: ta['default'].attr('string'),
    description: ta['default'].attr('string'),
    presentation: ta['default'].attr('string'),
    response_metadata: ta['default'].attr(),
    help_tip: ta['default'].attr()
  });

  exports['default'] = ___DefaultExportObject___;;

});