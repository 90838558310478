define('client/templates/components/thinkspace/casespace/case_manager/assignment/wizards/assessment/steps/settings', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ts-wizard");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","ts-wizard_content");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-wizard_row");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-grid_columns small-12");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-forms_heading");
        var el6 = dom.createElement("h4");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("p");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-forms_field-container");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-forms_field-label ts-forms_field-label-big");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-forms_field-container");
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","ts-radio");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","ts-radio_description");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-forms_field-container");
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","ts-radio");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","ts-radio_description");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-wizard_row ts-forms_action-btns-container");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-grid_columns small-12");
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"class","btn btn-default btn-small left");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"class","btn btn-primary btn-small right");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, inline = hooks.inline, element = hooks.element, get = hooks.get;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [0, 0]);
        var element1 = dom.childAt(element0, [0, 0]);
        var element2 = dom.childAt(element1, [0]);
        var element3 = dom.childAt(element1, [1]);
        var element4 = dom.childAt(element3, [1, 0]);
        var element5 = dom.childAt(element3, [2, 0]);
        var element6 = dom.childAt(element1, [2]);
        var element7 = dom.childAt(element1, [3]);
        var element8 = dom.childAt(element0, [1, 0]);
        var element9 = dom.childAt(element8, [0]);
        var element10 = dom.childAt(element8, [1]);
        var morph0 = dom.createMorphAt(dom.childAt(element2, [0]),0,0);
        var morph1 = dom.createMorphAt(dom.childAt(element2, [1]),0,0);
        var morph2 = dom.createMorphAt(dom.childAt(element3, [0]),0,0);
        var morph3 = dom.createMorphAt(element4,0,0);
        var morph4 = dom.createMorphAt(element4,1,1);
        var morph5 = dom.createMorphAt(dom.childAt(element4, [2]),0,0);
        var morph6 = dom.createMorphAt(element5,0,0);
        var morph7 = dom.createMorphAt(element5,1,1);
        var morph8 = dom.createMorphAt(dom.childAt(element5, [2]),0,0);
        var morph9 = dom.createMorphAt(element6,0,0);
        var morph10 = dom.createMorphAt(element7,0,0);
        var morph11 = dom.createMorphAt(element9,0,0);
        var morph12 = dom.createMorphAt(element10,0,0);
        inline(env, morph0, context, "t", ["builder.assessment.headings.settings"], {});
        inline(env, morph1, context, "t", ["builder.assessment.describe.settings"], {});
        inline(env, morph2, context, "t", ["builder.assessment.instruct.method"], {});
        element(env, element4, context, "action", ["set_methodology", "michaelsen"], {"on": "click"});
        inline(env, morph3, context, "component", [get(env, context, "c_radio")], {"checked": get(env, context, "is_michaelsen"), "disable_click": true});
        inline(env, morph4, context, "t", ["builder.assessment.headings.method_michaelsen"], {});
        inline(env, morph5, context, "t", ["builder.assessment.describe.method_michaelsen"], {});
        element(env, element5, context, "action", ["set_methodology", "custom"], {"on": "click"});
        inline(env, morph6, context, "component", [get(env, context, "c_radio")], {"checked": get(env, context, "is_custom"), "disable_click": true});
        inline(env, morph7, context, "t", ["builder.assessment.headings.method_custom"], {});
        inline(env, morph8, context, "t", ["builder.assessment.describe.method_custom"], {});
        element(env, element6, context, "bind-attr", [], {"class": ":ts-forms_section-container has_methodology:is-active:is-disabled"});
        inline(env, morph9, context, "component", [get(env, context, "c_quantitative")], {"model": get(env, context, "quantitative"), "options": get(env, context, "options"), "assessment": get(env, context, "model"), "type": get(env, context, "type")});
        element(env, element7, context, "bind-attr", [], {"class": ":ts-forms_section-container has_methodology:is-active:is-disabled"});
        inline(env, morph10, context, "component", [get(env, context, "c_qualitative")], {"model": get(env, context, "qualitative"), "options": get(env, context, "options"), "assessment": get(env, context, "model"), "has_individual_comments": get(env, context, "has_individual_comments")});
        element(env, element9, context, "action", ["back"], {"on": "click"});
        inline(env, morph11, context, "t", ["builder.assessment.buttons.back"], {});
        element(env, element10, context, "action", ["complete"], {"on": "click"});
        inline(env, morph12, context, "t", ["builder.assessment.buttons.next"], {});
        return fragment;
      }
    };
  }()));

});