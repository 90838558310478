define('client/components/thinkspace/readiness_assurance/admin/trat/phase_states', ['exports', 'ember', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    init: function() {
      this._super();
      return this.trad = this.am.rad({
        name: 'TRAT',
        width_selector: '.ts-ra_admin-phase-states-content'
      });
    },
    willInsertElement: function() {
      return this.am.get_trat_team_users().then((function(_this) {
        return function(team_users) {
          _this.trad.set_team_users(team_users);
          return _this.set_ready_on();
        };
      })(this));
    },
    actions: {
      validate: function() {
        return this.validate();
      },
      send_phase_states: function() {
        var trat;
        this.validate();
        this.selected_send_on();
        if (ember['default'].isPresent(this.trad.errors)) {
          return;
        }
        trat = this.trad.get_data();
        return this.am.send_trat_phase_states({
          trat: trat
        });
      }
    },
    validate: function() {
      this.trad.clear_errors();
      if (ember['default'].isBlank(this.trad.get_teams())) {
        this.trad.error('No teams are selected.');
      }
      if (ember['default'].isBlank(this.trad.get_phase_state())) {
        return this.trad.error('No state selected.');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});