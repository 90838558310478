define('client/helpers/format-date', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Handlebars.makeBoundHelper(function(date, formatting, options) {
    var formatted_date;
    if (!date) {
      return '';
    }
    return formatted_date = moment(date).format(formatting);
  });

  exports['default'] = ___DefaultExportObject___;;

});