define('client/components/thinkspace/readiness_assurance/response/trat/justification', ['exports', 'ember', 'thinkspace-base/components/base'], function (exports, ember, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    show_save: false,
    input_value: null,
    willInsertElement: function() {
      return this.set_input_value();
    },
    justification_disabled: ember['default'].computed.or('qm.readonly', 'qm.justification_disabled'),
    justification_change: ember['default'].observer('qm.justification', function() {
      return this.set_input_value();
    }),
    set_input_value: function() {
      return this.set('input_value', this.qm.justification);
    },
    actions: {
      save: function() {
        if (this.get('justification_disabled')) {
          return;
        }
        this.sendAction('save', this.get('input_value'));
        return this.set('show_save', false);
      },
      cancel: function() {
        this.set_input_value();
        this.sendAction('cancel');
        return this.set('show_save', false);
      }
    },
    focusIn: function() {
      this.sendAction('focus_in');
      return this.set('show_save', true);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});