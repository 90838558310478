define('client/thinkspace/casespace/case_manager/route', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace-case-manager/routes/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    titleToken: 'Case Manager',
    thinkspace: ember['default'].inject.service(),
    activate: function() {
      return this.get('thinkspace').enable_wizard_mode();
    },
    deactivate: function() {
      this.get_case_manager().reset_all();
      this.get_wizard_manager().reset_all();
      return this.get('thinkspace').disable_wizard_mode();
    },
    actions: {
      exit: function() {
        var controller, thinkspace, transition;
        controller = this.get('wizard_manager.controller');
        if (controller) {
          controller.set('step', null);
        }
        thinkspace = this.get('thinkspace');
        transition = thinkspace.get_current_transition();
        if (ember['default'].isBlank(transition) || thinkspace.transition_is_for(transition, 'case_manager')) {
          return this.transitionTo(ns['default'].to_r('spaces'));
        } else {
          return transition.retry();
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});