define('client/helpers/file-icon-from-type', ['exports', 'ember', 'totem/util'], function (exports, ember, util) {

  'use strict';

  var ___DefaultExportObject___, default_icon, get_icon_html, icon_map;

  default_icon = ['fa', 'fa-file-o'];

  icon_map = {
    html: ['fa', 'fa-code'],
    text: ['fa', 'fa-file-text-o'],
    image: ['fa', 'fa-camera'],
    pdf: ['fa', 'fa-file-pdf-o']
  };

  get_icon_html = function(classes) {
    return new ember['default'].Handlebars.SafeString("<i class='" + (classes.join(' ')) + "'></i>");
  };

  ___DefaultExportObject___ = ember['default'].Handlebars.makeBoundHelper(function(content_type, classes, options) {
    var i, icon, icon_class, icon_classes, len;
    if (typeof classes === 'string') {
      classes = classes.split(' ');
    } else {
      options = classes;
      classes = [];
    }
    if (content_type) {
      for (icon in icon_map) {
        icon_classes = icon_map[icon];
        if (util['default'].ends_with(content_type, icon) || util['default'].starts_with(content_type, icon)) {
          for (i = 0, len = icon_classes.length; i < len; i++) {
            icon_class = icon_classes[i];
            classes.push(icon_class);
          }
          break;
        }
      }
      if (ember['default'].isBlank(classes)) {
        return get_icon_html(default_icon);
      } else {
        return get_icon_html(classes.uniq());
      }
    } else {
      return get_icon_html(default_icon);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});