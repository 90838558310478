define('client/models/thinkspace/observation_list/observation', ['exports', 'ember', 'totem/ds/associations', 'totem/mixins/data'], function (exports, ember, ta, data_mixin) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(data_mixin['default'], ta['default'].add(ta['default'].polymorphic('ownerable'), ta['default'].belongs_to('list', {
    reads: {}
  }), ta['default'].has_many('observation_notes', {
    reads: {}
  })), {
    position: ta['default'].attr('number'),
    value: ta['default'].attr('string'),
    created_at: ta['default'].attr('date'),
    list_id: ta['default'].attr('number'),
    ownerable_id: ta['default'].attr('number'),
    ownerable_type: ta['default'].attr('string'),
    totem_data_config: {
      ability: true
    },
    didCreate: function() {
      return this.didLoad();
    },
    didLoad: function() {
      return this.store.find(ta['default'].to_p('list'), this.get('list_id')).then((function(_this) {
        return function(list) {
          return list.get(ta['default'].to_p('observations')).then(function(observations) {
            if (!observations.contains(_this)) {
              return observations.pushObject(_this);
            }
          });
        };
      })(this));
    },
    notes_count: ember['default'].computed.reads(ta['default'].to_prop('observation_notes', 'length')),
    has_no_notes: ember['default'].computed.lte('notes_count', 0),
    has_notes: ember['default'].computed.gt('notes_count', 0),
    category_icon: ember['default'].computed.reads('list.category_icon'),
    is_used: null,
    get_is_used: function() {
      return this.get('is_used');
    },
    set_is_used: function(value) {
      if (value == null) {
        value = true;
      }
      if (this.get_is_used() !== value) {
        return this.set('is_used', value);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});