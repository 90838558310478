define('client/templates/components/thinkspace/casespace/case_manager/assignment/wizards/assessment/steps/logistics', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, null);
            dom.insertBoundary(fragment, 0);
            inline(env, morph0, context, "component", [get(env, context, "c_team_set")], {"model": get(env, context, "team_set_each"), "selected": get(env, context, "team_set")});
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, null);
          dom.insertBoundary(fragment, 0);
          block(env, morph0, context, "each", [get(env, context, "team_sets")], {"keyword": "team_set_each"}, child0, null);
          return fragment;
        }
      };
    }());
    var child1 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
          inline(env, morph0, context, "t", ["builder.assessment.describe.no_team_sets"], {});
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ts-wizard");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","ts-wizard_content");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-wizard_row");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-grid_columns small-12");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-forms_heading");
        var el6 = dom.createElement("h4");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("p");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-forms_section-container");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-forms_section-heading");
        var el7 = dom.createElement("h5");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("hr");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-forms_section-container");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-forms_section-heading");
        var el7 = dom.createElement("h5");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("hr");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-forms_section-container");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-forms_section-heading");
        var el7 = dom.createElement("h5");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("hr");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-forms_section-container");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-forms_section-heading");
        var el7 = dom.createElement("h5");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("ul");
        dom.setAttribute(el6,"class","thinkspace-tbl-pa_team-set-list");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-wizard_row ts-forms_action-btns-container");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-grid_columns small-12");
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"class","btn btn-default btn-small left");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"class","btn btn-primary btn-small right");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, inline = hooks.inline, get = hooks.get, block = hooks.block, element = hooks.element;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [0, 0]);
        var element1 = dom.childAt(element0, [0, 0]);
        var element2 = dom.childAt(element1, [0]);
        var element3 = dom.childAt(element1, [1]);
        var element4 = dom.childAt(element3, [0]);
        var element5 = dom.childAt(element1, [2]);
        var element6 = dom.childAt(element5, [0]);
        var element7 = dom.childAt(element1, [3]);
        var element8 = dom.childAt(element7, [0]);
        var element9 = dom.childAt(element1, [4]);
        var element10 = dom.childAt(element9, [0]);
        var element11 = dom.childAt(element0, [1, 0]);
        var element12 = dom.childAt(element11, [0]);
        var element13 = dom.childAt(element11, [1]);
        var morph0 = dom.createMorphAt(dom.childAt(element2, [0]),0,0);
        var morph1 = dom.createMorphAt(dom.childAt(element2, [1]),0,0);
        var morph2 = dom.createMorphAt(dom.childAt(element4, [0]),0,0);
        var morph3 = dom.createMorphAt(dom.childAt(element4, [1]),0,0);
        var morph4 = dom.createMorphAt(element3,1,1);
        var morph5 = dom.createMorphAt(dom.childAt(element6, [0]),0,0);
        var morph6 = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
        var morph7 = dom.createMorphAt(element5,1,1);
        var morph8 = dom.createMorphAt(dom.childAt(element8, [0]),0,0);
        var morph9 = dom.createMorphAt(dom.childAt(element8, [1]),0,0);
        var morph10 = dom.createMorphAt(element7,1,1);
        var morph11 = dom.createMorphAt(dom.childAt(element10, [0]),0,0);
        var morph12 = dom.createMorphAt(dom.childAt(element10, [1]),0,0);
        var morph13 = dom.createMorphAt(dom.childAt(element9, [1]),0,0);
        var morph14 = dom.createMorphAt(element12,0,0);
        var morph15 = dom.createMorphAt(element13,0,0);
        inline(env, morph0, context, "t", ["builder.casespace.headings.case_logistics"], {});
        inline(env, morph1, context, "t", ["builder.casespace.describe.logistics"], {});
        inline(env, morph2, context, "t", ["builder.casespace.headings.release_date"], {});
        inline(env, morph3, context, "t", ["builder.casespace.instruct.set_release_date"], {});
        inline(env, morph4, context, "date-picker-split-row", [], {"content": get(env, context, "this"), "property": "release_at", "hide_input": false, "hide_button": true, "time_picker": true, "date_picker_header": "Release Day", "time_picker_header": "Release Time (your local timezone)", "save_on_close": false});
        inline(env, morph5, context, "t", ["builder.casespace.headings.due_date"], {});
        inline(env, morph6, context, "t", ["builder.casespace.instruct.set_due_date"], {});
        inline(env, morph7, context, "date-picker-split-row", [], {"content": get(env, context, "this"), "property": "due_at", "hide_input": false, "hide_button": true, "time_picker": true, "date_picker_header": "Due Day", "time_picker_header": "Due Time (your local timezone)", "save_on_close": false});
        inline(env, morph8, context, "t", ["builder.casespace.headings.case_instructions"], {});
        inline(env, morph9, context, "t", ["builder.casespace.instruct.add_instructions"], {});
        inline(env, morph10, context, "view", [get(env, context, "ckeditor_view")], {"value": get(env, context, "instructions"), "height": "200"});
        inline(env, morph11, context, "t", ["builder.assessment.headings.team_set"], {});
        inline(env, morph12, context, "t", ["builder.assessment.instruct.set_team_set"], {});
        block(env, morph13, context, "if", [get(env, context, "team_sets")], {}, child0, child1);
        element(env, element12, context, "action", ["back"], {"on": "click"});
        inline(env, morph14, context, "t", ["builder.casespace.buttons.back"], {});
        element(env, element13, context, "action", ["complete"], {"on": "click"});
        inline(env, morph15, context, "t", ["builder.casespace.buttons.next"], {});
        return fragment;
      }
    };
  }()));

});