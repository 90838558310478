define('client/components/thinkspace/casespace/assignment/phases', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    r_phases_show: ns['default'].to_r('phases', 'show'),
    c_assignment_phase: ns['default'].to_p('assignment', 'phase'),
    actions: {
      toggle_details: function() {
        return this.toggleProperty('display_phase_details');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});