define('client/components/thinkspace/builder/steps/parts/templates/phase', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    model: null,
    selected_phase_template: null,
    is_selected: ember['default'].computed('model', 'selected_phase', function() {
      var model, selected_phase;
      model = this.get('model');
      selected_phase = this.get('selected_phase');
      return ember['default'].isEqual(model, selected_phase);
    }),
    init: function() {
      var model;
      this._super();
      model = this.get('model');
      return model.get(ns['default'].to_p('phase_template')).then((function(_this) {
        return function(phase_template) {
          _this.set('phase_template', phase_template);
          return _this.set_all_data_loaded();
        };
      })(this));
    },
    actions: {
      select: function() {
        return this.sendAction('select', this.get('model'));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});