define('client/components/thinkspace/casespace/case_manager/assignment/peer_assessment/team', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'thinkspace-base/components/base'], function (exports, ember, ns, ajax, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    is_expanded: false,
    assessment: null,
    team_sets: null,
    review_sets: null,
    team_members: ember['default'].computed.reads('model.users'),
    team_set: ember['default'].computed('team_sets', 'model', function() {
      var team_id, team_sets;
      team_sets = this.get('team_sets');
      team_id = parseInt(this.get('model.id'));
      if (!(ember['default'].isPresent(team_sets) && ember['default'].isPresent(team_id))) {
        return;
      }
      return team_sets.findBy('team_id', team_id);
    }),
    is_approvable: ember['default'].computed('team_set.state', 'team_set', function() {
      var team_set;
      team_set = this.get('team_set');
      if (!ember['default'].isPresent(team_set)) {
        return false;
      }
      return team_set.get('is_approvable');
    }),
    css_style_team_header: ember['default'].computed('model.color', function() {
      var color, css;
      color = this.get('model.color');
      if (!ember['default'].isPresent(color)) {
        return '';
      }
      css = '';
      css += "background-color: #" + color + ";";
      css += "border-color: #" + color + ";";
      return new ember['default'].Handlebars.SafeString(css);
    }),
    css_style_team_content: ember['default'].computed('model.color', function() {
      var color, css;
      color = this.get('model.color');
      if (!ember['default'].isPresent(color)) {
        return '';
      }
      css = '';
      css += "border-color: #" + color + ";";
      return new ember['default'].Handlebars.SafeString(css);
    }),
    dropdown_collection: ember['default'].computed('model', function() {
      var collection;
      collection = [];
      collection.pushObject({
        display: 'Approve <strong>team only</strong>',
        action: 'approve'
      });
      collection.pushObject({
        display: 'Re-open <strong>team only</strong>',
        action: 'unapprove'
      });
      return collection;
    }),
    c_user: ns['default'].to_p('case_manager', 'assignment', 'peer_assessment', 'user'),
    c_state: ns['default'].to_p('case_manager', 'assignment', 'peer_assessment', 'state'),
    c_dropdown_split_button: ns['default'].to_p('common', 'dropdown_split_button'),
    toggle_is_expanded: function() {
      if (this.get('is_expanded')) {
        return this.toggleProperty('is_expanded');
      } else {
        return this.get_review_sets().then((function(_this) {
          return function(review_sets) {
            return _this.toggleProperty('is_expanded');
          };
        })(this));
      }
    },
    get_review_sets: function() {
      var query;
      query = {
        model: ns['default'].to_p('tbl:assessment'),
        verb: 'get',
        action: 'review_sets',
        id: this.get('assessment.id'),
        data: {
          team_id: this.get('model.id')
        }
      };
      return ajax['default'].object(query).then((function(_this) {
        return function(payload) {
          var review_sets;
          review_sets = ajax['default'].normalize_and_push_payload('tbl:review_set', payload);
          console.log("[tbl-pa-cm] Review set returned as: ", review_sets);
          return _this.set('review_sets', review_sets);
        };
      })(this));
    },
    state_change: function(state) {
      var query, team_set;
      team_set = this.get('team_set');
      query = {
        id: team_set.get('id'),
        action: state,
        verb: 'PUT'
      };
      return this.tc.query(ns['default'].to_p('tbl:team_set'), query, {
        single: true
      });
    },
    actions: {
      toggle: function() {
        return this.toggle_is_expanded();
      },
      approve: function() {
        return this.state_change('approve');
      },
      unapprove: function() {
        return this.state_change('unapprove');
      },
      approve_all: function() {
        return this.state_change('approve_all');
      },
      unapprove_all: function() {
        return this.state_change('unapprove_all');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});