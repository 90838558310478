define('client/locales/en', ['exports'], function (exports) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = {
    totem: {
      api: {
        status_codes: {
          failure: 'An error occurred.',
          success: '%@ %@ successful.',
          not_found: '%@ could not be found.',
          model_validation: '%@ validation errors.',
          unauthorized_access: 'You are not authorized to perform this action.',
          session_error: 'Your session had an error.',
          session_timeout: 'Your session has timed out.',
          session_expired: 'Your session has expired.',
          sign_in_error: 'Sign in error occurred.',
          "default": 'Missing i18n message for handler [%@].'
        }
      }
    }
  };

  exports['default'] = ___DefaultExportObject___;;

});