define('client/components/thinkspace/lab/builder/lab/result/new', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    admin: ember['default'].inject.service(ns['default'].to_p('lab', 'admin')),
    model: ember['default'].computed(function() {
      var admin, type;
      admin = this.get('admin');
      type = admin.get('new_result_type');
      return admin.get_mock_new_result_record(type);
    }),
    form_heading: ember['default'].computed(function() {
      var type;
      type = this.get('admin.new_result_type');
      return this.t("builder.lab.admin.new_" + type);
    }),
    actions: {
      cancel: function() {
        return this.get('admin').set_action_overlay_off();
      },
      save: function() {
        var admin;
        admin = this.get('admin');
        return admin.get_category_results().then((function(_this) {
          return function(results) {
            var mock, position, result;
            position = results.get('length') + 1;
            mock = _this.get('model');
            mock.set('position', position);
            result = admin.get_store().createRecord(ns['default'].to_p('lab:result'));
            return admin.save_result(result, {
              values: mock
            }).then(function() {
              return _this.send('cancel');
            });
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});