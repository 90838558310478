define('client/components/thinkspace/peer_assessment/overview/type/base', ['exports', 'ember', 'totem/ns', 'thinkspace-peer-assessment/components/peer_assessment/overview/type/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    assessment_quantitative_items: ember['default'].computed('assessment', function() {
      return this.get('assessment.quantitative_items');
    }),
    categories: ember['default'].computed('assessment_quantitative_items', function() {
      var ids, items, responses;
      items = this.get('assessment_quantitative_items');
      if (!ember['default'].isPresent(items)) {
        return [];
      }
      ids = items.mapBy('id');
      responses = [];
      ids.forEach((function(_this) {
        return function(id) {
          var label, response;
          label = items.findBy('id', id).label;
          response = {};
          response['id'] = id;
          response['value'] = _this.get_calculated_overview_value_for_id(id);
          response['label'] = label;
          return responses.pushObject(response);
        };
      })(this));
      return responses;
    }),
    overview_score: ember['default'].computed('calculated_overview.quantitative', function() {
      var id, overview, quantitative, score;
      overview = this.get('calculated_overview');
      if (!ember['default'].isPresent(overview)) {
        return null;
      }
      quantitative = ember['default'].get(overview, 'quantitative');
      if (!ember['default'].isPresent(quantitative)) {
        return 0;
      }
      for (id in quantitative) {
        score = quantitative[id];
        return score;
      }
    }),
    get_calculated_overview_value_for_id: function(id) {
      var calculated_overview, quantitative;
      calculated_overview = this.get('calculated_overview');
      if (!ember['default'].isPresent(calculated_overview)) {
        return null;
      }
      quantitative = calculated_overview['quantitative'];
      if (!ember['default'].isPresent(quantitative)) {
        return null;
      }
      id = parseInt(id);
      return quantitative[id];
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});