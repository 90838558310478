define('client/components/thinkspace/casespace/case_manager/team/form', ['exports', 'ember', 'totem/ds/associations', 'totem/ns', 'totem/ajax', 'totem/util', 'thinkspace-base/components/base'], function (exports, ember, ta, ns, ajax, util, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    title: ember['default'].computed(function() {
      return console.error("Implement a specific title property for new/edit team form components.");
    }),
    color: ember['default'].computed(function() {
      return console.error("Implement a specific color property for new/edit team form components.");
    }),
    css_style: ember['default'].computed('color', function() {
      var color, css;
      color = this.get('color');
      if (!ember['default'].isPresent(color)) {
        return '';
      }
      css = '';
      css += "background-color: #" + color + ";";
      return new ember['default'].Handlebars.SafeString(css);
    }),
    users_to_add: null,
    users_to_remove: null,
    space: ember['default'].computed.reads('team_manager.current_space'),
    current_users: ember['default'].computed.reads('team_manager.current_users'),
    team_users: ember['default'].computed(function() {
      return this.get('team_manager').team_users_for_team(this.get('model'));
    }),
    unassigned_users: ember['default'].computed.reads('team_manager.current_unassigned_users'),
    unassigned_users_loaded: ember['default'].computed.reads('team_manager.current_unassigned_users_loaded'),
    c_space_header: ns['default'].to_p('space', 'header'),
    c_team_member_unassigned: ns['default'].to_p('case_manager', 'team', 'member', 'unassigned'),
    r_team_sets_show: ns['default'].to_r('case_manager', 'team_sets', 'show'),
    team_manager: ember['default'].inject.service(),
    init: function() {
      this._super();
      this.set('users_to_add', []);
      return this.set('users_to_remove', []);
    },
    get_store: function() {
      return this.container.lookup('store:main');
    },
    reset_user_arrays: function() {
      this.get('users_to_add').clear();
      return this.get('users_to_remove').clear();
    },
    reset_team: function() {
      return this.get('model').reset_all();
    },
    reset_all: function() {
      this.reset_user_arrays();
      this.reset_team();
      return this.get('team_manager').update_unassigned_users();
    },
    get_model: function() {
      return console.error("Implement a specific get_model for new/edit team form components.");
    },
    transition_from_save: function(team) {
      return console.error("Implement a specific transition_from_save for new/edit team form components.");
    },
    actions: {
      add_user_to_team: function(user) {
        var users_to_add;
        users_to_add = this.get('users_to_add');
        if (users_to_add.contains(user)) {
          return users_to_add.removeObject(user);
        } else {
          return users_to_add.pushObject(user);
        }
      },
      remove_user_from_team: function(user) {
        var users_to_remove;
        users_to_remove = this.get('users_to_remove');
        if (users_to_remove.contains(user)) {
          return users_to_remove.removeObject(user);
        } else {
          return users_to_remove.pushObject(user);
        }
      },
      save: function() {
        return this.get_model().then((function(_this) {
          return function(team) {
            var remove_ids, users_to_add, users_to_remove;
            users_to_add = _this.get('users_to_add');
            users_to_remove = _this.get('users_to_remove');
            remove_ids = null;
            if (ember['default'].isPresent(users_to_add)) {
              team.add_users(users_to_add);
            }
            if (ember['default'].isPresent(users_to_remove)) {
              remove_ids = util['default'].string_array_to_numbers(users_to_remove.mapBy('id'));
              team.remove_users(users_to_remove);
            }
            team.set('title', _this.get('title'));
            team.set('color', _this.get('color'));
            return team.save().then(function(team) {
              if (_this.get('is_new')) {
                team.get('team_set').then(function(team_set) {
                  return team_set.increment_team_count();
                });
              }
              if (ember['default'].isPresent(remove_ids)) {
                return team.unload_team_users(remove_ids).then(function() {
                  _this.reset_all();
                  return _this.transition_from_save(team);
                });
              } else {
                _this.reset_all();
                return _this.transition_from_save(team);
              }
            }, function(error) {
              team.rollback();
              return _this.reset();
            });
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});