define('client/thinkspace/casespace/readiness_assurance/dashboard/route', ['exports', 'ember', 'totem/ns', 'simple-auth/mixins/authenticated-route-mixin', 'thinkspace-base/base/route'], function (exports, ember, ns, auth_mixin, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(auth_mixin['default'], {
    casespace: ember['default'].inject.service(),
    afterModel: function(assignment) {
      return this.get('casespace').set_current_models({
        assignment: assignment
      }).then((function(_this) {
        return function() {
          return _this.totem_messages.hide_loading_outlet();
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});