define('client/components/thinkspace/input_element/elements/standard_radio', ['exports', 'ember', 'totem/ns', 'totem/mixins/validations', 'thinkspace-base/components/base'], function (exports, ember, ns, val_mixin, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend(val_mixin['default'], {
    tagName: '',
    input_value: null,
    view_only: ember['default'].computed.bool('totem_scope.is_view_only'),
    is_checked: ember['default'].computed('model.value', function() {
      return this.get('radio_value') === this.get('model.value');
    }),
    actions: {
      check: function() {
        var model, value;
        if (this.get('is_view_only')) {
          return;
        }
        model = this.get('model');
        value = this.get('radio_value');
        this.set('input_value', value);
        return this.set_status().then((function(_this) {
          return function() {
            model.set('value', value);
            return _this.save_response();
          };
        })(this), (function(_this) {
          return function() {};
        })(this));
      }
    },
    save_response: function() {
      var model;
      model = this.get('model');
      this.totem_scope.set_record_ownerable_attributes(model);
      return model.save().then((function(_this) {
        return function() {
          return _this.totem_messages.api_success({
            source: _this,
            model: model,
            action: 'save',
            i18n_path: ns['default'].to_o('response', 'save')
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.totem_messages.api_failure(error, {
            source: _this,
            model: model
          });
        };
      })(this));
    },
    set_status: function() {
      return this.get('status').validate(this, this.get('status_group_guid'));
    },
    didInsertElement: function() {
      this.set('input_value', this.get('model.value'));
      return this.set_status();
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});