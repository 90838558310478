define('client/models/thinkspace/markup/comment', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].polymorphic('commenterable'), ta['default'].has_many('comments', {
    inverse: ta['default'].to_p('comment:parent'),
    reads: {}
  }), ta['default'].belongs_to('comment:parent', {
    type: ta['default'].to_p('comment'),
    inverse: ta['default'].to_p('comments')
  }), ta['default'].belongs_to('discussion')), {
    comment: ta['default'].attr('string'),
    commenterable_id: ta['default'].attr('number'),
    commenterable_type: ta['default'].attr('string'),
    user_id: ta['default'].attr('number'),
    updated_at: ta['default'].attr('date'),
    created_at: ta['default'].attr('date'),
    updateable: ta['default'].attr('boolean'),
    parent_id: ta['default'].attr('number'),
    discussion_id: ta['default'].attr('number'),
    position: ta['default'].attr('number'),
    can_update: ember['default'].computed('id', 'updateable', function() {
      if (ember['default'].isPresent(this.get('id'))) {
        return this.get('updateable');
      } else {
        return true;
      }
    }),
    has_no_comment: ember['default'].computed.empty('comment'),
    is_child: ember['default'].computed.notEmpty('parent_id'),
    has_children: ember['default'].computed.notEmpty('comments')
  });

  exports['default'] = ___DefaultExportObject___;;

});