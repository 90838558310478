define('client/components/thinkspace/casespace/case_manager/assignment/peer_assessment/review_set', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'thinkspace-base/components/base'], function (exports, ember, ns, ajax, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    is_expanded: false,
    assessment: null,
    reviews_sort_by: ['reviewable.sort_name:asc'],
    sorted_reviews: ember['default'].computed.sort('model.reviews', 'reviews_sort_by'),
    css_style: ember['default'].computed('color', function() {
      var color, css;
      color = this.get('color');
      if (!ember['default'].isPresent(color)) {
        return '';
      }
      css = '';
      css += "border-left-color: #" + color + ";";
      css += "border-top-color: #" + color + ";";
      new ember['default'].Handlebars.SafeString(css);
      return "border-left-color: #" + color + "; border-top-color: #" + color;
    }),
    c_review: ns['default'].to_p('case_manager', 'assignment', 'peer_assessment', 'review'),
    actions: {
      approve: function() {
        var query;
        console.log("[tbl-pa-cm] Approving review SET: ", this.get('model'));
        query = {
          model: this.get('model'),
          id: this.get('model.id'),
          action: 'approve',
          verb: 'put'
        };
        return ajax['default'].object(query).then((function(_this) {
          return function(payload) {
            var review_set;
            return review_set = ajax['default'].normalize_and_push_payload('tbl:review_set', payload, {
              single: true
            });
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});