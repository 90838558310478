define('client/thinkspace/casespace/case_manager/team_sets/show/route', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace-case-manager/routes/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    thinkspace: ember['default'].inject.service(),
    team_manager: ember['default'].inject.service(),
    titleToken: function(model) {
      return model.get('title');
    },
    activate: function() {
      return this.get('thinkspace').disable_wizard_mode();
    },
    model: function(params) {
      return this.store.find(ns['default'].to_p('team_set'), params.team_set_id);
    },
    setupController: function(controller, model) {
      controller.set('model', model);
      return this.get('team_manager').set_team_set(model);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});