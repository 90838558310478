define('client/components/thinkspace/readiness_assurance/admin/irat/to_trat_due_at', ['exports', 'ember', 'thinkspace-readiness-assurance/base/admin/irat/to_trat'], function (exports, ember, base) {

	'use strict';

	var ___DefaultExportObject___;

	___DefaultExportObject___ = base['default'].extend();

	exports['default'] = ___DefaultExportObject___;;

});