define('client/initializers/totem_template_manager', ['exports', 'ember', 'totem-template-manager/services/template_value_object'], function (exports, ember, tvo) {

  'use strict';

  var ___DefaultExportObject___, initializer;

  initializer = {
    name: 'totem-template-manager',
    after: ['totem'],
    initialize: function(container, app) {
      return app.register('service:tvo', tvo['default']);
    }
  };

  ___DefaultExportObject___ = initializer;

  exports['default'] = ___DefaultExportObject___;;

});