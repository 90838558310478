define('client/views/select/option', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].View.extend({
    tagName: 'option',
    templateName: 'select/option',
    classNameBindings: ['class_names'],
    attributeBindings: ['selected', 'disabled', 'value'],
    is_record: true,
    content_display_property: null,
    class_names: '',
    selected_items: [],
    disabled_items: [],
    empty: false,
    data_placeholder: 'Select an Option',
    parent_view: ember['default'].computed.alias('parentView'),
    totem_select_id_property: ember['default'].computed.alias('parent_view.totem_select_id_property'),
    value: (function() {
      var totem_select_id_property;
      totem_select_id_property = this.get('totem_select_id_property');
      if (this.get('is_record')) {
        return this.get("content." + totem_select_id_property);
      } else {
        return this.get('content');
      }
    }).property('is_record', 'content', 'parent_view.select_ids_recomputed'),
    display_property: (function() {
      var property;
      if (this.get('empty')) {
        return this.get('data_placeholder');
      }
      if (this.get('is_record')) {
        property = this.get('content_display_property');
        return this.get("content." + property);
      } else {
        return this.get('content');
      }
    }).property('content', 'content_display_property', 'is_record'),
    selected: (function() {
      var content, selected_items;
      selected_items = this.get('selected_items');
      selected_items = ember['default'].makeArray(selected_items);
      content = this.get('content');
      return selected_items.contains(content);
    }).property('selected_items', 'selected_items.@each', 'content'),
    disabled: (function() {
      var content, disabled_items;
      disabled_items = this.get('disabled_items');
      disabled_items = ember['default'].makeArray(disabled_items);
      content = this.get('content');
      return disabled_items.contains(content);
    }).property('disabled_items', 'disabled_items.@each', 'content')
  });

  exports['default'] = ___DefaultExportObject___;;

});