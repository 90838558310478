define('client/models/thinkspace/authorization/metadata', ['exports', 'ember', 'totem/ds/associations', 'totem/mixins/data/did_load'], function (exports, ember, ta, did_load) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(did_load['default'], {
    metadata: ta['default'].attr(),
    data_name: 'metadata'
  });

  exports['default'] = ___DefaultExportObject___;;

});