define('client/components/thinkspace/dock/messages/message', ['exports', 'ember', 'thinkspace-base/components/base'], function (exports, ember, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    auto_clear_after: 2000,
    dismiss_after: 250,
    dismissed: false,
    image_class_prefix: 'ts-message_image-',
    info_image_suffixes: ['info-1'],
    error_image_suffixes: ['error-1'],
    info_headers: ['Success', 'Hooray!', 'Woohoo'],
    error_headers: ['Uh Oh', 'Whoops', "D'oh"],
    is_debug: ember['default'].computed.bool('totem_messages.debug_on'),
    auto_clear: ember['default'].computed.not('model.sticky'),
    image_class: ember['default'].computed('model.type', function() {
      return this.get_image_class_for_type();
    }),
    type_header: ember['default'].computed('model.type', function() {
      return this.get_header_for_type();
    }),
    didInsertElement: function() {
      if (this.get('auto_clear')) {
        return this.add_clear_timer();
      }
    },
    click: function() {
      this.set('dismissed', true);
      return this.clear_message(this.get('dismiss_after'));
    },
    add_clear_timer: function() {
      var ms;
      ms = this.get('auto_clear_after');
      return ember['default'].run.later(this, ((function(_this) {
        return function() {
          if (ember['default'].isPresent(_this.$()) && (!_this.get('dismissed'))) {
            return _this.clear_message(ms);
          }
        };
      })(this)), ms);
    },
    clear_message: function(ms) {
      var totem_messages;
      totem_messages = this.totem_messages;
      this.$().fadeOut(ms);
      return ember['default'].run.later(this, ((function(_this) {
        return function() {
          totem_messages.remove_message(_this.get('model'));
          return _this.destroy();
        };
      })(this)), ms);
    },
    get_default_image_class: function() {
      var prefix;
      prefix = this.get('image_class_prefix');
      return prefix + 'default';
    },
    get_image_class_for_type: function() {
      var prefix, suffix, suffixes, type;
      type = this.get('model.type');
      suffixes = this.get(type + "_image_suffixes");
      if (!ember['default'].isPresent(suffixes)) {
        return this.get_default_image_class();
      }
      suffix = suffixes[Math.floor(Math.random() * suffixes.length)];
      prefix = this.get('image_class_prefix');
      return prefix + suffix;
    },
    get_header_for_type: function() {
      var headers, type;
      type = this.get('model.type');
      headers = this.get(type + "_headers");
      if (!ember['default'].isPresent(headers)) {
        return '';
      }
      return headers[Math.floor(Math.random() * headers.length)];
    },
    actions: {
      suppress: function() {
        return this.totem_messages.set_suppress_all();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});