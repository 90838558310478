define('client/thinkspace/builder/cases/logistics/route', ['exports', 'ember', 'totem/ns', 'simple-auth/mixins/authenticated-route-mixin', 'thinkspace-base/base/route'], function (exports, ember, ns, auth_mixin, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(auth_mixin['default'], {
    builder: ember['default'].inject.service(),
    titleToken: 'Logistics',
    model: function(params) {
      return this.tc.find_record(ns['default'].to_p('assignment'), params.case_id);
    },
    setupController: function(controller, model) {
      var builder;
      builder = this.get('builder');
      builder.set_current_step_from_id('logistics');
      builder.set_model(model);
      return controller.set('model', model);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});