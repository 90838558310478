define('client/components/thinkspace/markup/library/edit/manager/marker', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    model: null,
    is_editing: null,
    tagName: '',
    model_text: ember['default'].computed('model', function() {
      return this.get('model');
    }),
    actions: {
      remove_tag: function() {
        return this.sendAction('remove_tag', this.get('model'));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});