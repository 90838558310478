define('client/users/password/show/route', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Route.extend({
    model: function(params) {
      return this.store.find(ns['default'].to_p('password_reset'), params.token);
    },
    setupController: function(controller, model) {
      return controller.set('model', model);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});