define('client/services/sockets', ['exports', 'ember', 'totem/config', 'totem/ns'], function (exports, ember, config, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Object.extend({
    connection: null,
    channel_map: null,
    thinkspace: ember['default'].inject.service(),
    initialize: function() {
      if (typeof window.Pusher !== 'object') {
        return;
      }
      this.connect();
      return this.subscribe_all();
    },
    subscribe_all: function() {
      return this.subscribe('global');
    },
    connect: function() {
      if (!ember['default'].isPresent(Pusher)) {
        return console.warn("No Pusher object found - is internet connection good?");
      }
      return this.set('connection', new Pusher(config['default'].pusher_app_key));
    },
    get_connection: function() {
      return this.get('connection');
    },
    get_channel: function(name) {
      var channel, map;
      map = this.get('channel_map');
      channel = map.get(name);
      if (ember['default'].isPresent(channel)) {
        return channel;
      } else {
        return null;
      }
    },
    get_channel_map: function() {
      var map;
      map = this.get('channel_map');
      if (!ember['default'].isPresent(map)) {
        this.set('channel_map', ember['default'].Object.create());
      }
      return this.get('channel_map');
    },
    subscribe: function(name) {
      var channel, fn;
      channel = this.get_connection().subscribe(name);
      this.add_to_channel_map(name, channel);
      fn = this["events_" + name];
      if (fn != null) {
        return this["events_" + name]();
      } else {
        return null;
      }
    },
    add_to_channel_map: function(name, channel) {
      var map;
      map = this.get_channel_map();
      return map.set(name, channel);
    },
    warn_no_channel: function(name) {
      return console.warn("[sockets] No channel found for name: [" + name + "]");
    },
    events_global: function() {
      var channel, name;
      name = 'global';
      channel = this.get_channel(name);
      if (!ember['default'].isPresent(channel)) {
        return this.warn_no_channel(name);
      }
      return channel.bind('notify', (function(_this) {
        return function(data) {
          var message, type;
          message = data.message;
          type = data.type || 'info';
          if (!ember['default'].isPresent(message)) {
            return;
          }
          return _this.get('thinkspace').add_system_notification(type, message);
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});