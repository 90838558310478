define('client/helpers/phase-progress-icon', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Handlebars.makeBoundHelper(function(current_phase, each_phase, current_state, options) {
    if (current_phase === each_phase) {
      return '<div class="tsi tsi-small tsi-phase-current"></div>'.htmlSafe();
    } else {
      return ("<div class='tsi tsi-small tsi-phase-" + current_state + "'></div>").htmlSafe();
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});