define('client/components/thinkspace/common/user/password/fail', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    layoutName: 'thinkspace/common/user/password/fail',
    message: 'There was a problem with resetting your password.',
    contact_us: 'Please request another email or contact support@thinkspace.org if the problem persists.',
    display_message: ember['default'].computed('message', function() {
      return (this.get('message')) + " " + (this.get('contact_us'));
    }),
    c_checkbox: ns['default'].to_p('common', 'shared', 'checkbox'),
    c_validated_input: ns['default'].to_p('common', 'shared', 'validated_input'),
    c_loader: ns['default'].to_p('common', 'shared', 'loader')
  });

  exports['default'] = ___DefaultExportObject___;;

});