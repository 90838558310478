define('client/thinkspace/team/viewers/user_viewer/view', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].View.extend({
    tagName: 'li',
    classNames: ['team_viewers-user-viewer-list-user'],
    is_user_assigned: ember['default'].computed("controller.selected_team." + (ns['default'].to_prop('team_viewers', '@each')), function() {
      return this.get('controller').is_viewer_assigned(this.get('controller.selected_team'), this.get('user'));
    }),
    actions: {
      add_user: function() {
        return this.get('controller').create_team_viewer(this.get('controller.selected_team'), this.get('user'));
      },
      remove_user: function() {
        return this.get('controller').delete_team_viewer(this.get('controller.selected_team'), this.get('user'));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});