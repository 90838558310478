define('client/components/thinkspace/indented_list/list/source/another', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    classNames: ['indented-list_new-other-container'],
    get_container: function() {
      return this.$();
    },
    get_item_values: function() {
      return this.get('item_values');
    },
    get_response_manager: function() {
      return this.get('response_manager');
    },
    willInsertElement: function() {
      return this.get_response_manager().register_source_container(this, this.get_container(), {
        callback: 'set_new_item_values'
      });
    },
    set_new_item_values: function($el, new_item) {
      var item_values, rm;
      rm = this.get_response_manager();
      item_values = rm.get_source_element_html_model_attributes($el);
      new_item.description = item_values.itemable_type;
      new_item.icon = 'lab';
      return null;
    },
    get_item: function(n) {
      var item;
      return item = {
        drag_text: "--> other item " + n,
        description: "*** other item " + n + " list text ***"
      };
    },
    other_list_items: ember['default'].computed(function() {
      var items;
      items = [];
      items.push(this.get_item(1));
      items.push(this.get_item(2));
      items.push(this.get_item(3));
      items.push(this.get_item(4));
      items.push(this.get_item(5));
      return items;
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});