define('client/components/thinkspace/weather_forecaster/response/radio/item', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    is_checked: ember['default'].computed('input_value', function() {
      var id, value;
      value = this.get('input_value');
      id = this.get_id();
      return id && value === id;
    }),
    has_score: ember['default'].computed.reads('model.has_score'),
    is_correct_class: ember['default'].computed('is_checked', function() {
      switch (false) {
        case !!this.get('has_score'):
          return null;
        case !this.get('model').id_is_correct(this.get_id()):
          return 'correct';
        case !this.get('is_checked'):
          return 'incorrect';
        default:
          return null;
      }
    }),
    get_id: function() {
      return this.get('response_id');
    },
    actions: {
      toggle_radio: function() {
        var id;
        if (this.get('is_view_only')) {
          return;
        }
        id = this.get_id();
        if (this.get('is_checked')) {
          return this.sendAction('uncheck', id);
        } else {
          return this.sendAction('check', id);
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});