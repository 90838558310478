define('client/components/thinkspace/lab/category/vet_med', ['exports', 'ember', 'totem/ns', 'thinkspace-lab/vet_med_lab', 'thinkspace-base/components/base'], function (exports, ember, ns, lab, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    init: function() {
      var tvo;
      this._super();
      tvo = this.get('tvo');
      return tvo.status.register_validation('lab', this, 'validate_lab');
    },
    tvo: ember['default'].inject.service(),
    is_inactive: ember['default'].computed('selected', function() {
      return this.get('model') !== this.get('selected');
    }),
    is_view_only: ember['default'].computed.bool('totem_scope.is_view_only'),
    lab: ember['default'].computed(function() {
      return lab['default'].create({
        tvo: this.get('tvo'),
        category: this.get('model'),
        lab_observations: [],
        totem_scope: this.totem_scope,
        totem_messages: this.totem_messages
      });
    }),
    c_observation: ember['default'].computed(function() {
      return ns['default'].to_p('lab:observation', this.get('model.value.component'));
    }),
    c_lab_result: ns['default'].to_p('lab', 'result', 'vet_med'),
    set_category_focus: ember['default'].observer('selected', function() {
      if (this.get('is_inactive')) {
        return;
      }
      return this.get_lab().set_focus_on_selected_category();
    }),
    didInsertElement: function() {
      if (this.get('is_inactive')) {

      } else {
        return this.get_lab().set_focus_on_selected_category();
      }
    },
    get_lab: function() {
      return this.get('lab');
    },
    validate_lab: function(status) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          _this.get_lab().validate_lab_observations(status);
          return resolve();
        };
      })(this));
    },
    click: function(e) {
      var $input, $parents, $select, $target, tag_name;
      $target = $(e.target);
      tag_name = $target.prop('tagName').toLowerCase();
      if (!['select', 'input', 'option'].contains(tag_name)) {
        $parents = $target.parents('tr.ts-lab_result');
        $select = $parents.find('.ts-lab_select').first();
        $input = $parents.find('input:enabled').first();
        if (ember['default'].isPresent($select)) {
          return $select.focus();
        } else {
          return $input.focus();
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});