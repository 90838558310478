define('client/components/thinkspace/artifact/bucket', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    classNames: ['ts-componentable'],
    tvo: ember['default'].inject.service(),
    c_bucket_upload: ns['default'].to_p('artifact', 'bucket', 'upload'),
    c_bucket_file: ns['default'].to_p('artifact', 'bucket', 'file'),
    init: function() {
      this._super();
      return this.get('tvo.helper').load_ownerable_view_records(this.get('model'));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});