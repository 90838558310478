define('client/components/thinkspace/casespace/gradebook/phase', ['exports', 'ember', 'ember-data', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ds, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    phase_manager: ember['default'].inject.service(),
    casespace_gradebook: ember['default'].inject.service(),
    casespace: ember['default'].inject.service(),
    current_space: ember['default'].computed.reads('casespace.current_space'),
    current_assignment: ember['default'].computed.reads('casespace.current_assignment'),
    current_phase: ember['default'].computed.reads('casespace.current_phase'),
    addon_ownerable: ember['default'].computed.reads('casespace.active_addon_ownerable'),
    c_phase_score: ns['default'].to_p('gradebook', 'phase/score'),
    c_phase_state: ns['default'].to_p('gradebook', 'phase/state'),
    c_overview: ns['default'].to_p('gradebook', 'phase/overview'),
    t_header: ns['default'].to_t('gradebook', 'phase/header'),
    t_footer: ns['default'].to_t('gradebook', 'phase/footer'),
    t_overview: ns['default'].to_t('gradebook', 'phase/overview'),
    t_team_select: ns['default'].to_t('gradebook', 'phase/team_select'),
    t_user_select: ns['default'].to_t('gradebook', 'phase/user_select'),
    select_user_prompt: 'Select a Student',
    select_team_prompt: 'Select a Team',
    select_visible: false,
    get_addon_ownerable: function() {
      return this.get('addon_ownerable');
    },
    get_casespace_gradebook: function() {
      return this.get('casespace_gradebook');
    },
    gradebook_users: ember['default'].computed(function() {
      var assignment, space;
      space = this.get('current_space');
      assignment = this.get('current_assignment');
      return this.get_casespace_gradebook().get_gradebook_users(space, assignment);
    }),
    gradebook_phase_teams: ember['default'].computed('current_phase', function() {
      var assignment, phase;
      assignment = this.get('current_assignment');
      phase = this.get('current_phase');
      return this.get_casespace_gradebook().get_gradebook_phase_teams(assignment, phase);
    }),
    phase_score_change: null,
    notify_phase_score_change: function() {
      return this.notifyPropertyChange('phase_score_change');
    },
    total_score: ember['default'].computed('phase_score_change', 'current_phase', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var current_assignment, map;
          map = _this.get('phase_manager.map');
          current_assignment = _this.get('current_assignment');
          if (!current_assignment) {
            return resolve(0);
          }
          return current_assignment.get(ns['default'].to_p('phases')).then(function(phases) {
            var total;
            if (!phases) {
              return resolve(0);
            }
            total = 0;
            phases.forEach(function(phase) {
              var phase_states;
              phase_states = map.get_ownerable_phase_states(phase);
              return phase_states.forEach(function(phase_state) {
                return total += phase_state.get('score') || 0;
              });
            });
            return resolve(total);
          });
        };
      })(this));
      return ds['default'].PromiseObject.create({
        promise: promise
      });
    }),
    actions: {
      toggle_select: function() {
        this.toggleProperty('select_visible');
      },
      next_team: function() {
        return this.next_previous_team({
          index_increment: +1,
          "default": 'firstObject'
        });
      },
      previous_team: function() {
        return this.next_previous_team({
          index_increment: -1,
          "default": 'lastObject'
        });
      },
      next_user: function() {
        return this.next_previous_user({
          index_increment: +1,
          "default": 'firstObject'
        });
      },
      previous_user: function() {
        return this.next_previous_user({
          index_increment: -1,
          "default": 'lastObject'
        });
      },
      select_team: function(team) {
        return this.change_ownerable_selected(team);
      },
      select_user: function(user) {
        return this.change_ownerable_selected(user);
      },
      score_view: function(view) {
        return this.set('current_score_view', view);
      },
      phase_score: function(score) {
        return this.save_phase_score(score);
      },
      phase_state: function(state) {
        return this.save_phase_state(state);
      }
    },
    next_previous_team: function(options) {
      var current_team;
      if (options == null) {
        options = {};
      }
      current_team = this.get_addon_ownerable();
      return this.get('gradebook_phase_teams').then((function(_this) {
        return function(teams) {
          var index, team;
          if (current_team) {
            index = teams.indexOf(current_team);
            if (index != null) {
              if (options.index_increment != null) {
                index += options.index_increment;
              }
              team = teams.objectAt(index);
            }
          }
          if (!team) {
            team = teams.get(options["default"]);
          }
          return _this.send('select_team', team);
        };
      })(this));
    },
    next_previous_user: function(options) {
      var current_user;
      if (options == null) {
        options = {};
      }
      current_user = this.get_addon_ownerable();
      return this.get('gradebook_users').then((function(_this) {
        return function(users) {
          var index, user;
          if (current_user) {
            index = users.indexOf(current_user);
            if (index != null) {
              if (options.index_increment != null) {
                index += options.index_increment;
              }
              user = users.objectAt(index);
            }
          }
          if (!user) {
            user = users.get(options["default"]);
          }
          return _this.send('select_user', user);
        };
      })(this));
    },
    change_ownerable_selected: function(ownerable) {
      if (!ownerable) {
        this.totem_error["throw"](this, "Change to ownerable is blank.");
      }
      this.set('select_visible', false);
      this.totem_scope.view_only_on();
      return this.get('phase_manager').set_addon_ownerable_and_generate_view(ownerable);
    },
    save_phase_score: function(score) {
      var phase, phase_state;
      phase = this.get('current_phase');
      phase_state = phase.get('phase_state');
      return phase_state.get(ns['default'].to_p('phase_score')).then((function(_this) {
        return function(phase_score) {
          if (!phase_score) {
            phase_score = phase.store.createRecord(ns['default'].to_p('phase_score'));
            phase_score.set(ns['default'].to_p('phase_state'), phase_state);
          }
          score = Number(score);
          phase_score.set('score', score);
          if (phase_score.get('isDirty')) {
            return phase_score.save().then(function(record) {
              _this.totem_messages.api_success({
                source: _this,
                model: record,
                action: 'save',
                i18n_path: ns['default'].to_o('phase_score', 'save')
              });
              _this.focus_on_score_input();
              return _this.notify_phase_score_change();
            }, function(error) {
              return _this.totem_messages.api_failure(error, {
                source: _this,
                model: phase_score
              });
            });
          }
        };
      })(this));
    },
    save_phase_state: function(state) {
      var phase, phase_state;
      phase = this.get('current_phase');
      phase_state = phase.get('phase_state');
      if (phase_state.get('current_state') !== state) {
        phase_state.set('new_state', state);
        return phase_state.save().then((function(_this) {
          return function(record) {
            _this.totem_messages.api_success({
              source: _this,
              model: record,
              action: 'save',
              i18n_path: ns['default'].to_o('phase_state', 'save')
            });
            return _this.focus_on_score_input();
          };
        })(this), (function(_this) {
          return function(error) {
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: phase_state
            });
          };
        })(this));
      }
    },
    view_attrs: ember['default'].computed('current_phase', function() {
      return {};
    }),
    focus_on_score_input: function() {
      var $input;
      $input = this.$(':input').first();
      return $input.focus();
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});