define('client/components/thinkspace/weather_forecaster/response/input', ['exports', 'ember', 'totem/ns', 'totem/mixins/validations'], function (exports, ember, ns, val_mixin) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Component.extend(val_mixin['default'], {
    classNameBindings: ['no_errors::thinkspace-weather-forecaster_error'],
    no_errors: ember['default'].computed.or('is_valid', 'is_view_only'),
    input_value: null,
    input_attributes: ember['default'].computed(function() {
      return this.get('metadata.attributes');
    }),
    is_correct_class: null,
    has_score: ember['default'].computed.reads('model.has_score'),
    has_score_message: ember['default'].computed(function() {
      var actual;
      if (this.get('model.is_correct')) {
        this.set('is_correct_class', 'correct');
        return null;
      } else {
        this.set('is_correct_class', 'incorrect');
        actual = this.get('model.actual');
        if (this.get('model.logic') === 'range') {
          return "Correct value is between '" + actual.min + "' and '" + actual.max + "'";
        } else {
          return "Correct value is '" + actual + "'";
        }
      }
    }),
    focusOut: function() {
      return this.validate().then((function(_this) {
        return function() {
          if (_this.get('is_valid')) {
            return _this.sendAction('save', _this.get('input_value'));
          }
        };
      })(this));
    },
    didInsertElement: function() {
      var item_validations, rules;
      item_validations = this.get('metadata.validations');
      if (item_validations) {
        rules = this.camelize_validation_keys(item_validations);
        this.set_validation_rules({
          input_value: rules
        });
      }
      return this.set('input_value', this.get('model.value.input') || this.get('input_attributes.value'));
    },
    validations: {
      input_value: {
        presence: {
          message: 'You must enter a response'
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});