define('client/mixins/thinkspace/intercom/markup', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    open_addon: function() {
      return this._super();
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});