define('client/components/thinkspace/casespace/ownerable/bar/gradebook/phase/overview/score', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    model: null,
    phase_score: null,
    init: function() {
      var model;
      this._super();
      model = this.get('model');
      return model.get(ns['default'].to_p('phase_score')).then((function(_this) {
        return function(phase_score) {
          return _this.set('phase_score', phase_score);
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});