define('client/components/thinkspace/peer_assessment/assessment/type/base', ['exports', 'ember', 'totem/ds/associations', 'thinkspace-base/components/base'], function (exports, ember, ta, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    manager: null,
    model: ember['default'].computed.reads('manager.model'),
    review: ember['default'].computed.reads('manager.review'),
    reviews: ember['default'].computed.reads('manager.reviews'),
    reviewable: ember['default'].computed.reads('manager.reviewable'),
    reviewables: ember['default'].computed.reads('manager.reviewables'),
    points_total: ember['default'].computed.reads('manager.points_total'),
    points_expended: ember['default'].computed.reads('manager.points_expended'),
    points_remaining: ember['default'].computed.reads('manager.points_remaining'),
    is_confirmation: ember['default'].computed.reads('manager.is_confirmation'),
    is_balance: ember['default'].computed.reads('manager.is_balance'),
    is_read_only: ember['default'].computed.reads('manager.is_read_only'),
    is_disabled: ember['default'].computed.reads('manager.is_disabled'),
    has_errors: ember['default'].computed.reads('manager.has_errors'),
    has_negative_points_remaining: ember['default'].computed.reads('manager.has_negative_points_remaining'),
    has_qualitative_section: ember['default'].computed.reads('manager.has_qualitative_section'),
    errors: ember['default'].computed.reads('manager.errors'),
    c_item_quantitative: ta['default'].to_p('tbl:assessment', 'item', 'quantitative'),
    c_item_qualitative: ta['default'].to_p('tbl:assessment', 'item', 'qualitative'),
    c_team: ta['default'].to_p('tbl:assessment', 'team'),
    c_review_summary: ta['default'].to_p('tbl:assessment', 'review', 'summary'),
    t_review: ta['default'].to_t('tbl:assessment', 'type', 'shared', 'review'),
    t_confirmation: ta['default'].to_t('tbl:assessment', 'type', 'shared', 'confirmation'),
    actions: {
      next: function() {
        return this.get('manager').set_reviewable_from_offset(1);
      },
      previous: function() {
        return this.get('manager').set_reviewable_from_offset(-1);
      },
      confirmation: function() {
        return this.get('manager').set_confirmation();
      },
      submit: function() {
        return this.get('manager').submit();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});