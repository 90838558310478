define('client/users/password/show/controller', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Controller.extend({
    c_user_password_show: 'thinkspace/common/user/password/show',
    actions: {
      goto_users_password_success: function() {
        return this.transitionToRoute('users/password.success');
      },
      goto_users_password_fail: function(error) {
        var message;
        message = error.responseJSON.errors.user_message;
        return this.transitionToRoute('users/password.fail', {
          queryParams: {
            message: message
          }
        });
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});