define('client/components/thinkspace/diagnostic_path/builder/path/preview', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base) {

	'use strict';

	var ___DefaultExportObject___;

	___DefaultExportObject___ = base['default'].extend();

	exports['default'] = ___DefaultExportObject___;;

});