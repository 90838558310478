define('client/thinkspace/casespace/assignments/scores/controller', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Controller.extend({
    c_assignment_header: ns['default'].to_p('assignment', 'header'),
    c_gradebook_assignment: ns['default'].to_p('gradebook', 'assignment'),
    c_gradebook_phase: ns['default'].to_p('gradebook', 'phase')
  });

  exports['default'] = ___DefaultExportObject___;;

});