define('client/components/thinkspace/indented_list/list/responses', ['exports', 'ember', 'totem/ns', 'totem/ds/associations', 'thinkspace-base/components/base'], function (exports, ember, ns, ta, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    classNames: ['indented-list'],
    tvo: ember['default'].inject.service(),
    c_response_items: ns['default'].to_p('indented_list', 'response', 'items'),
    init: function() {
      this._super();
      return this.set_responses().then((function(_this) {
        return function() {
          return _this.set('is_ready', true);
        };
      })(this));
    },
    set_responses: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var list, path;
          list = _this.get('model');
          path = ns['default'].to_p('indented:responses');
          return _this.get('tvo.helper').load_ownerable_view_records(list).then(function() {
            var responses;
            responses = list.get('responses');
            if (ember['default'].isBlank(responses) && _this.totem_scope.get('view_user_is_current_user')) {
              return _this.create_response().save().then(function() {
                return resolve();
              });
            } else {
              return resolve();
            }
          });
        };
      })(this));
    },
    create_response: function() {
      var list, response, store;
      store = this.totem_scope.get_store();
      list = this.get('model');
      response = store.createRecord(ns['default'].to_p('indented:response'), {
        ownerable_id: this.totem_scope.get_ownerable_id(),
        ownerable_type: this.totem_scope.get_ownerable_type(),
        value: {
          items: []
        }
      });
      response.set(ns['default'].to_p('indented:list'), list);
      return response;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});