define('client/models/thinkspace/weather_forecaster/assessment', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].polymorphic('authable'), ta['default'].belongs_to('wf:station', {
    reads: {}
  }), ta['default'].has_many('wf:assessment_items', {
    reads: {}
  }), ta['default'].has_many('wf:forecasts', {
    reads: [
      {
        name: 'forecasts_by_date',
        filter: true,
        sort: 'forecast_at:desc'
      }
    ]
  })), {
    title: ta['default'].attr('string'),
    description: ta['default'].attr('string'),
    authable_type: ta['default'].attr('string'),
    authable_id: ta['default'].attr('number')
  });

  exports['default'] = ___DefaultExportObject___;;

});