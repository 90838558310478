define('client/controllers/session_timeout', ['exports', 'ember', 'totem/config', 'totem/logger', 'totem/util'], function (exports, ember, config, logger, util) {

  'use strict';

  var TotemLaterTimer, TotemTimer, ___DefaultExportObject___, session;

  TotemLaterTimer = (function() {
    function TotemLaterTimer(obj1, fn1, interval1) {
      this.obj = obj1;
      this.fn = fn1;
      this.interval = interval1;
      this.timer = null;
    }

    TotemLaterTimer.prototype.start = function() {
      return this.timer = ember['default'].run.later(this.obj, this.fn, this.interval);
    };

    TotemLaterTimer.prototype.cancel = function() {
      if (this.timer) {
        return ember['default'].run.cancel(this.timer);
      }
    };

    return TotemLaterTimer;

  })();

  TotemTimer = (function() {
    function TotemTimer(options1) {
      this.options = options1;
      this.run_type = this.options.run_type || 'later';
      delete this.options.run_type;
      this.timers = [];
    }

    TotemTimer.prototype.reset = function() {
      this.cancel();
      return this.start();
    };

    TotemTimer.prototype.start = function() {
      var args, key, ref, results;
      if (!(this.options && this.run_type)) {
        return;
      }
      switch (this.run_type) {
        case 'later':
          ref = this.options;
          results = [];
          for (key in ref) {
            args = ref[key];
            results.push(this.add_later(args[0], key, args[1]));
          }
          return results;
      }
    };

    TotemTimer.prototype.add_later = function(obj, fn, interval) {
      var timer;
      timer = new TotemLaterTimer(obj, fn, interval);
      timer.start();
      return this.timers.push(timer);
    };

    TotemTimer.prototype.cancel = function() {
      var i, len, ref, timer;
      ref = this.timers;
      for (i = 0, len = ref.length; i < len; i++) {
        timer = ref[i];
        timer.cancel();
        timer = null;
      }
      return this.timers = [];
    };

    return TotemTimer;

  })();

  session = (config['default'] && config['default'].session_timeout) || {};

  ___DefaultExportObject___ = ember['default'].Controller.extend({
    totem_outlet_messages: null,
    seconds_remaining_before_sign_out: null,
    timer_created_at_key: 'totem:session_timer_created_at',
    init: function() {
      this._super();
      this.setup_session_timeout();
      return this.bind_to_storage();
    },
    setup_session_timeout: function() {
      this.timeout_time = session.time || 0;
      this.warning_time = session.warning_time || 0;
      this.timeout_time = this.timeout_time * 1000 * 60;
      this.warning_time = this.warning_time * 1000 * 60;
      this.warning_decrement = session.warning_decrement_by || 1;
      this.warning_decrement = this.warning_decrement * 1000;
      if (this.warning_decrement < 1000) {
        this.warning_decrement = 1000;
      }
      if (logger['default'].is_debug) {
        logger['default'].debug("Config: Timeout=" + this.timeout_time + "  Warning=" + this.warning_time + "  DecBy=" + this.warning_decrement);
      }
      if (this.timeout_time <= this.warning_time) {
        this.warning_time = 0;
      }
      if (this.timeout_time > 0 && this.warning_time > 0) {
        this.session_timer = new TotemTimer({
          show_session_timeout_warning: [this, this.timeout_time - this.warning_time],
          sign_out_user: [this, this.timeout_time]
        });
        this.count_down_time = this.warning_time / 1000;
        if (this.count_down_time > 0) {
          this.count_down_timer = new TotemTimer({
            decrement_count_down_time: [this, this.warning_decrement]
          });
          return this.decrement_by = this.warning_decrement / 1000;
        }
      } else if (this.timeout_time > 0) {
        return this.session_timer = new TotemTimer({
          sign_out_user: [this, this.timeout_time]
        });
      } else {
        return this.session_timer = null;
      }
    },
    cancel_session_timer: function() {
      if (this.session_timer) {
        this.session_timer.cancel();
      }
      if (this.count_down_timer) {
        return this.count_down_timer.cancel();
      }
    },
    reset_session_timer: function(options) {
      var action, type;
      if (!this.session_timer) {
        return;
      }
      if (logger['default'].is_debug) {
        logger['default'].debug("Reset session timer: " + (util['default'].hh_ss_mm(new Date)));
      }
      if (options && options.stay_alive) {
        type = this.ns.to_p('user');
        action = 'stay_alive';
        this.reset_local_store();
        this.session_timer.reset();
        this.store.find(type, {
          action: action
        }).then(null, (function(_this) {
          return function(error) {
            return _this.totem_messages.api_failure(error, {
              model: type,
              action: action
            });
          };
        })(this));
      } else {
        this.reset_local_store();
        this.session_timer.reset();
      }
      if (this.count_down_timer) {
        return this.count_down_timer.cancel();
      }
    },
    reset_local_store: function() {
      return window.localStorage.setItem(this.get_timer_created_at_key(), new Date());
    },
    show_session_timeout_warning: function() {
      var message;
      if (!ember['default'].isPresent(this.get('session.user'))) {
        return;
      }
      if (logger['default'].is_debug) {
        logger['default'].debug("Warn: " + (util['default'].hh_ss_mm(new Date)));
      }
      message = session.warning_message || "Your session is about to timeout!";
      this.totem_messages.warn(message);
      this.totem_messages.message_outlet({
        template_name: 'totem_message_outlet/session_timeout_warning',
        outlet_view: 'session_timeout_warning',
        outlet_controller: this,
        outlet_messages: message
      });
      if (this.count_down_timer) {
        this.set('seconds_remaining_before_sign_out', this.count_down_time);
        return this.count_down_timer.reset();
      }
    },
    hide_session_timeout_warning: function() {
      return this.totem_messages.hide_message_outlet();
    },
    sign_out_user: function() {
      if (logger['default'].is_debug) {
        logger['default'].debug("Sign out user: " + (util['default'].hh_ss_mm(new Date)));
      }
      return this.totem_messages.sign_out_user();
    },
    decrement_count_down_time: function() {
      var time_remaining;
      time_remaining = this.get('seconds_remaining_before_sign_out');
      if ((time_remaining -= this.decrement_by) > 0) {
        this.set('seconds_remaining_before_sign_out', time_remaining);
        return this.count_down_timer.reset();
      } else {
        this.set('seconds_remaining_before_sign_out', 'session timeout');
        return this.count_down_timer.cancel();
      }
    },
    get_timer_created_at_key: function() {
      return this.get('timer_created_at_key');
    },
    bind_to_storage: function() {
      return $(window).bind('storage', (function(_this) {
        return function(e) {
          if (e.originalEvent.key === _this.get_timer_created_at_key() || e.key === _this.get_timer_created_at_key()) {
            _this.reset_session_timer();
            return _this.hide_session_timeout_warning();
          }
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});