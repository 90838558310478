define('client/users/password/new/route', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Route.extend({
    setupController: function(controller, model) {
      model = this.store.createRecord(ns['default'].to_p('password_reset'));
      return controller.set('model', model);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});