define('client/templates/components/thinkspace/simulation/simulations/radiation', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","sim_content");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","sim_left-column");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","sim_header-text");
        var el4 = dom.createTextNode("Radiation Simulation");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("canvas");
        dom.setAttribute(el3,"id","radiation-sim-canvas");
        dom.setAttribute(el3,"class","sim-canvas-setup");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","sim_sub-header-text");
        var el4 = dom.createTextNode("Simulation Controls");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","sim_control-header-text");
        var el4 = dom.createTextNode("Main");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","btn btn-primary btn-small radiation-sim_btn");
        var el4 = dom.createTextNode("Plot Point");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","btn btn-small");
        var el4 = dom.createTextNode("Clear Graph");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("hr");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","radiation-sim_radio-header-left");
        var el4 = dom.createTextNode("Surface Type");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("  Sand");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("  Snow");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("  Plowed");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("  Grass");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","radiation-sim_radio-header-right");
        var el4 = dom.createTextNode("Time of Day");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("  Day");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("  Night");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","sim_right-column");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","sim_sub-header-text");
        var el4 = dom.createTextNode("Plot Graph");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"id","chart-div");
        dom.setAttribute(el3,"class","radiation-sim_chart");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, element = hooks.element, get = hooks.get, inline = hooks.inline;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [0, 0]);
        var element1 = dom.childAt(element0, [4]);
        var element2 = dom.childAt(element0, [5]);
        var element3 = dom.childAt(element0, [7]);
        var element4 = dom.childAt(element0, [8]);
        var morph0 = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
        var morph1 = dom.createMorphAt(dom.childAt(element3, [2]),0,0);
        var morph2 = dom.createMorphAt(dom.childAt(element3, [3]),0,0);
        var morph3 = dom.createMorphAt(dom.childAt(element3, [4]),0,0);
        var morph4 = dom.createMorphAt(dom.childAt(element4, [1]),0,0);
        var morph5 = dom.createMorphAt(dom.childAt(element4, [2]),0,0);
        element(env, element1, context, "action", ["plot_point"], {});
        element(env, element2, context, "action", ["clear_graph"], {});
        inline(env, morph0, context, "radio-button", [], {"checked": get(env, context, "cur_surface"), "value": "sand"});
        inline(env, morph1, context, "radio-button", [], {"checked": get(env, context, "cur_surface"), "value": "snow"});
        inline(env, morph2, context, "radio-button", [], {"checked": get(env, context, "cur_surface"), "value": "plowed"});
        inline(env, morph3, context, "radio-button", [], {"checked": get(env, context, "cur_surface"), "value": "grass"});
        inline(env, morph4, context, "radio-button", [], {"checked": get(env, context, "cur_time_of_day"), "value": "day"});
        inline(env, morph5, context, "radio-button", [], {"checked": get(env, context, "cur_time_of_day"), "value": "night"});
        return fragment;
      }
    };
  }()));

});