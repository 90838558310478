define('client/components/thinkspace/casespace/toolbar', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    thinkspace: ember['default'].inject.service(),
    casespace: ember['default'].inject.service(),
    tagName: '',
    user_expansion_visible: false,
    is_user_profile: false,
    totem_data_config: {
      ability: {
        ajax_source: ns['default'].to_p('spaces')
      }
    },
    current_space: ember['default'].computed.reads('casespace.current_space'),
    no_addon: ember['default'].computed.not('casespace.active_addon'),
    switch_user: ember['default'].computed.bool('session.can_switch_user'),
    show_switch_user: ember['default'].computed.and('switch_user', 'no_addon', 'current_space'),
    c_switch_user: ns['default'].to_p('toolbar', 'switch_user'),
    c_ownerable_bar: ns['default'].to_p('casespace', 'ownerable', 'bar'),
    observe_can_update: ember['default'].observer('can.update', function() {
      if (this.get('can.update') && this.get('casespace.current_assignment.can.update')) {
        return this.set('session.secure.switch_user', true);
      }
    }),
    hide_all_expansions: function() {
      return this.set('user_expansion_visible', false);
    },
    actions: {
      toggle_support: function() {
        this.set('support_button_pressed', true);
        return this.toggleProperty('is_support_visible');
      },
      hide_expansions: function() {
        return this.hide_all_expansions();
      },
      sign_out: function() {
        return this.totem_messages.sign_out_user();
      },
      toggle_users: function() {
        this.toggleProperty('user_expansion_visible');
      },
      transition_to_profile: function() {
        return this.transitionToRoute('users.show.profile', this.get('session.current_user'));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});