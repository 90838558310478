define('client/thinkspace/casespace/case_manager/assignments/peer_assessment/assessments/route', ['exports', 'ember', 'thinkspace-casespace-case-manager/routes/base', 'totem/ns'], function (exports, ember, base, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    model: function() {
      return this.modelFor(ns['default'].to_p('case_manager', 'assignments', 'peer_assessment'));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});