define('client/templates/components/thinkspace/common/user/password/success', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Back to Log In");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ts-password fade-in");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","ts-password_content");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-grid_row");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","large-4 small-10 small-centered ts-grid_columns");
        var el5 = dom.createElement("h2");
        dom.setAttribute(el5,"class","ts-password_header");
        var el6 = dom.createTextNode("Think");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","ts-password_branding-space");
        var el7 = dom.createTextNode("Space");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-password_invitation");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-grid_row");
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","small-4 ts-grid_columns");
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","ts-password_invitation-img");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","small-8 ts-grid_columns");
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","ts-password_invitation-text");
        var el9 = dom.createElement("h5");
        dom.setAttribute(el9,"class","ts-password_invitation-header");
        var el10 = dom.createTextNode("Password has been changed.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("p");
        dom.setAttribute(el9,"class","ts-password_invitation-details");
        var el10 = dom.createTextNode("Your password has been reset. Please log in with your new password.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-password_submit");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var morph0 = dom.createMorphAt(dom.childAt(fragment, [0, 0, 0, 0, 2]),0,0);
        block(env, morph0, context, "link-to", ["users.sign_in"], {"class": "ts-password_submit-action btn btn-small btn-primary"}, child0, null);
        return fragment;
      }
    };
  }()));

});