define('client/users/password/show/view', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].View.extend({
    templateName: ns['default'].to_p('users', 'password', 'show')
  });

  exports['default'] = ___DefaultExportObject___;;

});