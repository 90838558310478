define('client/components/thinkspace/peer_assessment/assessment', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base', 'thinkspace-peer-assessment/managers/evaluation'], function (exports, ember, ns, base_component, eval_manager) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    classNames: ['ts-componentable'],
    has_team_members: true,
    c_item_quantitative: ns['default'].to_p('tbl:assessment', 'item', 'quantitative'),
    c_item_qualitative: ns['default'].to_p('tbl:assessment', 'item', 'qualitative'),
    c_review_summary: ns['default'].to_p('tbl:assessment', 'review', 'summary'),
    c_loader: ns['default'].to_p('common', 'loader'),
    c_assessment: ns['default'].to_p('tbl:assessment', 'type', 'base'),
    init: function() {
      this._super();
      return this.set_manager().then((function(_this) {
        return function() {
          return _this.set_all_data_loaded();
        };
      })(this));
    },
    set_manager: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var manager, model;
          model = _this.get('model');
          manager = eval_manager['default'].create({
            container: _this.container,
            component: _this,
            model: model
          });
          return manager.set_user_data().then(function() {
            _this.set('manager', manager);
            return resolve();
          }, function(error) {
            return _this.set('has_team_members', false);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});