define('client/models/thinkspace/weather_forecaster/forecast', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].polymorphic('ownerable'), ta['default'].belongs_to('wf:assessment'), ta['default'].has_many('wf:responses')), {
    is_locked: ta['default'].attr('boolean'),
    state: ta['default'].attr('string'),
    score: ta['default'].attr('number'),
    forecast_at: ta['default'].attr('date'),
    ownerable_type: ta['default'].attr('string'),
    ownerable_id: ta['default'].attr('number'),
    completed: ember['default'].computed.equal('state', 'completed'),
    locked: ember['default'].computed.equal('state', 'locked'),
    response_for_assessment_item: function(assessment_item) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get(ta['default'].to_p('wf:responses')).then(function(responses) {
            var find_id;
            find_id = parseInt(assessment_item.get('id'));
            return resolve(responses.findBy('assessment_item_id', find_id));
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});