define('client/components/thinkspace/builder/steps/phases', ['exports', 'ember', 'totem/ns', 'totem/ds/associations', 'thinkspace-builder/components/wizard/steps/base'], function (exports, ember, ns, ta, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    title: ember['default'].computed.reads('builder.model.title'),
    archived_phases: null,
    active_phases: null,
    is_ordering: false,
    is_viewing_archived: false,
    is_adding_phase: false,
    c_phase: ns['default'].to_p('builder', 'steps', 'parts', 'phases', 'phase'),
    c_new_phase: ns['default'].to_p('builder', 'steps', 'parts', 'phases', 'new'),
    c_phase_errors: ns['default'].to_p('builder', 'shared', 'phases', 'errors'),
    init: function() {
      this._super();
      return this.load_assignment().then((function(_this) {
        return function() {
          return _this.set_all_data_loaded();
        };
      })(this));
    },
    load_assignment: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model;
          model = _this.get('model');
          return model.get(ns['default'].to_p('phases')).then(function(phases) {
            if (phases.get('length') > 0) {
              return resolve();
            }
            return _this.tc.query(ns['default'].to_p('assignment'), {
              id: model.get('id'),
              action: 'load'
            }, {
              single: true
            }).then(function(assignment) {
              return resolve();
            });
          });
        };
      })(this));
    },
    actions: {
      toggle_is_ordering: function() {
        return this.toggleProperty('is_ordering');
      },
      toggle_is_viewing_archived: function() {
        return this.toggleProperty('is_viewing_archived');
      },
      toggle_is_adding_phase: function() {
        return this.toggleProperty('is_adding_phase');
      },
      reset_is_adding_phase: function() {
        return this.set('is_adding_phase', false);
      },
      cancel_ordering: function() {
        var model;
        model = this.get('model');
        return model.get(ns['default'].to_p('phases')).then((function(_this) {
          return function(phases) {
            phases.forEach(function(phase) {
              if (phase.get('isDirty')) {
                return phase.rollback();
              }
            });
            return _this.set('is_ordering', false);
          };
        })(this));
      },
      save_order: function() {
        var model;
        model = this.get('model');
        this.totem_messages.show_loading_outlet();
        return model.save_phase_positions().then((function(_this) {
          return function() {
            _this.totem_messages.hide_loading_outlet();
            return _this.set('is_ordering', false);
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});