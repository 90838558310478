define('client/models/thinkspace/lab/chart', ['exports', 'ember', 'totem/ds/associations', 'client/models/thinkspace/common/componentable'], function (exports, ember, ta, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(ta['default'].add(ta['default'].polymorphic('authable'), ta['default'].has_many('lab:categories', {
    reads: {
      sort: 'position'
    }
  })), {
    title: ta['default'].attr('string'),
    authable_type: ta['default'].attr('string'),
    authable_id: ta['default'].attr('number'),
    edit_component: ta['default'].to_p('lab', 'admin', 'chart', 'admin'),
    admin_exit: function() {
      var store;
      store = this.get('store');
      store.unloadAll(ta['default'].to_p('lab:observation'));
      store.unloadAll(ta['default'].to_p('lab:result'));
      store.unloadAll(ta['default'].to_p('lab:category'));
      return store.unloadAll(ta['default'].to_p('lab:chart'));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});