define('client/templates/components/thinkspace/builder/phases/edit/settings', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","ts-forms_field-container");
            var el2 = dom.createElement("label");
            dom.setAttribute(el2,"class","ts-forms_field-label");
            var el3 = dom.createTextNode("Max Score");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
            inline(env, morph0, context, "input", [], {"value": get(env, context, "max_score"), "class": "ts-forms_input-tiny"});
            return fragment;
          }
        };
      }());
      var child1 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","dialogue dialogue-error");
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("You cannot edit the max score of this phase.");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createTextNode("Max Score: ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, content = hooks.content;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            content(env, morph0, context, "max_score");
            return fragment;
          }
        };
      }());
      var child2 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","dialogue dialogue-error");
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("You cannot edit the auto scoring of this phase.");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            return fragment;
          }
        };
      }());
      var child3 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","dialogue dialogue-error");
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("You cannot edit the phase unlocking of this phase.");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            return fragment;
          }
        };
      }());
      var child4 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","dialogue dialogue-error");
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("You cannot edit the input validation of this phase.");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            return fragment;
          }
        };
      }());
      var child5 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","dialogue dialogue-error");
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("You cannot change the default state of this phase.");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            return fragment;
          }
        };
      }());
      var child6 = (function() {
        var child0 = (function() {
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("img");
              dom.setAttribute(el1,"style","width:100px; margin-right:1em;");
              dom.setAttribute(el1,"src","/assets/images/ts-icons/builder/ts-case_active-status-icons_draft.svg");
              dom.setAttribute(el1,"class","ts-builder_phase-type-img");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h1");
              var el2 = dom.createTextNode("Inactive");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createTextNode("Your phase is currently inactive, it will not be seen by your students.");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","ts-forms_field-container");
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","btn btn-small btn-primary");
              var el3 = dom.createTextNode("Activate Phase");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, element = hooks.element;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var element6 = dom.childAt(fragment, [3, 0]);
              element(env, element6, context, "action", ["activate"], {"on": "click"});
              return fragment;
            }
          };
        }());
        var child1 = (function() {
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("img");
              dom.setAttribute(el1,"style","width:100px; margin-right:1em;");
              dom.setAttribute(el1,"src","/assets/images/ts-icons/builder/ts-case_active-status-icons_active.svg");
              dom.setAttribute(el1,"class","ts-builder_phase-type-img");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h1");
              var el2 = dom.createTextNode("Active");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createTextNode("Your phase is currently active and will be seen by your students.");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","ts-forms_field-container");
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","btn btn-small btn-default");
              var el3 = dom.createTextNode("Deactivate Phase");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, element = hooks.element;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var element5 = dom.childAt(fragment, [3, 0]);
              element(env, element5, context, "action", ["inactivate"], {"on": "click"});
              return fragment;
            }
          };
        }());
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","ts-forms_section-heading");
            var el2 = dom.createElement("h1");
            var el3 = dom.createTextNode("Phase State");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","ts-forms_field-container");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, block = hooks.block;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element7 = dom.childAt(fragment, [1]);
            var morph0 = dom.createMorphAt(element7,0,0);
            var morph1 = dom.createMorphAt(element7,1,1);
            block(env, morph0, context, "if", [get(env, context, "model.is_not_active")], {}, child0, null);
            block(env, morph1, context, "if", [get(env, context, "model.is_active")], {}, child1, null);
            return fragment;
          }
        };
      }());
      var child7 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","ts-forms_section-heading");
            var el2 = dom.createElement("h1");
            var el3 = dom.createTextNode("Phase State");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","ts-forms_field-container");
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"style","width:100px; margin-right:1em;");
            dom.setAttribute(el2,"src","/assets/images/ts-icons/builder/ts-case_active-status-icons_archive.svg");
            dom.setAttribute(el2,"class","ts-builder_phase-type-img");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h1");
            var el3 = dom.createTextNode("Archived");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            var el3 = dom.createTextNode("This phase is currently archived, so it cannot be seen by you or students in the phase list.  If you save the phase as a draft, it will allow you to use it in your cases again.");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","ts-forms_field-container");
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","btn btn-small btn-ownership");
            var el4 = dom.createTextNode("Save Phase as Draft");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, element = hooks.element;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element4 = dom.childAt(fragment, [1, 3, 0]);
            element(env, element4, context, "action", ["inactivate"], {"on": "click"});
            return fragment;
          }
        };
      }());
      var child8 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","ts-forms_section-heading");
            var el2 = dom.createElement("h1");
            var el3 = dom.createTextNode("Phase Archival");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","ts-forms_field-container");
            var el2 = dom.createElement("p");
            var el3 = dom.createTextNode("Archive the phase.  Students cannot see archived phases and you will not see it in the phase list.  You can bring it back at any time.");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","ts-forms_field-container");
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","btn btn-small btn-default");
            var el4 = dom.createTextNode("Archive Phase");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, element = hooks.element;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element3 = dom.childAt(fragment, [1, 1, 0]);
            element(env, element3, context, "action", ["archive"], {"on": "click"});
            return fragment;
          }
        };
      }());
      var child9 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","dialogue dialogue-error");
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("You cannot edit the team based of this phase.");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            return fragment;
          }
        };
      }());
      var child10 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                isHTMLBars: true,
                revision: "Ember@1.11.1",
                blockParams: 0,
                cachedFragment: null,
                hasRendered: false,
                build: function build(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","ts-forms_field-container");
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","ts-forms_field-label-wrapper");
                  var el3 = dom.createElement("div");
                  dom.setAttribute(el3,"class","ts-forms_field-label");
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("p");
                  dom.setAttribute(el3,"class","ts-forms_field-description");
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                render: function render(context, env, contextualElement) {
                  var dom = env.dom;
                  var hooks = env.hooks, inline = hooks.inline, get = hooks.get;
                  dom.detectNamespace(contextualElement);
                  var fragment;
                  if (env.useFragmentCache && dom.canClone) {
                    if (this.cachedFragment === null) {
                      fragment = this.build(dom);
                      if (this.hasRendered) {
                        this.cachedFragment = fragment;
                      } else {
                        this.hasRendered = true;
                      }
                    }
                    if (this.cachedFragment) {
                      fragment = dom.cloneNode(this.cachedFragment, true);
                    }
                  } else {
                    fragment = this.build(dom);
                  }
                  var element1 = dom.childAt(fragment, [0]);
                  var element2 = dom.childAt(element1, [0]);
                  var morph0 = dom.createMorphAt(dom.childAt(element2, [0]),0,0);
                  var morph1 = dom.createMorphAt(dom.childAt(element2, [1]),0,0);
                  var morph2 = dom.createMorphAt(element1,1,1);
                  inline(env, morph0, context, "t", ["builder.casespace.phases.edit.instruct.team_category"], {});
                  inline(env, morph1, context, "t", ["builder.casespace.phases.edit.describe.team_category"], {});
                  inline(env, morph2, context, "view", ["select"], {"selection": get(env, context, "selected_team_category"), "content": get(env, context, "team_categories"), "optionValuePath": "content.id", "optionLabelPath": "content.title", "class": "btn btn-small", "prompt": "Not team based"});
                  return fragment;
                }
              };
            }());
            var child1 = (function() {
              return {
                isHTMLBars: true,
                revision: "Ember@1.11.1",
                blockParams: 0,
                cachedFragment: null,
                hasRendered: false,
                build: function build(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","dialogue dialogue-error");
                  var el2 = dom.createElement("span");
                  var el3 = dom.createTextNode("You cannot change the team category, it is presently ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                render: function render(context, env, contextualElement) {
                  var dom = env.dom;
                  var hooks = env.hooks, content = hooks.content;
                  dom.detectNamespace(contextualElement);
                  var fragment;
                  if (env.useFragmentCache && dom.canClone) {
                    if (this.cachedFragment === null) {
                      fragment = this.build(dom);
                      if (this.hasRendered) {
                        this.cachedFragment = fragment;
                      } else {
                        this.hasRendered = true;
                      }
                    }
                    if (this.cachedFragment) {
                      fragment = dom.cloneNode(this.cachedFragment, true);
                    }
                  } else {
                    fragment = this.build(dom);
                  }
                  var morph0 = dom.createMorphAt(dom.childAt(fragment, [0, 0]),1,1);
                  content(env, morph0, context, "selected_team_category.title");
                  return fragment;
                }
              };
            }());
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.1",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                var hooks = env.hooks, get = hooks.get, block = hooks.block;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, null);
                dom.insertBoundary(fragment, 0);
                block(env, morph0, context, "if", [get(env, context, "can.team_category")], {}, child0, child1);
                return fragment;
              }
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                isHTMLBars: true,
                revision: "Ember@1.11.1",
                blockParams: 0,
                cachedFragment: null,
                hasRendered: false,
                build: function build(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","ts-forms_field-container");
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","ts-forms_field-label");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                render: function render(context, env, contextualElement) {
                  var dom = env.dom;
                  var hooks = env.hooks, inline = hooks.inline, get = hooks.get;
                  dom.detectNamespace(contextualElement);
                  var fragment;
                  if (env.useFragmentCache && dom.canClone) {
                    if (this.cachedFragment === null) {
                      fragment = this.build(dom);
                      if (this.hasRendered) {
                        this.cachedFragment = fragment;
                      } else {
                        this.hasRendered = true;
                      }
                    }
                    if (this.cachedFragment) {
                      fragment = dom.cloneNode(this.cachedFragment, true);
                    }
                  } else {
                    fragment = this.build(dom);
                  }
                  var element0 = dom.childAt(fragment, [0]);
                  var morph0 = dom.createMorphAt(dom.childAt(element0, [0]),0,0);
                  var morph1 = dom.createMorphAt(element0,1,1);
                  inline(env, morph0, context, "t", ["builder.casespace.phases.edit.instruct.team_set"], {});
                  inline(env, morph1, context, "view", ["select"], {"selection": get(env, context, "selected_team_set"), "content": get(env, context, "team_sets"), "optionValuePath": "content.id", "optionLabelPath": "content.title", "class": "btn btn-small", "prompt": "No team set."});
                  return fragment;
                }
              };
            }());
            var child1 = (function() {
              return {
                isHTMLBars: true,
                revision: "Ember@1.11.1",
                blockParams: 0,
                cachedFragment: null,
                hasRendered: false,
                build: function build(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","dialogue dialogue-error");
                  var el2 = dom.createElement("span");
                  var el3 = dom.createTextNode("You cannot change the team set, it is presently ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                render: function render(context, env, contextualElement) {
                  var dom = env.dom;
                  var hooks = env.hooks, content = hooks.content;
                  dom.detectNamespace(contextualElement);
                  var fragment;
                  if (env.useFragmentCache && dom.canClone) {
                    if (this.cachedFragment === null) {
                      fragment = this.build(dom);
                      if (this.hasRendered) {
                        this.cachedFragment = fragment;
                      } else {
                        this.hasRendered = true;
                      }
                    }
                    if (this.cachedFragment) {
                      fragment = dom.cloneNode(this.cachedFragment, true);
                    }
                  } else {
                    fragment = this.build(dom);
                  }
                  var morph0 = dom.createMorphAt(dom.childAt(fragment, [0, 0]),1,1);
                  content(env, morph0, context, "selected_team_set.title");
                  return fragment;
                }
              };
            }());
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.1",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                var hooks = env.hooks, get = hooks.get, block = hooks.block;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, null);
                dom.insertBoundary(fragment, 0);
                block(env, morph0, context, "if", [get(env, context, "can.team_set")], {}, child0, child1);
                return fragment;
              }
            };
          }());
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, get = hooks.get, block = hooks.block;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
              var morph1 = dom.createMorphAt(fragment,1,1,contextualElement);
              dom.insertBoundary(fragment, null);
              dom.insertBoundary(fragment, 0);
              block(env, morph0, context, "if", [get(env, context, "has_team_categories")], {}, child0, null);
              block(env, morph1, context, "if", [get(env, context, "has_team_sets")], {}, child1, null);
              return fragment;
            }
          };
        }());
        var child1 = (function() {
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","dialogue dialogue-error");
              var el2 = dom.createTextNode("This space doesn't have any team sets.  You'll need to go make one before you enable the phase to be team based.");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              return fragment;
            }
          };
        }());
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","ts-forms_field-subcontainer");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, block = hooks.block;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
            block(env, morph0, context, "if", [get(env, context, "has_team_sets")], {}, child0, child1);
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","ts-forms_form-container");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","small-12 medium-6 large-6 ts-grid_columns ts-grid_columns-thin");
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","ts-forms_section-heading");
          var el5 = dom.createElement("h1");
          var el6 = dom.createTextNode("Phase Scoring");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("p");
          var el6 = dom.createTextNode("Set your scoring preferences for this phase.");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","ts-forms_field-container");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"class","ts-checkbox");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"class","ts-checkbox_label");
          var el8 = dom.createTextNode("Phase auto scoring");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"class","ts-radio_description");
          var el8 = dom.createTextNode("When the phase is submitted, ThinkSpace will automatically award full points to the student.");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","ts-forms_section-heading");
          var el5 = dom.createElement("h1");
          var el6 = dom.createTextNode("Phase Behavior");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","ts-forms_field-container");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"class","ts-checkbox");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"class","ts-checkbox_label");
          var el8 = dom.createTextNode("Unlock next phase");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"class","ts-radio_description");
          var el8 = dom.createTextNode("Next phase is unlocked when student submits this phase.");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","ts-forms_field-container");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"class","ts-checkbox");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"class","ts-checkbox_label");
          var el8 = dom.createTextNode("Validate inputs");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"class","ts-radio_description");
          var el8 = dom.createTextNode("Require all text inputs to be filled out before the student can submit the phase. Presently, this setting only affects inputs in the HTML components.");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","ts-forms_field-container");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"class","ts-checkbox");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"class","ts-checkbox_label");
          var el8 = dom.createTextNode("Phase initially locked");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"class","ts-radio_description");
          var el8 = dom.createTextNode("Phase is initial locked preventing students to access it until unlocked or phase before is set to unlock it. ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","small-12 medium-6 large-6 ts-grid_columns ts-grid_columns-thin");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","ts-forms_section-heading");
          var el5 = dom.createElement("h1");
          var el6 = dom.createTextNode("Team-Based Learning");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("p");
          var el6 = dom.createTextNode("Manage which team set is assigned to this p hase and how the teams interact with the phase.");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","ts-forms_field-container");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","ts-forms_field-container");
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"class","ts-checkbox");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"class","ts-checkbox_label");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, block = hooks.block, element = hooks.element, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element8 = dom.childAt(fragment, [0, 0]);
          var element9 = dom.childAt(element8, [0]);
          var element10 = dom.childAt(element9, [0]);
          var element11 = dom.childAt(element10, [3]);
          var element12 = dom.childAt(element11, [1]);
          var element13 = dom.childAt(element9, [1]);
          var element14 = dom.childAt(element13, [1]);
          var element15 = dom.childAt(element14, [1]);
          var element16 = dom.childAt(element13, [2]);
          var element17 = dom.childAt(element16, [1]);
          var element18 = dom.childAt(element13, [3]);
          var element19 = dom.childAt(element18, [1]);
          var element20 = dom.childAt(element8, [1]);
          var element21 = dom.childAt(element20, [3]);
          var element22 = dom.childAt(element21, [1]);
          var element23 = dom.childAt(element22, [0]);
          var morph0 = dom.createMorphAt(element10,2,2);
          var morph1 = dom.createMorphAt(element11,0,0);
          var morph2 = dom.createMorphAt(element12,0,0);
          var morph3 = dom.createMorphAt(element14,0,0);
          var morph4 = dom.createMorphAt(element15,0,0);
          var morph5 = dom.createMorphAt(element16,0,0);
          var morph6 = dom.createMorphAt(element17,0,0);
          var morph7 = dom.createMorphAt(element18,0,0);
          var morph8 = dom.createMorphAt(element19,0,0);
          var morph9 = dom.createMorphAt(element20,0,0);
          var morph10 = dom.createMorphAt(element20,1,1);
          var morph11 = dom.createMorphAt(element21,0,0);
          var morph12 = dom.createMorphAt(element23,0,0);
          var morph13 = dom.createMorphAt(dom.childAt(element23, [1]),0,0);
          var morph14 = dom.createMorphAt(element22,1,1);
          block(env, morph0, context, "if", [get(env, context, "can.max_score")], {}, child0, child1);
          block(env, morph1, context, "unless", [get(env, context, "can.auto_score")], {}, child2, null);
          element(env, element12, context, "action", ["toggle_auto_score"], {"on": "click"});
          inline(env, morph2, context, "component", [get(env, context, "c_checkbox")], {"checked": get(env, context, "auto_score"), "class": "case_manager_phase-validate-checkbox", "disable_click": true});
          block(env, morph3, context, "unless", [get(env, context, "can.unlock_phase")], {}, child3, null);
          element(env, element15, context, "action", ["toggle_unlock_phase"], {"on": "click"});
          inline(env, morph4, context, "component", [get(env, context, "c_checkbox")], {"checked": get(env, context, "unlock_phase"), "class": "case_manager_phase-validate-checkbox", "disable_click": true});
          block(env, morph5, context, "unless", [get(env, context, "can.configuration_validate")], {}, child4, null);
          element(env, element17, context, "action", ["toggle_configuration_validate"], {"on": "click"});
          inline(env, morph6, context, "component", [get(env, context, "c_checkbox")], {"checked": get(env, context, "configuration_validate"), "class": "case_manager_phase-validate-checkbox", "disable_click": true});
          block(env, morph7, context, "unless", [get(env, context, "can.default_state")], {}, child5, null);
          element(env, element19, context, "action", ["toggle_default_state_locked"], {"on": "click"});
          inline(env, morph8, context, "component", [get(env, context, "c_checkbox")], {"checked": get(env, context, "default_state_locked"), "class": "case_manager_phase-validate-checkbox", "disable_click": true});
          block(env, morph9, context, "if", [get(env, context, "model.is_not_archived")], {}, child6, null);
          block(env, morph10, context, "if", [get(env, context, "model.is_archived")], {}, child7, child8);
          block(env, morph11, context, "unless", [get(env, context, "can.team_based")], {}, child9, null);
          element(env, element23, context, "action", ["toggle_is_team_based"], {"on": "click"});
          inline(env, morph12, context, "component", [get(env, context, "c_checkbox")], {"checked": get(env, context, "is_team_based"), "class": "case_manager_phase-validate-checkbox", "disable_click": true});
          inline(env, morph13, context, "t", ["builder.casespace.phases.edit.instruct.team_based"], {});
          block(env, morph14, context, "if", [get(env, context, "is_team_based")], {}, child10, null);
          return fragment;
        }
      };
    }());
    var child1 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, null);
          dom.insertBoundary(fragment, 0);
          inline(env, morph0, context, "component", [get(env, context, "c_loader")], {});
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","row");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","small-12 medium-12 large-12 ts-grid_columns ts-grid_columns-thin");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-forms_inline-heading");
        var el4 = dom.createElement("h4");
        var el5 = dom.createTextNode("Phase Settings");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-forms_heading-actions");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","btn btn-small btn-primary");
        var el6 = dom.createTextNode("Update Settings");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","btn btn-small btn-default");
        var el6 = dom.createTextNode("Back to Content");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, element = hooks.element, get = hooks.get, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element24 = dom.childAt(fragment, [0, 0, 0, 1]);
        var element25 = dom.childAt(element24, [0]);
        var element26 = dom.childAt(element24, [1]);
        var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
        dom.insertBoundary(fragment, null);
        element(env, element25, context, "action", ["save"], {"on": "click"});
        element(env, element26, context, "action", ["cancel"], {"on": "click"});
        block(env, morph0, context, "if", [get(env, context, "all_data_loaded")], {}, child0, child1);
        return fragment;
      }
    };
  }()));

});