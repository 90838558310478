define('client/users/sign_up/view', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].View.extend({
    templateName: ns['default'].to_p('users', 'sign_up')
  });

  exports['default'] = ___DefaultExportObject___;;

});