define('client/components/thinkspace/casespace/case_manager/assignment/wizards/casespace/steps/logistics', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace-case-manager/components/wizards/steps/base', 'totem/mixins/validations', 'totem/mixins/ckeditor'], function (exports, ember, ns, base, val_mixin, ckeditor_mixin) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(val_mixin['default'], ckeditor_mixin['default'], {
    step: 'logistics',
    instructions: ember['default'].computed.reads('model.instructions'),
    release_at: ember['default'].computed.reads('model.release_at'),
    due_at: ember['default'].computed.reads('model.due_at'),
    page_title: ember['default'].computed.reads('model.title'),
    model_state: ember['default'].computed.reads('model.state'),
    actions: {
      complete: function() {
        var wizard_manager;
        if (!this.get('isValid')) {
          return;
        }
        wizard_manager = this.get('wizard_manager');
        wizard_manager.send_action('set_instructions', this.get('instructions'));
        wizard_manager.send_action('set_release_at', this.get('release_at'));
        wizard_manager.send_action('set_due_at', this.get('due_at'));
        wizard_manager.send_action('set_model_state', this.get('model_state'));
        return wizard_manager.send_action('complete_step', 'logistics');
      }
    },
    validations: {
      release_at: {
        presence: true
      },
      due_at: {
        presence: true
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});