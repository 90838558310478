define('client/models/thinkspace/peer_assessment/assessment', ['exports', 'ember', 'totem/ds/associations', 'totem/util', 'client/models/thinkspace/common/componentable'], function (exports, ember, ta, util, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(ta['default'].add(ta['default'].polymorphic('authable'), ta['default'].has_many('tbl:review_sets', {
    reads: {
      filter: true,
      name: 'review_sets'
    }
  })), {
    authable_type: ta['default'].attr('string'),
    authable_id: ta['default'].attr('number'),
    state: ta['default'].attr('string'),
    value: ta['default'].attr(),
    is_categories: ember['default'].computed.equal('assessment_type', 'categories'),
    is_balance: ember['default'].computed.equal('assessment_type', 'balance'),
    is_custom: ember['default'].computed.equal('assessment_type', 'custom'),
    is_active: ember['default'].computed.equal('state', 'active'),
    is_approved: ember['default'].computed.equal('state', 'approved'),
    is_read_only: ember['default'].computed.or('is_active', 'is_approved'),
    is_not_active: ember['default'].computed.not('is_active'),
    qualitative_items: ember['default'].computed('value.qualitative.@each', function() {
      return this.get('value.qualitative');
    }),
    quantitative_items: ember['default'].computed('value.quantitative.@each', function() {
      return this.get('value.quantitative');
    }),
    assessment_type: ember['default'].computed('value.options.type', function() {
      return this.get('value.options.type');
    }),
    points: ember['default'].computed('value.options.points', function() {
      return this.get('value.options.points');
    }),
    points_per_member: ember['default'].computed('value.options.points.per_member', function() {
      return this.get('points.per_member');
    }),
    points_min: ember['default'].computed('value.options.points.min', function() {
      return this.get('points.min');
    }),
    points_max: ember['default'].computed('value.options.points.max', function() {
      return this.get('points.max');
    }),
    points_descriptive_enabled: ember['default'].computed('value.options.points.descriptive.enabled', function() {
      return this.get('points.descriptive.enabled');
    }),
    points_descriptive_low: ember['default'].computed('value.options.points.descriptive.values', function() {
      var values;
      values = this.get('points.descriptive.values');
      if (ember['default'].isArray(values)) {
        return values.get('firstObject');
      }
    }),
    points_descriptive_medium: ember['default'].computed('value.options.points.descriptive.values', function() {
      var values;
      values = this.get('points.descriptive.values');
      if (ember['default'].isArray(values)) {
        return values[1];
      }
    }),
    points_descriptive_high: ember['default'].computed('value.options.points.descriptive.values', function() {
      var values;
      values = this.get('points.descriptive.values');
      if (ember['default'].isArray(values)) {
        return values.get('lastObject');
      }
    }),
    points_different: ember['default'].computed('value.options.points.different', function() {
      var points_different;
      points_different = this.get('points.different');
      if (ember['default'].isPresent(points_different)) {
        return points_different;
      }
      return true;
    }),
    positive_qualitative_items: ember['default'].computed('value.qualitative.@each', function() {
      return this.get_qualitative_items_for_type('positive');
    }),
    constructive_qualitative_items: ember['default'].computed('value.qualitative.@each', function() {
      return this.get_qualitative_items_for_type('constructive');
    }),
    quant_items: ember['default'].computed('value.quantitative.@each', function() {
      return this.get('value.quantitative');
    }),
    qual_items: ember['default'].computed('value.qualitative.@each', function() {
      return this.get('value.qualitative');
    }),
    didLoad: function() {
      return this.validate_value();
    },
    get_qualitative_items_for_type: function(type) {
      var i, item, items, items_of_type, len;
      items = this.get('qualitative_items');
      items_of_type = [];
      for (i = 0, len = items.length; i < len; i++) {
        item = items[i];
        if (ember['default'].isEqual(item.feedback_type, type)) {
          items_of_type.pushObject(item);
        }
      }
      return items_of_type;
    },
    validate_value: function() {
      var value;
      value = this.get('value');
      if (!ember['default'].isArray(value.qualitative)) {
        value.qualitative = [];
      }
      if (!ember['default'].isArray(value.quantitative)) {
        value.quantitative = [];
      }
      if (!ember['default'].isPresent(value.options)) {
        value.options = {};
      }
      if (!ember['default'].isPresent(value.options.points)) {
        value.options.points = {};
      }
      if (!ember['default'].isPresent(value.options.points.descriptive)) {
        return value.options.points.descriptive = {};
      }
    },
    value_did_change: function() {
      return this.propertyDidChange('value');
    },
    set_points_per_member: function(value) {
      return util['default'].set_path_value(this, 'value.options.points.per_member', value);
    },
    reset_points_per_member: function() {
      return util['default'].set_path_value(this, 'value.options.points.per_member', null);
    },
    set_points_min: function(value) {
      return util['default'].set_path_value(this, 'value.options.points.min', value);
    },
    set_points_max: function(value) {
      return util['default'].set_path_value(this, 'value.options.points.max', value);
    },
    set_points_different: function(value) {
      return util['default'].set_path_value(this, 'value.options.points.different', value);
    },
    reset_points_different: function(value) {
      return util['default'].set_path_value(this, 'value.options.points.different', true);
    },
    set_type: function(type) {
      return util['default'].set_path_value(this, 'value.options.type', type);
    },
    set_is_balance: function() {
      return this.set_type('balance');
    },
    reset_is_balance: function() {
      return this.reset_type();
    },
    reset_type: function() {
      return this.set_type(null);
    },
    set_is_categories: function() {
      return this.set_type('categories');
    },
    builder_abilities: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var abilities, can_edit;
          can_edit = !_this.get('is_read_only');
          abilities = {
            has_builder_content: can_edit,
            has_builder_settings: can_edit
          };
          return resolve(abilities);
        };
      })(this));
    },
    builder_messages: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var is_active, is_approved, messages;
          messages = [];
          is_active = _this.get('is_active');
          is_approved = _this.get('is_approved');
          if (is_active) {
            messages.pushObject('The evaluation has been activated, so it cannot be edited.');
            messages.pushObject('The evaluation has been activated, so you cannot modify the teams associated with it.');
          }
          if (is_approved) {
            messages.pushObject('The evaluation has been sent, it has been set to read-only.');
          }
          return resolve(messages);
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});