define('client/thinkspace/team/viewers/controller', ['exports', 'ember', 'ember-data', 'totem/ns', 'thinkspace-team/controllers/base', 'client/thinkspace/team/viewers/team/view', 'client/thinkspace/team/viewers/team_team/view', 'client/thinkspace/team/viewers/team_user/view', 'client/thinkspace/team/viewers/team_viewer/view', 'client/thinkspace/team/viewers/user_viewer/view'], function (exports, ember, ds, ns, base, team_view, team_team_view, team_user_view, team_viewer_view, user_viewer_view) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    viewers_team_view: team_view['default'],
    viewers_team_team_view: team_team_view['default'],
    viewers_team_user_view: team_user_view['default'],
    viewers_user_viewer_view: user_viewer_view['default'],
    viewers_team_viewer_view: team_viewer_view['default'],
    teams_visible: false,
    users_visible: false,
    team_viewers_visible: true,
    selected_team: null,
    resource_users: ember['default'].computed(function() {
      return this.get('all_team_users');
    }),
    filtered_teams: ember['default'].computed.reads('all_collaboration_teams'),
    team_viewers: ember['default'].computed('selected_team', 'filtered_teams.@each', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var selected;
          selected = _this.get('selected_team');
          return _this.get('filtered_teams').then(function(teams) {
            return resolve(teams.reject(function(team) {
              return team === selected;
            }));
          });
        };
      })(this));
      return ds['default'].PromiseArray.create({
        promise: promise
      });
    }),
    is_viewer_assigned: function(review_team, viewer) {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return review_team.get(ns['default'].to_p('team_viewers')).then(function(viewerables) {
            return resolve(viewerables.find(function(viewerable) {
              return _this.record_is_polymorphic(viewer, viewerable, 'viewerable');
            }));
          });
        };
      })(this));
      return ds['default'].PromiseObject.create({
        promise: promise
      });
    },
    actions: {
      select_team: function(team) {
        return this.set_selected_team(team);
      },
      show_teams: function() {
        return this.set('teams_visible', true);
      },
      hide_teams: function() {
        return this.set('teams_visible', false);
      },
      show_users: function() {
        return this.set('users_visible', true);
      },
      hide_users: function() {
        return this.set('users_visible', false);
      },
      toggle_team_viewers_visible: function() {
        this.toggleProperty('team_viewers_visible');
        return false;
      }
    },
    set_selected_team: function(team) {
      this.set('selected_team', team);
      return this.send('show_teams');
    },
    create_team_viewer: function(team, viewer) {
      var team_viewer;
      if (!team) {
        this.totem_error["throw"](this, "create team viewer team is blank.");
      }
      if (!viewer) {
        this.totem_error["throw"](this, "create team viewer viewer is blank.");
      }
      if (!ember['default'].isBlank(this.get_team_viewers(team, viewer))) {
        return;
      }
      team_viewer = this.store.createRecord(ns['default'].to_p('team_viewer'), {
        team_id: parseInt(team.get('id')),
        viewerable_type: this.totem_scope.get_record_path(viewer),
        viewerable_id: parseInt(viewer.get('id'))
      });
      team_viewer.set(ns['default'].to_p('team'), team);
      return team_viewer.save().then((function(_this) {
        return function() {
          return _this.totem_messages.api_success({
            source: _this,
            model: team_viewer,
            action: 'create',
            i18n_path: ns['default'].to_o('team_viewer', 'save')
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.totem_messages.api_failure(error, {
            source: _this,
            model: team_viewer,
            action: 'create'
          });
        };
      })(this));
    },
    delete_team_viewer: function(team, viewer) {
      if (!team) {
        this.totem_error["throw"](this, "remove team viewer team is blank.");
      }
      if (!viewer) {
        this.totem_error["throw"](this, "remove team viewer viewer is blank.");
      }
      return this.get_team_viewers(team, viewer).forEach((function(_this) {
        return function(team_viewer) {
          team_viewer.deleteRecord();
          return team_viewer.save().then(function() {
            _this.totem_messages.api_success({
              source: _this,
              model: team_viewer,
              action: 'delete',
              i18n_path: ns['default'].to_o('team_viewer', 'destroy')
            });
            return _this.remove_from_association(team, team_viewer);
          }, function(error) {
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: team_viewer,
              action: 'delete'
            });
          });
        };
      })(this));
    },
    get_team_viewers: function(team, viewer) {
      var team_id;
      team_id = parseInt(team.get('id'));
      return this.store.all(ns['default'].to_p('team_viewer')).filter((function(_this) {
        return function(team_viewer) {
          return team_viewer.get('team_id') === team_id && _this.record_is_polymorphic(viewer, team_viewer, 'viewerable');
        };
      })(this));
    },
    remove_from_association: function(team, team_viewer) {
      return team.get(ns['default'].to_p('team_viewers')).then((function(_this) {
        return function(team_viewers) {
          return team_viewers.removeObject(team_viewer);
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});