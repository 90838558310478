define('client/thinkspace/casespace/case_manager/spaces/clone/route', ['exports', 'ember', 'thinkspace-casespace-case-manager/routes/base'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    model: function(params) {
      return this.get_space_from_params(params);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});