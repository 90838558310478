define('client/components/thinkspace/readiness_assurance/admin/trat/summary/answers', ['exports', 'ember', 'thinkspace-readiness-assurance/mixins/data_rows', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, m_data_rows, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(m_data_rows['default'], {
    columns_per_row: 5,
    qms_rows: null,
    assessment: null,
    willInsertElement: function() {
      return this.setup();
    },
    setup: function() {
      return this.am.get_trat_assessment().then((function(_this) {
        return function(assessment) {
          _this.set('assessment', assessment);
          return _this.am.get_trat_response_managers().then(function(rms) {
            var array, i, len, qid, qids, qms, rm;
            qids = assessment.get_question_ids();
            array = [];
            for (i = 0, len = qids.length; i < len; i++) {
              qid = qids[i];
              qms = (function() {
                var j, len1, results;
                results = [];
                for (j = 0, len1 = rms.length; j < len1; j++) {
                  rm = rms[j];
                  results.push(rm.question_manager_map.get(qid));
                }
                return results;
              })();
              array.push(qms);
            }
            _this.set('qms_rows', _this.get_data_rows(array));
            return _this.set_ready_on();
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});