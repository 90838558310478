define('client/components/thinkspace/peer_assessment/assessment/item', ['exports', 'ember', 'thinkspace-base/components/base'], function (exports, ember, base_component) {

	'use strict';

	var ___DefaultExportObject___;

	___DefaultExportObject___ = base_component['default'].extend();

	exports['default'] = ___DefaultExportObject___;;

});