define('client/components/thinkspace/readiness_assurance/admin/shared/team_users/team', ['exports', 'ember', 'totem/ns', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    selected: ember['default'].computed('selected_teams.[]', function() {
      return this.selected_teams.contains(this.team);
    }),
    collapsed: ember['default'].observer('show_all', function() {
      return this.set_show_users();
    }),
    sorted_users: ember['default'].computed(function() {
      return this.sort_users(this.users);
    }),
    show_users: null,
    willInsertElement: function() {
      return this.set_show_users();
    },
    set_show_users: function() {
      return this.set('show_users', this.show_all);
    },
    actions: {
      toggle_show_users: function() {
        this.toggleProperty('show_users');
      },
      select: function() {
        return this.sendAction('select_team', this.team);
      },
      select_user: function(user) {
        return this.sendAction('select_user', this.team, user);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});