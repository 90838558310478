define('client/initializers/thinkspace/intercom/setup', ['exports', 'ember', 'totem/ns', 'totem/mixins', 'totem/config'], function (exports, ember, ns, tm, config) {

  'use strict';

  var ___DefaultExportObject___, initializer;

  initializer = {
    name: 'thinkspace-intercom-setup',
    after: ['totem', 'simple-auth'],
    initialize: function(container, app) {
      if (ember['default'].isBlank(config['default'].crisp_app_id)) {
        return;
      }
      tm['default'].add('controllers/application', ns['default'].to_m('intercom', 'controller'));
      return tm['default'].add(ns['default'].to_c('casespace', 'toolbar'), ns['default'].to_m('intercom', 'toolbar'));
    }
  };

  ___DefaultExportObject___ = initializer;

  exports['default'] = ___DefaultExportObject___;;

});