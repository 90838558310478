define('client/components/thinkspace/casespace/peer_review/phase', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    phase_manager: ember['default'].inject.service(),
    casespace_peer_review: ember['default'].inject.service(),
    t_header: ns['default'].to_t('peer_review', 'phase', 'header'),
    t_footer: ns['default'].to_t('peer_review', 'phase', 'footer'),
    t_team_select: ns['default'].to_t('peer_review', 'phase', 'team_select'),
    t_user_select: ns['default'].to_t('peer_review', 'phase', 'user_select'),
    current_phase: ember['default'].computed.reads('dock.current_phase'),
    addon_ownerable: ember['default'].computed.reads('dock.addon_ownerable'),
    select_user_prompt: 'Select a Student',
    select_team_prompt: 'Select a Team',
    select_visible: false,
    get_addon_ownerable: function() {
      return this.get('addon_ownerable');
    },
    peer_review_users: ember['default'].computed('current_phase', function() {
      return this.get('casespace_peer_review').get_peer_review_users(this.get('current_phase'));
    }),
    peer_review_teams: ember['default'].computed('current_phase', function() {
      return this.get('casespace_peer_review').get_peer_review_teams(this.get('current_phase'));
    }),
    actions: {
      toggle_select: function() {
        return this.toggleProperty('select_visible') && false;
      },
      next_team: function() {
        return this.next_previous_team({
          index_increment: +1,
          "default": 'firstObject'
        });
      },
      previous_team: function() {
        return this.next_previous_team({
          index_increment: -1,
          "default": 'lastObject'
        });
      },
      next_user: function() {
        return this.next_previous_user({
          index_increment: +1,
          "default": 'firstObject'
        });
      },
      previous_user: function() {
        return this.next_previous_user({
          index_increment: -1,
          "default": 'lastObject'
        });
      },
      select_team: function(team) {
        return this.change_ownerable_selected(team);
      },
      select_user: function(user) {
        return this.change_ownerable_selected(user);
      }
    },
    next_previous_team: function(options) {
      var current_team;
      if (options == null) {
        options = {};
      }
      current_team = this.get_addon_ownerable();
      return this.get('peer_review_teams').then((function(_this) {
        return function(teams) {
          var index, team;
          if (current_team) {
            index = teams.indexOf(current_team);
            if (index != null) {
              if (options.index_increment != null) {
                index += options.index_increment;
              }
              team = teams.objectAt(index);
            }
          }
          if (!team) {
            team = teams.get(options["default"]);
          }
          return _this.send('select_team', team);
        };
      })(this));
    },
    next_previous_user: function(options) {
      var current_user;
      if (options == null) {
        options = {};
      }
      current_user = this.get_addon_ownerable();
      return this.get('peer_review_users').then((function(_this) {
        return function(users) {
          var index, user;
          if (current_user) {
            index = users.indexOf(current_user);
            if (index != null) {
              if (options.index_increment != null) {
                index += options.index_increment;
              }
              user = users.objectAt(index);
            }
          }
          if (!user) {
            user = users.get(options["default"]);
          }
          return _this.send('select_user', user);
        };
      })(this));
    },
    change_ownerable_selected: function(ownerable) {
      if (!ownerable) {
        this.totem_error["throw"](this, "Change to ownerable is blank.");
      }
      this.set('select_visible', false);
      this.totem_scope.view_only_on();
      this.get('dock').mock_phase_states_on();
      return this.get('phase_manager').set_addon_ownerable_and_generate_view(ownerable);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});