define('client/components/thinkspace/casespace/gradebook/phase/state', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    layoutName: ns['default'].to_t('gradebook', 'phase/state'),
    domain_phase_states: [
      {
        state: 'unlocked',
        title: 'Unlock'
      }, {
        state: 'locked',
        title: 'Lock'
      }, {
        state: 'completed',
        title: 'Complete'
      }
    ],
    actions: {
      change: function(state) {
        return this.sendAction('change', state);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});