define('client/_config/thinkspace/indented_list/query_params', ['exports'], function (exports) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = {
    'indented:list': {
      ownerable: true,
      authable: true
    },
    'indented:response': {
      ownerable: true,
      authable: true
    },
    'indented:expert_response': {
      ownerable: true,
      authable: true
    }
  };

  exports['default'] = ___DefaultExportObject___;;

});