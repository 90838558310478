define('client/components/thinkspace/lab/builder/lab/category/destroy', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    admin: ember['default'].inject.service(ns['default'].to_p('lab', 'admin')),
    actions: {
      cancel: function() {
        return this.get('admin').set_action_overlay_off();
      },
      "delete": function() {
        var admin;
        admin = this.get('admin');
        return admin.delete_category().then((function(_this) {
          return function() {
            return admin.set_chart_selected_category().then(function() {
              return _this.send('cancel');
            });
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});