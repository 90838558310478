define('client/thinkspace/team/teams/index/controller', ['exports', 'ember', 'totem/ns', 'thinkspace-team/controllers/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    actions: {
      filter_by_collaboration_teams: function() {
        return this.set_team_filter_category(this.get('collaboration_category'));
      },
      filter_by_peer_review_teams: function() {
        return this.set_team_filter_category(this.get('peer_review_category'));
      },
      filter_teams_off: function() {
        return this.set_team_filter_category();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});