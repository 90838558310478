define('client/models/thinkspace/lab/category', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].belongs_to('lab:chart', {
    reads: {}
  }), ta['default'].has_many('lab:results', {
    reads: {
      sort: 'position'
    }
  })), {
    title: ta['default'].attr('string'),
    position: ta['default'].attr('number'),
    value: ta['default'].attr(),
    description_heading: ember['default'].computed('value.description_heading', function() {
      return this.get('value.description_heading') || 'Description';
    }),
    columns: ember['default'].computed.reads('value.columns'),
    colspan_for_html: ember['default'].computed('columns', function() {
      return this.get('columns.length') - 1;
    }),
    component: ember['default'].computed(function() {
      return ta['default'].to_p('lab:category', this.get('value.component'));
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});