define('client/components/thinkspace/casespace/gradebook/assignment/roster/phase/scores', ['exports', 'ember', 'ember-data', 'totem/ns', 'thinkspace-casespace-gradebook/calc', 'thinkspace-casespace-gradebook/common_helpers', 'totem/mixins/group_by', 'thinkspace-base/components/base'], function (exports, ember, ds, ns, calc, gch, group_by_mixin, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend(group_by_mixin['default'], {
    show_non_zero_supplement: true,
    c_dropdown_split_button: ns['default'].to_p('common', 'dropdown_split_button'),
    c_score: ns['default'].to_p('gradebook', 'assignment', 'roster', 'shared', 'score'),
    c_state: ns['default'].to_p('gradebook', 'assignment', 'roster', 'shared', 'state'),
    t_sort_links: ns['default'].to_t('gradebook', 'assignment', 'roster', 'shared', 'sort_links'),
    t_table_options: ns['default'].to_t('gradebook', 'assignment', 'roster', 'shared', 'table_options'),
    t_group_1: ns['default'].to_t('gradebook', 'assignment', 'roster', 'phase', 'group_1'),
    t_group_1_sort_by: ns['default'].to_t('gradebook', 'assignment', 'roster', 'phase', 'group_1_sort_by'),
    t_group_2: ns['default'].to_t('gradebook', 'assignment', 'roster', 'phase', 'group_2'),
    t_group_2_sort_by: ns['default'].to_t('gradebook', 'assignment', 'roster', 'phase', 'group_2_sort_by'),
    t_supplement: ns['default'].to_t('gradebook', 'assignment', 'roster', 'phase', 'supplement'),
    is_group_1: ember['default'].computed.equal('roster.content.groups', 1),
    is_group_2: ember['default'].computed.equal('roster.content.groups', 2),
    decimals: ember['default'].computed.reads('roster.content.decimals'),
    supplement: ember['default'].computed.reads('roster.content.supplement'),
    server_roster: null,
    show_scores: true,
    sort_order: null,
    row_number: null,
    edit_visible: false,
    selected_decimals: 2,
    sort_links: ember['default'].computed('sort_order', function() {
      return gch['default'].get_sort_links(this.get_sort_def(), this.get_sort_order(), this.get_sort_link_for());
    }),
    dropdown_collection: ember['default'].computed('sort_order', function() {
      var collection, sort_links;
      collection = [];
      sort_links = gch['default'].get_sort_links(this.get_sort_def(), this.get_sort_order(), this.get_sort_link_for());
      sort_links.forEach((function(_this) {
        return function(sort_link) {
          return collection.pushObject({
            display: sort_link.text,
            action: sort_link.key
          });
        };
      })(this));
      return collection;
    }),
    selected_sort_order: ember['default'].computed('sort_order', function() {
      var selected, sort_links;
      sort_links = gch['default'].get_sort_links(this.get_sort_def(), this.get_sort_order(), this.get_sort_link_for());
      selected = sort_links.findBy('active', true);
      return selected;
    }),
    roster: ember['default'].computed('sort_order', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          _this.set('show_scores', false);
          return ember['default'].run.next(function() {
            return _this.get_roster().then(function(roster) {
              _this.totem_messages.hide_loading_outlet();
              _this.set('row_number', 0);
              resolve(roster);
              return _this.set('show_scores', true);
            });
          });
        };
      })(this));
      return ds['default'].PromiseObject.create({
        promise: promise
      });
    }),
    sort_def: {
      by_user: {
        "for": 'user',
        sort: ['user'],
        heading: {
          column_1: 'Student'
        },
        title: 'Last Name ( A - Z )'
      },
      by_score_user: {
        "for": 'user',
        sort: ['score', 'user'],
        heading: {
          column_1: 'Student'
        },
        heading_sort_by: 'Score',
        title: 'Score (high - low)'
      },
      by_state_user: {
        "for": 'user',
        sort: ['state', 'user'],
        heading: {
          column_1: 'Student'
        },
        heading_sort_by: 'State',
        title: 'Phase State '
      },
      by_user_team: {
        "for": 'team',
        sort: ['user', 'team'],
        heading: {
          column_1: 'Student',
          column_2: 'Team'
        },
        title: 'Teams'
      },
      by_team_user: {
        "for": 'team',
        sort: ['team', 'user'],
        heading: {
          column_1: 'Team',
          column_2: 'Student'
        },
        title: 'By Team to Student'
      },
      by_score_team_user: {
        "for": 'team',
        sort: ['score', 'team', 'user'],
        heading: {
          column_1: 'Team',
          column_2: 'Student'
        },
        heading_sort_by: 'Score',
        title: 'By Score to Team to Student'
      },
      by_state_team_user: {
        "for": 'team',
        sort: ['state', 'team', 'user'],
        heading: {
          column_1: 'Team',
          column_2: 'Student'
        },
        heading_sort_by: 'State',
        title: 'By State to Team to Student'
      },
      by_score_user_team: {
        "for": 'team',
        sort: ['score', 'user', 'team'],
        heading: {
          column_1: 'Student',
          column_2: 'Team'
        },
        heading_sort_by: 'Score',
        title: 'By Score to Student to Team'
      },
      by_state_user_team: {
        "for": 'team',
        sort: ['state', 'user', 'team'],
        heading: {
          column_1: 'Student',
          column_2: 'Team'
        },
        heading_sort_by: 'State',
        title: 'By State to Student to Team'
      }
    },
    get_sort_def: function() {
      return this.get('sort_def');
    },
    get_sort_order: function() {
      return this.get('sort_order') || (this.get('model.team_ownerable') && 'by_user_team') || 'by_user';
    },
    get_sort_link_for: function() {
      return (this.get('model.team_ownerable') && 'team') || 'user';
    },
    get_number_decimals: function() {
      return this.get('selected_decimals');
    },
    rerender_view: function() {
      return this.notifyPropertyChange('sort_order');
    },
    set_decimals_from_offset: function(offset) {
      var decimals;
      decimals = this.get('selected_decimals' || 2);
      this.set('selected_decimals', decimals + offset);
      return this.rerender_view();
    },
    actions: {
      by_user: function() {
        return this.set('sort_order', 'by_user');
      },
      by_score_user: function() {
        return this.set('sort_order', 'by_score_user');
      },
      by_state_user: function() {
        return this.set('sort_order', 'by_state_user');
      },
      by_team_user: function() {
        return this.set('sort_order', 'by_team_user');
      },
      by_user_team: function() {
        return this.set('sort_order', 'by_user_team');
      },
      by_score_team_user: function() {
        return this.set('sort_order', 'by_score_team_user');
      },
      by_state_team_user: function() {
        return this.set('sort_order', 'by_state_team_user');
      },
      by_score_user_team: function() {
        return this.set('sort_order', 'by_score_user_team');
      },
      by_state_user_team: function() {
        return this.set('sort_order', 'by_state_user_team');
      },
      toggle_edit: function() {
        this.toggleProperty('edit_visible');
        return this.rerender_view();
      },
      increase_decimals: function() {
        return this.set_decimals_from_offset(1);
      },
      decrease_decimals: function() {
        return this.set_decimals_from_offset(-1);
      },
      save_score: function(values, score) {
        return gch['default'].update_roster_score(this.get_server_roster_scores(), values, score).then((function(_this) {
          return function() {
            return _this.rerender_view();
          };
        })(this));
      },
      save_state: function(values, state) {
        return gch['default'].update_roster_state(this.get_server_roster_scores(), values, state).then((function(_this) {
          return function() {
            return _this.rerender_view();
          };
        })(this));
      },
      view_phase_list: function() {
        return this.sendAction('view_phase_list');
      }
    },
    get_roster: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (_this.get_server_roster()) {
            return _this.get_roster_values().then(function(roster) {
              return resolve(roster);
            });
          } else {
            return gch['default'].get_phase_roster_from_server(_this.get('assignment'), _this.get('model')).then(function(roster) {
              roster.scores = roster.scores.map(function(hash) {
                hash.score = Number(hash.score);
                return ember['default'].Object.create(hash);
              });
              _this.set_server_roster(roster);
              return _this.get_roster_values().then(function(roster_values) {
                return resolve(roster_values);
              });
            });
          }
        };
      })(this));
    },
    get_roster_values: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var options, phase, roster, sort, sort_def, sort_order;
          phase = _this.get('model');
          sort_order = _this.get_sort_order();
          sort_def = _this.get("sort_def." + sort_order);
          roster = {};
          roster.supplement = _this.get_roster_supplement(sort_def);
          roster.decimals = _this.get_number_decimals();
          roster.heading = sort_def.heading;
          roster.heading_sort_by = sort_def.heading_sort_by;
          sort = sort_def.sort || [];
          roster.groups = sort.get('length');
          if (roster.heading_sort_by != null) {
            roster.groups -= 1;
          }
          options = ember['default'].merge({}, sort_def);
          options.add_props = ['score', 'state', 'state_id', 'multiple_scores', 'team_ownerable', 'phase_id'];
          options.find = {
            score: 'score',
            state: 'state'
          };
          roster.group_values = _this.group_values(_this.get_server_roster_scores(), options);
          return resolve(roster);
        };
      })(this));
    },
    get_server_roster: function() {
      return this.get('server_roster');
    },
    set_server_roster: function(roster) {
      return this.set('server_roster', roster);
    },
    get_server_roster_scores: function() {
      return this.get('server_roster.scores');
    },
    get_roster_supplement: function(sort_def) {
      var scores, scores_array, sup, user_count;
      sup = {};
      sup.decimals = this.get_number_decimals();
      scores = this.get_server_roster_scores();
      scores_array = scores.mapBy('score');
      sup.base = calc['default'].values(scores_array);
      user_count = calc['default'].count_uniq_key_values(scores, 'user_id');
      if (user_count !== sup.base.count) {
        sup.base.user_count = user_count;
      }
      sup.non_zero = calc['default'].non_zero_values(scores_array);
      user_count = calc['default'].count_non_zero_uniq_key_values(scores, 'score', 'user_id');
      if (user_count !== sup.non_zero.count) {
        sup.non_zero.user_count = user_count;
      }
      return sup;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});