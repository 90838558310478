define('client/components/thinkspace/lab/builder/lab/category/edit', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    admin: ember['default'].inject.service(ns['default'].to_p('lab', 'admin')),
    result_type: ember['default'].computed.reads('model.admin_type'),
    form_heading: ember['default'].computed(function() {
      return this.t('builder.lab.admin.edit_category');
    }),
    model: ember['default'].computed(function() {
      var admin, category, mock;
      admin = this.get('admin');
      mock = admin.get_mock_new_category_record();
      category = admin.get_selected_category();
      return admin.clone_category_values(mock, category);
    }),
    actions: {
      cancel: function() {
        return this.get('admin').set_action_overlay_off();
      },
      save: function() {
        var admin, category, mock;
        admin = this.get('admin');
        category = admin.get_selected_category();
        mock = this.get('model');
        return admin.save_category(category, {
          values: mock
        }).then((function(_this) {
          return function() {
            admin.reset_selected_category();
            return _this.send('cancel');
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});