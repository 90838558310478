define('client/templates/components/thinkspace/casespace/case_manager/assignment/peer_assessment/user', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","span right");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
            inline(env, morph0, context, "component", [get(env, context, "c_dropdown_split_button")], {"collection": get(env, context, "dropdown_collection"), "button_class": "btn-square btn-default", "show_button": false});
            return fragment;
          }
        };
      }());
      var child1 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.setAttribute(el1,"title","Approve all evaluations");
            dom.setAttribute(el1,"class","tsi tsi-phase-complete");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1,"title","Set all evaluations to in-progress");
            dom.setAttribute(el1,"class","tsi tsi-phase-incomplete");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1,"title","Ignore all evaluations");
            dom.setAttribute(el1,"class","tsi tsi-trash");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, element = hooks.element;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element0 = dom.childAt(fragment, [0]);
            var element1 = dom.childAt(fragment, [1]);
            var element2 = dom.childAt(fragment, [2]);
            element(env, element0, context, "action", ["approve_all"], {});
            element(env, element1, context, "action", ["unapprove_all"], {});
            element(env, element2, context, "action", ["ignore"], {});
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","right");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2,"title","Notify student");
          dom.setAttribute(el2,"class","tsi tsi-add-note");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, block = hooks.block, element = hooks.element;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element3 = dom.childAt(fragment, [1]);
          var element4 = dom.childAt(element3, [1]);
          var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
          var morph1 = dom.createMorphAt(element3,0,0);
          dom.insertBoundary(fragment, 0);
          block(env, morph0, context, "if", [get(env, context, "review_set")], {}, child0, null);
          block(env, morph1, context, "if", [get(env, context, "review_set")], {}, child1, null);
          element(env, element4, context, "action", ["notify"], {});
          return fragment;
        }
      };
    }());
    var child1 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","ts-grid_row");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","small-11 ts-grid_columns right");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(dom.childAt(fragment, [0, 0]),0,0);
          inline(env, morph0, context, "component", [get(env, context, "c_review_set")], {"model": get(env, context, "review_set"), "user": get(env, context, "model"), "assessment": get(env, context, "assessment"), "color": get(env, context, "color")});
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ts-grid_row");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","ts-grid_columns small-12");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","thinkspace-tbl-pa_user-assessment-container");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","thinkspace-tbl-pa_parent-user-container");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","thinkspace-tbl-pa_toggle-wrapper");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","thinkspace-tbl-pa_user-avatar left");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","thinkspace-tbl-pa_user-name left");
        var el7 = dom.createTextNode(" ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("'s Evaluations ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","thinkspace-tbl-pa_status left");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"aria-hidden","true");
        dom.setAttribute(el1,"aria-labelledby","modalTitle");
        dom.setAttribute(el1,"data-reveal","");
        dom.setAttribute(el1,"role","dialog");
        dom.setAttribute(el1,"class","ts-tblpa_user-modal reveal-modal");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","ts-grid_row");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-grid_columns small-6 ts-grid_columns-thick");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-forms_heading");
        var el5 = dom.createElement("h4");
        var el6 = dom.createTextNode("Notification for ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-grid_columns small-6 ts-grid_columns-thick");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-forms_heading");
        var el5 = dom.createElement("h4");
        var el6 = dom.createTextNode("Preview");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("Hi ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(",");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("You have received a notification from your instructor about your peer evaluation (link):");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("Your instructor said: ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("With love,");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("ThinkBot and the ThinkSpace Team");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"class","btn btn-primary btn-small left");
        var el3 = dom.createTextNode("Send Notification");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"class","btn btn-default btn-small right");
        var el3 = dom.createTextNode("Cancel");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"aria-label","Close");
        dom.setAttribute(el2,"class","close-reveal-modal");
        var el3 = dom.createTextNode(" ×");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, element = hooks.element, block = hooks.block, content = hooks.content, inline = hooks.inline;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element5 = dom.childAt(fragment, [0, 0, 0]);
        var element6 = dom.childAt(element5, [0]);
        var element7 = dom.childAt(element6, [1]);
        var element8 = dom.childAt(fragment, [1]);
        var element9 = dom.childAt(element8, [0]);
        var element10 = dom.childAt(element9, [0]);
        var element11 = dom.childAt(element9, [1]);
        var element12 = dom.childAt(element8, [1]);
        var element13 = dom.childAt(element8, [2]);
        var morph0 = dom.createMorphAt(element6,0,0);
        var morph1 = dom.createMorphAt(dom.childAt(element7, [1]),1,1);
        var morph2 = dom.createMorphAt(dom.childAt(element7, [2]),0,0);
        var morph3 = dom.createMorphAt(element5,1,1);
        var morph4 = dom.createMorphAt(dom.childAt(element10, [0, 0]),1,1);
        var morph5 = dom.createMorphAt(element10,1,1);
        var morph6 = dom.createMorphAt(dom.childAt(element11, [1]),1,1);
        var morph7 = dom.createMorphAt(dom.childAt(element11, [4]),0,0);
        element(env, element6, context, "bind-attr", [], {"style": get(env, context, "css_style")});
        block(env, morph0, context, "unless", [get(env, context, "review_set.is_sent")], {}, child0, null);
        element(env, element7, context, "action", ["toggle"], {});
        content(env, morph1, context, "model.full_name");
        inline(env, morph2, context, "component", [get(env, context, "c_state")], {"model_state": get(env, context, "review_set_state")});
        block(env, morph3, context, "if", [get(env, context, "is_expanded")], {}, child1, null);
        content(env, morph4, context, "model.full_name");
        inline(env, morph5, context, "textarea", [], {"value": get(env, context, "notification")});
        content(env, morph6, context, "model.first_name");
        content(env, morph7, context, "notification");
        element(env, element12, context, "action", ["send_notification"], {"on": "click"});
        element(env, element13, context, "action", ["close_notification_modal"], {"on": "click"});
        return fragment;
      }
    };
  }()));

});