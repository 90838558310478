define('client/components/thinkspace/casespace/case_manager/assignment/wizards/assessment/steps/settings/quantitative/item', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace-case-manager/components/wizards/steps/base', 'totem/mixins/validations'], function (exports, ember, ns, base, val_mixin) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(val_mixin['default'], {
    label: ember['default'].computed.reads('model.label'),
    actions: {
      set_label: function(value) {
        return ember['default'].set(this.get('model'), 'label', value);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});