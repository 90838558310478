define('client/components/thinkspace/casespace/case_manager/space/clone', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'thinkspace-base/components/base'], function (exports, ember, ns, ajax, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    wizard_manager: ember['default'].inject.service(),
    actions: {
      exit: function() {
        return this.get('wizard_manager').exit();
      },
      clone: function() {
        return this.clone_space();
      }
    },
    clone_space: function() {
      var query, space;
      space = this.get('model');
      this.totem_messages.show_loading_outlet({
        message: "Cloning " + (space.get('title')) + "..."
      });
      query = {
        model: space,
        id: space.get('id'),
        action: 'clone',
        verb: 'post'
      };
      return ajax['default'].object(query).then((function(_this) {
        return function(payload) {
          var index_route;
          index_route = _this.container.lookup("route:" + (ns['default'].to_p('spaces', 'index')));
          index_route.refresh_spaces();
          _this.totem_messages.hide_loading_outlet();
          _this.totem_messages.api_success({
            source: _this,
            model: space,
            action: 'clone',
            i18n_path: ns['default'].to_o('space', 'clone')
          });
          return _this.send('exit');
        };
      })(this), (function(_this) {
        return function(error) {
          _this.totem_messages.hide_loading_outlet();
          return _this.totem_messages.api_failure(error, {
            source: _this,
            model: space,
            action: 'clone'
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});