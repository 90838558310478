define('client/validators/local/model_errors', ['exports', 'ember', 'ember-validations/validators/base'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    init: function() {
      this._super();
      return this.dependentValidationKeys.pushObject("observe_validation_model_errors");
    },
    call: function() {
      var i, j, len, len1, msg, path, prop, prop_errors, with_key;
      with_key = this.options.withKey || false;
      prop = this.property.split('.').pop();
      path = "validation_model_errors." + prop;
      if (prop_errors = this.get(path)) {
        if (with_key) {
          for (i = 0, len = prop_errors.length; i < len; i++) {
            msg = prop_errors[i];
            this.errors.pushObject(prop + " " + msg);
          }
        } else {
          for (j = 0, len1 = prop_errors.length; j < len1; j++) {
            msg = prop_errors[j];
            this.errors.pushObject(msg);
          }
        }
        return this.set(path, null);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});