define('client/users/terms/route', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin', 'totem/ns'], function (exports, ember, auth_mixin, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Route.extend(auth_mixin['default'], {
    model: function() {
      return this.modelFor('users.show');
    },
    setupController: function(controller, model) {
      var query;
      controller.set('model', model);
      query = {
        action: 'latest_for',
        verb: 'GET',
        data: {
          doc_type: 'terms_of_service'
        }
      };
      return this.tc.query(ns['default'].to_p('agreement'), query, {
        single: true
      }).then((function(_this) {
        return function(tos) {
          return controller.set('tos', tos);
        };
      })(this));
    },
    renderTemplate: function() {
      return this.render(ns['default'].to_p('users', 'terms'));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});