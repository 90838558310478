define('client/thinkspace/builder/cases/details/controller', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Controller.extend({
    builder: ember['default'].inject.service(),
    model: null
  });

  exports['default'] = ___DefaultExportObject___;;

});