define('client/components/thinkspace/artifact/bucket/file/image/carry_forward/wrapper', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    classNames: ['artifact_image-wrapper', 'clearfix'],
    c_image_file: ns['default'].to_p('artifact', 'bucket', 'file', 'image', 'carry_forward', 'file'),
    is_expert: ember['default'].computed.equal('tag_attrs.expert', 'true')
  });

  exports['default'] = ___DefaultExportObject___;;

});