define('client/components/thinkspace/input_element/elements/standard_checkbox', ['exports', 'ember', 'totem/ns', 'totem/mixins/validations', 'thinkspace-base/components/base'], function (exports, ember, ns, val_mixin, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend(val_mixin['default'], {
    tagName: '',
    view_only: ember['default'].computed.bool('totem_scope.is_view_only'),
    is_checked: ember['default'].computed(function(key, value) {
      if (value == null) {
        return this.get('model.value') === 'true';
      } else {
        this.set('model.value', value.toString());
        this.save_response();
        return value === true;
      }
    }),
    save_response: function() {
      var model;
      model = this.get('model');
      this.totem_scope.set_record_ownerable_attributes(model);
      return model.save().then((function(_this) {
        return function() {
          return _this.totem_messages.api_success({
            source: _this,
            model: model,
            action: 'save',
            i18n_path: ns['default'].to_o('response', 'save')
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.totem_messages.api_failure(error, {
            source: _this,
            model: model
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});