define('client/helpers/index-of', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Handlebars.makeBoundHelper(function(item, collection, add) {
    var index;
    index = collection.indexOf(item);
    if (ember['default'].isPresent(index)) {
      return index + add;
    }
    return index;
  });

  exports['default'] = ___DefaultExportObject___;;

});