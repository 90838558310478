define('client/components/thinkspace/casespace/case_manager/assignment/wizards/assessment/steps/settings/qualitative/item', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace-case-manager/components/wizards/steps/base', 'totem/mixins/validations'], function (exports, ember, ns, base, val_mixin) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(val_mixin['default'], {
    label: ember['default'].computed.reads('model.label'),
    feedback_type: ember['default'].computed.reads('model.feedback_type'),
    feedback_types: ['positive', 'constructive'],
    remove_qualitative_item: 'remove_qualitative_item',
    actions: {
      set_feedback_type: function(value) {
        return ember['default'].set(this.get('model'), 'feedback_type', value);
      },
      set_label: function(value) {
        return ember['default'].set(this.get('model'), 'label', value);
      },
      remove_qualitative_item: function() {
        return this.sendAction('remove_qualitative_item', this.get('model'));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});