define('client/components/thinkspace/resource/manage/file', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    classNames: ['thinkspace-resource_file'],
    ttz: ember['default'].inject.service(),
    selection: null,
    prompt: 'No tag',
    file_updated_at: ember['default'].computed('model.file_updated_at', function() {
      return this.get('ttz').format(this.get('model.file_updated_at'), {
        format: 'MMM Do, h:mm a'
      });
    }),
    didInsertElement: function() {
      return this.set('selection', this.get('model.tag'));
    },
    change: function() {
      return this.save_tag();
    },
    actions: {
      destroy: function() {
        var file;
        file = this.get('model');
        file.deleteRecord();
        return file.save().then((function(_this) {
          return function() {
            return _this.totem_messages.api_success({
              source: _this,
              model: file,
              action: 'delete',
              i18n_path: ns['default'].to_o('file', 'destroy')
            });
          };
        })(this), (function(_this) {
          return function(error) {
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: file,
              action: 'delete'
            });
          };
        })(this));
      }
    },
    save_tag: function() {
      var file, new_tag, tag_ids;
      file = this.get('model');
      new_tag = this.get('selection');
      tag_ids = (new_tag && new_tag.get('id')) || [];
      file.set('new_tags', ember['default'].makeArray(tag_ids));
      return file.save().then((function(_this) {
        return function(file) {
          return _this.totem_messages.api_success({
            source: _this,
            model: file,
            i18n_path: ns['default'].to_o('tag', 'save')
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.totem_messages.api_failure(error, {
            source: _this,
            model: file
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});