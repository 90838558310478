define('client/models/thinkspace/readiness_assurance/status', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].belongs_to('ra:response')), {
    settings: ta['default'].attr(),
    questions: ta['default'].attr()
  });

  exports['default'] = ___DefaultExportObject___;;

});