define('client/components/thinkspace/casespace/gradebook/dock', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace/components/dock_base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    totem_data_config: {
      ability: {
        model: 'current_assignment'
      }
    },
    casespace_gradebook: ember['default'].inject.service(),
    addon_name: 'gradebook',
    addon_display_name: 'Gradebook',
    c_assignment: ns['default'].to_p('gradebook', 'assignment'),
    c_phase: ns['default'].to_p('gradebook', 'phase'),
    can_grade_assignment: ember['default'].computed.bool('can.gradebook'),
    can_grade_phase: ember['default'].computed.and('can_grade_assignment', 'has_phase_view'),
    assignment_change: ember['default'].computed('current_assignment', function() {
      if (ember['default'].isPresent(this.get('current_assignment'))) {
        return this.totem_data.ability.refresh();
      }
    }),
    can_access_addon: ember['default'].computed('current_phase', 'can_grade_assignment', 'can_grade_phase', function() {
      if (this.get('current_phase')) {
        return this.get('can_grade_phase');
      } else {
        return this.get('can_grade_assignment');
      }
    }),
    exit_addon: function() {
      return this.get('casespace_gradebook').clear();
    },
    valid_addon_ownerable: function(addon_ownerable) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.validate_ownerable(addon_ownerable).then(function(valid) {
            return resolve(valid);
          });
        };
      })(this));
    },
    validate_ownerable: function(ownerable) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var assignment, map, phase, pm_map, space;
          pm_map = _this.get('phase_manager.map');
          map = _this.get('casespace_gradebook');
          space = _this.get('current_space');
          assignment = _this.get('current_assignment');
          phase = _this.get('current_phase');
          if (!phase) {
            return resolve(false);
          }
          if (phase.is_team_ownerable()) {
            return map.get_gradebook_phase_teams(assignment, phase).then(function(teams) {
              return resolve(teams.contains(ownerable));
            });
          } else {
            return map.get_gradebook_users(space, assignment).then(function(users) {
              return resolve(users.contains(ownerable));
            });
          }
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});