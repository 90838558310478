define('client/templates/components/thinkspace/artifact/bucket/upload', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","thinkspace-artifact_upload");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","btn btn-primary btn-small");
        var el3 = dom.createTextNode("Select File");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        dom.setAttribute(el3,"method","POST");
        dom.setAttribute(el3,"enctype","multipart/form-data");
        var el4 = dom.createElement("input");
        dom.setAttribute(el4,"type","hidden");
        dom.setAttribute(el4,"name","bucket_id");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("input");
        dom.setAttribute(el4,"type","hidden");
        dom.setAttribute(el4,"name","ownerable_type");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("input");
        dom.setAttribute(el4,"type","hidden");
        dom.setAttribute(el4,"name","ownerable_id");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("input");
        dom.setAttribute(el4,"type","file");
        dom.setAttribute(el4,"name","files[]");
        dom.setAttribute(el4,"class","thinkspace-artifact_upload-input");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, element = hooks.element;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [0, 0, 1]);
        var element1 = dom.childAt(element0, [0]);
        var element2 = dom.childAt(element0, [1]);
        var element3 = dom.childAt(element0, [2]);
        element(env, element0, context, "bind-attr", [], {"action": get(env, context, "files_url")});
        element(env, element1, context, "bind-attr", [], {"value": get(env, context, "model.id")});
        element(env, element2, context, "bind-attr", [], {"value": get(env, context, "ownerable_type")});
        element(env, element3, context, "bind-attr", [], {"value": get(env, context, "ownerable_id")});
        return fragment;
      }
    };
  }()));

});