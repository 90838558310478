define('client/models/thinkspace/readiness_assurance/server_event', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend({
    authable_id: ta['default'].attr('number'),
    authable_type: ta['default'].attr('string'),
    ownerable_id: ta['default'].attr('number'),
    ownerable_type: ta['default'].attr('string'),
    room: ta['default'].attr('string'),
    event: ta['default'].attr('string'),
    question_id: ta['default'].attr('string'),
    value: ta['default'].attr()
  });

  exports['default'] = ___DefaultExportObject___;;

});