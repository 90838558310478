define('client/components/thinkspace/lab/builder/lab/category/show', ['exports', 'ember', 'totem/ns', 'totem/ds/associations', 'thinkspace-base/components/base'], function (exports, ember, ns, ta, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    admin: ember['default'].inject.service(ns['default'].to_p('lab', 'admin')),
    is_category_active: ember['default'].computed('admin.selected_category', function() {
      return this.get('model') === this.get('admin.selected_category');
    }),
    category_dropdown_collection: ember['default'].computed(function() {
      return [
        {
          display: this.t('builder.lab.admin.edit_category'),
          action: 'category_edit'
        }, {
          display: this.t('builder.lab.admin.delete_category'),
          action: 'category_delete'
        }
      ];
    }),
    result_new_dropdown_collection: ember['default'].computed(function() {
      return [
        {
          display: this.t('builder.lab.admin.new_result'),
          action: 'result_new',
          type: 'result'
        }, {
          display: this.t('builder.lab.admin.new_adjusted_result'),
          action: 'result_new',
          type: 'adjusted_result'
        }, {
          display: this.t('builder.lab.admin.new_html_result'),
          action: 'result_new',
          type: 'html_result'
        }
      ];
    }),
    sort_by: ['position'],
    sorted_results: ember['default'].computed.sort('category_results', 'sort_by'),
    category_results: ember['default'].computed(function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var category;
          category = _this.get('model');
          return category.get(ns['default'].to_p('lab:results')).then(function(results) {
            return resolve(results);
          });
        };
      })(this));
      return ta['default'].PromiseArray.create({
        promise: promise
      });
    }),
    actions: {
      category_edit: function() {
        return this.get('admin').set_action_overlay('c_category_edit');
      },
      category_delete: function() {
        return this.get('admin').set_action_overlay('c_category_delete');
      },
      result_new: function(params) {
        var admin;
        admin = this.get('admin');
        admin.set('new_result_type', params.type);
        return admin.set_action_overlay('c_result_new');
      }
    },
    element_id: function() {
      return this.get('elementId');
    },
    sortable_selector: function() {
      return this.get('admin').get_result_sortable_selector() + ("." + (this.element_id()));
    },
    didInsertElement: function() {
      return this.add_result_sortable();
    },
    willDestroyElement: function() {
      var selector;
      selector = this.sortable_selector();
      return this.$(selector).sortable('destroy');
    },
    add_result_sortable: function() {
      var options, selector;
      selector = this.sortable_selector();
      options = {
        group: "results_" + (this.element_id()),
        containerSelector: selector,
        itemPath: '> tbody',
        itemSelector: 'tr',
        placeholder: '<tr class="placeholder"/>',
        clone: false,
        consume: false,
        exclude: '.sortable-exclude',
        nested: false,
        vertical: true,
        drag: true,
        drop: true,
        delay: 100,
        pullPlaceholder: false,
        onDrop: this.on_drop,
        component: this
      };
      return this.$(selector).sortable(options);
    },
    on_drop: function($item, container, _super, event) {
      var admin, component, results;
      _super($item, container, _super, event);
      component = container && container.options && container.options.component;
      if (!component) {
        return;
      }
      admin = component.get('admin');
      results = component.get('category_results');
      return admin.on_drop_result_reorder({
        component: component,
        notify: 'category_results'
      });
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});