define('client/components/thinkspace/readiness_assurance/admin/irat/phase_states', ['exports', 'ember', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    init: function() {
      this._super();
      return this.irad = this.am.rad({
        name: 'IRAT',
        width_selector: '.ts-ra_admin-phase-states-content'
      });
    },
    willInsertElement: function() {
      return this.am.get_trat_team_users().then((function(_this) {
        return function(team_users) {
          _this.irad.set_team_users(team_users);
          _this.irad.show_all_on();
          return _this.set_ready_on();
        };
      })(this));
    },
    actions: {
      validate: function() {
        return this.validate();
      },
      send_phase_states: function() {
        var irat;
        this.validate();
        this.selected_send_on();
        if (ember['default'].isPresent(this.irad.errors)) {
          return;
        }
        irat = this.irad.get_data();
        return this.am.send_irat_phase_states({
          irat: irat
        });
      }
    },
    validate: function() {
      this.irad.clear_errors();
      if (ember['default'].isBlank(this.irad.get_users())) {
        this.irad.error('No users are selected.');
      }
      if (ember['default'].isBlank(this.irad.get_phase_state())) {
        return this.irad.error('No state selected.');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});