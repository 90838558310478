define('client/components/thinkspace/markup/sidepocket/discussion/comment', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    markup: ember['default'].inject.service(ns['default'].to_p('markup', 'manager')),
    model: null,
    discussion: null,
    is_anonymous: null,
    is_editing: false,
    is_highlighted: false,
    is_collapsed: true,
    tagName: 'li',
    classNames: ['ts-markup_sidepocket-comment'],
    classNameBindings: ['is_collapsed:is-collapsed', 'is_child:ts-markup_sidepocket-comment-reply'],
    comment_text: ember['default'].computed.reads('model.comment'),
    can_update: ember['default'].computed.reads('model.can_update'),
    can_reply: false,
    children: ember['default'].computed.reads('model.comments'),
    has_children: ember['default'].computed.reads('model.has_children'),
    is_child: ember['default'].computed.reads('model.is_child'),
    is_new: ember['default'].computed.reads('model.isNew'),
    c_markup_discussion_comment: ns['default'].to_p('markup', 'sidepocket', 'discussion', 'comment'),
    c_confirmation_modal: ns['default'].to_p('common', 'shared', 'confirmation', 'modal'),
    get_avatar_text: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get('model.commenterable').then(function(commenterable) {
            if (_this.get('is_anonymous')) {
              return resolve(_this.get('markup').get_anonymized_commenter(commenterable));
            } else {
              return resolve(commenterable.get('initials'));
            }
          });
        };
      })(this));
    },
    set_avatar_text: function() {
      return this.get_avatar_text().then((function(_this) {
        return function(avatar_text) {
          return _this.set('avatar_text', avatar_text);
        };
      })(this));
    },
    keyPress: function(event) {
      var key_code;
      if (!this.get('is_editing')) {
        return;
      }
      key_code = event.keyCode;
      if ((key_code === 10 || key_code === 13) && event.ctrlKey) {
        this.send('save');
        event.preventDefault();
        return event.stopPropagation();
      }
    },
    didInsertElement: function() {
      this.set_is_overflowing();
      return this.set_avatar_text();
    },
    get_$input: function() {
      return this.$('textarea');
    },
    set_is_overflowing: function() {
      return ember['default'].run.schedule('afterRender', (function(_this) {
        return function() {
          var $value;
          $value = _this.$('.ts-markup_sidepocket-comment-value');
          return _this.set('is_overflowing', $value[0].scrollWidth > $value.innerWidth());
        };
      })(this));
    },
    focus_input: function() {
      if (this.get('is_editing')) {
        return ember['default'].run.schedule('afterRender', (function(_this) {
          return function() {
            var $input;
            $input = _this.get_$input();
            $input.select();
            return $input.submit(function() {
              return false;
            });
          };
        })(this));
      }
    },
    init: function() {
      this._super();
      return this.get('markup').add_comment_component(this);
    },
    willDestroyElement: function() {
      return this.get('markup').remove_comment_component(this);
    },
    set_is_editing: function() {
      this.set('is_editing', true);
      return this.focus_input();
    },
    reset_is_editing: function() {
      return this.set('is_editing', false);
    },
    toggle_is_editing: function() {
      if (this.get('is_editing')) {
        return this.reset_is_editing();
      } else {
        return this.set_is_editing();
      }
    },
    save_discussion: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var component, discussion, markup;
          markup = _this.get('markup');
          discussion = _this.get('discussion');
          component = markup.get_discussion_component_for_discussion(discussion);
          return component.save_record().then(function() {
            return resolve(discussion);
          });
        };
      })(this));
    },
    delete_discussion: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var component, discussion, markup;
          markup = _this.get('markup');
          discussion = _this.get('discussion');
          component = markup.get_discussion_component_for_discussion(discussion);
          component.delete_record();
          return resolve(discussion);
        };
      })(this));
    },
    actions: {
      edit: function() {
        return this.toggle_is_editing();
      },
      save: function() {
        var comment, model;
        model = this.get('model');
        comment = this.get('comment_text');
        model.set('comment', comment);
        this.reset_is_editing();
        this.set_is_overflowing();
        return this.save_discussion().then((function(_this) {
          return function() {
            return model.save();
          };
        })(this));
      },
      cancel: function() {
        var discussion, model;
        this.reset_is_editing();
        model = this.get('model');
        discussion = this.get('discussion');
        if (model.get('isNew')) {
          model.deleteRecord();
        }
        if (discussion.get('isNew')) {
          return this.delete_discussion();
        }
      },
      remove: function() {
        var component, discussion, markup, model;
        model = this.get('model');
        markup = this.get('markup');
        discussion = this.get('discussion');
        component = markup.get_discussion_component_for_discussion(discussion);
        return model.get(ns['default'].to_p('comments')).then((function(_this) {
          return function(comments) {
            return model.destroyRecord().then(function() {
              comments.forEach(function(comment) {
                return _this.get_store().unloadRecord(comment);
              });
              return discussion.get(ns['default'].to_p('comments')).then(function(discussion_comments) {
                if (ember['default'].isEmpty(discussion_comments)) {
                  return component.destroy_record();
                }
              });
            });
          };
        })(this));
      },
      toggle_expand: function() {
        return this.toggleProperty('is_collapsed');
      },
      add_reply: function() {
        return this.get('children').then((function(_this) {
          return function(children) {
            var discussion, markup, model, options;
            model = _this.get('model');
            discussion = _this.get('discussion');
            markup = _this.get('markup');
            options = {
              commenterable: markup.get_current_commenterable(),
              comment: 'New Reply',
              position: _this.get('children.length'),
              parent: _this.get('model')
            };
            return markup.add_comment_to_discussion_and_edit(discussion, options);
          };
        })(this));
      },
      add_to_library: function() {
        var markup, model;
        model = this.get('model');
        markup = this.get('markup');
        return markup.add_comment_to_library(model);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});