define('client/templates/components/thinkspace/casespace/case_manager/assignment/clone', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","ts-forms_field-container");
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"class","ts-radio");
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","ts-radio_button");
          var el4 = dom.createElement("input");
          dom.setAttribute(el4,"type","radio");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, element = hooks.element, content = hooks.content;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element0 = dom.childAt(fragment, [0, 0]);
          var morph0 = dom.createMorphAt(element0,1,1);
          element(env, element0, context, "action", ["clone", get(env, context, "space.id")], {"on": "click"});
          content(env, morph0, context, "space.title");
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ts-wizard-title-region");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","ts-wizard-title-region_row");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","small-3 ts-grid_columns ts-grid_columns-thick");
        var el4 = dom.createElement("h1");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-wizard-title-region_columns");
        var el4 = dom.createElement("h1");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" |  ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","emphasis");
        var el6 = dom.createTextNode(" ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","small-3 ts-grid_columns ts-grid_columns-thick");
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","btn btn-default btn-small right");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ts-wizard");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","ts-wizard_content");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-wizard_row");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-grid_columns small-12");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-forms_heading");
        var el6 = dom.createElement("h4");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-forms_field-container");
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","ts-radio");
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","ts-radio_button");
        var el8 = dom.createElement("input");
        dom.setAttribute(el8,"type","radio");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","ts-wizard_green");
        var el8 = dom.createTextNode("- current space");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, inline = hooks.inline, content = hooks.content, element = hooks.element, get = hooks.get, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element1 = dom.childAt(fragment, [0, 0]);
        var element2 = dom.childAt(element1, [1, 0]);
        var element3 = dom.childAt(element1, [2, 0]);
        var element4 = dom.childAt(fragment, [1, 0, 0, 0]);
        var element5 = dom.childAt(element4, [1, 0]);
        var morph0 = dom.createMorphAt(dom.childAt(element1, [0, 0]),0,0);
        var morph1 = dom.createMorphAt(element2,0,0);
        var morph2 = dom.createMorphAt(dom.childAt(element2, [2]),1,1);
        var morph3 = dom.createMorphAt(element3,0,0);
        var morph4 = dom.createMorphAt(dom.childAt(element4, [0, 0]),0,0);
        var morph5 = dom.createMorphAt(element5,1,1);
        var morph6 = dom.createMorphAt(element4,2,2);
        inline(env, morph0, context, "t", ["builder.casespace.mode.clone"], {});
        content(env, morph1, context, "model.space.title");
        content(env, morph2, context, "model.title");
        element(env, element3, context, "action", ["exit"], {"on": "click"});
        inline(env, morph3, context, "t", ["builder.casespace.buttons.exit"], {});
        inline(env, morph4, context, "t", ["builder.casespace.headings.clone_case"], {});
        element(env, element5, context, "action", ["clone", get(env, context, "current_space.id")], {"on": "click"});
        content(env, morph5, context, "current_space.title");
        block(env, morph6, context, "each", [get(env, context, "clone_spaces")], {"keyword": "space"}, child0, null);
        return fragment;
      }
    };
  }()));

});