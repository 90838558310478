define('client/models/thinkspace/html/content', ['exports', 'ember', 'totem/ds/associations', 'client/models/thinkspace/common/componentable'], function (exports, ember, ta, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(ta['default'].add(ta['default'].polymorphic('authable'), ta['default'].has_many('elements', {
    reads: {
      name: 'input_elements'
    }
  })), {
    html_content: ta['default'].attr('string'),
    abilities: ta['default'].attr(),
    authable_type: ta['default'].attr('string'),
    authable_id: ta['default'].attr('number')
  });

  exports['default'] = ___DefaultExportObject___;;

});