define('client/components/thinkspace/readiness_assurance/admin/shared/team_users/user', ['exports', 'ember', 'totem/ns', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    selected: ember['default'].computed('selected_users.[]', function() {
      return this.selected_users.contains(this.user);
    }),
    collapsed: ember['default'].observer('show_users', 'team_selected', function() {
      return this.set_show_user();
    }),
    show_user: true,
    actions: {
      select: function() {
        this.sendAction('select', this.user);
        return this.set_show_user();
      }
    },
    set_show_user: function() {
      if (this.show_users) {
        return this.set('show_user', true);
      } else {
        if (this.team_selected) {
          return this.set('show_user', false);
        } else {
          return this.set('show_user', this.get('selected'));
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});