define('client/models/thinkspace/input_element/response', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].belongs_to('element', {
    reads: {
      name: 'input_element'
    }
  })), {
    value: ta['default'].attr('string'),
    ownerable_id: ta['default'].attr('number'),
    ownerable_type: ta['default'].attr('string'),
    defaulted_value: ember['default'].computed('value', function() {
      var value;
      value = this.get('value');
      if (ember['default'].isPresent(value)) {
        return value;
      } else {
        return '';
      }
    }),
    didCreate: function() {
      return this.didLoad();
    },
    didLoad: function() {
      return this.get(ta['default'].to_p('element')).then((function(_this) {
        return function(element) {
          if (element) {
            return element.get(ta['default'].to_p('responses')).then(function(responses) {
              if (responses && !responses.contains(_this)) {
                return responses.pushObject(_this);
              }
            });
          }
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});