define('client/services/phase_manager', ['exports', 'ember', 'totem/ns', 'totem/scope', 'totem-messages/messages', 'thinkspace-casespace/phase_manager_map'], function (exports, ember, ns, totem_scope, totem_messages, phase_manager_map) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Object.extend({
    toString: function() {
      return 'PhaseManager';
    },
    tvo: ember['default'].inject.service(),
    casespace: ember['default'].inject.service(),
    totem_scope: ember['default'].inject.service(),
    regenerate_observer: ember['default'].observer('tvo.regenerate_view', function() {
      return this.generate_view();
    }),
    is_view_only: ember['default'].computed.reads('totem_scope.is_view_only'),
    is_current_html: '<i class="tsi tsi-left tsi-tiny tsi-right-arrow left">',
    map: phase_manager_map['default'].create(),
    reset_all: function() {
      this.set('map', phase_manager_map['default'].create());
      this.mock_phase_states_off();
      return totem_scope['default'].ownerable_to_current_user();
    },
    has_active_addon: function() {
      return this.get_active_addon() != null;
    },
    get_active_addon: function() {
      return this.get('casespace').get_active_addon();
    },
    get_active_addon_ownerable: function() {
      return this.get('casespace').get_active_addon_ownerable();
    },
    set_addon_ownerable_and_generate_view: function(ownerable) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          _this.view_is_generated_off();
          _this.show_loading_outlet();
          return ember['default'].run.next(function() {
            _this.get('casespace').set_active_addon_ownerable(ownerable);
            return _this.set_ownerable(ownerable).then(function() {
              return _this.generate_view_with_ownerable().then(function() {
                _this.hide_loading_outlet();
                return resolve();
              });
            });
          });
        };
      })(this));
    },
    validate_and_set_addon_ownerable: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var active_addon, ownerable;
          active_addon = _this.get_active_addon();
          if (!active_addon) {
            return resolve();
          }
          if (typeof active_addon.valid_addon_ownerable !== 'function') {
            return resolve();
          }
          ownerable = _this.get_active_addon_ownerable();
          return active_addon.valid_addon_ownerable(ownerable).then(function(valid) {
            if (valid) {
              return _this.set_ownerable(ownerable).then(function() {
                return resolve();
              });
            } else {
              _this.get('casespace').set_active_addon_ownerable(null);
              _this.mock_phase_states_off();
              return _this.set_ownerable(null).then(function() {
                return resolve();
              });
            }
          });
        };
      })(this));
    },
    set_ownerable: function(ownerable) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (ownerable) {
            if (totem_scope['default'].get_ownerable_record() === ownerable) {
              return resolve();
            }
            totem_scope['default'].ownerable(ownerable);
            if (!_this.phase_is_loaded()) {
              return resolve();
            }
            return _this.set_all_phase_states().then(function() {
              return resolve();
            });
          } else {
            if (totem_scope['default'].get_ownerable_record() === totem_scope['default'].get_current_user()) {
              return resolve();
            }
            totem_scope['default'].ownerable_to_current_user();
            if (!_this.phase_is_loaded()) {
              return resolve();
            }
            return _this.set_all_phase_states().then(function() {
              return resolve();
            });
          }
        };
      })(this));
    },
    set_ownerable_from_phase_state: function(phase_state) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return phase_state.get('ownerable').then(function(ownerable) {
            return _this.set_ownerable(ownerable).then(function() {
              _this.get_map().set_phase_selected_phase_state(_this.get_phase(), phase_state);
              _this.get_map().set_global_selected_phase_state(phase_state);
              return resolve();
            });
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    },
    mock_phase_states: false,
    mock_phase_states_on: function() {
      return this.set('mock_phase_states', true);
    },
    mock_phase_states_off: function() {
      return this.set('mock_phase_states', false);
    },
    mock_phase_state_object: ember['default'].Object.extend({
      ownerable: ember['default'].computed(function() {
        return new ember['default'].RSVP.Promise((function(_this) {
          return function(resolve, reject) {
            return resolve(_this.get('mock_ownerable'));
          };
        })(this));
      })
    }),
    get_mock_phase_state: function(ownerable) {
      return this.mock_phase_state_object.create({
        id: 'mock',
        mock_ownerable: ownerable,
        ownerable_type: totem_scope['default'].record_type_key(ownerable),
        ownerable_id: ownerable.get('id')
      });
    },
    get_phase_state_for_phase: function(phase, id) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var addon_ownerable, global_selected, map, ownerable, phase_state, phase_states, selected, type;
          map = _this.get_map();
          if (id && id !== 'none') {
            type = ns['default'].to_p('phase_state');
            if (phase.store.hasRecordForId(type, id)) {
              return phase.store.find(ns['default'].to_p('phase_state'), id).then(function(phase_state) {
                return resolve(phase_state);
              });
            } else {
              return resolve(null);
            }
          } else {
            addon_ownerable = (_this.has_active_addon() && _this.get_active_addon_ownerable()) || null;
            ownerable = addon_ownerable || totem_scope['default'].get_current_user();
            if (_this.get('mock_phase_states')) {
              return resolve(_this.get_mock_phase_state(ownerable));
            }
            selected = map.find_ownerable_selected_phase_state(phase, ownerable);
            if (selected) {
              return resolve(selected);
            }
            global_selected = map.get_global_selected_phase_state();
            if (global_selected) {
              phase_states = map.find_ownerable_phase_states(phase, ownerable);
              if (phase_states.contains(global_selected)) {
                return resolve(global_selected);
              }
              phase_state = map.find_phase_state_ownerable_in_phase_states(global_selected, phase_states);
              return resolve(phase_state);
            } else {
              return resolve(null);
            }
          }
        };
      })(this));
    },
    set_all_phase_states: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.validate_and_set_addon_ownerable().then(function() {
            var assignment, map;
            assignment = _this.get_assignment();
            map = _this.get_map();
            if (_this.get('mock_phase_states')) {
              return map.set_map_without_phase_states(assignment).then(function() {
                return resolve();
              });
            } else {
              return map.set_map(assignment).then(function() {
                return resolve();
              });
            }
          });
        };
      })(this));
    },
    has_phase_view: ember['default'].computed('view_container', 'view_is_generated', function() {
      return this.valid_view_container() && this.get('view_is_generated');
    }),
    loaded_phase_ids: [],
    view_container: null,
    view_is_generated: false,
    view_is_generated_on: function() {
      return this.set('view_is_generated', true);
    },
    view_is_generated_off: function() {
      return this.set('view_is_generated', false);
    },
    phase_is_loaded: function() {
      var phase;
      phase = this.get_phase();
      return phase && this.get('loaded_phase_ids').contains(phase.get('id'));
    },
    generate_view: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.validate_and_set_addon_ownerable().then(function() {
            return _this.generate_view_with_ownerable().then(function() {
              return resolve();
            });
          });
        };
      })(this));
    },
    generate_view_with_ownerable: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var loaded_ids, phase, phase_id;
          _this.view_is_generated_off();
          phase = _this.get_phase();
          totem_scope['default'].authable(phase);
          if (_this.phase_is_loaded()) {
            return _this.set_totem_scope_view_ability().then(function() {
              return _this.build_view(phase).then(function() {
                return resolve();
              });
            });
          } else {
            phase_id = phase.get('id');
            loaded_ids = _this.get('loaded_phase_ids');
            loaded_ids.push(phase_id);
            return phase.store.find(ns['default'].to_p('phase'), {
              action: 'load',
              id: phase_id
            }).then(function() {
              return _this.set_all_phase_states().then(function() {
                return _this.refresh_abilites_for_phase(phase).then(function() {
                  return _this.set_totem_scope_view_ability().then(function() {
                    return _this.validate_phase_and_build_view(phase).then(function() {
                      return resolve();
                    });
                  });
                });
              });
            });
          }
        };
      })(this));
    },
    refresh_abilites_for_phase: function(phase) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return phase.totem_data.ability.refresh().then(function() {
            return resolve();
          });
        };
      })(this));
    },
    validate_phase_and_build_view: function(phase) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (phase.get('can.read_phase')) {
            return _this.build_view(phase).then(function() {
              return resolve();
            });
          } else {
            _this.transition_from_phase(phase);
            return reject();
          }
        };
      })(this));
    },
    build_view: function(phase) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var tvo, view_container;
          _this.debug_ownerable();
          view_container = _this.get_view_container();
          view_container.removeAllChildren();
          tvo = _this.get('tvo');
          tvo.clear();
          tvo.hash.set_value('show_errors', false);
          tvo.hash.set_value('process_validations', phase.get('configuration_validate'));
          return phase.get(ns['default'].to_p('phase_template')).then(function(template) {
            return phase.get(ns['default'].to_p('phase_components')).then(function(components) {
              tvo.template.parse(template.get('template'));
              return tvo.template.add_components(components).then(function() {
                var view;
                view = ember['default'].View.create({
                  template: tvo.template.compile()
                });
                view_container.pushObject(view);
                _this.view_is_generated_on();
                _this.hide_loading_outlet();
                return resolve();
              });
            }, function(error) {
              return reject(error);
            });
          }, function(error) {
            return reject(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return reject(error);
        };
      })(this));
    },
    set_totem_scope_view_ability: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var phase, phase_state;
          if (_this.get('casespace').has_active_addon()) {
            return resolve();
          }
          phase = _this.get_phase();
          phase_state = _this.get_map().get_phase_selected_phase_state(phase);
          if (!phase_state) {
            totem_scope['default'].view_only_on();
            return resolve();
          }
          if (phase_state.get('is_view_only') || (phase.get('can.read_phase') && !phase.get('can.modify_phase'))) {
            totem_scope['default'].view_only_on();
            return resolve();
          }
          if (phase.is_team_ownerable()) {
            return _this.set_totem_scope_view_ability_team_ownerable().then(function() {
              return resolve();
            });
          } else {
            totem_scope['default'].view_only_off();
            return resolve();
          }
        };
      })(this));
    },
    set_totem_scope_view_ability_team_ownerable: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var phase, phase_state;
          phase = _this.get_phase();
          phase_state = _this.get_map().get_phase_selected_phase_state(phase);
          if (phase_state) {
            return phase_state.get('ownerable').then(function(ownerable) {
              if (ownerable && ownerable.get('is_member')) {
                totem_scope['default'].view_only_off();
              } else {
                totem_scope['default'].view_only_on();
              }
              return resolve();
            });
          } else {
            totem_scope['default'].view_only_on();
            return resolve();
          }
        };
      })(this));
    },
    transition_from_phase: function(phase) {
      var app_route;
      app_route = totem_messages['default'].get_app_route();
      totem_messages['default'].error('You cannot access this phase.');
      this.hide_loading_outlet();
      if (phase.get('can.modify_assignment')) {
        return app_route.transitionTo(ns['default'].to_r('assignments', 'show'), this.get_assignment());
      } else {
        return app_route.transitionTo(ns['default'].to_r('spaces', 'index'));
      }
    },
    get_current_view_container: function() {
      return this.get('view_container');
    },
    set_current_view_container: function(container) {
      return this.set('view_container', container);
    },
    get_view_container: function() {
      var view_container;
      if (this.valid_view_container()) {
        return this.get_current_view_container();
      } else {
        view_container = this.container.lookup('view:template_manager_view_container');
        this.set_current_view_container(view_container);
        return view_container;
      }
    },
    valid_view_container: function() {
      var view_container;
      view_container = this.get_current_view_container();
      return view_container && !(view_container.get('isDestroyed') || view_container.get('isDestroying'));
    },
    get_map: function() {
      return this.get('map');
    },
    get_assignment: function() {
      return this.get('casespace').get_current_assignment();
    },
    get_phase: function() {
      return this.get('casespace').get_current_phase();
    },
    show_loading_outlet: function() {
      return totem_messages['default'].show_loading_outlet();
    },
    hide_loading_outlet: function() {
      return totem_messages['default'].hide_loading_outlet();
    },
    debug_ownerable: function(text) {
      var ownerable;
      if (text == null) {
        text = '';
      }
      ownerable = totem_scope['default'].get_ownerable_record();
      if (!ownerable) {
        console.warn(text + 'ownerable is blank');
        return;
      }
      if (ownerable && totem_scope['default'].ownerable_is_type_user()) {
        return console.info(text + "[user: " + (ownerable.get('first_name')) + "] ownerable:", ownerable.toString());
      } else {
        return console.info(text + "[team: " + (ownerable.get('title')) + "] ownerable:", ownerable.toString());
      }
    },
    debug_phase_states: function() {
      var assignment, map, ownerable;
      map = this.get_map();
      assignment = this.get_assignment();
      ownerable = totem_scope['default'].get_ownerable_record();
      console.warn('-------------------------------------------------');
      console.info('ownerable:', ownerable);
      console.info('assignment:', assignment);
      return assignment.get(ns['default'].to_p('phases')).then((function(_this) {
        return function(phases) {
          phases.forEach(function(phase) {
            console.info('......phase:', phase);
            return console.warn('phase_state:', phase.get('phase_state'));
          });
          return console.warn('-------------------------------------------------');
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});