define('client/components/thinkspace/peer_assessment/builder/assessment/toolbar', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    builder: ember['default'].inject.service(),
    manager: ember['default'].inject.service(ns['default'].to_p('peer_assessment', 'builder', 'manager')),
    action_handler: null,
    actions: {
      add_quant_item: function() {
        return this.get('action_handler').send('add_quant_item');
      },
      add_qual_item: function(type) {
        return this.get('action_handler').send('add_qual_item', type);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});