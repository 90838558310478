define('client/components/thinkspace/casespace/ownerable/selector', ['exports', 'ember', 'totem/ns', 'totem/ds/associations', 'thinkspace-base/components/base'], function (exports, ember, ns, ta, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    casespace: ember['default'].inject.service(),
    ownerables: null,
    search: null,
    multiple: false,
    selected_ownerables: null,
    menu_only: false,
    searchable: false,
    is_selecting_ownerables: false,
    click_watcher: null,
    click_watcher_event: 'mouseup.ownerable_selector',
    close_on_click: true,
    model: ember['default'].computed.reads('casespace.current_phase'),
    current_user: ember['default'].computed.reads('totem_scope.current_user'),
    has_selected_ownerables: ember['default'].computed.notEmpty('selected_ownerables'),
    is_searchable: ember['default'].computed.reads('searchable'),
    is_searching: ember['default'].computed.notEmpty('search'),
    is_not_menu_only: ember['default'].computed.not('menu_only'),
    filtered_ownerables: ember['default'].computed('search', 'ownerables', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var current_user, search;
          search = _this.get('search');
          current_user = _this.get('current_user');
          return _this.get_ownerables().then(function(ownerables) {
            var matches;
            if (ember['default'].isPresent(search)) {
              matches = [];
              ownerables.forEach(function(ownerable) {
                var full_name;
                full_name = ownerable.get('full_name');
                if (full_name.match(new RegExp(search))) {
                  return matches.pushObject(ownerable);
                }
              });
              return resolve(matches);
            } else {
              return resolve(ownerables.without(current_user));
            }
          });
        };
      })(this));
      return ta['default'].PromiseArray.create({
        promise: promise
      });
    }),
    c_selector_ownerable: ns['default'].to_p('casespace', 'ownerable', 'selector', 'ownerable'),
    c_loader: ns['default'].to_p('common', 'loader'),
    is_selecting_ownerables_observer: ember['default'].observer('is_selecting_ownerables', function() {
      var close_on_click, is_selecting;
      close_on_click = this.get('close_on_click');
      if (!close_on_click) {
        return;
      }
      is_selecting = this.get('is_selecting_ownerables');
      if (is_selecting) {
        return this.bind_click_watcher();
      } else {
        return this.unbind_click_watcher();
      }
    }),
    willDestroyElement: function() {
      return this.unbind_click_watcher();
    },
    set_is_selecting_ownerables: function() {
      return this.set('is_selecting_ownerables', true);
    },
    reset_is_selecting_ownerables: function() {
      return this.set('is_selecting_ownerables', false);
    },
    get_ownerables: function() {
      var ownerables;
      ownerables = this.get('ownerables');
      if (ownerables.then != null) {
        return ownerables;
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return resolve(ownerables);
        };
      })(this));
    },
    get_click_watcher_event: function() {
      return this.get('click_watcher_event');
    },
    set_click_watcher: function(handler) {
      return this.set('click_watcher', handler);
    },
    reset_click_watcher: function() {
      return this.set('click_watcher', null);
    },
    get_click_watcher: function() {
      return this.get('click_watcher');
    },
    bind_click_watcher: function() {
      var event, watcher;
      watcher = this.get_click_watcher();
      if (ember['default'].isPresent(watcher)) {
        return;
      }
      event = this.get_click_watcher_event();
      watcher = $(document).bind(event, (function(_this) {
        return function(e) {
          var $container;
          $container = _this.$();
          if (!$container.is(e.target) && $container.has(e.target).length === 0) {
            return _this.reset_is_selecting_ownerables();
          }
        };
      })(this));
      return this.set_click_watcher(watcher);
    },
    unbind_click_watcher: function() {
      var event, watcher;
      watcher = this.get_click_watcher();
      if (!ember['default'].isPresent(watcher)) {
        return;
      }
      event = this.get_click_watcher_event();
      $(document).unbind(event);
      return this.reset_click_watcher();
    },
    actions: {
      cancel: function() {
        return this.sendAction('cancel');
      },
      select: function(model) {
        return this.sendAction('select', model);
      },
      set_is_selecting_ownerables: function() {
        return this.set_is_selecting_ownerables();
      },
      toggle_is_selecting_ownerables: function() {
        return this.toggleProperty('is_selecting_ownerables');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});