define('client/models/thinkspace/markup/library_comment', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].belongs_to('library', {
    reads: {}
  })), {
    comment: ta['default'].attr('string'),
    user_id: ta['default'].attr('number'),
    uses: ta['default'].attr('number'),
    last_used: ta['default'].attr('date'),
    created_at: ta['default'].attr('date'),
    all_tags: ta['default'].attr(),
    increment_uses: function(count) {
      var uses;
      if (count == null) {
        count = 1;
      }
      uses = this.get('uses');
      this.set('uses', uses + count);
      return this.save();
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});