define('client/models/thinkspace/casespace/assignment', ['exports', 'ember', 'totem/ds/associations', 'totem/cache', 'thinkspace-resource/mixins/resources', 'totem-application/mixins/included_models'], function (exports, ember, ta, tc, resource_mixin, included_models_mixin) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].totem_data, resource_mixin['default'], included_models_mixin['default'], ta['default'].add(ta['default'].belongs_to('space', {
    reads: {}
  }), ta['default'].has_many('phases', {
    reads: {
      sort: 'position:asc'
    }
  })), {
    title: ta['default'].attr('string'),
    description: ta['default'].attr('string'),
    active: ta['default'].attr('boolean'),
    instructions: ta['default'].attr('string'),
    name: ta['default'].attr('string'),
    template_id: ta['default'].attr('number'),
    bundle_type: ta['default'].attr('string'),
    state: ta['default'].attr('string'),
    release_at: ta['default'].attr('date'),
    due_at: ta['default'].attr('date'),
    settings: ta['default'].attr(),
    included_options: ta['default'].attr(),
    builder_version: ta['default'].attr('number'),
    builder_template_id: ta['default'].attr('number'),
    is_active: ember['default'].computed.equal('state', 'active'),
    is_inactive: ember['default'].computed.equal('state', 'inactive'),
    ttz: ember['default'].inject.service(),
    totem_data_config: {
      ability: true,
      metadata: true
    },
    default_name: 'Case',
    states: ['inactive', 'active'],
    components: ember['default'].computed.reads('settings.ember.components'),
    assignment_name: ember['default'].computed.or('name', 'default_name'),
    first_phase: ember['default'].computed.reads('phases.firstObject'),
    is_peer_assessment: ember['default'].computed.equal('bundle_type', 'assessment'),
    is_casespace: ember['default'].computed.equal('bundle_type', 'casespace'),
    is_past_due: ember['default'].computed('metadata_due_at', function() {
      return this.get('metadata_due_at') <= Date.now();
    }),
    friendly_due_at: ember['default'].computed('metadata', 'metadata.due_at', function() {
      var due_at;
      due_at = this.get('metadata.due_at');
      if (!ember['default'].isPresent(due_at)) {
        return null;
      }
      due_at = Date.parse(due_at);
      return this.get('ttz').format(due_at, {
        format: 'MMM Do, h:mm a'
      });
    }),
    metadata_due_at: ember['default'].computed('metadata', 'metadata.due_at', function() {
      var due_at;
      due_at = this.get('metadata.due_at');
      if (ember['default'].isPresent(due_at)) {
        return Date.parse(due_at);
      } else {
        return null;
      }
    }),
    add_ability: function(abilities) {
      var update;
      update = abilities.gradebook;
      abilities.update = update;
      abilities.clone = update;
      return abilities.destroy = update;
    },
    save_phase_positions: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var update;
          update = [];
          return _this.get(ta['default'].to_p('phases')).then(function(phases) {
            var phase_order, query;
            phases.forEach(function(phase) {
              if (phase.get('isDirty')) {
                return update.push(phase);
              }
            });
            if (ember['default'].isEmpty(update)) {
              return resolve();
            }
            phase_order = [];
            update.forEach(function(phase) {
              return phase_order.push({
                phase_id: phase.get('id'),
                position: phase.get('position')
              });
            });
            query = {
              action: 'phase_order',
              verb: 'put',
              phase_order: phase_order,
              id: _this.get('id')
            };
            return tc['default'].query(ta['default'].to_p('assignment'), query, {
              payload_type: ta['default'].to_p('phase')
            }).then(function() {
              return resolve();
            }, function(error) {
              return _this.error(error);
            });
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    phase_state_promise: function(states) {
      var promise;
      states = ember['default'].makeArray(states);
      return promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get(ta['default'].to_p('phases')).then(function(phases) {
            var filtered, sorted;
            filtered = phases.filter(function(phase) {
              return states.contains(phase.get('state'));
            });
            sorted = filtered.sortBy('position');
            return resolve(sorted);
          });
        };
      })(this));
    },
    phases_without_team_set: ember['default'].computed('phases.@each.team_category_id', 'phases.@each.team_set_id', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var phases;
          phases = new Array;
          return _this.get(ta['default'].to_p('phases')).then(function(phases) {
            var filtered;
            filtered = phases.filter(function(phase) {
              return phase.get('has_team_category_without_team_set');
            });
            return resolve(filtered);
          });
        };
      })(this));
      return ta['default'].PromiseArray.create({
        promise: promise
      });
    }),
    has_phases_without_team_set: ember['default'].computed.notEmpty('phases_without_team_set'),
    has_no_phases_without_team_set: ember['default'].computed.not('has_phases_without_team_set'),
    has_inactive_phases: ember['default'].computed.notEmpty('inactive_phases'),
    has_no_inactive_phases: ember['default'].computed.not('has_inactive_phases'),
    inactive_phases: ember['default'].computed('phases.@each.state', function() {
      return ta['default'].PromiseArray.create({
        promise: this.phase_state_promise('inactive')
      });
    }),
    active_phases: ember['default'].computed('phases.@each.state', function() {
      return ta['default'].PromiseArray.create({
        promise: this.phase_state_promise(['active', 'inactive'])
      });
    }),
    archived_phases: ember['default'].computed('phases.@each.state', function() {
      return ta['default'].PromiseArray.create({
        promise: this.phase_state_promise('archived')
      });
    }),
    has_valid_phases: ember['default'].computed.and('has_no_phases_without_team_set', 'has_no_inactive_phases'),
    model_state_change: function(action) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return tc['default'].query(ta['default'].to_p('assignment'), {
            id: _this.get('id'),
            action: action,
            verb: 'PUT'
          }, {
            single: true
          }).then(function(assignment) {
            return resolve();
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    activate: function() {
      return this.model_state_change('activate');
    },
    inactivate: function() {
      return this.model_state_change('inactivate');
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});