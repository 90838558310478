define('client/views/select/optgroup', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].View.extend({
    tagName: 'optgroup',
    templateName: 'select/optgroup',
    attributeBindings: ['label'],
    data_placeholder: 'Select an Option',
    is_record_list: true,
    content: null,
    optgroups: null,
    display_property: null,
    selected_items: [],
    disabled_items: [],
    option_class_names: '',
    parent_view: ember['default'].computed.alias('parentView'),
    select_ids_recomputed: ember['default'].computed.alias('parent_view.select_ids_recomputed'),
    totem_select_id_property: ember['default'].computed.alias('parent_view.totem_select_id_property'),
    label: (function() {
      var content, group, name, optgroups;
      optgroups = this.get('optgroups');
      content = this.get('content');
      for (name in optgroups) {
        group = optgroups[name];
        if (this.compare_arrays(content, group)) {
          return name;
        }
      }
      return null;
    }).property('content', 'optgroups'),
    compare_arrays: function(array_a, array_b) {
      var equal;
      equal = true;
      array_a = ember['default'].makeArray(array_a);
      array_b = ember['default'].makeArray(array_b);
      array_a.forEach((function(_this) {
        return function(a, index) {
          equal = array_b.objectAt(index) === a;
          if (!equal) {

          }
        };
      })(this));
      return equal;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});