define('client/components/thinkspace/artifact/bucket/file/pdf/wrapper', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    classNames: ['artifact_pdf-wrapper', 'clearfix'],
    classNameBindings: ['show_file:is-visible:is-hidden'],
    c_pdf_file: ns['default'].to_p('artifact', 'bucket', 'file', 'pdf', 'file'),
    file_container_id: ember['default'].computed('model', function() {
      return this.get('model.container_id');
    }),
    comment_section: ember['default'].computed.reads('file_container_id'),
    can_comment: true,
    didInsertElement: function() {
      var width;
      if (this.get('can_comment')) {
        width = '1260px';
        return this.$().css('width', width).css('margin', 0).css('position', 'relative');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});