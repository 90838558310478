define('client/components/thinkspace/markup/library/edit/manager/comments', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    comments: null,
    model: null,
    tagName: '',
    remove_tag: null,
    add_tag: null,
    c_library_comment: ns['default'].to_p('markup', 'library', 'edit', 'manager', 'comment'),
    actions: {
      remove_tag: function(comment, tag) {
        return this.sendAction('remove_tag', comment, tag);
      },
      add_tag: function(comment, tag) {
        return this.sendAction('add_tag', comment, tag);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});