define('client/components/thinkspace/lab/builder/lab/category/form', ['exports', 'ember', 'totem/ns', 'totem/mixins/validations', 'thinkspace-base/components/base'], function (exports, ember, ns, val_mixin, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend(val_mixin['default'], {
    admin: ember['default'].inject.service(ns['default'].to_p('lab', 'admin')),
    category_columns: ember['default'].computed(function() {
      var admin, category;
      admin = this.get('admin');
      category = this.get('model');
      return admin.category_columns(category);
    }),
    title: null,
    correctable_prompt: null,
    show_errors: false,
    actions: {
      cancel: function() {
        return this.sendAction('cancel');
      },
      save: function() {
        var category;
        if (!this.get('is_valid')) {
          this.set('show_errors', true);
          return;
        }
        category = this.get('model');
        category.set('title', this.get('title'));
        category.set('value.correctable_prompt', this.get('correctable_prompt'));
        category.set('value.columns', this.get('category_columns'));
        return this.sendAction('save');
      }
    },
    didInsertElement: function() {
      this.set('title', this.get('model.title'));
      this.set('correctable_prompt', this.get('model.value.correctable_prompt'));
      return this.$('input').first().focus();
    },
    validations: {
      title: {
        presence: true
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});