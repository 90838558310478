define('client/components/thinkspace/casespace/case_manager/assignment/wizards/casespace/steps/phases/destroy', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace-case-manager/components/wizards/steps/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    get_modal: function() {
      return $('.reveal-modal');
    },
    destroy_modal: function() {
      return this.get_modal().foundation('reveal', 'close');
    },
    didInsertElement: function() {
      return this.get_modal().foundation('reveal', 'open');
    },
    actions: {
      approve: function() {
        this.destroy_modal();
        return this.sendAction('approve');
      },
      cancel: function() {
        this.destroy_modal();
        return this.sendAction('cancel');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});