define('client/thinkspace/team/assign/team/view', ['exports', 'ember', 'ember-data', 'totem/ns'], function (exports, ember, ds, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].View.extend({
    tagName: 'li',
    classNames: ['team_assign-teams-list-team'],
    attributeBindings: ['title'],
    title: ember['default'].computed(function() {
      return "Add/remove team from '" + (this.get('controller.selected_resource.title')) + "'";
    }),
    is_team_assigned: ember['default'].computed("team." + (ns['default'].to_prop('team_teamables', '@each')), function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get_controller().get_resource_teams(_this.get_resource()).then(function(teams) {
            return resolve(teams.contains(_this.get_team()));
          });
        };
      })(this));
      return ds['default'].PromiseObject.create({
        promise: promise
      });
    }),
    users_visible: false,
    team_users_visible: ember['default'].observer('controller.team_users_visible', function() {
      return this.set('users_visible', this.get('controller.team_users_visible'));
    }),
    team_users: ember['default'].computed("team." + (ns['default'].to_prop('team_users', '@each')), function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get_team().get(ns['default'].to_p('team_users')).then(function(team_users) {
            var user_promises;
            user_promises = team_users.getEach(common.to_p('user'));
            return ember['default'].RSVP.Promise.all(user_promises).then(function(users) {
              return resolve(users.sortBy('sort_name'));
            });
          });
        };
      })(this));
      return ds['default'].PromiseArray.create({
        promise: promise
      });
    }),
    get_team: function() {
      return this.get('team');
    },
    get_resource: function() {
      return this.get('controller.selected_resource');
    },
    get_controller: function() {
      return this.get('controller');
    },
    actions: {
      add_team: function() {
        return this.get_controller().create_team_teamable(this.get_team(), this.get_resource());
      },
      remove_team: function() {
        return this.get_controller().delete_team_teamable(this.get_team(), this.get_resource());
      },
      toggle_users_visible: function() {
        this.toggleProperty('users_visible');
        return false;
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});