define('client/thinkspace/common/spaces/scores/route', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin', 'thinkspace-base/base/route'], function (exports, ember, auth_mixin, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(auth_mixin['default'], {
    thinkspace: ember['default'].inject.service(),
    casespace: ember['default'].inject.service(),
    titleToken: function(model) {
      return model.get('title');
    },
    beforeModel: function(transition) {
      this.get('thinkspace').set_current_transition(transition);
      return this._super(transition);
    },
    model: function(params) {
      return this.store.find(this.ns.to_p('space'), params.space_id).then((function(_this) {
        return function(space) {
          return _this.totem_messages.api_success({
            source: _this,
            model: space
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.totem_messages.api_failure(error, {
            source: _this,
            model: _this.ns.to_p('space')
          });
        };
      })(this));
    },
    setupController: function(controller, model) {
      return controller.set('model', model);
    },
    renderTemplate: function(controller, model) {
      return this.get('casespace').set_current_models({
        space: model
      }).then((function(_this) {
        return function() {
          return _this.render();
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});