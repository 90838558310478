define('client/models/thinkspace/readiness_assurance/chat', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].belongs_to('ra:response')), {
    messages: ta['default'].attr()
  });

  exports['default'] = ___DefaultExportObject___;;

});