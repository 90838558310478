define('client/thinkspace/casespace/assignments/route', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin', 'thinkspace-base/base/route'], function (exports, ember, auth_mixin, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(auth_mixin['default'], {
    titleToken: 'Assignments',
    renderTemplate: function() {
      return this.render();
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});