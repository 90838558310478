define('client/components/thinkspace/indented_list/list/layout/other', ['exports', 'ember', 'totem/ns', 'client/components/thinkspace/indented_list/list/layout/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    c_list_source_another: ns['default'].to_p('indented_list', 'list', 'source', 'another'),
    validate_indented_list: function(status) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          console.warn('validating other indented list.....................', _this, status);
          status.set_is_valid(false);
          status.increment_invalid_count();
          status.set_status_messages(["Use all observations."]);
          return resolve();
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});