define('client/components/thinkspace/resource/manage/link/new', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    t_manage_link_form: ns['default'].to_t('resource', 'manage', 'link', 'form'),
    title: null,
    url: null,
    selection: null,
    actions: {
      cancel: function() {
        return this.sendAction('cancel');
      },
      save: function() {
        var link, resourceable, title, url;
        resourceable = this.get('resourceable');
        title = this.get('title');
        url = this.get('url');
        link = resourceable.store.createRecord(ns['default'].to_p('link'), {
          title: title,
          url: url,
          resourceable_type: this.totem_scope.record_type_key(resourceable),
          resourceable_id: resourceable.get('id')
        });
        link.set_new_tags(this.get('selection'));
        return link.save().then((function(_this) {
          return function(link) {
            _this.totem_messages.api_success({
              source: _this,
              model: link,
              i18n_path: ns['default'].to_o('link', 'save')
            });
            return _this.send('cancel');
          };
        })(this), (function(_this) {
          return function(error) {
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: link
            });
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});