define('client/components/thinkspace/readiness_assurance/response/irat/questions', ['exports', 'ember', 'totem/ns', 'thinkspace-readiness-assurance/base/ra_component'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    question_managers: ember['default'].computed(function() {
      var managers;
      managers = [];
      this.rm.question_manager_map.forEach((function(_this) {
        return function(qm) {
          return managers.push(qm);
        };
      })(this));
      return managers;
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});