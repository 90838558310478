define('client/components/thinkspace/weather_forecaster/assessment/show', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    c_attempt: ns['default'].to_p('wf:assessment', 'attempt'),
    c_forecast: ns['default'].to_p('wf:forecast', 'show'),
    forecast_observer: ember['default'].observer('forecast', function() {
      return this.get_forecast_responses().then((function(_this) {
        return function() {
          return _this.rerender();
        };
      })(this));
    }),
    actions: {
      show_help: function(help_tip) {
        return this.sendAction('show_help', help_tip);
      }
    },
    get_forecast_responses: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var forecast, query;
          forecast = _this.get('forecast');
          query = _this.totem_scope.get_view_query(forecast);
          _this.totem_messages.show_loading_outlet();
          return forecast.store.find(ns['default'].to_p('wf:forecast'), query).then(function() {
            _this.totem_messages.api_success({
              source: _this,
              model: forecast,
              action: 'save'
            });
            _this.totem_messages.hide_loading_outlet();
            return resolve();
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.totem_messages.api_failure(error, {
            source: _this,
            model: forecast,
            action: 'save'
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});