define('client/components/thinkspace/casespace/assignment/phase', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    phase_manager: ember['default'].inject.service(),
    ttz: ember['default'].inject.service(),
    phase_states: ember['default'].computed(function() {
      if (this.get('phase_manager').has_active_addon()) {
        return this.get('phase_manager.map').get_ownerable_phase_states(this.get('model'));
      } else {
        return this.get('phase_manager.map').get_current_user_phase_states(this.get('model'));
      }
    }),
    due_at: ember['default'].computed.reads('model.phase_state.metadata.due_at'),
    unlock_at: ember['default'].computed.reads('model.phase_state.metadata.unlock_at'),
    friendly_due_at_date: ember['default'].computed('due_at', function() {
      var date;
      date = this.get('due_at');
      if (!ember['default'].isPresent(date)) {
        return 'N/A';
      }
      return this.get('ttz').format(date, {
        format: 'MMM D, YYYY',
        zone: this.get('ttz').get_client_zone_iana()
      });
    }),
    friendly_due_at_time: ember['default'].computed('due_at', function() {
      var date;
      date = this.get('due_at');
      if (!ember['default'].isPresent(date)) {
        return 'N/A';
      }
      return this.get('ttz').format(date, {
        format: 'h:mm a',
        zone: this.get('ttz').get_client_zone_iana()
      });
    }),
    friendly_unlock_at_date: ember['default'].computed('unlock_at', function() {
      var date;
      date = this.get('unlock_at');
      if (!ember['default'].isPresent(date)) {
        return 'N/A';
      }
      return this.get('ttz').format(date, {
        format: 'MMM D, YYYY',
        zone: this.get('ttz').get_client_zone_iana()
      });
    }),
    friendly_unlock_at_time: ember['default'].computed('unlock_at', function() {
      var date;
      date = this.get('unlock_at');
      if (!ember['default'].isPresent(date)) {
        return 'N/A';
      }
      return this.get('ttz').format(date, {
        format: 'h:mm a',
        zone: this.get('ttz').get_client_zone_iana()
      });
    }),
    friendly_unlock_mode: ember['default'].computed('mode', function() {
      var mode, previous_phase, previous_phase_position;
      mode = this.get('mode');
      switch (mode) {
        case 'date':
          return "on " + (this.get('friendly_unlock_at_date')) + " at " + (this.get('friendly_unlock_at_time'));
        case 'manual':
          return "manually (currently " + (this.get('model.phase_state.current_state')) + ")";
        case 'case_release':
          return 'on case release';
        case 'on_previous_phase':
          previous_phase = this.get('previous_phase.phase');
          previous_phase_position = this.get('previous_phase.position');
          return "after " + previous_phase_position + ". " + (previous_phase.get('title'));
      }
    }),
    mode: null,
    is_date_mode: ember['default'].computed.equal('mode', 'date'),
    is_manual_mode: ember['default'].computed.equal('mode', 'manual'),
    is_case_release_mode: ember['default'].computed.equal('mode', 'case_release'),
    is_previous_phase_mode: ember['default'].computed.equal('mode', 'on_previous_phase'),
    r_case_builder_logistics: ns['default'].to_r('builder', 'cases', 'logistics'),
    init: function() {
      this._super();
      return this.init_position().then((function(_this) {
        return function() {
          return _this.init_previous_phase().then(function() {
            return _this.init_select_option();
          });
        };
      })(this));
    },
    init_position: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model;
          model = _this.get('model');
          return model.get('position_in_assignment').then(function(position) {
            _this.set('position', position.value);
            return resolve();
          });
        };
      })(this));
    },
    init_previous_phase: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model;
          model = _this.get('model');
          return model.get('previous_phase').then(function(phase) {
            var obj;
            obj = {
              phase: null,
              position: null
            };
            if (ember['default'].isPresent(phase)) {
              obj.phase = phase;
              phase.get('position_in_assignment').then(function(position) {
                if (ember['default'].isPresent(position)) {
                  return obj.position = position.value;
                }
              });
            }
            if (ember['default'].isPresent(obj)) {
              _this.set('previous_phase', obj);
            }
            return resolve();
          });
        };
      })(this));
    },
    init_select_option: function() {
      var model, previous_phase;
      model = this.get('model');
      previous_phase = this.get('previous_phase.phase');
      if (model.get('unlock_at')) {
        return this.set_mode('date');
      } else if (model.get('default_state') === 'unlocked') {
        return this.set_mode('case_release');
      } else if (ember['default'].isPresent(previous_phase) && previous_phase.get('has_unlock_phase')) {
        return this.set_mode('on_previous_phase');
      } else {
        return this.set_mode('manual');
      }
    },
    set_mode: function(mode) {
      return this.set('mode', mode);
    },
    reset_mode: function() {
      return this.set('mode', null);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});