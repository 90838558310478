define('client/thinkspace/casespace/case_manager/teams/route', ['exports', 'ember', 'thinkspace-casespace-case-manager/routes/base', 'totem/ns'], function (exports, ember, base, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    thinkspace: ember['default'].inject.service(),
    team_manager: ember['default'].inject.service(),
    activate: function() {
      return this.get('thinkspace').disable_wizard_mode();
    },
    model: function(params) {
      return this.get('team_manager').get_team_set_from_params(params);
    },
    setupController: function(controller, model) {
      return this.get('team_manager').set_team_set_and_space(model).then((function(_this) {
        return function() {
          return controller.set('model', model);
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});