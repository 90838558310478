define('client/components/thinkspace/lab/observation/vet_med/input', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    init: function() {
      var analysis_lo, value;
      this._super();
      this.get_lab_observation().register_component(this);
      value = this.get_value();
      this.set_input_value(value);
      this.add_user_input(value);
      analysis_lo = this.get_analysis_lab_observation();
      if (analysis_lo.get('is_correct') && analysis_lo.get('detail_key_is_correct') && ember['default'].isPresent(this.get_lowercase_correct_values())) {
        this.get_lab_observation().set_show_input(true);
      }
      return this.set('number_of_attempts', 0);
    },
    get_lab_observation: function() {
      return this.get('lab_observation');
    },
    get_analysis_lab_observation: function() {
      return this.get_lab_observation().get_result_lab_observation_for_key('analysis');
    },
    set_show_message: function(value) {
      return this.get_lab_observation().set_show_message(value);
    },
    get_value: function() {
      return this.get_lab_observation().get_value();
    },
    set_value: function(value) {
      return this.get_lab_observation().set_value(value);
    },
    set_lab_observation_value: function() {
      return this.set_value(this.get_input_value());
    },
    get_result: function() {
      return this.get_lab_observation().get_result();
    },
    save_result: function() {
      return this.get_lab_observation().save_result();
    },
    get_input_value: function() {
      return this.get('input_value');
    },
    set_input_value: function(value) {
      return this.set('input_value', value);
    },
    add_user_input: function(input) {
      return this.get_lab_observation().add_user_input(input);
    },
    get_entered_user_input: function(input) {
      return this.get_lab_observation().entered_user_input(input);
    },
    add_to_observation_list: function(input) {
      return this.get_lab_observation().add_to_observation_list(input);
    },
    get_max_attempts: function() {
      return this.get_lab_observation().get_max_attempts();
    },
    focus_self: function() {
      return this.$('input').focus();
    },
    focus_next_result: function() {
      return this.get_lab_observation().set_focus_on_next_result();
    },
    get_error_message: function() {
      return this.get_lab_observation().get_error_message();
    },
    set_error_message: function(message) {
      return this.get_lab_observation().set_error_message(message);
    },
    clear_error_message: function() {
      return this.get_lab_observation().clear_error_message();
    },
    is_correct: null,
    correct_input: function(input) {
      if (ember['default'].isBlank(input)) {
        return false;
      }
      return this.get_lowercase_correct_values().contains(input.toLowerCase());
    },
    get_lowercase_correct_values: function() {
      return this.get('correct_values').map(function(val) {
        return val.toLowerCase();
      });
    },
    get_lowercase_user_values: function() {
      return this.get_lab_observation().get_user_inputs();
    },
    get_correct_entered_user_input: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          _this.set('lowercase_correct_values', _this.get_lowercase_correct_values());
          _this.set('lowercase_input_values', _this.get_lowercase_user_values());
          return ember['default'].run.next(function() {
            var input, values;
            values = _this.get('intersect_values');
            if (ember['default'].isBlank(values)) {
              return null;
            }
            input = values.get('firstObject');
            return resolve(_this.get_entered_user_input(input));
          });
        };
      })(this));
    },
    lowercase_correct_values: [],
    lowercase_input_values: [],
    intersect_values: ember['default'].computed.intersect('lowercase_correct_values', 'lowercase_input_values'),
    correct_link_text: ember['default'].computed(function() {
      return this.get_lab_observation().show_correct_values_text();
    }),
    correct_values: ember['default'].computed(function() {
      return ember['default'].makeArray(this.get_lab_observation().get_result_value('validate.correct'));
    }),
    show_correct: ember['default'].computed('number_of_attempts', function() {
      if (this.get('is_correct')) {
        return;
      }
      return this.get('number_of_attempts') >= this.get_max_attempts();
    }),
    incorrect_text: ember['default'].computed('number_of_attempts', function() {
      return this.get_lab_observation().show_incorrect_text() + (" - attempt: [" + (this.get('number_of_attempts')) + "]");
    }),
    show_incorrect: ember['default'].computed('number_of_attempts', function() {
      var ref;
      if (this.get('is_correct')) {
        return;
      }
      return (0 < (ref = this.get('number_of_attempts')) && ref < this.get_max_attempts());
    }),
    show_input: null,
    show_correct_values: null,
    number_of_attempts: null,
    is_another_event: null,
    input_value: null,
    focusIn: function() {
      this.get_lab_observation().set_focused();
      return this.get_correct_entered_user_input().then((function(_this) {
        return function(correct_value) {
          if (!ember['default'].isPresent(correct_value)) {
            return;
          }
          _this.set('is_correct', true);
          return ember['default'].run.next(function() {
            _this.set_input_value(correct_value);
            return _this.save_input_result();
          });
        };
      })(this));
    },
    keyDown: function(e) {
      var input;
      if (ember['default'].isEqual(e.keyCode, 13)) {
        input = this.get_input_value();
        this.incrementProperty('number_of_attempts');
        if (this.correct_input(input)) {
          this.set('is_correct', true);
          return this.save_input_result(input);
        } else {
          return this.focus_self();
        }
      }
    },
    save_input_result: function(input) {
      if (input == null) {
        input = null;
      }
      this.show_save_retry_message();
      return ember['default'].run.next((function(_this) {
        return function() {
          _this.set_lab_observation_value();
          return _this.save_result().then(function() {
            if (input) {
              _this.add_user_input(input);
              _this.add_to_observation_list(input);
            }
            _this.focus_next_result();
            return _this.clear_error_message();
          }, function(error) {
            return _this.reset_event_and_show_save_error();
          });
        };
      })(this));
    },
    reset_event_and_show_save_error: function() {
      this.set('is_another_event', false);
      return this.show_save_error_message();
    },
    show_save_error_message: function() {
      return this.set_error_message("Could not save result. Please try again.");
    },
    show_save_retry_message: function() {
      if (!this.get_error_message()) {
        return;
      }
      return this.set_error_message("Retrying save.");
    },
    actions: {
      show_correct_values: function() {
        return this.set('show_correct_values', true);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});