define('client/_config/thinkspace/peer_assessment/locales/en', ['exports'], function (exports) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = {
    casespace: {
      api: {
        success: {
          thinkspace: {
            peer_assessment: {
              review: {
                save: 'Review saved successfully.'
              },
              review_set: {
                save: 'Review set saved successfully.',
                submit: 'Review set submitted successfully.'
              },
              team_set: {
                save: 'Team set saved successfully.'
              },
              assessment: {
                save: 'Evaluation saved successfully.',
                notify: 'Student notified successfully.',
                notify_all: 'All in-progress students notified.'
              }
            }
          }
        }
      }
    }
  };

  exports['default'] = ___DefaultExportObject___;;

});