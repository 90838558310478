define('client/models/thinkspace/common/configuration', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend({
    settings: ta['default'].attr('settings'),
    configurable: ta['default'].attr()
  });

  exports['default'] = ___DefaultExportObject___;;

});