define('client/components/thinkspace/casespace/gradebook/phase/overview', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    phase_manager: ember['default'].inject.service(),
    phase_states: ember['default'].computed(function() {
      var map, phase;
      phase = this.get('model');
      map = this.get('phase_manager.map');
      return map.get_ownerable_phase_states(phase);
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});