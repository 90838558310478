define('client/components/thinkspace/indented_list/builder/list/settings', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    is_setting_expert_response: false,
    c_expert_selector: ns['default'].to_p('indented_list', 'builder', 'list', 'parts', 'expert_selector'),
    c_radio: ns['default'].to_p('common', 'shared', 'radio'),
    is_expert: ember['default'].computed.reads('model.expert'),
    is_not_expert: ember['default'].computed.not('is_expert'),
    init: function() {
      this._super();
      return console.log("[list:settings] model: ", this.get('model'));
    },
    set_model_expert_value: function(value) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model;
          model = _this.get('model');
          model.set('expert', value);
          return model.save().then(function() {
            return resolve();
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    set_is_setting_expert_response: function() {
      return this.set('is_setting_expert_response', true);
    },
    reset_is_setting_expert_response: function() {
      return this.set('is_setting_expert_response', false);
    },
    actions: {
      set_is_expert: function() {
        this.totem_messages.show_loading_outlet();
        return this.set_model_expert_value(true).then((function(_this) {
          return function() {
            return _this.totem_messages.hide_loading_outlet();
          };
        })(this));
      },
      set_is_not_expert: function() {
        this.totem_messages.show_loading_outlet();
        return this.set_model_expert_value(false).then((function(_this) {
          return function() {
            return _this.totem_messages.hide_loading_outlet();
          };
        })(this));
      },
      set_is_setting_expert_response: function() {
        return this.set_is_setting_expert_response();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});