define('client/components/thinkspace/casespace/case_manager/team/snapshot', ['exports', 'ember', 'ember-data', 'totem/ns', 'totem/ajax', 'thinkspace-base/components/base'], function (exports, ember, ds, ns, ajax, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: 'li',
    is_expanded: false,
    selected_user: null,
    has_users: ember['default'].computed.gte('model.users.length', 1),
    css_style: ember['default'].computed('model.color', function() {
      var color, css;
      color = this.get('model.color');
      if (!ember['default'].isPresent(color)) {
        return '';
      }
      css = '';
      css += "background-color: #" + color + ";";
      return new ember['default'].Handlebars.SafeString(css);
    }),
    c_user_avatar: ns['default'].to_p('common', 'user', 'avatar'),
    r_teams_edit: ns['default'].to_r('case_manager', 'teams', 'edit'),
    click: function() {
      var team, user;
      user = this.get('selected_user');
      if (!ember['default'].isPresent(user)) {
        return;
      }
      team = this.get('model');
      if (team.get('is_locked')) {
        return;
      }
      team.add_user(user);
      return this.sendAction('deselect_user');
    },
    actions: {
      toggle_expanded: function() {
        return this.toggleProperty('is_expanded');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});