define('client/thinkspace/reporter/reports/show/route', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin', 'thinkspace-base/base/route'], function (exports, ember, auth_mixin, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(auth_mixin['default'], {
    model: function(params) {
      return params;
    },
    setupController: function(controller, params) {
      return controller.set('token', params.token);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});