define('client/views/date_picker/split_row', ['exports', 'ember', 'client/views/date_picker/base'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    templateName: 'date_picker/split'
  });

  exports['default'] = ___DefaultExportObject___;;

});