define('client/users/sign_up/controller', ['exports', 'ember', 'totem/scope', 'totem/config'], function (exports, ember, totem_scope, config) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Controller.extend({
    queryParams: ['token', 'email', 'invitable'],
    token: null,
    invitable: null,
    invitation_present: ember['default'].computed.notEmpty('token'),
    provided_token: ember['default'].computed.reads('token'),
    provided_invitable: ember['default'].computed.reads('invitable'),
    c_user_sign_up: ember['default'].computed(function() {
      return config['default'].simple_auth.sign_up_template || 'thinkspace/common/user/new';
    }),
    actions: {
      sign_in_transition: function(invitable, email) {
        return this.transitionToRoute('users.sign_in', {
          queryParams: {
            invitable: invitable,
            email: email,
            refer: 'signup'
          }
        });
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});