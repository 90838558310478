define('client/users/terms/controller', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Controller.extend({
    tos: null,
    c_user_terms: ns['default'].to_p('user', 'terms')
  });

  exports['default'] = ___DefaultExportObject___;;

});