define('client/components/thinkspace/readiness_assurance/admin/shared/team_users/select', ['exports', 'ember', 'thinkspace-readiness-assurance/mixins/data_rows', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, m_data_rows, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(m_data_rows['default'], {
    show_all: false,
    team_member_rows: null,
    columns_per_row: ember['default'].computed(function() {
      return (ember['default'].isPresent(this.rad.width_selector) && this.rad.width_selector) || 1;
    }),
    column_width: 300,
    init: function() {
      this._super();
      return this.validate = this.rad.validate;
    },
    willInsertElement: function() {
      return this.setup();
    },
    setup: function() {
      this.teams = this.rad.get_teams() || [];
      this.users = this.rad.get_users() || [];
      this.team_users = this.rad.get_team_users();
      this.set('show_all', this.rad.get_show_all());
      if (this.rad.select_all_users()) {
        this.send('select_all');
      }
      return this.set('team_users_rows', this.get_data_rows(this.team_users));
    },
    actions: {
      show_all: function() {
        return this.set('show_all', true);
      },
      hide_all: function() {
        return this.set('show_all', false);
      },
      select_all: function() {
        var i, j, len, len1, ref, ref1, team_users, user;
        this.teams.clear();
        this.users.clear();
        ref = this.team_users;
        for (i = 0, len = ref.length; i < len; i++) {
          team_users = ref[i];
          this.teams.pushObject(team_users.team);
          ref1 = team_users.users;
          for (j = 0, len1 = ref1.length; j < len1; j++) {
            user = ref1[j];
            this.users.pushObject(user);
          }
        }
        return this.set_users();
      },
      deselect_all: function() {
        this.teams.clear();
        this.users.clear();
        return this.set_users();
      },
      select_team: function(team) {
        var index;
        index = this.teams.indexOf(team);
        if (index >= 0) {
          this.teams.removeAt(index);
          this.remove_team_users(team);
        } else {
          this.teams.pushObject(team);
          this.add_team_users(team);
        }
        return this.set_users();
      },
      select_user: function(team, user) {
        var index;
        this.remove_team(team);
        index = this.users.indexOf(user);
        if (index >= 0) {
          this.users.removeAt(index);
        } else {
          this.users.pushObject(user);
          if (this.all_team_users_selected(team)) {
            this.add_team(team);
          }
        }
        return this.set_users();
      }
    },
    set_users: function() {
      this.rad.set_users(this.users);
      if (this.validate) {
        return this.sendAction('validate');
      }
    },
    get_users_for_team: function(team) {
      var team_users;
      team_users = this.team_users.find((function(_this) {
        return function(tu) {
          return tu.team.id === team.id;
        };
      })(this));
      if (ember['default'].isBlank(team_users)) {
        return [];
      } else {
        return team_users.users;
      }
    },
    add_team: function(team) {
      var index;
      index = this.teams.indexOf(team);
      if (!(index >= 0)) {
        return this.teams.pushObject(team);
      }
    },
    remove_team: function(team) {
      var index;
      index = this.teams.indexOf(team);
      if (index >= 0) {
        return this.teams.removeAt(index);
      }
    },
    add_team_users: function(team) {
      var i, len, ref, results, user;
      ref = this.get_users_for_team(team);
      results = [];
      for (i = 0, len = ref.length; i < len; i++) {
        user = ref[i];
        if (!this.users.contains(user)) {
          results.push(this.users.pushObject(user));
        } else {
          results.push(void 0);
        }
      }
      return results;
    },
    remove_team_users: function(team) {
      var i, index, len, ref, results, user;
      ref = this.get_users_for_team(team);
      results = [];
      for (i = 0, len = ref.length; i < len; i++) {
        user = ref[i];
        index = this.users.indexOf(user);
        if (index >= 0) {
          results.push(this.users.removeAt(index));
        } else {
          results.push(void 0);
        }
      }
      return results;
    },
    all_team_users_selected: function(team) {
      var i, len, ref, user;
      ref = this.get_users_for_team(team);
      for (i = 0, len = ref.length; i < len; i++) {
        user = ref[i];
        if (!this.users.contains(user)) {
          return false;
        }
      }
      return true;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});