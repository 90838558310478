define('client/components/thinkspace/dock/messages/messages', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    message_present: ember['default'].computed.bool('totem_messages.message_present'),
    messages: ember['default'].computed.reads('totem_messages.message_queue'),
    is_debug: ember['default'].computed.bool('totem_messages.debug_on'),
    c_message: ns['default'].to_p('dock', 'messages', 'message'),
    actions: {
      toggle_addon_visible: function() {
        if (this.get('is_addon_visible')) {
          this.send('clear_all');
        }
        this.toggleProperty('is_addon_visible');
      },
      clear_all: function() {
        return this.totem_messages.clear_all();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});