define('client/components/thinkspace/lab/observation/html', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    mouseUp: function(event) {
      var lab, sel_obj, value;
      if (this.totem_scope.get('is_view_only')) {
        return;
      }
      if (!this.get('result.is_html')) {
        return;
      }
      sel_obj = window.getSelection();
      value = sel_obj.toString();
      if (!value) {
        return;
      }
      sel_obj.removeAllRanges();
      lab = this.get('lab');
      if (!lab) {
        return;
      }
      return lab.add_to_observation_list(value);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});