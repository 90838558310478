define('client/components/thinkspace/common/shared/loader', ['exports', 'ember', 'totem/ns', 'totem/i18n', 'thinkspace-base/components/base'], function (exports, ember, ns, i18n, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    type: 'default',
    size: 'medium',
    header: '',
    message: '',
    i18n_path: '',
    text: ember['default'].computed('message', 'i18n_path', function() {
      var i18n_path, message;
      message = this.get('message');
      i18n_path = this.get('i18n_path');
      if (ember['default'].isPresent(i18n_path)) {
        return i18n['default'].message({
          path: i18n_path
        });
      }
      if (!ember['default'].isPresent(message)) {
        return 'Loading...';
      }
      return message;
    }),
    c_loader: ember['default'].computed('type', function() {
      return ns['default'].to_p('common', 'shared', 'loader', this.get('type'));
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});