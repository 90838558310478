define('client/components/thinkspace/readiness_assurance/admin/shared/teams/team', ['exports', 'ember', 'totem/ns', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    willInsertElement: function() {
      return this.set_sorted_users();
    },
    show_users: ember['default'].observer('show_all', function() {
      return this.set('collapsed', !this.get('show_all'));
    }),
    selected: ember['default'].computed('selected_teams.[]', function() {
      return this.selected_teams.contains(this.team);
    }),
    collapsed: true,
    sorted_users: null,
    actions: {
      toggle_collapsed: function() {
        this.toggleProperty('collapsed');
      },
      select: function() {
        return this.sendAction('select', this.team);
      }
    },
    set_sorted_users: function() {
      var i, id, len, name, ref, sorted_users, user;
      sorted_users = [];
      if (ember['default'].isPresent(this.users)) {
        ref = this.users;
        for (i = 0, len = ref.length; i < len; i++) {
          user = ref[i];
          id = user.id;
          name = this.get_username(user);
          sorted_users.push({
            id: id,
            name: name
          });
        }
      }
      return this.set('sorted_users', sorted_users.sortBy('name'));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});