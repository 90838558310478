define('client/components/thinkspace/common/shared/input', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].TextField.extend({
    type: 'text',
    classNames: ['ts-validated-input_input'],
    focusOut: function() {
      this.sendAction('save', this.get('value'));
      return this.sendAction('focus_out');
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});