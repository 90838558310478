define('client/templates/components/thinkspace/common/invitation/new', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("h4");
          var el2 = dom.createTextNode("Sending invitation..");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          return fragment;
        }
      };
    }());
    var child1 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("button");
          dom.setAttribute(el1,"type","submit");
          dom.setAttribute(el1,"class","btn btn-primary btn-small left");
          var el2 = dom.createTextNode("Send Invitation");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","btn btn-default btn-small right");
          var el2 = dom.createTextNode("Cancel");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, element = hooks.element;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element0 = dom.childAt(fragment, [0]);
          var element1 = dom.childAt(fragment, [1]);
          element(env, element0, context, "action", ["send"], {"on": "click"});
          element(env, element1, context, "action", ["cancel"], {"on": "click"});
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ts-forms_form-container");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","ts-grid_row");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","small-12 ts-grid_columns");
        var el4 = dom.createElement("h5");
        var el5 = dom.createTextNode("Invitation for ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","ts-grid_row");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","small-6 ts-grid_columns");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-forms_field-container");
        var el5 = dom.createElement("form");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","small-5 ts-grid_columns ts-grid_columns-thick");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-forms_field-container");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-forms_field-label");
        var el6 = dom.createTextNode("Role");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("hr");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, content = hooks.content, get = hooks.get, inline = hooks.inline, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element2 = dom.childAt(fragment, [0]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element3, [0, 0, 0]);
        var morph0 = dom.createMorphAt(dom.childAt(element2, [0, 0, 0]),1,1);
        var morph1 = dom.createMorphAt(element4,0,0);
        var morph2 = dom.createMorphAt(element4,1,1);
        var morph3 = dom.createMorphAt(dom.childAt(element3, [1, 0]),1,1);
        content(env, morph0, context, "invitable.title");
        inline(env, morph1, context, "component", [get(env, context, "c_validated_input")], {"value": get(env, context, "email"), "errors": get(env, context, "errors.email"), "type": "email", "initial_validate": true, "label": "Email", "disabled": get(env, context, "is_sending"), "class": "ts-invitation-new_input-email", "show_errors": get(env, context, "show_errors")});
        block(env, morph2, context, "if", [get(env, context, "is_sending")], {}, child0, child1);
        inline(env, morph3, context, "view", ["select"], {"content": get(env, context, "friendly_roles"), "value": get(env, context, "friendly_role")});
        return fragment;
      }
    };
  }()));

});