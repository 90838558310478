define('client/models/thinkspace/peer_assessment/review', ['exports', 'ember', 'totem/util', 'totem/ds/associations'], function (exports, ember, util, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].polymorphic('reviewable'), ta['default'].belongs_to('tbl:review_set', {
    reads: {}
  })), {
    value: ta['default'].attr(),
    reviewable_type: ta['default'].attr('string'),
    reviewable_id: ta['default'].attr('number'),
    assessment_id: ta['default'].attr('number'),
    state: ta['default'].attr('string'),
    value_is_dirty: false,
    is_approved: ember['default'].computed.equal('state', 'approved'),
    is_sent: ember['default'].computed.equal('state', 'sent'),
    is_submitted: ember['default'].computed.equal('state', 'submitted'),
    is_not_approved: ember['default'].computed.not('is_approved'),
    is_not_sent: ember['default'].computed.not('is_sent'),
    is_approvable: ember['default'].computed.and('is_not_approved', 'is_not_sent'),
    is_not_approvable: ember['default'].computed.not('is_approvable'),
    is_unapprovable: ember['default'].computed.or('is_approved', 'is_submitted'),
    is_ignoreable: ember['default'].computed.not('is_sent'),
    quantitative_path: 'value.quantitative',
    qualitative_path: 'value.qualitative',
    positive_type: 'positive',
    constructive_type: 'constructive',
    positive_comments: ember['default'].computed('value', function() {
      return this.get_positive_qualitative_comments();
    }),
    constructive_comments: ember['default'].computed('value', function() {
      return this.get_constructive_qualitative_comments();
    }),
    reset_value_is_dirty: function() {
      return this.set('value_is_dirty', false);
    },
    set_value_is_dirty: function() {
      return this.set('value_is_dirty', true);
    },
    set_quantitative_value: function(id, value) {
      return util['default'].set_path_value(this, (this.get('quantitative_path')) + "." + id + ".value", value);
    },
    set_quantitative_comment: function(id, value) {
      return util['default'].set_path_value(this, (this.get('quantitative_path')) + "." + id + ".comment.value", value);
    },
    get_quantitative_value_for_id: function(id) {
      return this.get((this.get('quantitative_path')) + "." + id + ".value");
    },
    get_quantitative_comment_for_id: function(id) {
      return this.get((this.get('quantitative_path')) + "." + id + ".comment.value");
    },
    set_qualitative_value: function(id, type, value) {
      util['default'].set_path_value(this, (this.get('qualitative_path')) + "." + id + ".value", value);
      return util['default'].set_path_value(this, (this.get('qualitative_path')) + "." + id + ".feedback_type", type);
    },
    get_qualitative_value_for_id: function(id) {
      return this.get((this.get('qualitative_path')) + "." + id + ".value");
    },
    get_expended_points: function() {
      var id, items, points;
      items = this.get(this.get('quantitative_path'));
      points = 0;
      for (id in items) {
        points += items[id].value;
      }
      return points;
    },
    get_positive_qualitative_comments: function() {
      return this.get_qualitative_comments_for_type(this.get('positive_type'));
    },
    get_constructive_qualitative_comments: function() {
      return this.get_qualitative_comments_for_type(this.get('constructive_type'));
    },
    get_qualitative_comments_for_type: function(type) {
      var comments, comments_of_type, id;
      comments = this.get(this.get('qualitative_path'));
      comments_of_type = [];
      for (id in comments) {
        if (comments[id].feedback_type === type) {
          comments_of_type.pushObject(comments[id]);
          comments[id].id = id;
        }
      }
      return comments_of_type;
    },
    get_qualitative_items: function() {
      return this.get(this.get('qualitative_path'));
    },
    get_qualitative_comment_for_id: function(id) {
      return this.get((this.get('qualitative_path')) + "." + id);
    },
    valid_qualitative_items_count: function() {
      var id, items, valid;
      items = this.get(this.get('qualitative_path'));
      if (!ember['default'].isPresent(items)) {
        return 0;
      }
      valid = 0;
      for (id in items) {
        if (ember['default'].isPresent(items[id].value)) {
          valid += 1;
        }
      }
      return valid;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});