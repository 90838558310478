define('client/components/thinkspace/weather_forecaster/response/error', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    didInsertElement: function() {
      return console.warn(this);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});