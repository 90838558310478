define('client/components/thinkspace/simulation/simulations/radiation/list-btn', ['exports', 'ember', 'thinkspace-base/components/base'], function (exports, ember, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    active: false,
    content: null,
    parent_action: null,
    parent_attr: null,
    classNames: ['sim_btn-list'],
    init: function() {
      if (this.get('is_selected')) {
        this.set('active', true);
      }
      return this._super();
    },
    is_selected: ember['default'].computed('parent_attr', 'content', function() {
      return this.get('parent_attr') === this.get('content');
    }),
    btn_style: ember['default'].computed('active', function() {
      if (this.get('active')) {
        this.send('parent_send_action');
        return 'cnc-btn-blue';
      }
    }),
    btn_obs: ember['default'].observer('is_selected', function() {
      if (this.get('active')) {
        if (!this.get('is_selected')) {
          return this.set('active', false);
        }
      }
    }),
    actions: {
      toggle_active: function() {
        if (this.get('active') === false) {
          return this.toggleProperty('active');
        }
      },
      parent_send_action: function() {
        var content;
        content = this.get('content');
        return this.sendAction('parent_action', content);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});