define('client/components/thinkspace/casespace/case_manager/assignment/wizards/casespace/steps/phases/order', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'thinkspace-casespace-case-manager/components/wizards/steps/base'], function (exports, ember, ns, ajax, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    actions: {
      move_up: function(phase) {
        return ember['default'].run.debounce(this, this.move_phase, phase, 'up', 200);
      },
      move_down: function(phase) {
        return ember['default'].run.debounce(this, this.move_phase, phase, 'down', 200);
      },
      move_top: function(phase) {
        return ember['default'].run.debounce(this, this.move_phase, phase, 'top', 200);
      },
      move_bottom: function(phase) {
        return ember['default'].run.debounce(this, this.move_phase, phase, 'bottom', 200);
      },
      save: function() {
        this.save_phase_positions();
        return this.send('exit');
      },
      cancel: function() {
        this.rollback_phases();
        return this.send('exit');
      },
      exit: function() {
        var assignment, wizard_manager;
        wizard_manager = this.get('wizard_manager');
        assignment = this.get('model');
        return wizard_manager.transition_to_assignment_edit(assignment, {
          queryParams: {
            step: 'phases'
          }
        });
      }
    },
    save_phase_positions: function() {
      var assignment, phase_order, phases, query, store, update;
      update = [];
      assignment = this.get('model');
      phases = assignment.get('phases');
      store = assignment.store;
      phases.forEach((function(_this) {
        return function(phase) {
          if (phase.get('isDirty')) {
            return update.push(phase);
          }
        };
      })(this));
      if (update.get('length') < 1) {
        return;
      }
      if (!ember['default'].isPresent(assignment)) {
        return;
      }
      phase_order = [];
      update.forEach((function(_this) {
        return function(phase) {
          return phase_order.push({
            phase_id: phase.get('id'),
            position: phase.get('position')
          });
        };
      })(this));
      query = {
        model: assignment,
        action: 'phase_order',
        verb: 'put',
        data: {
          phase_order: phase_order
        },
        id: assignment.get('id')
      };
      return ajax['default'].object(query).then((function(_this) {
        return function(payload) {
          var normalized, records, type;
          type = ns['default'].to_p('phase');
          records = payload[ns['default'].to_p('phases')];
          normalized = records.map(function(record) {
            return store.normalize(type, record);
          });
          records = store.pushMany(type, normalized);
          return records;
        };
      })(this));
    },
    move_phase: function(phase, direction) {
      switch (direction) {
        case 'up':
          return this.swap_phase_positions(phase, -1);
        case 'down':
          return this.swap_phase_positions(phase, +1);
        case 'top':
          return this.move_to_top(phase);
        case 'bottom':
          return this.move_to_bottom(phase);
      }
    },
    rollback_phases: function() {
      var phases;
      phases = this.get('model.phases');
      return phases.forEach((function(_this) {
        return function(phase) {
          if (phase.get('isDirty')) {
            return phase.rollback();
          }
        };
      })(this));
    },
    swap_phase_positions: function(phase, inc) {
      var index, other, other_pos, phase_pos, phases;
      phases = this.get('model.phases');
      index = phases.indexOf(phase);
      if (!(index >= 0)) {
        return;
      }
      index += inc;
      if (index < 0) {
        return;
      }
      other = phases.objectAt(index);
      if (!other) {
        return;
      }
      other_pos = other.get('position');
      phase_pos = phase.get('position');
      other.set('position', phase_pos);
      return phase.set('position', other_pos);
    },
    move_to_top: function(phase) {
      var phase_index, phases, running_pos;
      phases = this.get('model.phases').sortBy('position');
      phase_index = phases.indexOf(phase);
      if (phase_index == null) {
        return;
      }
      running_pos = 1;
      phase.set('position', running_pos);
      return phases.forEach((function(_this) {
        return function(other, index) {
          if (index !== phase_index) {
            return other.set('position', running_pos += 1);
          }
        };
      })(this));
    },
    move_to_bottom: function(phase) {
      var phase_index, phases, running_pos;
      phases = this.get('model.phases').sortBy('position');
      phase_index = phases.indexOf(phase);
      if (phase_index == null) {
        return;
      }
      running_pos = 0;
      phase.set('position', phases.get('length'));
      return phases.forEach((function(_this) {
        return function(other, index) {
          if (index !== phase_index) {
            return other.set('position', running_pos += 1);
          }
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});