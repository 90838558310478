define('client/users/sign_in/controller', ['exports', 'ember', 'totem/scope', 'totem/ns'], function (exports, ember, totem_scope, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Controller.extend({
    queryParams: ['invitable', 'email', 'refer'],
    invitable: null,
    email: null,
    refer: null,
    c_user_sign_in: ns['default'].to_p('common', 'user', 'sign_in')
  });

  exports['default'] = ___DefaultExportObject___;;

});