define('client/components/thinkspace/diagnostic_path/path_item/confirm_destroy', ['exports', 'ember', 'thinkspace-base/components/base'], function (exports, ember, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    actions: {
      cancel: function() {
        return this.sendAction('cancel');
      },
      ok: function() {
        return this.sendAction('ok');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});