define('client/components/thinkspace/lab/builder/lab/result/form/base', ['exports', 'ember', 'totem/ns', 'totem/util', 'thinkspace-base/components/base'], function (exports, ember, ns, util, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    admin: ember['default'].inject.service(ns['default'].to_p('lab', 'admin')),
    value_path: ember['default'].computed.reads('column.value_path'),
    is_edit: ember['default'].computed('admin.result_value_edit_component', function() {
      return this.get('admin').is_result_value_edit_component(this) || this.get('result_edit');
    }),
    show_errors: ember['default'].computed('show_form_errors', function() {
      if (!this.get('result_edit')) {
        return true;
      }
      return this.get('show_form_errors');
    }),
    init: function() {
      var paths;
      this._super();
      if (this.get('result_edit')) {
        this.init_values();
        return this.get('admin').add_result_form_component(this);
      } else {
        paths = ember['default'].makeArray(this.get('value_path'));
        if (ember['default'].isPresent(paths)) {
          paths = paths.map(function(path) {
            return 'model.' + path;
          });
          return ember['default'].defineProperty(this, 'display_value', ember['default'].computed(paths.join(','), function() {
            this.init_values();
            return this.get_display_value();
          }));
        }
      }
    },
    get_model_value_path: function() {
      var model, path;
      model = this.get('model');
      if (ember['default'].isBlank(model)) {
        return null;
      }
      path = this.get('value_path');
      if (ember['default'].isBlank(path)) {
        return null;
      }
      if (ember['default'].isArray(path)) {
        return path.map((function(_this) {
          return function(p) {
            return model.get(p);
          };
        })(this));
      } else {
        return model.get(path);
      }
    },
    set_model_value_path: function(value) {
      var model, paths, values;
      model = this.get('model');
      if (ember['default'].isBlank(model)) {
        return;
      }
      paths = ember['default'].makeArray(this.get('value_path'));
      if (ember['default'].isBlank(paths)) {
        return;
      }
      values = ember['default'].makeArray(value);
      return ember['default'].makeArray(paths).forEach((function(_this) {
        return function(path, index) {
          return _this.set_path_value(model, path, values[index] || '');
        };
      })(this));
    },
    set_path_value: function(model, path, value) {
      return util['default'].set_path_value(model, path, value);
    },
    form_valid: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return reject();
        };
      })(this));
    },
    rollback: function() {
      return this.init_values();
    },
    edit_value: 'edit_value',
    save_value: 'save_value',
    cancel_value: 'cancel_value',
    actions: {
      edit: function() {
        return this.sendAction('edit_value', this);
      },
      save: function() {
        return this.form_valid().then((function(_this) {
          return function() {
            return _this.sendAction('save_value');
          };
        })(this));
      },
      cancel: function() {
        this.rollback();
        return this.sendAction('cancel_value');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});