define('client/components/thinkspace/casespace/case_manager/assignment/wizards/casespace/steps/phases', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace-case-manager/components/wizards/steps/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    step: 'phases',
    is_adding: false,
    page_title: ember['default'].computed.reads('model.title'),
    c_phase_clone: ns['default'].to_p('case_manager', 'assignment', 'wizards', 'casespace', 'steps', 'phases', 'clone'),
    c_phase: ns['default'].to_p('case_manager', 'assignment', 'wizards', 'casespace', 'steps', 'phases', 'phase'),
    r_phase_order: ns['default'].to_r('case_manager', 'assignments', 'phase_order'),
    actions: {
      reset_is_adding: function() {
        return this.set('is_adding', false);
      },
      set_is_adding: function() {
        return this.set('is_adding', true);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});