define('client/templates/components/thinkspace/builder/steps/logistics/phase', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row collapse");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","small-12 medium-7 large-7 ts-grid_columns");
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","ts-forms_field-label ts-forms_field-label-small");
          var el4 = dom.createTextNode("Unlock Date");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","small-12 medium-4 large-4 ts-grid_columns right");
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","ts-forms_field-label ts-forms_field-label-small");
          var el4 = dom.createTextNode("Time");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","ts-forms_input-notification");
          var el2 = dom.createElement("strong");
          var el3 = dom.createTextNode("Note:");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" All times are in your local computer's time.");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row collapse");
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"class","table_action");
          var el3 = dom.createTextNode("Cancel");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline, element = hooks.element;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element1 = dom.childAt(fragment, [0]);
          var element2 = dom.childAt(fragment, [2, 0]);
          var morph0 = dom.createMorphAt(dom.childAt(element1, [0]),1,1);
          var morph1 = dom.createMorphAt(dom.childAt(element1, [1]),1,1);
          inline(env, morph0, context, "component", [get(env, context, "c_date_picker")], {"value": get(env, context, "unlock_at"), "select": "select_unlock_date", "show_icon": true});
          inline(env, morph1, context, "component", [get(env, context, "c_time_picker")], {"value": get(env, context, "unlock_at"), "select": "select_unlock_time", "show_icon": true});
          element(env, element2, context, "action", ["cancel_select_option"], {});
          return fragment;
        }
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","table_action");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, element = hooks.element, content = hooks.content, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element0 = dom.childAt(fragment, [0]);
            var morph0 = dom.createMorphAt(element0,0,0);
            var morph1 = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            element(env, element0, context, "action", ["select_option", get(env, context, "option")], {});
            content(env, morph0, context, "option");
            inline(env, morph1, context, "delimit-string", ["", get(env, context, "option"), get(env, context, "remaining_options"), " | "], {});
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("p");
          dom.setAttribute(el1,"class","table_emphasis");
          var el2 = dom.createTextNode("Unlock ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table_secondary-action_label");
          var el2 = dom.createTextNode("Or, unlock:");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table_secondary-action_options");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, content = hooks.content, get = hooks.get, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
          var morph1 = dom.createMorphAt(dom.childAt(fragment, [2]),0,0);
          content(env, morph0, context, "selected_option");
          block(env, morph1, context, "each", [get(env, context, "remaining_options")], {"keyword": "option"}, child0, null);
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","row borderless-table_item-row");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","small-12 medium-4 large-4 ts-grid_columns");
        var el3 = dom.createElement("p");
        dom.setAttribute(el3,"class","table_emphasis");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(". ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","small-12 medium-4 large-4 ts-grid_columns ts-grid_columns-thin");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","small-12 medium-4 large-4 ts-grid_columns ts-grid_columns-thin");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","row collapse");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","small-12 medium-7 large-7 ts-grid_columns");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-forms_field-label ts-forms_field-label-small");
        var el6 = dom.createTextNode("Due Date");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","small-12 medium-4 large-4 ts-grid_columns right");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-forms_field-label ts-forms_field-label-small");
        var el6 = dom.createTextNode("Time");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-forms_input-notification");
        var el4 = dom.createElement("strong");
        var el5 = dom.createTextNode("Note:");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" All times are in your local computer's time.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","row collapse");
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","table_action");
        var el5 = dom.createTextNode("Reset Due Date");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, content = hooks.content, get = hooks.get, block = hooks.block, inline = hooks.inline, element = hooks.element;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element3 = dom.childAt(fragment, [0]);
        var element4 = dom.childAt(element3, [0, 0]);
        var element5 = dom.childAt(element3, [2]);
        var element6 = dom.childAt(element5, [0]);
        var element7 = dom.childAt(element5, [2, 0]);
        var morph0 = dom.createMorphAt(element4,0,0);
        var morph1 = dom.createMorphAt(element4,2,2);
        var morph2 = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
        var morph3 = dom.createMorphAt(dom.childAt(element6, [0]),1,1);
        var morph4 = dom.createMorphAt(dom.childAt(element6, [1]),1,1);
        content(env, morph0, context, "model.position_in_assignment.content.value");
        content(env, morph1, context, "model.title");
        block(env, morph2, context, "if", [get(env, context, "date_option_is_selected")], {}, child0, child1);
        inline(env, morph3, context, "component", [get(env, context, "c_date_picker")], {"value": get(env, context, "due_at"), "select": "select_due_date", "show_icon": true});
        inline(env, morph4, context, "component", [get(env, context, "c_time_picker")], {"value": get(env, context, "due_at"), "select": "select_due_time", "show_icon": true});
        element(env, element7, context, "action", ["reset_due_at"], {});
        return fragment;
      }
    };
  }()));

});