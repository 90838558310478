define('client/thinkspace/casespace/case_manager/assignments/delete/controller', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace-case-manager/controllers/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    c_assignment_delete: ns['default'].to_p('case_manager', 'assignment', 'delete')
  });

  exports['default'] = ___DefaultExportObject___;;

});