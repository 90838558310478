define('client/components/thinkspace/builder/header/step', ['exports', 'ember', 'totem/ns', 'totem/ds/associations', 'thinkspace-base/components/base'], function (exports, ember, ns, ta, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    builder: ember['default'].inject.service(),
    tagName: '',
    is_selected: ember['default'].computed('builder.step', function() {
      var builder_step, step;
      builder_step = this.get('builder.step');
      step = this.get('model');
      return ember['default'].isEqual(builder_step, step);
    }),
    is_completed: ember['default'].computed.reads('model.is_completed'),
    actions: {
      select: function() {
        var builder, step;
        builder = this.get('builder');
        step = this.get('model');
        return builder.set_current_step_and_transition(step);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});