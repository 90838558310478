define('client/components/thinkspace/builder/steps/logistics/phase', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    tagName: 'li',
    ttz: ember['default'].inject.service(),
    c_date_picker: ns['default'].to_p('common', 'date_picker'),
    c_time_picker: ns['default'].to_p('common', 'time_picker'),
    options: ['on case release', 'on previous phase submission', 'on date', 'manually'],
    selected_option: null,
    last_selected_option: null,
    default_option: ember['default'].computed.reads('case_release_option'),
    case_release_option: 'on case release',
    previous_phase_submission_option: 'on previous phase submission',
    date_option: 'on date',
    manual_option: 'manually',
    date_option_is_selected: ember['default'].computed.equal('selected_option', 'on date'),
    unlock_at: ember['default'].computed.reads('model.unlock_at'),
    due_at: ember['default'].computed.reads('model.due_at'),
    has_previous_phase: ember['default'].computed.notEmpty('previous_phase'),
    remaining_options: ember['default'].computed('selected_option', 'has_previous_phase', function() {
      var remaining;
      remaining = ember['default'].makeArray();
      this.get('options').forEach((function(_this) {
        return function(option) {
          if (_this.get('selected_option') !== option) {
            return remaining.pushObject(option);
          }
        };
      })(this));
      if (!this.get('has_previous_phase')) {
        remaining.removeObject(this.get('previous_phase_submission_option'));
      }
      return remaining;
    }),
    set_date: function(context, property, date) {
      return context.set(property, date.obj);
    },
    set_time: function(context, property, time) {
      var date, new_date;
      date = context.get(property) || new Date();
      date = this.get('ttz').set_date_to_time(date, time);
      new_date = new Date(date.getTime());
      return context.set(property, new_date);
    },
    init: function() {
      var model;
      this._super();
      this.sendAction('register_phase', this);
      model = this.get('model');
      return model.get('previous_phase').then((function(_this) {
        return function(phase) {
          if (ember['default'].isPresent(phase)) {
            _this.set('previous_phase', phase);
          }
          return _this.initialize_select_option();
        };
      })(this));
    },
    initialize_select_option: function() {
      var model, previous_phase;
      model = this.get('model');
      previous_phase = this.get('previous_phase');
      if (model.get('unlock_at')) {
        return this.set_selected_option(this.get('date_option'));
      } else if (model.get('default_state') === 'unlocked') {
        return this.set_selected_option(this.get('case_release_option'));
      } else if (ember['default'].isPresent(previous_phase) && previous_phase.get('has_unlock_phase')) {
        return this.set_selected_option(this.get('previous_phase_submission_option'));
      } else {
        return this.set_selected_option(this.get('manual_option'));
      }
    },
    get_phase_configuration: function(phase) {
      var configuration;
      return configuration = {
        max_score: phase.get('max_score'),
        configuration_validate: phase.get('configuration_validate'),
        team_based: ember['default'].isPresent(phase.get('team_set_id')) || ember['default'].isPresent(phase.get('team_category_id')),
        auto_score: phase.get('has_auto_score'),
        complete_phase: phase.get('has_complete_phase'),
        unlock_phase: phase.get('has_unlock_phase')
      };
    },
    update_phase_configuration: function(phase, options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var configuration, key, value;
          configuration = _this.get_phase_configuration(phase);
          for (key in options) {
            value = options[key];
            if (ember['default'].isPresent(configuration[key])) {
              configuration[key] = value;
            }
          }
          return _this.save(phase, {
            configuration: configuration
          }).then(function(saved_phase) {
            return resolve(saved_phase);
          });
        };
      })(this));
    },
    save: function(phase, options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var ns_phase, obj, query, store;
          ns_phase = ns['default'].to_p('phase');
          store = _this.totem_scope.get_store();
          query = (
            obj = {
              id: phase.get('id'),
              action: '',
              verb: 'put',
              configuration: options.configuration ? options.configuration : _this.get_phase_configuration(phase)
            },
            obj["" + ns_phase] = {
              default_state: options.hasOwnProperty('default_state') ? options.default_state : phase.get('default_state'),
              unlock_at: options.hasOwnProperty('unlock_at') ? options.unlock_at : phase.get('unlock_at'),
              due_at: options.hasOwnProperty('due_at') ? options.due_at : phase.get('due_at')
            },
            obj
          );
          if (options.hasOwnProperty('due_at')) {
            query[ns_phase].due_at = options.due_at;
          }
          return _this.tc.query(ns['default'].to_p('phase'), query, {
            single: true
          }).then(function(phase) {
            return resolve(phase);
          });
        };
      })(this));
    },
    handle_select_option: function(option) {
      var model, options, phase;
      this.set_selected_option(option);
      model = this.get('model');
      phase = this.get('previous_phase');
      switch (option) {
        case this.get('case_release_option'):
          if (ember['default'].isPresent(phase)) {
            this.update_phase_configuration(phase, {
              unlock_phase: false
            });
          }
          options = {
            unlock_at: null,
            default_state: 'unlocked',
            configuration: this.get_phase_configuration(model)
          };
          return this.save(model, options);
        case this.get('previous_phase_submission_option'):
          if (ember['default'].isPresent(phase)) {
            this.update_phase_configuration(phase, {
              unlock_phase: true
            });
          }
          options = {
            unlock_at: null,
            default_state: 'locked',
            configuration: this.get_phase_configuration(model)
          };
          return this.save(model, options);
        case this.get('manual_option'):
          if (ember['default'].isPresent(phase)) {
            this.update_phase_configuration(phase, {
              unlock_phase: false
            });
          }
          options = {
            unlock_at: null,
            default_state: 'locked',
            configuration: this.get_phase_configuration(model)
          };
          return this.save(model, options);
      }
    },
    set_selected_option: function(option) {
      this.set('last_selected_option', this.get('selected_option'));
      return this.set('selected_option', option);
    },
    save_unlock_date: function() {
      var model, options, phase;
      model = this.get('model');
      phase = this.get('previous_phase');
      if (ember['default'].isPresent(phase)) {
        this.update_phase_configuration(phase, {
          unlock_phase: false
        });
      }
      options = {
        unlock_at: this.get('unlock_at'),
        default_state: 'locked',
        configuration: this.get_phase_configuration(model)
      };
      return this.save(model, options);
    },
    actions: {
      select_option: function(option) {
        return this.handle_select_option(option);
      },
      cancel_select_option: function() {
        return this.send('select_option', this.get('last_selected_option') || this.get('default_option'));
      },
      select_unlock_date: function(date) {
        var model;
        model = this.get('model');
        this.set_date(this, 'unlock_at', date);
        this.sendAction('select_unlock_at', this.get('unlock_at'));
        return this.save_unlock_date();
      },
      select_unlock_time: function(time) {
        var model;
        model = this.get('model');
        this.set_time(this, 'unlock_at', time);
        this.sendAction('select_unlock_at', this.get('unlock_at'));
        return this.save_unlock_date();
      },
      select_due_date: function(date) {
        var model;
        model = this.get('model');
        this.set_date(this, 'due_at', date);
        return this.save(model, {
          due_at: this.get('due_at')
        });
      },
      select_due_time: function(time) {
        var model;
        model = this.get('model');
        this.set_time(this, 'due_at', time);
        return this.save(model, {
          due_at: this.get('due_at')
        });
      },
      reset_due_at: function() {
        var model;
        model = this.get('model');
        return this.save(model, {
          due_at: null
        }).then((function(_this) {
          return function(phase) {
            return _this.set('due_at', phase.get('due_at'));
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});