define('client/components/thinkspace/casespace/ownerable/selector/ownerable', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    casespace: ember['default'].inject.service(),
    phase_manager: ember['default'].inject.service(),
    tagName: 'li',
    model: null,
    addon_ownerable: ember['default'].computed.reads('casespace.active_addon_ownerable'),
    current_user: ember['default'].computed.reads('totem_scope.current_user'),
    is_current_user: ember['default'].computed('model', 'current_user', function() {
      return this.get('model') === this.get('current_user');
    }),
    is_selected_addon_ownerable: ember['default'].computed('addon_ownerable', function() {
      return this.get('model') === this.get('addon_ownerable');
    }),
    click: function() {
      return this.send('select');
    },
    actions: {
      select: function() {
        return this.sendAction('select', this.get('model'));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});