define('client/components/thinkspace/readiness_assurance/admin/shared/error', ['exports', 'ember', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    valid: true,
    errors: null
  });

  exports['default'] = ___DefaultExportObject___;;

});