define('client/components/thinkspace/common/user/show/profile', ['exports', 'ember', 'totem/ns', 'totem/mixins/validations', 'thinkspace-base/components/base', 'totem/util'], function (exports, ember, ns, val_mixin, base_component, util) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend(val_mixin['default'], {
    model: null,
    c_checkbox: ns['default'].to_p('common', 'shared', 'checkbox'),
    c_validated_input: ns['default'].to_p('common', 'shared', 'validated_input'),
    c_file_upload: ns['default'].to_p('common', 'file-upload'),
    c_radio: ns['default'].to_p('common', 'shared', 'radio'),
    c_dropdown: ns['default'].to_p('common', 'dropdown'),
    p_header: 'components/' + ns['default'].to_p('user', 'show', 'header'),
    first_name: ember['default'].computed.reads('model.first_name'),
    last_name: ember['default'].computed.reads('model.last_name'),
    email: ember['default'].computed.reads('model.email'),
    email_optin: ember['default'].computed.reads('model.email_optin'),
    avatar_url: ember['default'].computed.reads('model.avatar_url'),
    instructor_role: ember['default'].computed.reads('model.profile.roles.instructor'),
    student_role: ember['default'].computed.reads('model.profile.roles.student'),
    update_password: null,
    is_instructor: ember['default'].computed.reads('instructor_role'),
    is_student: ember['default'].computed.reads('student_role'),
    r_user_profile: 'users.show.profile',
    r_user_keys: 'users.show.keys',
    upload_avatar_form_action: ember['default'].computed('model', function() {
      return "/api/thinkspace/common/users/" + (this.get('model.id')) + "/avatar";
    }),
    upload_avatar_model_path: 'thinkspace/common/user',
    upload_avatar_params: ember['default'].computed('model', function() {
      return {
        id: this.get('model.id')
      };
    }),
    upload_avatar_btn_text: 'Upload New Picture',
    upload_avatar_loading_text: 'Uploading picture..',
    upload_avatar_select_text: 'Select File',
    init: function() {
      this._super();
      return this.load_domain_data().then((function(_this) {
        return function() {
          return _this.set_data();
        };
      })(this));
    },
    load_domain_data: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.tc.find_all(ns['default'].to_p('discipline')).then(function(disciplines) {
            _this.set('all_disciplines', disciplines);
            return resolve();
          });
        };
      })(this));
    },
    set_data: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model;
          model = _this.get('model');
          return model.get(ns['default'].to_p('disciplines')).then(function(model_disciplines) {
            _this.set('discipline', model_disciplines.get('firstObject'));
            return resolve();
          });
        };
      })(this));
    },
    save: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.validate().then(function() {
            return _this.sync_save().then(function(synced_model) {
              _this.totem_messages.show_loading_outlet();
              return synced_model.save().then(function() {
                _this.totem_messages.hide_loading_outlet();
                return resolve();
              })["catch"](function() {
                _this.totem_messages.hide_loading_outlet();
                return reject();
              });
            });
          })["catch"](function() {
            _this.totem_messages.error('Please ensure that your name is present.');
            return reject();
          });
        };
      })(this));
    },
    sync_save: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var discipline, email_optin, first_name, last_name, model;
          model = _this.get('model');
          first_name = _this.get('first_name');
          last_name = _this.get('last_name');
          email_optin = _this.get('email_optin');
          discipline = _this.get('discipline');
          _this.sync_discipline();
          _this.sync_role('instructor');
          _this.sync_role('student');
          model.set('first_name', first_name);
          model.set('last_name', last_name);
          model.set('email_optin', email_optin);
          return resolve(model);
        };
      })(this));
    },
    sync_discipline: function() {
      var discipline, model, update;
      model = this.get('model');
      discipline = this.get('discipline');
      if (ember['default'].isPresent(discipline)) {
        update = {};
        update.disciplines = [];
        update.disciplines.pushObject(parseInt(discipline.get('id')));
        return model.set('updates', update);
      }
    },
    sync_role: function(role) {
      var model, value;
      value = this.get("is_" + role);
      model = this.get('model');
      if (!ember['default'].isPresent(value)) {
        value = false;
      }
      return util['default'].set_path_value(model, "profile.roles." + role, value);
    },
    actions: {
      select_discipline: function(discipline) {
        return this.set('discipline', discipline);
      },
      set_role: function(role) {
        switch (role) {
          case 'instructor':
            this.set('is_instructor', true);
            return this.set('is_student', false);
          case 'student':
            this.set('is_instructor', false);
            return this.set('is_student', true);
        }
      },
      update_password: function() {
        return this.sendAction('update_password');
      },
      update_information: function() {
        var model;
        model = this.get('model');
        return this.save().then((function(_this) {
          return function() {
            return _this.totem_messages.api_success({
              source: _this,
              model: model,
              action: 'update',
              i18n_path: ns['default'].to_o('user', 'update_success')
            });
          };
        })(this))["catch"]((function(_this) {
          return function() {
            return _this.totem_messages.api_failure({
              source: _this,
              model: model,
              action: 'update',
              i18n_path: ns['default'].to_o('user', 'update_failure')
            });
          };
        })(this));
      }
    },
    validations: {
      first_name: {
        presence: {
          message: 'Please fill out your first name.'
        }
      },
      last_name: {
        presence: {
          message: 'Please fill out your last name.'
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});