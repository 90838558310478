define('client/validators/property_less_than', ['exports', 'ember-validations/validators/base', 'ember'], function (exports, base, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    smaller_property: null,
    larger_property: null,
    init: function() {
      var larger_property, message, smaller_property;
      this._super();
      smaller_property = this.options.smaller_property;
      larger_property = this.options.larger_property;
      message = this.options.message;
      this.set('smaller_property', smaller_property);
      this.set('larger_property', larger_property);
      this.set('message', message);
      this.dependentValidationKeys.pushObject(smaller_property);
      return this.dependentValidationKeys.pushObject(larger_property);
    },
    call: function() {
      var larger_property, larger_value, message, model, smaller_property, smaller_value;
      model = this.get('model');
      smaller_property = this.get('smaller_property');
      larger_property = this.get('larger_property');
      message = this.get('message');
      smaller_value = model.get(smaller_property);
      larger_value = model.get(larger_property);
      if (smaller_value > larger_value) {
        return this.errors.pushObject(message);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});