define('client/services/team_manager', ['exports', 'ember', 'totem/ajax', 'totem/ns', 'totem/util', 'totem/ds/associations', 'totem/scope'], function (exports, ember, ajax, ns, util, ta, totem_scope) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Object.extend({
    space_loaded_map: null,
    team_set_loaded_map: null,
    current_space: null,
    current_team_set: null,
    current_unassigned_users_loaded: false,
    current_users: ember['default'].computed('current_space', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var space;
          space = _this.get('current_space');
          console.warn("Current users called with: ", space);
          if (!ember['default'].isPresent(space)) {
            return resolve([]);
          }
          return space.get(ns['default'].to_p('users')).then(function(users) {
            return resolve(users);
          });
        };
      })(this));
      return ta['default'].PromiseArray.create({
        promise: promise
      });
    }),
    current_unassigned_users: ember['default'].computed('current_team_set', function() {
      var promise;
      console.log("[team-manager] Unassigned users fired.", this.get('current_space'), this.get('current_team_set'));
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var team_set;
          team_set = _this.get('current_team_set');
          return team_set.get('unlocked_teams').then(function(teams) {
            var team_user_promises;
            team_user_promises = teams.mapBy(ns['default'].to_p('team_users'));
            return ember['default'].RSVP.all(team_user_promises).then(function(team_user_arrays) {
              var assigned_user_ids, space, team_users;
              team_users = [];
              team_user_arrays.forEach(function(team_user_array) {
                return team_user_array.forEach(function(team_user) {
                  return team_users.pushObject(team_user);
                });
              });
              assigned_user_ids = util['default'].string_array_to_numbers(team_users.mapBy('user_id'));
              space = _this.get('current_space');
              return space.get(ns['default'].to_p('users')).then(function(users) {
                var filter, user_ids;
                user_ids = util['default'].string_array_to_numbers(users.mapBy('id'));
                filter = _this.get_store().filter(ns['default'].to_p('user'), function(user) {
                  var id;
                  id = parseInt(user.get('id'));
                  return !assigned_user_ids.contains(id) && user_ids.contains(id);
                });
                team_set.set_unassigned_users_filter(filter);
                return resolve(filter);
              });
            });
          });
        };
      })(this));
      return ta['default'].PromiseArray.create({
        promise: promise
      });
    }),
    update_unassigned_users: function() {
      return this.propertyDidChange('current_unassigned_users');
    },
    get_store: function() {
      return this.container.lookup('store:main');
    },
    set_team_set: function(team_set) {
      return this.set('current_team_set', team_set);
    },
    set_team_set_and_space: function(team_set) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return team_set.get('space').then(function(space) {
            return _this.get_teams_for_team_set(team_set).then(function() {
              _this.set('current_space', space);
              _this.set('current_unassigned_users_loaded', true);
              _this.set_team_set(team_set);
              return resolve(team_set);
            });
          });
        };
      })(this));
    },
    teams_ajax: function(model, type, action, verb, options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          query = {
            model: model,
            id: model.get('id'),
            action: action,
            verb: verb
          };
          return ajax['default'].object(query).then(function(payload) {
            var records;
            records = ajax['default'].normalize_and_push_payload(type, payload, options);
            return resolve(records);
          });
        };
      })(this));
    },
    set_space_roster: function(space) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          _this.set('current_space', space);
          if (_this.is_space_loaded(space)) {
            return resolve();
          }
          return _this.teams_ajax(space, 'user', 'roster', 'get').then(function(records) {
            _this.set_space_loaded(space);
            return resolve();
          });
        };
      })(this));
    },
    get_space_from_params: function(params) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get_store().find(ns['default'].to_p('space'), params.space_id).then(function(space) {
            _this.set('current_space', space);
            return _this.set_space_roster(space).then(function() {
              return resolve(space);
            });
          });
        };
      })(this));
    },
    get_team_set_from_params: function(params) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get_store().find(ns['default'].to_p('team_set'), params.team_set_id).then(function(team_set) {
            return team_set.get('space').then(function(space) {
              _this.set('current_space', space);
              _this.set_team_set(team_set);
              return resolve(team_set);
            });
          });
        };
      })(this));
    },
    get_teams_for_team_set: function(team_set) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (_this.is_team_set_loaded(team_set)) {
            return resolve();
          }
          return _this.teams_ajax(team_set, 'team', 'teams', 'get').then(function(records) {
            _this.set_team_set_loaded(team_set);
            return resolve(records);
          });
        };
      })(this));
    },
    get_loaded_map: function(type) {
      var key, map;
      key = type + "_loaded_map";
      map = this.get(type);
      if (!ember['default'].isPresent(map)) {
        map = ember['default'].Map.create();
        this.set(type, map);
      }
      return map;
    },
    set_loaded: function(type, record) {
      var map;
      map = this.get_loaded_map(type);
      return map.set(record, {
        is_loaded: true
      });
    },
    reset_loaded: function(type, record) {
      var map;
      map = this.get_loaded_map(type);
      return map.set(record, {
        is_loaded: false
      });
    },
    is_map_loaded: function(type, record) {
      var map, value;
      map = this.get_loaded_map(type);
      if (!ember['default'].isPresent(map)) {
        return false;
      }
      value = map.get(record);
      if (!ember['default'].isPresent(value)) {
        return false;
      }
      return value.is_loaded;
    },
    get_team_set_loaded_map: function() {
      return this.get_loaded_map('team_set');
    },
    get_space_loaded_map: function() {
      return this.get_loaded_map('space');
    },
    set_team_set_loaded: function(team_set) {
      return this.set_loaded('team_set', team_set);
    },
    reset_team_set_loaded: function(team_set) {
      return this.reset_loaded('team_set', team_set);
    },
    set_space_loaded: function(space) {
      return this.set_loaded('space', space);
    },
    reset_space_loaded: function(space) {
      return this.reset_loaded('space', space);
    },
    is_space_loaded: function(space) {
      return this.is_map_loaded('space', space);
    },
    is_team_set_loaded: function(team_set) {
      return this.is_map_loaded('team_set', team_set);
    },
    get_route: function() {
      return this.container.lookup('route:application');
    },
    transition_to_team_show: function(team) {
      return this.get_route().transitionTo(ns['default'].to_r('case_manager', 'teams', 'edit'), team);
    },
    transition_to_team_set_show: function(team) {
      return team.get('team_set').then((function(_this) {
        return function(team_set) {
          return _this.get_route().transitionTo(ns['default'].to_r('case_manager', 'team_sets', 'show'), team_set.get('space'), team_set);
        };
      })(this));
    },
    team_users_for_team: function(team) {
      var store;
      store = this.get_store();
      return store.filter(ns['default'].to_p('team_user'), (function(_this) {
        return function(team_user) {
          var id, team_id;
          id = parseInt(team.get('id'));
          team_id = parseInt(team_user.get('team_id'));
          _this.propertyDidChange('current_unassigned_users');
          return id === team_id;
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});