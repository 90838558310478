define('client/thinkspace/casespace/controller', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Controller.extend({
    needs: ['application'],
    current_route: ember['default'].computed.reads('controllers.application.currentRouteName'),
    c_casespace: ns['default'].to_p('casespace')
  });

  exports['default'] = ___DefaultExportObject___;;

});