define('client/thinkspace/casespace/assignments/reports/route', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin', 'thinkspace-base/base/route'], function (exports, ember, auth_mixin, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(auth_mixin['default'], {
    casespace: ember['default'].inject.service(),
    phase_manager: ember['default'].inject.service(),
    titleToken: function(model) {
      return "Reports - " + (model.get('title'));
    },
    renderTemplate: function() {
      return this.render();
    },
    model: function(params) {
      return this.store.find(this.ns.to_p('assignment'), params.assignment_id).then((function(_this) {
        return function(assignment) {
          return _this.totem_messages.api_success({
            source: _this,
            model: assignment
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.totem_messages.api_failure(error, {
            source: _this,
            model: _this.ns.to_p('assignment')
          });
        };
      })(this));
    },
    afterModel: function(assignment, transition) {
      if (!assignment) {
        transition.abort();
      }
      return this.get('casespace').set_current_models({
        assignment: assignment
      }).then((function(_this) {
        return function() {
          return _this.get('phase_manager').set_all_phase_states();
        };
      })(this));
    },
    setupController: function(controller, assignment) {
      return controller.set('model', assignment);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});