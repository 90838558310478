define('client/thinkspace/casespace/case_manager/spaces/roster/route', ['exports', 'ember', 'thinkspace-casespace-case-manager/routes/base'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    thinkspace: ember['default'].inject.service(),
    titleToken: function(model) {
      return model.get('title') + ' Roster';
    },
    activate: function() {
      return this.get('thinkspace').disable_wizard_mode();
    },
    model: function(params) {
      return this.get_space_from_params(params);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});