define('client/adapters/application', ['exports', 'ember', 'ember-data', 'totem/config', 'totem/scope', 'totem-messages/messages'], function (exports, ember, ds, config, totem_scope, totem_messages) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ds['default'].ActiveModelAdapter.extend({
    namespace: 'api',
    host: config['default'].api_host,
    coalesceFindRequests: true,
    ajax: function(url, type, hash) {
      var timeout;
      if (hash == null) {
        hash = {};
      }
      timeout = config['default'] && config['default'].ajax_timeout;
      if (timeout) {
        hash.timeout = timeout;
      }
      return this._super(url, type, hash);
    },
    findQuery: function(store, type, query) {
      var action, id, url, verb;
      totem_scope['default'].find_query(type, query);
      action = query.action;
      id = query.id;
      verb = query.verb || 'GET';
      url = this.buildURL(type.typeKey, id);
      if (action) {
        url += '/' + action;
      }
      if (query.id) {
        delete query.id;
      }
      if (query.action) {
        delete query.action;
      }
      if (query.verb) {
        delete query.verb;
      }
      return this.ajax(url, verb, {
        data: query
      });
    },
    deleteRecord: function(store, type, record) {
      var id, query;
      query = {};
      totem_scope['default'].delete_record(type, record, query);
      id = record.get('id');
      return this.ajax(this.buildURL(type.typeKey, id), "DELETE", {
        data: query
      });
    },
    find: function(store, type, id) {
      var query;
      query = {};
      totem_scope['default'].find(type, id, query);
      return this.ajax(this.buildURL(type.typeKey, id), 'GET', {
        data: query
      });
    },
    findAll: function(store, type, sinceToken) {
      var query;
      query = {};
      if (sinceToken) {
        query.since = sinceToken;
      }
      totem_scope['default'].find_all(type, query);
      return this.ajax(this.buildURL(type.typeKey), 'GET', {
        data: query
      });
    },
    findMany: function(store, type, ids) {
      var query;
      query = {
        ids: ids
      };
      totem_scope['default'].find_many(type, query);
      return this.ajax(this.buildURL(type.typeKey, 'select'), 'GET', {
        data: query
      });
    },
    ajaxError: function(jqXHR, responseText, errorThrown) {
      var isObject;
      isObject = jqXHR !== null && typeof jqXHR === 'object';
      if (isObject) {
        jqXHR.then = null;
        if (!jqXHR.errorThrown) {
          if (typeof errorThrown === 'string') {
            jqXHR.errorThrown = new Error(errorThrown);
          } else {
            jqXHR.errorThrown = errorThrown;
          }
        }
      }
      return jqXHR;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});