define('client/components/thinkspace/casespace/case_manager/assignment/wizards/assessment/steps/logistics', ['exports', 'ember', 'totem/ns', 'totem/ds/associations', 'totem/ajax', 'totem/mixins/validations', 'totem/mixins/ckeditor', 'thinkspace-casespace-case-manager/components/wizards/steps/base'], function (exports, ember, ns, ta, ajax, val_mixin, ckeditor_mixin, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(val_mixin['default'], ckeditor_mixin['default'], {
    step: 'logistics',
    instructions: ember['default'].computed.reads('model.instructions'),
    release_at: ember['default'].computed.reads('model.release_at'),
    due_at: ember['default'].computed.reads('model.due_at'),
    page_title: ember['default'].computed.reads('model.title'),
    team_set: null,
    team_sets: ember['default'].computed(function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var assignment;
          assignment = _this.get('model');
          return assignment.get('space').then(function(space) {
            return space.get_team_sets({
              include_locked: false
            }).then(function(team_sets) {
              return resolve(team_sets);
            }, function(error) {
              return console.error("[logistics] Error in space.get_team_sets.", error);
            });
          });
        };
      })(this));
      return ta['default'].PromiseArray.create({
        promise: promise
      });
    }),
    c_team_set: ns['default'].to_p('case_manager', 'assignment', 'wizards', 'assessment', 'steps', 'logistics', 'team_set'),
    actions: {
      complete: function() {
        var wizard_manager;
        if (!this.get('isValid')) {
          return;
        }
        wizard_manager = this.get('wizard_manager');
        wizard_manager.send_action('set_instructions', this.get('instructions'));
        wizard_manager.send_action('set_release_at', this.get('release_at'));
        wizard_manager.send_action('set_due_at', this.get('due_at'));
        wizard_manager.send_action('set_team_set', this.get('team_set'));
        return wizard_manager.send_action('complete_step', 'logistics');
      },
      set_team_set: function(team_set) {
        return this.set('team_set', team_set);
      }
    },
    validations: {
      instructions: {
        presence: true
      },
      release_at: {
        presence: true
      },
      due_at: {
        presence: true
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});