define('client/components/thinkspace/common/user/password/show', ['exports', 'ember', 'totem/ns', 'totem/mixins/validations', 'thinkspace-base/components/base'], function (exports, ember, ns, val_mixin, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend(val_mixin['default'], {
    layoutName: 'thinkspace/common/user/password/show',
    c_checkbox: ns['default'].to_p('common', 'shared', 'checkbox'),
    c_validated_input: ns['default'].to_p('common', 'shared', 'validated_input'),
    c_loader: ns['default'].to_p('common', 'shared', 'loader'),
    c_pwd_meter: ns['default'].to_p('common', 'user', 'password', 'meter'),
    password: null,
    password_confirmation: null,
    password_confirmation_errors: ember['default'].computed('password', 'password_confirmation', function() {
      if (this.get('password') !== this.get('password_confirmation')) {
        return ['Passwords must match'];
      }
      return [];
    }),
    actions: {
      submit: function() {
        var model;
        if (!(this.get('isValid') && ember['default'].isEmpty(this.get('password_confirmation_errors')))) {
          return;
        }
        model = this.get('model');
        model.set('password', this.get('password'));
        return model.save().then((function(_this) {
          return function() {
            return _this.sendAction('goto_users_password_success');
          };
        })(this), (function(_this) {
          return function(error) {
            return _this.sendAction('goto_users_password_fail', error);
          };
        })(this));
      }
    },
    validations: {
      password: {
        length: {
          minimum: 8,
          messages: {
            tooShort: "Password needs to be at least 8 characters long"
          }
        },
        password_strength: {}
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});