define('client/models/thinkspace/simulation/simulation', ['exports', 'ember', 'totem/ds/associations', 'client/models/thinkspace/common/componentable'], function (exports, ember, ta, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(ta['default'].add(ta['default'].polymorphic('authable')), {
    authable_type: ta['default'].attr('string'),
    authable_id: ta['default'].attr('number'),
    path: ta['default'].attr('string')
  });

  exports['default'] = ___DefaultExportObject___;;

});