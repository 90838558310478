define('client/components/thinkspace/common/shared/validated_input', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    type: 'text',
    show_errors: true,
    has_focused: false,
    initial_validate: true,
    size: null,
    placeholder: null,
    classNameBindings: [':ts-validated-input', 'display_errors:has-errors', 'class'],
    poll: false,
    autofill_poll: (function() {
      if (!this.get('poll')) {
        return;
      }
      return ember['default'].run.later(this, ((function(_this) {
        return function() {
          if (!(ember['default'].isPresent(_this) && ember['default'].isPresent(_this.$()) && _this.get('poll'))) {
            return;
          }
          _this.$('input').trigger('change');
          return _this.autofill_poll();
        };
      })(this)), 250);
    }).on('didInsertElement'),
    display_errors: ember['default'].computed('show_errors', 'has_focused', 'initial_validate', 'errors.length', function() {
      if (!this.get('show_errors')) {
        return false;
      }
      if (!this.get('errors.length')) {
        return false;
      }
      if (!this.get('initial_validate') && !this.get('has_focused')) {
        return false;
      }
      return true;
    }),
    is_text_area: ember['default'].computed.equal('type', 'textarea'),
    c_input: ns['default'].to_p('common', 'shared', 'input'),
    c_text_area: ns['default'].to_p('common', 'shared', 'text_area'),
    actions: {
      save: function(value) {
        return this.sendAction('save', value);
      },
      focus_out: function() {
        return this.set('has_focused', true);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});