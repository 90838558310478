define('client/components/thinkspace/casespace/toolbar/crumbs', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    casespace: ember['default'].inject.service(),
    totem_data_config: {
      ability: {
        ajax_source: ns['default'].to_p('spaces')
      }
    },
    current_space: ember['default'].computed.reads('casespace.current_space'),
    current_assignment: ember['default'].computed.reads('casespace.current_assignment'),
    current_phase: ember['default'].computed.reads('casespace.current_phase'),
    is_user_profile: false,
    display_space: ember['default'].computed('current_space', 'is_user_profile', function() {
      return ember['default'].isPresent(this.get('current_space')) || this.get('is_user_profile');
    }),
    observe_can_update: ember['default'].observer('can.update', function() {
      if (this.get('can.update') === true) {
        return this.set('session.secure.switch_user', true);
      }
    }),
    no_addon: ember['default'].computed.not('casespace.active_addon'),
    switch_user: ember['default'].computed.bool('session.can_switch_user'),
    space_link_active: ember['default'].computed.bool('session.is_original_user'),
    show_switch_user: ember['default'].computed.and('switch_user', 'no_addon', 'current_space'),
    c_switch_user: ns['default'].to_p('toolbar', 'switch_user'),
    t_user_actions: ns['default'].to_t('crumbs', 'user_actions'),
    t_space: ns['default'].to_t('crumbs', 'space'),
    t_assignment: ns['default'].to_t('crumbs', 'assignment'),
    t_phase: ns['default'].to_t('crumbs', 'phase'),
    user_expansion_visible: false,
    hide_all_expansions: function() {
      return this.set('user_expansion_visible', false);
    },
    actions: {
      toggle_users: function() {
        this.toggleProperty('user_expansion_visible');
      },
      sign_out: function() {
        return this.sendAction('sign_out');
      },
      hide_expansions: function() {
        return this.hide_all_expansions();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});