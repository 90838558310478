define('client/templates/thinkspace/casespace/case_manager/shared/roster_uploader', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("p");
          var el2 = dom.createTextNode("Oops! An error occured while trying to import the roster.");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("Check that your .csv file is in the correct format or select a different file to upload.");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","https://thinkspace-prod.s3.amazonaws.com/examples/thinkspace_example_roster.csv");
          var el3 = dom.createTextNode("Click here to view an example");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          return fragment;
        }
      };
    }());
    var child1 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("p");
          var el2 = dom.createTextNode("Use the button below to import a student roster with a '.csv' format.");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("This may take up to several minutes depending on the size of the roster.");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("ul");
          dom.setAttribute(el2,"class","case-manager-roster-uploader_instruction-list");
          var el3 = dom.createElement("li");
          var el4 = dom.createTextNode("You must include a settings:send_to and settings:role for each student.");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createTextNode("A 'read' role allows students to view and complete cases.");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createTextNode("An 'update' role allows a teacher's assistant full control over content and to grade cases.");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createTextNode("An 'owner' role allows an instructor to create, edit, and grade cases.");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","https://thinkspace-prod.s3.amazonaws.com/examples/thinkspace_example_roster.csv");
          var el3 = dom.createTextNode("Click here to view an example");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","case-manager-roster-uploader_upload-text");
        var el3 = dom.createTextNode("Drop file(s)");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","btn btn-primary btn-small");
        var el2 = dom.createTextNode("Import Roster");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"style","text-align: center;");
        dom.setAttribute(el2,"class","case-manager-roster-uploader_upload-modal-body");
        var el3 = dom.createElement("div");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","btn btn-primary case-manager-roster-uploader_upload-button");
        var el4 = dom.createTextNode("Select A File");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4,"method","POST");
        dom.setAttribute(el4,"enctype","multipart/form-data");
        var el5 = dom.createElement("input");
        dom.setAttribute(el5,"type","hidden");
        dom.setAttribute(el5,"name","auth_token");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5,"type","hidden");
        dom.setAttribute(el5,"name","rosterable_id");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5,"type","hidden");
        dom.setAttribute(el5,"name","rosterable_type");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5,"type","file");
        dom.setAttribute(el5,"name","files[]");
        dom.setAttribute(el5,"class","case-manager-roster-uploader_upload-input");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","btn btn-small case-manager-roster-uploader_upload-cancel");
        var el4 = dom.createTextNode("Cancel");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, element = hooks.element, get = hooks.get, inline = hooks.inline, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(fragment, [1]);
        var element2 = dom.childAt(fragment, [2]);
        var element3 = dom.childAt(fragment, [3]);
        var element4 = dom.childAt(element3, [0]);
        var element5 = dom.childAt(element4, [0]);
        var element6 = dom.childAt(element4, [1, 1]);
        var element7 = dom.childAt(element6, [0]);
        var element8 = dom.childAt(element6, [1]);
        var element9 = dom.childAt(element6, [2]);
        var element10 = dom.childAt(element4, [2]);
        var morph0 = dom.createMorphAt(element2,0,0);
        var morph1 = dom.createMorphAt(element5,0,0);
        element(env, element0, context, "bind-attr", [], {"class": "view.is_drag_hovered:is-drag-hovered :case-manager-roster-uploader_upload-overlay :case-manager-roster-uploader_upload-drop-zone view.is_uploading:is-visible:is-hidden"});
        element(env, element1, context, "action", ["toggle_upload"], {"target": get(env, context, "view")});
        element(env, element2, context, "bind-attr", [], {"class": ":case-manager-roster-uploader_upload-progress view.is_processing:is-visible:is-hidden"});
        inline(env, morph0, context, "partial", ["thinkspace/casespace/case_manager/shared/loading_modal"], {});
        element(env, element3, context, "bind-attr", [], {"class": ":case-manager-roster-uploader_upload-modal view.show_modal:is-visible:is-hidden"});
        element(env, element5, context, "bind-attr", [], {"class": ":dialogue view.import_error:dialogue-error:dialogue-instruct"});
        block(env, morph1, context, "if", [get(env, context, "view.import_error")], {}, child0, child1);
        element(env, element6, context, "bind-attr", [], {"action": get(env, context, "view.form_action")});
        element(env, element7, context, "bind-attr", [], {"value": get(env, context, "view.auth_token")});
        element(env, element8, context, "bind-attr", [], {"value": get(env, context, "view.rosterable_id")});
        element(env, element9, context, "bind-attr", [], {"value": get(env, context, "view.rosterable_type")});
        element(env, element10, context, "action", ["toggle_upload"], {"target": get(env, context, "view")});
        return fragment;
      }
    };
  }()));

});