define('client/routes/loading', ['exports', 'ember', 'totem/config'], function (exports, ember, config) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Route.extend({
    renderTemplate: function() {
      var template;
      if (config['default'].messages) {
        template = config['default'].messages.loading_template;
      }
      if (!template) {
        template = 'totem_message_outlet/loading';
      }
      return this.render(template);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});