define('client/thinkspace/common/spaces/index/route', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin', 'thinkspace-base/base/route'], function (exports, ember, auth_mixin, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(auth_mixin['default'], {
    casespace: ember['default'].inject.service(),
    session: ember['default'].inject.service(),
    model: function() {
      return this.get_index_spaces();
    },
    setupController: function(controller, model) {
      return controller.set('model', model);
    },
    renderTemplate: function() {
      if (this.get('session.is_original_user')) {
        return this.get('casespace').set_current_models().then((function(_this) {
          return function() {
            return _this.render();
          };
        })(this));
      } else {
        return this.totem_messages.invalidate_session();
      }
    },
    refresh_spaces: function() {
      if (!ember['default'].isPresent(this.controller)) {
        return;
      }
      return this.controller.set('model', this.get_index_spaces());
    },
    get_index_spaces: function() {
      var spaces;
      spaces = this.store.all(this.ns.to_p('space'));
      spaces = spaces.filter((function(_this) {
        return function(space) {
          return !space.get('isNew');
        };
      })(this));
      return spaces.sortBy('title');
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});