define('client/models/thinkspace/lab/result', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].belongs_to('lab:category', {
    reads: {}
  }), ta['default'].has_many('lab:observations', {
    reads: {
      filter: true,
      notify: true
    }
  })), {
    title: ta['default'].attr('string'),
    position: ta['default'].attr('number'),
    values: ta['default'].attr(),
    value: ta['default'].attr(),
    metadata: ta['default'].attr(),
    observation: ember['default'].computed.reads('observations.firstObject'),
    description: ember['default'].computed.reads('values.description'),
    html: ember['default'].computed(function() {
      return this.get('values.columns.result').htmlSafe();
    }),
    is_html: ember['default'].computed.equal('values.type', 'html_result'),
    admin_type: ember['default'].computed.reads('value.type'),
    admin_is_html: ember['default'].computed.equal('value.type', 'html_result'),
    admin_is_adjusted: ember['default'].computed.equal('value.type', 'adjusted_result'),
    is_focused: false,
    set_focused: function() {
      return this.set('is_focused', true);
    },
    reset_focused: function() {
      return this.set('is_focused', false);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});