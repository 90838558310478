define('client/initializers/totem_messages', ['exports', 'ember', 'totem-messages/messages'], function (exports, ember, tm) {

  'use strict';

  var ___DefaultExportObject___, initializer;

  initializer = {
    name: 'totem-messages',
    after: ['totem'],
    initialize: function(container, app) {
      tm['default'].set_container(container);
      app.register('totem:messages', tm['default'], {
        instantiate: false
      });
      app.inject('controller', 'totem_messages', 'totem:messages');
      app.inject('route', 'totem_messages', 'totem:messages');
      return app.inject('component', 'totem_messages', 'totem:messages');
    }
  };

  ___DefaultExportObject___ = initializer;

  exports['default'] = ___DefaultExportObject___;;

});