define('client/components/thinkspace/indented_list/list/layout/shared/all_visible', ['exports', 'ember', 'thinkspace-base/components/base'], function (exports, ember, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    all_visible: true,
    actions: {
      toggle_all_visible: function() {
        return this.get('response_manager').set_all_items_visibility(this.toggleProperty('all_visible'));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});