define('client/components/thinkspace/readiness_assurance/shared/radio/ifat_button', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    button_id: ember['default'].computed.reads('choice.id'),
    button_label: ember['default'].computed.reads('choice.label'),
    buttons_disabled: ember['default'].computed.or('qm.readonly', 'qm.answers_disabled'),
    is_correct: false,
    get_answer_id: function() {
      return this.get('qm.answer_id');
    },
    get_button_id: function() {
      return this.get('button_id');
    },
    is_selected: ember['default'].computed('qm.answer_id', function() {
      var aid, bid;
      aid = this.get_answer_id();
      bid = this.get_button_id();
      return bid && bid === aid;
    }),
    has_been_selected: ember['default'].computed('qm.response_updated', function() {
      var attempt_values, bid, correct_answer, is_correct, qid, qid_is_correct;
      qid = this.qm.qid;
      attempt_values = this.get("qm.response.userdata.attempt_values." + qid);
      if (ember['default'].isBlank(attempt_values)) {
        return false;
      }
      bid = this.get_button_id();
      if (!attempt_values.contains(bid)) {
        return false;
      }
      qid_is_correct = this.get("qm.response.userdata.question_correct." + qid);
      correct_answer = this.get("qm.response.userdata.correct_answer." + qid);
      is_correct = qid_is_correct && bid === correct_answer;
      this.set('is_correct', is_correct);
      if (is_correct) {
        this.qm.set_question_disabled_on();
      }
      return true;
    }),
    actions: {
      select: function() {
        if (this.get('buttons_disabled')) {
          return;
        }
        if (this.get('has_been_selected')) {
          return;
        }
        return this.sendAction('select', this.get_button_id());
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});