define('client/components/thinkspace/peer_assessment/assessment/review/summary', ['exports', 'ember', 'thinkspace-base/components/base'], function (exports, ember, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: 'li',
    expended_points: ember['default'].computed('model', function() {
      return this.get('model').get_expended_points();
    }),
    positive_comments: ember['default'].computed('model', function() {
      return this.get('model').get_positive_qualitative_comments();
    }),
    constructive_comments: ember['default'].computed('model', function() {
      return this.get('model').get_constructive_qualitative_comments();
    }),
    assessment_quantitative_items: ember['default'].computed('assessment', function() {
      return this.get('assessment.quantitative_items');
    }),
    category_responses: ember['default'].computed('assessment_quantitative_items', function() {
      var ids, items, responses, review;
      items = this.get('assessment_quantitative_items');
      if (!ember['default'].isPresent(items)) {
        return [];
      }
      review = this.get('model');
      ids = items.mapBy('id');
      responses = [];
      ids.forEach((function(_this) {
        return function(id) {
          var label, response, value;
          value = review.get_quantitative_value_for_id(id);
          label = items.findBy('id', id).label;
          response = {};
          response['id'] = id;
          response['value'] = value;
          response['label'] = label;
          return responses.pushObject(response);
        };
      })(this));
      return responses;
    }),
    edit_team_member: 'edit_team_member',
    actions: {
      edit_team_member: function() {
        return this.get('model.reviewable').then((function(_this) {
          return function(reviewable) {
            return _this.get('manager').set_reviewable(reviewable);
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});