define('client/models/thinkspace/team/team_user', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].belongs_to('user', {
    reads: {}
  }), ta['default'].belongs_to('team', {
    reads: {}
  })), {
    user_id: ta['default'].attr('number'),
    team_id: ta['default'].attr('number'),
    didCreate: function() {
      return this.didLoad();
    },
    didLoad: function() {
      return this.get(ta['default'].to_p('team')).then((function(_this) {
        return function(team) {
          return team.get(ta['default'].to_p('team_users')).then(function(team_users) {
            if (!team_users.contains(_this)) {
              return team_users.pushObject(_this);
            }
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});