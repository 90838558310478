define('client/components/thinkspace/observation_list/observation/edit', ['exports', 'ember', 'thinkspace-base/components/base'], function (exports, ember, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    actions: {
      done: function() {
        return this.sendAction('done', this.get('model'));
      },
      cancel: function() {
        return this.sendAction('cancel');
      }
    },
    didInsertElement: function() {
      var $textarea;
      $textarea = this.$('textarea');
      $textarea.focus();
      return $textarea.val(this.get('model.value')).trigger('autosize.resize');
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});