define('client/models/thinkspace/peer_assessment/team_set', ['exports', 'ember', 'totem/util', 'totem/ds/associations', 'totem/ns'], function (exports, ember, util, ta, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].has_many('tbl:review_sets', {
    reads: {}
  }), ta['default'].belongs_to('tbl:assessment'), ta['default'].belongs_to('team', {
    reads: {}
  })), {
    ownerable_id: ta['default'].attr('number'),
    ownerable_string: ta['default'].attr('string'),
    state: ta['default'].attr('string'),
    team_id: ta['default'].attr('number'),
    is_approved: ember['default'].computed.equal('state', 'approved'),
    is_sent: ember['default'].computed.equal('state', 'sent'),
    is_not_approved: ember['default'].computed.not('is_approved'),
    is_not_sent: ember['default'].computed.not('is_sent'),
    is_approvable: ember['default'].computed.and('is_not_approved', 'is_not_sent')
  });

  exports['default'] = ___DefaultExportObject___;;

});