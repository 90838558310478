define('client/thinkspace/casespace/case_manager/phases/edit/route', ['exports', 'ember', 'thinkspace-casespace-case-manager/routes/base'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    model: function(params) {
      return this.get_phase_from_params(params);
    },
    afterModel: function(model) {
      return this.set_current_models({
        phase: model
      }).then((function(_this) {
        return function() {
          return _this.load_assignment();
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});