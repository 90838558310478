define('client/users/show/keys/controller', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Controller.extend({
    c_user_show_keys: ns['default'].to_p('user', 'show', 'keys')
  });

  exports['default'] = ___DefaultExportObject___;;

});