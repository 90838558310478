define('client/models/thinkspace/observation_list/list', ['exports', 'ember', 'totem/ds/associations', 'client/models/thinkspace/common/componentable'], function (exports, ember, ta, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(ta['default'].add(ta['default'].polymorphic('authable'), ta['default'].has_many('lists'), ta['default'].has_many('observations', {
    reads: {
      from: 'lists',
      filter: true,
      sort: 'position:asc'
    }
  }), ta['default'].has_many('observation_list:groups', {
    reads: {
      name: 'groups'
    }
  })), {
    category: ta['default'].attr(),
    authable_type: ta['default'].attr('string'),
    authable_id: ta['default'].attr('number'),
    observation_positions: ember['default'].computed.mapBy('observations', 'position'),
    max_observation_position: ember['default'].computed.max('observation_positions'),
    category_id: ember['default'].computed(function() {
      return (this.get('category.name') || '').toLowerCase();
    }),
    category_icon: ember['default'].computed(function() {
      var icon;
      switch (this.get('category_id')) {
        case 'd':
          icon = '<i class="fa fa-flask data" title="Data"></i>';
          break;
        case 'h':
          icon = '<i class="im im-book history" title="History"></i>';
          break;
        case 'm':
          icon = '<i class="fa fa-circle-o mechanism" title="Mechanism"></i>';
          break;
        default:
          icon = '<i class="fa fa-square unknown" title="Unknown"></i>';
      }
      return icon.htmlSafe();
    }),
    category_values: [
      {
        id: 'd',
        label: 'Data'
      }, {
        id: 'h',
        label: 'History'
      }
    ],
    edit_component: ta['default'].to_p('list', 'edit')
  });

  exports['default'] = ___DefaultExportObject___;;

});