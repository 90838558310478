define('client/initializers/totem', ['exports', 'ember-data', 'totem/ns', 'totem/scope', 'totem/error', 'totem/cache', 'totem/ajax', 'totem/i18n'], function (exports, ds, ns, ts, te, tc, ajax, i18n) {

  'use strict';

  var ___DefaultExportObject___, initializer;

  initializer = {
    name: 'totem',
    initialize: function(container, app) {
      ds['default'].Model.reopen({
        totem_scope: ts['default']
      });
      app.register('totem:error', new te['default'], {
        instantiate: false
      });
      app.inject('controller', 'totem_error', 'totem:error');
      app.inject('route', 'totem_error', 'totem:error');
      app.inject('component', 'totem_error', 'totem:error');
      ts['default'].set_container(container);
      app.register('totem:scope', ts['default'], {
        instantiate: false
      });
      app.inject('controller', 'totem_scope', 'totem:scope');
      app.inject('route', 'totem_scope', 'totem:scope');
      app.inject('component', 'totem_scope', 'totem:scope');
      app.register('totem:namespace', ns['default'], {
        instantiate: false
      });
      app.inject('controller', 'ns', 'totem:namespace');
      app.inject('route', 'ns', 'totem:namespace');
      app.inject('component', 'ns', 'totem:namespace');
      tc['default'].set_container(container);
      app.register('totem:cache', tc['default'], {
        instantiate: false
      });
      app.inject('controller', 'tc', 'totem:cache');
      app.inject('route', 'tc', 'totem:cache');
      app.inject('component', 'tc', 'totem:cache');
      ajax['default'].set_container(container);
      i18n['default'].set_container(container);
      return app.register('service:totem_scope', ts['default'], {
        instantiate: false
      });
    }
  };

  ___DefaultExportObject___ = initializer;

  exports['default'] = ___DefaultExportObject___;;

});