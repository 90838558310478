define('client/components/thinkspace/peer_assessment/builder/assessment/qual/preview', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base', 'thinkspace-peer-assessment/components/peer_assessment/builder/assessment/qual/item'], function (exports, ember, ns, base, qual_item) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    manager: ember['default'].inject.service(ns['default'].to_p('peer_assessment', 'builder', 'manager')),
    classNameBindings: ['is_selected:is-selected'],
    classNames: ['ts-pa_qual-item', 'ts-pa_item'],
    edit_mode: null,
    is_managing_settings: false,
    placeholder_text: 'Qualitative response here...',
    is_edit_mode_content: ember['default'].computed.equal('edit_mode', 'content'),
    is_textarea: ember['default'].computed.reads('model.is_textarea'),
    is_text: ember['default'].computed.reads('model.is_text'),
    c_qual_settings: ns['default'].to_p('peer_assessment', 'builder', 'assessment', 'qual', 'settings'),
    init: function() {
      var assessment, item, model;
      this._super();
      model = this.get('model');
      assessment = this.get('assessment');
      item = qual_item['default'].create({
        model: model,
        assessment: assessment
      });
      return this.set('model', item);
    },
    mouseEnter: function(event) {
      return this.focusIn(event);
    },
    mouseLeave: function(event) {
      return this.focusOut(event);
    },
    focusIn: function(event) {
      return this.set_is_selected();
    },
    focusOut: function(event) {
      return this.reset_is_selected();
    },
    set_is_selected: function() {
      return this.set('is_selected', true);
    },
    reset_is_selected: function() {
      return this.set('is_selected', false);
    },
    actions: {
      set_is_managing_settings: function() {
        return this.set('is_managing_settings', true);
      },
      reset_is_managing_settings: function() {
        return this.set('is_managing_settings', false);
      },
      order_up: function() {
        var model;
        model = this.get('model.model');
        return this.get('manager').reorder_qual_item(model, -1);
      },
      order_down: function() {
        var model;
        model = this.get('model.model');
        return this.get('manager').reorder_qual_item(model, 1);
      },
      duplicate: function() {
        var model;
        model = this.get('model.model');
        return this.get('manager').duplicate_qual_item(model);
      },
      "delete": function() {
        var model;
        model = this.get('model.model');
        return this.get('manager').delete_qual_item(model);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});