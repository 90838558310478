define('client/components/thinkspace/resource/manage/link', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    classNames: ['thinkspace-resource_link'],
    c_manage_link_edit: ns['default'].to_p('resource', 'manage', 'link', 'edit'),
    prompt: 'No tag',
    edit_visible: false,
    actions: {
      edit: function() {
        return this.set('edit_visible', true);
      },
      cancel: function() {
        return this.set('edit_visible', false);
      },
      destroy: function() {
        var link;
        link = this.get('model');
        link.deleteRecord();
        return link.save().then((function(_this) {
          return function() {
            return _this.totem_messages.api_success({
              source: _this,
              model: link,
              action: 'delete',
              i18n_path: ns['default'].to_o('link', 'destroy')
            });
          };
        })(this), (function(_this) {
          return function(error) {
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: link,
              action: 'delete'
            });
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});