define('client/components/thinkspace/readiness_assurance/assessment/trat/show', ['exports', 'ember', 'totem/ns', 'thinkspace-readiness-assurance/response_manager', 'thinkspace-readiness-assurance/base/ra_component'], function (exports, ember, ns, response_manager, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    willInsertElement: function() {
      console.warn(this);
      this.ra.load_messages();
      return this.totem_data.ability.refresh().then((function(_this) {
        return function() {
          var assessment, is_readonly, rm;
          is_readonly = _this.get('viewonly');
          assessment = _this.get('model');
          rm = response_manager['default'].create({
            container: _this.container
          });
          rm.init_manager({
            assessment: assessment,
            readonly: is_readonly,
            can_update_assessment: _this.can.update,
            trat: true,
            room_users_header: 'Team Members'
          });
          return _this.set('rm', rm);
        };
      })(this));
    },
    chat_ids: [],
    actions: {
      chat: function(qid) {
        return this.get('chat_ids').pushObject(qid);
      },
      chat_close: function(qid) {
        var chat_ids;
        chat_ids = this.get('chat_ids');
        if (ember['default'].isBlank(qid)) {
          return chat_ids.clear();
        } else {
          return this.set('chat_ids', chat_ids.without(qid));
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});