define('client/components/thinkspace/weather_forecaster/assessment/attempts', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    c_attempt_show: ns['default'].to_p('wf:assessment', 'attempt', 'show'),
    forecast_attempts: ember['default'].computed(function() {
      return this.get('model.forecasts_by_date');
    }),
    actions: {
      select: function(forecast) {
        return this.sendAction('select', forecast);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});