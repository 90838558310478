define('client/components/thinkspace/common/user/avatar', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'totem/util', 'thinkspace-base/components/base'], function (exports, ember, ns, ajax, util, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    model: null,
    selected_user: null,
    clickable: false,
    size: 'small',
    tagName: '',
    is_small: ember['default'].computed.equal('size', 'small'),
    is_medium: ember['default'].computed.equal('size', 'medium'),
    is_large: ember['default'].computed.equal('size', 'large'),
    is_selected: ember['default'].computed('selected_user', function() {
      return ember['default'].isEqual(this.get('model'), this.get('selected_user'));
    }),
    css_style: ember['default'].computed('model', function() {
      var color, css;
      color = this.get('model.color');
      if (!ember['default'].isPresent(color)) {
        return '';
      }
      css = '';
      css += "background-color: #" + color + ";";
      return new ember['default'].Handlebars.SafeString(css);
    }),
    actions: {
      select_user: function() {
        return this.sendAction('select_user', this.get('model'));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});