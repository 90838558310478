define('client/components/thinkspace/diagnostic_path/path_item/show', ['exports', 'ember', 'totem/ns', 'thinkspace-diagnostic-path/path_manager', 'thinkspace-base/components/base'], function (exports, ember, ns, path_manager, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: 'li',
    classNames: ['diag-path_list-item'],
    classNameBindings: ['model.is_mechanism:diag-path_list-item-mechanism'],
    attributeBindings: ['model_id'],
    model_id: ember['default'].computed(function() {
      return this.get('model.id');
    }),
    c_path_item: ns['default'].to_p('diagnostic_path', 'path_item', 'show'),
    c_path_item_edit: ns['default'].to_p('diagnostic_path', 'path_item', 'edit'),
    c_path_item_confirm_destroy: ns['default'].to_p('diagnostic_path', 'path_item', 'confirm_destroy'),
    overflown_selector: '.diag-path_list-item-value',
    check_overflow: ember['default'].observer('model.itemable.value', function() {
      return ember['default'].run.next((function(_this) {
        return function() {
          return _this.set_overflown();
        };
      })(this));
    }),
    edit_visible: false,
    confirm_destroy_message: null,
    is_expanded: false,
    is_overflown: false,
    is_collapsed: false,
    collapsed_change: ember['default'].observer('all_collapsed', function() {
      return this.set('is_collapsed', this.get('all_collapsed'));
    }),
    actions: {
      edit: function() {
        this.sendAction('parent_cancel');
        this.set('confirm_destroy_message', null);
        if (!this.get('is_view_only')) {
          return this.set('edit_visible', true);
        }
      },
      cancel: function() {
        return this.set('edit_visible', false);
      },
      toggle_collapse: function() {
        this.sendAction('toggle_collapse', this);
        this.toggleProperty('is_collapsed');
      },
      toggle_expand: function() {
        this.sendAction('toggle_expand', this);
        this.toggleProperty('is_expanded');
      },
      add_item: function() {
        this.sendAction('parent_cancel');
        return path_manager['default'].add_first_nested_path_item(this.get('model'), {
          description: "pi(" + (this.get('model.id')) + ") : my new path item"
        });
      },
      remove_item: function() {
        var children, count;
        this.sendAction('parent_cancel');
        this.set('edit_visible', false);
        children = path_manager['default'].all_path_item_children(this.get('model'));
        count = children.get('length');
        if (count > 0) {
          return this.set('confirm_destroy_message', "Do you want to delete this item AND the (" + count + ") " + ((count > 1 && 'items') || 'item') + " nested under it?");
        } else {
          return this.send('destroy_path_item');
        }
      },
      destroy_path_item: function() {
        path_manager['default'].destroy_path_item(this.get('model'));
        return this.send('destroy_cancel');
      },
      destroy_cancel: function() {
        return this.set('confirm_destroy_message', null);
      },
      parent_cancel: function() {
        this.set('confirm_destroy_message', null);
        this.set('edit_visible', false);
        return this.sendAction('parent_cancel');
      }
    },
    set_overflown: function() {
      var $value, element, selector;
      selector = this.get('overflown_selector');
      $value = this.$(selector);
      if (!ember['default'].isPresent($value)) {
        return;
      }
      element = $value[0];
      if (!element) {
        return;
      }
      return this.set('is_overflown', element.scrollWidth > element.clientWidth);
    },
    sortable_dragend: function(event) {
      var component;
      console.log("Path Item sortable dragend called.", event);
      event.preventDefault();
      event.stopPropagation();
      event.is_collapsed = this.get('is_collapsed');
      component = ember['default'].get(event, 'dropped_container.options.component');
      return component.dragend_move_diagnostic_path_items(event);
    },
    didInsertElement: function() {
      return path_manager['default'].set_path_itemable_is_used(this.get('model'), true);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});