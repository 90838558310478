define('client/components/thinkspace/casespace/case_manager/assignment/wizards/assessment/steps/settings/quantitative/free', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace-case-manager/components/wizards/steps/base', 'totem/mixins/validations', 'thinkspace-casespace-case-manager/components/wizards/assessment/mixins/descriptive_values'], function (exports, ember, ns, base, val_mixin, descriptive_values) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(val_mixin['default'], descriptive_values['default'], {
    points: ember['default'].computed.reads('options.points'),
    points_min: ember['default'].computed.reads('points.min'),
    points_max: ember['default'].computed.reads('points.max'),
    set_points_max: 'set_points_max',
    set_points_min: 'set_points_min',
    actions: {
      set_points_max: function(value) {
        return this.sendAction('set_points_max', value);
      },
      set_points_min: function(value) {
        return this.sendAction('set_points_min', value);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});