define('client/components/thinkspace/readiness_assurance/shared/radio/buttons', ['exports', 'ember', 'thinkspace-readiness-assurance/base/ra_component'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    tagName: 'div',
    classNameBindings: ['no_errors::ts-ra_error'],
    actions: {
      select: function(id) {
        return this.sendAction('select', id);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});