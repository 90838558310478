define('client/thinkspace/markup/libraries/controller', ['exports', 'ember'], function (exports, ember) {

	'use strict';

	var ___DefaultExportObject___;

	___DefaultExportObject___ = ember['default'].Controller.extend();

	exports['default'] = ___DefaultExportObject___;;

});