define('client/components/thinkspace/diagnostic_path_viewer/viewer_ownerable', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    classNames: ['ts-componentable'],
    all_collapsed: false,
    c_path_item: ns['default'].to_p('diagnostic_path_viewer', 'path_item', 'show'),
    tse_after_render: ember['default'].observer('model.ownerable_top_level_path_items.length', function() {
      return ember['default'].run.schedule('afterRender', this, (function(_this) {
        return function() {
          return _this.tse_resize();
        };
      })(this));
    }),
    actions: {
      toggle_collapse_all: function() {
        this.toggleProperty('all_collapsed');
        this.tse_resize();
      },
      toggle_collapse: function(c_path_item) {
        return this.tse_resize();
      },
      toggle_expand: function(c_path_item) {
        return this.tse_resize();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});