define('client/components/thinkspace/casespace/case_manager/assignment/wizards/assessment', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'totem/util', 'thinkspace-casespace-case-manager/components/wizards/base'], function (exports, ember, ns, ajax, util, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    step: null,
    steps: ['details', 'settings', 'logistics', 'confirmation'],
    model: null,
    assignment: ember['default'].computed.alias('model'),
    title: null,
    instructions: null,
    due_at: null,
    release_at: null,
    c_wizard_step: ember['default'].computed('step', function() {
      var step;
      step = this.get('step');
      if (!ember['default'].isPresent(step)) {
        step = this.get('default_step');
      }
      return ns['default'].to_p('case_manager', 'assignment', 'wizards', 'assessment', 'steps', step);
    }),
    team_manager: ember['default'].inject.service(),
    check_bundle_type: function(bundle_type, options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return resolve();
        };
      })(this));
    },
    check_step: function(step, options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var direction, is_editing;
          direction = options.direction;
          is_editing = _this.get('is_editing');
          switch (is_editing) {
            case true:
              if (ember['default'].isEqual(step, 'templates') && ember['default'].isEqual(direction, 'forward')) {
                step = 'phases';
              }
              if (ember['default'].isEqual(step, 'templates') && ember['default'].isEqual(direction, 'back')) {
                step = 'details';
              }
          }
          return resolve(step);
        };
      })(this));
    },
    complete_details: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return resolve();
        };
      })(this));
    },
    complete_settings: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return resolve();
        };
      })(this));
    },
    complete_logistics: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var assignment, due_at, instructions, release_at, team_set;
          assignment = _this.get('model');
          instructions = _this.get('instructions');
          release_at = _this.get('release_at');
          due_at = _this.get('due_at');
          team_set = _this.get('team_set');
          assignment.set('instructions', instructions);
          assignment.set('release_at', release_at);
          assignment.set('due_at', due_at);
          util['default'].set_path_value(assignment, 'included_options.team_set_id', team_set.get('id'));
          return resolve();
        };
      })(this));
    },
    complete_confirmation: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var assessment, assignment, space_id, team_set;
          assignment = _this.get('model');
          assessment = _this.get('assessment');
          space_id = _this.get('space_id');
          team_set = _this.get('team_set');
          assignment.set('bundle_type', 'assessment');
          assignment.set('builder_version', 1);
          assignment.add_included_models(assessment);
          return assignment.store.find(ns['default'].to_p('space'), space_id).then(function(space) {
            assignment.set('space', space);
            return assignment.save().then(function(assignment) {
              _this.get('team_manager').reset_team_set_loaded(team_set);
              _this.get('wizard_manager').transition_to_assignment_show(assignment);
              return resolve();
            }, function(error) {
              return reject(error);
            });
          });
        };
      })(this));
    },
    actions: {
      set_title: function(title) {
        return this.set('title', title);
      },
      set_step: function(step) {
        return this.set('step', step);
      },
      set_assessment: function(assessment) {
        return this.set('assessment', assessment);
      },
      set_instructions: function(instructions) {
        return this.set('instructions', instructions);
      },
      set_due_at: function(due_at) {
        return this.set('due_at', due_at);
      },
      set_release_at: function(release_at) {
        return this.set('release_at', release_at);
      },
      set_team_set: function(team_set) {
        return this.set('team_set', team_set);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});