define('client/components/thinkspace/readiness_assurance/response/trat/question', ['exports', 'ember', 'totem/mixins/validations', 'thinkspace-readiness-assurance/base/ra_component'], function (exports, ember, val_mixin, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(val_mixin['default'], {
    tagName: 'li',
    classNames: ['ts-ra_question'],
    actions: {
      select_answer: function(id) {
        return this.qm.save_answer(id);
      },
      save_justification: function(value) {
        return this.qm.save_justification(value).then((function(_this) {
          return function() {
            return _this.qm.unlock();
          };
        })(this));
      },
      cancel_justification: function() {
        this.qm.reset_values();
        return this.qm.unlock();
      },
      focus_justification: function() {
        return this.qm.lock();
      },
      toggle_chat: function() {
        if (this.toggleProperty('qm.chat_displayed')) {
          return this.sendAction('chat', this.qm.qid);
        } else {
          return this.sendAction('chat_close', this.qm.qid);
        }
      }
    },
    answer_id: ember['default'].computed.reads('qm.answer_id'),
    validations: {
      answer_id: {
        presence: {
          message: 'You must select one of the above choices'
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});