define('client/components/thinkspace/observation_list/builder/list/settings', ['exports', 'ember', 'totem/ns', 'totem/ds/associations', 'thinkspace-base/components/base'], function (exports, ember, ns, ta, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    builder: ember['default'].inject.service(),
    model: null,
    groups: null,
    assignable_groups: null,
    is_creating_group: false,
    group_title: null,
    has_groups: ember['default'].computed.notEmpty('model.groups'),
    has_assignable_groups: ember['default'].computed.notEmpty('assignable_groups'),
    valid_assignable_groups: ember['default'].computed('model.groups.length', 'assignable_groups.length', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model;
          model = _this.get('model');
          return model.get('groups').then(function(groups) {
            var assignable_groups, filtered_groups;
            assignable_groups = _this.get('assignable_groups');
            assignable_groups = ember['default'].makeArray(assignable_groups);
            filtered_groups = assignable_groups.filter(function(group) {
              return !groups.contains(group);
            });
            return resolve(filtered_groups);
          });
        };
      })(this));
      return ta['default'].PromiseArray.create({
        promise: promise
      });
    }),
    c_loader: ns['default'].to_p('common', 'loader'),
    c_dropdown: ns['default'].to_p('common', 'dropdown'),
    init: function() {
      this._super();
      return this.get_groups().then((function(_this) {
        return function() {
          return _this.get_assignable_groups().then(function() {
            return _this.set_all_data_loaded();
          });
        };
      })(this));
    },
    get_groups: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model, query;
          model = _this.get('model');
          query = {
            id: model.get('id'),
            action: 'groups',
            verb: 'GET'
          };
          return _this.tc.query(ns['default'].to_p('list'), query, {
            payload_type: ns['default'].to_p('observation_list:group')
          }).then(function(groups) {
            _this.set('groups', groups);
            return resolve();
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    get_assignable_groups: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model, query;
          model = _this.get('model');
          query = {
            id: model.get('id'),
            action: 'assignable_groups',
            verb: 'GET'
          };
          return _this.tc.query(ns['default'].to_p('list'), query, {
            payload_type: ns['default'].to_p('observation_list:group')
          }).then(function(groups) {
            _this.set('assignable_groups', groups);
            return resolve();
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    get_store: function() {
      return this.container.lookup('store:main');
    },
    group_assignment: function(group, action) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model, query;
          model = _this.get('model');
          query = {
            group_id: group.get('id'),
            id: model.get('id'),
            verb: 'PUT',
            action: action
          };
          return _this.tc.query(ns['default'].to_p('list'), query, {
            single: true
          }).then(function(list) {
            return resolve();
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    reset_is_creating_group: function() {
      return this.set('is_creating_group', false);
    },
    actions: {
      toggle_is_creating_group: function() {
        return this.toggleProperty('is_creating_group');
      },
      cancel_is_creating_group: function() {
        return this.reset_is_creating_group();
      },
      create_group: function() {
        var builder, store, title;
        this.totem_messages.show_loading_outlet();
        builder = this.get('builder');
        title = this.get('group_title');
        store = this.get_store();
        return builder.get_assignment().then((function(_this) {
          return function(assignment) {
            var group, type_path;
            type_path = _this.totem_scope.standard_record_path(assignment);
            group = store.createRecord(ns['default'].to_p('observation_list:group'), {
              groupable_type: type_path,
              groupable_id: assignment.get('id'),
              title: title
            });
            return group.save().then(function(group) {
              var assignable_groups;
              assignable_groups = _this.get('assignable_groups');
              assignable_groups.pushObject(group);
              _this.set('group_title', null);
              _this.reset_is_creating_group();
              return _this.totem_messages.hide_loading_outlet();
            });
          };
        })(this));
      },
      assign_group: function(group) {
        this.totem_messages.show_loading_outlet();
        return this.group_assignment(group, 'assign_group').then((function(_this) {
          return function() {
            return _this.totem_messages.hide_loading_outlet();
          };
        })(this));
      },
      unassign_group: function(group) {
        this.totem_messages.show_loading_outlet();
        return this.group_assignment(group, 'unassign_group').then((function(_this) {
          return function() {
            return _this.totem_messages.hide_loading_outlet();
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});