define('client/components/thinkspace/common/user/password/meter', ['exports', 'ember', 'thinkspace-base/components/base'], function (exports, ember, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    password: null,
    password_score: 0,
    password_score_text: null,
    password_strength: {
      0: 'Worst',
      1: 'Bad',
      2: 'Weak',
      3: 'Good',
      4: 'Strong'
    },
    password_strength_error_message: 'Must use a good or strong password.',
    password_observer: ember['default'].observer('password', function() {
      return this.set_password_meter();
    }),
    didInsertElement: function() {
      return this.set_password_meter();
    },
    set_password_meter: function() {
      var password, score;
      password = this.get('password');
      if (ember['default'].isBlank(password)) {
        this.set('password_score', 0);
        this.set('password_score_text', null);
        return;
      }
      score = window.zxcvbn(password).score;
      this.set('password_score', score);
      return this.set('password_score_text', this.get('password_strength')[score] || '');
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});