define('client/components/thinkspace/common/space/index', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    c_space: ns['default'].to_p('space', 'space'),
    r_spaces_new: ns['default'].to_r('case_manager', 'spaces', 'new'),
    totem_data_config: {
      ability: {
        ajax_source: ns['default'].to_p('spaces')
      },
      metadata: true
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});