define('client/components/thinkspace/peer_assessment/builder/assessment/settings', ['exports', 'ember', 'totem/ns', 'totem/mixins/validations', 'thinkspace-base/components/base'], function (exports, ember, ns, val_mixin, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(val_mixin['default'], {
    manager: ember['default'].inject.service(ns['default'].to_p('peer_assessment', 'builder', 'manager')),
    c_checkbox: ns['default'].to_p('common', 'shared', 'checkbox'),
    c_radio: ns['default'].to_p('common', 'shared', 'radio'),
    c_validated_input: ns['default'].to_p('common', 'shared', 'validated_input'),
    type: ember['default'].computed.reads('model.assessment_type'),
    is_balance: ember['default'].computed.equal('type', 'balance'),
    is_categories: ember['default'].computed.equal('type', 'categories'),
    is_read_only: ember['default'].computed.reads('model.is_read_only'),
    points_per_member: ember['default'].computed.reads('model.points_per_member'),
    points_different: ember['default'].computed.reads('model.points_different'),
    actions: {
      toggle_points_different: function() {
        return this.toggleProperty('points_different');
      },
      set_is_categories: function() {
        if (this.get('is_read_only')) {
          return;
        }
        return this.set('type', 'categories');
      },
      set_is_balance: function() {
        if (this.get('is_read_only')) {
          return;
        }
        return this.set('type', 'balance');
      },
      save: function() {
        return this.validate().then((function(_this) {
          return function(valid) {
            var model, points_different, points_per_member, type;
            if (!valid) {
              return;
            }
            model = _this.get('model');
            type = _this.get('type');
            switch (type) {
              case 'categories':
                model.set_is_categories();
                model.reset_points_per_member();
                break;
              case 'balance':
                points_different = _this.get('points_different');
                points_per_member = _this.get('points_per_member');
                model.set_is_balance();
                model.set_points_per_member(points_per_member);
                model.set_points_different(points_different);
            }
            _this.totem_messages.show_loading_outlet();
            return model.save().then(function() {
              _this.totem_messages.hide_loading_outlet();
              return _this.sendAction('cancel');
            });
          };
        })(this));
      },
      cancel: function() {
        return this.sendAction('cancel');
      },
      activate: function() {
        var model;
        model = this.get('model');
        return this.validate().then((function(_this) {
          return function(valid) {
            var confirm;
            if (valid) {
              confirm = window.confirm('Are you sure you want to activate this evaluation?  You will not be able to make changes to the evaluation or teams once this is done.');
              if (!confirm) {
                return;
              }
              _this.totem_messages.show_loading_outlet();
              return _this.tc.query(ns['default'].to_p('tbl:assessment'), {
                id: model.get('id'),
                action: 'activate',
                verb: 'PUT'
              }, {
                single: true
              }).then(function(assessment) {
                _this.totem_messages.hide_loading_outlet();
                return _this.sendAction('cancel');
              });
            } else {
              return _this.totem_messages.error('This evaluation is not valid, please ensure it is and try again.');
            }
          };
        })(this));
      }
    },
    validations: {
      points_per_member: {
        numericality: {
          greaterThan: 0,
          'if': 'is_balance'
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});