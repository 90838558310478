define('client/models/thinkspace/peer_assessment/overview', ['exports', 'ember', 'totem/ds/associations', 'client/models/thinkspace/common/componentable'], function (exports, ember, ta, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(ta['default'].add(ta['default'].polymorphic('authable'), ta['default'].belongs_to('tbl:assessment', {
    reads: {
      name: 'tbl:assessment'
    }
  })), {
    authable_type: ta['default'].attr('string'),
    authable_id: ta['default'].attr('number'),
    assessment_id: ta['default'].attr('number')
  });

  exports['default'] = ___DefaultExportObject___;;

});