define('client/thinkspace/common/spaces/index/controller', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Controller.extend({
    c_space_index: ns['default'].to_p('space', 'index')
  });

  exports['default'] = ___DefaultExportObject___;;

});