define('client/services/ttz', ['exports', 'ember', 'client/services/ttz/zones/us'], function (exports, ember, us_tz) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Service.extend({
    zone_files: [us_tz['default']],
    current_time_zone: '',
    zones: [],
    init: function() {
      this._super();
      return this.map_zones();
    },
    map_zones: function() {
      var zones;
      zones = [];
      this.get('zone_files').forEach((function(_this) {
        return function(file) {
          return zones.pushObject(file.get('zones'));
        };
      })(this));
      zones = [].concat.apply([], zones);
      return this.set('zones', zones);
    },
    format: function(time, options) {
      var cast_zone, zone;
      if (!this.is_present(time)) {
        return this.error("Cannot format a time without passing in a time.");
      }
      if (this.is_present(options)) {
        zone = this.get_zone(options);
      }
      if (this.is_present(options)) {
        cast_zone = this.get_cast_zone(options);
      }
      if (this.is_present(cast_zone)) {
        time = this.cast_time(time, cast_zone);
      }
      if (this.is_present(zone)) {
        time = moment.tz(time, zone);
      } else {
        time = moment(time);
      }
      if (this.is_present(options.format)) {
        time = time.format(options.format);
      }
      return time;
    },
    get_zone: function(options) {
      if (this.is_present(this.get('current_time_zone'))) {
        return this.get('current_time_zone');
      }
      if (this.is_present(options.zone)) {
        return options.zone;
      }
      if (this.is_present(options.friendly)) {
        return this.iana_from_friendly(options.friendly);
      }
      if (this.is_present(options.alt)) {
        return this.iana_from_alt(options.alt);
      }
      return null;
    },
    get_cast_zone: function(options) {
      if (this.is_present(options.cast_zone)) {
        return options.cast_zone;
      }
      if (this.is_present(options.cast_zone_friendly)) {
        return this.iana_from_friendly(options.cast_zone_friendly);
      }
      if (this.is_present(options.cast_zone_alt)) {
        return this.iana_from_alt(options.cast_zone_alt);
      }
      return null;
    },
    cast_time: function(time, zone) {
      var dup_time, moment_time, offset;
      moment_time = moment(time);
      dup_time = moment_time.clone();
      dup_time = moment.tz(dup_time, zone);
      offset = moment_time.utcOffset() - dup_time.utcOffset();
      dup_time.add(offset, 'minutes');
      return dup_time;
    },
    get_client_zone_iana: function() {
      var has_intl, has_moment;
      has_intl = ember['default'].isPresent(Intl.DateTimeFormat().resolved);
      if (has_intl) {
        return Intl.DateTimeFormat().resolved.timeZone;
      }
      has_moment = ember['default'].isPresent(moment) && ember['default'].isPresent(moment.tz);
      if (has_moment) {
        return moment.tz.guess();
      }
      return 'America/Chicago';
    },
    get_client_zone_alt: function() {
      return this.alt_from_iana(this.get_client_zone_iana());
    },
    abbr_from_iana: function(iana) {
      return moment.tz(iana).zoneAbbr();
    },
    abbr_from_friendly: function(friendly) {
      var iana;
      iana = this.iana_from_friendly(friendly);
      return this.abbr_from_iana(iana);
    },
    iana_from_friendly: function(friendly) {
      var zone;
      zone = this.find_by_zone_property('friendly', friendly);
      if (ember['default'].isPresent(zone)) {
        return zone.iana;
      } else {
        return null;
      }
    },
    iana_from_alt: function(alt) {
      var results;
      alt = alt.toLowerCase();
      results = this.get('zones').filter((function(_this) {
        return function(zone) {
          return zone.alt.contains(alt);
        };
      })(this));
      if (results.get('length') > 1) {
        console.warn("Returning first of a set of time zones for alternate filter.");
      }
      if (ember['default'].isEmpty(results)) {
        console.error("No time zone found for given alternate [" + alt + "].");
        return null;
      }
      return results.get('firstObject').iana;
    },
    alt_from_iana: function(iana) {
      var results;
      results = this.get('zones').filterBy('iana', iana);
      if (results.get('length') > 1) {
        console.warn("Returning first of a set of time zones for alternate filter.");
      }
      if (ember['default'].isEmpty(results)) {
        console.error("No alt found from given iana [" + iana + "]");
        return null;
      }
      return results.get('firstObject.alt.firstObject');
    },
    find_by_zone_property: function(property, value) {
      return this.get('zones').findBy(property, value);
    },
    set_current_time_zone: function(zone) {
      return this.set('current_time_zone', zone);
    },
    set_date_to_time: function(date, time) {
      var hour, mins;
      hour = time.hour || 0;
      mins = time.mins || 0;
      date.setHours(hour);
      date.setMinutes(mins);
      date.setSeconds(0);
      date.setMilliseconds(0);
      return date;
    },
    is_present: function(value) {
      return ember['default'].isPresent(value);
    },
    error: function(message) {
      return console.error("[ttz] " + message);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});