define('client/components/thinkspace/builder/steps/logistics/phases', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    tagName: 'ul',
    c_logistics_phase: ns['default'].to_p('builder', 'steps', 'logistics', 'phase'),
    c_loader: ns['default'].to_p('common', 'loader'),
    init: function() {
      this._super();
      return this.set_all_data_loaded();
    },
    bulk_reset_date: function(property) {
      this.reset_all_data_loaded();
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var ids, model, query;
          model = _this.get('model');
          ids = model.getEach('id');
          query = {
            action: 'bulk_reset_date',
            verb: 'POST',
            ids: ids,
            property: property
          };
          return _this.tc.query(ns['default'].to_p('phase'), query).then(function(phases) {
            _this.set_all_data_loaded();
            return resolve(phases);
          });
        };
      })(this));
    },
    actions: {
      select_unlock_at: function(date) {
        return this.sendAction('select_unlock_at', date);
      },
      register_phase: function(component) {
        return this.sendAction('register_phase', component);
      },
      reset_unlock_at: function() {
        return this.bulk_reset_date('unlock_at');
      },
      reset_due_at: function() {
        return this.bulk_reset_date('due_at');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});