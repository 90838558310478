define('client/components/thinkspace/resource/dock', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace/components/dock_base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    casespace: ember['default'].inject.service(),
    c_pane: ns['default'].to_p('resource', 'pane'),
    resource_model: ember['default'].computed.reads('casespace.current_model'),
    can_manage_resources: ember['default'].computed.bool('resource_model.can.manage_resources'),
    show_resources: ember['default'].computed.or('resource_model.has_resources', 'can_manage_resources'),
    actions: {
      close: function() {
        return this.addon_visible_off();
      },
      exit: function() {
        return this.addon_visible_off();
      }
    },
    set_active_addon: function() {}
  });

  exports['default'] = ___DefaultExportObject___;;

});