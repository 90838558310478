define('client/components/thinkspace/casespace/gradebook/phase/score', ['exports', 'ember', 'totem/ns', 'totem/mixins/validations', 'thinkspace-base/components/base'], function (exports, ember, ns, val_mixin, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend(val_mixin['default'], {
    layoutName: ns['default'].to_t('gradebook', 'phase/score'),
    input_score: null,
    score_from: null,
    score_to: null,
    phase_score_validation: ember['default'].computed.reads('current_phase.configuration.settings.phase_score_validation'),
    actions: {
      save: function() {
        if (!this.get('is_valid')) {
          return;
        }
        this.sendAction('save', this.get('input_score'));
        return this.set('input_score', null);
      }
    },
    set_focus: function() {
      return this.$('input').focus();
    },
    didInsertElement: function() {
      this.set_score_validations();
      this.set_focus();
      return this.sendAction('view', this);
    },
    default_score_validation: {
      numericality: {
        only_integer: false,
        greater_than_or_equal_to: 1,
        less_than_or_equal_to: 10,
        allow_blank: true,
        decimals: 0
      }
    },
    set_score_validations: function() {
      var from, phase_rules, ref, ref1, rules, to;
      phase_rules = this.get('phase_score_validation') || this.get('default_score_validation');
      rules = JSON.parse(JSON.stringify(phase_rules));
      rules = this.camelize_validation_keys(rules);
      ref = [null, null], from = ref[0], to = ref[1];
      if (rules.numericality) {
        rules.inline = this.numericality_decimals_validator(rules.numericality.decimals);
        ref1 = this.get_score_from_and_to(rules.numericality), from = ref1[0], to = ref1[1];
      }
      this.set('score_from', from);
      this.set('score_to', to);
      return this.set_validation_rules({
        input_score: rules
      });
    },
    numericality_decimals_validator: function(number_of_decimals) {
      if (number_of_decimals == null) {
        number_of_decimals = 0;
      }
      return this.inline_validator()(function() {
        var decimals, digits, ref, scale, v, value;
        value = this.get('input_score');
        if (!value) {
          return null;
        }
        ref = ('' + value).split('.'), v = ref[0], digits = ref[1];
        decimals = (digits && digits.length) || 0;
        scale = number_of_decimals;
        if (decimals > scale) {
          if (scale <= 0) {
            return "decimals are not allowed";
          } else {
            return "decimals must be less than " + scale;
          }
        } else {
          return null;
        }
      });
    },
    get_score_from_and_to: function(options) {
      var from, to;
      if (options == null) {
        options = {};
      }
      switch (false) {
        case options.greaterThan == null:
          from = options.greaterThan + 1;
          break;
        case options.greaterThanOrEqualTo == null:
          from = options.greaterThanOrEqualTo;
          break;
        default:
          from = null;
      }
      switch (false) {
        case options.lessThan == null:
          to = options.lessThan - 1;
          break;
        case options.lessThanOrEqualTo == null:
          to = options.lessThanOrEqualTo;
          break;
        default:
          to = null;
      }
      return [from, to];
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});