define('client/thinkspace/common/spaces/controller', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Controller.extend({
    needs: ['application'],
    all_spaces: null,
    current_route: ember['default'].computed.reads('controllers.application.currentRouteName'),
    thinkspace: ember['default'].inject.service(),
    c_spaces: ns['default'].to_p('spaces')
  });

  exports['default'] = ___DefaultExportObject___;;

});