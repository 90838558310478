define('client/templates/thinkspace/casespace/case_manager/phases/order', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("tr");
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","text-center case_manager_phase-order-move");
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"title","Move up");
          dom.setAttribute(el3,"class","tsi tsi-up-arrow icon-small");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","text-center case_manager_phase-order-move");
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"title","Move down");
          dom.setAttribute(el3,"class","tsi tsi-down-arrow icon-small");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","text-center case_manager_phase-order-move");
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"title","Move to top");
          dom.setAttribute(el3,"class","tsi tsi-up-top icon-small");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"class","text-center case_manager_phase-order-move");
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"title","Move to bottom");
          dom.setAttribute(el3,"class","tsi tsi-down-bottom icon-small");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, element = hooks.element, content = hooks.content;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element0 = dom.childAt(fragment, [0]);
          var element1 = dom.childAt(element0, [0]);
          var element2 = dom.childAt(element0, [1]);
          var element3 = dom.childAt(element0, [2]);
          var element4 = dom.childAt(element0, [3]);
          var morph0 = dom.createMorphAt(dom.childAt(element0, [4]),0,0);
          element(env, element1, context, "action", ["move_up", get(env, context, "phase")], {});
          element(env, element2, context, "action", ["move_down", get(env, context, "phase")], {});
          element(env, element3, context, "action", ["move_top", get(env, context, "phase")], {});
          element(env, element4, context, "action", ["move_bottom", get(env, context, "phase")], {});
          content(env, morph0, context, "phase.title");
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","title-region");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","ts-grid_row");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","small-12 ts-grid_columns");
        var el4 = dom.createElement("h3");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","action");
        var el6 = dom.createTextNode("Case Manager - Order Phases");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","case_manager_select");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","ts-grid_row");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","small-12 ts-grid_columns");
        var el4 = dom.createElement("table");
        dom.setAttribute(el4,"class","case_manager_phase-order");
        var el5 = dom.createElement("thead");
        var el6 = dom.createElement("tr");
        var el7 = dom.createElement("th");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        var el8 = dom.createTextNode("Phase");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("tbody");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-grid_row");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","small-3 ts-grid_columns");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","btn btn-primary btn-small");
        var el7 = dom.createTextNode("Save Order");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","small-2 ts-grid_columns");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","btn btn-primary btn-small");
        var el7 = dom.createTextNode("Reset");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","small-2 ts-grid_columns");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","btn btn-primary btn-small");
        var el7 = dom.createTextNode("Cancel");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","small-5 ts-grid_columns");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, block = hooks.block, element = hooks.element;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element5 = dom.childAt(fragment, [1, 0, 0]);
        var element6 = dom.childAt(element5, [1]);
        var element7 = dom.childAt(element6, [0, 0]);
        var element8 = dom.childAt(element6, [1, 0]);
        var element9 = dom.childAt(element6, [2, 0]);
        var morph0 = dom.createMorphAt(dom.childAt(element5, [0, 1]),0,0);
        block(env, morph0, context, "each", [get(env, context, "controller.sorted_phases")], {"keyword": "phase"}, child0, null);
        element(env, element7, context, "action", ["save"], {"on": "click"});
        element(env, element8, context, "action", ["reset"], {"on": "click"});
        element(env, element9, context, "action", ["cancel"], {"on": "click"});
        return fragment;
      }
    };
  }()));

});