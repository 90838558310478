define('client/models/thinkspace/diagnostic_path_viewer/viewer', ['exports', 'ember', 'totem/ds/associations', 'client/models/thinkspace/common/componentable'], function (exports, ember, ta, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(ta['default'].add(ta['default'].polymorphic('ownerable'), ta['default'].belongs_to('path')), {
    user_id: ta['default'].attr('number'),
    path_id: ta['default'].attr('number'),
    ownerable_id: ta['default'].attr('number'),
    ownerable_type: ta['default'].attr('string'),
    tvo: ember['default'].inject.service(),
    top_level_path_items: ember['default'].computed(function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get('tvo.helper').load_ownerable_view_records(_this).then(function() {
            return _this.get('ownerable').then(function(ownerable) {
              return resolve(_this.filtered_path_items(ownerable).sortBy('position'));
            });
          });
        };
      })(this));
      return ta['default'].PromiseArray.create({
        promise: promise
      });
    }),
    viewer_path: ember['default'].computed('path_id', function() {
      return ta['default'].PromiseObject.create({
        promise: this.get('top_level_path_items').then((function(_this) {
          return function() {
            return _this.store.find(ta['default'].to_p('path'), _this.get('path_id')).then(function(path) {
              return {
                title: path.get('title')
              };
            });
          };
        })(this))
      });
    }),
    ownerable_top_level_path_items: ember['default'].computed(function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get('tvo.helper').load_ownerable_view_records(_this, {
            sub_action: 'ownerable'
          }).then(function() {
            var ownerable;
            ownerable = _this.totem_scope.get_ownerable_record();
            return resolve(_this.filtered_path_items(ownerable).sortBy('position'));
          });
        };
      })(this));
      return ta['default'].PromiseArray.create({
        promise: promise
      });
    }),
    filtered_path_items: function(ownerable) {
      var path_id;
      path_id = this.get('path_id');
      return this.store.all(ta['default'].to_p('path_item')).filter((function(_this) {
        return function(item) {
          if (item.get('path_id') !== path_id) {
            return false;
          }
          if (item.get('parent_id')) {
            return false;
          }
          return _this.totem_scope.record_ownerable_match_ownerable(item, ownerable);
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});