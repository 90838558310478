define('client/components/thinkspace/common/file-upload', ['exports', 'ember', 'totem/ns', 'totem/config', 'thinkspace-base/components/base'], function (exports, ember, ns, config, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    layoutName: 'thinkspace/common/file_upload',
    classNameBindings: ['is_drag_hovered:drag-hovered'],
    form_action: null,
    host_name: null,
    verb: 'POST',
    api_params: null,
    model_path: null,
    btn_text: 'Upload',
    select_text: 'Browse file(s)',
    loading_text: 'Uploading files..',
    drop: true,
    after_import: null,
    close_on_success: true,
    debug: false,
    host: ember['default'].computed('host_name', function() {
      if (this.get('host_name')) {
        return this.get('host_name');
      } else {
        return config['default'].api_host;
      }
    }),
    form_url: ember['default'].computed('host', 'form_action', function() {
      return "" + (this.get('host')) + (this.get('form_action'));
    }),
    auth_token: ember['default'].computed(function() {
      return this.container.lookup('simple-auth-session:main').get('secure.token');
    }),
    store: ember['default'].computed(function() {
      return this.container.lookup('store:main');
    }),
    input_id: ember['default'].computed(function() {
      return "thinkspace-common_fu_input-" + (this.get('elementId'));
    }),
    modal_id: ember['default'].computed(function() {
      return "thinkspace-common_fu-modal-" + (this.get('elementId'));
    }),
    upload_success: false,
    upload_error: false,
    files: ember['default'].makeArray(),
    show_success: ember['default'].computed('upload_success', 'upload_error', function() {
      return this.get('upload_success') && !this.get('upload_error');
    }),
    show_error: ember['default'].computed('upload_success', 'upload_error', function() {
      return this.get('upload_error') && !this.get('upload_success');
    }),
    show_instructions: ember['default'].computed('upload_success', 'upload_error', function() {
      return (!this.get('upload_success')) && (!this.get('upload_error'));
    }),
    modal: ember['default'].computed('modal_id', function() {
      return $("#" + (this.get('modal_id')));
    }),
    file_input: ember['default'].computed('input_id', function() {
      return $("#" + (this.get('input_id')));
    }),
    drop_zone: ember['default'].computed.alias('modal'),
    params_list: ember['default'].computed('api_params', function() {
      var k, list, ref, v;
      list = [];
      ref = this.get('api_params');
      for (k in ref) {
        v = ref[k];
        list.pushObject({
          key: k,
          value: v
        });
      }
      return list;
    }),
    didInsertElement: function() {
      $(document).foundation('reveal');
      this.initialize_fileupload();
      if (this.get('drop')) {
        return this.add_listeners();
      }
    },
    initialize_fileupload: function() {
      var $drop_zone, $input;
      $input = this.get('file_input');
      $drop_zone = this.get('drop_zone');
      return $input.fileupload({
        autoUpload: true,
        dataType: 'json',
        dropZone: $drop_zone,
        fileInput: $input,
        add: (function(_this) {
          return function(e, data) {
            var jqXHR;
            _this.debug_log('add:', data);
            _this.set('processing', true);
            _this.set('server_response', null);
            jqXHR = data.submit();
            jqXHR.success(function(result, textStatus, jqXHR) {
              _this.set('upload_error', false);
              _this.set('upload_success', true);
              if (_this.get('after_import')) {
                _this.get('after_import')(result);
              }
              if (_this.get('close_on_success')) {
                return _this.send('close_modal');
              }
            });
            jqXHR.error(function(jqXHR, textStatus, errorThrown) {
              _this.set('server_response', jqXHR.responseJSON.errors.user_message);
              _this.set('upload_error', true);
              return _this.set('upload_success', false);
            });
            return jqXHR.complete(function(result, textStatus, jqXHR) {
              _this.set('processing', false);
              return _this.debug_log(textStatus, result);
            });
          };
        })(this),
        done: (function(_this) {
          return function(e, data) {
            var i, key, len, model_path, promises, record, records, ref, results, store;
            _this.debug_log('done:', data);
            _this.get('files').pushObjects(data.files);
            store = _this.get('store');
            model_path = _this.get('model_path');
            if (!model_path) {
              return;
            }
            store.pushPayload(model_path, data.result);
            key = model_path;
            records = data.result[key];
            if (!ember['default'].isPresent(records)) {
              key += 's';
            }
            records = data.result[key];
            ref = data.result[key];
            results = [];
            for (i = 0, len = ref.length; i < len; i++) {
              record = ref[i];
              promises = ember['default'].makeArray();
              promises.pushObject(store.find(model_path, record.id));
              results.push(ember['default'].RSVP.all(promises).then(function(records) {
                if (records.get('firstObject') && (records.get('firstObject').add_to_all_relationships != null)) {
                  promises = ember['default'].makeArray();
                  records.forEach(function(record) {
                    return promises.pushObject(record.add_to_all_relationships());
                  });
                  return ember['default'].RSVP.all(records).then(function() {
                    return _this.set('processing', false);
                  });
                } else {
                  return _this.set('processing', false);
                }
              }));
            }
            return results;
          };
        })(this),
        change: (function(_this) {
          return function(e, data) {
            return _this.debug_log('change:', data);
          };
        })(this)
      });
    },
    add_listeners: function() {
      var $drop_zone, $input;
      $(document).bind('drop dragover', function(e) {
        return e.preventDefault();
      });
      $drop_zone = this.get('drop_zone');
      $input = this.get('file_input');
      if ($drop_zone) {
        $drop_zone.on('dragover', function() {
          if (!$drop_zone.hasClass('drag-hovered')) {
            return $drop_zone.addClass('drag-hovered');
          }
        });
        return $drop_zone.on('dragleave drop', function() {
          if ($drop_zone.hasClass('drag-hovered')) {
            return $drop_zone.removeClass('drag-hovered');
          }
        });
      }
    },
    debug_log: function(context, message) {
      if (this.get('debug')) {
        return console.log("[fileupload-" + (this.get('elementId')) + "]", context, message);
      }
    },
    actions: {
      close_modal: function() {
        return this.get('modal').foundation('reveal', 'close');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});