define('client/components/thinkspace/casespace/assignment/show', ['exports', 'ember', 'totem/ns', 'totem/ds/associations', 'thinkspace-base/components/base'], function (exports, ember, ns, ta, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    init: function() {
      this._super();
      this.set_phase_progress();
      return this.set_show_components();
    },
    show_components: null,
    phase_manager: ember['default'].inject.service(),
    totem_data_config: {
      ability: true,
      metadata: {
        ajax_source: true
      }
    },
    c_assignment_header: ns['default'].to_p('assignment', 'header'),
    c_assignment_phases: ns['default'].to_p('assignment', 'phases'),
    c_phase_errors: ns['default'].to_p('builder', 'shared', 'phases', 'errors'),
    r_assignments_edit: ns['default'].to_r('builder', 'cases', 'overview'),
    r_phases_show: ns['default'].to_r('phases', 'show'),
    all_phases_completed: null,
    is_in_progress: null,
    resume_phase: null,
    phase_states_loaded: false,
    set_phase_progress: function() {
      var assignment;
      assignment = this.get('model');
      return assignment.get(ns['default'].to_p('phases')).then((function(_this) {
        return function(phases) {
          var phase_promises;
          phase_promises = phases.getEach(_this.ns.to_p('phase_states'));
          return ember['default'].RSVP.Promise.all(phase_promises).then(function() {
            var resume_phase, sorted_phases, uncompleted_phase;
            sorted_phases = phases.sortBy('position');
            resume_phase = sorted_phases.find(function(phase) {
              return phase.get('is_unlocked');
            });
            if (resume_phase) {
              _this.set('resume_phase', resume_phase);
              if (resume_phase !== sorted_phases.get('firstObject')) {
                _this.set('is_in_progress', true);
              }
            }
            uncompleted_phase = phases.find(function(phase) {
              return phase.get('is_completed') !== true;
            });
            if (!uncompleted_phase) {
              _this.set('all_phases_completed', true);
            }
            return _this.set('phase_states_loaded', true);
          }, function(error) {
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: ns['default'].to_p('phase_states')
            });
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.totem_messages.api_failure(error, {
            source: _this,
            model: ns['default'].to_p('phases')
          });
        };
      })(this));
    },
    set_show_components: function() {
      var comps, paths;
      paths = this.get('model.components.show');
      if (!paths) {
        return;
      }
      comps = ember['default'].makeArray(paths).map(function(path) {
        return ns['default'].to_p(path);
      });
      return this.set('show_components', comps);
    },
    actions: {
      activate: function() {
        this.totem_messages.show_loading_outlet();
        return this.get('model').activate().then((function(_this) {
          return function() {
            return _this.totem_messages.hide_loading_outlet();
          };
        })(this));
      },
      inactivate: function() {
        this.totem_messages.show_loading_outlet();
        return this.get('model').inactivate().then((function(_this) {
          return function() {
            return _this.totem_messages.hide_loading_outlet();
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});