define('client/components/thinkspace/readiness_assurance/admin/menu/dashboard', ['exports', 'ember', 'totem/ns', 'thinkspace-readiness-assurance/base/admin/menu'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    ra: ember['default'].inject.service(ns['default'].to_p('ra')),
    menu: ember['default'].computed.reads('am.dashboard_menu'),
    r_model: ember['default'].computed(function() {
      var model, route;
      model = this.get('model');
      route = this.totem_scope.get_record_path(model).pluralize();
      return route + ".show";
    }),
    willInsertElement: function() {
      var ra, room;
      this.am.set_model(this.get('model'));
      ra = this.get('ra');
      room = ra.get_admin_room();
      return ra.messages.load({
        room: room
      });
    },
    willDestroy: function() {
      return this.am.reset();
    },
    didInsertElement: function() {}
  });

  exports['default'] = ___DefaultExportObject___;;

});