define('client/models/thinkspace/casespace/phase_score', ['exports', 'ember', 'ember-data', 'totem/ds/associations'], function (exports, ember, ds, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].belongs_to('phase_state', {
    reads: {}
  })), {
    score: ta['default'].attr('number')
  });

  exports['default'] = ___DefaultExportObject___;;

});