define('client/components/thinkspace/casespace/case_manager/assignment/wizards/assessment/steps/confirmation', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace-case-manager/components/wizards/steps/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    step: 'confirmation',
    page_title: ember['default'].computed.reads('model.title'),
    team_set: ember['default'].computed.reads('wizard_manager.wizard.team_set')
  });

  exports['default'] = ___DefaultExportObject___;;

});