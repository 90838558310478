define('client/components/thinkspace/artifact/bucket/upload', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'thinkspace-base/components/base'], function (exports, ember, ns, ajax, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    files_url: ember['default'].computed(function() {
      return ajax['default'].adapter_model_url({
        model: ns['default'].to_p('artifact:file')
      });
    }),
    upload_wrapper_selector: '.thinkspace-artifact_upload',
    ownerable_type: ember['default'].computed(function() {
      return this.totem_scope.get_ownerable_type();
    }),
    ownerable_id: ember['default'].computed(function() {
      return this.totem_scope.get_ownerable_id();
    }),
    didInsertElement: function() {
      var $input, upload_wrapper_selector;
      upload_wrapper_selector = this.get('upload_wrapper_selector');
      $input = this.$().find(upload_wrapper_selector).first();
      return $input.fileupload({
        dataType: 'json',
        dropZone: upload_wrapper_selector,
        done: (function(_this) {
          return function(e, data) {
            var file, i, key, len, model, ref, results;
            model = _this.get('model');
            key = ns['default'].to_p('artifact:files');
            model.store.pushPayload(ns['default'].to_p('artifact:file'), data.result);
            ref = data.result[key];
            results = [];
            for (i = 0, len = ref.length; i < len; i++) {
              file = ref[i];
              results.push(model.store.find(ns['default'].to_p('artifact:file'), file.id).then(function(file) {
                return file.didLoad();
              }));
            }
            return results;
          };
        })(this)
      });
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});