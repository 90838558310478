define('client/components/thinkspace/casespace/case_manager/assignment/wizards/casespace', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'thinkspace-casespace-case-manager/components/wizards/base'], function (exports, ember, ns, ajax, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    step: null,
    steps: ['details', 'templates', 'phases', 'logistics', 'confirmation'],
    model: null,
    assignment: ember['default'].computed.alias('model'),
    title: null,
    instructions: null,
    due_at: null,
    model_state: null,
    release_at: null,
    template: null,
    build_mode: ember['default'].computed('is_editing', function() {
      if (this.get('is_editing')) {
        return 'edit';
      } else {
        return 'create';
      }
    }),
    c_wizard_step: ember['default'].computed('step', function() {
      var step;
      step = this.get('step');
      if (!ember['default'].isPresent(step)) {
        step = this.get('default_step');
      }
      return ns['default'].to_p('case_manager', 'assignment', 'wizards', 'casespace', 'steps', step);
    }),
    check_bundle_type: function(bundle_type, options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return resolve();
        };
      })(this));
    },
    check_step: function(step, options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var direction, is_editing;
          direction = options.direction;
          is_editing = _this.get('is_editing');
          switch (is_editing) {
            case true:
              if (ember['default'].isEqual(step, 'templates') && ember['default'].isEqual(direction, 'forward')) {
                step = 'phases';
              }
              if (ember['default'].isEqual(step, 'templates') && ember['default'].isEqual(direction, 'back')) {
                step = 'details';
              }
          }
          return resolve(step);
        };
      })(this));
    },
    complete_details: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return resolve();
        };
      })(this));
    },
    complete_templates: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var assignment, space_id, template, title;
          assignment = _this.get('model');
          title = _this.get('title');
          template = _this.get('template');
          space_id = _this.get('space_id');
          assignment.set('title', title);
          assignment.set('builder_template_id', template.get('id'));
          assignment.set('bundle_type', 'casespace');
          assignment.set('builder_version', 1);
          return assignment.store.find(ns['default'].to_p('space'), space_id).then(function(space) {
            assignment.set('space', space);
            _this.totem_messages.show_loading_outlet({
              message: 'Creating case...'
            });
            return assignment.save().then(function() {
              _this.get('wizard_manager').transition_to_assignment_edit(assignment, {
                queryParams: {
                  step: 'phases'
                }
              });
              _this.totem_messages.hide_loading_outlet();
              return resolve(true);
            }, function(error) {
              return reject(error);
            });
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    },
    complete_phases: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return resolve();
        };
      })(this));
    },
    complete_logistics: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var assignment, due_at, instructions, release_at, state;
          assignment = _this.get('model');
          instructions = _this.get('instructions');
          release_at = _this.get('release_at');
          due_at = _this.get('due_at');
          state = _this.get('model_state');
          assignment.set('instructions', instructions);
          assignment.set('release_at', release_at);
          assignment.set('due_at', due_at);
          assignment.set('state', state);
          return resolve();
        };
      })(this));
    },
    complete_confirmation: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var assignment;
          assignment = _this.get('model');
          return assignment.save().then(function(assignment) {
            console.log("[casespace wizard] Assignment saved: ", assignment);
            _this.get('thinkspace').disable_wizard_mode();
            return _this.get('wizard_manager').transition_to_assignment_show(assignment);
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    },
    actions: {
      set_title: function(title) {
        return this.set('title', title);
      },
      set_step: function(step) {
        return this.set('step', step);
      },
      set_template: function(template) {
        return this.set('template', template);
      },
      set_instructions: function(instructions) {
        return this.set('instructions', instructions);
      },
      set_due_at: function(due_at) {
        return this.set('due_at', due_at);
      },
      set_release_at: function(release_at) {
        return this.set('release_at', release_at);
      },
      set_model_state: function(state) {
        return this.set('model_state', state);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});