define('client/components/thinkspace/casespace/case_manager/assignment/peer_assessment/review', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'thinkspace-base/components/base'], function (exports, ember, ns, ajax, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    is_expanded: false,
    css_style: ember['default'].computed('color', function() {
      var color, css;
      color = this.get('color');
      if (!ember['default'].isPresent(color)) {
        return '';
      }
      css = '';
      css += "border-left-color: #" + color + ";";
      css += "border-top-color: #" + color + ";";
      return new ember['default'].Handlebars.SafeString(css);
    }),
    is_approvable: ember['default'].computed.reads('model.is_approvable'),
    c_review_comment: ns['default'].to_p('case_manager', 'assignment', 'peer_assessment', 'review', 'comment'),
    c_review_qual_item: ns['default'].to_p('case_manager', 'assignment', 'peer_assessment', 'review', 'qualitative'),
    c_state: ns['default'].to_p('case_manager', 'assignment', 'peer_assessment', 'state'),
    c_dropdown_split_button: ns['default'].to_p('common', 'dropdown_split_button'),
    willInsertElement: function() {
      var approved;
      approved = this.get('model.is_approved');
      if (!approved) {
        return this.set('is_expanded', true);
      }
    },
    state_change: function(state) {
      var model, query;
      model = this.get('model');
      query = {
        id: model.get('id'),
        action: state,
        verb: 'PUT'
      };
      return this.tc.query(ns['default'].to_p('tbl:review'), query, {
        single: true
      });
    },
    actions: {
      toggle: function() {
        return this.toggleProperty('is_expanded');
      },
      set_qualitative_comment_value: function(id, type, value) {
        var review;
        console.log("[tbl-pa-cm] Setting with id, type, value: ", id, type, value);
        review = this.get('model');
        review.set_qualitative_value(id, type, value);
        return this.get('assessment.authable').then((function(_this) {
          return function(authable) {
            _this.totem_scope.set_authable(authable);
            return review.save().then(function() {
              return _this.totem_messages.api_success({
                source: _this,
                model: review,
                action: 'update',
                i18n_path: ns['default'].to_o('tbl:review', 'save')
              });
            }, function(error) {
              return _this.totem_messages.api_failure(error, {
                source: _this,
                model: review,
                action: 'update'
              });
            });
          };
        })(this));
      },
      approve: function() {
        return this.state_change('approve');
      },
      unapprove: function() {
        return this.state_change('unapprove');
      },
      ignore: function() {
        return this.state_change('ignore');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});