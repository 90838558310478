define('client/components/thinkspace/peer_assessment/builder/overview/preview', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    manager: ember['default'].inject.service(ns['default'].to_p('peer_assessment', 'builder', 'manager'))
  });

  exports['default'] = ___DefaultExportObject___;;

});