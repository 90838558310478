define('client/components/thinkspace/builder/steps/logistics', ['exports', 'ember', 'totem/ns', 'thinkspace-builder/components/wizard/steps/base', 'totem/mixins/ckeditor'], function (exports, ember, ns, base, cke) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(cke['default'], {
    registered_phases: ember['default'].makeArray(),
    ttz: ember['default'].inject.service(),
    instructions: ember['default'].computed.reads('builder.model.instructions'),
    release_at: ember['default'].computed.reads('builder.model.release_at'),
    due_at: ember['default'].computed.reads('builder.model.due_at'),
    friendly_release_at: ember['default'].computed('release_at', function() {
      var date;
      date = this.get('release_at');
      if (!ember['default'].isPresent(date)) {
        return 'N/A';
      }
      return this.get('ttz').format(date, {
        format: 'MMMM Do YYYY, h:mm z',
        zone: this.get('ttz').get_client_zone_iana()
      });
    }),
    friendly_due_at: ember['default'].computed('due_at', function() {
      var date;
      date = this.get('due_at');
      if (!ember['default'].isPresent(date)) {
        return 'N/A';
      }
      return this.get('ttz').format(date, {
        format: 'MMMM Do YYYY, h:mm z',
        zone: this.get('ttz').get_client_zone_iana()
      });
    }),
    c_date_picker: ns['default'].to_p('common', 'date_picker'),
    c_time_picker: ns['default'].to_p('common', 'time_picker'),
    c_logistics_phases: ns['default'].to_p('builder', 'steps', 'logistics', 'phases'),
    init: function() {
      this._super();
      return this.load_assignment().then((function(_this) {
        return function() {
          return _this.set_all_data_loaded();
        };
      })(this));
    },
    load_assignment: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model;
          model = _this.get('model');
          return model.get(ns['default'].to_p('phases')).then(function(phases) {
            if (phases.get('length') > 0) {
              return resolve();
            }
            return _this.tc.query(ns['default'].to_p('assignment'), {
              id: model.get('id'),
              action: 'load'
            }, {
              single: true
            }).then(function(assignment) {
              return resolve();
            });
          });
        };
      })(this));
    },
    callbacks_next_step: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var assignment, builder, instructions, ns_assignment, obj, query;
          assignment = _this.get('model');
          instructions = _this.get('instructions');
          builder = _this.get('builder');
          ns_assignment = ns['default'].to_p('assignment');
          query = (
            obj = {
              id: assignment.get('id'),
              action: '',
              verb: 'put',
              configuration: _this.get_configuration_values()
            },
            obj["" + ns_assignment] = {
              instructions: instructions
            },
            obj
          );
          builder.set_is_saving();
          _this.totem_messages.show_loading_outlet();
          return _this.tc.query(ns['default'].to_p('assignment'), query, {
            single: true
          }).then(function(assignment) {
            console.log('[steps:logistics] Assignment: ', assignment);
            builder.reset_is_saving();
            _this.get('builder').transition_to_next_step();
            return resolve();
          }, function(error) {
            return _this.get('builder').encountered_save_error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return console.error('Error caught in details step.');
        };
      })(this));
    },
    get_configuration_values: function() {
      var values;
      return values = {
        due_at: this.get('due_at'),
        release_at: this.get('release_at')
      };
    },
    save_phases: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {};
      })(this));
    },
    save_phase: function(phase, options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var ns_phase, obj, query, store;
          ns_phase = ns['default'].to_p('phase');
          store = _this.totem_scope.get_store();
          query = (
            obj = {
              id: phase.get('id'),
              action: '',
              verb: 'put',
              configuration: options.configuration
            },
            obj["" + ns_phase] = {
              default_state: options.default_state,
              unlock_at: options.unlock_at,
              due_at: options.due_at
            },
            obj
          );
          return _this.tc.query(ns['default'].to_p('phase'), query, {
            single: true
          }).then(function(phase) {
            return resolve(phase);
          });
        };
      })(this));
    },
    set_date: function(property, date) {
      console.log(date, date.obj);
      return this.set(property, date.obj);
    },
    set_time: function(property, time) {
      var date, new_date;
      date = this.get(property);
      date = this.get('ttz').set_date_to_time(date, time);
      new_date = new Date(date.getTime());
      return this.set(property, new_date);
    },
    get_registered_phases: function() {
      return this.get('registered_phases');
    },
    actions: {
      select_release_date: function(date) {
        return this.set_date('release_at', date);
      },
      select_release_time: function(time) {
        return this.set_time('release_at', time);
      },
      select_due_date: function(date) {
        return this.set_date('due_at', date);
      },
      select_due_time: function(time) {
        return this.set_time('due_at', time);
      },
      select_unlock_at: function(date) {
        return this.set('unlock_at', date);
      },
      toggle_phase_logistics: function() {
        return this.toggleProperty('showing_phase_logistics');
      },
      register_phase: function(component) {
        return this.get_registered_phases().pushObject(component);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});