define('client/components/thinkspace/builder/steps/parts/phases/phase', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    model: null,
    is_ordering: null,
    assignment: null,
    tagName: '',
    dropdown_collection: ember['default'].computed('model.state', function() {
      var collection;
      collection = [];
      collection.pushObject({
        display: 'Clone',
        action: 'clone'
      });
      if (this.get('model.is_not_archived')) {
        collection.pushObject({
          display: 'Archive',
          action: 'archive'
        });
      }
      if (this.get('model.is_not_active') && this.get('model.is_not_archived')) {
        collection.pushObject({
          display: 'Activate',
          action: 'activate'
        });
      }
      if (this.get('model.is_active') || this.get('model.is_archived')) {
        collection.pushObject({
          display: 'Save as Draft',
          action: 'inactivate'
        });
      }
      collection.pushObject({
        display: 'Delete',
        action: 'delete'
      });
      return collection;
    }),
    r_phases_edit: ns['default'].to_r('builder', 'phases', 'edit'),
    c_dropdown_split_button: ns['default'].to_p('common', 'dropdown_split_button'),
    model_state_change: function(action) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model;
          model = _this.get('model');
          if (!ember['default'].isPresent(model)) {
            return;
          }
          _this.totem_messages.show_loading_outlet();
          if (model[action] == null) {
            return;
          }
          return model[action]().then(function(phase) {
            _this.totem_messages.hide_loading_outlet();
            return resolve();
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    actions: {
      activate: function() {
        return this.model_state_change('activate');
      },
      archive: function() {
        return this.model_state_change('archive');
      },
      inactivate: function() {
        return this.model_state_change('inactivate');
      },
      clone: function() {
        var model;
        model = this.get('model');
        this.totem_messages.show_loading_outlet({
          message: "Cloning " + (model.get('title')) + "..."
        });
        return model.get(ns['default'].to_p('assignment')).then((function(_this) {
          return function(assignment) {
            var data;
            data = {
              phase_id: model.get('id'),
              assignment_id: assignment.get('id'),
              verb: 'POST',
              action: 'clone'
            };
            return _this.tc.query(ns['default'].to_p('phase'), data, {
              single: true
            }).then(function(phase) {
              _this.totem_messages.hide_loading_outlet();
              return _this.totem_messages.api_success({
                source: _this,
                model: phase,
                action: 'clone',
                i18n_path: ns['default'].to_o('phase', 'clone')
              });
            }, function(error) {
              _this.totem_messages.hide_loading_outlet();
              return _this.totem_messages.api_failure(error, {
                source: _this,
                model: model,
                action: 'clone'
              });
            });
          };
        })(this), (function(_this) {
          return function(error) {
            _this.totem_messages.hide_loading_outlet();
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: model,
              action: 'clone'
            });
          };
        })(this));
      },
      "delete": function() {
        var confirm, phase;
        confirm = window.confirm('Are you sure you want to delete this phase?');
        if (!confirm) {
          return;
        }
        phase = this.get('model');
        phase.deleteRecord();
        return phase.save().then((function(_this) {
          return function() {
            return _this.totem_messages.api_success({
              source: _this,
              model: phase,
              action: 'destroy',
              i18n_path: ns['default'].to_o('phase', 'destroy')
            });
          };
        })(this), (function(_this) {
          return function(error) {
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: phase,
              action: 'destroy'
            });
          };
        })(this));
      },
      move_up: function() {
        return this.get('model').move_to_offset(-1);
      },
      move_down: function() {
        return this.get('model').move_to_offset(1);
      },
      move_to_top: function() {
        return this.get('model').move_to_top();
      },
      move_to_bottom: function() {
        return this.get('model').move_to_bottom();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});