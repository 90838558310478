define('client/components/thinkspace/reporter/report', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    casespace: ember['default'].inject.service(),
    token: null,
    c_loader: ns['default'].to_p('common', 'loader'),
    r_assignments_reports: ns['default'].to_p('assignments', 'reports'),
    init: function() {
      this._super();
      return this.get_report().then((function(_this) {
        return function() {
          return _this.set_all_data_loaded();
        };
      })(this));
    },
    get_report: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query, token;
          token = _this.get('token');
          if (!ember['default'].isPresent(token)) {
            return;
          }
          query = {
            action: 'access',
            verb: 'get',
            id: token
          };
          return _this.tc.query(ns['default'].to_p('reporter:report'), query, {
            single: true
          }).then(function(report) {
            _this.set('model', report);
            return resolve();
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});