define('client/components/thinkspace/markup/sidepocket/library/marker', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    model: null,
    tagName: '',
    model_text: ember['default'].computed('model', function() {
      if (ember['default'].isPresent(this.get('model'))) {
        return this.get('model');
      }
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});