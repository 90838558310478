define('client/components/thinkspace/peer_assessment/builder/assessment/quant/settings', ['exports', 'ember', 'totem/ns', 'totem/mixins/validations', 'thinkspace-base/components/base'], function (exports, ember, ns, validations, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(validations['default'], {
    manager: ember['default'].inject.service(ns['default'].to_p('peer_assessment', 'builder', 'manager')),
    points_min: ember['default'].computed.reads('model.points_min'),
    points_max: ember['default'].computed.reads('model.points_max'),
    label: ember['default'].computed.reads('model.label'),
    scale_label_min: ember['default'].computed.reads('model.settings.labels.scale.min'),
    scale_label_max: ember['default'].computed.reads('model.settings.labels.scale.max'),
    has_comments: ember['default'].computed.reads('model.settings.comments.enabled'),
    c_checkbox: ns['default'].to_p('common', 'shared', 'checkbox'),
    c_validated_input: ns['default'].to_p('common', 'shared', 'validated_input'),
    update_model: function() {
      var has_comments, label, model, points_max, points_min, scale_label_max, scale_label_min;
      model = this.get('model');
      points_min = this.get('points_min');
      points_max = this.get('points_max');
      label = this.get('label');
      scale_label_min = this.get('scale_label_min');
      scale_label_max = this.get('scale_label_max');
      has_comments = this.get('has_comments');
      model.set_value('points_min', points_min);
      model.set_value('points_max', points_max);
      model.set_value('label', label);
      model.set_value('scale_label_min', scale_label_min);
      model.set_value('scale_label_max', scale_label_max);
      model.set_value('has_comments', has_comments);
      return console.info("[pa:builder:quant:settings] Model post update is: ", model);
    },
    actions: {
      toggle_has_comments: function() {
        return this.toggleProperty('has_comments');
      },
      back: function() {
        return this.sendAction('back');
      },
      save: function() {
        this.update_model();
        this.get('manager').save_model();
        return this.sendAction('back');
      }
    },
    validations: {
      points_min: {
        numericality: true,
        property_less_than: {
          smaller_property: 'points_min',
          larger_property: 'points_max',
          message: 'Minimum points must be less than maximum points.'
        }
      },
      points_max: {
        numericality: true
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});