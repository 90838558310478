define('client/components/thinkspace/readiness_assurance/assessment/trat_overview/show', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'thinkspace-readiness-assurance/response_manager', 'thinkspace-readiness-assurance/mixins/data_rows', 'thinkspace-readiness-assurance/base/ra_component'], function (exports, ember, ns, ajax, response_manager, m_data_rows, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(m_data_rows['default'], {
    team_data_rows: null,
    columns_per_row: 5,
    willInsertElement: function() {
      return this.setup();
    },
    setup: function() {
      this.ra.load_messages();
      this.store = this.totem_scope.get_store();
      return this.get_trat_overview().then((function(_this) {
        return function(payload) {
          return _this.set_team_data(payload);
        };
      })(this));
    },
    set_team_data: function(payload) {
      var answer, answer_id, answers, choices, data, hash, i, j, len, len1, q, qid, question, questions, results, tdata, team, teams, title;
      teams = payload.teams;
      questions = payload.questions;
      data = payload.data;
      results = [];
      for (i = 0, len = questions.length; i < len; i++) {
        q = questions[i];
        qid = q.id;
        question = q.question;
        choices = q.choices;
        answers = [];
        for (j = 0, len1 = teams.length; j < len1; j++) {
          hash = teams[j];
          team = hash.team;
          title = team.title;
          tdata = this.get_team_data(data, team.id);
          answer_id = tdata && tdata.answers[qid];
          answer = this.get_team_answer(choices, answer_id);
          answers.push({
            title: title,
            answer: answer
          });
        }
        results.push({
          question: question,
          answers: answers
        });
      }
      return this.set('team_data_rows', this.get_data_rows(results));
    },
    get_team_data: function(data, id) {
      return data.findBy('team_id', id);
    },
    get_team_answer: function(choices, id) {
      var choice;
      choice = id && choices.findBy('id', id);
      if (choice) {
        choice = choice.label;
      }
      return choice || 'none';
    },
    get_trat_overview: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var action, model, query, verb;
          model = ns['default'].to_p('ra:assessment');
          action = 'trat_overview';
          verb = 'post';
          query = {
            model: model,
            action: action,
            verb: verb
          };
          _this.totem_scope.add_auth_to_ajax_query(query);
          return ajax['default'].object(query).then(function(payload) {
            return resolve(payload);
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});