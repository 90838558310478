define('client/components/thinkspace/indented_list/builder/list/parts/expert_selector', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    builder: ember['default'].inject.service(),
    lists: null,
    users: null,
    selected_list: null,
    selected_user: null,
    has_selected_list: ember['default'].computed.notEmpty('selected_list'),
    has_selected_user: ember['default'].computed.notEmpty('selected_user'),
    has_both_selected: ember['default'].computed.and('has_selected_list', 'has_selected_user'),
    c_loader: ns['default'].to_p('common', 'loader'),
    c_ownerable_selector: ns['default'].to_p('casespace', 'ownerable', 'selector'),
    init: function() {
      this._super();
      return this.set_indented_lists().then((function(_this) {
        return function() {
          return _this.set_roster().then(function() {
            return _this.set_all_data_loaded();
          });
        };
      })(this));
    },
    set_indented_lists: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get('builder').get_assignment().then(function(assignment) {
            var query;
            query = {
              id: assignment.get('id'),
              action: 'phase_componentables',
              componentable_type: ns['default'].to_p('indented:list')
            };
            return _this.tc.query(ns['default'].to_p('assignment'), query, {
              payload_type: ns['default'].to_p('indented:list')
            }).then(function(lists) {
              lists = lists.filterBy('expert', false);
              _this.set('lists', lists);
              return resolve();
            }, function(error) {
              return _this.error(error);
            });
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    set_roster: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var builder;
          builder = _this.get('builder');
          return builder.get_roster().then(function(users) {
            _this.set('users', users);
            return resolve();
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    reset_selected_list: function() {
      return this.set('selected_list', null);
    },
    reset_selected_user: function() {
      return this.set('selected_user', null);
    },
    reset_all_selected: function() {
      this.reset_selected_user();
      return this.reset_selected_list();
    },
    actions: {
      select_list: function(list) {
        return this.set('selected_list', list);
      },
      select_user: function(user) {
        return this.set('selected_user', user);
      },
      reset_selected_list: function() {
        return this.reset_selected_list();
      },
      reset_selected_user: function() {
        return this.reset_all_selected();
      },
      set_expert_response: function() {
        var list, model, query, user;
        model = this.get('model');
        list = this.get('selected_list');
        user = this.get('selected_user');
        query = {
          action: 'set_expert_response',
          id: model.get('id'),
          list_id: list.get('id'),
          user_id: user.get('id'),
          verb: 'PUT'
        };
        this.totem_messages.show_loading_outlet();
        return this.tc.query(ns['default'].to_p('indented:list'), query).then((function(_this) {
          return function() {
            _this.reset_all_selected();
            return _this.totem_messages.hide_loading_outlet();
          };
        })(this), (function(_this) {
          return function(error) {
            _this.reset_all_selected();
            return _this.totem_messages.hide_loading_outlet();
          };
        })(this));
      },
      cancel: function() {
        return this.reset_all_selected();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});