define('client/routes/application', ['exports', 'ember', 'totem/config', 'totem/logger', 'totem/ajax', 'simple-auth/mixins/application-route-mixin'], function (exports, ember, config, logger, ajax, auth_mixin) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Route.extend(auth_mixin['default'], {
    title: function(tokens) {
      var name;
      name = config['default'].stylized_platform_name;
      if (!ember['default'].isPresent(name)) {
        name = config.env.EmberENV.PLATFORM_NAME.capitalize();
      }
      if (ember['default'].isPresent(tokens)) {
        tokens = tokens.reverse();
        tokens.pushObject(name);
        return tokens.join(' - ');
      } else {
        return name;
      }
    },
    renderTemplate: function() {
      return this.render('totem/layout');
    },
    render_error: function() {
      var template;
      if (config['default'].messages) {
        template = config['default'].messages.error_template;
      }
      if (!template) {
        template = 'totem/error';
      }
      return this.render(template);
    },
    invalidate_session: function() {
      if (this.session.get('isAuthenticated')) {
        return this.session.invalidate();
      }
    },
    actions: {
      sign_out: function() {
        return this.invalidate_session();
      },
      error: function(reason) {
        if (reason == null) {
          reason = {};
        }
        console.info('1.......action-error', reason);
        this.invalidate_session();
        if (reason.is_totem_error && reason.is_handled) {
          return;
        }
        if (reason.is_totem_error) {
          reason.is_handled = true;
        }
        if (reason.is_totem_error && reason.is_api_error) {
          if (this.is_development()) {
            logger['default'].error(reason.message);
          }
        } else {
          this.totem_messages.error(reason.message);
          logger['default'].error(reason.message);
        }
        if (reason.stack && this.is_development()) {
          logger['default'].error(reason.stack);
        }
        this.render_error();
      },
      hide_all: function(type) {
        return this.totem_messages.hide_all();
      },
      hide_type: function(type) {
        return this.totem_messages.hide_type(type);
      },
      show_all: function(type) {
        return this.totem_messages.show_all();
      },
      show_type: function(type) {
        return this.totem_messages.show_type(type);
      },
      clear_type: function(type) {
        return this.totem_messages.clear_type(type);
      },
      clear_all: function() {
        this.totem_messages.clear_all();
        return this.reset_totem_error_template_messages();
      },
      show_totem_message_outlet: function(template_name, options) {
        if (options == null) {
          options = {};
        }
        this._show_totem_message_outlet(options);
        return this.render(template_name, {
          into: 'totem/layout',
          outlet: 'totem_message_outlet',
          view: options.view,
          controller: options.controller
        });
      },
      hide_totem_message_outlet: function() {
        this._hide_totem_message_outlet();
        return this.disconnectOutlet({
          outlet: 'totem_messages_outlet',
          parentView: 'totem/layout'
        });
      }
    },
    reset_totem_error_template_messages: function() {
      return this.set_totem_error_template_message(null);
    },
    set_totem_error_template_message: function(message) {
      return this.get('controller').set('totem_error_template_message', message);
    },
    _show_totem_message_outlet: function(options) {
      var class_names, main_outlet, message_outlet;
      message_outlet = this.jquery_totem_message_outlet();
      main_outlet = this.jquery_totem_main_outlet();
      message_outlet.removeClass('totem_message_outlet-hide');
      if (options.fade_main_outlet !== false) {
        main_outlet.addClass('totem_main_outlet-fade');
      }
      class_names = this._class_names(options.outlet_class_names);
      this.set('controller.totem_message_outlet_class_names', class_names);
      if (class_names) {
        message_outlet.addClass(class_names);
      }
      class_names = this._class_names(options.main_outlet_class_names);
      this.set('controller.totem_main_outlet_class_names', class_names);
      if (class_names) {
        return main_outlet.addClass(class_names);
      }
    },
    _hide_totem_message_outlet: function() {
      var class_names, main_outlet, message_outlet;
      message_outlet = this.jquery_totem_message_outlet();
      main_outlet = this.jquery_totem_main_outlet();
      message_outlet.addClass('totem_message_outlet-hide');
      main_outlet.removeClass('totem_main_outlet-fade');
      class_names = this.get('controller.totem_message_outlet_class_names');
      if (class_names) {
        message_outlet.removeClass(class_names);
      }
      class_names = this.get('controller.totem_main_outlet_class_names');
      if (class_names) {
        return main_outlet.removeClass(class_names);
      }
    },
    _class_names: function(class_names) {
      if (!class_names) {
        return null;
      }
      return ember['default'].makeArray(class_names).compact().join(' ');
    },
    jquery_totem_message_outlet: function() {
      return ember['default'].$('.totem_message_outlet');
    },
    jquery_totem_main_outlet: function() {
      return ember['default'].$('.totem_main_outlet');
    },
    is_development: function() {
      return config.env.environment === 'development';
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});