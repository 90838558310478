define('client/components/thinkspace/casespace/case_manager/assignment/wizards/assessment/steps/settings/quantitative', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace-case-manager/components/wizards/steps/base', 'totem/mixins/validations'], function (exports, ember, ns, base, val_mixin) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(val_mixin['default'], {
    type: null,
    methodology: null,
    methodology_types: null,
    options: null,
    is_categories: ember['default'].computed.equal('type', 'categories'),
    is_balance: ember['default'].computed.equal('type', 'balance'),
    is_free: ember['default'].computed.equal('type', 'free'),
    c_categories: ns['default'].to_p('case_manager', 'assignment', 'wizards', 'assessment', 'steps', 'settings', 'quantitative', 'categories'),
    c_balance: ns['default'].to_p('case_manager', 'assignment', 'wizards', 'assessment', 'steps', 'settings', 'quantitative', 'balance'),
    c_free: ns['default'].to_p('case_manager', 'assignment', 'wizards', 'assessment', 'steps', 'settings', 'quantitative', 'free'),
    c_assessment_type: ember['default'].computed('type', function() {
      return this.get("c_" + (this.get('type')));
    }),
    set_type: 'set_type',
    set_points_per_member: 'set_points_per_member',
    set_points_max: 'set_points_max',
    set_points_min: 'set_points_min',
    set_points_different: 'set_points_different',
    set_points_descriptive_enabled: 'set_points_descriptive_enabled',
    set_points_descriptive_values: 'set_points_descriptive_values',
    add_quantitative_item: 'add_quantitative_item',
    actions: {
      set_points_per_member: function(value) {
        return this.sendAction('set_points_per_member', value);
      },
      set_points_max: function(value) {
        return this.sendAction('set_points_max', value);
      },
      set_points_min: function(value) {
        return this.sendAction('set_points_min', value);
      },
      set_points_different: function(value) {
        return this.sendAction('set_points_different', value);
      },
      set_points_descriptive_enabled: function(value) {
        return this.sendAction('set_points_descriptive_enabled', value);
      },
      set_points_descriptive_values: function(values) {
        return this.sendAction('set_points_descriptive_values', values);
      },
      set_type: function(type) {
        return this.sendAction('set_type', type);
      },
      add_quantitative_item: function() {
        return this.sendAction('add_quantitative_item');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});