define('client/components/thinkspace/readiness_assurance/admin/menu/irat', ['exports', 'ember', 'thinkspace-readiness-assurance/base/admin/menu'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    menu: ember['default'].computed.reads('am.irat_menu'),
    didInsertElement: function() {}
  });

  exports['default'] = ___DefaultExportObject___;;

});