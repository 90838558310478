define('client/components/thinkspace/casespace/case_manager/assignment/wizards/casespace/steps/templates', ['exports', 'ember', 'totem/ns', 'totem/ds/associations', 'thinkspace-casespace-case-manager/components/wizards/steps/base'], function (exports, ember, ns, ta, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    step: 'templates',
    all_data_loaded: false,
    page_title: ember['default'].computed.reads('model.title'),
    assignment_template: ember['default'].computed.reads('wizard_manager.wizard.template'),
    c_template_select: ns['default'].to_p('case_manager', 'assignment', 'wizards', 'casespace', 'steps', 'templates', 'select'),
    c_loader: ns['default'].to_p('common', 'shared', 'loader'),
    assignment_templates: ember['default'].computed(function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var store;
          store = _this.totem_scope.get_store();
          return _this.templates_ajax().then(function(templates) {
            _this.set('all_data_loaded', true);
            return resolve(templates);
          });
        };
      })(this));
      return ta['default'].PromiseArray.create({
        promise: promise
      });
    }),
    templates_ajax: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query, store;
          store = _this.totem_scope.get_store();
          query = {
            model: ns['default'].to_p('assignment'),
            action: 'templates',
            verb: 'get'
          };
          return _this.wizard_ajax(query, 'builder:template').then(function(records) {
            return resolve(records);
          });
        };
      })(this));
    },
    actions: {
      set_template: function(template) {
        return this.get('wizard_manager').send_action('set_template', template);
      },
      complete: function() {
        if (!this.get('assignment_template')) {
          return;
        }
        return this.get('wizard_manager').send_action('complete_step', 'templates');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});