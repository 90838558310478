define('client/components/thinkspace/casespace/case_manager/assignment/wizards/casespace/steps/phases/clone', ['exports', 'ember', 'totem/ns', 'totem/ds/associations', 'totem/ajax', 'thinkspace-casespace-case-manager/components/wizards/steps/base'], function (exports, ember, ns, ta, ajax, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    all_data_loaded: false,
    case_manager: ember['default'].inject.service(),
    c_loader: ns['default'].to_p('common', 'shared', 'loader'),
    phase_templates: ember['default'].computed(function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var templates;
          templates = _this.get('case_manager').get_case_manager_map().get('cm_phase_templates');
          if (ember['default'].isPresent(templates)) {
            return resolve(templates);
          }
          return _this.templates_ajax().then(function(templates) {
            templates = templates.sortBy('title');
            _this.get('case_manager').get_case_manager_map().set('cm_phase_templates', templates);
            _this.set('all_data_loaded', true);
            return resolve(templates);
          });
        };
      })(this));
      return ta['default'].PromiseArray.create({
        promise: promise
      });
    }),
    actions: {
      clone: function(template) {
        this.totem_messages.show_loading_outlet({
          message: 'Cloning phase template...'
        });
        return this.clone_ajax(template).then((function(_this) {
          return function() {
            return _this.totem_messages.hide_loading_outlet();
          };
        })(this), (function(_this) {
          return function(error) {
            return console.error("[clone] Cloning failed: ", error);
          };
        })(this));
      },
      close: function() {
        return this.sendAction('close');
      }
    },
    templates_ajax: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query, store;
          store = _this.totem_scope.get_store();
          query = {
            model: ns['default'].to_p('phase'),
            action: 'templates',
            verb: 'get'
          };
          return _this.wizard_ajax(query, 'case_manager_template').then(function(records) {
            return resolve(records);
          });
        };
      })(this));
    },
    clone_ajax: function(template) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query, store;
          store = template.store;
          query = {
            model: ns['default'].to_p('phase'),
            action: 'clone',
            verb: 'post',
            data: {
              case_manager_template_id: template.get('id'),
              assignment_id: _this.get('model.id')
            }
          };
          return ajax['default'].object(query).then(function(payload) {
            store.pushPayload(payload);
            return resolve();
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});