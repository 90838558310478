define('client/components/thinkspace/indented_list/response/item/edit', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    edit_value: null,
    willInsertElement: function() {
      return this.get('response_manager').get_item_value(this.get('item')).then((function(_this) {
        return function(value) {
          return _this.set('edit_value', value);
        };
      })(this));
    },
    actions: {
      cancel: function() {
        return this.sendAction('cancel');
      },
      done: function() {
        return this.sendAction('done', this.get('edit_value'));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});