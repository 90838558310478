define('client/components/thinkspace/lab/builder/lab/result/form/range', ['exports', 'ember', 'totem/ns', 'client/components/thinkspace/lab/builder/lab/result/form/base', 'totem/mixins/validations'], function (exports, ember, ns, base, val_mixin) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(val_mixin['default'], {
    lower_value: null,
    upper_value: null,
    init_values: function() {
      var lower, ref, upper;
      ref = this.get_model_value_path(), lower = ref[0], upper = ref[1];
      this.set('lower_value', lower);
      return this.set('upper_value', upper);
    },
    get_display_value: function() {
      var lower, ref, upper;
      ref = this.get_model_value_path(), lower = ref[0], upper = ref[1];
      if (ember['default'].isPresent(lower) && ember['default'].isPresent(upper)) {
        return lower + "-" + upper;
      }
      if (ember['default'].isPresent(lower)) {
        return "" + lower;
      }
      return "" + upper;
    },
    form_valid: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (!_this.get('is_valid')) {
            return reject();
          }
          _this.set_model_value_path([_this.get('lower_value'), _this.get('upper_value')]);
          return resolve();
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});