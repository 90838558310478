define('client/components/thinkspace/peer_assessment/builder/assessment/quant/preview', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base', 'thinkspace-peer-assessment/components/peer_assessment/builder/assessment/quant/item'], function (exports, ember, ns, base, quant_item) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    manager: ember['default'].inject.service(ns['default'].to_p('peer_assessment', 'builder', 'manager')),
    classNameBindings: ['is_selected:is-selected'],
    classNames: ['ts-pa_quant-item', 'ts-pa_item'],
    edit_mode: null,
    is_managing_settings: false,
    is_edit_mode_content: ember['default'].computed.equal('edit_mode', 'content'),
    fill_class: 'ts-rangeslider_fill',
    handle_class: 'ts-rangeslider_handle',
    range_class: 'ts-rangeslider',
    fill_class_hex: {
      'green': '#6dc05e',
      'yellow': '#fcb725',
      'red': '#fb6b6b'
    },
    slider_background_template: "<div class='ts-rangeslider_background' />",
    fill_color_change: ember['default'].observer('fill_color', function() {
      var color, fill_class, fill_class_hex, fill_color;
      fill_color = this.get('fill_color');
      fill_class = this.get('fill_class');
      fill_class_hex = this.get('fill_class_hex');
      color = fill_class_hex[fill_color];
      return this.$("." + fill_class).css('background-color', color);
    }),
    c_quant_settings: ns['default'].to_p('peer_assessment', 'builder', 'assessment', 'quant', 'settings'),
    init: function() {
      var assessment, item, model;
      this._super();
      model = this.get('model');
      assessment = this.get('assessment');
      item = quant_item['default'].create({
        model: model,
        assessment: assessment
      });
      return this.set('model', item);
    },
    didInsertElement: function() {
      return this.$('input[type="range"]').rangeslider({
        polyfill: false,
        rangeClass: this.get('range_class'),
        fillClass: this.get('fill_class'),
        handleClass: this.get('handle_class'),
        onInit: (function(_this) {
          return function() {
            return _this.slider_set_ruler();
          };
        })(this),
        onSlide: (function(_this) {
          return function(position, value) {
            return _this.slider_set_color(value);
          };
        })(this)
      });
    },
    willDestroyElement: function() {
      return this.$('input[type="range"]').rangeslider('destroy');
    },
    mouseEnter: function(event) {
      return this.focusIn(event);
    },
    mouseLeave: function(event) {
      return this.focusOut(event);
    },
    focusIn: function(event) {
      return this.set_is_selected();
    },
    focusOut: function(event) {
      return this.reset_is_selected();
    },
    set_is_selected: function() {
      return this.set('is_selected', true);
    },
    reset_is_selected: function() {
      return this.set('is_selected', false);
    },
    slider_set_ruler: function() {
      var $background, range_class, template;
      range_class = this.get('range_class');
      template = this.get('slider_background_template');
      $background = $(template);
      return this.$("." + range_class).prepend($background);
    },
    slider_set_color: function(value) {
      var percentage, points_max;
      points_max = 5;
      percentage = value / points_max;
      switch (false) {
        case !(percentage >= 0.67):
          return this.set('fill_color', 'green');
        case !(percentage >= 0.33 && percentage < 0.67):
          return this.set('fill_color', 'yellow');
        case !(percentage >= 0 && percentage < 0.33):
          return this.set('fill_color', 'red');
      }
    },
    actions: {
      set_is_managing_settings: function() {
        return this.set('is_managing_settings', true);
      },
      reset_is_managing_settings: function() {
        this.set('is_managing_settings', false);
        return ember['default'].run.schedule('afterRender', this, (function(_this) {
          return function() {
            return _this.didInsertElement();
          };
        })(this));
      },
      order_up: function() {
        var model;
        model = this.get('model.model');
        return this.get('manager').reorder_quant_item(model, -1);
      },
      order_down: function() {
        var model;
        model = this.get('model.model');
        return this.get('manager').reorder_quant_item(model, 1);
      },
      duplicate: function() {
        var model;
        model = this.get('model.model');
        return this.get('manager').duplicate_quant_item(model);
      },
      "delete": function() {
        var model;
        model = this.get('model.model');
        return this.get('manager').delete_quant_item(model);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});