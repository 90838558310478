define('client/components/thinkspace/diagnostic_path/path_item/edit', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    className: 'sortable-exclude',
    value: null,
    placeholder: 'What did you observe?',
    has_path_itemable: ember['default'].computed.bool('model.path_itemable_id'),
    edit_path_itemable: ember['default'].computed('has_path_itemable', function() {
      return this.get('has_path_itemable') && true;
    }),
    didInsertElement: function() {
      if (this.get('has_path_itemable')) {
        return this.get('model.path_itemable').then((function(_this) {
          return function(itemable) {
            _this.set('value', itemable.get('value'));
            return _this.set_textarea();
          };
        })(this));
      } else {
        this.set('value', this.get('model.description'));
        return this.set_textarea();
      }
    },
    set_textarea: function() {
      var $textarea;
      $textarea = this.$('textarea');
      return $textarea.focus();
    },
    actions: {
      cancel: function() {
        return this.sendAction('cancel');
      },
      done: function() {
        var path_item, value;
        path_item = this.get('model');
        value = this.get('value');
        if (this.get('edit_path_itemable')) {
          this.save_path_itemable(path_item, value);
        } else {
          this.save_path_item(path_item, value);
        }
        return this.send('cancel');
      }
    },
    save_path_itemable: function(path_item, value) {
      return path_item.get('path_itemable').then((function(_this) {
        return function(itemable) {
          console.warn('save path itemable:', value, itemable);
          itemable.set('value', value);
          return itemable.save().then(function(itemable) {
            return _this.totem_messages.api_success({
              source: _this,
              model: itemable,
              action: 'save',
              i18n_path: ns['default'].to_o('observation', 'save')
            });
          }, function(error) {
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: itemable,
              action: 'save'
            });
          });
        };
      })(this));
    },
    save_path_item: function(path_item, value) {
      console.warn('save path item:', value, path_item, this);
      path_item.set('path_itemable_id', null);
      path_item.set('path_itemable_type', null);
      path_item.set('description', value);
      return path_item.save().then((function(_this) {
        return function(path_item) {
          return _this.totem_messages.api_success({
            source: _this,
            model: path_item,
            action: 'save',
            i18n_path: ns['default'].to_o('path_item', 'save')
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.totem_messages.api_failure(error, {
            source: _this,
            model: path_item,
            action: 'save'
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});