define('client/components/thinkspace/readiness_assurance/admin/shared/time_at', ['exports', 'ember', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    can_clear: ember['default'].computed('selected_at', function() {
      return this.clearable !== false && ember['default'].isPresent(this.get('selected_at'));
    }),
    show_select: true,
    init: function() {
      this._super();
      this.set_time_options();
      this.selected_at = this.rad.get(this.time);
      return this.validate = this.rad.validate;
    },
    actions: {
      clear: function() {
        return this.set_time(null);
      },
      select: function(time) {
        var at;
        if (ember['default'].isBlank(time)) {
          return;
        }
        at = this.get_time_at();
        at = this.am.ttz.set_date_to_time(at, time);
        return this.set_time(at);
      }
    },
    set_time: function(time) {
      this.set('selected_at', time);
      this.set_rad(time);
      if (this.validate) {
        return this.sendAction('validate');
      }
    },
    set_time_options: function() {
      var clear, interval, max, min;
      interval = parseInt(this.get_rad('interval') || 1);
      min = this.get_min(interval);
      max = this.get_max(interval);
      clear = this.get_rad('clear') || '';
      this.set('time_options', {
        interval: interval,
        min: min,
        max: max,
        clear: clear
      });
      if (this.validate) {
        return this.sendAction('validate');
      }
    },
    get_min: function(int) {
      var min, min_at;
      min = this.get_rad('min') || 0;
      if (this.am.is_date(min)) {
        min_at = this.am.clone_date(min);
      } else {
        min_at = this.get_time_at();
        this.am.adjust_by_minutes(min_at, (min * int) + 1);
        this.am.round_up_minutes(min_at, int);
      }
      return min_at;
    },
    get_max: function(int) {
      var max, max_at;
      max = this.get_rad('max') || 0;
      if (this.am.is_date(max)) {
        max_at = this.am.clone_date(max);
      } else {
        max_at = this.get_time_at();
        this.am.adjust_by_minutes(max_at, (max * int) - 1);
        this.am.round_down_minutes(max_at, int);
      }
      return max_at;
    },
    get_time_at: function() {
      return this.am.clone_date(this.time_at || this.rad.time_at);
    },
    get_rad: function(key) {
      return this.rad.get(this.time + "_" + key);
    },
    set_rad: function(val) {
      return this.rad.set(this.time, val);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});