define('client/models/thinkspace/common/space_user', ['exports', 'ember', 'totem/config', 'totem/ds/associations'], function (exports, ember, config, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].belongs_to('user', {
    reads: {}
  }), ta['default'].belongs_to('space', {
    reads: {}
  })), {
    role: ta['default'].attr('string'),
    space_id: ta['default'].attr('number'),
    state: ta['default'].attr('string'),
    friendly_roles: ember['default'].computed(function() {
      var friendly, role, roles, roles_map;
      roles_map = config['default'].roles_map;
      if (!ember['default'].isPresent(roles_map)) {
        console.error("Could not find roles map in config, cannot process space_user.");
      }
      roles = [];
      for (role in roles_map) {
        friendly = roles_map[role];
        roles.push(friendly);
      }
      return roles;
    }),
    friendly_role: ember['default'].computed('role', function() {
      var current_role, friendly, name, role, roles_map;
      current_role = this.get('role');
      roles_map = config['default'].roles_map;
      name = null;
      if (!ember['default'].isPresent(roles_map)) {
        console.error("Could not find roles map in config, cannot process space_user.");
      }
      for (role in roles_map) {
        friendly = roles_map[role];
        if (ember['default'].isEqual(role.toLowerCase(), current_role.toLowerCase())) {
          name = friendly;
        }
      }
      if (!ember['default'].isPresent(name)) {
        console.error("Could not find friendly for [" + current_role + "] in config.roles_map [" + roles_map + "].");
      }
      return name;
    }),
    is_active: ember['default'].computed.equal('state', 'active'),
    is_inactive: ember['default'].computed.equal('state', 'inactive'),
    set_role_from_friendly: function(friendly_role) {
      var friendly, results, role, roles;
      roles = config['default'].roles_map;
      results = [];
      for (role in roles) {
        friendly = roles[role];
        if (friendly === friendly_role) {
          results.push(this.set('role', role));
        } else {
          results.push(void 0);
        }
      }
      return results;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});