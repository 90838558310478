define('client/components/thinkspace/html/builder/html/content', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'totem/mixins/ckeditor', 'thinkspace-base/components/base'], function (exports, ember, ns, ajax, ckeditor_mixin, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend(ckeditor_mixin['default'], {
    classNames: ['html_content'],
    ckeditor_tag: null,
    editor_loaded: false,
    confirm_visible: false,
    confirm_changes: null,
    has_delete: false,
    error_messages: null,
    validation_message: null,
    t_edit_confirm: ns['default'].to_t('html', 'builder', 'html', 'content', 'confirm'),
    has_errors: ember['default'].computed.or('error_messages', 'validation_message'),
    didInsertElement: function() {
      var edit_area, options;
      edit_area = this.$('textarea.html_html-edit-content');
      this.set('ckeditor_tag', edit_area);
      options = {
        height: 550
      };
      return this.ckeditor_load().then((function(_this) {
        return function() {
          return edit_area.ckeditor((function() {
            return _this.set('editor_loaded', true);
          }), options);
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.totem_error["throw"](_this, error);
        };
      })(this));
    },
    willDestroyElement: function() {
      return this.send('cancel');
    },
    actions: {
      save: function() {
        return this.save(this.ckeditor_value());
      },
      cancel: function() {
        return this.sendAction('cancel');
      },
      cancel_confirm: function() {
        return this.confirm_off();
      },
      next: function() {
        return this.submit_html_for_validation();
      }
    },
    save: function(new_html) {
      return this.save_content(new_html);
    },
    save_content: function(new_html) {
      var content;
      content = this.get('model');
      content.set('html_content', new_html);
      return content.save().then((function(_this) {
        return function(content) {
          _this.totem_messages.api_success({
            source: _this,
            model: content,
            action: 'save',
            i18n_path: ns['default'].to_o('content', 'save')
          });
          return _this.sendAction('cancel');
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.totem_messages.api_failure(error, {
            source: _this,
            model: content
          });
        };
      })(this));
    },
    submit_html_for_validation: function() {
      var content, new_html;
      content = this.get('model');
      new_html = this.ckeditor_value();
      this.confirm_off();
      return this.validate_html(content, new_html).then((function(_this) {
        return function(result) {
          _this.clear_error_messages();
          if (result.errors) {
            return _this.set('error_messages', result.errors);
          } else {
            _this.set('confirm_changes', result.changes);
            _this.set('confirm_visible', true);
            return _this.set('has_delete', result.changes.find(function(change) {
              return change.tag === 'input' && change.action === 'delete';
            }));
          }
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.totem_messages.api_failure(error, {
            source: _this,
            model: content
          });
        };
      })(this));
    },
    validate_html: function(content, new_html) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          if (!new_html) {
            return resolve(null);
          }
          query = {
            verb: 'post',
            action: 'validate',
            model: content,
            id: content.get('id'),
            data: {
              new_html: new_html
            }
          };
          _this.totem_scope.add_authable_to_query(query.data);
          return ajax['default'].object(query).then(function(result) {
            return resolve(result);
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    },
    confirm_off: function() {
      return this.set('confirm_visible', false);
    },
    clear_error_messages: function() {
      this.set('validation_message', null);
      return this.set('error_messages', null);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});