define('client/components/thinkspace/resource/pane', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    t_pane_actions: ns['default'].to_t('resource', 'pane', 'actions'),
    c_manage_files: ns['default'].to_p('resource', 'manage', 'files'),
    c_manage_links: ns['default'].to_p('resource', 'manage', 'links'),
    c_manage_tags: ns['default'].to_p('resource', 'manage', 'tags'),
    c_pane_file: ns['default'].to_p('resource', 'pane', 'file'),
    c_pane_link: ns['default'].to_p('resource', 'pane', 'link'),
    manage_files_expanded: false,
    manage_links_expanded: false,
    manage_tags_expanded: false,
    actions: {
      close: function() {
        return this.sendAction('close');
      },
      toggle_files_pane: function() {
        if (this.toggleProperty('manage_files_expanded')) {
          this.send('close_links_pane');
          return this.send('close_tags_pane');
        }
      },
      toggle_links_pane: function() {
        if (this.toggleProperty('manage_links_expanded')) {
          this.send('close_files_pane');
          return this.send('close_tags_pane');
        }
      },
      toggle_tags_pane: function() {
        if (this.toggleProperty('manage_tags_expanded')) {
          this.send('close_files_pane');
          return this.send('close_links_pane');
        }
      },
      close_files_pane: function() {
        return this.set('manage_files_expanded', false);
      },
      close_links_pane: function() {
        return this.set('manage_links_expanded', false);
      },
      close_tags_pane: function() {
        return this.set('manage_tags_expanded', false);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});