define('client/components/thinkspace/builder/phases/edit', ['exports', 'ember', 'totem/ns', 'thinkspace-builder/components/wizard/steps/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    component_map: null,
    edit_mode: 'content',
    transition_previous_phase: null,
    transition_next_phase: null,
    is_edit_mode_content: ember['default'].computed.equal('edit_mode', 'content'),
    is_edit_mode_settings: ember['default'].computed.equal('edit_mode', 'settings'),
    has_previous_phase: ember['default'].computed.notEmpty('transition_previous_phase'),
    has_next_phase: ember['default'].computed.notEmpty('transition_next_phase'),
    tvo: ember['default'].inject.service(),
    r_cases_phases: ns['default'].to_r('builder', 'cases', 'phases'),
    r_phases_show: ns['default'].to_r('casespace', 'phases', 'show'),
    c_phases_edit_layout: ns['default'].to_p('builder', 'phases', 'edit', 'layout'),
    c_phases_edit_settings: ns['default'].to_p('builder', 'phases', 'edit', 'settings'),
    model_observer: ember['default'].observer('model', function() {
      this.reset_all_data_loaded();
      return this.set_all();
    }),
    init: function() {
      this._super();
      return this.set_all();
    },
    set_all: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.set_assignment().then(function() {
            return _this.load_phase().then(function() {
              return _this.set_phase_components().then(function() {
                return _this.set_phase_template().then(function() {
                  return _this.set_transition_phases().then(function() {
                    _this.set_all_data_loaded();
                    return resolve();
                  });
                });
              });
            });
          });
        };
      })(this));
    },
    set_transition_phases: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var assignment, model;
          assignment = _this.get('assignment');
          model = _this.get('model');
          return assignment.get('active_phases').then(function(phases) {
            var index, next_phase, prev_phase;
            index = phases.indexOf(model);
            if (!ember['default'].isPresent(index)) {
              return resolve();
            }
            prev_phase = phases[index - 1];
            next_phase = phases[index + 1];
            _this.set('transition_previous_phase', prev_phase);
            _this.set('transition_next_phase', next_phase);
            return resolve();
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    set_assignment: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model;
          model = _this.get('model');
          return model.get(ns['default'].to_p('assignment')).then(function(assignment) {
            var id;
            id = assignment.get('id');
            return _this.tc.query(ns['default'].to_p('assignment'), {
              id: id,
              action: 'load'
            }, {
              single: true
            }).then(function(assignment) {
              _this.set('assignment', assignment);
              return resolve();
            }, function(error) {
              return _this.error(error);
            });
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    load_phase: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var id, model;
          model = _this.get('model');
          id = model.get('id');
          return _this.tc.query(ns['default'].to_p('phase'), {
            id: id,
            action: 'load'
          }).then(function() {
            return resolve();
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this));
    },
    set_phase_components: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model;
          model = _this.get('model');
          return model.get(ns['default'].to_p('phase_components')).then(function(phase_components) {
            _this.set('phase_components', phase_components);
            return resolve();
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    set_phase_template: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model;
          model = _this.get('model');
          return model.get(ns['default'].to_p('phase_template')).then(function(phase_template) {
            _this.set('phase_template', phase_template);
            return _this.create_phase_component_map().then(function() {
              _this.parse_phase_template(phase_template);
              return resolve();
            });
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    create_phase_component_map: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var component_map, phase_components, promises;
          component_map = _this.set_component_map();
          phase_components = _this.get('phase_components');
          promises = [];
          phase_components.forEach(function(phase_component) {
            var promise;
            promise = new ember['default'].RSVP.Promise(function(resolve, reject) {
              return phase_component.get('component').then(function(component) {
                return phase_component.get('componentable').then(function(componentable) {
                  component_map.set(phase_component, {
                    phase_component: phase_component,
                    component: component,
                    componentable: componentable
                  });
                  return resolve();
                });
              }, function(error) {
                return _this.error(error);
              });
            });
            return promises.pushObject(promise);
          });
          return ember['default'].RSVP.all(promises).then(function() {
            return resolve();
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    set_component_map: function() {
      var component_map;
      component_map = ember['default'].Map.create();
      this.set('component_map', component_map);
      return component_map;
    },
    parse_phase_template: function(pt) {
      var $component, $components, $template, c_componentable, component, component_map, html, i, len, phase_component, phase_components, section, template, tvo, value_path, values;
      phase_components = this.get('phase_components');
      component_map = this.get('component_map');
      tvo = this.get('tvo');
      tvo.clear();
      tvo.template.parse(pt.get('template'));
      $template = tvo.template.get_template();
      $components = $template.find('component');
      for (i = 0, len = $components.length; i < len; i++) {
        component = $components[i];
        $component = $(component);
        section = $component.attr('section');
        if (ember['default'].isPresent(section)) {
          phase_component = phase_components.findBy('section', section);
          values = component_map.get(phase_component);
          c_componentable = ns['default'].to_p('builder', 'phases', 'edit', 'componentable');
          value_path = tvo.value.set_value(values);
          html = "{{ component '" + c_componentable + "' model=tvo." + value_path + ".componentable component=tvo." + value_path + ".component phase_component=tvo." + value_path + ".phase_component }}";
          $component.replaceWith(html);
        }
      }
      tvo.template.set('$template', $template);
      template = tvo.template.compile();
      return this.set('template', template);
    },
    actions: {
      set_edit_mode_settings: function() {
        this.totem_data.ability.unload();
        return this.set('edit_mode', 'settings');
      },
      set_edit_mode_content: function() {
        return this.set('edit_mode', 'content');
      },
      next_phase: function() {
        var phase;
        phase = this.get('transition_next_phase');
        if (!ember['default'].isPresent(phase)) {
          return;
        }
        return this.get('builder').transition_to_phases_edit(phase);
      },
      previous_phase: function() {
        var phase;
        phase = this.get('transition_previous_phase');
        if (!ember['default'].isPresent(phase)) {
          return;
        }
        return this.get('builder').transition_to_phases_edit(phase);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});