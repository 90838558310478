define('client/components/thinkspace/casespace/phase/header/progress', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    phase_manager: ember['default'].inject.service(),
    r_phases_show: ns['default'].to_r('phases', 'show'),
    has_multiple_phase_states: ember['default'].computed('phase_states', function() {
      return this.get('phase_states.length') > 1;
    }),
    is_current_phase: ember['default'].computed(function() {
      return this.get('model') === this.get('current_phase');
    }),
    is_select_phase_state: ember['default'].computed.and('is_current_phase', 'has_multiple_phase_states'),
    phase_states: ember['default'].computed(function() {
      var phase;
      phase = this.get('model');
      if (this.get('phase_manager').has_active_addon()) {
        return this.get('phase_manager.map').get_ownerable_phase_states(phase);
      } else {
        return this.get('phase_manager.map').get_current_user_phase_states(phase);
      }
    }),
    actions: {
      select: function(phase_state) {
        return this.sendAction('select', phase_state);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});