define('client/models/thinkspace/readiness_assurance/response', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].belongs_to('ra:assessment'), ta['default'].belongs_to('ra:status'), ta['default'].belongs_to('ra:chat'), ta['default'].polymorphic('ownerable')), {
    answers: ta['default'].attr(),
    justifications: ta['default'].attr(),
    userdata: ta['default'].attr(),
    ownerable_id: ta['default'].attr('number'),
    ownerable_type: ta['default'].attr('string')
  });

  exports['default'] = ___DefaultExportObject___;;

});