define('client/views/select/select', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].View.extend({
    tagName: 'select',
    templateName: 'select/select',
    attributeBindings: ['multiple', 'data_placeholder:data-placeholder'],
    classNames: ['chosen-select'],
    classNameBindings: ['class_names'],
    data_placeholder: 'Select an Option',
    multiple: false,
    no_select: false,
    content: null,
    display_property: null,
    option_class_names: '',
    class_names: '',
    selected_items: [],
    disabled_items: [],
    select_action: null,
    deselect_action: null,
    value: null,
    disabled: false,
    optgroups: null,
    context: null,
    validated: false,
    is_object_list: false,
    update_class_names: ember['default'].observer('class_names', function() {
      var $chosen_select, new_class_names, old_class_names;
      new_class_names = this.get('class_names');
      old_class_names = this.get('old_class_names');
      $chosen_select = this.$().siblings('.chosen-select');
      $chosen_select.removeClass(old_class_names);
      $chosen_select.addClass(new_class_names);
      return this.set('old_class_names', new_class_names);
    }),
    allow_single_deselect: false,
    disable_search: false,
    disable_search_threshold: 0,
    enable_split_word_search: true,
    inherit_select_classes: true,
    max_selected_options: 2e308,
    no_results_text: "No results match",
    placeholder_text_multiple: "Select Some Options",
    placeholder_text_single: "Select an Option",
    search_contains: false,
    single_backstroke_delete: true,
    display_disabled_options: true,
    display_selected_options: true,
    width: null,
    single: ember['default'].computed.not('multiple'),
    show_empty_option: ember['default'].computed.and('no_select', 'single'),
    no_select_option_text: ember['default'].computed.oneWay('data_placeholder'),
    optgroup: ember['default'].computed.notEmpty('optgroups'),
    select_ids_recomputed: 0,
    is_record_list: (function() {
      var content, record;
      content = this.get('content');
      if (content) {
        record = content.get('firstObject');
      }
      if (record) {
        return record.save != null;
      }
    }).property('content.firstObject', 'optgroups'),
    groups: (function() {
      var group, groups, i, len, member, name, optgroup, optgroups;
      optgroups = this.get('optgroups');
      groups = [];
      for (name in optgroups) {
        optgroup = optgroups[name];
        group = [];
        for (i = 0, len = optgroup.length; i < len; i++) {
          member = optgroup[i];
          group.pushObject(member);
        }
        groups.pushObject(group);
      }
      return groups;
    }).property('optgroups'),
    totem_select_id: (function() {
      return this.get('elementId');
    }).property('elementId'),
    totem_select_id_property: (function() {
      var property, totem_select_id;
      totem_select_id = this.get('totem_select_id');
      return property = "totem_select_" + totem_select_id + "_id";
    }).property('totem_select_id'),
    content_changed: (function() {
      this.destroy_chosen();
      return this.rerender();
    }).observes('content', 'rerender_on'),
    context_obs: (function() {
      this.destroy_chosen();
      return this.$().trigger('chosen:updated');
    }).observes('context'),
    didInsertElement: function() {
      return this.initialize_chosen();
    },
    initialize_chosen: function() {
      var options, view;
      view = this;
      this.set_select_ids();
      this.set('old_class_names', this.get('class_names'));
      options = this.get_options();
      this.$().chosen(options);
      if (this.get('disabled')) {
        this.$().prop('disabled', true).trigger("chosen:updated");
      }
      return this.$().on('change', function(evt, params) {
        var action, controller, getter, id, item, multiple, new_array, obj, prop, validated, value, values;
        if (params === void 0 && view.get('allow_single_deselect')) {
          params = {
            deselected: ''
          };
        }
        if (params && 'selected' in params) {
          id = params.selected;
        }
        if (params && 'deselected' in params) {
          id = params.deselected;
        }
        if (id !== void 0) {
          item = view.get('is_record_list') ? view.get_record_with_id(id) : id;
          controller = view.get('controller');
          value = view.get('value');
          multiple = view.get('multiple');
          validated = view.get('validated');
          action = 'selected' in params ? view.get('select_action') : action = view.get('deselect_action');
          if (value) {
            values = value.split('.');
            prop = values.pop();
            getter = values.join('.');
            switch (false) {
              case !('selected' in params):
                if (multiple) {
                  if ((value != null) && (controller.get(value).pushObject != null) && !controller.get(value).contains(item)) {
                    controller.get(value).pushObject(item);
                  }
                  if (validated) {
                    new_array = ember['default'].makeArray().concat(controller.get(value));
                    controller.set(value, new_array);
                  }
                } else {
                  if (ember['default'].isEmpty(values)) {
                    controller.set(prop, item);
                  } else {
                    obj = controller.get(getter);
                    obj.set(prop, item);
                  }
                }
                break;
              case !('deselected' in params):
                if (multiple) {
                  if ((value != null) && (controller.get(value).removeObject != null) && controller.get(value).contains(item)) {
                    controller.get(value).removeObject(item);
                  }
                  if (validated) {
                    new_array = ember['default'].makeArray().concat(controller.get(value));
                    controller.set(value, new_array);
                  }
                } else {
                  if (ember['default'].isEmpty(values)) {
                    controller.set(prop, null);
                  } else {
                    obj = controller.get(getter);
                    obj.set(prop, null);
                  }
                }
            }
          }
          if (action) {
            return controller.send(action, item);
          }
        }
      });
    },
    set_select_ids: function() {
      var content, totem_select_id_property;
      if (this.get('is_record_list') && !this.get('is_object_list')) {
        content = this.get('content');
        totem_select_id_property = this.get('totem_select_id_property');
        content.forEach((function(_this) {
          return function(record, index) {
            return record.set(totem_select_id_property, index + 1);
          };
        })(this));
        return this.trigger_select_ids_recomputed();
      }
    },
    trigger_select_ids_recomputed: function() {
      return this.incrementProperty('select_ids_recomputed');
    },
    get_record_with_id: function(id) {
      var content, record, totem_select_id_property;
      record = null;
      if (id) {
        if (typeof id === 'string') {
          id = parseInt(id);
        }
        totem_select_id_property = this.get('totem_select_id_property');
        content = this.get('content');
        content.forEach((function(_this) {
          return function(item) {
            if (item.get(totem_select_id_property) === id) {
              return record = item;
            }
          };
        })(this));
      }
      return record;
    },
    destroy_chosen: function() {
      var $chosen, chosen_id, chosen_selector, id;
      if (this.$()) {
        id = this.get('totem_select_id');
        chosen_id = id + "_chosen";
        chosen_selector = '#' + chosen_id;
        $chosen = this.$().siblings(chosen_selector);
        return $chosen.remove();
      }
    },
    get_options: function() {
      var options;
      return options = {
        allow_single_deselect: this.get('allow_single_deselect'),
        disable_search: this.get('disable_search'),
        disable_search_threshold: this.get('disable_search_threshold'),
        enable_split_word_search: this.get('enable_split_word_search'),
        inherit_select_classes: this.get('inherit_select_classes'),
        max_selected_options: this.get('max_selected_options'),
        no_results_text: this.get('no_results_text'),
        placeholder_text_multiple: this.get('placeholder_text_multiple'),
        placeholder_text_single: this.get('placeholder_text_single'),
        search_contains: this.get('search_contains'),
        single_backstroke_delete: this.get('single_backstroke_delete'),
        display_disabled_options: this.get('display_disabled_options'),
        display_selected_options: this.get('display_selected_options'),
        width: this.get('width')
      };
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});