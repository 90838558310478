define('client/helpers/time-to-now', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Handlebars.makeBoundHelper(function(date, options) {
    return moment(date).toNow();
  });

  exports['default'] = ___DefaultExportObject___;;

});