define('client/components/thinkspace/markup/discussion/markers/default', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    markup: ember['default'].inject.service(ns['default'].to_p('markup', 'manager')),
    model: null,
    classNames: ['ts-markup_discussion', 'ts-markup_discussion-default'],
    classNameBindings: ['is_highlighted:is-highlighted'],
    width: 50,
    height: 50,
    discussion_number: ember['default'].computed('discussions.length', 'model', function() {
      var discussions, model;
      discussions = this.get('discussions');
      model = this.get('model');
      return this.get('markup').get_discussion_number(discussions, model);
    }),
    init: function() {
      this._super();
      return this.get('markup').add_marker_component(this);
    },
    willDestroyElement: function() {
      return this.get('markup').remove_marker_component(this);
    },
    willInsertElement: function() {
      return this.position_from_value();
    },
    click: function(e) {
      var model;
      e.stopPropagation();
      e.preventDefault();
      model = this.get('model');
      return this.get('markup').highlight_discussion(model);
    },
    set_is_highlighted: function() {
      return this.set('is_highlighted', true);
    },
    reset_is_highlighted: function() {
      return this.set('is_highlighted', false);
    },
    get_is_highlighted: function() {
      return this.get('is_highlighted');
    },
    position_from_value: function() {
      var height, height_offset, page, page_top, position, render_x, render_y, width, width_offset, y;
      position = this.get('model.value.position');
      height = this.get('height');
      width = this.get('width');
      height_offset = parseFloat(height / 2.0);
      width_offset = parseFloat(width / 2.0);
      render_x = position.x - width_offset;
      y = position.y;
      page = position.page;
      page_top = $("canvas[page='" + page + "']").position().top;
      render_y = page > 1 ? y + page_top - height_offset : y - height_offset;
      return this.$().css({
        top: render_y,
        left: render_x
      });
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});