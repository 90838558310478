define('client/components/thinkspace/weather_forecaster/response/checkbox', ['exports', 'ember', 'totem/ns', 'totem/mixins/validations'], function (exports, ember, ns, val_mixin) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Component.extend(val_mixin['default'], {
    classNameBindings: ['no_errors::thinkspace-weather-forecaster_error'],
    no_errors: ember['default'].computed.or('is_valid', 'is_view_only'),
    c_checkbox_item: ns['default'].to_p('wf:response', 'checkbox', 'item'),
    choices: ember['default'].computed(function() {
      return this.get('metadata.choices');
    }),
    input_values: null,
    didInsertElement: function() {
      var item_validations, rules;
      if (item_validations = this.get('metadata.validations')) {
        rules = this.get_validation_rules_from_metadata(item_validations);
        this.set_validation_rules({
          input_values: rules
        });
      }
      return this.set('input_values', ember['default'].makeArray(this.get('model.value.input')));
    },
    get_input_values: function() {
      return this.get('input_values');
    },
    actions: {
      check: function(id) {
        if (this.get('is_view_only')) {
          return;
        }
        this.add_id(id);
        return this.validate().then((function(_this) {
          return function() {
            return _this.sendAction('save', _this.get_input_values());
          };
        })(this), (function(_this) {
          return function() {
            return _this.remove_id(id);
          };
        })(this));
      },
      uncheck: function(id) {
        if (this.get('is_view_only')) {
          return;
        }
        this.remove_id(id);
        return this.validate().then((function(_this) {
          return function() {
            return _this.sendAction('save', _this.get_input_values());
          };
        })(this), (function(_this) {
          return function() {
            return _this.add_id(id);
          };
        })(this));
      }
    },
    add_id: function(id) {
      var values;
      values = this.get_input_values();
      if (!values.contains(id)) {
        return values.pushObject(id);
      }
    },
    remove_id: function(id) {
      var index, values;
      values = this.get_input_values();
      index = values.indexOf(id);
      if (index != null) {
        return values.removeAt(index);
      }
    },
    get_validation_rules_from_metadata: function(validation) {
      var length, max, min, msg;
      if (!validation) {
        return null;
      }
      length = validation.length || {};
      min = length.minimum || null;
      max = length.maximum || null;
      msg = length.message || null;
      return {
        inline: this.input_values_min_max_validator(min, max, msg)
      };
    },
    input_values_min_max_validator: function(min, max, msg) {
      return this.inline_validator()(function() {
        var length;
        length = this.get('input_values.length');
        if (length == null) {
          return null;
        }
        switch (false) {
          case !(((min != null) && (max != null)) && (length < min || length > max)):
            return msg || ("You must select between " + min + " and " + max + " items");
          case !((min != null) && length < min):
            return msg || ("You must select at least " + min + " items");
          case !((max != null) && length > max):
            return msg || ("You must select less than " + max + " items");
          default:
            return null;
        }
      });
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});