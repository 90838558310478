define('client/models/thinkspace/common/space_type', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].has_many('spaces', {
    reads: {}
  })), {
    title: ta['default'].attr('string'),
    lookup_model: ta['default'].attr('string'),
    component: ember['default'].computed(function() {
      return this.get('lookup_model');
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});