define('client/components/thinkspace/casespace/assignment', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    has_actions: ember['default'].computed.or('can.clone', 'can.destroy'),
    dropdown_collection: ember['default'].computed(function() {
      var collection;
      collection = [];
      if (this.get('can.clone')) {
        collection.push({
          display: 'Clone Case',
          route: this.get('r_assignments_clone'),
          model: this.get('model')
        });
      }
      if (this.get('can.destroy')) {
        collection.push({
          display: 'Delete Case',
          route: this.get('r_assignments_delete'),
          model: this.get('model')
        });
      }
      return collection;
    }),
    c_dropdown_split_button: ns['default'].to_p('common', 'dropdown_split_button'),
    r_assignments_show: ns['default'].to_r('assignments', 'show'),
    r_assignments_clone: ns['default'].to_r('case_manager', 'assignments', 'clone'),
    r_assignments_delete: ns['default'].to_r('case_manager', 'assignments', 'delete')
  });

  exports['default'] = ___DefaultExportObject___;;

});