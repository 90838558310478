define('client/_config/thinkspace/markup/routes', ['exports'], function (exports) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = {
    'thinkspace/markup': {
      path: '/markup'
    },
    '/markup': {
      'thinkspace/markup/libraries': {
        path: '/libraries',
        resource: true
      }
    },
    '/markup/libraries': {
      edit: {
        path: '/:library_id/edit'
      }
    }
  };

  exports['default'] = ___DefaultExportObject___;;

});