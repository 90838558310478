define('client/components/thinkspace/casespace/case_manager/team_set/new', ['exports', 'ember', 'ember-data', 'totem/ns', 'totem/ajax', 'thinkspace-base/components/base'], function (exports, ember, ds, ns, ajax, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    space: ember['default'].computed.reads('model'),
    title: null,
    c_space_header: ns['default'].to_p('space', 'header'),
    r_team_sets: ns['default'].to_r('case_manager', 'team_sets', 'index'),
    transition_to_team_set_show: 'transition_to_team_set_show',
    get_store: function() {
      return this.container.lookup('store:main');
    },
    actions: {
      save: function() {
        var space, team_set;
        space = this.get('space');
        team_set = this.get_store().createRecord(ns['default'].to_p('team_set'), {
          title: this.get('title'),
          space: space
        });
        return team_set.save().then((function(_this) {
          return function(team_set) {
            return _this.sendAction('transition_to_team_set_show', space, team_set);
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});