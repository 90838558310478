define('client/_config/thinkspace/indented_list/ns', ['exports'], function (exports) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = {
    namespaces: {
      indented_list: 'thinkspace/indented_list'
    },
    type_to_namespace: {
      'indented:list': 'indented_list',
      'indented:response': 'indented_list',
      'indented:expert_response': 'indented_list'
    }
  };

  exports['default'] = ___DefaultExportObject___;;

});