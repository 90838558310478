define('client/components/thinkspace/indented_list/list/source/observation', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    get_container: function() {
      return $('ol.obs-list_list');
    },
    get_response_manager: function() {
      return this.get('response_manager');
    },
    willInsertElement: function() {
      return this.get_response_manager().register_source_container(this, this.get_container(), {
        callback: 'set_new_item_values'
      });
    },
    set_new_item_values: function($el, new_item) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var item_values, rm;
          rm = _this.get_response_manager();
          item_values = rm.get_source_element_html_model_attributes($el);
          if (ember['default'].isBlank(item_values.itemable_value_path)) {
            item_values.itemable_value_path = 'value';
          }
          return rm.get_item_itemable(item_values).then(function(observation) {
            if (ember['default'].isBlank(observation)) {
              return resolve(item_values);
            }
            return observation.get(ns['default'].to_p('list')).then(function(list) {
              var category_id;
              if (ember['default'].isBlank(list)) {
                return resolve(item_values);
              }
              category_id = list.get('category_id');
              item_values.icon = _this.convert_observation_list_category_to_icon_id(category_id);
              return resolve(item_values);
            });
          });
        };
      })(this));
    },
    convert_observation_list_category_to_icon_id: function(category_id) {
      switch (category_id) {
        case 'd':
          return 'lab';
        case 'h':
          return 'html';
        case 'm':
          return 'mechanism';
        default:
          return null;
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});