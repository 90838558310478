define('client/users/show/controller', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Controller.extend({
    c_profile_space: ns['default'].to_p('user', 'profile_space')
  });

  exports['default'] = ___DefaultExportObject___;;

});