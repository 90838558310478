define('client/_config/thinkspace/casespace/ns', ['exports'], function (exports) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = {
    namespaces: {
      authorization: 'thinkspace/authorization',
      toolbar: 'thinkspace/casespace/toolbar',
      sidepocket: 'thinkspace/casespace/sidepocket',
      crumbs: 'thinkspace/casespace/toolbar/crumbs',
      dock: 'thinkspace/dock',
      common: 'thinkspace/common',
      casespace: 'thinkspace/casespace',
      artifact: 'thinkspace/artifact',
      diagnostic_path: 'thinkspace/diagnostic_path',
      diagnostic_path_viewer: 'thinkspace/diagnostic_path_viewer',
      html: 'thinkspace/html',
      input_element: 'thinkspace/input_element',
      lab: 'thinkspace/lab',
      markup: 'thinkspace/markup',
      observation_list: 'thinkspace/observation_list',
      resource: 'thinkspace/resource',
      team: 'thinkspace/team',
      peer_assessment: 'thinkspace/peer_assessment',
      weather_forecaster: 'thinkspace/weather_forecaster',
      simulation: 'thinkspace/simulation',
      builder: 'thinkspace/builder'
    },
    type_to_namespace: {
      ability: 'authorization',
      metadata: 'authorization',
      user: 'common',
      owner: 'common',
      space: 'common',
      space_type: 'common',
      space_user: 'common',
      configuration: 'common',
      configurable: 'common',
      component: 'common',
      invitation: 'common',
      password_reset: 'common',
      discipline: 'common',
      agreement: 'common',
      key: 'common',
      comment: 'markup',
      library: 'markup',
      library_comment: 'markup',
      discussion: 'markup',
      resourceable: 'resource',
      file: 'resource',
      link: 'resource',
      tag: 'resource',
      team: 'team',
      team_category: 'team',
      team_user: 'team',
      team_teamable: 'team',
      team_viewer: 'team',
      team_set: 'team',
      assignment: 'casespace',
      phase: 'casespace',
      phase_template: 'casespace',
      phase_component: 'casespace',
      phase_state: 'casespace',
      phase_score: 'casespace',
      gradebook: 'casespace',
      peer_review: 'casespace',
      case_manager: 'casespace',
      case_manager_template: 'casespace',
      content: 'html',
      path: 'diagnostic_path',
      path_item: 'diagnostic_path',
      path_itemable: 'diagnostic_path',
      viewer: 'diagnostic_path_viewer',
      bucket: 'artifact',
      'artifact:file': 'artifact',
      'path_item:parent': 'diagnostic_path',
      'comment:parent': 'markup',
      'lab:chart': 'lab',
      'lab:category': 'lab',
      'lab:result': 'lab',
      'lab:observation': 'lab',
      'wf:assessment': 'weather_forecaster',
      'wf:assessment_item': 'weather_forecaster',
      'wf:forecast': 'weather_forecaster',
      'wf:forecast_day': 'weather_forecaster',
      'wf:item': 'weather_forecaster',
      'wf:response': 'weather_forecaster',
      'wf:response_score': 'weather_forecaster',
      'wf:station': 'weather_forecaster',
      list: 'observation_list',
      observation: 'observation_list',
      observation_note: 'observation_list',
      'observation_list:group': 'observation_list',
      element: 'input_element',
      response: 'input_element',
      'tbl:assessment': 'peer_assessment',
      'tbl:review': 'peer_assessment',
      'tbl:review_set': 'peer_assessment',
      'tbl:team_set': 'peer_assessment',
      'tbl:overview': 'peer_assessment',
      'sim:simulation': 'simulation',
      'builder:template': 'builder'
    }
  };

  exports['default'] = ___DefaultExportObject___;;

});