define('client/components/thinkspace/common/invitation/row', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'thinkspace-base/components/base'], function (exports, ember, ns, ajax, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: 'tr',
    roles: ['read', 'update', 'owner'],
    actions: {
      destroy: function() {
        return this.get('model').destroyRecord();
      },
      resend: function() {
        var model, query;
        model = this.get('model');
        query = {
          model: model,
          id: model.get('id'),
          action: 'resend',
          verb: 'put'
        };
        return ajax['default'].object(query).then((function(_this) {
          return function(payload) {
            model.store.pushPayload(ns['default'].to_p('invitation'), payload);
            return _this.totem_messages.api_success({
              source: _this,
              model: model,
              action: 'update',
              i18n_path: ns['default'].to_o('invitation', 'save')
            });
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});