define('client/thinkspace/casespace/case_manager/team_sets/edit/controller', ['exports', 'ember', 'thinkspace-casespace-case-manager/controllers/base', 'totem/ns'], function (exports, ember, base, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    c_team_set_edit: ns['default'].to_p('case_manager', 'team_set', 'edit')
  });

  exports['default'] = ___DefaultExportObject___;;

});