define('client/components/thinkspace/casespace/ownerable/bar/gradebook/phase/state', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    domain_phase_states: [
      {
        state: 'unlocked',
        title: 'Unlocked',
        description: 'The learner can access the phase and modify their responses.'
      }, {
        state: 'locked',
        title: 'Locked',
        description: 'The learner cannot access this phase at all.'
      }, {
        state: 'completed',
        title: 'Completed',
        description: 'The learner can view the phase, but not modify any responses.'
      }
    ],
    actions: {
      change: function(state) {
        return this.sendAction('change', state);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});