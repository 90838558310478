define('client/models/thinkspace/artifact/bucket', ['exports', 'ember', 'totem/ds/associations', 'client/models/thinkspace/common/componentable'], function (exports, ember, ta, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(ta['default'].add(ta['default'].polymorphic('authable'), ta['default'].has_many('artifact:files', {
    reads: {
      sort: 'title',
      filter: true,
      notify: true
    }
  })), {
    user_id: ta['default'].attr('number'),
    instructions: ta['default'].attr('string'),
    authable_type: ta['default'].attr('string'),
    authable_id: ta['default'].attr('number'),
    has_instructions: ember['default'].computed.notEmpty('instructions'),
    edit_component: ta['default'].to_p('bucket', 'edit')
  });

  exports['default'] = ___DefaultExportObject___;;

});