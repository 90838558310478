define('client/components/thinkspace/input_element/preprocessors/carry_forward', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'totem/scope'], function (exports, ember, ns, ajax, totem_scope) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Object.extend({
    process: function(componentable, template) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (!(componentable && template)) {
            return resolve(template);
          }
          return _this.process_carry_forward_tags(componentable, template).then(function(template) {
            return _this.process_carry_forward_image_tags(componentable, template).then(function(template) {
              return resolve(template);
            });
          });
        };
      })(this));
    },
    process_carry_forward_tags: function(componentable, template) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var $content, $tags, names, type;
          $content = ember['default'].$('<div/>').html(template);
          $tags = $content.find('thinkspace[type=carry_forward]');
          if ($tags.length < 1) {
            return resolve(template);
          }
          type = ns['default'].to_p('response');
          names = ($tags.map(function(index, tag) {
            return $(tag).attr('name');
          })).toArray();
          return _this.get_carry_forward_responses_from_server(names).then(function(payload) {
            var c_path, name_map, norm_responses, response_json, responses;
            response_json = payload[ns['default'].to_p('responses')];
            norm_responses = componentable.store.normalize(type, response_json);
            responses = componentable.store.pushMany(type, norm_responses);
            c_path = ns['default'].to_p('input_element', 'elements', 'carry_forward');
            name_map = payload['element_map'] || {};
            $tags.each(function(index, tag) {
              var $tag, comp_name, hash, html, ids, name, tag_responses, value_path;
              $tag = $(tag);
              name = $tag.attr('name');
              comp_name = $tag.attr('title') || 'standard';
              ids = name_map[name] || [];
              tag_responses = responses.filter(function(response) {
                return ids.contains(parseInt(response.get('id')));
              });
              hash = {
                component: c_path + ("/" + comp_name),
                responses: tag_responses,
                element_name: name
              };
              value_path = _this.tvo.value.set_value(hash);
              html = '{{ component';
              html += " tvo." + value_path + ".component";
              html += " responses=tvo." + value_path + ".responses";
              html += " element_name=tvo." + value_path + ".element_name";
              html += ' }}';
              return $tag.replaceWith(html);
            });
            return resolve($content.html());
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    },
    get_carry_forward_responses_from_server: function(names) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          query = {
            verb: 'post',
            action: 'carry_forward',
            model: ns['default'].to_p('response'),
            data: {
              element_names: names
            }
          };
          totem_scope['default'].add_ownerable_to_query(query.data);
          totem_scope['default'].add_authable_to_query(query.data);
          return ajax['default'].object(query).then(function(payload) {
            return resolve(payload);
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    },
    process_carry_forward_image_tags: function(componentable, template) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var $content, $tags;
          $content = ember['default'].$('<div/>').html(template);
          $tags = $content.find('thinkspace[type=carry_forward_image]');
          if ($tags.length < 1) {
            return resolve(template);
          }
          $tags.each(function(index, tag) {
            var $tag, component, file_type, hash, html, tag_attrs, value_path;
            $tag = $(tag);
            file_type = $tag.attr('file_type') || 'image';
            component = ns['default'].to_p('artifact', 'bucket', 'file', file_type, 'carry_forward', 'wrapper');
            tag_attrs = _this.get_tag_attributes(tag);
            hash = {
              component: component,
              tag_attrs: tag_attrs
            };
            value_path = _this.tvo.value.set_value(hash);
            html = '{{ component';
            html += " tvo." + value_path + ".component";
            html += " tag_attrs=tvo." + value_path + ".tag_attrs";
            html += ' }}';
            return $tag.replaceWith(html);
          });
          return resolve($content.html());
        };
      })(this));
    },
    get_tag_attributes: function(tag) {
      var attrs, i, j, lasti, node_map, ref;
      node_map = tag.attributes;
      lasti = node_map.length - 1;
      if (!(lasti >= 0)) {
        return {};
      }
      attrs = {};
      for (i = j = 0, ref = lasti; 0 <= ref ? j <= ref : j >= ref; i = 0 <= ref ? ++j : --j) {
        attrs[node_map[i].name] = node_map[i].value;
      }
      return attrs;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});