define('client/thinkspace/casespace/route', ['exports', 'ember', 'totem/ns', 'thinkspace-base/base/route'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    thinkspace: ember['default'].inject.service(),
    casespace: ember['default'].inject.service(),
    phase_manager: ember['default'].inject.service(),
    csse: ember['default'].inject.service(ns['default'].to_p('casespace', 'server_events')),
    beforeModel: function(transition) {
      var thinkspace;
      thinkspace = this.get('thinkspace');
      if (!thinkspace.transition_is_for(transition, 'case_manager')) {
        thinkspace.set_current_transition(transition);
      }
      return this._super(transition);
    },
    deactivate: function() {
      console.warn('deactivate casespace route');
      this.get('casespace').reset_all();
      this.get('phase_manager').reset_all();
      return this.get('csse').reset_all();
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});