define('client/components/thinkspace/readiness_assurance/admin/shared/timer/interval', ['exports', 'ember', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    selected_id: null,
    interval_buttons: null,
    time_change: ember['default'].observer('time_at', function() {
      return this.set_interval_buttons();
    }),
    willInsertElement: function() {
      return this.set_interval_buttons();
    },
    actions: {
      select: function(id) {
        this.set('selected_id', id);
        this.sendAction('select', id);
        return this.set_interval_buttons();
      }
    },
    set_interval_buttons: function() {
      var buttons, i, id, j, label, max, mins, ref;
      max = this.get('intervals') || 5;
      mins = this.am.minutes_from_now(this.time_at);
      if (mins <= 0) {
        this.set('interval_buttons', null);
        this.set('selected_id', null);
        this.sendAction('clear');
        return;
      }
      if (mins > max) {
        mins = max;
      }
      buttons = [];
      for (i = j = 1, ref = mins; 1 <= ref ? j <= ref : j >= ref; i = 1 <= ref ? ++j : --j) {
        label = this.am.pluralize(i, 'minute');
        buttons.push({
          id: i,
          label: i + " " + label
        });
      }
      if (buttons.length === 1) {
        id = buttons[0].id;
        this.set('selected_id', id);
        this.sendAction('select', id);
      } else {
        if (this.selected_id && this.selected_id > mins) {
          this.set('selected_id', null);
          this.sendAction('select', null);
        }
      }
      return this.set('interval_buttons', buttons);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});