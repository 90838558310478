define('client/thinkspace/casespace/assignments/reports/show/controller', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Controller.extend({
    token: null,
    c_reporter_report: ns['default'].to_p('reporter', 'report')
  });

  exports['default'] = ___DefaultExportObject___;;

});