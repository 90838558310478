define('client/components/thinkspace/lab/builder/lab/result/form/correctable', ['exports', 'ember', 'totem/ns', 'client/components/thinkspace/lab/builder/lab/result/form/base', 'totem/mixins/validations'], function (exports, ember, ns, base, val_mixin) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(val_mixin['default'], {
    max_attempts: null,
    correct_value: null,
    error_messages: null,
    init_values: function() {
      var correct, max, ref;
      ref = this.get_model_value_path(), correct = ref[0], max = ref[1];
      this.set('correct_value', correct);
      return this.set('max_attempts', max);
    },
    get_display_value: function() {
      var correct, max, ref;
      ref = this.get_model_value_path(), correct = ref[0], max = ref[1];
      if (ember['default'].isBlank(correct)) {
        correct = ['click to add'];
      }
      return "(" + correct + ") (" + max + ")";
    },
    form_valid: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (!_this.get('is_valid')) {
            return reject();
          }
          return _this.validate_correct_value().then(function(correct) {
            return _this.validate_max_attempts().then(function(max_attempts) {
              _this.set_model_value_path([correct, max_attempts]);
              return resolve();
            }, function(error_messages) {
              _this.set_error_messages(error_messages);
              return reject();
            });
          }, function(error_messages) {
            _this.set_error_messages(error_messages);
            return reject();
          });
        };
      })(this));
    },
    validate_correct_value: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var correct;
          correct = _this.get('correct_value') || '';
          correct = correct.trim();
          return resolve(correct);
        };
      })(this));
    },
    validate_max_attempts: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var max;
          max = _this.get('max_attempts') || 0;
          if (!('' + max).match(/^\d+$/)) {
            return reject(_this.t('builder.lab.admin.form.correctable.errors.max_attempts'));
          }
          return resolve(parseInt(max));
        };
      })(this));
    },
    set_error_messages: function(messages) {
      return this.set('error_messages', ember['default'].makeArray(messages));
    },
    validations: {
      correct_value: {
        presence: true,
        numericality: {
          greaterThanOrEqualTo: 0
        }
      },
      max_attempts: {
        presence: true,
        numericality: {
          onlyInteger: true,
          greaterThanOrEqualTo: 0
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});