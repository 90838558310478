define('client/templates/thinkspace/team/teams/new', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("li");
          var el2 = dom.createElement("label");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline, content = hooks.content;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element0 = dom.childAt(fragment, [0, 0]);
          var morph0 = dom.createMorphAt(element0,0,0);
          var morph1 = dom.createMorphAt(element0,1,1);
          inline(env, morph0, context, "radio-button", [], {"value": get(env, context, "category.id"), "checked": get(env, context, "category_id")});
          content(env, morph1, context, "category.title");
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ts-grid_row");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","small-12 ts-grid_columns");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","form");
        var el4 = dom.createElement("fieldset");
        var el5 = dom.createElement("legend");
        var el6 = dom.createTextNode("Team Information");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-grid_row");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","small-12 ts-grid_columns");
        var el7 = dom.createElement("h6");
        var el8 = dom.createTextNode("Title (required)");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-grid_row");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","small-12 ts-grid_columns");
        var el7 = dom.createElement("h6");
        var el8 = dom.createTextNode("Team Category (required)");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("ul");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-grid_row");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","small-12 ts-grid_columns");
        var el7 = dom.createElement("h6");
        var el8 = dom.createTextNode("Description");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-grid_row");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","small-2 ts-grid_columns");
        var el7 = dom.createElement("button");
        dom.setAttribute(el7,"class","btn btn-primary btn-small");
        var el8 = dom.createTextNode("Save");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","small-2 ts-grid_columns");
        var el7 = dom.createElement("button");
        dom.setAttribute(el7,"class","btn btn-primary btn-small");
        var el8 = dom.createTextNode("Cancel");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","small-8 ts-grid_columns");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, inline = hooks.inline, get = hooks.get, block = hooks.block, element = hooks.element;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element1 = dom.childAt(fragment, [0, 0, 0, 0]);
        var element2 = dom.childAt(element1, [5]);
        var element3 = dom.childAt(element2, [0, 0]);
        var element4 = dom.childAt(element2, [1, 0]);
        var morph0 = dom.createMorphAt(element1,1,1);
        var morph1 = dom.createMorphAt(dom.childAt(element1, [2, 0]),1,1);
        var morph2 = dom.createMorphAt(dom.childAt(element1, [3, 0, 1]),0,0);
        var morph3 = dom.createMorphAt(dom.childAt(element1, [4, 0]),1,1);
        inline(env, morph0, context, "partial", ["thinkspace/team/shared/validation_errors"], {});
        inline(env, morph1, context, "input", [], {"value": get(env, context, "title")});
        block(env, morph2, context, "each", [get(env, context, "team_categories_sorted")], {"keyword": "category"}, child0, null);
        inline(env, morph3, context, "textarea", [], {"value": get(env, context, "description"), "rows": "2"});
        element(env, element3, context, "bind-attr", [], {"disabled": get(env, context, "view.save_disabled")});
        element(env, element3, context, "action", ["save"], {"on": "click"});
        element(env, element4, context, "action", ["cancel"], {"on": "click"});
        return fragment;
      }
    };
  }()));

});