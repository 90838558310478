define('client/components/thinkspace/readiness_assurance/admin/trat/teams/show', ['exports', 'ember', 'thinkspace-readiness-assurance/mixins/data_rows', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, m_data_rows, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(m_data_rows['default'], {
    show_team_members: false,
    columns_per_row: 5,
    team_member_rows: null,
    actions: {
      toggle_team_members: function() {
        this.toggleProperty('show_team_members');
      }
    },
    willInsertElement: function() {
      return this.am.get_trat_team_users().then((function(_this) {
        return function(teams) {
          _this.set('team_member_rows', _this.get_data_rows(teams));
          return _this.set_ready_on();
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});