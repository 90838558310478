define('client/components/thinkspace/readiness_assurance/shared/radio/button', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    button_id: ember['default'].computed.reads('choice.id'),
    button_label: ember['default'].computed.reads('choice.label'),
    buttons_disabled: ember['default'].computed.or('qm.readonly', 'qm.answers_disabled'),
    get_answer_id: function() {
      return this.get('qm.answer_id');
    },
    get_button_id: function() {
      return this.get('button_id');
    },
    is_selected: ember['default'].computed('qm.answer_id', function() {
      var aid, bid;
      aid = this.get_answer_id();
      bid = this.get_button_id();
      return bid && bid === aid;
    }),
    actions: {
      select: function() {
        if (this.get('buttons_disabled')) {
          return;
        }
        return this.sendAction('select', this.get_button_id());
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});