define('client/components/thinkspace/diagnostic_path_viewer/path_item/show', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: 'li',
    classNames: ['diag-path_list-item'],
    classNameBindings: ['model.is_mechanism:diag-path_list-item-mechanism'],
    c_path_item: ns['default'].to_p('diagnostic_path_viewer', 'path_item', 'show'),
    overflown_selector: '.diag-path_list-item-value',
    check_overflow: ember['default'].observer('model.itemable.value', function() {
      return ember['default'].run.next((function(_this) {
        return function() {
          return _this.set_overflown();
        };
      })(this));
    }),
    collapsed_change: ember['default'].observer('all_collapsed', function() {
      return this.set('is_collapsed', this.get('all_collapsed'));
    }),
    is_collapsed: false,
    is_expanded: false,
    is_overflown: false,
    set_overflown: function() {
      var $value, element, selector;
      selector = this.get('overflown_selector');
      $value = this.$(selector);
      if (!ember['default'].isPresent($value)) {
        return;
      }
      element = $value[0];
      if (!element) {
        return;
      }
      return this.set('is_overflown', element.scrollWidth > element.clientWidth);
    },
    actions: {
      toggle_collapse: function() {
        this.toggleProperty('is_collapsed');
      },
      toggle_expand: function() {
        this.toggleProperty('is_expanded');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});