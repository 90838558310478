define('client/controllers/users/sign_in', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Controller.extend({
    authenticator: 'authenticator:totem',
    has_errors: ember['default'].computed.reads('totem_messages.error_present'),
    error_messages: ember['default'].computed.reads('totem_messages.message_queue'),
    actions: {
      authenticate: function() {
        var data;
        data = this.getProperties('identification', 'password');
        this.set('password', null);
        return this.get('session').authenticate(this.get('authenticator'), data).then((function(_this) {
          return function() {
            return _this.totem_messages.info(data.identification + " sign in successful.");
          };
        })(this), (function(_this) {
          return function(error) {
            var message;
            message = error.responseText || 'Invalid credentials.';
            return _this.totem_messages.error(message);
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});