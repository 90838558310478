define('client/components/thinkspace/readiness_assurance/admin/shared/teams/select', ['exports', 'ember', 'totem/ns', 'thinkspace-readiness-assurance/mixins/data_rows', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, ns, m_data_rows, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(m_data_rows['default'], {
    show_all: false,
    team_member_rows: null,
    columns_per_row: ember['default'].computed(function() {
      return (ember['default'].isPresent(this.rad.width_selector) && this.rad.width_selector) || 1;
    }),
    init: function() {
      this._super();
      return this.validate = this.rad.validate;
    },
    willInsertElement: function() {
      return this.setup();
    },
    setup: function() {
      var team_users;
      this.teams = this.rad.get_teams() || [];
      this.set('show_all', this.rad.get_show_all());
      if (this.rad.select_all_teams()) {
        this.send('select_all');
      }
      team_users = this.rad.get_team_users();
      return this.set('team_member_rows', this.get_data_rows(team_users));
    },
    actions: {
      show_all: function() {
        return this.set('show_all', true);
      },
      hide_all: function() {
        return this.set('show_all', false);
      },
      select_all: function() {
        var i, len, ref, team;
        this.teams.clear();
        ref = this.rad.get_team_users();
        for (i = 0, len = ref.length; i < len; i++) {
          team = ref[i];
          this.teams.pushObject(team.team);
        }
        return this.set_teams();
      },
      deselect_all: function() {
        this.teams.clear();
        return this.set_teams();
      },
      select: function(team) {
        var index;
        index = this.teams.indexOf(team);
        if (index >= 0) {
          this.teams.removeAt(index);
        } else {
          this.teams.pushObject(team);
        }
        return this.set_teams();
      }
    },
    set_teams: function() {
      this.rad.set_teams(this.teams);
      if (this.validate) {
        return this.sendAction('validate');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});