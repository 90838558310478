define('client/components/thinkspace/casespace/case_manager/team_set/show', ['exports', 'ember', 'ember-data', 'totem/ns', 'totem/ajax', 'thinkspace-base/components/base'], function (exports, ember, ds, ns, ajax, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    unassigned_users: ember['default'].computed.reads('team_manager.current_unassigned_users'),
    selected_user: null,
    all_data_loaded: false,
    c_space_header: ns['default'].to_p('space', 'header'),
    c_team_snapshot: ns['default'].to_p('case_manager', 'team', 'snapshot'),
    c_user_avatar: ns['default'].to_p('common', 'user', 'avatar'),
    c_loader: ns['default'].to_p('common', 'shared', 'loader'),
    r_teams_new: ns['default'].to_r('case_manager', 'teams', 'new'),
    r_team_sets: ns['default'].to_r('case_manager', 'team_sets', 'index'),
    r_team_sets_edit: ns['default'].to_r('case_manager', 'team_sets', 'edit'),
    team_manager: ember['default'].inject.service(),
    init: function() {
      this._super();
      return this.get_team_users().then((function(_this) {
        return function() {
          return _this.set('all_data_loaded', true);
        };
      })(this));
    },
    get_team_users: function() {
      return this.get('team_manager').get_teams_for_team_set(this.get('model'));
    },
    actions: {
      select_user: function(user) {
        return this.set('selected_user', user);
      },
      deselect_user: function() {
        return this.set('selected_user', null);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});