define('client/services/builder', ['exports', 'ember', 'totem/ns', 'totem/util', 'totem/cache', 'totem-messages/messages', 'totem/scope', 'thinkspace-builder/steps/details', 'thinkspace-builder/steps/templates', 'thinkspace-builder/steps/phases', 'thinkspace-builder/steps/logistics', 'thinkspace-builder/steps/overview'], function (exports, ember, ns, util, tc, totem_messages, totem_scope, step_details, step_templates, step_phases, step_logistics, step_overview) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Service.extend({
    model: null,
    steps: null,
    components_map: null,
    values: null,
    is_saving: false,
    toolbar_action_handler: null,
    has_toolbar: false,
    c_step: ember['default'].computed('step', function() {
      return this.get('step.component_path');
    }),
    c_builder_toolbar: null,
    reset: function(route) {
      if (route == null) {
        route = null;
      }
      console.warn("[builder] Resetting...", this);
      this.initialize_values();
      this.initialize_maps();
      this.initialize_steps();
      if (ember['default'].isPresent(route)) {
        return this.set('route', route);
      }
    },
    initialize_maps: function() {
      return this.set('components_map', ember['default'].Map.create());
    },
    get_components_map: function() {
      return this.get('components_map');
    },
    initialize_values: function() {
      return this.set('values', ember['default'].Object.create());
    },
    set_value: function(property, value) {
      return this.set("values." + property, value);
    },
    get_value: function(property) {
      return this.get("values." + property);
    },
    initialize_steps: function() {
      return this.set('steps', [
        step_details['default'].create({
          container: this.container
        }), step_templates['default'].create({
          container: this.container
        }), step_phases['default'].create({
          container: this.container
        }), step_logistics['default'].create({
          container: this.container
        }), step_overview['default'].create({
          container: this.container
        })
      ]);
    },
    register_step_component: function(component) {
      var step;
      step = this.get('step');
      return this.get_components_map().set(step, component);
    },
    set_current_step_from_id: function(id) {
      var step, steps;
      steps = this.get('steps');
      step = steps.findBy('id', id);
      if (!ember['default'].isPresent(step)) {
        console.warn("[builder] Could not find step for id: [" + id + "]");
      }
      return this.set('step', step);
    },
    set_current_step: function(step) {
      return this.set_current_step_from_id(step.id);
    },
    set_current_step_and_transition: function(step) {
      this.set_current_step(step);
      return this.transition_to_step(step);
    },
    get_space: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model, model_type;
          model = _this.get('model');
          model_type = totem_scope['default'].standard_record_path(model);
          switch (model_type) {
            case ns['default'].to_p('assignment'):
              return model.get(ns['default'].to_p('space')).then(function(space) {
                return resolve(space);
              }, function(error) {
                return _this.error(error);
              });
            case ns['default'].to_p('phase'):
              return model.get(ns['default'].to_p('assignment')).then(function(assignment) {
                return assignment.get(ns['default'].to_p('space')).then(function(space) {
                  return resolve(space);
                }, function(error) {
                  return _this.error(error);
                });
              }, function(error) {
                return _this.error(error);
              });
          }
        };
      })(this));
    },
    get_assignment: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model, model_type;
          model = _this.get('model');
          model_type = totem_scope['default'].standard_record_path(model);
          switch (model_type) {
            case ns['default'].to_p('assignment'):
              return resolve(model);
            case ns['default'].to_p('phase'):
              return model.get(ns['default'].to_p('assignment')).then(function(assignment) {
                return resolve(assignment);
              }, function(error) {
                return _this.error(error);
              });
          }
        };
      })(this));
    },
    get_roster: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get_space().then(function(space) {
            var query;
            query = {
              id: space.get('id'),
              action: 'roster'
            };
            return tc['default'].query(ns['default'].to_p('space'), query, {
              payload_type: ns['default'].to_p('user')
            }).then(function(users) {
              return resolve(users);
            }, function(error) {
              return _this.error(error);
            });
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    warn: function(message) {
      return console.warn(message);
    },
    set_is_saving: function() {
      this.set('is_saving', true);
      return totem_messages['default'].show_loading_outlet();
    },
    reset_is_saving: function() {
      this.set('is_saving', false);
      return totem_messages['default'].hide_loading_outlet();
    },
    transition_to_next_step: function() {
      var next_step;
      next_step = this.get_step_from_offset(1);
      if (!ember['default'].isPresent(next_step)) {
        this.warn("[builder] Cannot transition without a valid next step.");
      }
      if (next_step.route_path == null) {
        this.warn("[builder' Cannot transition without a route_path defined on the step [" + step.id + "]");
      }
      return this.transition_to_step(next_step);
    },
    transition_to_previous_step: function() {
      var prev_step;
      prev_step = this.get_step_from_offset(-1);
      if (!ember['default'].isPresent(prev_step)) {
        this.warn("[builder] Cannot transition without a valid next step.");
      }
      if (prev_step.route_path == null) {
        this.warn("[builder' Cannot transition without a route_path defined on the step [" + step.id + "]");
      }
      return this.transition_to_step(prev_step);
    },
    transition_to_step: function(step) {
      var model, model_type, route;
      route = this.get('route');
      model = this.get('model');
      if (!ember['default'].isPresent(route)) {
        this.warn("[builder] Cannot transition without a valid route set.");
      }
      if (!ember['default'].isPresent(model)) {
        this.warn("[builder] Cannot transition without a valid model.");
      }
      model_type = totem_scope['default'].standard_record_path(model);
      switch (model_type) {
        case ns['default'].to_p('assignment'):
          return route.transitionTo(step.route_path, model);
        case ns['default'].to_p('phase'):
          return model.get(ns['default'].to_p('assignment')).then((function(_this) {
            return function(assignment) {
              return route.transitionTo(step.route_path, assignment);
            };
          })(this));
      }
    },
    transition_to_assignment: function() {
      var route;
      route = this.get('route');
      if (!ember['default'].isPresent(route)) {
        this.warn("[builder] Cannot transition without a valid route set.");
      }
      return this.get_assignment().then((function(_this) {
        return function(assignment) {
          return route.transitionTo(ns['default'].to_r('casespace', 'assignments', 'show'), assignment);
        };
      })(this));
    },
    transition_to_phases_edit: function(phase) {
      var route;
      route = this.get('route');
      if (!ember['default'].isPresent(route)) {
        this.warn("[builder] Cannot transition without a valid route set.");
      }
      return route.transitionTo(ns['default'].to_r('builder', 'phases', 'edit'), phase);
    },
    get_step_from_offset: function(offset) {
      var index, new_step, step, steps;
      step = this.get('step');
      steps = this.get('steps');
      index = steps.indexOf(step);
      new_step = steps.objectAt(index + offset);
      return new_step;
    },
    set_model: function(model) {
      this.set('model', model);
      return this.reset_toolbar();
    },
    get_model: function() {
      return this.get('model');
    },
    set_toolbar: function(action_handler, path) {
      return ember['default'].run.next((function(_this) {
        return function() {
          _this.set('c_builder_toolbar', path);
          _this.set('toolbar_action_handler', action_handler);
          return _this.set('has_toolbar', true);
        };
      })(this));
    },
    reset_toolbar: function() {
      this.set('has_toolbar', false);
      return ember['default'].run.next((function(_this) {
        return function() {
          _this.set('toolbar_action_handler', null);
          return _this.set('c_builder_toolbar', null);
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});