define('client/models/thinkspace/team/team', ['exports', 'ember', 'totem/ds/associations', 'totem/util', 'totem/ns'], function (exports, ember, ta, util, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].polymorphic('authable'), ta['default'].belongs_to('team_category', {
    reads: {}
  }), ta['default'].belongs_to('team_set', {
    reads: {}
  }), ta['default'].has_many('team_users', {
    reads: {}
  }), ta['default'].has_many('team_teamables'), ta['default'].has_many('team_viewers'), ta['default'].has_many('users', {
    reads: {
      sort: 'sort_name:asc'
    }
  })), {
    title: ta['default'].attr('string'),
    description: ta['default'].attr('string'),
    color: ta['default'].attr('string'),
    is_member: ta['default'].attr('boolean'),
    authable_type: ta['default'].attr('string'),
    authable_id: ta['default'].attr('number'),
    state: ta['default'].attr('string'),
    updates: ta['default'].attr(),
    full_name: ember['default'].computed.reads('title'),
    is_locked: ember['default'].computed.equal('state', 'locked'),
    team_manager: ember['default'].inject.service(),
    didLoad: function() {
      return this.get('team_set').then((function(_this) {
        return function(team_set) {
          if (!ember['default'].isPresent(team_set)) {
            return;
          }
          return team_set.get('teams').then(function(teams) {
            if (!teams.contains(_this)) {
              return teams.pushObject(_this);
            }
          });
        };
      })(this));
    },
    didCreate: function() {
      return this.didLoad();
    },
    reset_all: function() {
      return this.reset_updates();
    },
    reset_updates: function() {
      return this.set('updates', {});
    },
    add_user: function(user) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          _this.change_users([user], 'add');
          return _this.save().then(function() {
            _this.reset_updates();
            _this.get('team_manager').update_unassigned_users();
            return resolve();
          });
        };
      })(this));
    },
    remove_user: function(user) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          _this.change_users([user], 'remove');
          return _this.save().then(function() {
            _this.reset_updates();
            _this.get('team_manager').update_unassigned_users();
            return resolve();
          });
        };
      })(this));
    },
    add_users: function(users, save) {
      if (save == null) {
        save = false;
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          _this.change_users(users, 'add');
          if (!save) {
            return resolve();
          }
          return _this.save().then(function() {
            _this.reset_updates();
            _this.get('team_manager').update_unassigned_users();
            return resolve();
          });
        };
      })(this));
    },
    remove_users: function(users, save) {
      if (save == null) {
        save = false;
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          _this.change_users(users, 'remove');
          if (!save) {
            return resolve();
          }
          return _this.save().then(function() {
            _this.reset_updates();
            _this.get('team_manager').update_unassigned_users();
            return resolve();
          });
        };
      })(this));
    },
    unload_team_users: function(user_ids) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var records;
          records = _this.store.filter(ns['default'].to_p('team_user'), function(team_user) {
            var team_id, user_id;
            team_id = team_user.get('team_id');
            user_id = team_user.get('user_id');
            if (team_id !== parseInt(_this.get('id'))) {
              return false;
            }
            return user_ids.contains(user_id);
          });
          return records.then(function(records) {
            records.forEach(function(record) {
              return _this.store.unloadRecord(record);
            });
            return resolve();
          });
        };
      })(this));
    },
    change_users: function(users, type) {
      var ids, key, sub_key, updates;
      sub_key = "users." + type;
      key = "updates." + sub_key;
      ids = util['default'].string_array_to_numbers(users.mapBy('id'));
      updates = {};
      updates.users = this.get('updates.users') || {};
      updates.users.add = this.get('updates.users.add') || [];
      updates.users.remove = this.get('updates.users.remove') || [];
      switch (type) {
        case 'remove':
          ids.forEach((function(_this) {
            return function(id) {
              if (!updates.users.remove.contains(id)) {
                return updates.users.remove.pushObject(id);
              }
            };
          })(this));
          break;
        case 'add':
          ids.forEach((function(_this) {
            return function(id) {
              if (!updates.users.add.contains(id)) {
                return updates.users.add.pushObject(id);
              }
            };
          })(this));
      }
      return this.set('updates', updates);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});