define('client/initializers/thinkspace/casespace/register', ['exports', 'thinkspace-casespace/generic_container'], function (exports, gc) {

  'use strict';

  var ___DefaultExportObject___, initializer;

  initializer = {
    name: 'thinkspace-casespace-register',
    initialize: function(container, app) {
      return app.register('view:template_manager_view_container', gc['default']);
    }
  };

  ___DefaultExportObject___ = initializer;

  exports['default'] = ___DefaultExportObject___;;

});