define('client/thinkspace/common/spaces/route', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin', 'thinkspace-base/base/route'], function (exports, ember, auth_mixin, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(auth_mixin['default'], {
    thinkspace: ember['default'].inject.service(),
    titleToken: 'Spaces',
    activate: function() {
      return this.get('thinkspace').disable_wizard_mode();
    },
    model: function() {
      var controller;
      controller = this.controllerFor(this.ns.to_p('spaces'));
      if (controller.get('all_spaces')) {
        return;
      }
      return this.store.find(this.ns.to_p('space')).then((function(_this) {
        return function(spaces) {
          controller.set('all_spaces', true);
          return _this.totem_messages.api_success({
            model: spaces
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.totem_messages.api_failure(error, {
            source: _this,
            model: _this.ns.to_p('space')
          });
        };
      })(this));
    },
    renderTemplate: function() {
      return this.render();
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});