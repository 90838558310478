define('client/initializers/totem_simple_auth_current_user', ['exports', 'ember', 'totem/ns', 'simple-auth/session', 'totem-messages/messages'], function (exports, ember, ns, session, totem_messages) {

  'use strict';

  var ___DefaultExportObject___, initializer;

  initializer = {
    name: 'totem-simple-auth-current-user',
    before: ['simple-auth'],
    initialize: function(container, app) {
      return session['default'].reopen({
        totem_scope: ember['default'].inject.service(),
        user: ember['default'].computed.reads('totem_scope.current_user'),
        is_original_user: ember['default'].computed.bool('secure.original_user'),
        can_switch_user: ember['default'].computed.bool('secure.switch_user')
      });
    }
  };

  ___DefaultExportObject___ = initializer;

  exports['default'] = ___DefaultExportObject___;;

});