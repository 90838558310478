define('client/services/thinkspace/casespace/messages', ['exports', 'ember', 'totem/ns', 'totem-messages/mixins/services/messages/base'], function (exports, ember, ns, m_base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Service.extend(m_base['default'], {
    message_model_type: ns['default'].to_p('casespace', 'message'),
    message_load_url: 'thinkspace/pub_sub/server_events/load_messages'
  });

  exports['default'] = ___DefaultExportObject___;;

});