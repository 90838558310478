define('client/components/thinkspace/casespace/case_manager/team/new', ['exports', 'ember', 'totem/ds/associations', 'totem/ns', 'totem/ajax', 'totem/util', 'totem/scope', 'client/components/thinkspace/casespace/case_manager/team/form'], function (exports, ember, ta, ns, ajax, util, totem_scope, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    title: null,
    color: null,
    is_new: true,
    css_style: ember['default'].computed('color', function() {
      var color, css;
      color = this.get('color');
      if (!ember['default'].isPresent(color)) {
        return '';
      }
      css = '';
      css += "background-color: #" + color + ";";
      return new ember['default'].Handlebars.SafeString(css);
    }),
    get_model: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var team;
          team = _this.get_store().createRecord(ns['default'].to_p('team'), {
            authable_type: totem_scope['default'].standard_record_path(_this.get('space')),
            authable_id: parseInt(_this.get('space.id')),
            'thinkspace/team/team_set': _this.get('team_set')
          });
          _this.set('model', team);
          return resolve(team);
        };
      })(this));
    },
    transition_from_save: function(team) {
      return this.get('team_manager').transition_to_team_set_show(team);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});