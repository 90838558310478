define('client/templates/components/thinkspace/indented_list/list/layout/diagnostic_path', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, null);
          dom.insertBoundary(fragment, 0);
          inline(env, morph0, context, "component", [get(env, context, "c_response_item_show")], {"response_manager": get(env, context, "response_manager"), "item": get(env, context, "item")});
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ts-grid_row");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","ts-grid_columns ts-grid_columns-thick small-12");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","indented-list_action-container");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","indented-list_list-container");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","gu-list-end");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, inline = hooks.inline, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [0, 0]);
        var element1 = dom.childAt(element0, [2]);
        var morph0 = dom.createMorphAt(element0,0,0);
        var morph1 = dom.createMorphAt(element0,1,1);
        var morph2 = dom.createMorphAt(element1,0,0);
        var morph3 = dom.createMorphAt(element1,1,1);
        var morph4 = dom.createMorphAt(fragment,1,1,contextualElement);
        var morph5 = dom.createMorphAt(fragment,2,2,contextualElement);
        var morph6 = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
        inline(env, morph0, context, "component", [get(env, context, "c_list_header")], {"model": get(env, context, "model"), "list": get(env, context, "list")});
        inline(env, morph1, context, "component", [get(env, context, "c_list_all_visible")], {"response_manager": get(env, context, "response_manager")});
        inline(env, morph2, context, "component", [get(env, context, "c_list_new_top")], {"response_manager": get(env, context, "response_manager")});
        inline(env, morph3, context, "component", [get(env, context, "c_list_new_bottom")], {"response_manager": get(env, context, "response_manager")});
        inline(env, morph4, context, "component", [get(env, context, "c_list_source_observation")], {"response_manager": get(env, context, "response_manager")});
        inline(env, morph5, context, "component", [get(env, context, "c_list_source_mechanism")], {"response_manager": get(env, context, "response_manager")});
        block(env, morph6, context, "each", [get(env, context, "response_manager.value_items")], {"keyword": "item"}, child0, null);
        return fragment;
      }
    };
  }()));

});