define('client/components/thinkspace/readiness_assurance/irat', ['exports', 'ember', 'thinkspace-readiness-assurance/base/ra_component'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    willInsertElement: function() {
      this.ra.tracker();
      return this.ra.load_messages();
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});