define('client/models/thinkspace/team/team_viewer', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].polymorphic('viewerable'), ta['default'].belongs_to('team')), {
    team_id: ta['default'].attr('number'),
    viewerable_type: ta['default'].attr('string'),
    viewerable_id: ta['default'].attr('number'),
    didCreate: function() {
      return this.didLoad();
    },
    didLoad: function() {
      return this.get(ta['default'].to_p('team')).then((function(_this) {
        return function(team) {
          return team.get(ta['default'].to_p('team_viewers')).then(function(team_viewers) {
            if (!team_viewers.contains(_this)) {
              return team_viewers.pushObject(_this);
            }
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});