define('client/components/thinkspace/lab/builder/lab/result/form/input', ['exports', 'ember', 'totem/ns', 'client/components/thinkspace/lab/builder/lab/result/form/base', 'totem/mixins/validations'], function (exports, ember, ns, base, val_mixin) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(val_mixin['default'], {
    column_value: null,
    init_values: function() {
      return this.set('column_value', this.get_model_value_path());
    },
    get_display_value: function() {
      return this.get_model_value_path();
    },
    form_valid: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (!_this.get('is_valid')) {
            return reject();
          }
          _this.set_model_value_path(_this.get('column_value'));
          return resolve();
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});