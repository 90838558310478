define('client/helpers/date-picker', ['exports', 'ember', 'client/views/date_picker/date'], function (exports, ember, view) {

	'use strict';

	var ___DefaultExportObject___;

	___DefaultExportObject___ = ember['default'].Handlebars.makeViewHelper(view['default']);

	exports['default'] = ___DefaultExportObject___;;

});