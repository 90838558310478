define('client/templates/components/thinkspace/markup/library/edit/manager', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","ts-markup-manager_comment-wrapper");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","ts-markup-manager_comment-col");
          var el3 = dom.createElement("h6");
          var el4 = dom.createTextNode("New Comment");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","btn-cluster left-cluster");
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","btn btn-primary btn-small");
          var el5 = dom.createTextNode("Add Comment");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","btn btn-default btn-small");
          var el5 = dom.createTextNode("Cancel");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","ts-markup-manager_uses-col");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","ts-markup-manager_actions-col");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline, element = hooks.element;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element0 = dom.childAt(fragment, [0, 0]);
          var element1 = dom.childAt(element0, [2, 0]);
          var morph0 = dom.createMorphAt(element0,1,1);
          inline(env, morph0, context, "component", [get(env, context, "c_validated_input")], {"value": get(env, context, "new_comment_text"), "class": get(env, context, "add_comment_class")});
          element(env, element1, context, "action", ["add_library_comment"], {});
          return fragment;
        }
      };
    }());
    var child1 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, null);
          dom.insertBoundary(fragment, 0);
          inline(env, morph0, context, "component", [get(env, context, "c_library_comments")], {"comments": get(env, context, "sorted_and_filtered_comments"), "model": get(env, context, "model"), "remove_tag": "remove_comment_tag", "add_tag": "add_comment_tag"});
          return fragment;
        }
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("p");
            var el2 = dom.createTextNode("Library comments serve as a way to easily reuse the comments you leave most often. Add a comment to your library to get started!");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            return fragment;
          }
        };
      }());
      var child1 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("p");
            var el2 = dom.createTextNode("You currently have no comments in your selected categories. You can add categories to a comment by editing a comment using the pencil icon.");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, null);
          dom.insertBoundary(fragment, 0);
          block(env, morph0, context, "if", [get(env, context, "library_empty")], {}, child0, child1);
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ts-markup-manager ts-full-page");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","ts-grid_row ts-full-page_header");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","small-12 medium-3 ts-grid_columns ts-grid_columns-thick");
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4,"class","ts-branding");
        var el5 = dom.createTextNode("Think");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","ts-branding_space");
        var el6 = dom.createTextNode("Space");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","small-12 medium-9 ts-grid_columns ts-grid_columns-thick");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-full-page_header-content");
        var el5 = dom.createElement("h1");
        var el6 = dom.createTextNode("Comment Manager");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createTextNode("Manage all of your comments in one place. ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-full-page_header-actions");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","btn btn-default btn-small");
        var el6 = dom.createTextNode("Exit Manager");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","ts-grid_row ts-full-page_content");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","small-12 medium-5 large-3 ts-grid_columns ts-grid_columns-thick ts-full-page_content-side");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","small-12 medium-7 large-9 ts-grid_columns ts-grid_columns-thick ts-full-page_content-primary");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-full-page_content-heading");
        var el5 = dom.createElement("h1");
        var el6 = dom.createTextNode("Comment List");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","btn-cluster right-cluster");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","btn btn-primary btn-small");
        var el7 = dom.createTextNode("New Comment");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-markup-manager_sort-list");
        var el5 = dom.createTextNode("Sort by: ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-markup-manager_list");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-markup-manager_list-header");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-markup-manager_comment-col");
        var el7 = dom.createElement("h2");
        var el8 = dom.createTextNode("Comment");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-markup-manager_uses-col");
        var el7 = dom.createElement("h2");
        var el8 = dom.createTextNode("Uses");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","ts-markup-manager_actions-col");
        var el7 = dom.createElement("h2");
        var el8 = dom.createTextNode("Actions");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-markup-manager_list-content");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, element = hooks.element, get = hooks.get, inline = hooks.inline, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element2 = dom.childAt(fragment, [0]);
        var element3 = dom.childAt(element2, [0, 1, 1, 0]);
        var element4 = dom.childAt(element2, [1]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(element5, [0, 1, 0]);
        var element7 = dom.childAt(element5, [2, 1]);
        var morph0 = dom.createMorphAt(dom.childAt(element4, [0]),0,0);
        var morph1 = dom.createMorphAt(dom.childAt(element5, [1]),1,1);
        var morph2 = dom.createMorphAt(element7,0,0);
        var morph3 = dom.createMorphAt(element7,1,1);
        element(env, element3, context, "action", ["exit"], {});
        inline(env, morph0, context, "component", [get(env, context, "c_library_tags")], {"model": get(env, context, "model"), "register_as": get(env, context, "tag_component"), "selected_tags": get(env, context, "selected_tags"), "select_tag_action": "toggle_tag_selection", "add_tag_action": "confirm_category_add", "register_tags_component": "register_tags_component", "select_all": "all_selected", "all_selected": get(env, context, "all_selected"), "input_class": get(env, context, "add_category_class")});
        element(env, element6, context, "action", ["toggle_add_comment"], {});
        inline(env, morph1, context, "component", [get(env, context, "c_common_dropdown")], {"collection": get(env, context, "sort_text"), "select_action": "select_sort", "action_receiver": get(env, context, "this"), "text": get(env, context, "selected_sort"), "anchor_class": "library_dropdown-style", "class": "dropdown-wrapper"});
        block(env, morph2, context, "if", [get(env, context, "is_adding_library_comment")], {}, child0, null);
        block(env, morph3, context, "if", [get(env, context, "sorted_and_filtered_comments")], {}, child1, child2);
        return fragment;
      }
    };
  }()));

});