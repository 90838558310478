define('client/templates/components/thinkspace/common/user/terms', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, null);
          dom.insertBoundary(fragment, 0);
          inline(env, morph0, context, "component", [get(env, context, "c_loader")], {"type": "small", "header": "Blasting off!", "message": "You will arrive shortly."});
          return fragment;
        }
      };
    }());
    var child1 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","btn-cluster left-cluster");
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"class","btn btn-small btn-primary");
          var el3 = dom.createTextNode("I accept new Terms");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"class","btn btn-small btn-default");
          var el3 = dom.createTextNode("I do not accept");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, element = hooks.element;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element0 = dom.childAt(fragment, [0]);
          var element1 = dom.childAt(element0, [0]);
          var element2 = dom.childAt(element0, [1]);
          element(env, element1, context, "action", ["accept"], {});
          element(env, element2, context, "action", ["deny"], {});
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ts-signin fade-in");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","ts-signin_content");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-grid_row");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","large-4 small-10 small-centered ts-grid_columns");
        var el5 = dom.createElement("h3");
        dom.setAttribute(el5,"class","ts-signin_header");
        var el6 = dom.createTextNode("Think");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","ts-signin_branding-space");
        var el7 = dom.createTextNode("Space");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-grid_row");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","large-5 small-10 small-centered ts-grid_columns");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","message-container");
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","message-with-img");
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","message_img ts-login_new-tos-img");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","message_text");
        var el8 = dom.createElement("h1");
        var el9 = dom.createTextNode("We've made some changes");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("p");
        var el9 = dom.createTextNode("Just letting you know we've updated our ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("b");
        var el10 = dom.createElement("a");
        var el11 = dom.createTextNode("Terms of Service. ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("Take a look before you come inside. ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","ts-signin_help");
        var el9 = dom.createTextNode("Need help? Contact us at ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("a");
        dom.setAttribute(el9,"href","mailto:support@thinkspace.org");
        var el10 = dom.createTextNode("support@thinkspace.org");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, element = hooks.element, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element3 = dom.childAt(fragment, [0, 0, 1, 0, 0, 0, 1]);
        var element4 = dom.childAt(element3, [1, 1, 0]);
        var morph0 = dom.createMorphAt(element3,2,2);
        element(env, element4, context, "bind-attr", [], {"href": get(env, context, "tos_link")});
        block(env, morph0, context, "if", [get(env, context, "authenticating")], {}, child0, child1);
        return fragment;
      }
    };
  }()));

});