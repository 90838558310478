define('client/thinkspace/casespace/assignments/show/controller', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Controller.extend({
    c_assignment_show: ns['default'].to_p('assignment', 'show')
  });

  exports['default'] = ___DefaultExportObject___;;

});