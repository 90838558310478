define('client/components/thinkspace/peer_assessment/assessment/item/qualitative', ['exports', 'ember', 'thinkspace-base/components/base'], function (exports, ember, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    manager: null,
    comment: null,
    review: ember['default'].computed.reads('manager.review'),
    assessment: ember['default'].computed.reads('manager.model'),
    points_different: ember['default'].computed.reads('assessment.points_different'),
    is_read_only: ember['default'].computed.or('manager.is_read_only', 'manager.is_review_read_only'),
    model_index: ember['default'].computed('model', 'items', function() {
      var items, model;
      items = this.get('items');
      model = this.get('model');
      if (!(ember['default'].isPresent(items) && ember['default'].isPresent(model))) {
        return 0;
      }
      return items.indexOf(model) + 1;
    }),
    review_change: ember['default'].observer('manager.review', function() {
      return this.initialize_review();
    }),
    init: function() {
      var model_id;
      this._super();
      model_id = this.get('model.id');
      return ember['default'].defineProperty(this, 'comment', ember['default'].computed('review', "review.value.qualitative." + model_id + ".value", function() {
        var review, value;
        review = this.get('review');
        if (!ember['default'].isPresent(review)) {
          return;
        }
        value = review.get_qualitative_value_for_id(model_id);
        if (ember['default'].isPresent(value)) {
          return value;
        } else {
          return '';
        }
      }));
    },
    initialize_review: function() {
      var comment, model_id;
      model_id = this.get('model.id');
      comment = this.get('review').get_qualitative_value_for_id(model_id);
      return this.set('comment', comment);
    },
    actions: {
      set_qualitative_value: function(value) {
        return this.get('manager').set_qualitative_value(this.get('model.id'), this.get('model.feedback_type'), value);
      },
      save_review: function() {
        return this.get('manager').save_review();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});