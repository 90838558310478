define('client/components/thinkspace/readiness_assurance/response/irat/question', ['exports', 'ember', 'totem/ns', 'totem/mixins/validations', 'thinkspace-readiness-assurance/base/ra_component'], function (exports, ember, ns, val_mixin, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(val_mixin['default'], {
    tagName: 'li',
    classNames: ['ts-ra_question'],
    actions: {
      select_answer: function(id) {
        return this.qm.save_answer(id);
      },
      save_justification: function(value) {
        return this.qm.save_justification(value);
      }
    },
    answer_id: ember['default'].computed.reads('qm.answer_id'),
    validations: {
      answer_id: {
        presence: {
          message: 'You must select one of the above choices'
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});