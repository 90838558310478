define('client/components/thinkspace/readiness_assurance/response/irat/justification', ['exports', 'ember', 'thinkspace-base/components/base'], function (exports, ember, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    input_value: null,
    justification_disabled: ember['default'].computed.or('qm.readonly', 'qm.justification_disabled'),
    actions: {
      save: function() {
        if (this.get('justification_disabled')) {
          return;
        }
        this.sendAction('save', this.get('input_value'));
        return this.set('show_save', false);
      }
    },
    focusOut: function() {
      return this.send('save');
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});