define('client/components/thinkspace/input_element/preprocessors/responses', ['exports', 'ember', 'totem/ns', 'totem/logger', 'totem/scope'], function (exports, ember, ns, logger, totem_scope) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Object.extend({
    process: function(componentable, template) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (!(componentable && template)) {
            return resolve(template);
          }
          return componentable.get(ns['default'].to_p('elements')).then(function(elements) {
            var $content, show_errors_path, status_path;
            if (ember['default'].isBlank(elements)) {
              return resolve(template);
            }
            $content = ember['default'].$('<div/>').html(template);
            status_path = _this.tvo.status.set_value('elements');
            show_errors_path = _this.tvo.hash.get_path('show_errors');
            return _this.tvo.helper.ownerable_view_association_records_each(componentable, elements, {
              each: 'response'
            }).then(function(responses) {
              elements.forEach(function(element, index) {
                var $input, attribute_matches, attribute_query, attributes, group_guid, hash, html, input_name, path, response, tag_kind, tag_type;
                response = responses.objectAt(index);
                input_name = element.get('name');
                if (!input_name) {
                  return reject("Componentable id [" + (componentable.get('id')) + "] input element id [" + (element.get('id')) + "] is blank");
                }
                $input = $content.find("input[name=" + input_name + "]");
                if (!ember['default'].isPresent($input)) {
                  $input = $content.find("textarea[name=" + input_name + "]");
                }
                if (!$input) {
                  return resolve();
                }
                if (!($input.length > 0)) {
                  return reject("Componentable id [" + (componentable.get('id')) + "] tool_content is missing input element name [" + input_name + "]");
                }
                tag_kind = _this.tvo.tag_kind($input);
                tag_type = _this.tvo.tag_type($input);
                attributes = _this.tvo.tag_attribute_hash($input);
                if (_this.is_checkbox(tag_type)) {
                  if (totem_scope['default'].get('is_view_only')) {
                    attributes.disabled = true;
                  }
                } else {
                  if (totem_scope['default'].get('is_disabled')) {
                    attributes.disabled = true;
                  }
                  if (totem_scope['default'].get('is_read_only')) {
                    attributes.readonly = true;
                  }
                }
                if (!response) {
                  response = element.store.createRecord(ns['default'].to_p('response'), {
                    value: null
                  });
                  response.set(ns['default'].to_p('element'), element);
                  totem_scope['default'].set_record_ownerable_attributes(response);
                  response.didLoad();
                }
                hash = {};
                hash.component = ns['default'].to_p('elements', _this.get_input_component_name(tag_kind, tag_type));
                hash.model = response;
                hash.tattrs = attributes;
                if (_this.tvo.hash.get_value('process_validations')) {
                  hash.validations = _this.get_validations(tag_type);
                }
                path = _this.tvo.value.set_value_for(element, hash);
                html = [];
                html.push("tvo." + path + ".component");
                html.push(_this.tvo.component_bind_properties(path, hash));
                html.push("show_errors=tvo." + show_errors_path);
                html.push("status=tvo." + status_path);
                attribute_query = tag_kind + "[name='" + input_name + "']";
                attribute_matches = $content.find(attribute_query);
                if (attribute_matches.length === 0) {
                  logger['default'].error("No attribute match found for find on " + attribute_query + " against: " + ($content.html()));
                }
                if (_this.is_radio(tag_type)) {
                  group_guid = _this.tvo.generate_guid();
                  html.push("status_group_guid='" + group_guid + "'");
                  return attribute_matches.each(function(index, input_match) {
                    var $radio, comp, value;
                    $radio = $(input_match);
                    value = $radio.attr('value');
                    if (value != null) {
                      comp = ["radio_value='" + value + "'"];
                      return $radio.replaceWith(_this.get_component_html(html.concat(comp)));
                    } else {
                      return logger['default'].error("Radio button " + input_match + " does not have a value attribute.");
                    }
                  });
                } else {
                  if (attribute_matches.length > 1) {
                    logger['default'].warn("Possible mass-replacement of an InputElement due to multiple matches for " + attribute_query);
                  }
                  return attribute_matches.replaceWith(_this.get_component_html(html));
                }
              });
              return resolve($content.html());
            });
          });
        };
      })(this));
    },
    get_component_html: function(html) {
      return '{{ component ' + html.join(' ') + ' }}';
    },
    is_radio: function(tag_type) {
      return tag_type === 'radio';
    },
    is_checkbox: function(tag_type) {
      return tag_type === 'checkbox';
    },
    get_input_component_name: function(tag_kind, tag_type) {
      switch (tag_kind) {
        case 'input':
          switch (false) {
            case !this.is_checkbox(tag_type):
              return 'standard_checkbox';
            case !this.is_radio(tag_type):
              return 'standard_radio';
            default:
              return 'standard_input';
          }
          break;
        case 'textarea':
          return 'standard_textarea';
        default:
          return null;
      }
    },
    get_validations: function(tag_type) {
      if (this.is_checkbox(tag_type)) {
        return {};
      }
      return {
        input_value: {
          presence: {
            message: 'You must enter a response'
          }
        }
      };
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});