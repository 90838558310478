define('client/components/thinkspace/builder/phases/edit/componentable', ['exports', 'ember', 'totem/ns', 'totem/ds/associations', 'thinkspace-builder/components/wizard/steps/base'], function (exports, ember, ns, ta, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    tvo: ember['default'].inject.service(),
    builder: ember['default'].inject.service(),
    mode: 'preview',
    is_selected: false,
    classNames: ['ts-builder_preview'],
    classNameBindings: ['is_edit_mode_content:is-edit-mode-content'],
    has_builder_content: ember['default'].computed('component.has_builder_content', 'model', 'mode', function() {
      return ta['default'].PromiseObject.create({
        promise: this.get_builder_ability('has_builder_content')
      });
    }),
    has_builder_settings: ember['default'].computed('component.has_builder_settings', 'model', 'mode', function() {
      return ta['default'].PromiseObject.create({
        promise: this.get_builder_ability('has_builder_settings')
      });
    }),
    has_builder_preview: ember['default'].computed('component.has_builder_preview', 'model', 'mode', function() {
      return ta['default'].PromiseObject.create({
        promise: this.get_builder_ability('has_builder_preview')
      });
    }),
    has_builder_messages: ember['default'].computed.notEmpty('builder_messages'),
    builder_messages: ember['default'].computed('model', 'mode', function() {
      return ta['default'].PromiseArray.create({
        promise: this.get_builder_messages()
      });
    }),
    get_builder_ability: function(property) {
      var has, model;
      model = this.get('model');
      has = this.get("component." + property);
      if (model.builder_abilities != null) {
        return new ember['default'].RSVP.Promise((function(_this) {
          return function(resolve, reject) {
            return model.builder_abilities().then(function(abilities) {
              var ability;
              ability = abilities[property];
              if (ember['default'].isPresent(ability)) {
                has = ability;
              }
              return resolve(has);
            });
          };
        })(this));
      } else {
        return new ember['default'].RSVP.Promise((function(_this) {
          return function(resolve, reject) {
            return resolve(has);
          };
        })(this));
      }
    },
    get_builder_messages: function() {
      var model;
      model = this.get('model');
      if (model.builder_messages != null) {
        return model.builder_messages();
      } else {
        return new ember['default'].RSVP.Promise((function(_this) {
          return function(resolve, reject) {
            return resolve();
          };
        })(this));
      }
    },
    display_toolbar: ember['default'].computed.or('is_selected', 'is_edit_mode_content'),
    c_mode: ember['default'].computed('component', 'mode', function() {
      var component, mode, path, property;
      mode = this.get('mode');
      component = this.get('component');
      property = "builder_" + mode + "_path";
      path = component.get(property);
      return ns['default'].to_p(path);
    }),
    is_edit_mode_content: ember['default'].computed.equal('mode', 'content'),
    set_mode_content: function() {
      this.reset_builder_toolbar();
      return this.set_mode('content');
    },
    set_mode_settings: function() {
      this.reset_builder_toolbar();
      return this.set_mode('settings');
    },
    set_mode_preview: function() {
      this.reset_builder_toolbar();
      return this.set_mode('preview');
    },
    set_mode: function(mode) {
      return this.set('mode', mode);
    },
    reset_builder_toolbar: function() {
      return this.get('builder').reset_toolbar();
    },
    actions: {
      set_mode_content: function() {
        if (!ember['default'].isEqual(this.get('mode'), 'content')) {
          return this.set_mode_content();
        }
      },
      set_mode_settings: function() {
        if (!ember['default'].isEqual(this.get('mode'), 'settings')) {
          return this.set_mode_settings();
        }
      },
      set_mode_preview: function() {
        if (!ember['default'].isEqual(this.get('mode'), 'preview')) {
          return this.set_mode_preview();
        }
      },
      set_mode: function(mode) {
        var fn;
        fn = "set_mode_" + mode;
        if (this[fn] == null) {
          console.error("[edit:compnentable] Does not respond to set_mode_" + mode + ".");
        }
        return this[fn]();
      },
      cancel: function() {
        return this.set_mode_preview();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});