define('client/models/thinkspace/resource/file', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].polymorphic('resourceable'), ta['default'].has_many('tags', {
    reads: {}
  })), {
    url: ta['default'].attr('string'),
    title: ta['default'].attr('string'),
    content_type: ta['default'].attr('string'),
    size: ta['default'].attr('number'),
    file_updated_at: ta['default'].attr('date'),
    resourceable_type: ta['default'].attr('string'),
    resourceable_id: ta['default'].attr('string'),
    new_tags: ta['default'].attr(),
    tag: ember['default'].computed.reads(ta['default'].to_prop('tags', 'firstObject')),
    extension: ember['default'].computed('title', function() {
      var title;
      title = this.get('title');
      return '.' + title.split('.').pop();
    }),
    without_extension: ember['default'].computed('title', function() {
      var parts, title;
      title = this.get('title');
      parts = title.split('.');
      parts.pop();
      return parts.join('.');
    }),
    human_size: ember['default'].computed('size', function() {
      var bytes, gigabytes, kilobytes, megabytes;
      bytes = this.get('size');
      kilobytes = bytes / 1024;
      megabytes = kilobytes / 1024;
      gigabytes = megabytes / 1024;
      if (gigabytes >= 1) {
        return gigabytes.toFixed(1) + ' GB';
      }
      if (megabytes >= 1) {
        return megabytes.toFixed(1) + ' MB';
      }
      if (kilobytes >= 1) {
        return kilobytes.toFixed(1) + ' KB';
      }
      return bytes.toFixed(1) + ' B';
    }),
    didCreate: function() {
      return this.didLoad();
    },
    didLoad: function() {
      return this.get('resourceable').then((function(_this) {
        return function(resource) {
          return resource.get(ta['default'].to_p('files')).then(function(files) {
            if (!files.contains(_this)) {
              return files.pushObject(_this);
            }
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});