define('client/components/thinkspace/readiness_assurance/admin/irat/to_trat_after', ['exports', 'ember', 'thinkspace-readiness-assurance/base/admin/irat/to_trat'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    init: function() {
      this._super();
      return this.trad.select_all_teams_on();
    },
    button_range: [
      {
        start: 1,
        end: 5,
        by: 1
      }, {
        start: 10,
        end: 30,
        by: 5
      }
    ]
  });

  exports['default'] = ___DefaultExportObject___;;

});