define('client/components/thinkspace/readiness_assurance/admin/irat/to_trat_now', ['exports', 'ember', 'thinkspace-readiness-assurance/base/admin/irat/to_trat'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    init: function() {
      this._super();
      return this.irad.set('transition_now', true);
    },
    validate_data: function() {
      this.irad.clear_errors();
      this.trad.clear_errors();
      if (ember['default'].isBlank(this.trad.get_teams())) {
        return this.trad.error('You have not selected any teams.');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});