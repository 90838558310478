define('client/models/thinkspace/artifact/file', ['exports', 'ember', 'totem/ds/associations', 'client/models/thinkspace/common/componentable'], function (exports, ember, ta, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(ta['default'].add(ta['default'].polymorphic('ownerable'), ta['default'].belongs_to('bucket', {
    reads: {}
  })), {
    user_id: ta['default'].attr('number'),
    url: ta['default'].attr('string'),
    title: ta['default'].attr('string'),
    content_type: ta['default'].attr('string'),
    size: ta['default'].attr('number'),
    attachment_updated_at: ta['default'].attr('date'),
    updateable: ta['default'].attr('boolean'),
    ownerable_id: ta['default'].attr('number'),
    ownerable_type: ta['default'].attr('string'),
    authable: ember['default'].computed(function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get(ta['default'].to_p('bucket')).then(function(bucket) {
            return bucket.get('authable').then(function(authable) {
              return resolve(authable);
            });
          });
        };
      })(this));
      return ta['default'].PromiseObject.create({
        promise: promise
      });
    }),
    extension: ember['default'].computed('title', function() {
      return '.' + this.get('title').split('.').pop();
    }),
    is_pdf: ember['default'].computed.equal('extension', '.pdf'),
    is_image: ember['default'].computed('extension', function() {
      return ['.png', '.jpeg', '.jpg', '.gif'].contains(this.get('extension'));
    }),
    without_extension: ember['default'].computed('title', function() {
      var parts, title;
      title = this.get('title');
      parts = title.split('.');
      parts.pop();
      return parts.join('.');
    }),
    friendly_size: ember['default'].computed('size', function() {
      var bytes, gigabytes, kilobytes, megabytes;
      bytes = this.get('size');
      kilobytes = bytes / 1024;
      megabytes = kilobytes / 1024;
      gigabytes = megabytes / 1024;
      if (gigabytes >= 1) {
        return gigabytes.toFixed(1) + ' GB';
      }
      if (megabytes >= 1) {
        return megabytes.toFixed(1) + ' MB';
      }
      if (kilobytes >= 1) {
        return kilobytes.toFixed(1) + ' KB';
      }
      return bytes.toFixed(1) + ' B';
    }),
    container_id: ember['default'].computed('id', function() {
      return "ts-artifact_file-" + (this.get('id'));
    }),
    didCreate: function() {
      return this.didLoad();
    },
    didLoad: function() {
      return this.get(ta['default'].to_p('bucket')).then((function(_this) {
        return function(bucket) {
          return bucket.get(ta['default'].to_p('artifact:files')).then(function(files) {
            if (!files.contains(_this)) {
              return files.pushObject(_this);
            }
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});