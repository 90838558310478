define('client/components/thinkspace/readiness_assurance/admin/shared/toggle_select', ['exports', 'ember', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    actions: {
      toggle: function() {
        this.toggleProperty('rad.show_select');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});