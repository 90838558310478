define('client/serializers/application', ['exports', 'ember-data', 'totem/scope'], function (exports, ds, totem_scope) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ds['default'].ActiveModelSerializer.extend({
    serializeIntoHash: function(hash, type, record, options) {
      totem_scope['default'].serialize_into_hash(hash, type, record, options);
      return this._super(hash, type, record, options);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});