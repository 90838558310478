define('client/initializers/totem/config/locales', ['exports', 'totem/config/locales'], function (exports, locales) {

  'use strict';

  var ___DefaultExportObject___, initializer;

  initializer = {
    name: 'totem-config-locales',
    initialize: function(container, app) {
      return locales['default'].process();
    }
  };

  ___DefaultExportObject___ = initializer;

  exports['default'] = ___DefaultExportObject___;;

});