define('client/components/thinkspace/readiness_assurance/shared/messages/view', ['exports', 'ember', 'totem/ns', 'thinkspace-readiness-assurance/base/ra_component'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    init: function() {
      this._super();
      this.messages = this.ra.get('messages');
      return this.filter_rooms = this.rooms || this.ra.current_user_room();
    },
    new_messages: ember['default'].computed(function() {
      return this.messages.get_new_messages({
        rooms: this.filter_rooms
      });
    }),
    previous_messages: ember['default'].computed(function() {
      return this.messages.get_previous_messages({
        rooms: this.filter_rooms
      });
    }),
    has_messages: ember['default'].computed.or('new_messages.length', 'previous_messages.length'),
    show_new: false,
    show_previous: false,
    actions: {
      mark_previous: function(msg) {
        msg.set_previous();
        return this.set_show_new();
      },
      mark_all_previous: function() {
        this.messages.move_all_to_previous(this.filter_rooms);
        return this.set_show_new();
      },
      toggle_new: function() {
        this.toggleProperty('show_new');
      },
      toggle_previous: function() {
        this.toggleProperty('show_previous');
      }
    },
    set_show_new: function() {
      if (this.get('new_messages.length') === 0) {
        return this.set('show_new', false);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});