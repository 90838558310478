define('client/components/thinkspace/readiness_assurance/response/trat/users', ['exports', 'ember', 'totem/ns', 'thinkspace-readiness-assurance/mixins/user_list'], function (exports, ember, ns, user_list_mixin) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Component.extend(user_list_mixin['default'], {
    header: ember['default'].computed.reads('rm.room_users_header'),
    room: ember['default'].computed.reads('rm.room'),
    users: ember['default'].computed.reads('rm.room_users'),
    willInsertElement: function() {
      return this.setup();
    },
    willDestroy: function() {
      return this.rm.leave_room(this.get_room_type());
    },
    setup: function() {
      this.rm.join_room({
        source: this,
        room_type: this.get_room_type(),
        callback: 'handle_room_users',
        after_authorize_callback: 'refresh_users'
      });
      return this.set_all_sorted_users();
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});