define('client/components/thinkspace/input_element/elements/standard_textarea', ['exports', 'ember', 'client/components/thinkspace/input_element/elements/standard_input'], function (exports, ember, text_field) {

	'use strict';

	var ___DefaultExportObject___;

	___DefaultExportObject___ = text_field['default'].extend();

	exports['default'] = ___DefaultExportObject___;;

});