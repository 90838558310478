define('client/initializers/totem/application/helpers/format-decimals', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___, initializer;

  initializer = {
    name: 'totem-application-helpers-format-decimals',
    after: ['totem'],
    initialize: function(container, app) {
      return ember['default'].Handlebars.helper('format-decimals', function(number, decimals, options) {
        number = Number(number);
        decimals = Number(decimals);
        if (isNaN(decimals)) {
          decimals = 1;
        }
        if (isNaN(number)) {
          return '';
        }
        return number.toFixed(decimals);
      });
    }
  };

  ___DefaultExportObject___ = initializer;

  exports['default'] = ___DefaultExportObject___;;

});