define('client/components/thinkspace/indented_list/list/edit', ['exports', 'ember', 'thinkspace-base/components/base'], function (exports, ember, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    didInsertElement: function() {
      var $input;
      $input = this.$('input');
      return $input.focus();
    },
    focusOut: function() {
      return this.sendAction('save');
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});