define('client/templates/components/thinkspace/casespace/case_manager/assignment/peer_assessment/assessment', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","ts-grid_row");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","ts-grid_columns small-12");
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","dialogue dialogue-success");
          var el4 = dom.createElement("h4");
          var el5 = dom.createTextNode("Your evaluations are sent.");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("p");
          var el5 = dom.createTextNode("These take a bit to process, so they will not reflect here immediately.  Check back in a few minutes to see if their states have updated.");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          return fragment;
        }
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, null);
            dom.insertBoundary(fragment, 0);
            inline(env, morph0, context, "component", [get(env, context, "c_team")], {"model": get(env, context, "team"), "assessment": get(env, context, "model"), "team_sets": get(env, context, "team_sets")});
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"aria-hidden","true");
          dom.setAttribute(el1,"aria-labelledby","modalTitle");
          dom.setAttribute(el1,"data-reveal","");
          dom.setAttribute(el1,"role","dialog");
          dom.setAttribute(el1,"class","ts-tblpa_modal reveal-modal");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","ts-forms_heading");
          var el3 = dom.createElement("h4");
          var el4 = dom.createTextNode("Are you sure?");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          var el4 = dom.createTextNode("Sending the evaluations will unlock and make visible all evaluations to team members in their current state.  Once they are sent, the students will have access to view all comments (anonymized) that their peers have written.");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          var el4 = dom.createTextNode("Sending the evaluations will send only ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("strong");
          var el5 = dom.createTextNode("approved");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" team's reviews.  This can only be done once, so ensure all teams are approved before continuing.");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          var el4 = dom.createElement("strong");
          var el5 = dom.createTextNode("Please note:");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" The evaluations will be sent in the background and will not reflect on this page immediately.  You will need to come back later (or refresh in a minute or two) to see which evaluations have been sent.");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","dialogue dialogue-error dialogue_inline");
          var el4 = dom.createElement("p");
          var el5 = dom.createTextNode("Any teams that are not approved will ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("strong");
          var el6 = dom.createTextNode("NOT");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" be sent.");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"class","btn btn-default btn-small left");
          var el3 = dom.createTextNode("No, Go Back");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"class","btn btn-primary btn-small right");
          var el3 = dom.createTextNode("Yes, Send");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"aria-label","Close");
          dom.setAttribute(el2,"class","close-reveal-modal");
          var el3 = dom.createTextNode(" ×");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"aria-hidden","true");
          dom.setAttribute(el1,"aria-labelledby","modalTitle");
          dom.setAttribute(el1,"data-reveal","");
          dom.setAttribute(el1,"role","dialog");
          dom.setAttribute(el1,"class","ts-tblpa_modal-notify reveal-modal");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","ts-forms_heading");
          var el3 = dom.createElement("h4");
          var el4 = dom.createTextNode("Notify all students who have not submitted");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          var el4 = dom.createTextNode("This will send a reminder to all students who are marked as ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","thinkspace-tbl-pa_state-tag state_neutral");
          var el5 = dom.createTextNode("in-progress");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" or have not yet started.");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"class","btn btn-default btn-small left");
          var el3 = dom.createTextNode("No, Go Back");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"class","btn btn-primary btn-small right");
          var el3 = dom.createTextNode("Yes, Notify");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"aria-label","Close");
          dom.setAttribute(el2,"class","close-reveal-modal");
          var el3 = dom.createTextNode(" ×");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, block = hooks.block, element = hooks.element;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [2]);
          var element3 = dom.childAt(fragment, [2]);
          var element4 = dom.childAt(element3, [1]);
          var element5 = dom.childAt(element3, [2]);
          var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          block(env, morph0, context, "each", [get(env, context, "teams")], {"keyword": "team"}, child0, null);
          element(env, element1, context, "action", ["close_approve_modal"], {"on": "click"});
          element(env, element2, context, "action", ["approve"], {"on": "click"});
          element(env, element4, context, "action", ["close_notify_all_modal"], {"on": "click"});
          element(env, element5, context, "action", ["approve_notify_all"], {"on": "click"});
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ts-grid_columns ts-grid_columns-thick small-12");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","ts-grid_row");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","phase-content_heading ts-grid_columns small-7");
        var el4 = dom.createElement("h4");
        var el5 = dom.createTextNode("Student Peer Evaluation Review");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h5");
        var el5 = dom.createTextNode("Major changes");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ol");
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Your downloadable report under \"Reports\" contains evaluation scores from all ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","thinkspace-tbl-pa_state-tag state_submitted");
        var el7 = dom.createTextNode("submitted");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(", ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","thinkspace-tbl-pa_state-tag state_approved");
        var el7 = dom.createTextNode("approved");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(", and ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","thinkspace-tbl-pa_state-tag state_sent");
        var el7 = dom.createTextNode("sent");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" evaluations.");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("ol");
        var el7 = dom.createElement("li");
        var el8 = dom.createTextNode("This allows you to monitor the evaluations as they're in-progress, but be aware they can differ from what the student may see.");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("A student will see their score calculated ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("strong");
        var el7 = dom.createTextNode("only");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" from ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","thinkspace-tbl-pa_state-tag state_sent");
        var el7 = dom.createTextNode("sent");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" evaluations.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("You can now send the evaluation as many times as you want.");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("ol");
        var el7 = dom.createElement("li");
        var el8 = dom.createTextNode("Sending will mark all ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","thinkspace-tbl-pa_state-tag state_submitted");
        var el9 = dom.createTextNode("submitted");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" as ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","thinkspace-tbl-pa_state-tag state_sent");
        var el9 = dom.createTextNode("sent");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(".");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createTextNode("Sending will also mark all ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","thinkspace-tbl-pa_state-tag state_neutral");
        var el9 = dom.createTextNode("in-progress");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" as ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","thinkspace-tbl-pa_state-tag state_ignored");
        var el9 = dom.createTextNode("ignored");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(".");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("There is a new feature to mark an evaluation as ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","thinkspace-tbl-pa_state-tag state_ignored");
        var el7 = dom.createTextNode("ignored");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(".  This removes all evaluations marked with this from calculations.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Icons have been added to handle the common functions on each row, about half as much clicking required.  Less common options are still in the drop-down menu.  Hover over the icons to see what their description.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Notifications can now be sent to students who have not yet started their evaluations. ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Added the ability to send a reminder to all ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","thinkspace-tbl-pa_state-tag state_neutral");
        var el7 = dom.createTextNode("in-progress");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" and not yet started work.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-grid_columns small-5");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","btn-group right");
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"class","btn btn-default btn-small");
        var el6 = dom.createTextNode("Notify All Incomplete");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"class","btn btn-primary btn-small");
        var el6 = dom.createTextNode("Send Evaluations");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, inline = hooks.inline, element = hooks.element, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element6 = dom.childAt(fragment, [1]);
        var element7 = dom.childAt(element6, [0, 1, 0]);
        var element8 = dom.childAt(element7, [0]);
        var element9 = dom.childAt(element7, [1]);
        var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
        var morph1 = dom.createMorphAt(element6,1,1);
        dom.insertBoundary(fragment, 0);
        inline(env, morph0, context, "component", [get(env, context, "c_assignment_header")], {"model": get(env, context, "assignment")});
        element(env, element8, context, "action", ["show_notify_all_modal"], {"on": "click"});
        element(env, element9, context, "action", ["show_approve_modal"], {"on": "click"});
        block(env, morph1, context, "if", [get(env, context, "has_sent")], {}, child0, child1);
        return fragment;
      }
    };
  }()));

});