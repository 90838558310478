define('client/components/thinkspace/casespace/gradebook/assignment', ['exports', 'ember', 'totem/ns', 'totem/scope', 'thinkspace-base/components/base'], function (exports, ember, ns, totem_scope, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    casespace: ember['default'].inject.service(),
    current_assignment: ember['default'].computed.reads('casespace.current_assignment'),
    selected_assignment: null,
    selected_phase: null,
    t_header: ns['default'].to_t('gradebook', 'assignment', 'header'),
    t_select_scores: ns['default'].to_t('gradebook', 'assignment', 'select_scores'),
    t_show_scores: ns['default'].to_t('gradebook', 'assignment', 'show_scores'),
    t_footer: ns['default'].to_t('gradebook', 'assignment', 'footer'),
    c_roster_assignment_scores: ns['default'].to_p('gradebook', 'assignment', 'roster', 'assignment', 'scores'),
    c_roster_phase_scores: ns['default'].to_p('gradebook', 'assignment', 'roster', 'phase', 'scores'),
    select_phase_scores_prompt: 'View Phase Scores',
    select_assignment_scores_prompt: 'View Assignment Scores',
    select_phase_scores_visible: false,
    assignment_scores_visible: false,
    phase_scores_visible: false,
    show_scores: ember['default'].computed.or('assignment_scores_visible', 'phase_scores_visible'),
    phase_scores_or_select_phase_scores_visible: ember['default'].computed.or('phase_scores_visible', 'select_phase_scores_visible'),
    willInsertElement: function() {
      return this.send('select_assignment_scores');
    },
    actions: {
      select_assignment_scores: function() {
        this.set('selected_phase', null);
        this.set('phase_scores_visible', false);
        this.set('select_phase_scores_visible', false);
        this.set('selected_assignment', this.get('current_assignment'));
        return this.set('assignment_scores_visible', true);
      },
      select_phase_scores: function(phase) {
        this.set('selected_assignment', null);
        this.set('assignment_scores_visible', false);
        this.set('selected_phase', phase);
        return this.set('phase_scores_visible', true);
      },
      toggle_select_phase_scores: function() {
        this.toggleProperty('select_phase_scores_visible');
        this.set('assignment_scores_visible', false);
        return false;
      },
      view_phase_list: function() {
        this.set('select_phase_scores_visible', true);
        this.set('phase_scores_visible', false);
        return this.set('selected_phase', null);
      },
      close: function() {
        this.set('selected_assignment', null);
        this.set('selected_phase', null);
        this.set('select_assignment_scores_visible', false);
        this.set('select_phase_scores_visible', false);
        this.set('assignment_scores_visible', false);
        return this.set('phase_scores_visible', false);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});