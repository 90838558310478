define('client/_config/thinkspace/indented_list/locales/en', ['exports'], function (exports) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = {
    casespace: {
      api: {
        success: {
          thinkspace: {
            indented_list: {
              response: {
                save: 'Response saved successfully.'
              }
            }
          }
        }
      }
    },
    indented_list: {
      list: 'Indented List',
      response: 'Indented Response'
    }
  };

  exports['default'] = ___DefaultExportObject___;;

});