define('client/components/thinkspace/casespace/case_manager/assignment/wizard', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    space_id: null,
    wizard_manager: ember['default'].inject.service(),
    actions: {
      exit: function() {
        return this.get('wizard_manager').exit();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});