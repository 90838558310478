define('client/components/thinkspace/common/date_picker', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    ttz: ember['default'].inject.service(),
    attributeBindings: ['tabindex'],
    tabindex: 1,
    show_icon: true,
    friendly_value: ember['default'].computed('value', function() {
      var value;
      value = this.get('value');
      if (!ember['default'].isPresent(value)) {
        return null;
      }
      return this.get('ttz').format(value, {
        format: 'MMMM Do, YYYY'
      });
    }),
    value_observer: ember['default'].observer('value', function() {
      return this.set_date();
    }),
    focusIn: function(e) {
      var picker;
      picker = this.get_date_picker();
      picker.open();
      return e.stopPropagation();
    },
    click: function(e) {
      var picker;
      picker = this.get_date_picker();
      picker.open();
      e.stopPropagation();
      return e.preventDefault();
    },
    didInsertElement: function() {
      var $input, options;
      $input = this.$('.ts-picker_input');
      options = this.get('date_options') || {};
      options.onClose = ((function(_this) {
        return function() {
          return _this.select_date();
        };
      })(this));
      $input.pickadate(options);
      return this.set_date();
    },
    set_date: function() {
      var value, value_type;
      value = this.get('value');
      if (!ember['default'].isPresent(value)) {
        return;
      }
      value_type = typeof value;
      if (value_type === 'string') {
        value = new Date(value);
      }
      return this.get_date_picker().set('select', value);
    },
    select_date: function() {
      var date;
      date = this.get_date_picker_date();
      return this.sendAction('select', date);
    },
    get_date_picker: function() {
      return this.$('.ts-picker_input').pickadate('picker');
    },
    get_date_picker_date: function() {
      return this.get_date_picker().get('select');
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});