define('client/components/thinkspace/simulation/simulation', ['exports', 'ember', 'ember-data', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ds, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    classNames: ['ts-componentable'],
    component_path: null,
    c_simulation: ember['default'].computed(function() {
      return ns['default'].to_p('simulation', 'simulations', this.get('model.path'));
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});