define('client/components/thinkspace/readiness_assurance/admin/shared/radio/button', ['exports', 'ember', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    button_id: ember['default'].computed.reads('button.id'),
    button_label: ember['default'].computed.reads('button.label'),
    label_select: ember['default'].computed(function() {
      return this.label_selectable !== false;
    }),
    is_selected: ember['default'].computed('selected_id', function() {
      return this.selected_id === this.get('button_id');
    }),
    actions: {
      select: function() {
        return this.sendAction('select', this.get('button_id'));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});