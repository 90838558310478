define('client/components/thinkspace/casespace/case_manager/assignment/wizards/selector', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace-case-manager/components/wizards/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    actions: {
      set_bundle_type: function(bundle_type) {
        var wizard_manager;
        wizard_manager = this.get('wizard_manager');
        switch (bundle_type) {
          case 'casespace':
            wizard_manager.set_query_param('step', 'details');
        }
        return wizard_manager.set_query_param('bundle_type', bundle_type);
      },
      cancel: function() {
        return this.get('wizard_manager').exit();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});