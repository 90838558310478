define('client/services/casespace_peer_review', ['exports', 'ember', 'ember-data', 'totem/ns', 'totem/ajax', 'totem/scope', 'totem-messages/messages'], function (exports, ember, ds, ns, ajax, totem_scope, totem_messages) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Object.extend({
    toString: function() {
      return 'PeerReviewPhaseMap';
    },
    map: null,
    clear: function() {
      return this.set('map', null);
    },
    new_map: function() {
      return ember['default'].Map.create();
    },
    get_map: function() {
      return this.get('map');
    },
    get_current_user: function() {
      return totem_scope['default'].get_current_user();
    },
    get_team_ownerable: function() {
      var team_ownerable;
      team_ownerable = this.get('team_ownerable');
      if (team_ownerable) {
        return team_ownerable;
      }
      this.set('team_ownerable', totem_scope['default'].get_ownerable_record());
      return this.get('team_ownerable');
    },
    get_or_init_map: function() {
      var map;
      if (!(map = this.get_map())) {
        this.set('map', this.new_map());
        map = this.get_map();
      }
      return map;
    },
    get_or_init_phase_map: function(phase) {
      var map, phase_map;
      map = this.get_or_init_map();
      phase_map = map.get(phase);
      if (!phase_map) {
        map.set(phase, this.new_map());
      }
      return map.get(phase);
    },
    get_or_init_ownerable_map: function(phase, ownerable) {
      var ownerable_map, phase_map;
      phase_map = this.get_or_init_phase_map(phase);
      ownerable_map = phase_map.get(ownerable);
      if (!ownerable_map) {
        phase_map.set(ownerable, this.new_map());
      }
      return phase_map.get(ownerable);
    },
    get_peer_review_users: function(phase) {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var map_users, ownerable, ownerable_map;
          ownerable = _this.get_current_user();
          ownerable_map = _this.get_or_init_ownerable_map(phase, ownerable);
          map_users = ownerable_map.get('users');
          if (ember['default'].isPresent(map_users)) {
            return resolve(map_users);
          } else {
            return _this.request_peer_review_users(phase).then(function(users) {
              var sorted_users;
              sorted_users = users.without(ownerable).sortBy('sort_name');
              ownerable_map.set('users', sorted_users);
              return resolve(sorted_users);
            }, function(error) {
              return reject(error);
            });
          }
        };
      })(this));
      return ds['default'].PromiseArray.create({
        promise: promise
      });
    },
    request_peer_review_users: function(phase) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          totem_scope['default'].ownerable_to_current_user();
          query = {
            model: ns['default'].to_p('team'),
            verb: 'post',
            action: 'team_users_view',
            sub_action: 'peer_review_users'
          };
          query = totem_scope['default'].add_auth_to_ajax_query(query);
          return ajax['default'].object(query).then(function(payload) {
            var payload_users, users;
            payload_users = payload[ns['default'].to_p('users')] || [];
            users = phase.store.pushMany(ns['default'].to_p('user'), payload_users);
            return resolve(users);
          }, function(error) {
            totem_messages['default'].api_failure(error, {
              source: _this,
              model: phase,
              action: 'peer_review_users'
            });
            return reject(error);
          });
        };
      })(this));
    },
    get_peer_review_teams: function(phase) {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var map_teams, ownerable, ownerable_map;
          ownerable = _this.get_team_ownerable();
          ownerable_map = _this.get_or_init_ownerable_map(phase, ownerable);
          map_teams = ownerable_map.get('teams');
          if (ember['default'].isPresent(map_teams)) {
            return resolve(map_teams);
          } else {
            return _this.request_peer_review_teams(phase).then(function(teams) {
              var sorted_teams;
              sorted_teams = teams.without(ownerable).sortBy('title');
              ownerable_map.set('teams', sorted_teams);
              return resolve(sorted_teams);
            }, function(error) {
              return reject(error);
            });
          }
        };
      })(this));
      return ds['default'].PromiseArray.create({
        promise: promise
      });
    },
    request_peer_review_teams: function(phase) {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          query = {
            model: ns['default'].to_p('team'),
            verb: 'post',
            action: 'teams_view',
            sub_action: 'peer_review_teams'
          };
          query = totem_scope['default'].add_auth_to_ajax_query(query);
          return ajax['default'].object(query).then(function(payload) {
            var payload_teams, teams;
            payload_teams = payload[ns['default'].to_p('teams')] || [];
            teams = phase.store.pushMany(ns['default'].to_p('team'), payload_teams);
            return resolve(teams);
          }, function(error) {
            totem_messages['default'].api_failure(error, {
              source: _this,
              model: phase,
              action: 'peer_review_teams'
            });
            return reject(error);
          });
        };
      })(this));
      return ds['default'].PromiseArray.create({
        promise: promise
      });
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});