define('client/thinkspace/builder/cases/route', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin', 'thinkspace-base/base/route'], function (exports, ember, auth_mixin, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(auth_mixin['default'], {
    builder: ember['default'].inject.service(),
    beforeModel: function() {
      return this.get('builder').reset(this);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});