define('client/templates/components/thinkspace/simulation/simulations/mountain/result', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","sim-mountain_result-wrapper");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","sim-mountain_result-divider");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","sim-mountain_result-divider");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","sim-mountain_result-divider");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","sim-mountain_result-divider");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","sim-mountain_result-divider");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","sim-mountain_result-divider");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","sim-mountain_result-divider");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, element = hooks.element, content = hooks.content;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [0]);
        var morph0 = dom.createMorphAt(dom.childAt(element0, [0]),0,0);
        var morph1 = dom.createMorphAt(dom.childAt(element0, [1]),0,0);
        var morph2 = dom.createMorphAt(dom.childAt(element0, [2]),0,0);
        var morph3 = dom.createMorphAt(dom.childAt(element0, [3]),0,0);
        var morph4 = dom.createMorphAt(dom.childAt(element0, [4]),0,0);
        var morph5 = dom.createMorphAt(dom.childAt(element0, [5]),0,0);
        var morph6 = dom.createMorphAt(dom.childAt(element0, [6]),0,0);
        element(env, element0, context, "bind-attr", [], {"style": get(env, context, "style")});
        element(env, element0, context, "action", ["parent_action"], {"on": "click"});
        content(env, morph0, context, "start_temp");
        content(env, morph1, context, "start_humidity");
        content(env, morph2, context, "start_dew_point");
        content(env, morph3, context, "cloud_base");
        content(env, morph4, context, "final_temp");
        content(env, morph5, context, "final_humidity");
        content(env, morph6, context, "final_dew_point");
        return fragment;
      }
    };
  }()));

});