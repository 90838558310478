define('client/components/thinkspace/observation_list/observation_note/show', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    is_editing_note: false,
    c_observation_note_edit: ns['default'].to_p('observation_note', 'edit'),
    totem_data_config: {
      ability: {
        model: 'observation'
      }
    },
    can_update: ember['default'].computed.and('not_viewonly', 'can.update'),
    edit_on: function() {
      return this.set('is_editing_note', true);
    },
    edit_off: function() {
      return this.set('is_editing_note', false);
    },
    actions: {
      edit: function() {
        this.edit_on();
        return this.sendAction('edit');
      },
      update: function() {
        this.edit_off();
        return this.sendAction('update', this.get('model'));
      },
      destroy: function() {
        this.edit_off();
        return this.sendAction('remove', this.get('model'));
      },
      cancel: function() {
        this.edit_off();
        return this.sendAction('cancel', this.get('model'));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});