define('client/components/thinkspace/casespace/case_manager/space/roster', ['exports', 'ember', 'ember-data', 'totem/ns', 'totem/ajax', 'thinkspace-base/components/base'], function (exports, ember, ds, ns, ajax, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    is_inviting: false,
    c_space_header: ns['default'].to_p('space', 'header'),
    c_new_invitation: ns['default'].to_p('invitation', 'new'),
    c_space_user_table: ns['default'].to_p('space_user', 'table'),
    c_file_upload: ns['default'].to_p('common', 'file-upload'),
    c_file_upload_modal: ns['default'].to_p('case_manager', 'shared', 'import_roster_modal'),
    import_form_action: ember['default'].computed('model', function() {
      return "/api/thinkspace/common/spaces/" + (this.get('model.id')) + "/import";
    }),
    import_model_path: 'thinkspace/common/space',
    import_params: ember['default'].computed('model', function() {
      return {
        id: this.get('model.id')
      };
    }),
    import_btn_text: 'Import Roster',
    import_loading_text: 'Importing roster..',
    import_team_form_action: ember['default'].computed('model', function() {
      return "/api/thinkspace/common/spaces/" + (this.get('model.id')) + "/import_teams";
    }),
    import_team_model_path: 'thinkspace/common/space',
    import_team_params: ember['default'].computed('model', function() {
      return {
        id: this.get('model.id')
      };
    }),
    import_team_btn_text: 'Import Team Roster',
    import_team_loading_text: 'Importing team roster..',
    c_team_file_upload_modal: ns['default'].to_p('case_manager', 'team_sets', 'import_modal'),
    space_users: ember['default'].computed(function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var space;
          space = _this.get('model');
          return _this.roster_ajax('get', 'roster').then(function(space_users) {
            var filter;
            filter = space.store.filter(ns['default'].to_p('space_user'), function(record) {
              return parseInt(record.get('space_id')) === parseInt(space.get('id'));
            });
            return resolve(filter);
          });
        };
      })(this));
      return ds['default'].PromiseArray.create({
        promise: promise
      });
    }),
    sorted_space_users: ember['default'].computed('space_users.length', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get('space_users').then(function(space_users) {
            var sortby;
            sortby = space_users.sortBy('user.last_name');
            return resolve(sortby);
          });
        };
      })(this));
      return ds['default'].PromiseArray.create({
        promise: promise
      });
    }),
    sorted_active_space_users: ember['default'].computed('sorted_space_users.@each.state', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get('sorted_space_users').then(function(space_users) {
            var filtered;
            filtered = space_users.filter(function(su) {
              return su.get('is_active');
            });
            return resolve(filtered);
          });
        };
      })(this));
      return ds['default'].PromiseArray.create({
        promise: promise
      });
    }),
    sorted_inactive_space_users: ember['default'].computed('sorted_space_users.@each.state', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get('sorted_space_users').then(function(space_users) {
            var filtered;
            filtered = space_users.filter(function(su) {
              return su.get('is_inactive');
            });
            return resolve(filtered);
          });
        };
      })(this));
      return ds['default'].PromiseArray.create({
        promise: promise
      });
    }),
    roster_ajax: function(verb, action) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model, query;
          model = _this.get('model');
          query = {
            id: model.get('id'),
            action: action,
            verb: verb
          };
          return _this.tc.query(ns['default'].to_p('space'), query, {
            payload_type: ns['default'].to_p('space_user')
          }).then(function(records) {
            return resolve(records);
          });
        };
      })(this));
    },
    actions: {
      toggle_inviting: function() {
        return this.toggleProperty('is_inviting');
      },
      cancel_inviting: function() {
        return this.set('is_inviting', false);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});