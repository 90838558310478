define('client/helpers/date-picker-split-row', ['exports', 'ember', 'client/views/date_picker/split_row'], function (exports, ember, view) {

	'use strict';

	var ___DefaultExportObject___;

	___DefaultExportObject___ = ember['default'].Handlebars.makeViewHelper(view['default']);

	exports['default'] = ___DefaultExportObject___;;

});