define('client/models/thinkspace/common/component', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend({
    value: ta['default'].attr(),
    preprocessors: ta['default'].attr(),
    has_builder_content: ember['default'].computed.notEmpty('builder_content_path'),
    has_builder_preview: ember['default'].computed.notEmpty('builder_preview_path'),
    has_builder_settings: ember['default'].computed.notEmpty('builder_settings_path'),
    builder_content_path: ember['default'].computed('value.builder', function() {
      return this.get('value.builder.paths.content');
    }),
    builder_preview_path: ember['default'].computed('value.builder', function() {
      return this.get('value.builder.paths.preview');
    }),
    builder_settings_path: ember['default'].computed('value.builder', function() {
      return this.get('value.builder.paths.settings');
    }),
    builder_friendly_name: ember['default'].computed('value.builder', function() {
      return this.get('value.builder.friendly_name');
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});