define('client/transforms/delocalized-date', ['exports', 'ember-data'], function (exports, ds) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ds['default'].Transform.extend({
    deserialize: function(serialized) {
      var date, offset_date, type;
      type = typeof serialized;
      if (type === "string") {
        date = new Date(Ember.Date.parse(serialized));
        offset_date = new Date(date.getTime() + (date.getTimezoneOffset() * 60000));
        return offset_date;
      } else if (type === "number") {
        return new Date(serialized);
      } else if (serialized === null || serialized === void 0) {
        return serialized;
      } else {
        return null;
      }
    },
    serialize: function(date) {
      var offset_date;
      if (date instanceof Date) {
        offset_date = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
        return offset_date.toJSON();
      } else {
        return null;
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});