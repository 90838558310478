define('client/components/thinkspace/common/space_user/table', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: 'table',
    c_space_user_row: ns['default'].to_p('space_user', 'row')
  });

  exports['default'] = ___DefaultExportObject___;;

});