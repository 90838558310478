define('client/thinkspace/team/users/team/view', ['exports', 'ember', 'ember-data', 'totem/ns'], function (exports, ember, ds, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].View.extend({
    tagName: 'ul',
    classNames: ['team_users-team-list'],
    classNameBindings: ['is_selected_team:team_users-selected-team'],
    is_selected_team: ember['default'].computed('controller.selected_team', function() {
      return this.get('team') === this.get('controller.selected_team');
    }),
    users_sorted: ember['default'].computed('users', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get('users').then(function(users) {
            return resolve(users.sortBy('sort_name'));
          });
        };
      })(this));
      return ds['default'].PromiseArray.create({
        promise: promise
      });
    }),
    users: ember['default'].computed('team_users.@each', function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get('team_users').then(function(team_users) {
            var user_promises;
            user_promises = team_users.getEach(common.to_p('user'));
            return ember['default'].RSVP.Promise.all(user_promises).then(function(users) {
              return resolve(users);
            });
          });
        };
      })(this));
    }),
    team_users: ember['default'].computed(function() {
      var team_id;
      team_id = parseInt(this.get('team.id'));
      return this.get('controller.store').filter(ns['default'].to_p('team_user'), (function(_this) {
        return function(team_user) {
          return team_user.get('team_id') === team_id;
        };
      })(this));
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});