define('client/components/thinkspace/simulation/simulations/mountain/result', ['exports', 'ember', 'ember-data', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ds, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    content: null,
    start_humidity: null,
    start_temp: null,
    start_dew_point: null,
    cloud_base: null,
    final_temp: null,
    final_humidity: null,
    final_dew_point: null,
    parent_action: null,
    color: null,
    trial_num: ember['default'].computed('content', function() {
      var content, key, value;
      content = this.get('content');
      for (key in content) {
        value = content[key];
        return key;
      }
    }),
    style: ember['default'].computed('color', 'is_visible', function() {
      var color, is_visible;
      color = this.get('color');
      is_visible = this.get('is_visible');
      if (color != null) {
        if (is_visible) {
          return "border: 2px solid " + color + "; border-radius:2px; margin-bottom:.15em;";
        } else {
          return "border: 2px solid black; border-radius:2px; margin-bottom:.15em;";
        }
      }
    }),
    is_visible: true,
    didInsertElement: function() {
      var cloud_base, color, content, final_dew_point, final_humidity, final_temp, key, results, start_dew_point, start_humidity, start_temp, value;
      content = this.get('content');
      results = [];
      for (key in content) {
        value = content[key];
        start_humidity = parseFloat(value['start_humidity']).toFixed(1);
        start_temp = parseFloat(value['start_temp']).toFixed(1);
        start_dew_point = parseFloat(value['start_dew_point']).toFixed(1);
        final_temp = parseFloat(value['final_temp']).toFixed(1);
        final_humidity = parseFloat(value['final_humidity']).toFixed(1);
        final_dew_point = parseFloat(value['final_dew_point']).toFixed(1);
        cloud_base = value['cloud_base'];
        color = value['color'];
        this.set('start_humidity', start_humidity);
        this.set('start_temp', start_temp);
        this.set('start_dew_point', start_dew_point);
        this.set('final_temp', final_temp);
        this.set('final_humidity', final_humidity);
        this.set('final_dew_point', final_dew_point);
        this.set('color', color);
        if (cloud_base === 'Clear') {
          results.push(this.set('cloud_base', cloud_base));
        } else {
          results.push(this.set('cloud_base', parseFloat(cloud_base).toFixed(1)));
        }
      }
      return results;
    },
    actions: {
      parent_action: function() {
        var is_visible, trial_num;
        this.toggleProperty('is_visible');
        is_visible = this.get('is_visible');
        trial_num = this.get('trial_num');
        return this.sendAction('parent_action', is_visible, trial_num);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});