define('client/thinkspace/common/invitations/show/controller', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Controller.extend({
    queryParams: ['email'],
    c_new_user: ns['default'].to_p('user', 'new'),
    email: null
  });

  exports['default'] = ___DefaultExportObject___;;

});