define('client/components/thinkspace/builder/steps/overview', ['exports', 'ember', 'totem/ns', 'totem/ds/associations', 'thinkspace-builder/components/wizard/steps/base'], function (exports, ember, ns, ta, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    ttz: ember['default'].inject.service(),
    model: ember['default'].computed.reads('builder.model'),
    title: ember['default'].computed.reads('model.title'),
    release_at: ember['default'].computed.reads('model.release_at'),
    due_at: ember['default'].computed.reads('model.due_at'),
    friendly_release_at: ember['default'].computed('release_at', function() {
      var date;
      date = this.get('release_at');
      if (!ember['default'].isPresent(date)) {
        return 'N/A';
      }
      return this.get('ttz').format(date, {
        format: 'MMMM Do YYYY, h:mm z',
        zone: this.get('ttz').get_client_zone_iana()
      });
    }),
    friendly_due_at: ember['default'].computed('due_at', function() {
      var date;
      date = this.get('due_at');
      if (!ember['default'].isPresent(date)) {
        return 'N/A';
      }
      return this.get('ttz').format(date, {
        format: 'MMMM Do YYYY, h:mm z',
        zone: this.get('ttz').get_client_zone_iana()
      });
    }),
    r_cases_details: ns['default'].to_r('builder', 'cases', 'details'),
    r_cases_phases: ns['default'].to_r('builder', 'cases', 'phases'),
    r_cases_logistics: ns['default'].to_r('builder', 'cases', 'logistics'),
    r_phases_edit: ns['default'].to_r('builder', 'phases', 'edit'),
    c_phase: ns['default'].to_p('builder', 'steps', 'parts', 'phases', 'phase'),
    c_phase_errors: ns['default'].to_p('builder', 'shared', 'phases', 'errors'),
    init: function() {
      this._super();
      return this.set_phases().then((function(_this) {
        return function() {
          return _this.set_all_data_loaded();
        };
      })(this));
    },
    set_phases: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model;
          model = _this.get('model');
          return _this.tc.query(ns['default'].to_p('assignment'), {
            id: model.get('id'),
            action: 'load'
          }, {
            single: true
          }).then(function() {
            return model.get('active_phases').then(function(phases) {
              _this.set('phases', phases);
              return resolve();
            }, function(error) {
              return _this.error(error);
            });
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    actions: {
      activate: function() {
        this.totem_messages.show_loading_outlet();
        return this.get('model').activate().then((function(_this) {
          return function() {
            return _this.totem_messages.hide_loading_outlet();
          };
        })(this));
      },
      inactivate: function() {
        this.totem_messages.show_loading_outlet();
        return this.get('model').inactivate().then((function(_this) {
          return function() {
            return _this.totem_messages.hide_loading_outlet();
          };
        })(this));
      },
      exit: function() {
        return this.get('builder').transition_to_assignment();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});