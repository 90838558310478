define('client/thinkspace/casespace/phases/show/controller', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Controller.extend({
    casespace: ember['default'].inject.service(),
    queryParams: ['query_id', 'phase_settings'],
    query_id: null,
    phase_settings: null,
    phase_settings_obs: ember['default'].observer('phase_settings', function() {
      var obj, phase_settings, string;
      phase_settings = this.get('phase_settings');
      if (!ember['default'].isPresent(phase_settings)) {
        return;
      }
      string = decodeURIComponent(phase_settings);
      if (!ember['default'].isPresent(string)) {
        return;
      }
      obj = JSON.parse(string);
      return this.set_phase_settings(obj);
    }),
    c_phase_show: ns['default'].to_p('phase', 'show'),
    set_phase_settings: function(obj) {
      this.get('casespace').set_phase_settings(obj);
      return this.set('phase_settings', encodeURIComponent(JSON.stringify(obj)));
    },
    reset_phase_settings: function() {
      return this.set('phase_settings', null);
    },
    reset_query_id: function() {
      return this.set('query_id', null);
    },
    actions: {
      submit_phase: function() {
        return this.send('submit');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});