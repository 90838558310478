define('client/thinkspace/casespace/readiness_assurance/dashboard/controller', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Controller.extend({
    c_dashboard: ns['default'].to_p('ra:admin', 'menu', 'dashboard')
  });

  exports['default'] = ___DefaultExportObject___;;

});