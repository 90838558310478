define('client/thinkspace/casespace/case_manager/team_sets/route', ['exports', 'ember', 'thinkspace-casespace-case-manager/routes/base', 'totem/ns'], function (exports, ember, base, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    thinkspace: ember['default'].inject.service(),
    team_manager: ember['default'].inject.service(),
    titleToken: 'Team Sets',
    activate: function() {
      return this.get('thinkspace').disable_wizard_mode();
    },
    model: function(params) {
      return this.get('team_manager').get_space_from_params(params);
    },
    setupController: function(controller, model) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var team_manager;
          team_manager = _this.get('team_manager');
          return team_manager.set_space_roster(model).then(function() {
            return resolve();
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});