define('client/components/thinkspace/casespace/case_manager/assignment/wizards/casespace/steps/details', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace-case-manager/components/wizards/steps/base', 'totem/mixins/validations'], function (exports, ember, ns, base, val_mixin) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(val_mixin['default'], {
    step: 'details',
    title: null,
    page_title: ember['default'].computed(function() {
      if (this.get('model.isNew')) {
        return 'New Case';
      } else {
        return this.get('model.title');
      }
    }),
    actions: {
      complete: function() {
        var wizard_manager;
        if (!this.get('isValid')) {
          return;
        }
        wizard_manager = this.get('wizard_manager');
        wizard_manager.send_action('set_title', this.get('title'));
        return wizard_manager.send_action('complete_step', 'details');
      }
    },
    validations: {
      title: {
        presence: true
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});