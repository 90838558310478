define('client/components/thinkspace/artifact/bucket/edit', ['exports', 'ember', 'totem/ns', 'totem/mixins/ckeditor', 'thinkspace-base/components/base'], function (exports, ember, ns, ckeditor_mixin, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend(ckeditor_mixin['default'], {
    ckeditor_tag: null,
    editor_loaded: false,
    actions: {
      select: function() {
        return this.sendAction('select', this.get('model'));
      },
      exit: function() {
        return this.sendAction('select', null);
      },
      save: function() {
        var bucket, instructions;
        bucket = this.get('model');
        instructions = (this.get('editor_loaded') && this.ckeditor_value()) || this.get('model.instructions');
        bucket.set('instructions', instructions);
        return bucket.save().then((function(_this) {
          return function(bucket) {
            _this.totem_messages.api_success({
              source: _this,
              model: bucket,
              action: 'update',
              i18n_path: ns['default'].to_o('bucket', 'save')
            });
            return _this.send('exit');
          };
        })(this), (function(_this) {
          return function(error) {
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: bucket,
              action: 'update'
            });
          };
        })(this));
      },
      cancel: function() {
        var bucket;
        bucket = this.get('model');
        if (bucket.get('isDirty')) {
          bucket.rollback();
        }
        return this.send('exit');
      }
    },
    didInsertElement: function() {
      var edit_area, options;
      edit_area = this.$('textarea.artifact_bucket-instructions');
      if (!edit_area) {
        return;
      }
      this.set('ckeditor_tag', edit_area);
      options = {
        height: 300
      };
      return this.ckeditor_load().then((function(_this) {
        return function() {
          return edit_area.ckeditor((function() {
            return _this.set('editor_loaded', true);
          }), options);
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.totem_error["throw"](_this, error);
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});