define('client/services/thinkspace/readiness_assurance/ra', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___,
    slice = [].slice;

  ___DefaultExportObject___ = ember['default'].Service.extend({
    csse: ember['default'].inject.service(ns['default'].to_p('casespace', 'server_events')),
    messages: ember['default'].inject.service(ns['default'].to_p('casespace', 'messages')),
    c_messages: ns['default'].to_p('readiness_assurance', 'shared', 'messages', 'view'),
    c_irat_assessment_show: ns['default'].to_p('ra:assessment', 'irat', 'show'),
    c_trat_assessment_show: ns['default'].to_p('ra:assessment', 'trat', 'show'),
    c_trat_overview_assessment_show: ns['default'].to_p('ra:assessment', 'trat_overview', 'show'),
    c_irat_questions: ns['default'].to_p('ra:response', 'irat', 'questions'),
    c_irat_question: ns['default'].to_p('ra:response', 'irat', 'question'),
    c_irat_justification: ns['default'].to_p('ra:response', 'irat', 'justification'),
    c_trat_room_users: ns['default'].to_p('ra:response', 'trat', 'users'),
    c_trat_questions: ns['default'].to_p('ra:response', 'trat', 'questions'),
    c_trat_question: ns['default'].to_p('ra:response', 'trat', 'question'),
    c_trat_chats: ns['default'].to_p('ra:response', 'trat', 'chats'),
    c_trat_chat: ns['default'].to_p('ra:response', 'trat', 'chat'),
    c_trat_justification: ns['default'].to_p('ra:response', 'trat', 'justification'),
    c_shared_radio_buttons: ns['default'].to_p('readiness_assurance', 'shared', 'radio', 'buttons'),
    c_shared_radio_button: ns['default'].to_p('readiness_assurance', 'shared', 'radio', 'button'),
    c_shared_radio_ifat_buttons: ns['default'].to_p('readiness_assurance', 'shared', 'radio', 'ifat_buttons'),
    c_shared_radio_ifat_button: ns['default'].to_p('readiness_assurance', 'shared', 'radio', 'ifat_button'),
    c_shared_timer_show: ns['default'].to_p('readiness_assurance', 'shared', 'timer', 'show'),
    init: function() {
      this._super();
      this.csse = this.get('csse');
      this.messages = this.get('messages');
      this.pubsub = this.csse.pubsub;
      this.messages_loaded = false;
      return this.timer_messages = {};
    },
    load_messages: function() {
      if (this.messages_loaded) {
        return;
      }
      this.messages.load({
        room: this.current_user_room()
      });
      return this.messages_loaded = true;
    },
    join_with_current_user: function() {
      var args, ref;
      args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      return (ref = this.csse).join_assignment_with_current_user.apply(ref, args);
    },
    current_user_room: function() {
      var args, ref;
      args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      return (ref = this.csse).assignment_current_user_room.apply(ref, args);
    },
    phase_ownerable_room: function() {
      var args, ref;
      args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      return (ref = this.csse).phase_ownerable_room.apply(ref, args);
    },
    leave_all_except_current_user_server_event_room: function() {
      var args, ref;
      args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      return (ref = this.csse).leave_all_except_assignment_current_user_room.apply(ref, args);
    },
    current_user_server_event_room: function() {
      var args, ref;
      args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      return (ref = this.csse).assignment_current_user_server_event_room.apply(ref, args);
    },
    join_admin_room: function() {
      return this.csse.join({
        room: this.get_admin_room()
      });
    },
    leave_all_except_admin_room: function() {
      return this.csse.leave_all({
        except: this.get_admin_room()
      });
    },
    get_admin_room: function() {
      return this.csse.assignment_room('admin');
    },
    join_timer_room: function(model, options) {
      var room, room_event;
      if (options == null) {
        options = {};
      }
      room = this.pubsub.room_with_current_user(model);
      room_event = 'timer';
      options.room = room;
      options.room_event = room_event;
      this.pubsub.join(options);
      return {
        room: room,
        room_event: room_event
      };
    },
    start_timers: function(options) {
      var event, room, room_type;
      room = options.room;
      event = this.pubsub.client_event('timers');
      room_type = null;
      return this.pubsub.message_to_rooms_members(event, room, {
        room_type: room_type
      });
    },
    get_timer_message: function(room) {
      return this.timer_messages[room];
    },
    set_timer_message: function(room, message) {
      return this.timer_messages[room] = message;
    },
    tracker_room: function() {
      return this.csse.assignment_room();
    },
    tracker: function() {
      var room;
      room = this.tracker_room();
      return this.pubsub.tracker({
        room: room
      });
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});