define('client/models/thinkspace/indented_list/response', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].belongs_to('indented:list', {
    reads: {}
  }), ta['default'].polymorphic('ownerable')), {
    value: ta['default'].attr(),
    ownerable_id: ta['default'].attr('number'),
    ownerable_type: ta['default'].attr('string')
  });

  exports['default'] = ___DefaultExportObject___;;

});