define('client/templates/components/thinkspace/lab/builder/lab/category/destroy', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ts-wizard");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","ts-wizard_content");
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-wizard_row");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-grid_columns small-12");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","ts-forms_heading");
        var el6 = dom.createElement("h4");
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","ts-wizard_red");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("br");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-wizard_row");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ts-grid_columns small-12");
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"class","btn btn-default btn-small left");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"class","btn btn-primary btn-small right");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, inline = hooks.inline, content = hooks.content, element = hooks.element;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [0, 0]);
        var element1 = dom.childAt(element0, [0, 0, 0, 0]);
        var element2 = dom.childAt(element0, [1, 0]);
        var element3 = dom.childAt(element2, [0]);
        var element4 = dom.childAt(element2, [1]);
        var morph0 = dom.createMorphAt(dom.childAt(element1, [0]),0,0);
        var morph1 = dom.createMorphAt(element1,2,2);
        var morph2 = dom.createMorphAt(element3,0,0);
        var morph3 = dom.createMorphAt(element4,0,0);
        inline(env, morph0, context, "t", ["builder.lab.admin.category.destroy_prompt"], {});
        content(env, morph1, context, "admin.selected_category.title");
        element(env, element3, context, "action", ["cancel"], {"on": "click"});
        inline(env, morph2, context, "t", ["builder.lab.admin.buttons.no"], {});
        element(env, element4, context, "action", ["delete"], {"on": "click"});
        inline(env, morph3, context, "t", ["builder.lab.admin.buttons.yes"], {});
        return fragment;
      }
    };
  }()));

});