define('client/components/thinkspace/casespace/case_manager/assignment/wizards/assessment/steps/settings/qualitative', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace-case-manager/components/wizards/steps/base', 'totem/mixins/validations'], function (exports, ember, ns, base, val_mixin) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(val_mixin['default'], {
    has_qualitative_items: ember['default'].computed.notEmpty('model'),
    has_individual_comments: false,
    c_qualitative_item: ns['default'].to_p('case_manager', 'assignment', 'wizards', 'assessment', 'steps', 'settings', 'qualitative', 'item'),
    add_qualitative_item: 'add_qualitative_item',
    toggle_has_individual_comments: 'toggle_has_individual_comments',
    remove_qualitative_item: 'remove_qualitative_item',
    actions: {
      add: function() {
        return this.sendAction('add_qualitative_item');
      },
      toggle_has_individual_comments: function() {
        return this.sendAction('toggle_has_individual_comments');
      },
      remove_qualitative_item: function(item) {
        return this.sendAction('remove_qualitative_item', item);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});