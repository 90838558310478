define('client/components/thinkspace/markup/library/edit/manager/comment', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    model: null,
    all_library_tags: null,
    tagName: '',
    is_editing: false,
    is_deleting: false,
    comment: ember['default'].computed.reads('model.comment'),
    uses: ember['default'].computed.reads('model.uses'),
    all_tags: ember['default'].computed.reads('model.all_tags'),
    c_validated_input: ns['default'].to_p('common', 'shared', 'validated_input'),
    c_tag_marker: ns['default'].to_p('markup', 'library', 'edit', 'manager', 'marker'),
    c_common_dropdown: ns['default'].to_p('common', 'dropdown'),
    dropdown_tags: ember['default'].computed('all_library_tags', function() {
      var all_library_tags, arr;
      all_library_tags = this.get('all_library_tags');
      arr = [];
      all_library_tags.forEach((function(_this) {
        return function(tag) {
          return arr.pushObject({
            display: tag
          });
        };
      })(this));
      return arr;
    }),
    last_used: ember['default'].computed('model.last_used', function() {
      var last_used, model, moment_last_used;
      model = this.get('model');
      last_used = model.get('last_used');
      moment_last_used = moment.utc(last_used);
      if (last_used != null) {
        return moment_last_used.utc().format('MMMM Do, YYYY');
      } else {
        return 'Not yet used';
      }
    }),
    requires_input: ember['default'].computed('is_editing', 'is_deleting', function() {
      return this.get('is_editing') || this.get('is_deleting');
    }),
    actions: {
      "delete": function() {
        return this.set('is_deleting', true);
      },
      edit: function() {
        return this.set('is_editing', true);
      },
      cancel_delete: function() {
        return this.set('is_deleting', false);
      },
      cancel_edit: function() {
        this.set('comment', this.get('model.comment'));
        this.set('all_tags', this.get('model.all_tags'));
        return this.set('is_editing', false);
      },
      confirm_edit: function() {
        var comment, model;
        model = this.get('model');
        comment = this.get('comment');
        model.set('comment', comment);
        model.set('all_tags', this.get('all_tags'));
        return model.save().then((function(_this) {
          return function(saved_comment) {
            return _this.set('is_editing', false);
          };
        })(this), (function(_this) {
          return function(error) {
            return console.log('Error in submitting edited comment.');
          };
        })(this));
      },
      confirm_delete: function() {
        var model;
        model = this.get('model');
        model.deleteRecord();
        return model.save();
      },
      remove_tag: function(tag) {
        var model;
        model = this.get('model');
        return this.sendAction('remove_tag', model, tag);
      },
      add_tag: function(tag) {
        var model;
        model = this.get('model');
        return this.sendAction('add_tag', model, tag.display);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});