define('client/components/thinkspace/readiness_assurance/admin/shared/users/select', ['exports', 'ember', 'totem/ns', 'thinkspace-readiness-assurance/mixins/data_rows', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, ns, m_data_rows, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(m_data_rows['default'], {
    show_all: false,
    user_member_rows: null,
    columns_per_row: ember['default'].computed(function() {
      return (ember['default'].isPresent(this.rad.width_selector) && this.rad.width_selector) || 1;
    }),
    init: function() {
      this._super();
      return this.validate = this.rad.validate;
    },
    willInsertElement: function() {
      return this.setup();
    },
    setup: function() {
      this.all_users = this.rad.get_all_users();
      this.users = this.rad.get_users() || [];
      this.set('show_all', this.rad.get_show_all());
      if (this.rad.select_all_users()) {
        this.send('select_all');
      }
      return this.set('user_member_rows', this.get_data_rows(this.sort_users(this.all_users)));
    },
    actions: {
      show_all: function() {
        return this.set('show_all', true);
      },
      hide_all: function() {
        return this.set('show_all', false);
      },
      select_all: function() {
        var i, len, ref, user;
        this.users.clear();
        ref = this.all_users;
        for (i = 0, len = ref.length; i < len; i++) {
          user = ref[i];
          this.users.pushObject(user);
        }
        return this.set_users();
      },
      deselect_all: function() {
        this.users.clear();
        return this.set_users();
      },
      select: function(user) {
        var index;
        index = this.users.indexOf(user);
        if (index >= 0) {
          this.users.removeAt(index);
        } else {
          this.users.pushObject(user);
        }
        return this.set_users();
      }
    },
    set_users: function() {
      this.rad.set_users(this.users);
      if (this.validate) {
        return this.sendAction('validate');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});