define('client/components/thinkspace/common/shared/radio', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    toggle_action: null,
    checked: false,
    disabled: false,
    label: null,
    disable_click: false,
    "class": null,
    classNameBindings: ['checked:is-checked', 'class'],
    classNames: ['ts-radio_button'],
    click: function() {
      if (!this.get('disable_click')) {
        return this.toggle_checked();
      }
    },
    toggle_checked: function() {
      this.toggleProperty('checked');
      if (this.get('toggle_action')) {
        return this.sendAction('toggle_action', this.get('checked'));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});