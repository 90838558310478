define('client/components/thinkspace/indented_list/list/expert_responses', ['exports', 'ember', 'totem/ns', 'totem/ds/associations', 'totem/ajax', 'thinkspace-base/components/base'], function (exports, ember, ns, ta, ajax, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    classNames: ['indented-list'],
    c_response_items: ns['default'].to_p('indented_list', 'response', 'items'),
    responses: ember['default'].computed(function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var sub_action;
          sub_action = _this.get('sub_action') || null;
          if (sub_action === 'expert') {
            return _this.send_responses_ajax_request('indented:expert_response', sub_action).then(function(responses) {
              return resolve(responses);
            }, function(error) {
              return _this.totem_messages.api_failure(error, {
                source: _this,
                model: _this.get('model')
              });
            });
          } else {
            if (ember['default'].isBlank(sub_action)) {
              sub_action = 'user';
            }
            return _this.send_responses_ajax_request('indented:response', sub_action).then(function(items) {
              var response;
              response = _this.create_response(items);
              return resolve([response]);
            }, function(error) {
              return _this.totem_messages.api_failure(error, {
                source: _this,
                model: _this.get('model')
              });
            });
          }
        };
      })(this));
      return ta['default'].PromiseArray.create({
        promise: promise
      });
    }),
    send_responses_ajax_request: function(type, sub_action) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var list, query;
          list = _this.get('model');
          query = _this.totem_scope.get_view_query(list, {
            sub_action: sub_action
          });
          query.data = {
            auth: query.auth
          };
          query.model = list;
          return ajax['default'].object(query).then(function(payload) {
            var records;
            if (sub_action === 'user') {
              return resolve(payload);
            }
            records = ajax['default'].normalize_and_push_payload(type, payload);
            return resolve(records);
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    },
    create_response: function(items) {
      var list, response, store;
      store = this.totem_scope.get_store();
      list = this.get('model');
      response = store.createRecord(ns['default'].to_p('indented:response'), {
        ownerable_id: this.totem_scope.get_ownerable_id(),
        ownerable_type: this.totem_scope.get_ownerable_type(),
        value: {
          items: items
        }
      });
      response.set(ns['default'].to_p('indented:list'), list);
      return response;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});