define('client/components/thinkspace/lab/observation/vet_med', ['exports', 'ember', 'totem/ns', 'thinkspace-lab/vet_med_lab_observation', 'thinkspace-base/components/base'], function (exports, ember, ns, lab_observation, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    init: function() {
      this._super();
      return this.set('lab_observation', lab_observation['default'].create({
        lab: this.get('lab'),
        lab_result: this.get('lab_result'),
        result: this.get('result'),
        component: this,
        observation_key: this.get('observation_key')
      }));
    },
    observation_key: ember['default'].computed(function() {
      return this.get('column.observation');
    }),
    c_select: ns['default'].to_p('lab:observation', 'vet_med', 'select'),
    c_input: ns['default'].to_p('lab:observation', 'vet_med', 'input'),
    c_correctable: ns['default'].to_p('lab:observation', 'vet_med', 'correctable'),
    input_type: ember['default'].computed(function() {
      return this.get('lab_observation').get_result_value('input_type');
    }),
    is_select: ember['default'].computed.equal('input_type', 'select'),
    is_input: ember['default'].computed.equal('input_type', 'input'),
    is_correctable: ember['default'].computed.equal('input_type', 'correctable')
  });

  exports['default'] = ___DefaultExportObject___;;

});