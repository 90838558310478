define('client/components/thinkspace/diagnostic_path/path_item/mechanism', ['exports', 'ember', 'totem/ns', 'thinkspace-diagnostic-path/path_manager', 'thinkspace-base/components/base'], function (exports, ember, ns, path_manager, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: 'li',
    path: null,
    classNames: ['diag-path_list-item-mechanism', 'diag-path_list-item'],
    didInsertElement: function() {
      return this.$('.diag-path_mechanism-list').sortable({
        group: 'path-obs-list',
        clone: true,
        consume: true,
        exclude: '.sortable-exclude',
        component: this,
        drop: false
      });
    },
    sortable_dragend: function(event) {
      var $item, component;
      event.preventDefault();
      event.stopPropagation();
      component = ember['default'].get(event, 'dropped_container.options.component');
      $item = event.dragged_item;
      return component.dragend_new_mechanism(event);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});