define('client/thinkspace/team/assign/resource/view', ['exports', 'ember', 'ember-data', 'totem/ns'], function (exports, ember, ds, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].View.extend({
    tagName: 'ul',
    classNames: ['team_assign-resource-list'],
    classNameBindings: ['is_selected_resource:team_assign-selected-resource'],
    is_selected_resource: ember['default'].computed('controller.selected_resource', function() {
      return this.get('resource') === this.get('controller.selected_resource');
    }),
    teams_visible: true,
    resource_teams_visible: ember['default'].observer('controller.resource_teams_visible', function() {
      return this.set('teams_visible', this.get('controller.resource_teams_visible'));
    }),
    resource_teams: ember['default'].computed('resource_teamables.@each', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get('resource_teamables').then(function(teamables) {
            var team_promises;
            team_promises = teamables.getEach('team');
            return ember['default'].RSVP.Promise.all(team_promises).then(function(teams) {
              return resolve(teams.sortBy('title'));
            });
          });
        };
      })(this));
      return ds['default'].PromiseArray.create({
        promise: promise
      });
    }),
    resource_teamables: ember['default'].computed(function() {
      var controller, resource;
      resource = this.get('resource');
      controller = this.get('controller');
      return this.get('controller.store').filter(ns['default'].to_p('team_teamable'), (function(_this) {
        return function(team_teamable) {
          return controller.record_is_polymorphic(resource, team_teamable, 'teamable');
        };
      })(this));
    }),
    actions: {
      toggle_teams_visible: function() {
        this.toggleProperty('teams_visible');
        return false;
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});