define('client/components/thinkspace/readiness_assurance/admin/trat/summary/teams/team', ['exports', 'ember', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    init: function() {
      var qm;
      this._super();
      qm = this.get('qms.firstObject');
      this.qid = qm.qid;
      this.qnumber = qm.qn;
      return this.question = qm.question;
    },
    show_justification: false,
    show_chat: false,
    actions: {
      show_justification: function() {
        return this.set('show_justification', true);
      },
      hide_justification: function() {
        return this.set('show_justification', false);
      },
      show_chat: function() {
        return this.set('show_chat', true);
      },
      hide_chat: function() {
        return this.set('show_chat', false);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});