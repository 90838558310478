define('client/components/thinkspace/peer_assessment/overview', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'thinkspace-base/components/base'], function (exports, ember, ns, ajax, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    calculated_overview: null,
    assessment: null,
    c_overview_type: ns['default'].to_p('tbl:overview', 'type', 'base'),
    init: function() {
      this._super();
      return this.set_user_data().then((function(_this) {
        return function() {
          return _this.set_assessment().then(function() {
            return _this.set('all_data_loaded', true);
          });
        };
      })(this));
    },
    set_user_data: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.overview_ajax('review', 'reviews').then(function(payload) {
            console.log("[tbl:overview] Payload returned as: ", payload);
            return resolve(payload);
          });
        };
      })(this));
    },
    overview_ajax: function(type, sub_action, options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          query = _this.get_overview_query(sub_action, options);
          return ajax['default'].object(query).then(function(payload) {
            _this.set('calculated_overview', payload);
            return resolve(payload);
          });
        };
      })(this));
    },
    set_assessment: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model;
          model = _this.get('model');
          return model.get('tbl:assessment').then(function(assessment) {
            _this.set('assessment', assessment);
            return resolve();
          });
        };
      })(this));
    },
    get_overview_query: function(sub_action, options) {
      var overview, query;
      if (options == null) {
        options = {};
      }
      overview = this.get('model');
      query = this.totem_scope.get_view_query(overview, {
        sub_action: sub_action
      });
      query.verb = 'get';
      query.model = overview;
      query.data = options.data || {};
      query.data.auth = query.auth;
      this.totem_scope.add_authable_to_query(query);
      return query;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});