define('client/components/thinkspace/builder/phases/edit/settings', ['exports', 'ember', 'totem/ns', 'thinkspace-builder/components/wizard/steps/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    totem_data_config: {
      ability: {
        ajax_source: true,
        ajax_method: 'builder_abilities'
      }
    },
    model: null,
    has_changes: false,
    team_sets: null,
    team_categories: null,
    selected_team_set: null,
    selected_team_category: null,
    default_states: [
      {
        id: 'unlocked',
        display: 'Unlocked'
      }, {
        id: 'locked',
        display: 'Locked'
      }
    ],
    max_score: ember['default'].computed.reads('model.max_score'),
    configuration_validate: ember['default'].computed.reads('model.configuration_validate'),
    auto_score: ember['default'].computed.reads('model.has_auto_score'),
    complete_phase: ember['default'].computed.reads('model.has_complete_phase'),
    unlock_phase: ember['default'].computed.reads('model.has_unlock_phase'),
    default_state_locked: ember['default'].computed.equal('model.default_state', 'locked'),
    selected_team_set_id: ember['default'].computed.reads('model.team_set_id'),
    selected_team_category_id: ember['default'].computed.reads('model.team_category_id'),
    is_team_based: ember['default'].computed.or('selected_team_set_id', 'selected_team_category_id'),
    has_team_sets: ember['default'].computed.notEmpty('team_sets'),
    has_team_categories: ember['default'].computed.notEmpty('team_categories'),
    c_checkbox: ns['default'].to_p('common', 'shared', 'checkbox'),
    c_dropdown: ns['default'].to_p('common', 'dropdown'),
    confirmation_observer: ember['default'].observer('is_team_based', 'auto_score', 'configuration_validate', 'max_score', function() {
      return this.set_has_changes();
    }),
    init: function() {
      this._super();
      this.totem_data.ability.unload();
      return this.set_team_sets().then((function(_this) {
        return function() {
          return _this.set_team_categories().then(function() {
            return _this.set_selected_team_set().then(function() {
              return _this.set_selected_team_category().then(function() {
                console.info("[builder] Phase settings component: ", _this);
                return _this.set_all_data_loaded();
              });
            });
          });
        };
      })(this));
    },
    set_team_sets: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get('builder').get_space().then(function(space) {
            return space.get_team_sets().then(function(team_sets) {
              _this.set('team_sets', team_sets);
              return resolve();
            }, function(error) {
              return _this.error(error);
            });
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    set_team_categories: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.tc.find_all(ns['default'].to_p('team_category')).then(function(team_categories) {
            _this.set('team_categories', team_categories);
            return resolve();
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    set_selected_team_set: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var team_set_id;
          team_set_id = _this.get('selected_team_set_id');
          if (ember['default'].isPresent(team_set_id)) {
            return _this.tc.find_record(ns['default'].to_p('team_set'), team_set_id).then(function(team_set) {
              _this.set('selected_team_set', team_set);
              return resolve();
            }, function(error) {
              return _this.error(error);
            });
          } else {
            return resolve();
          }
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    set_selected_team_category: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var team_category_id;
          team_category_id = _this.get('selected_team_category_id');
          if (ember['default'].isPresent(team_category_id)) {
            return _this.tc.find_record(ns['default'].to_p('team_category'), team_category_id).then(function(team_category) {
              _this.set('selected_team_category', team_category);
              return resolve();
            }, function(error) {
              return _this.error(error);
            });
          } else {
            return resolve();
          }
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    get_configuration_values: function() {
      var values;
      return values = {
        max_score: this.get('max_score'),
        configuration_validate: this.get('configuration_validate'),
        team_based: this.get('team_based'),
        auto_score: this.get('auto_score'),
        complete_phase: this.get('complete_phase'),
        unlock_phase: this.get('unlock_phase')
      };
    },
    set_has_changes: function() {
      return this.set('has_changes', true);
    },
    reset_has_changes: function() {
      return this.set('has_changes', false);
    },
    exit: function() {
      var confirm, has_changes;
      has_changes = this.get('has_changes');
      if (has_changes) {
        confirm = window.confirm('Are you sure you want to exit?  You have made unsaved changes.');
        if (!confirm) {
          return;
        }
        return this.sendAction('back');
      } else {
        return this.sendAction('back');
      }
    },
    model_state_change: function(action) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model;
          model = _this.get('model');
          if (!ember['default'].isPresent(model)) {
            return;
          }
          _this.totem_messages.show_loading_outlet();
          if (model[action] == null) {
            return;
          }
          return model[action]().then(function(phase) {
            _this.totem_messages.hide_loading_outlet();
            return resolve();
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    actions: {
      activate: function() {
        return this.model_state_change('activate');
      },
      archive: function() {
        return this.model_state_change('archive');
      },
      inactivate: function() {
        return this.model_state_change('inactivate');
      },
      save: function() {
        var default_state, is_team_based, ns_phase, obj, phase, query, store;
        phase = this.get('model');
        is_team_based = this.get('is_team_based');
        default_state = this.get('default_state_locked') ? 'locked' : 'unlocked';
        ns_phase = ns['default'].to_p('phase');
        store = this.totem_scope.get_store();
        query = (
          obj = {
            id: phase.get('id'),
            action: '',
            verb: 'put',
            configuration: this.get_configuration_values()
          },
          obj["" + ns_phase] = {
            default_state: default_state
          },
          obj
        );
        if (is_team_based) {
          query[ns_phase].team_set_id = this.get('selected_team_set.id');
          query[ns_phase].team_category_id = this.get('selected_team_category.id');
        } else {
          query[ns_phase].team_set_id = null;
          query[ns_phase].team_category_id = null;
        }
        this.totem_messages.show_loading_outlet();
        return this.tc.query(ns['default'].to_p('phase'), query, {
          single: true
        }).then((function(_this) {
          return function(phase) {
            _this.reset_has_changes();
            _this.totem_messages.hide_loading_outlet();
            return _this.exit();
          };
        })(this));
      },
      cancel: function() {
        return this.exit();
      },
      toggle_is_team_based: function() {
        if (!this.get('can.team_based')) {
          return;
        }
        return this.toggleProperty('is_team_based');
      },
      toggle_auto_score: function() {
        if (!this.get('can.auto_score')) {
          return;
        }
        return this.toggleProperty('auto_score');
      },
      toggle_unlock_phase: function() {
        if (!this.get('can.unlock_phase')) {
          return;
        }
        return this.toggleProperty('unlock_phase');
      },
      toggle_configuration_validate: function() {
        if (!this.get('can.configuration_validate')) {
          return;
        }
        return this.toggleProperty('configuration_validate');
      },
      toggle_default_state_locked: function() {
        if (!this.get('can.default_state')) {
          return;
        }
        return this.toggleProperty('default_state_locked');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});