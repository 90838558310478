define('client/components/thinkspace/weather_forecaster/forecast/assessment_item', ['exports', 'ember', 'totem/ns', 'ember-data'], function (exports, ember, ns, ds) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Component.extend({
    tagName: '',
    c_response: ember['default'].computed(function() {
      var metadata;
      metadata = this.get('item.response_metadata') || {};
      switch (metadata.type) {
        case 'input':
          return ns['default'].to_p('wf:response', 'input');
        case 'radio':
          return ns['default'].to_p('wf:response', 'radio');
        case 'checkbox':
          return ns['default'].to_p('wf:response', 'checkbox');
        default:
          return ns['default'].to_p('wf:response', 'error');
      }
    }),
    item_header: ember['default'].computed(function() {
      return this.get_assessment_item_or_item_value('item_header');
    }),
    presentation: ember['default'].computed(function() {
      return this.get_assessment_item_or_item_value('presentation');
    }),
    help_tip: ember['default'].computed(function() {
      return this.get('model.help_tip') || this.get('item.help_tip');
    }),
    metadata: ember['default'].computed(function() {
      return this.get('item.response_metadata');
    }),
    has_help_tip: ember['default'].computed.bool('help_tip'),
    get_assessment_item_or_item_value: function(prop) {
      var value;
      value = this.get("model." + prop) || this.get("item." + prop);
      return value && value.htmlSafe();
    },
    actions: {
      show_help: function() {
        return this.sendAction('show_help', this.get('help_tip'));
      },
      save: function(values) {
        return this.sendAction('save', this.get('response'), values);
      }
    },
    has_score: ember['default'].computed.reads('response.has_score'),
    is_correct_class: ember['default'].computed('has_score', function() {
      if (!this.get('has_score')) {
        return null;
      }
      return (this.get('response.is_correct') && 'correct') || 'incorrect';
    }),
    item: null,
    response: null,
    didInsertElement: function() {
      var assessment_item, forecast;
      forecast = this.get('forecast');
      assessment_item = this.get('model');
      return assessment_item.get(ns['default'].to_p('wf:item')).then((function(_this) {
        return function(item) {
          return forecast.response_for_assessment_item(assessment_item).then(function(response) {
            if (!response) {
              response = forecast.store.createRecord(ns['default'].to_p('wf:response'));
              response.set_associations(forecast, assessment_item);
            }
            _this.set('response', response);
            return _this.set('item', item);
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});