define('client/thinkspace/team/teams/edit/view', ['exports', 'ember', 'thinkspace-team/views/form'], function (exports, ember, base) {

	'use strict';

	var ___DefaultExportObject___;

	___DefaultExportObject___ = base['default'].extend();

	exports['default'] = ___DefaultExportObject___;;

});