define('client/initializers/totem_simple_auth', ['exports', 'ember', 'totem-simple-auth/authenticator', 'totem-simple-auth/authorizer', 'totem-simple-auth/authenticator_switch_user', 'totem-simple-auth/cookie_store'], function (exports, ember, authenticator, authorizer, authenticator_switch_user, cookie_store) {

  'use strict';

  var ___DefaultExportObject___, initializer;

  initializer = {
    name: 'totem-simple-auth',
    after: ['totem', 'simple-auth-devise'],
    before: ['simple-auth'],
    initialize: function(container, app) {
      container.register('authenticator:totem', authenticator['default']);
      container.register('authenticator_switch_user:totem', authenticator_switch_user['default']);
      container.register('authorizer:totem', authorizer['default']);
      return container.register('simple-auth-session-store:totem-cookie-store', cookie_store['default']);
    }
  };

  ___DefaultExportObject___ = initializer;

  exports['default'] = ___DefaultExportObject___;;

});