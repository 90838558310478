define('client/thinkspace/casespace/case_manager/assignments/edit/controller', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace-case-manager/controllers/assignment_base'], function (exports, ember, ns, base) {

	'use strict';

	var ___DefaultExportObject___;

	___DefaultExportObject___ = base['default'].extend();

	exports['default'] = ___DefaultExportObject___;;

});