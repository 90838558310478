define('client/components/thinkspace/readiness_assurance/admin/trat/summary/answers/answer', ['exports', 'ember', 'totem/ns', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    qnumber: ember['default'].computed.reads('qms.firstObject.qn'),
    qid: ember['default'].computed.reads('qms.firstObject.qid'),
    question: ember['default'].computed.reads('qms.firstObject.question'),
    choice_counts: null,
    willInsertElement: function() {
      var i, len, qm, ref;
      ref = this.qms;
      for (i = 0, len = ref.length; i < len; i++) {
        qm = ref[i];
        qm.register_change_callback(this, 'handle_question_change');
      }
      return this.set_choice_counts();
    },
    set_choice_counts: function() {
      var choice, choices, cid, correct, correct_id, count, i, label, len, qid, qms, question, results;
      qid = this.get('qid');
      question = this.assessment.get_question_by_id(qid);
      choices = question.choices;
      correct_id = this.get("assessment.answers.correct." + qid);
      results = [];
      for (i = 0, len = choices.length; i < len; i++) {
        choice = choices[i];
        cid = choice.id;
        label = choice.label;
        qms = this.qms.filterBy('answer_id', cid);
        count = ember['default'].isPresent(qms) ? qms.length : 0;
        correct = cid === correct_id;
        results.push({
          label: label,
          count: count,
          correct: correct
        });
      }
      this.set_max_count(results);
      return this.set('choice_counts', results);
    },
    set_max_count: function(results) {
      var counts, i, len, max, result, results1;
      counts = results.mapBy('count');
      max = counts.sort().get('lastObject');
      if (!(max > 0)) {
        return;
      }
      results1 = [];
      for (i = 0, len = results.length; i < len; i++) {
        result = results[i];
        if (result.count === max) {
          results1.push(result.max = true);
        } else {
          results1.push(void 0);
        }
      }
      return results1;
    },
    handle_question_change: function(qm, key) {
      return this.set_choice_counts();
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});