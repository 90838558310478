define('client/components/thinkspace/common/user/new', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'totem/mixins/validations', 'totem/util', 'thinkspace-base/components/base', 'thinkspace-common/mixins/sign_up_terms'], function (exports, ember, ns, ajax, val_mixin, util, base_component, sign_up_terms) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend(val_mixin['default'], sign_up_terms['default'], {
    tagName: '',
    first_name: null,
    last_name: null,
    user_email: null,
    password: null,
    fields: ['first_name', 'last_name', 'email', 'password'],
    taken_emails: [],
    email_taken_message: 'Email has already been taken',
    is_instructor: false,
    is_student: true,
    invitation_present: false,
    invitation_invalid: ember['default'].computed.notEmpty('invitation_status'),
    invitation_status: null,
    invitation_accepted: false,
    attempts: 0,
    initial_validate_first_name: ember['default'].computed.gt('attempts', 0),
    initial_validate_last_name: ember['default'].computed.gt('attempts', 0),
    initial_validate_email: ember['default'].computed('attempts', 'email', function() {
      if (this.get('email')) {
        return true;
      }
      return this.get('attempts') > 0;
    }),
    initial_validate_password: ember['default'].computed.gt('attempts', 0),
    email: ember['default'].computed.reads('user_email'),
    email_provided: ember['default'].computed.notEmpty('user_email'),
    lock_email: ember['default'].computed.or('authenticating', 'email_provided'),
    c_radio: ns['default'].to_p('common', 'shared', 'radio'),
    c_checkbox: ns['default'].to_p('common', 'shared', 'checkbox'),
    c_validated_input: ns['default'].to_p('common', 'shared', 'validated_input'),
    c_loader: ns['default'].to_p('common', 'shared', 'loader'),
    c_pwd_meter: ns['default'].to_p('common', 'user', 'password', 'meter'),
    set_email_taken_errors: ember['default'].observer('email', function() {
      var email, email_errors, email_taken_message, taken_emails;
      email = this.get('email');
      taken_emails = this.get('taken_emails');
      email_errors = this.get('errors.email');
      email_taken_message = this.get('email_taken_message');
      if (taken_emails.contains(email)) {
        if (!email.contains(email_taken_message)) {
          return email_errors.pushObject(email_taken_message);
        }
      } else if (email_errors.contains(email_taken_message) && !(taken_emails.contains(email))) {
        return email_errors.removeObject(email_taken_message);
      }
    }),
    didInsertElement: function() {
      this.set_focus_first_input();
      return this.get_latest_terms().then((function(_this) {
        return function(tos) {
          if (ember['default'].isPresent(tos)) {
            _this.set('tos', tos);
          }
          if (ember['default'].isPresent(_this.get('token'))) {
            return _this.get_invitation_state().then(function(result) {
              if (result.state && (result.state === 'accepted' || result.state === 'autoaccepted')) {
                return _this.set('invitation_accepted', true);
              }
            });
          }
        };
      })(this));
    },
    get_invitation_state: function() {
      var options, token;
      token = this.get('token');
      options = {
        verb: 'GET',
        action: 'fetch_state',
        model: 'thinkspace/common/invitation',
        id: token
      };
      return ajax['default'].object(options);
    },
    validate_and_set_focus: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.validate().then(function(valid) {
            _this.set_focus_on_error();
            if (_this.has_validation_error()) {
              resolve(false);
            }
            return resolve(_this.get('isValid'));
          }, function(error) {
            _this.set_focus_on_error();
            return resolve(_this.get('isValid'));
          });
        };
      })(this));
    },
    has_validation_error: function() {
      var errors, field, fields, i, len;
      errors = this.get('errors');
      fields = this.get('fields');
      for (i = 0, len = fields.length; i < len; i++) {
        field = fields[i];
        if (!ember['default'].isEmpty(errors[field])) {
          return true;
        }
      }
      return false;
    },
    set_focus_on_error: function() {
      return ember['default'].run.schedule('afterRender', (function(_this) {
        return function() {
          var $input, errors, field, fields, i, len;
          errors = _this.get('errors');
          fields = _this.get('fields');
          for (i = 0, len = fields.length; i < len; i++) {
            field = fields[i];
            if (!ember['default'].isEmpty(errors[field])) {
              $input = $("form input[name='" + field + "']");
              $input.select();
              return true;
            }
          }
          return false;
        };
      })(this));
    },
    set_focus_first_input: function() {
      return $('form input').first().select();
    },
    handle_api_error: function(errors) {
      if (util['default'].starts_with(errors.user_message, 'Email')) {
        if (!this.get('errors.email').contains(errors.user_message)) {
          this.get('errors.email').pushObject(errors.user_message);
        }
        if (!this.get('taken_emails').contains(this.get('email'))) {
          this.get('taken_emails').pushObject(this.get('email'));
        }
        return this.validate_and_set_focus();
      } else if (util['default'].starts_with(errors.user_message, 'Invitation')) {
        return this.set('invitation_status', errors.user_message);
      } else {
        return this.set('api_response_status', errors.user_message);
      }
    },
    get_profile: function() {
      var is_instructor, is_student, profile;
      is_instructor = this.get('is_instructor');
      is_student = this.get('is_student');
      return profile = {
        roles: {
          student: is_student,
          instructor: is_instructor
        }
      };
    },
    actions: {
      submit: function() {
        this.incrementProperty('attempts');
        return this.validate_and_set_focus().then((function(_this) {
          return function(valid) {
            var query;
            if (!valid) {
              return;
            }
            _this.set('authenticating', true);
            query = {
              url: '/api/thinkspace/common/users',
              verb: 'POST',
              data: {
                'thinkspace/common/user': {
                  first_name: _this.get('first_name'),
                  last_name: _this.get('last_name'),
                  email: _this.get('email'),
                  password: _this.get('password'),
                  token: _this.get('token'),
                  profile: _this.get_profile()
                }
              }
            };
            return ajax['default'].object(query).then(function(payload) {
              _this.set('authenticating', false);
              return _this.sendAction('sign_in_transition', _this.get('invitable'), _this.get('email'));
            }, function(error) {
              _this.set('authenticating', false);
              return _this.handle_api_error(error.responseJSON.errors);
            });
          };
        })(this));
      },
      toggle_persist_login: function(checked) {
        this.set('persist_login', checked);
        return false;
      },
      set_is_instructor: function() {
        this.set('is_instructor', true);
        return this.set('is_student', false);
      },
      set_is_student: function() {
        this.set('is_student', true);
        return this.set('is_instructor', false);
      }
    },
    validations: {
      first_name: {
        presence: {
          message: "First name can't be blank"
        }
      },
      last_name: {
        presence: {
          message: "Last name can't be blank"
        }
      },
      email: {
        format: {
          "with": /\S+@\S+/,
          message: "Must be a valid email"
        }
      },
      password: {
        length: {
          minimum: 8,
          messages: {
            tooShort: "Password needs to be at least 8 characters long"
          }
        },
        password_strength: {}
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});