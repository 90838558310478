define('client/thinkspace/casespace/case_manager/teams/new/route', ['exports', 'ember', 'thinkspace-casespace-case-manager/routes/base', 'totem/ns'], function (exports, ember, base, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    thinkspace: ember['default'].inject.service(),
    titleToken: function(model) {
      return 'New Team for ' + model.get('title');
    },
    activate: function() {
      return this.get('thinkspace').disable_wizard_mode();
    },
    model: function(params) {
      return this.modelFor(ns['default'].to_p('case_manager', 'teams'));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});