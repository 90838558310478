define('client/components/thinkspace/common/shared/confirmation/modal', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    modal_id: ember['default'].computed(function() {
      return "ts-confirmation-modal-" + (this.get('elementId'));
    }),
    title: 'Are you sure?',
    confirm_text: 'Yes',
    deny_text: 'Cancel',
    modal_class_names: '',
    default_modal_class_names: 'ts-confirmation-modal reveal-modal',
    all_modal_class_names: ember['default'].computed('modal_class_names', function() {
      var class_names;
      class_names = this.get('default_modal_class_names');
      if (!ember['default'].isEmpty(this.get('modal_class_names'))) {
        class_names = class_names + ' ' + this.get('modal_class_names');
      }
      return class_names;
    }),
    get_$modal: function() {
      return $("#" + (this.get('modal_id')));
    },
    didInsertElement: function() {
      return $(document).foundation('reveal');
    },
    actions: {
      confirm: function() {
        this.send('close');
        return this.sendAction('confirm');
      },
      deny: function() {
        this.send('close');
        return this.sendAction('deny');
      },
      close: function() {
        return this.get_$modal().foundation('reveal', 'close');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});