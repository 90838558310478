define('client/helpers/date-administered', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Handlebars.makeBoundHelper(function(form, forms, options) {
    var date, formatted_date, formatting;
    date = form.get('admin_at');
    formatting = "MMM Do YYYY";
    formatted_date = moment(date).format(formatting);
    if (form === forms.get('lastObject')) {
      return formatted_date;
    } else {
      return formatted_date = moment(date).format(formatting) + ' / ';
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});