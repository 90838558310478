define('client/models/thinkspace/casespace/phase_state', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].totem_data, ta['default'].add(ta['default'].belongs_to('phase', {
    reads: {}
  }), ta['default'].belongs_to('phase_score', {
    reads: {}
  }), ta['default'].polymorphic('ownerable')), {
    current_state: ta['default'].attr('string'),
    title: ta['default'].attr('string'),
    phase_id: ta['default'].attr('number'),
    ownerable_id: ta['default'].attr('number'),
    ownerable_type: ta['default'].attr('string'),
    new_state: ta['default'].attr('string'),
    totem_data_config: {
      metadata: true
    },
    score: ember['default'].computed.reads('phase_score.score'),
    title_with_phase: ember['default'].computed('title', 'phase', function() {
      return (this.get('phase.title')) + " - " + (this.get('title'));
    }),
    computed_current_state: ember['default'].computed.or('current_state', 'phase.default_state'),
    is_unlocked: ember['default'].computed('computed_current_state', function() {
      return ['neutral', 'unlocked'].contains(this.get('current_state'));
    }),
    is_locked: ember['default'].computed.equal('computed_current_state', 'locked'),
    is_completed: ember['default'].computed.equal('computed_current_state', 'completed'),
    is_view_only: ember['default'].computed.or('is_locked', 'is_completed'),
    is_past_due: ember['default'].computed('metadata', 'metadata.due_at', function() {
      var due_at;
      due_at = this.get_parsed_metadata_date('due_at');
      return due_at <= Date.now();
    }),
    has_unlock_at: ember['default'].computed.notEmpty('metadata.unlock_at'),
    has_not_unlocked: ember['default'].computed('metadata', 'metadata.unlock_at', function() {
      var unlock_at;
      unlock_at = this.get_parsed_metadata_date('unlock_at');
      return ember['default'].isPresent(unlock_at) && unlock_at >= Date.now() && this.get('is_locked');
    }),
    get_parsed_metadata_date: function(prop) {
      var date;
      date = Date.parse(this.get("metadata." + prop));
      if (date === (0/0)) {
        return null;
      } else {
        return date;
      }
    },
    lock: function() {
      return this.set('current_state', 'locked');
    },
    unlock: function() {
      return this.set('current_state', 'unlocked');
    },
    complete: function() {
      return this.set('current_state', 'completed');
    },
    is_team_ownerable: function() {
      return this.totem_scope.standard_record_path(this.get('ownerable_type')) === ta['default'].to_p('team');
    },
    didCreate: function() {
      return this.didLoad();
    },
    didLoad: function() {
      return this.get(ta['default'].to_p('phase')).then((function(_this) {
        return function(phase) {
          return phase.get(ta['default'].to_p('phase_states')).then(function(phase_states) {
            if (!phase_states.contains(_this)) {
              return phase_states.pushObject(_this);
            }
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});