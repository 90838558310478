define('client/helpers/pluralize-string', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Handlebars.makeBoundHelper(function(number, single, plural, options) {
    if (number === 1) {
      return single;
    } else {
      return plural;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});