define('client/components/thinkspace/lab/builder/lab/chart/show', ['exports', 'ember', 'totem/ns', 'totem/ds/associations', 'thinkspace-base/components/base'], function (exports, ember, ns, ta, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    admin: ember['default'].inject.service(ns['default'].to_p('lab', 'admin')),
    init: function() {
      this._super();
      return this.get('categories');
    },
    c_loader: ns['default'].to_p('common', 'loader'),
    sort_by: ['position'],
    sorted_categories: ember['default'].computed.sort('categories', 'sort_by'),
    categories: ember['default'].computed(function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var admin;
          admin = _this.get('admin');
          return admin.load_chart().then(function() {
            return admin.get_chart_categories().then(function(categories) {
              return admin.set_chart_selected_category().then(function() {
                _this.set_all_data_loaded();
                return resolve(categories);
              });
            });
          });
        };
      })(this));
      return ta['default'].PromiseArray.create({
        promise: promise
      });
    }),
    actions: {
      category_new: function() {
        return this.get('admin').set_action_overlay('c_category_new');
      },
      exit: function() {
        return this.get('admin').exit();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});