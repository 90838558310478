define('client/models/thinkspace/common/password_reset', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend({
    token: ta['default'].attr('string'),
    email: ta['default'].attr('string'),
    password: ta['default'].attr('string')
  });

  exports['default'] = ___DefaultExportObject___;;

});