define('client/services/thinkspace/markup/manager', ['exports', 'ember', 'totem/ns', 'totem/cache', 'totem/ds/associations', 'totem-messages/messages', 'totem/scope'], function (exports, ember, ns, tc, ta, totem_messages, totem_scope) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Service.extend({
    thinkspace: ember['default'].inject.service(),
    casespace: ember['default'].inject.service(),
    taf: ember['default'].inject.service(),
    library: null,
    is_library_open: false,
    is_pdf: false,
    is_pdf_loading: false,
    is_comments_open: false,
    selected_library_tags: [],
    discussion_components: [],
    marker_components: [],
    comment_components: [],
    selectors: {
      comment_gutter_wrapper: '#ts-markup_comment-gutter-wrapper',
      comment_gutter_header: '#ts-markup_comment-gutter-header',
      content_wrapper: '#content-wrapper'
    },
    open_library: function() {
      var casespace, thinkspace;
      casespace = this.get('casespace');
      thinkspace = this.get('thinkspace');
      casespace.set_sidepocket_width(2);
      this.set('is_library_open', true);
      thinkspace.minimize_toolbar();
      return this.bind_sticky_columns();
    },
    close_library: function() {
      var casespace, thinkspace;
      casespace = this.get('casespace');
      thinkspace = this.get('thinkspace');
      casespace.set_sidepocket_width(1);
      this.set('is_library_open', false);
      thinkspace.expand_toolbar();
      this.bind_sticky_columns();
      this.reset_selected_library_comment();
      return this.reset_selected_library_tags();
    },
    open_comments: function() {
      return ember['default'].run.schedule('afterRender', (function(_this) {
        return function() {
          _this.bind_sticky_columns();
          if (_this.get_is_pdf()) {
            _this.set_is_pdf_loaded();
          }
          return _this.set_is_comments_open();
        };
      })(this));
    },
    close_comments: function() {
      this.close_library();
      return this.reset_is_comments_open();
    },
    bind_sticky_columns: function() {
      return ember['default'].run.schedule('afterRender', (function(_this) {
        return function() {
          return _this.get('thinkspace').bind_sticky_columns();
        };
      })(this));
    },
    get_current_commenterable: function() {
      return totem_scope['default'].get_current_user();
    },
    get_comments: function(options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var authable, ownerable, query;
          ownerable = options.ownerable;
          authable = options.authable;
          query = {
            action: 'fetch',
            ownerable: ownerable,
            authable: authable,
            auth: {
              view_ids: ownerable.get('id'),
              view_type: totem_scope['default'].get_record_path(ownerable)
            }
          };
          return tc['default'].query(ns['default'].to_p('markup', 'comment'), query).then(function(comments) {
            return resolve(comments);
          });
        };
      })(this));
    },
    get_discussions: function(options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var authable, discussionable, ownerable, query;
          ownerable = options.ownerable;
          authable = options.authable;
          discussionable = options.discussionable;
          query = {
            action: 'fetch',
            ownerable: ownerable,
            authable: authable,
            auth: {
              view_ids: ownerable.get('id'),
              view_type: totem_scope['default'].get_record_path(ownerable),
              discussionable_id: discussionable.get('id'),
              discussionable_type: totem_scope['default'].get_record_path(discussionable)
            }
          };
          return tc['default'].query(ns['default'].to_p('markup', 'discussion'), query).then(function(discussions) {
            return resolve(discussions);
          });
        };
      })(this));
    },
    set_anonymized_commenters: function(discussions, options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var promises;
          promises = ember['default'].makeArray();
          discussions.forEach(function(discussion) {
            return promises.pushObject(discussion.get_commenterables());
          });
          return ember['default'].RSVP.Promise.all(promises).then(function(commenterables) {
            var map;
            commenterables = _this.get('taf').flatten(commenterables).uniq().sortBy('id');
            if (options.shuffle) {
              commenterables = _this.get('taf').shuffle(commenterables);
            }
            map = ember['default'].Map.create();
            commenterables.forEach(function(commenterable, index) {
              return map.set(commenterable, index + 1);
            });
            _this.set('anonymized_commenters', map);
            return resolve(map);
          });
        };
      })(this));
    },
    get_anonymized_commenter: function(commenter) {
      var commenters;
      commenters = this.get('anonymized_commenters');
      if (!ember['default'].isPresent(commenters)) {
        console.error('[markup:manager] get_anonymized_commetner: anonymized_commenters has not been set yet.');
      }
      return commenters.get(commenter);
    },
    get_library_for_current_user: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var library, query;
          library = _this.get('library');
          if (ember['default'].isPresent(library)) {
            return resolve(library);
          }
          query = {
            action: 'fetch'
          };
          return tc['default'].query(ns['default'].to_p('markup', 'library'), query, {
            single: true
          }).then(function(library) {
            _this.set('library', library);
            return resolve(library);
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    add_comment_to_library: function(comment) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (!ember['default'].isPresent(comment)) {
            return resolve(null);
          }
          return _this.get_library_for_current_user().then(function(library) {
            var library_comment, obj, text;
            text = comment.get('comment');
            library_comment = totem_scope['default'].get_store().createRecord(ns['default'].to_p('markup', 'library_comment'), (
              obj = {
                comment: text,
                uses: 0,
                last_used: new Date(),
                user_id: totem_scope['default'].get_current_user_id()
              },
              obj["" + (ns['default'].to_p('markup', 'library'))] = library,
              obj
            ));
            return library_comment.save().then(function() {
              return resolve(library_comment);
            });
          });
        };
      })(this));
    },
    add_comment: function(options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var comment, comment_text, commenterable, discussion, parent, position, save;
          comment_text = _this.get_comment_from_options(options) || 'New comment';
          if (!ember['default'].isPresent(comment_text)) {
            console.error("[markup:manager] Cannot create a comment without valid text.");
          }
          commenterable = options.commenterable;
          if (!ember['default'].isPresent(commenterable)) {
            console.error("[markup:manager] Cannot create a comment without a valid commenterable.");
          }
          discussion = options.discussion;
          if (!ember['default'].isPresent(discussion)) {
            console.error("[markup:manager] Cannot create a comment without a valid discussion.");
          }
          parent = options.parent;
          position = options.position || 0;
          save = options.save || false;
          if (ember['default'].isPresent(parent)) {
            comment = _this.get_store().createRecord(ns['default'].to_p('markup', 'comment'), {
              'thinkspace/markup/discussion': discussion,
              'discussion_id': discussion.get('id'),
              'thinkspace/markup/parent': parent,
              'parent_id': parent.get('id'),
              position: position,
              created_at: new Date(),
              comment: comment_text
            });
          } else {
            comment = _this.get_store().createRecord(ns['default'].to_p('markup', 'comment'), {
              'thinkspace/markup/discussion': discussion,
              'discussion_id': discussion.get('id'),
              position: position,
              created_at: new Date(),
              comment: comment_text
            });
          }
          _this.set_polymorphic_on_record(comment, commenterable, 'commenterable');
          if (save) {
            return comment.save().then(function() {
              return resolve(comment);
            });
          } else {
            return resolve(comment);
          }
        };
      })(this));
    },
    add_discussion: function(options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var authable, creatorable, discussion, discussionable, ownerable, value;
          value = options.value || new Object;
          authable = options.authable;
          ownerable = options.ownerable;
          creatorable = options.creatorable;
          discussionable = options.discussionable;
          if (!ember['default'].isPresent(authable)) {
            console.error("[markup:manager] Cannot create a discussion without a valid authable.");
          }
          if (!ember['default'].isPresent(ownerable)) {
            console.error("[markup:manager] Cannot create a discussion without a valid ownerable.");
          }
          if (!ember['default'].isPresent(creatorable)) {
            console.error("[markup:manager] Cannot create a discussion without a valid creatorable.");
          }
          if (!ember['default'].isPresent(discussionable)) {
            console.error("[markup:manager] Cannot create a discussion without a valid discussionable.");
          }
          discussion = _this.get_store().createRecord(ns['default'].to_p('markup', 'discussion'), {
            value: value,
            authable: authable,
            ownerable: ownerable,
            creatorable: creatorable,
            discussionable: discussionable
          });
          _this.set_polymorphic_on_record(discussion, authable, 'authable');
          _this.set_polymorphic_on_record(discussion, ownerable, 'ownerable');
          _this.set_polymorphic_on_record(discussion, creatorable, 'creatorable');
          _this.set_polymorphic_on_record(discussion, discussionable, 'discussionable');
          if (options.save) {
            return discussion.save().then(function() {
              return resolve(discussion);
            });
          } else {
            return resolve(discussion);
          }
        };
      })(this));
    },
    add_comment_to_discussion_and_edit: function(model, options) {
      if (options == null) {
        options = {};
      }
      options.edit = true;
      return this.add_comment_to_discussion(model, options);
    },
    add_comment_to_discussion: function(model, options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (!options.commenterable) {
            console.error('[markup:manager] WARNING: Commenterable not passed in with options for `add_comment_and_edit_discussion`');
          }
          options.discussion = model;
          if (options.library_comment) {
            options.save = true;
          }
          return _this.add_comment(options).then(function(comment) {
            return ember['default'].run.schedule('afterRender', function() {
              var component, discussion_component;
              if (options.edit) {
                component = _this.get_comment_component_for_comment(comment);
                if (component) {
                  component.set_is_editing();
                }
              }
              discussion_component = _this.get_discussion_component_for_discussion(model);
              return _this.scroll_sidepocket_to_component(discussion_component);
            });
          });
        };
      })(this));
    },
    get_comment_from_options: function(options) {
      var comment, library_comment;
      if (options == null) {
        options = {};
      }
      library_comment = options.library_comment;
      comment = options.comment;
      if (ember['default'].isPresent(library_comment)) {
        return library_comment.get('comment');
      } else {
        return comment;
      }
    },
    scroll_sidepocket_to_discussion: function(model) {
      var component;
      component = this.get_discussion_component_for_discussion(model);
      return this.scroll_sidepocket_to_component(component);
    },
    scroll_sidepocket_to_component: function(component) {
      var el;
      el = this.get_$comment_gutter_wrapper();
      return this.scroll_el_to_component(el, component);
    },
    scroll_content_wrapper_to_component: function(component) {
      var el;
      el = this.get_$content_wrapper();
      return this.scroll_el_to_component(el, component);
    },
    scroll_el_to_component: function(el, component) {
      var $component, scroll_top;
      if (!component) {
        return;
      }
      $component = component.$();
      scroll_top = $component.position().top;
      el.animate({
        scrollTop: scroll_top
      });
      if (typeof component.set_is_scrolled_to === 'function' && !component.get('isDestroying') && !component.get('isDestroyed')) {
        return component.set_is_scrolled_to();
      }
    },
    highlight_discussion: function(model) {
      var components, discussion, marker;
      components = this.get_discussion_components();
      discussion = null;
      components.forEach((function(_this) {
        return function(component) {
          if (component.get('model') === model) {
            component.set_is_highlighted();
            return discussion = component;
          } else {
            return component.reset_is_highlighted();
          }
        };
      })(this));
      components = this.get_marker_components();
      marker = null;
      components.forEach((function(_this) {
        return function(component) {
          if (component.get('model') === model) {
            component.set_is_highlighted();
            return marker = component;
          } else {
            return component.reset_is_highlighted();
          }
        };
      })(this));
      if (discussion) {
        this.scroll_sidepocket_to_component(discussion);
      }
      if (marker) {
        return this.scroll_content_wrapper_to_component(marker);
      }
    },
    set_polymorphic_on_record: function(record, polymorphic, type) {
      record.set(type + "_id", polymorphic.get('id'));
      return record.set(type + "_type", totem_scope['default'].get_record_path(polymorphic));
    },
    set_is_pdf: function() {
      return this.set('is_pdf', true);
    },
    reset_is_pdf: function() {
      return this.set('is_pdf', false);
    },
    get_is_pdf: function() {
      return this.get('is_pdf');
    },
    set_is_pdf_loading: function() {
      return this.set('is_pdf_loading', true);
    },
    reset_is_pdf_loading: function() {
      return this.set('is_pdf_loading', false);
    },
    get_is_pdf_loading: function() {
      return this.get('is_pdf_loading');
    },
    set_is_pdf_loaded: function() {
      return this.reset_is_pdf_loading();
    },
    get_comment_gutter_wrapper_selector: function() {
      return this.get('selectors.comment_gutter_wrapper');
    },
    get_$comment_gutter_wrapper: function() {
      return $(this.get_comment_gutter_wrapper_selector());
    },
    get_comment_gutter_header_selector: function() {
      return this.get('selectors.comment_gutter_header');
    },
    get_$comment_gutter_header: function() {
      return $(this.get_comment_gutter_header_selector());
    },
    get_content_wrapper_selector: function() {
      return this.get('selectors.content_wrapper');
    },
    get_$content_wrapper: function() {
      return $(this.get_content_wrapper_selector());
    },
    get_header_height: function() {
      var $header;
      $header = this.get_$comment_gutter_header();
      if (!ember['default'].isPresent($header)) {
        return 0;
      }
      return $header.outerHeight();
    },
    add_component_to_registry: function(type, component) {
      var components;
      components = this["get_" + type + "_components"]();
      if (!components.contains(component)) {
        return components.pushObject(component);
      }
    },
    remove_component_from_registry: function(type, component) {
      var components;
      components = this["get_" + type + "_components"]();
      if (components.contains(component)) {
        return components.removeObject(component);
      }
    },
    add_discussion_component: function(component) {
      return this.add_component_to_registry('discussion', component);
    },
    remove_discussion_component: function(component) {
      return this.remove_component_from_registry('discussion', component);
    },
    reset_discussion_components: function() {
      return this.set('discussion_components', new Array);
    },
    get_discussion_components: function() {
      return this.get('discussion_components');
    },
    add_marker_component: function(component) {
      return this.add_component_to_registry('marker', component);
    },
    remove_marker_component: function(component) {
      return this.remove_component_from_registry('marker', component);
    },
    reset_marker_components: function() {
      return this.set('marker_components', new Array);
    },
    get_marker_components: function() {
      return this.get('marker_components');
    },
    add_comment_component: function(component) {
      return this.add_component_to_registry('comment', component);
    },
    remove_comment_component: function(component) {
      return this.remove_component_from_registry('comment', component);
    },
    reset_comment_components: function() {
      return this.set('comment_components', new Array);
    },
    get_comment_components: function() {
      return this.get('comment_components');
    },
    get_marker_component_for_discussion: function(model) {
      console.log("[markup:manager] Getting marker components for discussion: ", model);
      return this.get_marker_components().findBy('model', model);
    },
    get_discussion_component_for_discussion: function(model) {
      console.log("[markup:manager] Getting discussion components for discussion: ", model);
      return this.get_discussion_components().findBy('model', model);
    },
    get_comment_component_for_comment: function(model) {
      console.log("[markup:manager] Getting comment components for comment: ", model);
      return this.get_comment_components().findBy('model', model);
    },
    discussion_discussionable_is_in_store: function(discussion) {
      var discussionable;
      discussionable = tc['default'].peek_record(totem_scope['default'].standard_record_path(discussion.get('discussionable_type')), discussion.get('discussionable_id'));
      return ember['default'].isPresent(discussionable);
    },
    discussion_has_ownerable: function(discussion, ownerable) {
      if (ownerable == null) {
        ownerable = null;
      }
      if (!ownerable) {
        ownerable = totem_scope['default'].get_ownerable_record();
      }
      return this.discussion_has_polymorphic(discussion, ownerable, 'ownerable');
    },
    discussion_has_authable: function(discussion, authable) {
      return this.discussion_has_polymorphic(discussion, authable, 'authable');
    },
    discussion_has_discussionable: function(discussion, discussionable) {
      return this.discussion_has_polymorphic(discussion, discussionable, 'discussionable');
    },
    discussion_has_polymorphic: function(discussion, record, type) {
      var polymorphic_id, polymorphic_type, record_id, record_type;
      record_type = totem_scope['default'].standard_record_path(record);
      record_id = parseInt(record.get('id'));
      polymorphic_type = totem_scope['default'].standard_record_path(discussion.get(type + "_type"));
      polymorphic_id = parseInt(discussion.get(type + "_id"));
      return ember['default'].isEqual(record_type, polymorphic_type) && ember['default'].isEqual(record_id, polymorphic_id);
    },
    set_selected_library_comment: function(library_comment) {
      return this.set('selected_library_comment', library_comment);
    },
    reset_selected_library_comment: function() {
      return this.set('selected_library_comment', null);
    },
    get_selected_library_comment: function() {
      return this.get('selected_library_comment');
    },
    add_selected_library_tag: function(tag) {
      var tags;
      tags = this.get_selected_library_tags();
      if (!tags.contains(tag)) {
        return tags.pushObject(tag);
      }
    },
    remove_selected_library_tag: function(tag) {
      var tags;
      tags = this.get_selected_library_tags();
      if (tags.contains(tag)) {
        return tags.removeObject(tag);
      }
    },
    reset_selected_library_tags: function() {
      return this.set('selected_library_tags', new Array);
    },
    get_selected_library_tags: function() {
      return this.get('selected_library_tags');
    },
    get_library_target_class: function() {
      return 'ts-markup_library-target';
    },
    get_discussion_number: function(discussions, discussion) {
      return discussions.indexOf(discussion) + 1;
    },
    set_is_comments_open: function() {
      return this.set('is_comments_open', true);
    },
    reset_is_comments_open: function() {
      return this.set('is_comments_open', false);
    },
    get_is_comments_open: function() {
      return this.get('is_comments_open');
    },
    save_comment: function(comment) {
      return comment.save();
    },
    get_store: function() {
      return this.container.lookup('store:main');
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});