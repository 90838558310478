define('client/components/thinkspace/peer_assessment/builder/assessment/preview', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    manager: ember['default'].inject.service(ns['default'].to_p('peer_assessment', 'builder', 'manager')),
    model: null,
    quant_items: ember['default'].computed.reads('model.quant_items'),
    qual_items: ember['default'].computed.reads('model.qual_items'),
    c_quant_preview: ns['default'].to_p('peer_assessment', 'builder', 'assessment', 'quant', 'preview'),
    c_qual_preview: ns['default'].to_p('peer_assessment', 'builder', 'assessment', 'qual', 'preview'),
    init: function() {
      this._super();
      return this.get('manager').set_model(this.get('model'));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});