define('client/templates/components/thinkspace/builder/steps/overview', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","btn btn-small btn-default right");
            var el2 = dom.createTextNode("Edit Details");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            return fragment;
          }
        };
      }());
      var child1 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","btn btn-small btn-default right");
            var el2 = dom.createTextNode("Edit Phases");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            return fragment;
          }
        };
      }());
      var child2 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, null);
            dom.insertBoundary(fragment, 0);
            inline(env, morph0, context, "component", [get(env, context, "c_phase")], {"model": get(env, context, "phase"), "assignment": get(env, context, "model"), "disable_actions": true});
            return fragment;
          }
        };
      }());
      var child3 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","btn btn-small btn-default right");
            var el2 = dom.createTextNode("Edit Instructions");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            return fragment;
          }
        };
      }());
      var child4 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","btn btn-small btn-default right");
            var el2 = dom.createTextNode("Edit Logistics");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            return fragment;
          }
        };
      }());
      var child5 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"src","/assets/images/ts-icons/builder/ts-case_active-status-icons_case-active.svg");
            dom.setAttribute(el1,"class","ts-builder_phase-type-img");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h1");
            var el2 = dom.createTextNode("Case is Active!");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createTextNode("This case is currently active and can be seen by students after the set release date.  Some tools need may need additional configuration before the students can access them.");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","btn btn-small btn-default");
            var el2 = dom.createTextNode("Deactivate Case");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, element = hooks.element;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element1 = dom.childAt(fragment, [3]);
            element(env, element1, context, "action", ["inactivate"], {"on": "click"});
            return fragment;
          }
        };
      }());
      var child6 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"src","/assets/images/ts-icons/builder/ts-case_active-status-icons_case-draft.svg");
            dom.setAttribute(el1,"class","ts-builder_phase-type-img");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h1");
            var el2 = dom.createTextNode("Case is Inactive");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createTextNode("Inactive cases allow you to save and make changes until you are ready to activate. This case will not be seen by students until you activate it. ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","btn btn-small btn-primary");
            var el2 = dom.createTextNode("Activate Case");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, element = hooks.element;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element0 = dom.childAt(fragment, [3]);
            element(env, element0, context, "action", ["activate"], {"on": "click"});
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","small-12 small-centered medium-10 large-8 ts-grid_columns ts-grid_columns-thin");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","ts-forms_form-container");
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","row");
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","small-12 ts-grid_columns ts-builder_content-header");
          var el5 = dom.createElement("h1");
          var el6 = dom.createTextNode("Case Overview");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("p");
          var el6 = dom.createTextNode("Review all your settings to make sure everything looks fine.");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","row");
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","small-12 ts-grid_columns ts-builder_section-container");
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","ts-builder_section-header");
          var el6 = dom.createElement("h1");
          var el7 = dom.createTextNode("Case Details");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          var el6 = dom.createTextNode("Case name");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("p");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","row");
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","small-12 ts-grid_columns ts-builder_section-container");
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","ts-builder_section-header");
          var el6 = dom.createElement("h1");
          var el7 = dom.createTextNode("Phases");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","ts-grid_row ts-builder_dialogue-container");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("ol");
          dom.setAttribute(el5,"class","ts-builder_phase-list");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","row");
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","small-12 ts-grid_columns ts-builder_section-container");
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","ts-builder_section-header");
          var el6 = dom.createElement("h1");
          var el7 = dom.createTextNode("Instructions");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","ts-forms_field-container");
          var el6 = dom.createElement("p");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","row");
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","small-12 ts-grid_columns ts-builder_section-container");
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","ts-builder_section-header");
          var el6 = dom.createElement("h1");
          var el7 = dom.createTextNode("Logistics");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","row collapse");
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","small-12 medium-6 large-6 columns");
          var el7 = dom.createElement("label");
          var el8 = dom.createTextNode("Release At");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("p");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","small-12 medium-6 large-6 columns");
          var el7 = dom.createElement("label");
          var el8 = dom.createTextNode("Due At");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("p");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","row");
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","small-12 ts-grid_columns ts-builder_section-container");
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","ts-builder_section-header");
          var el6 = dom.createElement("h1");
          var el7 = dom.createTextNode("Final Touches");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","ts-forms_field-container");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","row");
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","small-12 ts-grid_columns");
          var el5 = dom.createElement("a");
          dom.setAttribute(el5,"class","btn btn-small btn-default");
          var el6 = dom.createTextNode("Go Back");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","tsi tsi-left icon-small tsi-left-arrow-inverse left");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("a");
          dom.setAttribute(el5,"class","btn btn-small btn-primary right");
          var el6 = dom.createTextNode("Finish Case");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, block = hooks.block, content = hooks.content, inline = hooks.inline, element = hooks.element;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element2 = dom.childAt(fragment, [0, 0]);
          var element3 = dom.childAt(element2, [1, 0]);
          var element4 = dom.childAt(element2, [2, 0]);
          var element5 = dom.childAt(element2, [3, 0]);
          var element6 = dom.childAt(element2, [4, 0]);
          var element7 = dom.childAt(element6, [1]);
          var element8 = dom.childAt(element2, [5, 0, 1]);
          var element9 = dom.childAt(element2, [6, 0]);
          var element10 = dom.childAt(element9, [0]);
          var element11 = dom.childAt(element9, [1]);
          var morph0 = dom.createMorphAt(dom.childAt(element3, [0]),1,1);
          var morph1 = dom.createMorphAt(dom.childAt(element3, [2]),0,0);
          var morph2 = dom.createMorphAt(dom.childAt(element4, [0]),1,1);
          var morph3 = dom.createMorphAt(dom.childAt(element4, [1]),0,0);
          var morph4 = dom.createMorphAt(dom.childAt(element4, [2]),0,0);
          var morph5 = dom.createMorphAt(dom.childAt(element5, [0]),1,1);
          var morph6 = dom.createUnsafeMorphAt(dom.childAt(element5, [1, 0]),0,0);
          var morph7 = dom.createMorphAt(dom.childAt(element6, [0]),1,1);
          var morph8 = dom.createMorphAt(dom.childAt(element7, [0, 1]),0,0);
          var morph9 = dom.createMorphAt(dom.childAt(element7, [1, 1]),0,0);
          var morph10 = dom.createMorphAt(element8,0,0);
          var morph11 = dom.createMorphAt(element8,1,1);
          var morph12 = dom.createMorphAt(element8,2,2);
          block(env, morph0, context, "link-to", [get(env, context, "r_cases_details"), get(env, context, "model")], {}, child0, null);
          content(env, morph1, context, "model.title");
          block(env, morph2, context, "link-to", [get(env, context, "r_cases_phases"), get(env, context, "model")], {}, child1, null);
          inline(env, morph3, context, "component", [get(env, context, "c_phase_errors")], {"model": get(env, context, "model")});
          block(env, morph4, context, "each", [get(env, context, "phases")], {"keyword": "phase"}, child2, null);
          block(env, morph5, context, "link-to", [get(env, context, "r_cases_logistics"), get(env, context, "model")], {}, child3, null);
          content(env, morph6, context, "model.instructions");
          block(env, morph7, context, "link-to", [get(env, context, "r_cases_logistics"), get(env, context, "model")], {}, child4, null);
          content(env, morph8, context, "friendly_release_at");
          content(env, morph9, context, "friendly_due_at");
          inline(env, morph10, context, "component", [get(env, context, "c_phase_errors")], {"model": get(env, context, "model")});
          block(env, morph11, context, "if", [get(env, context, "model.is_active")], {}, child5, null);
          block(env, morph12, context, "if", [get(env, context, "model.is_inactive")], {}, child6, null);
          element(env, element10, context, "action", ["back"], {"on": "click"});
          element(env, element11, context, "action", ["exit"], {"on": "click"});
          return fragment;
        }
      };
    }());
    var child1 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, null);
          dom.insertBoundary(fragment, 0);
          inline(env, morph0, context, "component", [get(env, context, "c_loader")], {});
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, null);
        dom.insertBoundary(fragment, 0);
        block(env, morph0, context, "if", [get(env, context, "all_data_loaded")], {}, child0, child1);
        return fragment;
      }
    };
  }()));

});