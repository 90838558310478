define('client/thinkspace/casespace/phases/show/route', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'simple-auth/mixins/authenticated-route-mixin', 'thinkspace-base/base/route'], function (exports, ember, ns, ajax, auth_mixin, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(auth_mixin['default'], {
    phase_manager: ember['default'].inject.service(),
    casespace: ember['default'].inject.service(),
    tvo: ember['default'].inject.service(),
    titleToken: function(model) {
      return model.get('title');
    },
    deactivate: function() {
      var controller;
      this._super();
      controller = this.get('controller');
      controller.reset_phase_settings();
      return controller.reset_query_id();
    },
    model: function(params) {
      return this.store.find(ns['default'].to_p('phase'), params.phase_id).then((function(_this) {
        return function(phase) {
          return _this.totem_messages.api_success({
            source: _this,
            model: phase
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.totem_messages.api_failure(error, {
            source: _this,
            model: ns['default'].to_p('phase')
          });
        };
      })(this));
    },
    afterModel: function(phase, transition) {
      if (!phase) {
        transition.abort();
      }
      if (ember['default'].isEqual(this.get('casespace.current_phase'), phase)) {
        this.totem_messages.hide_loading_outlet();
      }
      return this.get('casespace').set_current_models({
        phase: phase
      }).then((function(_this) {
        return function() {
          return _this.get('phase_manager').set_all_phase_states().then(function() {
            return _this.validate_phase_state(phase);
          });
        };
      })(this));
    },
    renderTemplate: function(controller, phase) {
      return this.route_based_on_phase_state(phase);
    },
    get_casespace: function() {
      return this.get('casespace');
    },
    get_assignment: function() {
      return this.get_casespace().get_current_assignment();
    },
    get_phase: function() {
      return this.get_casespace().get_current_phase();
    },
    get_phase_manager: function() {
      return this.get('phase_manager');
    },
    get_phase_manager_map: function() {
      return this.get('phase_manager.map');
    },
    show_loading_outlet: function() {
      return this.get_phase_manager().show_loading_outlet();
    },
    hide_loading_outlet: function() {
      return this.get_phase_manager().hide_loading_outlet();
    },
    validate_phase_state: function(phase) {
      var assignment;
      assignment = this.get_assignment();
      return assignment.totem_data.ability.refresh().then((function(_this) {
        return function() {
          var current_user;
          current_user = _this.totem_scope.get_current_user();
          return assignment.totem_data.ability.for_ownerable(current_user).then(function(abilities) {
            var can_update, query_id;
            can_update = abilities.can.update;
            query_id = _this.get('controller.query_id');
            return _this.get_phase_manager().get_phase_state_for_phase(phase, query_id).then(function(phase_state) {
              var can_access, can_view;
              can_view = phase_state ? !phase_state.get('is_locked') : false;
              can_access = can_view || can_update;
              if (!can_access) {
                _this.totem_messages.error('You cannot access a locked phase.');
                return _this.transition_to_assignment();
              }
            });
          });
        };
      })(this));
    },
    route_based_on_phase_state: function(phase) {
      var can_update, map, phase_manager, query_id;
      can_update = this.get_assignment().get('can_update');
      phase_manager = this.get_phase_manager();
      map = this.get_phase_manager_map();
      query_id = this.get('controller.query_id');
      return phase_manager.get_phase_state_for_phase(phase, query_id).then((function(_this) {
        return function(phase_state) {
          switch (false) {
            case !phase_state:
              return phase_manager.set_ownerable_from_phase_state(phase_state).then(function() {
                return _this.render_view();
              });
            case !map.ownerable_has_multiple_phase_states(phase):
              if (can_update && phase_manager.has_active_addon()) {
                return _this.render_view();
              } else {
                _this.render(ns['default'].to_p('phases', 'select_phase_state'));
                return _this.hide_loading_outlet();
              }
              break;
            case !can_update:
              return _this.render_view();
            default:
              return _this.transition_to_assignment();
          }
        };
      })(this));
    },
    transition_to_assignment: function() {
      this.hide_loading_outlet();
      return this.transitionTo(ns['default'].to_r('assignments', 'show'), this.get_assignment());
    },
    render_view: function() {
      this.show_loading_outlet();
      return this.get_phase_manager().generate_view_with_ownerable().then((function(_this) {
        return function() {
          _this.render();
          return _this.hide_loading_outlet();
        };
      })(this));
    },
    get_ownerable: function() {
      return this.totem_scope.get_ownerable_record();
    },
    actions: {
      select_phase_state: function(phase_state) {
        return this.get_phase_manager().set_ownerable_from_phase_state(phase_state).then((function(_this) {
          return function() {
            return _this.render_view();
          };
        })(this));
      },
      submit: function() {
        var tvo;
        tvo = this.get('tvo');
        return tvo.status.all_valid().then((function(_this) {
          return function(status) {
            return _this.submit_phase();
          };
        })(this), (function(_this) {
          return function(status) {
            tvo.hash.set_value('show_errors', true);
            tvo.hash.set_value('phase_submit_messages_title', 'Please correct the following:');
            return tvo.hash.set_value('phase_submit_messages', status.status_messages);
          };
        })(this));
      }
    },
    submit_phase: function() {
      var phase, query;
      phase = this.get_phase();
      query = {
        verb: 'put',
        action: 'submit',
        id: phase.get('id'),
        model: phase
      };
      return ajax['default'].object(query).then((function(_this) {
        return function(payload) {
          phase.store.pushPayload(ns['default'].to_p('phase_state'), payload);
          _this.totem_messages.api_success({
            source: _this,
            model: phase,
            i18n_path: ns['default'].to_o('phase', 'submit')
          });
          return _this.transition_after_submit();
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.totem_messages.api_failure(error, {
            source: _this,
            model: phase
          });
        };
      })(this));
    },
    transition_after_submit: function() {
      var assignment, phase;
      assignment = this.get_assignment();
      phase = this.get_phase();
      return assignment.get(ns['default'].to_p('phases')).then((function(_this) {
        return function() {
          var index, next_phase, phase_state, phases;
          phases = assignment.get('phases');
          index = phases.indexOf(phase);
          if (index != null) {
            next_phase = phases.slice(index + 1).filterBy('is_unlocked').get('firstObject');
            if (next_phase) {
              phase_state = _this.get_phase_manager_map().find_ownerable_selected_phase_state(next_phase, _this.get_ownerable());
              if (ember['default'].isPresent(phase_state)) {
                _this.transitionTo(ns['default'].to_r('phases', 'show'), assignment, next_phase, {
                  queryParams: {
                    query_id: phase_state.get('id')
                  }
                });
              } else {
                _this.transitionTo(ns['default'].to_r('phases', 'show'), assignment, next_phase, {
                  queryParams: {
                    query_id: 'none'
                  }
                });
              }
              return;
            }
          }
          _this.totem_messages.info('Case submitted successfully.');
          return _this.transition_to_assignment();
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});