define('client/components/thinkspace/common/shared/loader/small', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    header: ' - Loading -',
    message: 'You will arrive shortly.',
    size: null
  });

  exports['default'] = ___DefaultExportObject___;;

});