define('client/components/thinkspace/weather_forecaster/assessment/attempt/show', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    ttz: ember['default'].inject.service(),
    is_selected: ember['default'].computed('forecast', function() {
      return this.get('model') === this.get('forecast');
    }),
    is_current: ember['default'].computed('forecast', function() {
      return this.get('model') === this.get('current_forecast');
    }),
    is_completed: ember['default'].computed.bool('model.completed'),
    forecast_at: ember['default'].computed(function() {
      return this.get('ttz').format(this.get('model.forecast_at'), {
        format: 'MMMM DD, YYYY'
      });
    }),
    forecast_score: ember['default'].computed(function() {
      if (this.get('is_current') || (!this.get('model.locked'))) {
        return '--';
      } else {
        return this.get('model.score');
      }
    }),
    actions: {
      select: function() {
        return this.sendAction('select', this.get('model'));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});