define('client/router', ['exports', 'ember', 'client/config/environment', 'totem/config/routes'], function (exports, ember, config, tr) {

  'use strict';

  var Router, ___DefaultExportObject___;

  Router = ember['default'].Router.extend({
    location: config['default'].locationType || '/users/sign_in'
  });

  Router.map(function() {
    return tr['default'].map(this);
  });

  ___DefaultExportObject___ = Router;

  exports['default'] = ___DefaultExportObject___;;

});