define('client/components/thinkspace/resource/pane/link', ['exports', 'ember', 'totem/util', 'thinkspace-base/components/base'], function (exports, ember, util, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    default_url: 'http://thinkspace.org',
    link_url: ember['default'].computed(function() {
      var url;
      url = this.get('model.url') || '';
      if (!util['default'].starts_with(url, 'http')) {
        url = this.get('default_url');
      }
      return url;
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});