define('client/models/thinkspace/common/invitation', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend({
    email: ta['default'].attr('string'),
    role: ta['default'].attr('string'),
    state: ta['default'].attr('string'),
    expires_at: ta['default'].attr('date'),
    accepted_at: ta['default'].attr('date'),
    created_at: ta['default'].attr('date'),
    invitable_type: ta['default'].attr('string'),
    invitable_id: ta['default'].attr('number'),
    casespace_roles: {
      'read': 'Student',
      'update': 'Teaching Assistant',
      'owner': 'Instructor'
    },
    friendly_role: ember['default'].computed('role', function() {
      return this.get('casespace_roles')[this.get('role')];
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});