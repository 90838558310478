define('client/components/thinkspace/readiness_assurance/shared/radio/ifat_buttons', ['exports', 'ember', 'thinkspace-readiness-assurance/base/ra_component'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    tagName: 'div',
    classNameBindings: ['no_errors::ts-ra_error'],
    willInsertElement: function() {
      if (this.qm.get('readonly')) {
        return this.qm.set_question_disabled_on();
      }
    },
    has_selections: false,
    is_correct: false,
    score: ember['default'].computed('qm.response_updated', function() {
      var has_selections, is_correct, qid;
      qid = this.qm.qid;
      has_selections = ember['default'].isPresent(this.get("qm.response.userdata.attempt_values." + qid));
      is_correct = this.get("qm.response.userdata.question_correct." + qid);
      this.setProperties({
        has_selections: has_selections,
        is_correct: is_correct
      });
      return this.get("qm.response.userdata.question_scores." + this.qm.qid);
    }),
    actions: {
      select: function(id) {
        return this.sendAction('select', id);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});