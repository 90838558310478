define('client/components/thinkspace/readiness_assurance/response/trat/questions', ['exports', 'ember', 'thinkspace-readiness-assurance/base/ra_component'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    question_managers: ember['default'].computed(function() {
      var managers;
      managers = [];
      this.rm.question_manager_map.forEach((function(_this) {
        return function(qm) {
          return managers.push(qm);
        };
      })(this));
      return managers;
    }),
    actions: {
      chat: function(qid) {
        return this.sendAction('chat', qid);
      },
      chat_close: function(qid) {
        return this.sendAction('chat_close', qid);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});