define('client/components/thinkspace/input_element/elements/carry_forward/standard', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    has_none: ember['default'].computed.equal('responses.length', 0),
    has_one: ember['default'].computed.equal('responses.length', 1),
    has_many: ember['default'].computed.gt('responses.length', 1),
    response: ember['default'].computed.reads('responses.firstObject')
  });

  exports['default'] = ___DefaultExportObject___;;

});