define('client/components/thinkspace/common/user/show/keys', ['exports', 'ember', 'totem/ns', 'totem/mixins/validations', 'thinkspace-base/components/base', 'totem/util'], function (exports, ember, ns, val_mixin, base_component, util) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend(val_mixin['default'], {
    model: null,
    p_header: 'components/' + ns['default'].to_p('user', 'show', 'header'),
    p_add_key: 'components/' + ns['default'].to_p('user', 'show', 'add_key'),
    c_validated_input: ns['default'].to_p('common', 'shared', 'validated_input'),
    c_loader: ns['default'].to_p('common', 'loader'),
    has_key: ember['default'].computed.reads('model.has_key'),
    r_user_profile: 'users.show.profile',
    r_user_keys: 'users.show.keys',
    key: null,
    init: function() {
      this._super();
      return this.init_keys().then((function(_this) {
        return function() {
          return _this.set_all_data_loaded();
        };
      })(this));
    },
    init_keys: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model;
          model = _this.get('model');
          return _this.tc.query(ns['default'].to_p('user'), {
            id: model.get('id'),
            action: 'list_keys'
          }, {
            payload_type: ns['default'].to_p('key')
          }).then(function(keys) {
            _this.set('keys', keys);
            return resolve();
          });
        };
      })(this));
    },
    actions: {
      add_key: function() {
        return new ember['default'].RSVP.Promise((function(_this) {
          return function(resolve, reject) {
            return _this.validate().then(function() {
              var key, model, query;
              model = _this.get('model');
              key = _this.get('key');
              query = {
                action: 'add_key',
                id: model.get('id'),
                key: key,
                verb: 'PUT'
              };
              return _this.tc.query(ns['default'].to_p('user'), query, {
                single: true
              }).then(function(user) {
                return _this.init_keys().then(function() {
                  return resolve();
                });
              });
            })["catch"](function() {
              return reject();
            });
          };
        })(this));
      }
    },
    validations: {
      key: {
        presence: {
          message: 'Please add a key.'
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});