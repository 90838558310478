define('client/components/thinkspace/html/html_only', ['exports', 'ember', 'totem/ns', 'thinkspace-html/components/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    html: ember['default'].computed(function() {
      return this.get('model.html_content').htmlSafe();
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});