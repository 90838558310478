define('client/components/thinkspace/indented_list/list/layout/shared/test_only', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    get_response_manager: function() {
      return this.get('response_manager');
    },
    get_list_container: function() {
      return this.get_response_manager().$list_container;
    },
    send_response_to_server: true,
    confirm_remove: false,
    indent_px: 20,
    zoom_level: ember['default'].computed.reads('response_manager.zoom_level'),
    willInsertElement: function() {
      var rm;
      rm = this.get_response_manager();
      this.set_indent_px(rm.indent_px);
      rm.set_confirm_remove(this.get('confirm_remove'));
      return rm.set_send_response_to_server(this.get('send_response_to_server'));
    },
    actions: {
      toggle_save: function() {
        return this.get_response_manager().set_send_response_to_server(this.toggleProperty('send_response_to_server'));
      },
      toggle_confirm_remove: function() {
        return this.get_response_manager().set_confirm_remove(this.toggleProperty('confirm_remove'));
      },
      indent_px_10: function() {
        return this.indent_px_action(10);
      },
      indent_px_20: function() {
        return this.indent_px_action(20);
      },
      indent_px_30: function() {
        return this.indent_px_action(30);
      },
      indent_px_40: function() {
        return this.indent_px_action(40);
      },
      indent_up: function() {
        return this.indent_px_action(this.incrementProperty('indent_px'));
      },
      indent_down: function() {
        return this.indent_px_action(this.decrementProperty('indent_px'));
      },
      zoom_in: function() {
        return this.zoom_action(1);
      },
      zoom_out: function() {
        return this.zoom_action(-1);
      },
      reset_overflow: function() {
        return this.reset_overflow();
      }
    },
    indent_px_action: function(px) {
      var rm;
      if (px < 5) {
        px = 5;
        this.set('indent_px', px);
      }
      this.set_indent_px(px);
      this.add_background_grid();
      rm = this.get_response_manager();
      return rm.value_items.forEach((function(_this) {
        return function(item) {
          var comp;
          comp = rm.get_item_component(item);
          return comp.set_indent();
        };
      })(this));
    },
    zoom_action: function(offset) {
      var rm;
      rm = this.get_response_manager();
      rm.set_zoom_level(this.get_zoom_level(offset));
      return this.reset_overflow();
    },
    get_zoom_level: function(offset) {
      var default_level, plus, zoom_level;
      zoom_level = this.get('zoom_level');
      default_level = this.get_response_manager().default_zoom_level();
      plus = offset > 0;
      switch (false) {
        case !(zoom_level >= 8 && plus):
          return default_level;
        case !(zoom_level <= 1 && !plus):
          return default_level;
        default:
          return zoom_level + offset;
      }
    },
    set_indent_px: function(px) {
      this.get_response_manager().set_indent_px(px);
      this.set('indent_px', px);
      this.$('.indent-px').css('background-color', '#bcbcbc');
      return this.$(".px-" + px).css('background-color', '#63b4d6');
    },
    add_background_grid: function() {
      var $list_container, ipx, px;
      ipx = this.get_response_manager().indent_px;
      px = ipx - 1;
      $list_container = this.get_list_container();
      $list_container.css('background-size', px + "px " + px + "px");
      return $list_container.css('background-image', 'linear-gradient(to right, lightgrey 1px, transparent 1px), linear-gradient(to bottom, lightgrey 1px, transparent 1px)');
    },
    reset_overflow: function() {
      var rm;
      rm = this.get_response_manager();
      return rm.value_items.forEach((function(_this) {
        return function(item) {
          var comp;
          comp = rm.get_item_component(item);
          return comp.set_has_overflow();
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});