define('client/views/users/sign_in', ['exports', 'ember', 'totem/config'], function (exports, ember, config) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].View.extend({
    templateName: config['default'].simple_auth && config['default'].simple_auth.sign_in_template,
    keyPress: function() {
      return this.get('controller.totem_messages').clear_all();
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});