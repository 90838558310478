define('client/thinkspace/casespace/case_manager/assignments/peer_assessment/controller', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

	'use strict';

	var ___DefaultExportObject___;

	___DefaultExportObject___ = ember['default'].Controller.extend();

	exports['default'] = ___DefaultExportObject___;;

});