define('client/components/thinkspace/lab/builder/lab/result/form/analysis', ['exports', 'ember', 'totem/ns', 'client/components/thinkspace/lab/builder/lab/result/form/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    selections: null,
    normal_label: null,
    correct_label: null,
    error_messages: null,
    new_count: 0,
    init_values: function() {
      var correct, normal, ref, selections;
      ref = this.get_model_value_path(), selections = ref[0], normal = ref[1], correct = ref[2];
      this.set('selections', this.get_unbound_selections(selections));
      this.set('normal_label', this.get_normal_label(normal));
      return this.set('correct_label', this.get_correct_label(correct));
    },
    get_display_value: function() {
      var correct, labels, normal;
      labels = this.get_selection_labels();
      normal = this.get('normal_label');
      correct = this.get('correct_label');
      return "(" + (labels.join(',')) + ") (" + normal + ") (" + correct + ")";
    },
    actions: {
      add_label: function() {
        var class_input, new_count, new_id, selections;
        selections = this.get('selections');
        new_count = this.incrementProperty('new_count');
        new_id = "new_" + new_count;
        class_input = "lab_analysis_" + new_id;
        selections.pushObject({
          id: new_id,
          label: '',
          "class": class_input
        });
        this.set('selections', selections);
        return ember['default'].run.schedule('afterRender', (function(_this) {
          return function() {
            return $("." + class_input).focus();
          };
        })(this));
      },
      delete_label: function(selection) {
        return this.set('selections', this.get('selections').without(selection));
      }
    },
    form_valid: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.validate_selections().then(function(selections) {
            return _this.validate_correct(selections).then(function(correct) {
              return _this.validate_normal(selections).then(function(normal) {
                _this.set_model_value_path([selections, normal, correct]);
                return resolve();
              }, function(error_messages) {
                _this.set_error_messages(error_messages);
                return reject();
              });
            }, function(error_messages) {
              _this.set_error_messages(error_messages);
              return reject();
            });
          }, function(error_messages) {
            _this.set_error_messages(error_messages);
            return reject();
          });
        };
      })(this));
    },
    validate_selections: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var all_selections, ids, selections;
          all_selections = _this.get('selections');
          if (ember['default'].isBlank(all_selections)) {
            return reject(_this.t('builder.lab.admin.form.analysis.errors.selections_blank'));
          }
          ids = [];
          selections = [];
          all_selections.forEach(function(selection) {
            var id, label;
            label = selection.label;
            if (ember['default'].isPresent(label)) {
              label = label.trim();
              id = _this.label_to_id(label);
              if (ids.contains(id)) {
                return reject(_this.t('builder.lab.admin.form.analysis.errors.duplicate_label', label));
              }
              ids.push(id);
              return selections.pushObject({
                id: id,
                label: label
              });
            }
          });
          return resolve(selections);
        };
      })(this));
    },
    validate_correct: function(selections) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var id, label, selected;
          label = _this.get('correct_label');
          if (ember['default'].isBlank(label)) {
            return reject(_this.t('builder.lab.admin.form.analysis.errors.correct_blank'));
          }
          label = label.trim();
          id = _this.label_to_id(label);
          selected = selections.findBy('id', id);
          if (!ember['default'].isPresent(selected)) {
            return reject(_this.t('builder.lab.admin.form.analysis.errors.correct_not_selectable', label));
          }
          if (selected.label !== label) {
            return reject(_this.t('builder.lab.admin.form.analysis.errors.correct_not_selectable', label));
          }
          return resolve(id);
        };
      })(this));
    },
    validate_normal: function(selections) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var id, label, selected;
          label = _this.get('normal_label');
          if (ember['default'].isBlank(label)) {
            return reject(_this.t('builder.lab.admin.form.analysis.errors.normal_blank'));
          }
          label = label.trim();
          id = _this.label_to_id(label);
          selected = selections.findBy('id', id);
          if (!ember['default'].isPresent(selected)) {
            return reject(_this.t('builder.lab.admin.form.analysis.errors.normal_not_selectable', label));
          }
          if (selected.label !== label) {
            return reject(_this.t('builder.lab.admin.form.analysis.errors.normal_not_selectable', label));
          }
          return resolve(id);
        };
      })(this));
    },
    label_to_id: function(label) {
      return (label || '').toLowerCase().replace(/\s/, '_');
    },
    set_error_messages: function(messages) {
      return this.set('error_messages', ember['default'].makeArray(messages));
    },
    get_unbound_selections: function(selections) {
      return selections.map(function(selection) {
        return ember['default'].merge({}, selection);
      });
    },
    get_selection_labels: function() {
      var labels;
      labels = this.get('selections').mapBy('label');
      return labels.map(function(label) {
        return '' + (label || '');
      });
    },
    get_correct_label: function(correct) {
      var selection;
      selection = this.get('selections').findBy('id', correct) || {};
      return '' + (selection.label || '');
    },
    get_normal_label: function(normal) {
      var selection;
      selection = this.get('selections').findBy('id', normal) || {};
      return '' + (selection.label || '');
    },
    rollback: function() {
      this.set('error_messages', null);
      return this.init_values();
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});