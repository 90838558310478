define('client/thinkspace/casespace/case_manager/spaces/route', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace-case-manager/routes/base'], function (exports, ember, ns, base) {

	'use strict';

	var ___DefaultExportObject___;

	___DefaultExportObject___ = base['default'].extend();

	exports['default'] = ___DefaultExportObject___;;

});