define('client/components/thinkspace/casespace/gradebook/assignment/roster/shared/score', ['exports', 'ember', 'thinkspace-base/components/base'], function (exports, ember, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    score: ember['default'].computed.reads('group_values.score'),
    team_ownerable: ember['default'].computed.reads('group_values.team_ownerable'),
    can_edit: ember['default'].computed.and('is_edit', 'group_values.state_id'),
    title: ember['default'].computed(function() {
      return (this.get('team_ownerable') && this.get('group_values.team_label')) || this.get('group_values.user_label') || this.get('group_values.label');
    }),
    edit_score_visible: false,
    input_size: 7,
    new_score: null,
    actions: {
      save: function() {
        this.set('edit_score_visible', false);
        return this.sendAction('save_score', this.get('group_values'), this.get('new_score') || 0);
      },
      cancel: function() {
        return this.set('edit_score_visible', false);
      },
      toggle_edit: function() {
        if (this.toggleProperty('edit_score_visible')) {
          return ember['default'].run.next((function(_this) {
            return function() {
              var parent;
              parent = _this.get('parentView');
              if (parent) {
                return parent.$(':input').focus();
              }
            };
          })(this));
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});