define('client/components/thinkspace/markup/sidepocket/library/tag', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    content: null,
    remove_tag: null,
    tagName: '',
    actions: {
      remove_tag: function() {
        return this.sendAction('remove_tag', this.get('content'));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});