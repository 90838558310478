define('client/components/thinkspace/readiness_assurance/assignment_show', ['exports', 'ember', 'thinkspace-readiness-assurance/base/ra_component'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    willInsertElement: function() {
      this.totem_scope.authable(this.get('model'));
      if (this.get('model.can.update')) {
        this.ra.leave_all_except_admin_room();
        return this.ra.join_admin_room();
      } else {
        this.ra.leave_all_except_current_user_server_event_room();
        this.ra.join_with_current_user();
        this.ra.load_messages();
        return this.ra.tracker();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});