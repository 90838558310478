define('client/components/thinkspace/common/time_zone_picker', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    ttz: ember['default'].inject.service(),
    selected_zone: null,
    zones: ember['default'].computed.reads('ttz.zones'),
    c_dropdown: ns['default'].to_p('common', 'dropdown'),
    init: function() {
      this._super();
      this.set_zone();
      return this.set_selected_zone();
    },
    set_zone: function() {
      var zone;
      zone = this.get('zone');
      if (ember['default'].isPresent(zone)) {
        return;
      }
      return this.set('zone', this.get('ttz').get_client_zone_iana());
    },
    set_selected_zone: function() {
      var selected_zone, zone;
      zone = this.get('zone');
      selected_zone = this.get('ttz').find_by_zone_property('iana', zone);
      return this.set('selected_zone', selected_zone);
    },
    actions: {
      select: function(zone) {
        this.set('selected_zone', zone);
        return this.sendAction('select', zone.iana);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});