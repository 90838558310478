define('client/components/thinkspace/casespace/case_manager/assignment/wizards/assessment/steps/settings/quantitative/balance', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace-case-manager/components/wizards/steps/base', 'totem/mixins/validations', 'thinkspace-casespace-case-manager/components/wizards/assessment/mixins/descriptive_values'], function (exports, ember, ns, base, val_mixin, descriptive_values) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(val_mixin['default'], descriptive_values['default'], {
    points: ember['default'].computed.reads('options.points'),
    points_per_member: ember['default'].computed.reads('points.per_member'),
    points_min: ember['default'].computed.reads('points.min'),
    points_max: ember['default'].computed.reads('points.max'),
    points_different: ember['default'].computed.reads('points.different'),
    set_points_per_member: 'set_points_per_member',
    set_points_max: 'set_points_max',
    set_points_min: 'set_points_min',
    set_points_different: 'set_points_different',
    set_points_descriptive_enabled: 'set_points_descriptive_enabled',
    set_points_descriptive_values: 'set_points_descriptive_values',
    points_different_observer: ember['default'].observer('points_different', function() {
      return this.sendAction('set_points_different', this.get('points_different'));
    }),
    points_descriptive_observer: ember['default'].observer('points_descriptive_enabled', function() {
      return this.sendAction('set_points_descriptive_enabled', this.get('points_descriptive_enabled'));
    }),
    options_observer: ember['default'].observer('options', function() {
      this.set('points', this.get('options.points'));
      this.set('points_per_member', this.get('points.per_member'));
      this.set('points_min', this.get('points.min'));
      this.set('points_max', this.get('points.max'));
      this.set('points_different', this.get('points.different'));
      this.set('points_descriptive_enabled', this.get('points.descriptive.enabled'));
      return this.set('points_descriptive_values', this.get('points.descriptive.values'));
    }),
    actions: {
      set_points_per_member: function(value) {
        return this.sendAction('set_points_per_member', value);
      },
      set_points_max: function(value) {
        return this.sendAction('set_points_max', value);
      },
      set_points_min: function(value) {
        return this.sendAction('set_points_min', value);
      },
      toggle_points_different: function() {
        return this.sendAction('set_points_different', !this.get('points_different'));
      }
    },
    validations: {
      points_per_member: {
        numericality: true
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});