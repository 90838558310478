define('client/components/thinkspace/artifact/bucket/file/pdf/file', ['exports', 'ember', 'totem/config', 'totem/ns', 'totem/ds/associations', 'thinkspace-base/components/base'], function (exports, ember, config, ns, ta, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tvo: ember['default'].inject.service(),
    markup: ember['default'].inject.service(ns['default'].to_p('markup', 'manager')),
    rendered_canvases: [],
    pdf_worker_src: ember['default'].computed(function() {
      return "" + config['default'].pdfjs.worker_src;
    }),
    is_loading: ember['default'].computed.reads('markup.is_pdf_loading'),
    discussions: ember['default'].computed('totem_scope.ownerable_record', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var discussions, markup, model;
          model = _this.get('model');
          markup = _this.get('markup');
          discussions = _this.container.lookup('store:main').filter(ns['default'].to_p('markup', 'discussion'), function(discussion) {
            return markup.discussion_has_discussionable(discussion, model) && markup.discussion_has_ownerable(discussion);
          });
          return resolve(discussions);
        };
      })(this));
      return ta['default'].PromiseArray.create({
        promise: promise
      });
    }),
    discussions_sort_by: ['sort_by'],
    sorted_discussions: ember['default'].computed.sort('discussions', 'discussions_sort_by'),
    defaulted_file_url: ember['default'].computed('file_url', function() {
      var url;
      url = this.get('file_url');
      if (config.env.environment === 'development') {
        if (url.includes('public/')) {
          url = url.replace('public/', '');
          return config['default'].api_host + "/" + url;
        } else {
          return url;
        }
      } else {
        return url;
      }
    }),
    show_file_change: ember['default'].observer('show_file', function() {
      return this.load_and_show_pdf();
    }),
    c_loader: ns['default'].to_p('common', 'loader'),
    c_markup_discussion_markers_default: ns['default'].to_p('markup', 'discussion', 'markers', 'default'),
    didInsertElement: function() {
      return this.load_and_show_pdf();
    },
    willDestroyElement: function() {
      return this.get('markup').reset_is_pdf();
    },
    init: function() {
      this._super();
      return console.log('[pdf:file] File component: ', this);
    },
    click: function(e) {
      var discussionable, library_comment, markup, page, target, value;
      markup = this.get('markup');
      if (!markup.get_is_comments_open()) {
        return;
      }
      target = e.target;
      if (target.tagName !== 'CANVAS') {
        this.totem_messages.error('You cannot create a new discussion on top of an existing discussion.');
        return;
      }
      page = parseInt(e.target.getAttribute('page'));
      value = {
        position: {
          x: e.offsetX,
          y: e.offsetY,
          page: page
        }
      };
      discussionable = this.get('model');
      library_comment = markup.get_selected_library_comment();
      return discussionable.get('authable').then((function(_this) {
        return function(authable) {
          var options;
          options = {
            value: value,
            authable: authable,
            ownerable: _this.totem_scope.get_ownerable_record(),
            creatorable: _this.totem_scope.get_current_user(),
            discussionable: discussionable
          };
          if (library_comment) {
            options.save = true;
          }
          return markup.add_discussion(options).then(function(discussion) {
            if (library_comment) {
              options = {
                commenterable: _this.totem_scope.get_current_user(),
                library_comment: library_comment
              };
              markup.reset_selected_library_comment();
              return markup.add_comment_to_discussion(discussion, options);
            } else {
              options = {
                commenterable: _this.totem_scope.get_current_user()
              };
              return markup.add_comment_to_discussion_and_edit(discussion, options);
            }
          });
        };
      })(this));
    },
    get_$file_container: function() {
      var container_id;
      container_id = this.get('file_container_id');
      return $('#' + container_id);
    },
    load_and_show_pdf: function() {
      var $container, model, width;
      if (!this.get('show_file')) {
        return;
      }
      if (this.get('is_loaded')) {
        return;
      }
      model = this.get('model');
      $container = this.get_$file_container();
      if ($container.length < 1) {
        return;
      }
      width = 960;
      if (config.env.environment === 'development') {
        PDFJS.disableWorker = true;
      }
      PDFJS.workerSrc = this.get('pdf_worker_src');
      PDFJS.cMapUrl = 'cmaps/';
      PDFJS.cMapPacked = true;
      this.get('markup').set_is_pdf_loading();
      return PDFJS.getDocument(this.get('defaulted_file_url')).then((function(_this) {
        return function(pdf) {
          return pdf.getPage(1).then(function(page) {
            var options;
            options = {
              pdf: pdf,
              num_pages: pdf.numPages,
              num_current_page: 1,
              container: $container,
              scale: 1,
              width: width
            };
            return _this.render_page(page, options);
          });
        };
      })(this));
    },
    render_page: function(page, options) {
      var canvas, container, context, markup, model, num_current_page, num_pages, pdf, render_context, scale, viewport, width;
      scale = options.scale;
      container = options.container;
      num_pages = options.num_pages;
      pdf = options.pdf;
      width = options.width;
      if (width != null) {
        viewport = page.getViewport(1);
        scale = width / viewport.width;
      }
      viewport = page.getViewport(scale);
      canvas = $('<canvas></canvas>').attr('height', viewport.height).attr('width', viewport.width).attr('page', options.num_current_page);
      context = canvas.get(0).getContext('2d');
      render_context = {
        canvasContext: context,
        viewport: viewport
      };
      page.render(render_context);
      container.append(canvas);
      this.get('rendered_canvases').pushObject(canvas);
      options.num_current_page += 1;
      num_current_page = options.num_current_page;
      if (num_current_page <= num_pages && pdf) {
        return pdf.getPage(num_current_page).then((function(_this) {
          return function(page) {
            return _this.render_page(page, options);
          };
        })(this));
      } else {
        markup = this.get('markup');
        model = this.get('model');
        markup.set_is_pdf();
        return markup.set_is_pdf_loaded();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});