define('client/components/thinkspace/artifact/bucket/file/pdf/comments', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    c_markup_comments: ns['default'].to_p('markup', 'comments')
  });

  exports['default'] = ___DefaultExportObject___;;

});