define('client/services/thinkspace/readiness_assurance/admin_manager', ['exports', 'ember', 'thinkspace-readiness-assurance/mixins/am/base'], function (exports, ember, m_base) {

	'use strict';

	var ___DefaultExportObject___;

	___DefaultExportObject___ = ember['default'].Service.extend(m_base['default']);

	exports['default'] = ___DefaultExportObject___;;

});