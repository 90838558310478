define('client/thinkspace/casespace/case_manager/assignments/clone/route', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace-case-manager/routes/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    titleToken: function(model) {
      return 'Clone ' + model.get('title');
    },
    model: function(params) {
      return this.get_updatable_spaces();
    },
    afterModel: function(model) {
      return this.set_current_models({
        assignment: model
      }).then((function(_this) {
        return function() {
          return _this.load_assignment();
        };
      })(this));
    },
    get_updatable_spaces: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.load_spaces().then(function() {
            return _this.get_assignment_from_params(params).then(function() {
              return resolve(_this.get('case_manager').get_updatable_store_spaces());
            });
          }, function(error) {
            return reject(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return reject(error);
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});