define('client/models/thinkspace/casespace/phase', ['exports', 'ember', 'totem/ds/associations', 'totem/cache', 'thinkspace-resource/mixins/resources'], function (exports, ember, ta, tc, resource_mixin) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].totem_data, resource_mixin['default'], ta['default'].add(ta['default'].belongs_to('assignment', {
    reads: {}
  }), ta['default'].belongs_to('configuration', {
    reads: {}
  }), ta['default'].belongs_to('phase_template', {
    reads: {}
  }), ta['default'].has_many('phase_components', {
    reads: {}
  }), ta['default'].has_many('phase_states', {
    reads: {
      filter: true,
      notify: true
    }
  })), {
    title: ta['default'].attr('string'),
    team_category_id: ta['default'].attr('number'),
    team_set_id: ta['default'].attr('number'),
    active: ta['default'].attr('boolean'),
    team_ownerable: ta['default'].attr('boolean'),
    position: ta['default'].attr('number'),
    description: ta['default'].attr('string'),
    user_action: ta['default'].attr('string'),
    default_state: ta['default'].attr('string'),
    state: ta['default'].attr('string'),
    unlock_at: ta['default'].attr('date'),
    due_at: ta['default'].attr('date'),
    settings: ta['default'].attr(),
    totem_data_config: {
      ability: true
    },
    ttz: ember['default'].inject.service(),
    didLoad: function() {
      return this.get(ta['default'].to_p('assignment')).then((function(_this) {
        return function(assignment) {
          return assignment.get(ta['default'].to_p('phases')).then(function(phases) {
            if (!phases.contains(_this)) {
              return phases.pushObject(_this);
            }
          });
        };
      })(this));
    },
    is_team_ownerable: function() {
      return this.get('team_ownerable');
    },
    configuration_validate: ember['default'].computed.reads('settings.validation.validate'),
    max_score: ember['default'].computed.reads('settings.phase_score_validation.numericality.less_than_or_equal_to'),
    submit_text: ember['default'].computed.reads('settings.submit.text'),
    show_errors_on_submit: ember['default'].computed.reads('settings.submit.show_errors'),
    submit_visible: ember['default'].computed.reads('settings.submit.visible'),
    is_submit_visible: ember['default'].computed.bool('submit_visible'),
    has_auto_score: ember['default'].computed.reads('settings.actions.submit.auto_score'),
    has_unlock_phase: ember['default'].computed.equal('settings.actions.submit.unlock', 'next'),
    has_complete_phase: ember['default'].computed.equal('settings.actions.submit.state', 'complete'),
    has_team_category: ember['default'].computed.notEmpty('team_category_id'),
    has_team_set: ember['default'].computed.notEmpty('team_set_id'),
    has_team_category_without_team_set: ember['default'].computed('has_team_category', 'has_team_set', function() {
      return this.get('has_team_category') && !this.get('has_team_set');
    }),
    phase_state: ember['default'].computed.reads('phase_states.firstObject'),
    current_state: ember['default'].computed.or('phase_state.current_state', 'default_state'),
    is_unlocked: ember['default'].computed.bool('phase_state.is_unlocked'),
    is_locked: ember['default'].computed.bool('phase_state.is_locked'),
    is_active: ember['default'].computed.equal('state', 'active'),
    is_inactive: ember['default'].computed.equal('state', 'inactive'),
    is_archived: ember['default'].computed.equal('state', 'archived'),
    is_not_active: ember['default'].computed.not('is_active'),
    is_not_archived: ember['default'].computed.not('is_archived'),
    friendly_unlock_at_date: ember['default'].computed('unlock_at', function() {
      var date;
      date = this.get('unlock_at');
      if (!ember['default'].isPresent(date)) {
        return 'N/A';
      }
      return this.get('ttz').format(date, {
        format: 'MMM D, YYYY',
        zone: this.get('ttz').get_client_zone_iana()
      });
    }),
    friendly_unlock_at_time: ember['default'].computed('unlock_at', function() {
      var date;
      date = this.get('unlock_at');
      if (!ember['default'].isPresent(date)) {
        return 'N/A';
      }
      return this.get('ttz').format(date, {
        format: 'h:mm a',
        zone: this.get('ttz').get_client_zone_iana()
      });
    }),
    friendly_unlock_at_date_and_time: ember['default'].computed('unlock_at', function() {
      return this.get('friendly_unlock_at_date') + ' at ' + this.get('friendly_unlock_at_time');
    }),
    friendly_due_at_date: ember['default'].computed('due_at', function() {
      var date;
      date = this.get('due_at');
      if (!ember['default'].isPresent(date)) {
        return 'N/A';
      }
      return this.get('ttz').format(date, {
        format: 'MMM D, YYYY',
        zone: this.get('ttz').get_client_zone_iana()
      });
    }),
    friendly_due_at_time: ember['default'].computed('due_at', function() {
      var date;
      date = this.get('due_at');
      if (!ember['default'].isPresent(date)) {
        return 'N/A';
      }
      return this.get('ttz').format(date, {
        format: 'h:mm a',
        zone: this.get('ttz').get_client_zone_iana()
      });
    }),
    friendly_due_at_date_and_time: ember['default'].computed('due_at', function() {
      return this.get('friendly_due_at_date') + ' at ' + this.get('friendly_due_at_time');
    }),
    unlock_mode: ember['default'].computed('default_state', 'unlock_at', 'position', 'previous_phase.settings.actions.submit.unlock', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get_unlock_mode().then(function(unlock_mode) {
            return resolve(unlock_mode);
          });
        };
      })(this));
      return ta['default'].PromiseObject.create({
        promise: promise
      });
    }),
    unlock_mode_display: ember['default'].computed('default_state', 'unlock_at', 'position', 'previous_phase.settings.actions.submit.unlock', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get_unlock_mode_display().then(function(unlock_mode_display) {
            return resolve(unlock_mode_display);
          });
        };
      })(this));
      return ta['default'].PromiseObject.create({
        promise: promise
      });
    }),
    get_unlock_mode: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get('previous_phase').then(function(phase) {
            if (_this.get('unlock_at')) {
              return resolve('date');
            } else if (_this.get('default_state') === 'unlocked') {
              return resolve('case release');
            } else if (ember['default'].isPresent(phase) && phase.get('has_unlock_phase')) {
              return resolve('previous phase submission');
            } else {
              return resolve('manual');
            }
          });
        };
      })(this));
    },
    get_unlock_mode_display: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get_unlock_mode().then(function(unlock_mode) {
            var date, time;
            switch (unlock_mode) {
              case 'date':
                date = moment(_this.get('unlock_at')).format('MMM D, YYYY');
                time = moment(_this.get('unlock_at')).format('h:mm a');
                return resolve("Unlocks on " + (_this.get('friendly_unlock_at_date_and_time')));
              case 'case release':
                return resolve('Unlocks on case release');
              case 'previous phase submission':
                return _this.get('previous_phase').then(function(phase) {
                  return phase.get('position_in_assignment').then(function(position) {
                    return resolve("Unlocks after " + position.value + ". " + (phase.get('title')));
                  });
                });
              case 'manual':
                return resolve("Unlocks manually (currently locked)");
            }
          });
        };
      })(this));
    },
    previous_phase: ember['default'].computed(ta['default'].to_p('assignment'), ta['default'].to_prop('assignment', 'phases', 'length'), 'position', function() {
      return this.get_phase_at_index_increment(-1);
    }),
    next_phase: ember['default'].computed(ta['default'].to_p('assignment'), ta['default'].to_prop('assignment', 'phases', 'length'), 'position', function() {
      return this.get_phase_at_index_increment(+1);
    }),
    get_phase_at_index_increment: function(increment) {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get(ta['default'].to_p('assignment')).then(function(assignment) {
            return assignment.get('active_phases').then(function(phases) {
              var index, new_index, phase;
              index = phases.indexOf(_this);
              new_index = index + increment;
              phase = phases.objectAt(new_index);
              if (!ember['default'].isPresent(phase)) {
                return resolve(null);
              }
              return resolve(phase);
            }, function(error) {
              return reject(error);
            });
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
      return ta['default'].PromiseObject.create({
        promise: promise
      });
    },
    friendly_submit_visible: ember['default'].computed('submit_visible', function() {
      return ((this.get('submit_visible') != null) && this.get('submit_visible')) || true;
    }),
    friendly_submit_text: ember['default'].computed('submit_text', function() {
      return this.get('submit_text') || 'Submit';
    }),
    friendly_max_score: ember['default'].computed('max_score', function() {
      return ((this.get('max_score') != null) && parseInt(this.get('max_score'))) || 1;
    }),
    move_to_top: function() {
      return this.get_sorted_phases().then((function(_this) {
        return function(phases) {
          phases.removeObject(_this);
          phases.insertAt(0, _this);
          return phases.forEach(function(phase, index) {
            var position;
            position = phase.get('position');
            phase.set('position', index);
            if (position === index) {
              return phase.notifyPropertyChange('position');
            }
          });
        };
      })(this));
    },
    move_to_bottom: function() {
      return this.get_sorted_phases().then((function(_this) {
        return function(phases) {
          var length;
          phases.removeObject(_this);
          length = phases.get('length');
          phases.insertAt(length, _this);
          return phases.forEach(function(phase, index) {
            var position;
            position = phase.get('position');
            phase.set('position', index);
            if (position === index) {
              return phase.notifyPropertyChange('position');
            }
          });
        };
      })(this));
    },
    move_to_offset: function(offset) {
      return this.get_sorted_phases().then((function(_this) {
        return function(phases) {
          var index, length, new_index;
          index = phases.indexOf(_this);
          new_index = index + offset;
          length = phases.get('length');
          if (new_index >= length || new_index < 0) {
            return;
          }
          phases.removeObject(_this);
          phases.insertAt(new_index, _this);
          return phases.forEach(function(phase, index) {
            var position;
            position = phase.get('position');
            phase.set('position', index);
            if (position === index) {
              return phase.notifyPropertyChange('position');
            }
          });
        };
      })(this));
    },
    get_sorted_phases: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get(ta['default'].to_p('assignment')).then(function(assignment) {
            var property;
            if (_this.get('is_active')) {
              property = 'active_phases';
            } else {
              property = 'archived_phases';
            }
            return assignment.get(property).then(function(phases) {
              var sorted;
              sorted = phases.sortBy('position');
              return resolve(sorted);
            });
          });
        };
      })(this));
    },
    position_in_assignment: ember['default'].computed('position', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (_this.get('is_archived')) {
            return resolve({
              value: _this.get('position')
            });
          } else {
            return _this.get(ta['default'].to_p('assignment')).then(function(assignment) {
              return assignment.get('active_phases').then(function(phases) {
                var position;
                position = phases.indexOf(_this);
                if (!ember['default'].isPresent(position)) {
                  return resolve({
                    value: 0
                  });
                }
                return resolve({
                  value: position + 1
                });
              });
            });
          }
        };
      })(this));
      return ta['default'].PromiseObject.create({
        promise: promise
      });
    }),
    state_change: function(action) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return tc['default'].query(ta['default'].to_p('phase'), {
            id: _this.get('id'),
            action: action,
            verb: 'PUT'
          }, {
            single: true
          }).then(function(phase) {
            return resolve(phase);
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    inactivate: function() {
      return this.state_change('inactivate');
    },
    archive: function() {
      return this.state_change('archive');
    },
    activate: function() {
      return this.state_change('activate');
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});