define('client/thinkspace/team/users/user/view', ['exports', 'ember', 'ember-data', 'totem/ns'], function (exports, ember, ds, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].View.extend({
    tagName: 'ul',
    classNames: ['team_users-user-list'],
    classNameBindings: ['controller.selected_team:team_users-selectable-user'],
    teams_visible: true,
    user_teams_visible: ember['default'].observer('controller.user_teams_visible', function() {
      return this.set('teams_visible', this.get('controller.user_teams_visible'));
    }),
    teams_sort_by: ['title'],
    teams_sorted: ember['default'].computed.sort('filtered_teams', 'teams_sort_by'),
    filtered_teams: ember['default'].computed.intersect('teams', 'controller.filtered_teams'),
    teams: ember['default'].computed('team_users.@each', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get('team_users').then(function(team_users) {
            var team_promises;
            team_promises = team_users.getEach(ns['default'].to_p('team'));
            return ember['default'].RSVP.Promise.all(team_promises).then(function(teams) {
              return resolve(teams);
            });
          });
        };
      })(this));
      return ds['default'].PromiseArray.create({
        promise: promise
      });
    }),
    team_users: ember['default'].computed(function() {
      var user_id;
      user_id = parseInt(this.get('user.id'));
      return this.get('controller.store').filter(ns['default'].to_p('team_user'), (function(_this) {
        return function(team_user) {
          return team_user.get('user_id') === user_id;
        };
      })(this));
    }),
    show_user: ember['default'].computed('controller.filter_users', 'filtered_teams', function() {
      var length, user_filter;
      user_filter = this.get('controller.filter_users');
      if (!user_filter) {
        return true;
      }
      length = this.get('filtered_teams.length');
      switch (user_filter) {
        case 'unassigned':
          return length <= 0;
        case 'assigned':
          return length > 0;
        default:
          return true;
      }
    }),
    actions: {
      add_user: function() {
        return this.get('controller').create_team_user(this.get('controller.selected_team'), this.get('user'));
      },
      toggle_teams_visible: function() {
        this.toggleProperty('teams_visible');
        return false;
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});