define('client/components/thinkspace/lab/chart/select_category', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    is_active: ember['default'].computed('selected', function() {
      return this.get('model') === this.get('selected');
    }),
    actions: {
      select: function(category) {
        return this.sendAction('select', category);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});