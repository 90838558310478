define('client/components/thinkspace/indented_list/list/layout/shared/header', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    layout_name: ember['default'].computed(function() {
      return this.get('list.layout').toLowerCase();
    }),
    list_title: ember['default'].computed(function() {
      var layout_name;
      layout_name = this.get('layout_name');
      switch (layout_name) {
        case 'diagnostic_path':
          return 'Diagnostic Path';
        case 'other':
          return 'Other Layout';
        default:
          return layout_name.toUpperCase();
      }
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});