define('client/components/thinkspace/lab/observation/vet_med/select', ['exports', 'ember', 'totem/ns', 'totem/util', 'thinkspace-base/components/base'], function (exports, ember, ns, util, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    init: function() {
      var value;
      this._super();
      this.set('is_init', true);
      this.get_lab_observation().register_component(this);
      value = this.get_value() || this.get_normal_value();
      this.set_value(value);
      this.set('selected', this.get_lab_observation().get_selections().findBy('id', value));
      return this.set('selection', value.capitalize());
    },
    c_dropdown: ns['default'].to_p('common', 'dropdown'),
    get_lab_observation: function() {
      return this.get('lab_observation');
    },
    get_value: function() {
      return this.get_lab_observation().get_value();
    },
    set_value: function(value) {
      return this.get_lab_observation().set_value(value);
    },
    get_normal_value: function() {
      return this.get_lab_observation().get_normal_value();
    },
    get_correct_value: function() {
      return this.get_lab_observation().get_correct_value();
    },
    set_lab_observation_value: function() {
      return this.set_value(this.get_selected_id());
    },
    get_result: function() {
      return this.get_lab_observation().get_result();
    },
    save_result: function() {
      return this.get_lab_observation().save_result(this.get_result());
    },
    get_selected_id: function() {
      return this.get('selected.id');
    },
    get_abnomality_lab_observation: function() {
      return this.get_lab_observation().get_result_lab_observation_for_key('abnormality');
    },
    focus_self: function() {
      return this.$('.ts-lab_select').focus();
    },
    focus_next_result: function() {
      return this.get_lab_observation().set_focus_on_next_result();
    },
    get_error_message: function() {
      return this.get_lab_observation().get_error_message();
    },
    set_error_message: function(message) {
      return this.get_lab_observation().set_error_message(message);
    },
    clear_error_message: function() {
      return this.get_lab_observation().clear_error_message();
    },
    selections: ember['default'].computed(function() {
      return this.get_lab_observation().get_selections();
    }),
    is_another_event: null,
    is_correct: null,
    selection: null,
    selected: null,
    didInsertElement: function() {
      return this.$('option').on('click', (function(_this) {
        return function(e) {
          return _this.process_option_click(e);
        };
      })(this));
    },
    focusIn: function(event) {
      return this.get_lab_observation().set_focused();
    },
    keyPress: function(event) {
      var char_code, key_code, selected, selected_id, selected_ids, selection_ids, selections, value;
      key_code = event.keyCode;
      switch (key_code) {
        case 13:
          return this.save_selected();
        default:
          char_code = event.which || event.charCode || event.keyCode;
          if (!char_code) {
            return;
          }
          value = String.fromCharCode(char_code).toLowerCase();
          selections = this.get('selections');
          selection_ids = selections.mapBy('id');
          selected_ids = selection_ids.filter(function(id) {
            return id && util['default'].starts_with(id, value);
          });
          if (selected_ids.get('length') !== 1) {
            return;
          }
          selected_id = selected_ids.get('firstObject');
          if (!selected_id) {
            return;
          }
          selected = selections.findBy('id', selected_id);
          if (!selected) {
            return;
          }
          this.set('selected', selected);
          this.set('selection', selected.label);
          return this.save_selected();
      }
    },
    process_option_click: function(e) {
      var clicked, selected;
      selected = this.get('selected.id');
      clicked = this.$('option:selected').val();
      if (ember['default'].isEqual(selected, clicked)) {
        return this.propertyDidChange('selected');
      }
    },
    show_input_and_focus: function() {
      var correct, lo;
      lo = this.get_abnomality_lab_observation();
      correct = lo.get_correct_value();
      if (ember['default'].isPresent(correct)) {
        lo.set_show_input(true);
        return lo.focus_self();
      } else {
        return this.focus_next_result();
      }
    },
    save_selected: function() {
      var correct, normal, value;
      this.set_lab_observation_value();
      value = this.get_value();
      correct = this.get_correct_value();
      normal = this.get_normal_value();
      this.set('is_correct', value === correct);
      switch (false) {
        case !(value === correct && value === normal):
          return this.save_select_result(this.get_abnomality_lab_observation());
        case !(correct === normal && value !== correct):
          return this.save_select_result();
        case value === correct:
          return this.save_select_result();
        default:
          return this.save_select_result(this.get_abnomality_lab_observation());
      }
    },
    save_select_result: function(lo) {
      if (lo == null) {
        lo = null;
      }
      this.show_save_retry_message();
      return this.save_result().then((function(_this) {
        return function() {
          _this.clear_error_message();
          if (lo) {
            return _this.show_input_and_focus();
          } else {
            return _this.focus_next_result();
          }
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.show_save_error();
        };
      })(this));
    },
    show_save_error: function() {
      this.set('is_another_event', false);
      return this.show_save_error_message();
    },
    show_save_error_message: function() {
      return this.set_error_message("Could not save result. Please try again.");
    },
    show_save_retry_message: function() {
      if (!this.get_error_message()) {
        return;
      }
      return this.set_error_message("Retrying save.");
    },
    actions: {
      select: function(option) {
        this.set('selection', option.label);
        this.set('selected', option);
        return this.save_selected();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});