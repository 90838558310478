define('client/initializers/thinkspace/authorization/metadata', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___, initializer;

  initializer = {
    name: 'thinkspace-authorization-metadata',
    after: ['totem'],
    initialize: function(container, app) {
      var metadata, path;
      metadata = 'metadata';
      path = ns['default'].to_p('authorization', metadata);
      ember['default'].Inflector.inflector.irregular(path, path);
      return ember['default'].Inflector.inflector.irregular(metadata, metadata);
    }
  };

  ___DefaultExportObject___ = initializer;

  exports['default'] = ___DefaultExportObject___;;

});