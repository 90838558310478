define('client/components/thinkspace/casespace/assignment/header', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    r_spaces_show: ns['default'].to_r('spaces', 'show'),
    r_assignments_reports: ns['default'].to_p('assignments', 'reports'),
    r_assignments_scores: ns['default'].to_r('assignments', 'scores'),
    r_assignments_show: ns['default'].to_r('assignments', 'show'),
    r_cm_assessments: ns['default'].to_r('case_manager', 'assignments', 'peer_assessment', 'assessments')
  });

  exports['default'] = ___DefaultExportObject___;;

});