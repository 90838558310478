define('client/models/thinkspace/weather_forecaster/response', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(ta['default'].add(ta['default'].belongs_to('wf:forecast'), ta['default'].belongs_to('wf:assessment_item')), {
    assessment_item_id: ta['default'].attr('number'),
    value: ta['default'].attr(),
    response_score_metadata: ta['default'].attr(),
    input_value: ember['default'].computed.reads('value.input'),
    actual: ember['default'].computed.reads('response_score_metadata.var_actual.actual'),
    logic: ember['default'].computed.reads('response_score_metadata.var_actual.logic'),
    is_correct: ember['default'].computed.bool('response_score_metadata.is_correct'),
    has_score: ember['default'].computed.bool('actual'),
    id_is_correct: function(id) {
      return ember['default'].makeArray(this.get('actual')).contains(id);
    },
    set_associations: function(forecast, assessment_item) {
      this.set(ta['default'].to_p('wf:forecast'), forecast);
      this.set(ta['default'].to_p('wf:assessment_item'), assessment_item);
      return this.set('assessment_item_id', parseInt(assessment_item.get('id')));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});