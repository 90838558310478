define('client/components/thinkspace/artifact/builder/bucket/content', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base', 'totem/mixins/ckeditor'], function (exports, ember, ns, base, ckeditor_mixin) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(ckeditor_mixin['default'], {
    ckeditor_tag: null,
    editor_loaded: false,
    c_loader: ns['default'].to_p('common', 'loader'),
    didInsertElement: function() {
      var edit_area, options;
      edit_area = this.$('textarea.bucket_instructions-content');
      this.set('ckeditor_tag', edit_area);
      options = {
        height: 250
      };
      return this.ckeditor_load().then((function(_this) {
        return function() {
          return edit_area.ckeditor((function() {
            return _this.set('editor_loaded', true);
          }), options);
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.totem_error["throw"](_this, error);
        };
      })(this));
    },
    willDestroyElement: function() {
      return this.send('cancel');
    },
    actions: {
      save: function() {
        var content, model;
        content = this.ckeditor_value();
        model = this.get('model');
        model.set('instructions', content);
        this.totem_messages.show_loading_outlet();
        return model.save().then((function(_this) {
          return function() {
            _this.totem_messages.hide_loading_outlet();
            return _this.sendAction('cancel');
          };
        })(this));
      },
      cancel: function() {
        return this.sendAction('cancel');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});