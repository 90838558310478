define('client/helpers/lab-result-value', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Handlebars.makeBoundHelper(function(result, prop, options) {
    var description, title, value_prop;
    if (!(result && prop)) {
      return '';
    }
    value_prop = 'values.columns.' + prop;
    switch (prop) {
      case 'title':
        description = result.get('description');
        title = result.get('title');
        return (description && ("<span title='" + description + "'>" + title + "</span>").htmlSafe()) || title;
      default:
        return result.get(value_prop);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});