define('client/components/thinkspace/resource/manage/link/edit', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    t_manage_link_form: ns['default'].to_t('resource', 'manage', 'link', 'form'),
    title: null,
    url: null,
    selection: null,
    actions: {
      cancel: function() {
        return this.sendAction('cancel');
      },
      save: function() {
        var link;
        link = this.get('model');
        link.set('title', this.get('title'));
        link.set('url', this.get('url'));
        link.set_new_tags(this.get('selection'));
        return link.save().then((function(_this) {
          return function() {
            _this.totem_messages.api_success({
              source: _this,
              model: link,
              action: 'save',
              i18n_path: ns['default'].to_o('link', 'save')
            });
            return _this.send('cancel');
          };
        })(this), (function(_this) {
          return function(error) {
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: link,
              action: 'save'
            });
          };
        })(this));
      }
    },
    didInsertElement: function() {
      this.set('title', this.get('model.title'));
      this.set('url', this.get('model.url'));
      return this.set('selection', this.get('model.tag'));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});