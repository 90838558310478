define('client/components/thinkspace/casespace/sidepocket', ['exports', 'ember', 'thinkspace-base/components/base'], function (exports, ember, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    current_route: null,
    model: ember['default'].computed.reads('casespace.current_phase'),
    thinkspace: ember['default'].inject.service(),
    casespace: ember['default'].inject.service(),
    c_sidepocket_component: ember['default'].computed.reads('casespace.c_sidepocket_component'),
    has_sticky_addon: ember['default'].computed.reads('casespace.has_sticky_addon'),
    sidepocket_reset: ember['default'].observer('current_route', function() {
      var casespace;
      casespace = this.get('casespace');
      if (casespace.get('sidepocket_is_expanded')) {
        return casespace.hide_sidepocket();
      }
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});