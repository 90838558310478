define('client/components/thinkspace/html/html', ['exports', 'ember', 'totem/ns', 'thinkspace-html/components/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    classNames: ['ts-componentable']
  });

  exports['default'] = ___DefaultExportObject___;;

});