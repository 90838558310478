define('client/components/thinkspace/lab/builder/lab/chart/select_category', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    admin: ember['default'].inject.service(ns['default'].to_p('lab', 'admin')),
    is_category_active: ember['default'].computed('admin.selected_category', function() {
      return this.get('model') === this.get('admin.selected_category');
    }),
    actions: {
      select_category: function(category) {
        var admin;
        admin = this.get('admin');
        admin.clear();
        return admin.set_selected_category(category);
      }
    },
    sortable_selector: function() {
      return this.get('admin').get_category_sortable_selector();
    },
    didInsertElement: function() {
      return this.add_category_sortable();
    },
    willDestroyElement: function() {
      var selector;
      selector = this.sortable_selector();
      return $(selector).sortable('destroy');
    },
    add_category_sortable: function() {
      var options, selector;
      selector = this.sortable_selector();
      options = {
        group: 'categories',
        containerSelector: selector,
        handle: '.ts-lab_admin-sortable-category-handle',
        clone: false,
        consume: false,
        exclude: '.sortable-exclude',
        nested: false,
        vertical: false,
        drag: true,
        drop: true,
        delay: 5,
        pullPlaceholder: false,
        onDrop: this.on_drop,
        admin: this.get('admin')
      };
      return $(selector).sortable(options);
    },
    on_drop: function($item, container, _super, event) {
      var admin;
      _super($item, container, _super, event);
      admin = container && container.options && container.options.admin;
      if (!admin) {
        return;
      }
      return admin.on_drop_category_reorder($item, container);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});