define('client/components/thinkspace/weather_forecaster/assessment/top_forecasts', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    top_forecasts_sort_by: ['score:desc', 'title:asc'],
    top_forecasts_sorted: ember['default'].computed.sort('top_forecasts', 'top_forecasts_sort_by')
  });

  exports['default'] = ___DefaultExportObject___;;

});