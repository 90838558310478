define('client/components/thinkspace/weather_forecaster/forecast/show', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    c_assessment_item: ns['default'].to_p('wf:forecast', 'assessment_item'),
    show_errors: true,
    is_locked: ember['default'].computed.bool('model.is_locked'),
    is_view_only: ember['default'].computed.or('totem_scope.is_view_only', 'is_locked'),
    actions: {
      show_help: function(help_tip) {
        return this.sendAction('show_help', help_tip);
      },
      save: function(response, values) {
        return this.save_response(response, values);
      },
      submit: function() {
        this.set('show_errors', true);
        return this.save_forecast();
      }
    },
    save_forecast: function() {
      var forecast;
      if (this.get('is_view_only')) {
        return;
      }
      forecast = this.get('model');
      if (!forecast) {
        return;
      }
      return forecast.save().then((function(_this) {
        return function() {
          return _this.totem_messages.api_success({
            source: _this,
            model: forecast,
            action: 'save',
            i18n_path: ns['default'].to_o('wf:forecast', 'save')
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.totem_messages.api_failure(error, {
            source: _this,
            model: forecast
          });
        };
      })(this));
    },
    save_response: function(response, values) {
      if (this.get('is_view_only')) {
        return;
      }
      if (!response) {
        return;
      }
      response.set('value', {
        input: values
      });
      return response.save().then((function(_this) {
        return function() {
          return _this.totem_messages.api_success({
            source: _this,
            model: response,
            action: 'save',
            i18n_path: ns['default'].to_o('wf:response', 'save')
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.totem_messages.api_failure(error, {
            source: _this,
            model: response
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});