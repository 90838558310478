define('client/helpers/row-number', ['exports', 'ember', 'totem/util'], function (exports, ember, util) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Handlebars.makeBoundHelper(function(row_object, row_prop, options) {
    var hash, length, pad, period, row;
    if (!(row_object && (row_object.set != null))) {
      return '';
    }
    if (!options) {
      options = row_prop;
      row_prop = 'row_number';
    }
    row = row_object.get(row_prop) || 0;
    row += 1;
    hash = options.hash || {};
    length = hash.length;
    period = hash.period;
    row_object.set(row_prop, row);
    if (period === false) {
      period = '';
    } else {
      period = '.';
    }
    if (length) {
      pad = hash.pad;
      pad = ((pad != null) && pad) || '0';
      return util['default'].rjust(row, length, "" + pad) + period;
    } else {
      return row + period;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});