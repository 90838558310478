define('client/thinkspace/casespace/case_manager/assignments/route', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace-case-manager/routes/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    titleToken: 'Assignments'
  });

  exports['default'] = ___DefaultExportObject___;;

});