define('client/components/thinkspace/peer_assessment/builder/assessment/content', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    builder: ember['default'].inject.service(),
    manager: ember['default'].inject.service(ns['default'].to_p('peer_assessment', 'builder', 'manager')),
    model: null,
    quant_items: ember['default'].computed.reads('model.quant_items'),
    qual_items: ember['default'].computed.reads('model.qual_items'),
    c_quant_preview: ns['default'].to_p('peer_assessment', 'builder', 'assessment', 'quant', 'preview'),
    c_qual_preview: ns['default'].to_p('peer_assessment', 'builder', 'assessment', 'qual', 'preview'),
    init: function() {
      this._super();
      this.get('manager').set_model(this.get('model'));
      return this.get('builder').set_toolbar(this, ns['default'].to_p('peer_assessment', 'builder', 'assessment', 'toolbar'));
    },
    actions: {
      add_quant_item: function() {
        var manager;
        manager = this.get('manager');
        return manager.add_quant_item();
      },
      add_qual_item: function(type) {
        var manager;
        manager = this.get('manager');
        return manager.add_qual_item(type);
      },
      save: function() {
        return this.sendAction('set_mode', 'preview');
      },
      cancel: function() {
        return this.sendAction('set_mode', 'preview');
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});