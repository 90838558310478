define('client/models/thinkspace/common/user', ['exports', 'ember', 'totem/ds/associations', 'thinkspace-common/mixins/avatar'], function (exports, ember, ta, avatar) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(avatar['default'], ta['default'].add(ta['default'].has_many('disciplines', {
    reads: {}
  }), ta['default'].has_many('keys', {
    reads: {}
  })), {
    email: ta['default'].attr('string'),
    first_name: ta['default'].attr('string'),
    last_name: ta['default'].attr('string'),
    state: ta['default'].attr('string'),
    activated_at: ta['default'].attr('date'),
    profile: ta['default'].attr(),
    terms_accepted_at: ta['default'].attr('date'),
    email_optin: ta['default'].attr('boolean'),
    updates: ta['default'].attr(),
    tos_current: ta['default'].attr('boolean'),
    has_key: ta['default'].attr('boolean'),
    full_name: ember['default'].computed('first_name', 'last_name', function() {
      var email, first_name, last_name;
      first_name = this.get('first_name') || '?';
      last_name = this.get('last_name') || '?';
      email = this.get('email');
      if (last_name === '?') {
        return first_name + " " + last_name + " - " + email;
      } else {
        return first_name + " " + last_name;
      }
    }),
    sort_name: ember['default'].computed(function() {
      return (this.get('last_name')) + ", " + (this.get('first_name'));
    }),
    html_title: ember['default'].computed(function() {
      return (this.get('full_name')) + " - " + (this.get('email'));
    }),
    first_initial: ember['default'].computed('first_name', function() {
      return this.get_initial_from_name(this.get('first_name'));
    }),
    last_initial: ember['default'].computed('last_name', function() {
      return this.get_initial_from_name(this.get('last_name'));
    }),
    display_name: ember['default'].computed.reads('full_name'),
    initials: ember['default'].computed('first_name', 'last_name', function() {
      return (this.get('first_initial')) + " " + (this.get('last_initial'));
    }),
    color_string: ember['default'].computed('initials', function() {
      return (this.get('initials')) + "-" + (this.get('id'));
    }),
    color: 'eeeeee',
    invitation_status: ember['default'].computed('state', function() {
      if (this.get('is_active')) {
        return 'Accepted';
      }
      if (this.get('is_inactive')) {
        return 'Pending';
      }
    }),
    is_active: ember['default'].computed.equal('state', 'active'),
    is_inactive: ember['default'].computed.equal('state', 'inactive'),
    get_initial_from_name: function(name) {
      if (!ember['default'].isPresent(name)) {
        return '?';
      }
      return name.charAt(0).capitalize();
    },
    is_student: ember['default'].computed.equal('profile.roles.student', true),
    is_teacher: ember['default'].computed.equal('profile.roles.instructor', true)
  });

  exports['default'] = ___DefaultExportObject___;;

});