define('client/templates/components/thinkspace/peer_assessment/builder/assessment/quant/settings', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ts-pa_item-settings");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","ts-forms_inline-heading");
        var el3 = dom.createElement("h4");
        var el4 = dom.createTextNode("Scale Settings");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-forms_heading-actions");
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","btn btn-small btn-primary");
        var el5 = dom.createTextNode("Save settings");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","btn btn-small btn-default");
        var el5 = dom.createTextNode("Cancel");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2,"class","ts-forms_field-label");
        var el3 = dom.createTextNode("Choose the range for your scale");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("to");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("hr");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2,"class","ts-forms_field-label");
        var el3 = dom.createTextNode("Scale label");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("hr");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","ts-forms_field-label-container");
        var el3 = dom.createElement("label");
        dom.setAttribute(el3,"class","ts-forms_field-label");
        var el4 = dom.createTextNode("End point labels");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ts-forms_fields-label-description");
        var el4 = dom.createTextNode("Labels that let students know what the end values mean.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("hr");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2,"class","ts-checkbox");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createTextNode("Enable comments on this scale");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, element = hooks.element, get = hooks.get, inline = hooks.inline, content = hooks.content;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [0, 1]);
        var element2 = dom.childAt(element1, [0]);
        var element3 = dom.childAt(element1, [1]);
        var element4 = dom.childAt(element0, [14]);
        var morph0 = dom.createMorphAt(element0,2,2);
        var morph1 = dom.createMorphAt(element0,4,4);
        var morph2 = dom.createMorphAt(element0,7,7);
        var morph3 = dom.createMorphAt(element0,10,10);
        var morph4 = dom.createMorphAt(element0,11,11);
        var morph5 = dom.createMorphAt(element0,12,12);
        var morph6 = dom.createMorphAt(element4,0,0);
        element(env, element2, context, "action", ["save"], {"on": "click"});
        element(env, element3, context, "action", ["back"], {"on": "click"});
        inline(env, morph0, context, "component", [get(env, context, "c_validated_input")], {"value": get(env, context, "points_min"), "placeholder": "Minimum points", "errors": get(env, context, "errors.points_min")});
        inline(env, morph1, context, "component", [get(env, context, "c_validated_input")], {"value": get(env, context, "points_max"), "placeholder": "Maximum points", "errors": get(env, context, "errors.points_max")});
        inline(env, morph2, context, "input", [], {"value": get(env, context, "label")});
        inline(env, morph3, context, "input", [], {"value": get(env, context, "scale_label_min"), "placeholder": "Min label"});
        content(env, morph4, context, "to");
        inline(env, morph5, context, "input", [], {"value": get(env, context, "scale_label_max"), "placeholder": "Max label"});
        element(env, element4, context, "action", ["toggle_has_comments"], {"on": "click"});
        inline(env, morph6, context, "component", [get(env, context, "c_checkbox")], {"checked": get(env, context, "has_comments"), "disable_click": true});
        return fragment;
      }
    };
  }()));

});