define('client/components/thinkspace/casespace/case_manager/team_set/edit', ['exports', 'ember', 'ember-data', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ds, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    title: ember['default'].computed.reads('model.title'),
    space: ember['default'].computed.reads('model.space'),
    c_space_header: ns['default'].to_p('space', 'header'),
    r_team_sets: ns['default'].to_r('case_manager', 'team_sets', 'index'),
    transition_to_team_set_show: 'transition_to_team_set_show',
    transition_to_team_set_index: 'transition_to_team_set_index',
    actions: {
      save: function() {
        var model;
        model = this.get('model');
        model.set('metadata', {});
        model.set('title', this.get('title'));
        this.totem_messages.show_loading_outlet({
          message: 'Saving team set...'
        });
        return model.save().then((function(_this) {
          return function(team_set) {
            return team_set.get(ns['default'].to_p('space')).then(function(space) {
              _this.sendAction('transition_to_team_set_show', space, team_set);
              return _this.totem_messages.hide_loading_outlet();
            });
          };
        })(this));
      },
      destroy: function() {
        var model;
        model = this.get('model');
        this.totem_messages.show_loading_outlet({
          message: 'Deleting team set...'
        });
        return model.destroyRecord().then((function(_this) {
          return function() {
            _this.sendAction('transition_to_team_set_index');
            return _this.totem_messages.hide_loading_outlet();
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});