define('client/components/thinkspace/common/space_user/row', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'thinkspace-base/components/base'], function (exports, ember, ns, ajax, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: 'tr',
    friendly_roles: ember['default'].computed.alias('model.friendly_roles'),
    state_change_ajax: function(action) {
      var model, query;
      model = this.get('model');
      query = {
        id: model.get('id'),
        action: action,
        verb: 'PUT'
      };
      return this.tc.query(ns['default'].to_p('space_user'), query, {
        single: true
      });
    },
    actions: {
      save: function(friendly_role) {
        var space_user;
        space_user = this.get('model');
        space_user.set_role_from_friendly(friendly_role);
        return space_user.save().then((function(_this) {
          return function(saved_space_user) {
            return _this.totem_messages.api_success({
              source: _this,
              model: saved_space_user,
              action: 'update',
              i18n_path: ns['default'].to_o('space_user', 'save')
            });
          };
        })(this), (function(_this) {
          return function(error) {
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: space_user,
              action: 'update'
            });
          };
        })(this));
      },
      inactivate: function() {
        this.totem_messages.show_loading_outlet();
        return this.state_change_ajax('inactivate').then((function(_this) {
          return function() {
            _this.totem_messages.api_success({
              source: _this,
              model: _this.get('model'),
              action: 'update',
              i18n_path: ns['default'].to_o('space_user', 'save')
            });
            return _this.totem_messages.hide_loading_outlet();
          };
        })(this));
      },
      activate: function() {
        this.totem_messages.show_loading_outlet();
        return this.state_change_ajax('activate').then((function(_this) {
          return function() {
            _this.totem_messages.api_success({
              source: _this,
              model: _this.get('model'),
              action: 'update',
              i18n_path: ns['default'].to_o('space_user', 'save')
            });
            return _this.totem_messages.hide_loading_outlet();
          };
        })(this));
      },
      resend: function() {
        return this.get('model.user').then((function(_this) {
          return function(user) {
            var refresh_options, resend_options;
            refresh_options = {
              model: user,
              id: user.get('id'),
              action: 'refresh',
              verb: 'PUT'
            };
            resend_options = {
              model: _this.get('model'),
              id: _this.get('model.id'),
              action: 'resend',
              verb: 'PUT'
            };
            return ajax['default'].object(refresh_options).then(function(payload) {
              return ajax['default'].object(resend_options).then(function() {
                return _this.totem_messages.api_success({
                  source: _this,
                  model: _this.get('model'),
                  action: 'resend',
                  i18n_path: ns['default'].to_o('invitation', 'resend')
                });
              });
            });
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});