define('client/services/taf', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Service.extend({
    filters: {},
    bound_filters: {},
    is_querying: false,
    query: function(collection) {
      collection = this.make_array(collection);
      this.set_is_querying();
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var filters, promises, property, value, values;
          _this.process_bound_filters();
          filters = _this.get('filters');
          if (ember['default'].isEmpty(filters)) {
            resolve(collection);
          }
          promises = _this.make_array();
          for (property in filters) {
            value = filters[property];
            values = _this.make_array(value);
            promises.pushObject(_this.filter_by(collection, property, values));
          }
          return ember['default'].RSVP.Promise.all(promises).then(function(results) {
            results = _this.intersection(results);
            _this.reset_is_querying();
            return resolve(results);
          });
        };
      })(this));
    },
    add_filter: function(key, values, options) {
      var filters;
      if (options == null) {
        options = {};
      }
      if (!key) {
        return;
      }
      if (options['clear']) {
        this.clear();
      }
      filters = this.get('filters') || {};
      filters[key] = values;
      this.set('filters', filters);
      return filters;
    },
    remove_filter: function(key) {
      var filters;
      if (!key) {
        return;
      }
      filters = this.get('filters') || {};
      delete filters[key];
      this.set('filters', filters);
      return filters;
    },
    remove_all_filters: function() {
      var filters, property, results1, value;
      filters = this.get('filters');
      results1 = [];
      for (property in filters) {
        value = filters[property];
        results1.push(this.remove_filter(property));
      }
      return results1;
    },
    clear: function() {
      return this.remove_all_filters();
    },
    add_bound_filter: function(key, values_property, context) {
      var bound_filters;
      if (!key && values_property && context) {
        return;
      }
      bound_filters = this.get('bound_filters') || {};
      bound_filters[key] = {
        context: context,
        values_property: values_property
      };
      this.set('bound_filters', bound_filters);
      return bound_filters;
    },
    remove_bound_filter: function(key) {
      var bound_filters;
      if (!key) {
        return;
      }
      bound_filters = this.get('bound_filters') || {};
      delete bound_filters[key];
      this.set('bound_filters', bound_filters);
      return bound_filters;
    },
    remove_all_bound_filters: function() {
      var bound_filters, property, results1, value;
      bound_filters = this.get('bound_filters');
      results1 = [];
      for (property in bound_filters) {
        value = bound_filters[property];
        results1.push(this.remove_bound_filter(property));
      }
      return results1;
    },
    process_bound_filters: function() {
      var bound_filter, context, key, ref, results1, values, values_property;
      ref = this.get('bound_filters');
      results1 = [];
      for (key in ref) {
        bound_filter = ref[key];
        context = bound_filter.context;
        values_property = bound_filter.values_property;
        values = context.get(values_property);
        results1.push(this.add_filter(key, values));
      }
      return results1;
    },
    filter_by: function(collection, property, values, options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var type;
          if (ember['default'].isEmpty(collection) || ember['default'].isEmpty(values)) {
            return resolve(collection);
          } else {
            type = _this.get_property_type(collection, property);
            switch (type) {
              case 'relationship':
                return _this.filter_by_relationship(collection, property, values, options).then(function(results) {
                  return resolve(results);
                });
              case 'property':
                return _this.filter_by_property(collection, property, values, options).then(function(results) {
                  return resolve(results);
                });
              default:
                return resolve(collection);
            }
          }
        };
      })(this));
    },
    filter_by_relationship: function(collection, relationship, values, options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (ember['default'].isEmpty(collection) || ember['default'].isEmpty(values)) {
            return resolve(collection);
          } else {
            return ember['default'].RSVP.filter(collection, function(member) {
              return member.get(relationship).then(function(related_records) {
                related_records = _this.make_array(related_records);
                return related_records.any(function(related_record) {
                  return values.contains(related_record);
                });
              });
            }).then(function(results) {
              return resolve(results);
            });
          }
        };
      })(this));
    },
    filter_by_property: function(collection, property, values, options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var results;
          if (ember['default'].isEmpty(collection || ember['default'].isEmpty(values))) {
            resolve(collection);
          }
          results = [];
          collection.forEach(function(member) {
            var value;
            value = member.get(property);
            if (values.contains(value)) {
              return results.pushObject(member);
            }
          });
          return resolve(results);
        };
      })(this));
    },
    get_property_type: function(collection, property) {
      var member, val;
      if (!ember['default'].isPresent(property)) {
        return;
      }
      if (ember['default'].isEmpty(collection)) {
        return;
      }
      member = collection.get('firstObject');
      val = member.get(property);
      if (!(ember['default'].isPresent(val) || ember['default'].isArray(val))) {
        return;
      }
      if (val.then != null) {
        return 'relationship';
      } else {
        return 'property';
      }
    },
    intersection: function(arrays) {
      var array, j, len, result;
      result = this.make_array(arrays.get('firstObject'));
      for (j = 0, len = arrays.length; j < len; j++) {
        array = arrays[j];
        result = ember['default'].EnumerableUtils.intersection(result, this.make_array(array));
      }
      return result;
    },
    difference: function(array1, array2) {
      var element1, j, len, result;
      array1 = this.make_array(array1);
      array2 = this.make_array(array2);
      result = this.make_array(array1);
      for (j = 0, len = array1.length; j < len; j++) {
        element1 = array1[j];
        if (array2.contains(element1)) {
          result.removeObject(element1);
        }
      }
      return result;
    },
    flatten: function(arrays) {
      var array, j, len, result;
      result = this.make_array();
      for (j = 0, len = arrays.length; j < len; j++) {
        array = arrays[j];
        result = result.concat(this.make_array(array));
      }
      return result;
    },
    compress: function(arrays) {
      var arr, i, j, len;
      arrays = this.make_array(arrays).compact();
      for (i = j = 0, len = arrays.length; j < len; i = ++j) {
        arr = arrays[i];
        arrays[i] = this.make_array(arr).compact();
      }
      return arrays;
    },
    shuffle: function(array, no_of_swaps) {
      var j, length, ref, swap_i_1, swap_i_2, tmp;
      if (no_of_swaps == null) {
        no_of_swaps = 2;
      }
      array = this.make_array(array);
      length = array.get('length');
      for (j = 0, ref = length * no_of_swaps; 0 <= ref ? j <= ref : j >= ref; 0 <= ref ? j++ : j--) {
        swap_i_1 = Math.round(Math.random() * (length - 1));
        swap_i_2 = Math.round(Math.random() * (length - 1));
        if (swap_i_1 !== swap_i_2) {
          tmp = array.objectAt(swap_i_1);
          array[swap_i_1] = array[swap_i_2];
          array[swap_i_2] = tmp;
        }
      }
      return array;
    },
    make_array: function(val) {
      if (!ember['default'].isPresent(val)) {
        return ember['default'].makeArray();
      }
      if (val.toArray != null) {
        val = val.toArray();
      }
      return ember['default'].makeArray(val);
    },
    set_is_querying: function() {
      return this.set('is_querying', true);
    },
    reset_is_querying: function() {
      return this.set('is_querying', false);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});