define('client/thinkspace/team/teams/destroy/controller', ['exports', 'ember', 'totem/ns', 'thinkspace-team/controllers/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    model_validation_message: null,
    actions: {
      destroy: function() {
        var team;
        team = this.get('model');
        return this.unload_team_associations(team).then((function(_this) {
          return function() {
            team.deleteRecord();
            return team.save().then(function(team) {
              _this.totem_messages.api_success({
                source: _this,
                model: team,
                action: 'destroy',
                i18n_path: ns['default'].to_o('team', 'destroy')
              });
              return _this.transition_to_index();
            }, function(error) {
              _this.totem_messages.api_failure(error, {
                source: _this,
                model: team,
                action: 'destroy',
                without_key: false
              });
              return _this.set('model_validation_message', team.get('validation_message'));
            });
          };
        })(this));
      },
      cancel: function() {
        return this.transition_to_index();
      }
    },
    unload_team_associations: function(team) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return team.get(ns['default'].to_p('team_teamables')).then(function(team_teamables) {
            return team.get(ns['default'].to_p('team_viewers')).then(function(team_viewers) {
              var team_id, team_path, viewing;
              team_teamables.map(function(team_teamable) {
                return team_teamable.unloadRecord();
              });
              team_viewers.map(function(team_viewer) {
                return team_viewer.unloadRecord();
              });
              team_path = _this.totem_scope.get_record_path(team);
              team_id = parseInt(team.get('id'));
              viewing = _this.store.all(ns['default'].to_p('team_viewer')).filter(function(team_viewer) {
                var viewer_path;
                viewer_path = _this.totem_scope.rails_polymorphic_type_to_path(team_viewer.get('viewerable_type'));
                return team_path === viewer_path && team_id === team_viewer.get('viewerable_id');
              });
              viewing.map(function(team_team_viewer) {
                return team_team_viewer.unloadRecord();
              });
              return resolve();
            });
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});