define('client/models/thinkspace/casespace/message', ['exports', 'ember', 'ember-data', 'totem/ds/associations', 'totem-messages/mixins/models/message'], function (exports, ember, ds, ta, m_msg) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ta['default'].Model.extend(m_msg['default'], {
    messages: ember['default'].inject.service(ta['default'].to_p('casespace', 'messages'))
  });

  exports['default'] = ___DefaultExportObject___;;

});