define('client/validators/local/phase_submit_text', ['exports', 'ember', 'ember-validations/validators/base'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    init: function() {
      this._super();
      return this.dependentValidationKeys.pushObject("submit_visible");
    },
    call: function() {
      var text, visible;
      text = this.get('submit_text');
      visible = this.get('submit_visible');
      if (visible && !text) {
        return this.errors.pushObject("submit text is required if the button is visible");
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});