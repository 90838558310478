define('client/components/thinkspace/readiness_assurance/admin/tracker/show', ['exports', 'ember', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    init: function() {
      var model;
      this._super();
      model = this.am.get_model();
      return this.room = this.am.pubsub.room_for(model);
    },
    track_users: null,
    willInsertElement: function() {
      return this.am.pubsub.tracker_show({
        room: this.room,
        source: this,
        callback: 'handle_tracker_show'
      });
    },
    willDestroyElement: function() {
      return this.am.pubsub.tracker_show_leave({
        room: this.room
      });
    },
    emit_tracker_show: function(options) {
      return this.am.pubsub.emit_tracker_show(options);
    },
    handle_tracker_show: function(data) {
      return this.set_track_users(data);
    },
    set_track_users: function(data) {
      var hrefs, values;
      console.info('tracker show', data);
      values = data.value || [];
      hrefs = values.mapBy('href');
      return this.get_tracker_href_to_title(hrefs).then((function(_this) {
        return function(href_to_titles) {
          var date, hash, href, href_users, i, item, len, order, ref, title, track_users, user, users;
          href_users = {};
          for (i = 0, len = values.length; i < len; i++) {
            hash = values[i];
            href = hash.href;
            date = hash.date;
            date = date ? _this.am.format_time(date) : 'no-date';
            title = (hash.resource || {}).title;
            user = hash.user;
            user.date = date;
            user.username = _this.get_username(user);
            item = (href_users[href] != null ? href_users[href] : href_users[href] = {});
            ref = _this.get_href_title(href_to_titles, href), order = ref[0], title = ref[1];
            item.title = title;
            item.order = order;
            users = (item.users != null ? item.users : item.users = []);
            users.push(user);
          }
          track_users = [];
          for (href in href_users) {
            item = href_users[href];
            title = item.title;
            order = item.order;
            users = item.users.sortBy('username');
            track_users.push({
              href: href,
              order: order,
              title: title,
              users: users
            });
          }
          return _this.set('track_users', track_users.sortBy('order'));
        };
      })(this));
    },
    get_href_title: function(href_to_titles, href) {
      var hash, i, len, match;
      for (i = 0, len = href_to_titles.length; i < len; i++) {
        hash = href_to_titles[i];
        match = hash.match;
        if (match && href.match(match)) {
          return [hash.order, hash.title];
        }
      }
      return href;
    },
    get_tracker_href_to_title: function(hrefs) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var href_to_title;
          href_to_title = [];
          href_to_title.push({
            match: 'casespace/cases/1/phases/1',
            title: 'IRAT',
            order: 2
          });
          href_to_title.push({
            match: 'casespace/cases/1/phases/2',
            title: 'TRAT',
            order: 3
          });
          href_to_title.push({
            match: 'casespace/cases/1/phases/3',
            title: 'Overview',
            order: 4
          });
          href_to_title.push({
            match: 'casespace/cases/1',
            title: 'Assignment',
            order: 1
          });
          return resolve(href_to_title);
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});