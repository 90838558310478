define('client/services/thinkspace', ['exports', 'ember', 'totem/config', 'totem/ns', 'totem-messages/messages'], function (exports, ember, config, ns, totem_messages) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Object.extend({
    sockets: ember['default'].inject.service(),
    init: function() {
      this._super();
      return this.get('sockets').initialize();
    },
    toolbar_is_hidden: false,
    toolbar_is_minimized: false,
    dock_is_visible: false,
    display_terms_modal: false,
    check_users_terms: function(user) {
      var terms_updated_at, user_accepted_at;
      terms_updated_at = new Date(config['default'].terms.updated_at);
      user_accepted_at = user.get('terms_accepted_at');
      if (ember['default'].isEmpty(user_accepted_at) || (user_accepted_at < terms_updated_at)) {
        return this.set('display_terms_modal', true);
      }
    },
    hide_toolbar: function() {
      return this.set('toolbar_is_hidden', true);
    },
    show_toolbar: function() {
      return this.set('toolbar_is_hidden', false);
    },
    toggle_hide_toolbar: function() {
      return this.toggleProperty('toolbar_is_hidden');
    },
    minimize_toolbar: function() {
      return this.set('toolbar_is_minimized', true);
    },
    expand_toolbar: function() {
      return this.set('toolbar_is_minimized', false);
    },
    toggle_minimize_toolbar: function() {
      return this.toggleProperty('toolbar_is_minimized');
    },
    hide_dock: function() {
      return this.set('dock_is_visible', false);
    },
    show_dock: function() {
      return this.set('dock_is_visible', true);
    },
    toggle_dock: function() {
      return this.toggleProperty('dock_is_visible');
    },
    enable_wizard_mode: function() {
      return this.hide_toolbar();
    },
    disable_wizard_mode: function() {
      return this.show_toolbar();
    },
    scroll_to_top: function() {
      $('#content-wrapper').scrollTop(0);
      return $(window).scrollTop(0);
    },
    current_transition: null,
    get_current_transition: function() {
      return this.get('current_transition');
    },
    set_current_transition: function(transition) {
      return this.set('current_transition', transition);
    },
    transition_is_for: function(transition, match) {
      var target;
      if (match == null) {
        match = null;
      }
      if (!(transition && match)) {
        return false;
      }
      target = transition.targetName || '';
      return target.match(match);
    },
    sticky_browser_resize: null,
    set_component_column_as_sticky: function(component) {
      var $column, $siblings, columns_class, sticky_class;
      columns_class = "." + config['default'].grid.classes.columns;
      sticky_class = config['default'].grid.classes.sticky;
      $column = component.$().parents(columns_class).first();
      if (!ember['default'].isPresent($column)) {
        return;
      }
      $column.addClass(sticky_class);
      $siblings = $column.siblings(columns_class);
      $siblings.addClass(sticky_class);
      return this.bind_sticky_columns();
    },
    bind_sticky_columns: function() {
      this.add_height_to_sticky_columns();
      return this.bind_sticky_browser_resize();
    },
    add_height_to_sticky_columns: function() {
      var height, sticky_class;
      sticky_class = "." + config['default'].grid.classes.sticky;
      height = this.get_visible_content_height();
      return $(sticky_class).each((function(_this) {
        return function(i, container) {
          var $container;
          $container = $(container);
          return $container.css('height', height + "px");
        };
      })(this));
    },
    get_visible_content_height: function() {
      var h_dock, h_nav, h_window;
      h_window = $(window).height();
      h_nav = $('#navbar').outerHeight();
      h_dock = $('.thinkspace-dock').outerHeight();
      return h_window - h_nav - h_dock;
    },
    bind_sticky_browser_resize: function() {
      var binding;
      if (ember['default'].isPresent(this.get('sticky_browser_resize'))) {
        return;
      }
      binding = $(window).resize((function(_this) {
        return function() {
          return _this.add_height_to_sticky_columns();
        };
      })(this));
      return this.set('sticky_browser_resize', binding);
    },
    add_system_notification: function(type, message, sticky) {
      var fn;
      if (sticky == null) {
        sticky = true;
      }
      fn = totem_messages['default'][type];
      if (fn != null) {
        return totem_messages['default'][type](message, sticky);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});