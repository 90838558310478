define('client/transforms/json-api-models', ['exports', 'ember', 'totem/scope', 'ember-data'], function (exports, ember, totem_scope, ds) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ds['default'].Transform.extend({
    deserialize: function(models) {
      var records, store;
      if (!ember['default'].isPresent(models)) {
        return null;
      }
      models = ember['default'].makeArray(models);
      store = totem_scope['default'].get_store();
      records = [];
      models.forEach((function(_this) {
        return function(hash) {
          var attributes, id, record, type;
          type = hash.type;
          id = hash.id;
          attributes = hash.attributes || {};
          if (!ember['default'].isPresent(attributes.id)) {
            attrubutes.id = id;
          }
          if (type && id) {
            record = store.push(type, store.normalize(type, attributes));
            return records.push(record);
          }
        };
      })(this));
      return records;
    },
    serialize: function(models) {
      var payload;
      models = ember['default'].makeArray(models);
      payload = [];
      models.forEach((function(_this) {
        return function(model) {
          var record;
          record = {
            type: totem_scope['default'].standard_record_path(model).pluralize(),
            id: model.get('id'),
            attributes: model.serialize()
          };
          return payload.pushObject(record);
        };
      })(this));
      return payload;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});