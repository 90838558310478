define('client/components/thinkspace/casespace/toolbar/switch_user', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    casespace: ember['default'].inject.service(),
    r_spaces_show: ns['default'].to_r('spaces', 'show'),
    actions: {
      switch_user: function() {
        var data, session, space;
        space = this.get('casespace').get_current_space();
        if (ember['default'].isBlank(space)) {
          this.totem_messages.invalidate_session();
        }
        data = {
          space_id: space.get('id')
        };
        session = this.get('session');
        return session.authenticate('authenticator_switch_user:totem', session, data).then((function(_this) {
          return function() {
            _this.totem_messages.show_loading_outlet();
            window.location = window.location.pathname;
          };
        })(this), (function(_this) {
          return function(error) {
            return console.error("Cannot switch user. Error:", error);
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});